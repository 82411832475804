<template>
  <v-container class="spacing-playground pa-6" fluid>
    <div class="tables-basic">
      <br />
      <h1 class="page-title mt-11 mb-6">Reportes de Fallas</h1>
    </div>
    <v-row>
      
      <v-col  cols="12" sm="12" lg="4"  v-for="reporte in reportes" :key="reporte._id">
         <transition appear name="slide-fade">
          <v-card  v-if="reporte.estatus == 'iniciado'"  color="#00C853" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5"
                  >R-{{ reporte.folio_falla }}</v-card-title
                >
                <v-card-subtitle
                  >{{ reporte.activo.tipoUnidad }} -
                  {{ reporte.activo.numeroEconomico }}</v-card-subtitle
                >
                <v-card-subtitle></v-card-subtitle>
                <v-card-actions>
                  <v-btn
                    class="ml-2 mt-3"
                    fab
                    icon
                    height="40px"
                    right
                    width="40px"
                  >
                    <v-icon>mdi-play</v-icon>
                  </v-btn>
                </v-card-actions>
              </div>

              <v-avatar class="ma-3" size="125" tile>
                <v-img :src="reporte.activo.fotos[0]"></v-img>
              </v-avatar>
            </div>
          </v-card>
        
          <v-card v-if="reporte.estatus == 'Asignado'" color="#0288D1" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5"
                  >R-{{ reporte.folio_falla }}</v-card-title
                >
                <v-card-subtitle
                  >{{ reporte.activo.tipoUnidad }} -
                  {{ reporte.activo.numeroEconomico }}</v-card-subtitle
                >
                <v-card-subtitle v-if="reporte.asignado">{{
                  reporte.asignado.nombreMecanico
                }}</v-card-subtitle>
                <v-card-actions>
                  <v-btn
                    class="ml-2 mt-3"
                    fab
                    icon
                    height="40px"
                    right
                    width="40px"
                  >
                    <v-icon>mdi-pause</v-icon>
                  </v-btn>
                </v-card-actions>
              </div>

              <v-avatar class="ma-3" size="125" tile>
                <v-img :src="reporte.activo.fotos[0]"></v-img>
              </v-avatar>
            </div>
          </v-card>
     </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import axios from "axios";
import io from "socket.io-client";

export default {
  name: "app",
  data() {
    return {
      reportes: [],
      show:false
    };
  },
  computed: {},
  created() {
    const socket = io("https://cma.cicsagruas.com/");
    socket.on("loadReport", (data) => {
      console.log(data);
      this.reportes = data;
    });
    this.showView()
  },
  methods: {
    showView(){
      this.show=true
    },
    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
};
</script>
<style>
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>