<template>
  <v-container fluid>
    <div class="tables-basic">
    
      <v-overlay :value="loadingTable">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      

      <v-card class="mx-auto">
        <br /><br /><br /><br />

        <h1 class="pa-1 ma-2 grey--text">Bitacora de Fallas</h1>
        <template>
          <vue-good-table
            theme="black-rhino"
            class="pa-6 ma-2"
            :columns="headersRF"
            :rows="reportesFallas"
            :line-numbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Buscar...',
            }"
            :pagination-options="{
              enabled: true,
              perPage: 20,
            }"
          >
            >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'activo.numeroEconomico'">
                <v-chip
                  dark
                  small
                  class="ma-2"
                  color="deep-purple accent-4"
                  outlined
                >
                  <v-icon left> mdi-crane </v-icon>
                  {{ props.row.activo.numeroEconomico }}
                </v-chip>
              </span>
              <span v-if="props.column.field == 'prioridad'">
                {{ props.row.prioridad }}
              </span>
              <span v-if="props.column.field == 'requisicion'">
                {{ props.row.requisicion }}
              </span>
              <span v-if="props.column.field == 'descripcionFalla'">
                {{ props.row.descripcionFalla }}
              </span>
              <span v-if="props.column.field == 'folio_falla'">
                {{ props.row.folio_falla }}
              </span>
             
              <span v-if="props.column.field == 'mes'">
                {{ moment(props.row.fechaFalla).format("MMMM YYYY") }}
              </span>
              <span v-if="props.column.field == 'fin_mtto'">
                <div v-if="props.row.fin_mtto">
                  {{ moment(props.row.fin_mtto).format("MMMM YYYY") }}
                </div>
              </span>
              <span v-if="props.column.field == 'estatus'">
                <v-chip
                  v-if="props.row.estatus == 'Asignado'"
                  color="blue"
                  dark
                  small
                >
                  {{ props.row.estatus }}
                </v-chip>
                <v-chip
                  dark
                  v-if="props.row.estatus == 'Pendiente'"
                  color="orange"
                  small
                >
                  {{ props.row.estatus }}
                </v-chip>
                <v-chip
                  dark
                  v-if="props.row.estatus == 'Cancelado'"
                  color="primary"
                  small
                >
                  {{ props.row.estatus }}
                </v-chip>
                <v-chip
                  dark
                  v-if="props.row.estatus == 'finalizado'"
                  color="success"
                  small
                >
                  Finalizado
                </v-chip>
              </span>
              <span v-if="props.column.field == 'actions'">
                <v-btn
                  class="mx-2"
                  @click="imprimirReporte(props.row._id)"
                  fab
                  dark
                  small
                  color="deep-purple accent-4"
                  outlined
                >
                  <v-icon dark> mdi-printer </v-icon>
                </v-btn>
              </span>
            </template>
          </vue-good-table>
        </template>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import mttoApi from "../../api/Mtto";
import moment from "moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
export default {
  data() {
    return {
      dialog: false,
      headersRF: [
        {
          label: "Unidad",
          align: "start",
          sortable: true,
          field: "activo.numeroEconomico",
          width: "130px",
          filterOptions: {
            styleClass: "class1",
            enabled: true,
            placeholder: "Equipo",
          },
        },
        { label: "Folio", field: "folio_falla", width: "80px" },
        { label: "Mes", field: "mes", width: "150px" },
        { label: "Atención", field: "fin_mtto", width: "150px" },

        { label: "Falla", field: "descripcionFalla", width: "450px" },
        { label: "Prioridad", field: "prioridad", width: "180px" },
        {
          label: "Estatus",
          field: "estatus",
          width: "150px",
          filterOptions: {
            styleClass: "class1",
            enabled: true,
            placeholder: "Estatus",
          },
        },
        { label: "Requisicion", field: "requisicion", width: "120px" },
        { label: "Detalles", field: "actions", width: "98px" },
      ],

      reportesFallas: [],
      loadingTable: true,
      moment,
    };
  },
  computed: {},
  created() {
    this.mostrarReporteFallas();
    this.$store.commit("setRuta", "Reporte de Fallas");
  },
  methods: {
    mostrarReporteFallas() {
      mttoApi
        .listarReporteFallas()
        .then((e) => {
          this.reportesFallas = e.data;
          console.log(e.data);
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    imprimirReporte(item) {
      this.loadingTable = true;
      mttoApi.reporteFallaId(item).then((e) => {
        this.generarPdf(e.data).finally(() => {
          this.loadingTable = false;
        });
      });
    },

    async generarPdf(item) {
      var doc = new jsPDF("p", "pt", "a4");
      var startX = 40;
      var startY = 20;
      doc.addImage(
        "https://i.ibb.co/L5Lmq8d/Logotipo-CICSA.png",
        "JPEG",
        startX + 15,
        (startY += 5),
        80,
        60
      );

      doc.setFont(undefined, "bold");
      doc.setFontSize(7);
      let date = moment(new Date()).format("LLL");
      doc.text(date, (startX += 405), (startY += 25));
      doc.text("CORPORATIVO INDUSTRIAL Y COMERCIAL S.A. DE C.V.", 348, 67);
      doc.setFontSize(14);
      doc.setLineWidth(1.5);
      doc.text("Reporte de Fallas", 230, 110);
      doc.setFontSize(10);
      doc.text("Información del Solicitante", 55, 140);
      doc.setFontSize(8);
      doc.setLineWidth(1);
      doc.text("Nombre Completo:", 55, 165);
      doc.line(145, 167, 400, 167);
      doc.text("Correo:", 55, 178);
      doc.line(145, 180, 400, 180);
      doc.text("Telefono:", 55, 191);
      doc.line(145, 193, 400, 193);

      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      if (item.usuarioCreador) {
        doc.text(item.usuarioCreador.nombreCompleto, 150, 165);
        doc.text(item.usuarioCreador.correo, 150, 178);
        doc.text(item.usuarioCreador.telefono.toString(), 150, 191);
      }

      doc.setFont(undefined, "bold");
      doc.setFontSize(10);
      doc.text("Información del Equipo", 55, 216);
      doc.setFontSize(8);
      doc.setLineWidth(1);
      doc.text("Activo:", 55, 241);
      doc.line(145, 243, 300, 243);
      doc.text("Marca:", 55, 254);
      doc.line(145, 256, 300, 256);
      doc.text("Modelo:", 55, 267);
      doc.line(145, 269, 300, 269);
      doc.text("Numero de Serie:", 55, 280);
      doc.line(145, 282, 300, 282);

      doc.text("Placa:", 308, 241);
      doc.line(395, 243, 550, 243);
      doc.text("Estatus:", 308, 254);
      doc.line(395, 256, 550, 256);
      doc.text("Numero Economico:", 308, 267);
      doc.line(395, 269, 550, 269);
      doc.text("Ubicacion:", 308, 280);
      doc.line(395, 282, 550, 282);

      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      doc.text(item.activo.nombreActivo, 150, 241);
      doc.text(item.activo.marca, 150, 254);
      doc.text(item.activo.modelo, 150, 267);
      doc.text(item.activo.numeroSerie, 150, 280);

      doc.text(item.activo.placa, 403, 241);
      doc.text(item.activo.estatu, 403, 254);
      doc.text(item.activo.numeroEconomico, 403, 267);
      doc.text(item.activo.ubicacion.ubicacion, 403, 280);

      doc.setFont(undefined, "bold");
      doc.setFontSize(10);
      doc.text("Información del Repore de Falla", 55, 380);
      doc.setFontSize(8);
      doc.setLineWidth(1);
      doc.text("Estatus:", 55, 395);
      doc.line(145, 397, 300, 397);
      doc.text("Fecha Falla:", 55, 408);
      doc.line(145, 410, 300, 410);
      doc.text("Operador:", 55, 421);
      doc.line(145, 423, 300, 423);
      doc.text("Prioridad:", 55, 434);
      doc.line(145, 436, 300, 436);

      doc.text("Tipo de Falla:", 308, 395);
      doc.line(395, 397, 550, 397);
      doc.text("Folio de Falla:", 308, 408);
      doc.line(395, 410, 550, 410);
      doc.text("Fecha de Creacion:", 308, 421);
      doc.line(395, 423, 550, 423);
      doc.text("Mecanico asignado:", 308, 434);
      doc.line(395, 436, 550, 436);

      let fechaFalla = moment(new Date(item.fechaFalla)).format("LL");
      let createdAt = moment(new Date(item.createdAt)).format("LLL");

      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      doc.text(item.estatus, 150, 393);
      doc.text(fechaFalla, 150, 406);
      doc.text(item.operador, 150, 419);
      if(item.prioridad){
        doc.text(item.prioridad,150,432)
      }

      doc.text(item.tipoFalla, 403, 393);
      doc.text(item.folio_falla.toString(), 403, 406);
      doc.text(createdAt, 403, 419);
      if(item.asignado){
        doc.text(item.asignado.nombreMecanico,403,432)
      }

      doc.setFont(undefined, "bold");
      doc.setFontSize(10);
      doc.text("Descripción de la Falla", 55, 305);
      doc.rect(55, 315, 500, 40);
      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      doc.text(item.descripcionFalla, 58, 325);
      // doc.line(200,600,380,600,'F')
      if (item.diagnostico) {
        doc.setFont(undefined, "bold");
        doc.setFontSize(10);
        doc.text("Diagnostico", 55, 455);
        doc.rect(55, 465, 500, 40);
        doc.setFont(undefined, "normal");
        doc.setFontSize(8);

        doc.text(item.diagnostico, 58, 475);
      }
      if (item.procedimiento) {
        doc.setFont(undefined, "bold");
        doc.setFontSize(10);
        doc.text("Procedimiento de Trabajo", 55, 525);
        doc.rect(55, 535, 500, 40);
        doc.setFont(undefined, "normal");
        doc.setFontSize(8);

        doc.text(item.procedimiento, 58, 545);
      }
      if (item.observaciones) {
        doc.setFont(undefined, "bold");
        doc.setFontSize(10);
        doc.text("Observaciones", 55, 595);
        doc.rect(55, 605, 500, 40);
        doc.setFont(undefined, "normal");
        doc.setFontSize(8);

        doc.text(item.observaciones, 58, 615);
      }
      
      if (item.articulos.length>0) {
        let Y = 660;
        let X = 55;
        let rows = [];

        item.articulos.map((x) => {
          rows.push({
            idMicrosip: x.idMicrosip,
            articulo: x.articulo,
            cantidad: x.cantidad,
          });
        });
        autoTable(doc, {
          startY: Y,
          startX: X,
          head: [["Id", "Articulo", "Cantidad"]],
          body: rows,
          columns: [
            { header: "Id", dataKey: "idMicrosip" },
            { header: "Articulo", dataKey: "articulo" },
            { header: "Cantidad", dataKey: "cantidad" },
          ],
          headStyles: {
            fillColor: [243, 22, 22],
          },
        });
        //const file = doc.output('datauristring')
        //this.pruebaCorreo(file,item.asignado.nombreMecanico)
      }
      await doc.output("dataurlnewwindow");

    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
};
</script>
<style>
.reporte.Operativo {
  border-left: 4px solid #3cd1c2;
}
.reporte.Fuera {
  border-left: 4px solid orange;
}
.reporte.Cancelado {
  border-left: 4px solid #f83e70;
}
</style>
