<template>
  <v-container fluid>
    <div class="tables-basic">
      <br />
      <h1 class="page-title mt-16 mb-6">Contrato</h1>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-6 pb-3">
              <v-row>
                <v-dialog
                  v-model="dialogNuevaEmpresa"
                  persistent
                  max-width="400px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      rounded
                      color="primary"
                      small
                      dark
                      >Nuevo Contrato</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="numero_contrato"
                              label="Numero de Contrato"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <vuetify-money
                              cleareble="true"
                              v-model="monto_contrato"
                              label="Monto del Contrato"
                              v-bind:options="options"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="plazo"
                              label="Plazo del contrato"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              type="date"
                              v-model="inicio_contrato"
                              label="Inicio del contrato"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              type="date"
                              v-model="fin_contrato"
                              label="Fin del contrato"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              :items="estatus"
                              label="Estatus de Contrato"
                              v-model="estatu"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="cerrarModalEmpresa()"
                      >
                        Cerrar
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="guardar()">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="contratos"
              :search="search"
              item-key="nombreComercial"
            >
              <template v-slot:[`item.action`]="{ item }">
                <template v-if="item.estatus == 'Activo'">
                  <v-icon
                    color="red"
                    @click="finalizarContrato(item._id)"
                    class="mr-2"
                    >mdi-folder-cancel</v-icon
                  >
                </template>
                <template v-if="item.estatus == 'Finalizado'">
                  <v-icon
                    color="success"
                    @click="activarContrato(item._id)"
                    class="mr-2"
                    >mdi-folder-check</v-icon
                  >
                </template>
                <template v-if="item.estatus == 'Pendiente'">
                  <v-icon
                    color="orange"
                    @click="activarContrato(item._id)"
                    class="mr-2"
                    >mdi-folder-check</v-icon
                  >
                </template>
              </template>
              <template v-slot:[`item.estatus`]="{ item }">
                <v-chip
                  v-if="item.estatus == 'Activo'"
                  class="ma-2"
                  color="green"
                  text-color="white"
                  small
                >
                  {{ item.estatus }}
                </v-chip>
                <v-chip
                  v-if="item.estatus == 'Pendiente'"
                  class="ma-2"
                  color="orange"
                  text-color="white"
                  small
                >
                  {{ item.estatus }}
                </v-chip>
                <v-chip
                  v-if="item.estatus == 'Finalizado'"
                  class="ma-2"
                  color="red"
                  text-color="white"
                  small
                >
                  {{ item.estatus }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import CosteoApi from "../../api/Costeo";
export default {
  data() {
    return {
      headers: [
        { text: "Actions", value: "action", sortable: false },
        {
          text: "Numero de Contrato",
          value: "numero_contrato",
          sortable: true,
        },
        { text: "Montro de Contrato", value: "monto_contrato", sortable: true },
        { text: "Plazo del Contrato", value: "plazo_contrato", sortable: true },
        {
          text: "Inicio del contrato",
          value: "inicio_contrato",
          sortable: true,
        },
        { text: "Fin del contrato", value: "fin_contrato", sortable: true },
        { text: "Estatus", value: "estatus", sortable: true },
      ],

      search: "",
      dialogNuevaEmpresa: false,
      estatus: ["Finalizado", "Activo", "Pendiente"],
      estatu: "",
      editedIndex: -1,
      contratos: [],
      submited: "",
      numero_contrato: "",
      monto_contrato: "",
      concepto: "",
      inicio_contrato: "",
      plazo: "",
      fin_contrato: "",
      options: {
        locale: "en-US",
        prefix: "$",
        suffix: "",
        length: 11,

        company_logo: {
          w: 110,
          h: 35,
        },

        precision: 2,
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Contrato" : "Editar Contrato";
    },
  },
  created() {
    this.listar();
  },
  methods: {
    listar() {
      //     let me = this;
      CosteoApi.obtenerContratos()
        .then((res) => {
          this.contratos = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    limpiar() {
      (this.numero_contrato = ""),
        (this.monto_contrato = ""),
        (this.plazo = ""),
        (this.inicio_contrato = "");
      this.fin_contrato = "";
      this.editedIndex = -1;
    },
    guardar() {
      let me = this;
      let datos = {
        numero_contrato: this.numero_contrato,
        monto_contrato: this.monto_contrato,
        plazo_contrato: this.plazo,
        inicio_contrato: this.inicio_contrato,
        fin_contrato: this.fin_contrato,
        estatus: this.estatu,
      };
      CosteoApi.insertarContratos(datos)
        .then(() => {
          me.listar();
          me.limpiar();
          me.dialogNuevaEmpresa = false;
          me.addSuccessNotification("Contrato agregado");
          me.limpiar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    finalizarContrato(item) {
      let me = this;
      let datos = { _id: item };
      CosteoApi.finalizarContrato(datos).then(() => {
        me.listar();
        me.addSuccessNotification("Contrato finalizado");
      });
    },
    activarContrato(item) {
      let me = this;
      let datos = { _id: item };
      CosteoApi.activarContrato(datos).then(() => {
        me.listar();
        me.addSuccessNotification("Contrato activo");
      });
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    cerrarModalEmpresa() {
      this.dialogNuevaEmpresa = false;
      this.limpiar();
      this.editedIndex = -1;
    },
  },
};
</script>
