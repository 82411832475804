<template>
  <div>
    <div class="background">
      <router-view>

      </router-view>
    </div>

  </div>
</template>

<script>
export default {
  name: 'App',

};

</script>

<style></style>
