import { render, staticRenderFns } from "./DashboardActivos.vue?vue&type=template&id=33567ae4&scoped=true"
import script from "./DashboardActivos.vue?vue&type=script&lang=js"
export * from "./DashboardActivos.vue?vue&type=script&lang=js"
import style0 from "./Dashboard.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./DashboardActivos.vue?vue&type=style&index=1&id=33567ae4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33567ae4",
  null
  
)

export default component.exports