<template>
  <v-navigation-drawer app clipped v-if="logueado" v-model="DRAWER_STATE" :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth" :permanent="$vuetify.breakpoint.mdAndUp" :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth" :class="{ 'drawer-mini': !DRAWER_STATE }">

    <div class="ml-2 ">

      <v-list-item-avatar size="62" v-if="$store.state.usuario.personal.empresa == '6207fa7a7422680c1e59051f'">
        <v-img src="https://i.ibb.co/M511VRy/user-1.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-avatar v-if="$store.state.usuario.personal.empresa == '6207f8cb7422680c1e59051c'">
        <v-img src="https://i.ibb.co/qpqR5XQ/AMLS-logo-color.png" alt="AMLS-logo-color"></v-img>
      </v-list-item-avatar>
    </div>
    <div class="ml-2 mr-2 mb-4">
      <strong style="font-size: 13px" class="text-justify">{{
        $store.state.usuario.personal.nombreCompleto
      }}</strong>
      <p style="font-size: 11px" class="font-weight-light">
        {{ $store.state.usuario.personal.correo }}
      </p>
    </div>
    <v-divider></v-divider>

    <v-list dense>
      <v-list-item :to="'/dashboard'">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>

    <v-list dense v-if="esRoot">
      <v-list-item :to="'/dashboard_mtto'">
        <v-list-item-icon>
          <v-icon>mdi-wrench-clock</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard Mtto</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>

    <v-list dense v-if="esRoot">
      <v-list-item :to="'/dashboard_costeo'">
        <v-list-item-icon>
          <v-icon>mdi-monitor-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard Costeo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense>
      <v-list-item :to="'/trabajos_mtto'" v-if="esRoot || esJefeDeMantenimiento || esMtto">
        <v-list-item-icon>
          <v-icon>mdi-hammer-screwdriver</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Trabajos de Mtto</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
    <v-divider></v-divider>
    <v-list dense v-if="esRoot || esJefeDeMantenimiento || esCapturista || esActivosFijos">
      <v-list-item :to="'/activos'">
        <v-list-item-icon>
          <v-icon color="blue">mdi-car</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Activos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot || esCapturista || esActivosFijos">
      <v-list-item :to="'/Ose'">
        <v-list-item-icon>
          <v-icon color="blue">mdi-car-arrow-right</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Salida de Equipos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot || esUsuario || esCapturista || esActivosFijos">
      <v-list-item :to="'/scan'">
        <v-list-item-icon>
          <v-icon color="blue">mdi-qrcode-scan</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Scan</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot || esActivosFijos || esCapturista">
      <v-list-item :to="'/resguardos'">
        <v-list-item-icon>
          <v-icon color="blue">mdi-clipboard-edit</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Resguardos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- <v-list dense v-if="esMtto">
      <v-list-item :to="'/mtto_preventivo'">
        <v-list-item-icon>
          <v-icon color="red">mdi-tools</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Mtto Preventivo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->
    <!-- <v-list dense v-if="esMecanico || esRoot">
      <v-list-item :to="'/seguimiento_fallas'">
        <v-list-item-icon>
          <v-icon color="red">mdi-account-hard-hat</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Mecanicos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->

    <!-- <v-list dense v-if="esMtto || esJefeDeMantenimiento || esRoot">
      <v-list-item :to="'/equipo_estatus'">
        <v-list-item-icon>
          <v-icon color="red">mdi-pier-crane</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Equipos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->
    <v-list dense v-if="esMtto || esRoot">
      <v-list-item :to="'/reporte_fallas'">
        <v-list-item-icon>
          <v-icon color="red">mdi-alert-octagon</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Reporte de Fallas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense v-if="esJefeDeMantenimiento || esRoot">
      <v-list-item :to="'/reporte_fallas_mtto'">
        <v-list-item-icon>
          <v-icon color="red">mdi-alert-octagon</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Reporte de Fallas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esJefeDeMantenimiento || esRoot">
      <v-list-item :to="'/os_mtto'">
        <v-list-item-icon>
          <v-icon color="red">mdi-file-sign</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Ordenes de Trabajo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esJefeDeMantenimiento || esRoot">
      <v-list-item :to="'/mecanicos'">
        <v-list-item-icon>
          <v-icon color="red">mdi-account-hard-hat</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Mecanicos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- <v-list dense v-if="esMtto || esJefeDeMantenimiento || esRoot">
      <v-list-item :to="'/reporte_fallas_lista'">
        <v-list-item-icon>
          <v-icon color="red">mdi-folder-cog-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Bitacora de Fallas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->

    <v-list dense v-if="esMecanico || esRoot">
      <v-list-item :to="'/pantalla_reportes'">
        <v-list-item-icon>
          <v-icon color="red">mdi-television</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Reportes de Fallas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot || esUsuario || esPemex">
      <v-list-item :to="'/estimaciones'">
        <v-list-item-icon>
          <v-icon color="green">mdi-file-sign</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Estimaciones</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot || esUsuario || esPemex">
      <v-list-item :to="'/costeo_prorrateado'">
        <v-list-item-icon>
          <v-icon color="green">mdi-file-document-plus-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Costeo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot || esUsuario || esPemex">
      <v-list-item :to="'/costeo'">
        <v-list-item-icon>
          <v-icon color="green">mdi-cash-multiple</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Prorrateo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot || esPemex">
      <v-list-item :to="'/trazabilidad'">
        <v-list-item-icon>
          <v-icon color="green">mdi-chart-timeline-variant</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Trazabilidad</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot || esPemex">
      <v-list-item :to="'/historial_prorrateo'">
        <v-list-item-icon>
          <v-icon color="green">mdi-history</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Historial Prorrateo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot || esPemex">
      <v-list-item :to="'/teb'">
        <v-list-item-icon>
          <v-icon color="green">mdi-alarm-panel-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Teb</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list dense v-if="esRoot">
      <v-list-item :to="'/empresa'">
        <v-list-item-icon>
          <v-icon color="blue">mdi-domain</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Empresas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot || esActivosFijos || esCapturista">
      <v-list-item :to="'/ubicacion'">
        <v-list-item-icon>
          <v-icon color="blue">mdi-google-maps</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Ubicaciones</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot">
      <v-list-item :to="'/proveedor'">
        <v-list-item-icon>
          <v-icon color="blue">mdi-account-hard-hat</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Proveedores</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense v-if="esRoot || esActivosFijos || esCapturista">
      <v-list-item :to="'/departamentos'">
        <v-list-item-icon>
          <v-icon color="blue">mdi-account-group</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Departamentos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot || esActivosFijos || esCapturista">
      <v-list-item :to="'/personal'">
        <v-list-item-icon>
          <v-icon color="blue">mdi-account-plus</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Personal</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense v-if="esRoot || esPemex">
      <v-list-item :to="'/partidas'">
        <v-list-item-icon>
          <v-icon color="blue">mdi-format-list-numbered</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Partidas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense v-if="esRoot">
      <v-list-item :to="'/horometro'">
        <v-list-item-icon>
          <v-icon color="red">mdi-hours-24</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Horometro</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense v-if="esRoot || esPemex">
      <v-list-item :to="'/os'">
        <v-list-item-icon>
          <v-icon color="red">mdi-account-wrench</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Ordenes de Servicio</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>



    <v-list dense v-if="esRoot">
      <v-list-item :to="'/importar'">
        <v-list-item-icon>
          <v-icon color="black">mdi-upload</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Importar</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-if="esRoot">
      <v-list-item :to="'/usuarios'">
        <v-list-item-icon>
          <v-icon color="black">mdi-account-reactivate</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Usuarios</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense v-if="esRoot || esPemex">
      <v-list-item :to="'/contrato'">
        <v-list-item-icon>
          <v-icon color="black">mdi-file-sign</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Contrato</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    source: String,
  },
  data() {
    return {
      personal: [],
      sidebarWidth: 240,
      sidebarMinWidth: 96,
    };
  },
  computed: {
    ...mapState(["drawer"]),
    DRAWER_STATE: {
      get() {
        return this.drawer;
      },
      set(newValue) {
        if (newValue === this.drawer) return;
        this.TOGGLE_DRAWER();
      },
    },
    logueado() {
      return this.$store.state.usuario;
    },
    esRoot() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "root";
    },
    esUsuario() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "usuario";
    },
    esCapturista() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "capturista";
    },
    esPemex() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "pemex";
    },
    esMtto() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "mtto";
    },
    esActivosFijos() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "activos";
    },
    esJefeDeMantenimiento() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "jefe_mtto";
    },
    esMecanico() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "mecanico";
    },
  },

  created() {
    this.$store.dispatch("autoLogin");
  },
  methods: {
    ...mapActions(["TOGGLE_DRAWER"]),
  },
};
</script>

<style src="./Sidebar.scss" lang="scss" />
<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

.texto {
  font-family: 'Raleway', sans-serif;
}
</style>