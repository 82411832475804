<template>
  <v-container fluid>
    <!-- Dialog Tipo Unidad -->
    <v-dialog v-model="dialogTipoDeUnidad" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Nuevo tipo de unidad</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Tipo de Unidad"
                  required
                  v-model="tipoUnidad"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogTipoDeUnidad = false">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text @click="guardarTipoUnidad">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Componente -->
    <v-dialog v-model="dialogComponente" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Nuevo Componente</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Componente"
                  required
                  v-model="componente"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogComponente = false">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text @click="guardarComponente">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="tables-basic">
      <br />
      <h1 class="page-title mt-11 mb-6">AMEF</h1>
    </div>
    <v-card class="mx-auto">
      <v-toolbar color="primary" dark>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="mostrarNuevo()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <v-list subheader two-line>
        <!--     <v-list-item v-for="(mecanico, index) in mecanicos" :key="mecanico._id">
          <v-list-item-avatar>
            <v-icon class="success" dark> mdi-account-hard-hat </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ mecanico.nombreMecanico }}</v-list-item-title>
            <v-list-item-subtitle>{{
              mecanico.categoria
            }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ mecanico.pin }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text
              v-text="index + 1"
            ></v-list-item-action-text>
            <v-btn icon>
              <v-icon @click="editItem(mecanico)" color="grey lighten-1"
                >mdi-pencil</v-icon
              >
              <v-icon @click="dialogMecanicoBaja = true" color="grey lighten-1"
                >mdi-account-cancel</v-icon
              >
            </v-btn>
          </v-list-item-action>
        </v-list-item> -->
      </v-list>
    </v-card>

    <v-sheet>
      <v-navigation-drawer
        :width="525"
        v-model="drawer"
        app
        clipped
        inset
        persistent
        right
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              ><span><h4>AMEF</h4></span></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-row class="pa-4">
          <v-col cols="6">
            <p class="font-weight-light" style="margin-bottom: -10px">
              Tipo de Equipo
            </p>
            <v-select :items="listaTipoUnidadSelect" v-model="nombreMecanico">
              <v-icon
                @click="dialogTipoDeUnidad = true"
                slot="append"
                color="success"
              >
                mdi-plus
              </v-icon></v-select
            >
          </v-col>
          <v-col cols="6">
            <p class="font-weight-light" style="margin-bottom: -10px">
              Componente
            </p>
            <v-select :items="listarComponenteSelect" v-model="nombreMecanico">
              <v-icon
                @click="dialogComponente = true"
                slot="append"
                color="success"
              >
                mdi-plus
              </v-icon></v-select
            >
          </v-col>
          <v-col cols="12">
            <v-text-field
              style="margin-top: -10px"
              label="Estandar de Funcionamiento"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-select
              style="margin-top: -10px"
              label="Funcion"
              :items="funcion"
            >
            </v-select>
          </v-col>

          <v-col cols="6">
            <v-text-field style="margin-top: -10px" label="Falla Funcional">
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field style="margin-top: -10px" label="Modo de Falla">
            </v-text-field>
          </v-col>

          <v-col cols="6">
            <v-select
              style="margin-top: -10px"
              label="Frecuencia de Falla por Año"
              v-model="frecuenciaPorAnio"
              :items="frecuencia"
            >
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              style="margin-top: -10px"
              label="Severidad"
              v-model="severidad"
              :items="frecuencia"
            >
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              style="margin-top: -10px"
              label="Deteccion"
              v-model="deteccion"
              :items="frecuencia"
            >
            </v-select>
          </v-col>
          <v-col cols="6" v-if="frecuenciaPorAnio && deteccion && frecuencia">
            <v-text-field
              style="margin-top: -10px"
              v-model="calculo"
              label="Nivel de Riesgo"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-navigation-drawer>
    </v-sheet>
    <v-dialog v-model="dialogMecanicoBaja" persistent max-width="490">
      <v-card>
        <v-card-title class="text-h5"> ¿Desactivar al mecanico? </v-card-title>
        <v-card-text>Seguro que decea dar de baja a este mecanico?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogMecanicoBaja = false"
          >
            Salir
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialogMecanicoBaja = false"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
  <script>
import moment from "moment";
import axios from "axios";
import apiCatalogo from "../../api/Catalogo";
export default {
  name: "app",

  data() {
    return {
      nombreMecanico: "",
      categoriaMecanico: "",
      dialogGenerarFallas: false,
      e1: 1,
      moment,
      dialogTipoDeUnidad: false,
      dialogComponente: false,
      componente: "",
      drawer: false,
      mostrarDetalleDeActivo: false,
      tipoUnidad: "",
      activoPorQuery: [],
      listaTipoUnidadSelect: [],
      listarComponenteSelect: [],
      funcion: ["Funcion Primaria", "Funcion Secundaria"],
      frecuencia: [1, 2, 3],
      cycle: true,
      id: "",
      dialogCancelar: false,
      fechaFalla: "",
      buscarEquipo: "",
      buscar: "",
      otraFalla: "",
      editedIndex: false,
      descripcion: "",
      operador: "",
      mecanicos: [],
      reportes: [],
      motivo_cancelacion: "",
      datosReporteFalla: [],
      mostrarMecanico: false,
      dialogMecanicoBaja: false,
      mecanicosActivos: [],
      nivel_riesgo: "",
      severidad: "",
      deteccion: "",
      frecuenciaPorAnio: "",
      calculoAmef: "",
      frecuencia_falla_anio: "",
    };
  },
  computed: {
    calculo() {
      return this.frecuenciaPorAnio * this.severidad * this.deteccion;
    },
  },
  created() {
    this.listar();
    this.listarTipoUnidadSelect();
    this.listarComponentes();
  },
  methods: {
    limpiar() {
      this.nombreMecanico = "";
      this.categoriaMecanico = "";
    },

    editItem(item) {
      this.editedIndex = true;
      this.drawer = true;
      this.id = item._id;
      this.nombreMecanico = item.nombreMecanico;
      this.categoriaMecanico = item.categoria;
    },

    mostrarNuevo() {
      this.drawer = true;
      this.editedIndex = false;
      this.limpiar();
    },
    ocultarNuevo() {},
    salir() {
      this.drawer = false;
    },

    guardarTipoUnidad() {
      let me = this;
      let datos = {
        tipoUnidad: this.tipoUnidad,
      };
      apiCatalogo
        .insertarTipoEquipo(datos)
        .then(() => {
          (me.dialogTipoDeUnidad = false),
            me.addSuccessNotification("Tipo de unidad ingresado correctamente");
          me.listarTipoUnidadSelect();
        })
        .catch(() => {
          me.addErrorNotification();
        });
    },
    guardarComponente() {
      let me = this;
      let datos = {
        componente: this.componente,
      };
      apiCatalogo
        .insertarComponente(datos)
        .then(() => {
          (me.dialogComponente = false),
            me.addSuccessNotification("Componente ingresado correctamente");
          me.listarComponentes();
        })
        .catch(() => {
          me.addErrorNotification();
        });
    },

    listar() {
      let me = this;
      axios
        .get("/mecanico/list")
        .then(function (response) {
          me.mecanicos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    listarTipoUnidadSelect() {
      let me = this;
      apiCatalogo
        .obtenerTipoUnidadSelect()
        .then((res) => {
          me.listaTipoUnidadSelect = res.data;
        })
        .catch(() => {
          me.addErrorNotification();
        });
    },

    listarComponentes() {
      let me = this;
      apiCatalogo
        .obtenerComponenteSelect()
        .then((res) => {
          me.listarComponenteSelect = res.data;
        })
        .catch(() => {
          me.addErrorNotification();
        });
    },

    guardar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      if (this.editedIndex == true) {
        axios
          .put(
            "/mecanico/update",
            {
              _id: this.id,
              nombreMecanico: this.nombreMecanico,
              categoria: this.categoriaMecanico,
            },
            configuracion
          )
          .then(function () {
            me.drawer = false;
            //  me.dialog=false

            me.listar();
            me.addSuccessNotification("Datos editados correctamente");
            me.editedIndex = false;
          })
          .catch(function (error) {
            console.log(error);
            me.addErrorNotification();
          });
      } else {
        axios
          .post(
            "/mecanico/add",
            {
              nombreMecanico: this.nombreMecanico,
              categoria: this.categoriaMecanico,
              pin: Math.floor(100000 + Math.random() * 900000),
            },
            configuracion
          )
          .then(function () {
            me.drawer = false;
            me.listar();
            me.addSuccessNotification("Datos ingresados correctamente");
          })
          .catch(function (error) {
            console.log(error);
            me.addErrorNotification();
          });
      }
    },

    deactivate(id) {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };

      axios
        .post(
          "/mecanico/inactivar",
          {
            _id: id,
          },
          configuracion
        )
        .then(function () {
          me.drawer = false;
          me.listar();
          me.addSuccessNotification("Datos ingresados correctamente");
        })
        .catch(function (error) {
          console.log(error);
          me.addErrorNotification();
        });
    },

    selectMecanicos() {
      let me = this;
      let mecaniccArray = [];
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/mecanico/listActivos", configuracion)
        .then((response) => {
          mecaniccArray = response.data;
          mecaniccArray.map(function (x) {
            me.mecanicosActivos.push({ text: x.nombreMecanico, value: x._id });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
};
</script>
  <style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.Revision {
  border-left: 4px solid #3cd1c2;
}
.sin_asignar {
  border-left: 4px solid orange;
}
.Pendiente {
  border-left: 4px solid black;
}
.Cancelado {
  border-left: 4px solid #f83e70;
}
.app_more {
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
}
.app_more:hover {
  text-decoration: underline;
}
.app_more,
.app_more:active {
  color: #3cd1c2;
}
.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid rgb(123, 90, 243);
  border-top-color: #fff;
  position: absolute;
  bottom: 0;
  left: calc(50%-20px);
  animation: spinner-animation 1.2s linear infinite;
}
.tebs.Revision {
  border-left: 4px solid orange;
}
.tebs.Finalizado {
  border-left: 4px solid rgb(123, 90, 243);
}
.v-chip.Activo {
  background: #f83e70;
  color: black;
}
#chips-container .v-chip.activo {
  color: white;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>