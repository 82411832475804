<template>
     <v-container fluid>
    <div class="tables-basic"><br><br><br>
     
         <v-card
  
    class="mx-auto"
  >
    <v-toolbar
      color="primary"
      dark
    >

      <v-toolbar-title>Mantenimiento Preventivo</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon @click="mostrarBuscar">mdi-magnify</v-icon>
      </v-btn>
       <v-btn icon>
        <v-icon @click="dialog=true">mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

      <v-list-item
        v-for="item in mttoPreventivos"
        :key="item._id"
      >
        <v-list-item-avatar>
          <v-icon v-if="item.equipo.estatu == 'Operativo'"
            class="success"
            dark
          >
            mdi-pier-crane
          </v-icon>
           <v-icon v-if="item.equipo.estatu == 'Fuera de Servicio'"
            class="red"
            dark
          >
            mdi-pier-crane
          </v-icon>
         
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="item.equipo.numeroEconomico"></v-list-item-title>
          <v-list-item-subtitle><span>Proximo Mantenimiento: </span>{{moment(item.proximoMantenimiento).add(1,'days').format('MMMM Do YYYY')}}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon>
            <v-icon @click="editItem(item)" color="grey lighten-1">mdi-information</v-icon>
          </v-btn>
         
        </v-list-item-action>
        
      </v-list-item>

     <v-col
          cols="12"
          sm="12"
          md="12"
          v-if="searchEquipo"
        >
          <v-text-field
          @input="listar"
            v-model="buscar"
            label="Buscar"
          ></v-text-field>
        </v-col>
  </v-card>
    </div>
     
     <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Equipo </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
             @click="guardar"
            >
              Guardar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card
    class="mx-auto"
   
  >
    <v-card-title class="text-h6 font-weight-regular justify-space-between">
      <span>{{ currentTitle }}</span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="24"
        v-text="step"
      ></v-avatar>
    </v-card-title>

    <v-window v-model="step">
      <v-window-item :value="1">
        <v-card-text>
            <v-autocomplete
              v-model="equipo"
              :items="equiposActivos"
              label="Equipos"
              @input="listarEquipo"
            ></v-autocomplete>
          <span class="text-caption grey--text text--darken-1">
            Seleccionar el numero economico del equipo a registrar el programa de mantenimiento preventivo
          </span>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="2">
        <v-card-text>
          
          <span class="text-caption grey--text text--darken-1">
            Ingrese los datos solicitados
          </span>
          <v-menu
        ref="menuUltimoMtto"
        v-model="menuUltimoMtto"
        :close-on-content-click="false"
        :return-value.sync="ultimo_mantenimiento"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="ultimo_mantenimiento"
            label="Ultimo Mantenimiento"
         
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="ultimo_mantenimiento"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menuUltimoMtto = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="primary"
          @click="$refs.menuUltimoMtto.save(ultimo_mantenimiento)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
       <v-menu
        ref="menuProximoMtto"
        v-model="menuProximoMtto"
        :close-on-content-click="false"
        :return-value.sync="proximo_mantenimiento"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="proximo_mantenimiento"
            label="Proximo Mantenimiento"
         
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="proximo_mantenimiento"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menuProximoMtto = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="primary"
             @click="$refs.menuProximoMtto.save(proximo_mantenimiento)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="3">
        <div class="pa-4">
          <v-row>
              <v-col cols="8">
            <v-text-field v-model="falla" label="Fallas a prevenir"></v-text-field>
          </v-col>
           <v-col cols="4">
            <v-btn @click="agregarFalla" class="mx-2" fab dark color="indigo">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
          </v-row>
          
          <v-col sm="12" lg="12">
            <v-list dense>
              <v-subheader>Fallas</v-subheader>
              <v-list-item-group
                color="primary"
              >
                <v-list-item
                  v-for="(item, i) in fallas"
                  :key="i"
                >
               
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </div>
      </v-window-item>
    </v-window>

    <v-divider></v-divider>
      
    <v-card-actions>
      <v-btn
        :disabled="step === 1"
        text
        @click="step--"
      >
        Regresar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="step === 3"
        color="primary"
        depressed
        @click="step++"
      >
        Siguiente
      </v-btn>
    </v-card-actions>
    
  </v-card>
     <v-card
      v-if="mostrarDetallesActivo"
    class="mx-auto"
    
  >
    <v-img
      :src="equiposActivosLista.fotos[0]"
      height="300px"
      dark
    >
      <v-row class="fill-height">
       

        <v-spacer></v-spacer>

        <v-card-title class="white--text pl-12 pt-12">
          <div class="text-h6 pl-12 pt-12">
            {{equiposActivosLista.nombreActivo}}
          </div>
        </v-card-title>
      </v-row>
    </v-img>

    <v-list two-line>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-folder-information-outline
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{equiposActivosLista.numeroEconomico}}</v-list-item-title>
          <v-list-item-subtitle>{{equiposActivosLista.tipoUnidad}}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon>mdi-file-pdf-box</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-list-item>
        <v-list-item-action></v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{equiposActivosLista.marca}}</v-list-item-title>
          <v-list-item-subtitle>{{equiposActivosLista.modelo}}</v-list-item-subtitle>
        </v-list-item-content>

        
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-numeric
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Numero de Serie</v-list-item-title>
          <v-list-item-subtitle>{{equiposActivosLista.numeroSerie}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action></v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>Placa</v-list-item-title>
          <v-list-item-subtitle>{{equiposActivosLista.placa}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

        
    </v-list>
  </v-card>
  
        
      </v-card>
    </v-dialog>
  </v-row>
     </v-container>
</template>

<script>
import axios from 'axios'
import {required} from "vuelidate/lib/validators"
import moment from 'moment'
export default {
    validations:{
        razonSocial:{
            required
        },
    
        arrendadora:{
            required,
        },
     
    },
    data(){
        return{
          moment:moment,
              dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        id:'',
        fallas:[],
        falla:'',
            arrendadoras:[],
            mttoPreventivos:[],
            search:'',
            nombreEquipo:'',
            menuUltimoMtto:false,
            menuProximoMtto:false,
            proximo_mantenimiento:'',
            ultimo_mantenimiento:'',
            dateMenu:false,
            searchEquipo:false,
            dialogNuevaArrendadora:false,
            razonSocial:'',
            inicio_operacion:'',
            horas_promedio:'',
            step: 1,
            arrendadora:'',
            equipo:'',
            horasProm:'',
            buscar:'',
            datosEquipo:[],
            foto:'',
            equiposActivosLista:[],
            mostrarDetallesActivo:false,
            equiposActivos:[],
             editedIndex: -1,
            nombreComercial:'',
            representante:'',
            submited:'',
            alert:'',
            
        }
    },
    computed: {
        formTitle() {
        return this.editedIndex === -1 ? "Nueva Arrendadora" : "Editar Arrendadora";
        },
        currentTitle () {
        switch (this.step) {
          case 1: return 'Seleccionar Equipo'
          case 2: return 'Fecha de Mantenimiento'
          default: return 'Fallas a prevenir'
        }
      }
    },
    created(){
        this.listar()
        this.mostrarActivos()
    },
    methods:{
        listar(){
             let me = this;
             let header={"Token":this.$store.state.token};
             
            let configuracion= {headers: header}
               axios.get('/mttoPreventivo/list',configuracion).then(function (response){
                    me.mttoPreventivos = response.data 
                   
                    }).catch(function(){
                    
                    })  
        },
        listarEquipo(){
          this.mostrarDetallesActivo=true
           let me = this;
             let header={"Token":this.$store.state.token};
             
            let configuracion= {headers: header}
                axios.get('/activos/query?_id='+this.equipo,configuracion).then(function (response){
                    me.equiposActivosLista = response.data 
                    }).catch(function(){
                    
                    })  
        },
         guardar(){
            let me = this
            let header={"Token":this.$store.state.token};
            let configuracion= {headers: header}
            axios.post('/mttoPreventivo/add',{'equipo':this.equipo,'ultimoMantenimiento':this.ultimo_mantenimiento,'proximoMantenimiento':this.proximo_mantenimiento,'fallas':this.fallas},configuracion)
            .then(function(){
                me.mostrarDetallesActivo=false
                me.dialog=false
                me.listar()
                me.addSuccessNotification('Mantenimiento preventivo ingresado correctamente')
            })
            .catch(function(error){
                console.log(error)
                me.addErrorNotification()
            })
        },

         mostrarActivos(){
             let me = this;
             let header={"Token":this.$store.state.token};
             let equiposActivos=[];
            let configuracion= {headers: header}
                axios.get('/activos/listActivosPemex',configuracion).then(function (response){
                    equiposActivos = response.data 
                    
                    equiposActivos.map((x)=>{
                        me.equiposActivos.push({text: x.nombreActivo+' '+x.numeroEconomico, value:x._id}) 
                    })
                    
                    
                    }).catch(function(){
                    
                    })  
        },
        limpiar(){
            this.arrendadora='',
            this.razonSocial=''
        },

        agregarFalla(){
            this.fallas.push(this.falla)
        },

        mostrarBuscar(){
            if(this.searchEquipo){
                this.searchEquipo = false
            }else if(this.searchEquipo==false){
                this.searchEquipo = true
            }
        },

        editItem(item){
            this.dialog = true;
            this.id = item._id
            this.foto = item.fotos[0]
            this.nombreEquipo = item.nombreActivo
            this.datosEquipo = item
            this.horas_promedio = item.horas_promedio
            this.inicio_operacion = item.inicio_operacion
        },

     
     
        addSuccessNotification(mensaje) {
                this.$toast.success(mensaje, {
                position: 'top-right',
                timeout: 2000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true
            });
        },
         addErrorNotification() {
                this.$toast.error("Error, verifique los campos ingresados", {
                position: 'top-right',
                timeout: 2000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true
            });   
        },
        cerrarModalArrendadora(){
            this.dialogNuevaArrendadora =false;
            this.limpiar()
            this.editedIndex = -1
        }

    }
}
</script>
