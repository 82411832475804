<template>
  <v-container fluid>
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Por favor espere
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogGuardarTrazabilidad" persistent max-width="490">
      <v-card>
        <v-card-title class="text-h5"> Guardar Registros </v-card-title>
        <v-card-text>
          <b>{{ karen }}</b> registros ingresados por Karen <br />
          <b>{{ kristell }}</b> registros ingresados por Kristell
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogGuardarTrazabilidad = false"
          >
            Salir
          </v-btn>
          <v-btn color="green darken-1" text @click="guardarTrazabilidadMult()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGuardarTrazabilidad" persistent max-width="490">
      <v-card>
        <v-card-title class="text-h5"> Guardar Registros </v-card-title>
        <v-card-text>
         
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogGuardarTrazabilidad = false"
          >
            Salir
          </v-btn>
          <v-btn color="green darken-1" text @click="guardarTrazabilidadMult()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="tables-basic">
      <br /><br /><br /><br />
      <h2 class="mb-5">Trazabilidad</h2>

      <v-row>
        <v-col cols="12">
          <div class="employee-list">
            <v-dialog v-model="dialogAperturaMes" scrollable max-width="500px">
              <v-card>
                <v-card-title>Aperturar mes de contrato</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 180px">
                  <br />
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        v-model="mesServicio"
                        label="Mes"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="mesServicio"
                      type="month"
                      @input="mostrarTepMes"
                    ></v-date-picker>
                  </v-menu>
                  <v-text-field
                    outlined
                    dense
                    v-model="estimacion"
                    label="Numero de estimacion"
                    prepend-icon="mdi-counter"
                    type="number"
                  ></v-text-field>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogAperturaMes = false"
                  >
                    Salir
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="ingresarTrazabilidadIndice()"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <div class="pa-5" v-if="verNuevo == 0">
              <div class="pa-3" v-if="verNuevo == 0">
                <v-btn
                  @click="dialogAperturaMes = true"
                  class="mb-5"
                  rounded
                  color="primary"
                  small
                  dark
                  >Aperturar nuevo mes</v-btn
                >
                <v-container class="my-5">
                  <v-row>
                    <v-col v-for="indT in trazabilidadIndice" :key="indT._id" lg="3" sm="6" md="7" cols="12">
                      <v-card max-width="500" >
                        <v-sheet
                          class="v-sheet--offset mx-auto"
                          color="primary"
                          elevation="12"
                        >
                          <div
                            class="text-h6 font-weight-bold white--text"
                            style="text-align: center"
                          >
                            {{moment(indT.mes).add(1, 'days').format("MMM YYYY")}}
                          </div>
                        </v-sheet>
                        <br />
                        <v-card-text class="pt-0" >
                          <div
                            class="subheading font-weight-light grey--text"
                            style="text-align: center"
                          >
                            <v-card-actions class="justify-center">
                            
                              <v-btn
                                  icon
                                  @click="trazabilidadDet(indT)"
                                  large
                                >
                                  <v-icon size="80" color="textColor">mdi-folder-file</v-icon>
                                </v-btn> 
                              </v-card-actions
                            >
                          </div> 
                          <v-divider class="my-2"></v-divider>
                          <v-icon class="mr-2"> mdi-pencil </v-icon>
                       
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </div>
            <v-dialog
              v-model="dialogVizualisarTrazabilidadImportada"
              width="2000"
            >
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Trazabilidad importadas
                </v-card-title>

                <v-card-text>
                  <v-data-table
                    :headers="headersImportado"
                    :items="trazabilidadsAdjuntosTabla"
                    :items-per-page="5"
                    class="elevation-1"
                  ></v-data-table>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="agregarTrazabilidadImportada()"
                  >
                    Importar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <div v-if="verNuevo">
              <div>
                <v-card-title>{{ formTitle }}</v-card-title>
              </div>
              <div class="pa-5">
                <v-flex xs11 sm11 lg11 x11>
                  <v-sheet>
                    <v-navigation-drawer
                      :width="425"
                      v-model="drawer"
                      app
                      clipped
                      inset
                      persistent
                      right
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >Reporte de Falla</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider></v-divider>
                      <div class="spacing-playground pa-3">
                        <v-row>
                          <v-col cols="6">
                            <p
                              class="font-weight-light"
                              style="margin-bottom: -10px"
                            >
                              Tipo de Falla
                            </p>
                            <v-text-field disabled></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                    </v-navigation-drawer>
                  </v-sheet>
                  <v-row>
                    <v-col cols="12" md="12" lg="2">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            style="margin-top: -30px"
                            outlined
                            dense
                            v-model="mesServicio"
                            label="Mes"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="mesServicio"
                          type="month"
                          @input="mostrarTepMes"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="6" lg="2">
                      <v-autocomplete
                        outlined
                        @input="mostrarFechaTeb"
                        dense
                        :items="tebMesLista"
                        style="margin-top: -30px"
                        v-model="numeroDeSolicitud"
                        label="Numero de Solicitud"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" lg="2">
                      <v-menu
                        v-model="menuServicio"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            style="margin-top: -30px"
                            outlined
                            dense
                            v-model="fechaServicio"
                            label="Fecha de Servicio"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fechaServicio"
                          @input="menuServicio = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <v-autocomplete
                        :items="osList"
                        @input="mostrarEquiposDeOrden(ordenServicio)"
                        style="margin-top: -30px"
                        outlined
                        dense
                        v-model="ordenServicio"
                        label="Ordenes de Servicio"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="6" lg="1">
                      <v-select
                        :items="tipoDeServicio"
                        v-model="tipoDeServicioSelec"
                        style="margin-top: -30px"
                        outlined
                        dense
                        label="Tipo de Servicio"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" lg="2">
                      <v-text-field
                        outlined
                        dense
                        style="margin-top: -30px"
                        v-model="numeroEstimacion"
                        type="number"
                        label="Estimacion"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" lg="2">
                      <v-text-field
                        outlined
                        dense
                        style="margin-top: -30px"
                        v-model="volumen"
                        @input="changeIcon"
                        label="Volumen"
                      />
                    </v-col>
                    <v-col cols="6" lg="1">
                      <v-autocomplete
                        :items="partidasList"
                        style="margin-top: -30px"
                        outlined
                        dense
                        v-model="partida"
                        label="Partida"
                        @input="changeIcon"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" lg="2">
                      <v-select
                        :items="ubicacionesOs"
                        v-model="ubicacion"
                        style="margin-top: -30px"
                        outlined
                        dense
                        label="Ubicación"
                      ></v-select>
                    </v-col>

                    <v-col cols="6" lg="3">
                      <v-autocomplete
                        :items="osEquiposinTraz"
                        style="margin-top: -30px"
                        outlined
                        dense
                        v-model="activoEquipo"
                        label="Equipo"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-combobox
                        v-model="instalacion_destino"
                        outlined
                        dense
                        :items="inst_dest"
                        style="margin-top: -30px"
                        :search-input.sync="search"
                        hide-selected
                        label="Instalación/Destino"
                        persistent-hint
                        small-chips
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No se encontraron resultados "<strong>{{
                                  search
                                }}</strong
                                >". Presiona <kbd>enter</kbd> para crear un
                                nuevo item
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" lg="5">
                      <v-text-field
                        outlined
                        dense
                        style="margin-top: -30px"
                        v-model="descripcion"
                        label="Observaciones"
                      />
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        outlined
                        dense
                        style="margin-top: -30px"
                        v-model="raf"
                        label="RAF"
                      />
                    </v-col>
                    <v-col cols="12" lg="1">
                      <v-text-field
                        outlined
                        dense
                        style="margin-top: -30px"
                        v-model="cantidad"
                        type="number"
                        label="Cantidad"
                      />
                    </v-col>
                    <v-col cols="12" lg="1">
                      <v-btn
                        style="margin-top: -50px"
                        small
                        color="primary"
                        @click="agregarRegistro"
                      >
                        Agregar
                        <template v-slot:loader>
                          <span class="custom-loader">
                            <v-icon light>mdi-cached</v-icon>
                          </span>
                        </template>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-col cols="12" lg="1">
                    <v-btn
                      style="margin-top: -50px"
                      small
                      color="primary"
                      @click="mostrarDialogDelete"
                    >
                      Eliminar Registro
                      <template v-slot:loader>
                        <span>
                          <v-icon>mdi-delete</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-col>
                  <v-row>
                    <v-col cols="12" lg="6" sm="6">
                      <v-file-input
                        v-model="trazabilidadImportDatos"
                        style="margin-top: -30px"
                        label="Adjuntar archivo en formato csv"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" lg="2" sm="2">
                      <v-btn
                        style="margin-top: -30px"
                        small
                        color="primary"
                        @click="obtnerDatosTrazabilidadImportada()"
                      >
                        Importar registros
                      </v-btn>
                    </v-col>
                    <!--    <v-col cols="12" lg="2" sm="2">
                        <v-btn
                          style="margin-top: -30px"
                          small
                          color="primary"
                          @click="limpiarTrazabilidadFull()"
                        >
                          Limpiar
                        </v-btn>
                      </v-col> -->
                    <v-col
                      v-if="trazabilidadsAdjuntosTabla.length > 0"
                      cols="12"
                      lg="2"
                      sm="2"
                    >
                      <v-btn
                        style="margin-top: -30px"
                        small
                        color="primary"
                        @click="vizualizarTrazabilidadImportada()"
                      >
                        Ver registros importados
                      </v-btn>
                    </v-col>
                    <!-- <v-col cols="12" lg="2" sm="2">
                        <v-btn
                          style="margin-top: -30px"
                          small
                          color="primary"
                          @click="agregarTeb()"
                        >
                         Agregar TEB
                        </v-btn>
                        <v-btn
                          style="margin-top: -30px"
                          small
                          color="primary"
                          @click="onUpdate()"
                        >
                        corregirTrazabilidad TEB
                        </v-btn>
                      </v-col> -->
                    <v-col cols="4" lg="4" sm=""> </v-col>
                    <v-col cols="2" lg="2" sm="">
                      <v-switch
                        v-model="modo_edicion"
                        label="Modo Edicion"
                      ></v-switch>
                    </v-col>
                  </v-row>

                  <v-col cols="12" lg="1">
                    <v-row justify="center">
                      <v-dialog
                        v-model="dialogDeleteRecord"
                        persistent
                        max-width="760"
                      >
                        <v-card>
                          <v-card-title class="text-h4">
                            Eliminar registro para el equipo {{ dialogEquipo }}?
                            <h2 v-if="dialogUsuario" style="color: red">
                              Caputarado por {{ dialogUsuario }}
                            </h2>
                          </v-card-title>
                          <v-card-text
                            >Esta a punto de eliminar el registro con los
                            siguientes datos: <br /><strong>Equipo: </strong
                            >{{ dialogEquipo }} <br /><strong>Partida: </strong
                            >{{ dialogPartida }} <br /><strong>Volumen: </strong
                            >{{ dialogVolumen }} <br /><strong>Monto: </strong
                            >{{ dialogMonto }} <br /><strong>Teb: </strong
                            >{{ dialogTeb }}
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="dialogDeleteRecord = false"
                            >
                              Salir
                            </v-btn>
                            <v-btn
                              color="green darken-1"
                              text
                              :disabled="dialogTimer"
                              :loading="dialogTimer"
                              @click="eliminarRegistroFilter2"
                            >
                              Confirmar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </v-col>

                  <v-row>
                    <v-col cols="12" lg="12">
                      <template>
                        <vue-excel-editor
                          filter-row
                          v-model="registroCosteo"
                          @update="onUpdate"
                          ref="grid"
                          @select="onSelect"
                          width="1500px"
                        >
                          <vue-excel-column
                            field="activoEquipo"
                            width="220px"
                            label="Equipo"
                          />
                          <vue-excel-column
                            field="numeroEstimacion"
                            type="number"
                            label="Estimacion"
                          />
                          <vue-excel-column
                            field="partida"
                            type="number"
                            :change="mostrarDatoSelecc"
                            label="Partida"
                          />

                          <vue-excel-column
                            field="volumen"
                            type="number"
                            :change="mostrarDatoSeleccVolumen"
                            label="Volumen"
                            summary="sum"
                          />
                          <vue-excel-column
                            :readonly="true"
                            field="montoEstimado"
                            :to-text="costoToDollar"
                            type="number"
                            label="Monto Estimado"
                            width="110px"
                            summary="sum"
                          />

                          <vue-excel-column
                            field="fechaServicio"
                            type="date"
                            label="Fecha Servicio"
                          />
                          <vue-excel-column
                            field="tipoDeServicioSelec"
                            type="select"
                            :options="tipoDeServicio"
                            label="Tipo S"
                          />
                          <vue-excel-column
                            field="descripcion"
                            label="Observaciones"
                            width="110px"
                          />
                          <vue-excel-column
                            field="ubicacion"
                            type="select"
                            :options="ubicacionesOs"
                            label="Ubicación"
                          />
                          <vue-excel-column
                            field="instalacion_destino"
                            type="select"
                            :options="inst_dest"
                            label="Ubicación/Destino"
                          />
                          <vue-excel-column
                            :readonly="true"
                            field="numeroDeSolicitud"
                            label="Teb"
                          />
                          <vue-excel-column field="ordenServicio" label="OS" />
                          <vue-excel-column field="raf" label="RAF" />
                        </vue-excel-editor>
                      </template>
                    </v-col>
                  </v-row>

                  <br />

                  <v-btn color="primary" text @click="ocultarNuevo()">
                    Salir
                  </v-btn>
                  <!--    <v-btn
                      :disabled="dialogTimer"
                      :loading="dialogTimer"
                      color="primary"
                      text
                      @click="guardar()"
                    >
                      Guardar
                    </v-btn> -->

                  <v-btn
                    :disabled="dialogTimer"
                    :loading="dialogTimer"
                    color="primary"
                    @click="dialogGuardar()"
                  >
                    Guardar
                  </v-btn>
                  <v-btn
                    class="button-fab-1 mx-2"
                    @click="!drawer"
                    dark
                    fab
                    color="green"
                    ><v-icon dark>mdi-plus</v-icon></v-btn
                  >
                  <!--  <v-btn
  
                      color="primary"
                      
                      @click="guardar2()"
                    >
                      Editar
                    </v-btn> -->

                  <v-dialog
                    v-model="dialogTimer"
                    hide-overlay
                    persistent
                    width="300"
                  >
                    <v-card color="primary" dark>
                      <v-card-text>
                        Por favor espere
                        <v-progress-linear
                          indeterminate
                          color="white"
                          class="mb-0"
                        ></v-progress-linear>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Catalogo from "../../api/Catalogo";
import Costeo from "../../api/Costeo";
export default {
  data() {
    return {
      moment: moment,
      headers: [
        { text: "Actions", value: "action", sortable: false },
        { text: "Tipo de Unidad", value: "tipoUnidad", sortable: true },
        { text: "Numero Serie", value: "numeroSerie", sortable: true },
        { text: "Marca", value: "marca", sortable: true },
        { text: "Modelo", value: "modelo", sortable: true },
        { text: "Activo", value: "nombreActivo", sortable: true },
        {
          text: "Empresa",
          value: "ubicacion.empresa.nombreComercial",
          sortable: true,
        },
        { text: "Resguardo", value: "personal.nombreCompleto", sortable: true },
        {
          text: "Departamento",
          value: "personal.departamento.departamento",
          sortable: true,
        },
        { text: "Fotos", value: "fotosAdjuntos", sortable: true },
        { text: "Documentos", value: "doc", sortable: true },
        { text: "Estatus", value: "estatu", sortable: true },
      ],
      headersImportado: [
        { text: "Estimacion", value: "numeroEstimacion", sortable: false },
        {
          text: "Numero de Solicitud",
          value: "numeroDeSolicitud",
          sortable: true,
        },
        {
          text: "Instalacion/Destino",
          value: "instalacion_destino",
          sortable: true,
        },
        { text: "Equipo", value: "activoEquipo", sortable: true },
        {
          text: "Tipo de Servicio",
          value: "tipoDeServicioSelec",
          sortable: true,
        },
        { text: "Volumen", value: "volumen", sortable: true },
        { text: "Partida", value: "partida", sortable: true },
        { text: "Monto Estimado", value: "montoEstimado", sortable: true },
        { text: "Fecha de Servicio", value: "fechaServicio", sortable: true },
        { text: "Descripcion", value: "descripcion", sortable: true },
        { text: "Orden de Servicio", value: "ordenServicio", sortable: true },
        { text: "PP", value: "programaPresupuestal", sortable: true },
        { text: "Activo", value: "activoPemex", sortable: true },
        { text: "RAF", value: "raf", sortable: true },
        { text: "usuarioCreador", value: "usuarioCreador", sortable: true },
      ],
      descripcion: "",
      loading: false,
      dialogDeleteRecord: false,
      partidasList: [],
      trazabilidadLista: [],
      dialogTimer: false,
      campoSeleccionadoPrueba: [],
      raf: "",
      osList: [],
      dialogEquipo: "",
      dialogVolumen: "",
      dialogPartida: "",
      dialogUsuario: "",
      dialogMonto: "",
      dialogFecha: "",
      dialogTeb: "",
      osEquiposinTraz: [],
      loader: null,
      campoSeleccionado: "",
      epep: "",
      ubicacionesOs: [],
      usuarioCreadorTrazabilidad: "",
      activoPemex: "",
      showDeleteAction: false,
      search: "",
      programaPresupuestalSelec: "",
      tipoDeServicioSelec: "",
      equiposActivosLista: [],
      mostrarTabla: false,
      hover: false,
      nombreContrato: "INTEGRAL EQ. TERRESTRE DB",
      inst_dest: [
        "MONOBOYAS",
        "PROCESO",
        "REMANENTE",
        "LOGISTICA",
        "INSPECCION TUBULAR",
        "URREP",
        "PLANTA DE LODOS",
        "SEYBAPLAYA",
        "ÁREA DE CAJAS",
        "TALLER DE ÁRBOLES",
        "VALVULAS",
        "COSECO",
        "PATRIMONIAL",
        "REMANENTE 1",
        "TALLER DE MANTENIMIENTO LITORAL",
        "TALLER DE MANTENIMIENTO LITORAL",
        "VENTAS",
        "ALMACEN GENERAL",
        "PROCESOS",
        "TANQUES 1",
        "TANQUES 2",
        "CD. PEMEX",
        "CENTRO DE ATENCIÓN A EVACUADOS POR HURACANES",
      ],
      partida: "",
      ordenServicio: "",
      activoCosteo: "",
      cantidad: "",
      partidas: [],
      epepSelecc: "",
      costoPartida: "",
      registroCosteo: [],
      programasPressLista: [],
      programaPress: "",
      costo: "",
      costoUnitario: "",
      instalacion_destino: "",
      tebMesLista: [],
      volumen: "",
      montoEstimado: "",
      numeroEstimacion: "",
      listaInstDestPorPress: [],
      activoEquipo: "",
      tipoDeServicio: ["J", "D", "FS", "MTTO", "S", "HORA"],
      ubicacionServicio: [
        "TMDB",
        "Almacen de Bienes de Consumo",
        "Terminal Martima Arbol Grande",
        "Terminal Maritima Cobos",
      ],
      ubicacion: "",
      numeroDeSolicitud: "",
      date: [],
      menu: false,
      modal: false,
      documentos: [],
      menu2: false,
      menuServicio: false,
      fechaServicio: "",
      mesServicio: "",
      verNuevo: 0,
      editedIndex: -1,
      alert: "",
      dialogGuardarTrazabilidad: false,
      dialogLoading: false,
      tebsSinUno: [],
      options: {
        locale: "en-US",
        prefix: "$",
        suffix: "",
        length: 11,
        descripcion: "",
        company_logo: {
          w: 110,
          h: 35,
        },

        precision: 2,
      },
      trazabilidadImportDatos: "",
      trazabilidadsAdjuntosTabla: [],
      dialogVizualisarTrazabilidadImportada: false,
      trazabilidadTotalAmbos: [],
      karen: 0,
      kristell: 0,
      modo_edicion: false,
      drawer: false,
      dialogAperturaMes: false,
      estimacion: "",
      trazabilidadIndice:[],
    };
  },
  watch: {},
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo registro" : "Editar registro";
    },
    dateRangeText() {
      return this.periodo.join(" al ");
    },
  },
  created() {
    this.trazabilidadIndiceList();
    this.listar();
    this.listarUbicaciones();
  },
  methods: {
    listar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      this.dialogLoading = true;
      axios
        .get("/trazabilidad/list/", configuracion)
        .then(function (response) {
          me.trazabilidadLista = response.data;
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          me.dialogLoading = false;
        });
    },

    onSelect(selectedRows) {
      this.campoSeleccionado = selectedRows[0];
    },

    onDelete() {
      let id_largo = this.$refs.grid._data.selected[this.campoSeleccionado];
      let id_corto = id_largo.substr(id_largo.length - 7);
      // this.$refs.grid.deleteRecord(id_corto)
      if (this.campoSeleccionado) {
        let itemReg = this.$refs.grid._data.table[parseInt(id_corto)];
        this.dialogEquipo = itemReg.activoEquipo;
        this.dialogVolumen = itemReg.volumen;

        this.dialogMonto = itemReg.montoEstimado;
        this.dialogFecha = itemReg.fechaServicio;
        this.dialogTeb = itemReg.numeroDeSolicitud;
      } else {
        this.addErrorNotification2("Ningun registro seleccionado");
      }
    },

    mostrarDialogDelete() {
      if (this.campoSeleccionado === 0) {
        this.dialogDeleteRecord = true;
        //let id_largo = this.$refs.grid._data.selected[this.campoSeleccionado]
        //  let id_corto = parseInt(id_largo.substr(id_largo.length - 7))
        let itemReg = this.$refs.grid.$data.table[this.campoSeleccionado];

        this.dialogEquipo = itemReg.activoEquipo;
        this.dialogVolumen = itemReg.volumen;
        this.dialogMonto = itemReg.montoEstimado;
        this.dialogTeb = itemReg.numeroDeSolicitud;
        this.dialogPartida = itemReg.partida;
        this.dialogUsuario = itemReg.usuarioCreador;
        //this.dialogUsuario = itemReg.partida;
      } else if (this.campoSeleccionado === "") {
        this.addErrorNotification2("Ningun registro seleccionado");
      } else {
        this.dialogDeleteRecord = true;
        //let id_largo = this.$refs.grid._data.selected[this.campoSeleccionado]
        //  let id_corto = parseInt(id_largo.substr(id_largo.length - 7))
        let itemReg = this.$refs.grid.$data.table[this.campoSeleccionado];

        this.dialogEquipo = itemReg.activoEquipo;
        this.dialogVolumen = itemReg.volumen;
        this.dialogMonto = itemReg.montoEstimado;
        this.dialogTeb = itemReg.numeroDeSolicitud;
        this.dialogPartida = itemReg.partida;
        this.dialogUsuario = itemReg.usuarioCreador;
      }
    },

    eliminarRegistroFilter() {
      this.dialogTimer = true;
      let id_largo = this.$refs.grid._data.selected[this.campoSeleccionado];
      let id_corto = id_largo.substr(id_largo.length - 7);
      let idNumber = parseInt(id_corto);
      this.$refs.grid.deleteRecord(idNumber);
      this.campoSeleccionado = "";
      this.itemReg = [];
      //this.guardar()
      // this.dialogDeleteRecord = false;
    },

    eliminarRegistroFilter2() {
      let itemReg = this.$refs.grid.$data.table[this.campoSeleccionado];
      this.eliminarRegistro2(itemReg);
      this.dialogDeleteRecord = false;
    },

    eliminarRegistro2(item) {
      let i = this.registroCosteo.indexOf(item);
      if (i != -1) {
        this.registroCosteo.splice(i, 1);
      }
    },

    costoToDollar(text) {
      let number = parseFloat(text);
      return number.toLocaleString("en-US");
    },

    limpiar() {
      this.arrendadora = "";
      //  this.editedIndex = -1
      this.alert = false;
      this.numeroDeSolicitud = "";
      this.fechaServicio = "";
      this.ordenServicio = "";
      this.tipoDeServicioSelec = "";
      this.numeroEstimacion = "";
      this.volumen = "";
      this.partida = "";
      this.ubicacion = "";
      this.activoEquipo = "";
      this.instalacion_destino = "";
      this.descripcion = "";
      this.trazabilidadTotalAmbos = [];
      this.dialogGuardarTrazabilidad = false;
    },

    delRecord() {
      if (this.campoSeleccionado) {
        this.$refs.grid.deleteRecord(this.campoSeleccionado);
        this.campoSeleccionado = "";
      } else {
        this.addErrorNotification2("Error, registro no seleccionado");
      }
    },

    async guardar2() {
      this.dialogLoading = true;
      await Costeo.listQueryTrazabilidad(this._id)
        .then((e) => {
          for (let i = 0; i < e.data.trazabilidad.length; i++) {
            if (
              this.registroCosteo[i].activoEquipo !=
                e.data.trazabilidad[i].activoEquipo ||
              this.registroCosteo[i].descripcion !=
                e.data.trazabilidad[i].descripcion ||
              this.registroCosteo[i].fechaServicio !=
                e.data.trazabilidad[i].fechaServicio ||
              this.registroCosteo[i].instalacion_destino !=
                e.data.trazabilidad[i].instalacion_destino ||
              this.registroCosteo[i].numeroDeSolicitud !=
                e.data.trazabilidad[i].numeroDeSolicitud ||
              this.registroCosteo[i].partida !=
                e.data.trazabilidad[i].partida ||
              this.registroCosteo[i].tipoDeServicioSelec !=
                e.data.trazabilidad[i].tipoDeServicioSelec ||
              this.registroCosteo[i].ubicacion !=
                e.data.trazabilidad[i].ubicacion ||
              this.registroCosteo[i].volumen != e.data.trazabilidad[i].volumen
            ) {
              console.log(i);
            }
          }
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },

    guardar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      this.dialogTimer = true;

      if (this.editedIndex > -1) {
        this.corregirTrazabilidad();
        axios
          .put(
            "/trazabilidad/update",
            {
              _id: this._id,
              mes: this.mesServicio,
              trazabilidad: this.registroCosteo,
            },
            configuracion
          )
          .then(function () {
            me.limpiar();
            // me.listar();
            if (!me.modo_edicion) {
              Costeo.deleteHistorialTrazabilidad();
            }
            me.addSuccessNotification("Trazabilidad actualizada");
            me.dialogTimer = false;
            //me.ocultarNuevo();
          })
          .catch(function (e) {
            me.dialogTimer = false;
            me.addErrorNotification2(
              "Error, verifica la informacion agregada " + e
            );
          })
          .finally(() => {
            me.dialogTimer = false;
          });
      } else {
        axios
          .post(
            "/trazabilidad/add",
            {
              mes: this.mesServicio,
              trazabilidad: this.registroCosteo,
            },
            configuracion
          )
          .then(function () {
            me.limpiar();
            me.listar();
            me.dialogTimer = false;
            me.addSuccessNotification("Trazabilidad agregada");
            me.ocultarNuevo();
          })
          .catch(function (error) {
            me.addErrorNotification2(
              "Error, verifica la informacion agregada " + error
            );
            console.log(error);
          })
          .finally(() => {
            me.dialogTimer = false;
          });
      }
    },

    editItem(trazabilidad) {
      this.dialogLoading = true;
      Costeo.listQueryTrazabilidad(trazabilidad._id)
        .then((e) => {
          this.registroCosteo = e.data.trazabilidad;
          this._id = trazabilidad._id;
          this.mostrarProgramaPress();
          this.mostrarActivos();
          this.mostrarPartidas();
          this.mostrarOs();
          (this.mesServicio = new Date(trazabilidad.mes)
            .toISOString()
            .substr(0, 7)),
            this.mostrarTepMes();

          this.verNuevo = 1;
          this.editedIndex = 1;
        })
        .catch((err) => {
          this.addErrorNotification2("error al cargar la trazabilidad" + err);
        })
        .finally(() => {
          this.dialogLoading = false;
        });

      // this.mostrarNuevo();
    },
    mostrarNuevo() {
      // this.listarAgencia(this.$store.state.usuario.agencia)
      this.mostrarProgramaPress();
      this.mostrarActivos();
      this.mostrarPartidas();
      this.mostrarOs();
      this.editedIndex = -1;
      this.limpiar();
      this.registroCosteo = [];
      this.verNuevo = 1;
    },
    ocultarNuevo() {
      this.verNuevo = 0;
      this.limpiar();
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification2(msg) {
      this.$toast.error(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },

    mostrarPartidas() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let partidasListArr = [];
      let configuracion = { headers: header };
      axios
        .get("/partidas/list", configuracion)
        .then(function (response) {
          partidasListArr = response.data;
          partidasListArr.map((x) => {
            me.partidasList.push({
              text: x.numeroPartida,
              value: x.numeroPartida,
            });
          });
        })
        .catch(function () {});
    },
    mostrarOs() {
      let me = this;
      this.osList = [];
      let header = { Token: this.$store.state.token };
      let osListArr = [];
      let configuracion = { headers: header };
      axios
        .get("/os/osActivos", configuracion)
        .then(function (response) {
          osListArr = response.data;
          osListArr.map((x) => {
            let mesSub = x.periodo[0].substring(0, 7);
            if (me.mesServicio == mesSub) {
              me.osList.push(x.os);
            }
          });
        })
        .catch(function () {});
    },
    limpiarTrazabilidadFull() {
      this.registroCosteo = [];
    },

    mostrarEquiposDeOrden(item) {
      let me = this;
      let equiposArray = [];
      console.log(item);
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/os/queryOs?os=" + item, configuracion)
        .then(function (response) {
          console.log(response.data);
          response.data.partidasEquipo.forEach((e) => {
            equiposArray.push(e.equipo);
          });
          let equipos = [];
          for (let i = 0; i < equiposArray.length; i++) {
            equipos = equipos.concat(equiposArray[i]);
          }
          me.osEquiposinTraz = equipos;
        })

        .catch(function (e) {
          console.log(e);
        });
    },

    mostrarActivosOs() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let osListArr = [];
      let configuracion = { headers: header };

      axios
        .get("/os/queryos?os=" + this.ordenServicio, configuracion)
        .then(function (response) {
          osListArr = response.data.equipos;
          osListArr.map((x) => {
            if (x) {
              me.osEquiposinTraz.push(x);
            }
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    changeIcon() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };

      if (me.volumen && me.partida) {
        axios
          .get("/partidas/query?partida=" + this.partida, configuracion)
          .then(function (response) {
            me.partidas = response.data;

            if (me.partidas.equipoPartidas.length == 1) {
              me.osEquiposinTraz = me.partidas.equipoPartidas[0].equipo;
            } else if (me.partidas.equipoPartidas.length == 2) {
              let horario1 = me.partidas.equipoPartidas[0].horario;
              let eq1 = [];
              me.partidas.equipoPartidas[0].equipo.forEach((e) => {
                eq1.push(e + horario1);
              });
              let horario2 = me.partidas.equipoPartidas[1].horario;
              let eq2 = [];
              me.partidas.equipoPartidas[1].equipo.forEach((e) => {
                eq2.push(e + horario2);
              });

              let arr = [...eq1, ...eq2];

              me.osEquiposinTraz = arr;
            } else if (me.partidas.equipoPartidas.length == 3) {
              let arr = [
                ...me.partidas.equipoPartidas[0].equipo,
                ...me.partidas.equipoPartidas[1].equipo,
                ...me.partidas.equipoPartidas[2].equipo,
              ];
              me.osEquiposinTraz = arr;
            } else if (me.partidas.equipoPartidas.length == 4) {
              let arr = [
                ...me.partidas.equipoPartidas[0].equipo,
                ...me.partidas.equipoPartidas[1].equipo,
                ...me.partidas.equipoPartidas[2].equipo,
                ...me.partidas.equipoPartidas[3].equipo,
              ];
              me.osEquiposinTraz = arr;
            } else if (me.partidas.equipoPartidas.length == 0) {
              me.mostrarEquiposDeOrden(me.ordenServicio);
            } //Ahora los equipos los jalo desde la orden de servicio

            /*             console.log(me.osEquiposinTraz);
             */ //   me.osEquiposinTraz = response.data.equipoPartidas[0].equipo;
            //   if (response.data.equipoPartidas[1]) {
            //     for (
            //       let i = 0;
            //       i < response.data.equipoPartidas[1].equipo.length;
            //       i++
            //      ) {
            //        me.osEquiposinTraz.push(
            //          response.data.equipoPartidas[1].equipo[i]
            //        );
            //      }
            //    }

            if (me.partidas) {
              me.costoUnitario = me.partidas.costo;
              me.montoEstimado = me.costoUnitario * me.volumen;
            } else {
              me.addErrorNotification();

              me.partida = "";
              (me.costoUnitario = ""), (me.montoEstimado = "");
            }
          })
          .catch(function () {
            me.osEquiposinTraz = [];
          });
      }
    },

    mostrarDatoSelecc(...args) {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/partidas/query?partida=" + args[0], configuracion)
        .then(function (response) {
          args[2].costoUnitario = response.data.costo;
          args[2].montoEstimado = response.data.costo * args[2].volumen;
        })
        .catch(function () {
          me.addErrorNotification();

          (args[2].costoUnitario = 0), (args[2].partida = 0);
          args[2].montoEstimado = 0;
        });
    },
    mostrarDatoSeleccVolumen(...args) {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/partidas/query?partida=" + args[2].partida, configuracion)
        .then(function (response) {
          let suma = 0;
          for (let i = 0; i < me.registroCosteo.length; i++) {
            if (
              me.registroCosteo[i].activoEquipo == args[2].activoEquipo &&
              me.registroCosteo[i].fechaServicio == args[2].fechaServicio &&
              me.registroCosteo[i].partida == args[2].partida
            ) {
              console.log(me.registroCosteo[i]);
              suma += parseFloat(me.registroCosteo[i].volumen);
            }
          }
          if (suma > 1) {
            me.addErrorNotification();
            console.log(suma);

            args[2].volumen = args[1];
          } else {
            args[2].costoUnitario = response.data.costo;
            args[2].montoEstimado = response.data.costo * args[2].volumen;
          }
        })
        .catch(function () {
          me.addErrorNotification();
          (args[2].costoUnitario = 0), (args[2].partida = 0);
        });
    },

    mostrarDatosSeleccEquipo(newVal, oldVal, row) {
      let me = this;
      let index = this.registroCosteo.findIndex((i) => i.$id === row.$id);
      this.registroCosteo[index].activoEquipo = newVal;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/partidas/query?partida=" + row.partida, configuracion)
        .then(function () {
          let suma = 0;
          for (let i = 0; i < me.registroCosteo.length; i++) {
            console.log(me.registroCosteo.length);
            if (
              me.registroCosteo[i].activoEquipo == row.activoEquipo &&
              me.registroCosteo[i].fechaServicio == row.fechaServicio
            ) {
              suma += parseFloat(me.registroCosteo[i].volumen);
            }
          }

          if (suma > 1) {
            me.addErrorNotification();
            row.activoEquipo = oldVal;
          }
        })
        .catch(function (e) {
          console.log(e);
        });
    },

    mostrarDatosSeleccFecha(newVal, oldVal, row) {
      let me = this;
      let index = this.registroCosteo.findIndex((i) => i.$id === row.$id);
      this.registroCosteo[index].fechaServicio = newVal;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/partidas/query?partida=" + row.partida, configuracion)
        .then(function () {
          let suma = 0;
          for (let i = 0; i < me.registroCosteo.length; i++) {
            console.log(me.registroCosteo.length);
            if (
              me.registroCosteo[i].activoEquipo == row.activoEquipo &&
              me.registroCosteo[i].fechaServicio == row.fechaServicio
            ) {
              suma += parseFloat(me.registroCosteo[i].volumen);
            }
          }

          if (suma > 1) {
            me.addErrorNotification();
            row.fechaServicio = oldVal;
          }
        })
        .catch(function (e) {
          console.log(e);
        });
    },

    eliminarRegistro(item) {
      let i = this.registroCosteo.indexOf(item);
      if (i != -1) {
        this.registroCosteo.splice(i, 1);
      }
    },

    agregarRegistro() {
      let datos = {
        numeroEstimacion: this.numeroEstimacion,
        numeroDeSolicitud: this.numeroDeSolicitud,
        instalacion_destino: this.instalacion_destino,
        activoEquipo: this.activoEquipo,
        tipoDeServicioSelec: this.tipoDeServicioSelec,
        activo: this.activoCosteo,
        volumen: this.volumen,
        partida: this.partida,
        costoUnitario: this.costoUnitario,
        montoEstimado: this.montoEstimado,
        fechaServicio: this.fechaServicio,
        descripcion: this.descripcion,
        ubicacion: this.ubicacion,
        ordenServicio: this.ordenServicio,
        programaPresupuestal: this.programaPresupuestalSelec,
        epep: this.epepSelecc,
        activoPemex: this.activoPemex,
        raf: this.raf,
        usuarioCreador: this.$store.state.usuario.personal.nombreCompleto,
        idTrazabilidad: this._id,
      };

      for (let i = 0; i < this.cantidad; i++) {
        this.registroCosteo.push({
          numeroEstimacion: this.numeroEstimacion,
          numeroDeSolicitud: this.numeroDeSolicitud,
          instalacion_destino: this.instalacion_destino,
          activoEquipo: this.activoEquipo,
          tipoDeServicioSelec: this.tipoDeServicioSelec,
          activo: this.activoCosteo,
          volumen: this.volumen,
          partida: this.partida,
          costoUnitario: this.costoUnitario,
          montoEstimado: this.montoEstimado,
          fechaServicio: this.fechaServicio,
          descripcion: this.descripcion,
          ubicacion: this.ubicacion,
          ordenServicio: this.ordenServicio,
          programaPresupuestal: this.programaPresupuestalSelec,
          epep: this.epepSelecc,
          activoPemex: this.activoPemex,
          raf: this.raf,
          usuarioCreador: this.$store.state.usuario.personal.nombreCompleto,
          //
        });
      }
      let suma = 0;

      for (let i = 0; i < this.registroCosteo.length; i++) {
        if (
          this.registroCosteo[i].activoEquipo == this.activoEquipo &&
          this.registroCosteo[i].fechaServicio == this.fechaServicio &&
          this.registroCosteo[i].partida == this.partida &&
          this.registroCosteo[i].tipoDeServicio !== "D"
        ) {
          suma += parseFloat(this.registroCosteo[i].volumen);
          if (suma > 1) {
            this.addErrorNotification();

            for (let i = 0; i < this.cantidad; i++) {
              this.registroCosteo.pop();
            }
            return;
          }
        }
      }
      this.historialTrazabilidadAdd(datos);
      this.addSuccessNotification("Registros agregados");
    },

    mostrarInstDest() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };

      axios
        .get(
          "/programasPress/query?programaPresupuestal=" + this.programaPress,
          configuracion
        )
        .then(function (response) {
          me.listaInstDestPorPress = response.data.inst_dest_items;
          me.epep = response.data.epep;
          me.activoCosteo = response.data.activo;
        })
        .catch(function () {});
    },
    mostrarProgramaPress() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let presupuestoArray = [];
      let configuracion = { headers: header };
      axios
        .get("/programasPress/list", configuracion)
        .then(function (response) {
          presupuestoArray = response.data;
          presupuestoArray.map((x) => {
            me.programasPressLista.push({
              text: x.programaPresupuestal,
              value: x.programaPresupuestal,
            });
          });
        })
        .catch(function () {});
    },
    mostrarTepMes() {
      this.menu2 = false;
      let me = this;
      let header = { Token: this.$store.state.token };
      let tebMesArray = [];
      this.tebMesLista = [];
      let configuracion = { headers: header };
      axios
        .get("/teb/queryMes?mes=" + this.mesServicio, configuracion)
        .then(function (response) {
          tebMesArray = response.data.tebRegistros;

          tebMesArray.map((x) => {
            me.tebMesLista.push(x.teb);
          });
        })
        .catch(function (e) {
          console.log("error" + e);
        });
    },

    obtenerDatos(id) {
      console.log(id);
      this.loading = true;
      axios({
        url: "/trazabilidad/listDatosReg?_id=" + id, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      });
    },

    mostrarFechaTeb() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let tebMesArray = [];

      let configuracion = { headers: header };
      axios
        .get("/teb/queryMes?mes=" + this.mesServicio, configuracion)
        .then(function (response) {
          tebMesArray = response.data.tebRegistros;

          let arr = tebMesArray.find(
            (element) => element.teb == me.numeroDeSolicitud
          );

          let date = arr.fechaRecibido.split("/");
          let date2 = new Date(date[2], date[1] - 1, date[0]);
          console.log(arr.fechaRecibido);
          if (arr.fechaRecibido.includes("-")) {
            me.fechaServicio = arr.fechaRecibido.replace("/", "-");
          } else {
            me.fechaServicio = date2.toISOString().substr(0, 10);
          }
          /*   me.fechaServicio = arr.fechaRecibido.replace('/','-')
              console.log(new Date(arr.fechaRecibido.replace('/','-'))) */
          me.programaPresupuestalSelec = arr.programaPressL;
          me.epepSelecc = arr.epepRegistro;
          me.activoPemex = arr.activo;
          me.activoEquipo = arr.equiposRequeridosReg;
          me.instalacion_destino = arr.instalacionD;
        })
        .catch(function (e) {
          console.log("error" + e);
        });
    },
    mostrarActivos() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let equiposActivos = [];
      let configuracion = { headers: header };
      axios
        .get("/activos/listActivosPemex", configuracion)
        .then(function (response) {
          equiposActivos = response.data;

          equiposActivos.map((x) => {
            me.equiposActivosLista.push(
              x.nombreActivo + " " + x.numeroEconomico
            );
          });
        })
        .catch(function () {});
    },

    listarUbicaciones() {
      Catalogo.obtenerUbicacionesOs().then((e) => {
        this.ubicacionesOs = e.data;
      });
    },

    obtnerDatosTrazabilidadImportada() {
      let me = this;
      var file = this.trazabilidadImportDatos;

      this.$papa.parse(file, {
        header: true,
        complete: function (results) {
          me.trazabilidadsAdjuntosTabla = results.data;
          me.trazabilidadsAdjuntosTabla.splice(
            me.trazabilidadsAdjuntosTabla.length - 1,
            1
          );
        },
      });
    },
    vizualizarTrazabilidadImportada() {
      this.dialogVizualisarTrazabilidadImportada = true;
      this.trazabilidadsAdjuntosTabla.forEach((e) => {
        parseFloat(e.montoEstimado);
      });
    },
    agregarTrazabilidadImportada() {
      this.dialogVizualisarTrazabilidadImportada = false;
      this.registroCosteo = [
        ...this.registroCosteo,
        ...this.trazabilidadsAdjuntosTabla,
      ];
    },

    async dialogGuardar() {
      if (this.modo_edicion == true) {
        this.guardar();
        return;
      }
      Costeo.listHistorialTrazabilidad().then((e) => {
        this.trazabilidadTotalAmbos = e.data;
        this.karen = 0;
        this.kristell = 0;
        e.data.forEach((e) => {
          if (e.usuarioCreador == "Karen Domínguez") {
            this.karen++;
          } else {
            this.kristell++;
          }
        });
        this.dialogGuardarTrazabilidad = true;
      });
    },

    async historialTrazabilidadAdd(datos) {
      this.dialogLoading = true;
      await Costeo.insertarTrazabilidadHistorial(datos).finally(() => {
        this.dialogLoading = false;
      });
    },
    async guardarTrazabilidadMult() {
      this.dialogLoading = true;
      await Costeo.listQueryTrazabilidad(this._id)
        .then((e) => {
          this.registroCosteo = [
            ...e.data.trazabilidad,
            ...this.trazabilidadTotalAmbos,
          ];
          this.guardar();
          console.log();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },

    async agregarTeb() {
      let encontrados = [];
      await Costeo.buscarTebMes(this.mesServicio).then((e) => {
        let sinTeb = [];

        for (let reg of this.registroCosteo) {
          if (!reg.numeroDeSolicitud) {
            sinTeb.push(reg);
          }
        }
        let equiposIndivuales = [];
        e.data.tebRegistros.forEach((e) => {
          let equipos = e.equiposRequeridosReg.split(", ");
          if (e.observaciones == "TEB MENSUAL") {
            equipos.forEach((f) => {
              equiposIndivuales.push({
                equipo: f,
                activo: e.activo,
                epep: e.epepRegistro,
                pp: e.programaPressL,
                teb: e.teb,
                instalacionD: e.instalacionD,
                fechaSolicitada: e.fechaRecibido,
              });
            });
          }
        });

        console.log(equiposIndivuales);

        sinTeb.forEach((e) => {
          let enc = equiposIndivuales.find(
            (reg) =>
              reg.equipo == e.activoEquipo &&
              reg.instalacionD == e.instalacion_destino
          );
          if (enc != undefined) {
            encontrados.push(e);
          }
        });

        console.log(encontrados);

        /* this.registroCosteo.forEach((e)=>{
            encontrados.forEach((f)=>{
              if(e._id==f._id){
                e.activoPemex = f.activo
                e.numeroDeSolicitud = f.teb
                e.programaPresupuestal = f.pp
                e.epep = f.epep
                e.activo = f.activo
              }
            })
          }) */
      });
    },

    imprimirTrazabilidSinTeb() {
      this.registroCosteo.forEach((e) => {
        if (!e.numeroDeSolicitud) {
          console.log(e);
        }
      });
    },

    corregirTrazabilidad() {
      this.registroCosteo.forEach((e) => {
        if (!e.numeroDeSolicitud) {
          (e.activoPemex = ""), (e.epep = "");
          e.programaPresupuestal = "";
        }
      });
    },

    ingresarTrazabilidadIndice() {
      let datos = {
        mes: this.mesServicio,
        numeroEstimacion: this.estimacion,
      };
      Costeo.insertarTrazabilidadIndice(datos).then(() => {
        this.addSuccessNotification("Apertura realizada correctamente");
        this.mesServicio = "";
        this.estimacion = "";
        this.dialogAperturaMes = false;
        this.trazabilidadIndiceList()
      });
    },

   async trazabilidadIndiceList(){
      await Costeo.listarTrazabilidadIndice().then((e)=>{
        this.trazabilidadIndice = e.data
        console.log(e.data)
      }).catch((err)=>{
        console.log(err)
      })
    },

     trazabilidadDet(datos){
       console.log(datos)
    }
  },
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
