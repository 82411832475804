import axios from 'axios'

export default {
    //----ENDPOINTS Email----//    

    enviarEmailReporteFalla(folio,fecha,numeroEconomico,tipoFalla,operador,descripcion,numeroSerie,estatusEquipo,usuarioCreador,correos,documento){
        return axios.post('/emails/notificacionReporteFallas',
        {
            folio,
            fecha,
            numeroEconomico,
            tipoFalla,
            operador,
            descripcion,
            numeroSerie,
            estatusEquipo,
            usuarioCreador,
            correos,
            documento
        })
    },
    enviarEmailOrdenTrabajo(folio,numeroEconomico,estatus,hora_apertura,hora_vencimiento,reporte_falla,tipo_falla,tipo_intervencion,correos,documento,mecanicos){
        return axios.post('/emails/notificacionOT',
        {
            folio,
            estatus,
            hora_apertura,
            hora_vencimiento,
            reporte_falla,
            tipo_falla,
            tipo_intervencion,
            numeroEconomico,
            correos,
            documento,mecanicos
        })
    },
}