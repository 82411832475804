<template>
  <v-container fluid>
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Por favor espere
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogViewCosteo" transition="dialog-bottom-transition">
      <v-card>
        <v-card>
          <v-card-title class="text-h5 black lighten-2 white--text">
            Prorrateo
          </v-card-title>
          <v-card-text
            ><br /><br />
            <div class="text--primary">
              <iframe
                :src="urlCosteo"
                style="width: 100%; height: 600px"
                frameborder="0"
              ></iframe>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="green accent-4" @click="descargarCosteoExcel()">
              Descargar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogViewImportCosteo"
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn class="ma-4" icon dark @click="dialogViewImportCosteo = false">
            <v-icon>mdi-close</v-icon>Salir
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-container>
              <v-row align="center">
                <v-col cols="12" sm="6" md="4">
                  <v-file-input
                    v-model="costeoImportar"
                    label="Adjunta el archivo "
                    prepend-icon="mdi-microsoft-excel"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="2" md="1">
                  <v-btn
                    color="black"
                    @click="importarCosteo()"
                    small
                    dark
                    depressed
                  >
                    Importar
                  </v-btn>
                </v-col>
                <v-col
                  v-if="costeoAdjuntosTable.length > 0"
                  cols="12"
                  sm="2"
                  md="1"
                >
                  <v-btn
                    dark
                    small
                    color="green accent-4"
                    @click="guardarCosteoImport()"
                  >
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="12">
                  <template>
                    <vue-excel-editor
                      filter-row
                      v-model="costeoAdjuntosTable"
                      ref="grid"
                      width="2200px"
                    >
                      <vue-excel-column
                        field="no_contrato"
                        width="120px"
                        label="No Contrato"
                      />
                      <vue-excel-column
                        field="no_estimacion"
                        type="number"
                        width="50px"
                        label="No Estimacion"
                      />
                      <vue-excel-column
                        field="embarcacion_equipo"
                        width="120px"
                        label="Embarcacion/Equipo"
                      />
                      <vue-excel-column
                        field="instalacion_origen"
                        width="120px"
                        label="Instalacion/Origen"
                      />

                      <vue-excel-column
                        field="instalacion_destino"
                        width="120px"
                        label="Instalacion/Destino"
                      />
                      <vue-excel-column
                        field="pp"
                        label="Programa Presupuestal"
                        width="180px"
                      />
                      <vue-excel-column
                        field="cege"
                        width="120px"
                        label="CEGE"
                      />
                      <vue-excel-column
                        field="epep"
                        width="120px"
                        label="EPEP"
                      />
                      <vue-excel-column
                        field="pospre"
                        width="120px"
                        label="POSPRE"
                      />
                      <vue-excel-column
                        field="fondo"
                        width="120px"
                        label="Fondo"
                      />
                      <vue-excel-column
                        field="c_mayor"
                        width="120px"
                        label="C MAYOR"
                      />
                      <vue-excel-column
                        field="activo"
                        width="120px"
                        label="Activo"
                      />

                      <vue-excel-column
                        field="volumen"
                        width="70px"
                        label="Volumen"
                        summary="sum"
                      />
                      <vue-excel-column
                        field="precio_unitario"
                        width="100px"
                        type="number"
                        label="Precio Unitario"
                        :to-text="costoToDollar"
                      />
                      <vue-excel-column
                        field="monto_estimado"
                        width="100px"
                        label="Monto Estimado"
                      />
                      <vue-excel-column
                        field="partida"
                        width="50px"
                        type="number"
                        label="Partida"
                      />
                    </vue-excel-editor>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmarGuardarCosteo" persistent max-width="410">
      <v-card>
        <v-card-title class="text-h5">
          Guardar registros importados de Costeo?
        </v-card-title>
        <v-card-text
          >Seguro que decea guardar el registro de costeo con un monto de
          <b> {{ costoToDollar(parseFloat(totalImportCosteo)) }} </b>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogConfirmarGuardarCosteo = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="guardarProrrateoImportado()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogConfirmarGuardarEstimacion"
      persistent
      max-width="410"
    >
      <v-card>
        <v-card-title class="text-h5"> ¿Guardar Estimación? </v-card-title>
        <v-card-text
          >Seguro que desea guardar la estimacion con un monto de
          <b> ${{ costoToDollar(parseFloat(sumaEstimacion)) }} </b>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogConfirmarGuardarEstimacion = false"
          >
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="guardarEstimacionFinal()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogConfirmarGuardarCosteoMultiple"
      persistent
      max-width="510"
    >
      <v-card>
        <v-card-title class="text-h5">
          Generar estimación complementaria
        </v-card-title>
        <v-card-text
          >El monto <b>${{ costoToDollar(sumaActivo) }}</b> de las partidas es
          menor al total del COSTEO, decea modificar las partidas de la
          estimacion?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogConfirmarGuardarCosteoMultiple = false"
          >
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="guardarPedidoEstim()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPartidasTotales" scrollable max-width="600px">
      <v-card>
        <v-card-title
          >Modificar Partidas <v-spacer></v-spacer>
          <v-btn
            :loading="loadingSincrinize"
            icon
            color="green"
            @click="actualizarTodasPartidas()"
          >
            <v-icon>mdi-cached</v-icon>
          </v-btn></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text style="height: 800px">
          <br />
          <v-row v-for="(item, key) in partidasTotales" :key="key" class="mt-0">
            <v-col cols="12" md="3">
              <v-text-field
                dense
                outlined
                disabled
                v-model="item[1].partida"
                label="Partida"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                outlined
                append-icon="mdi-cash-sync"
                :loading="loadingPartida"
                @click:append="
                  actualizarCosto(key, item[1].partida, item[1].volumen)
                "
                v-model="item[1].volumen"
                label="Jornada"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <vuetify-money
                type="number"
                outlined
                dense
                v-model="item[1].monto_estimado"
                label="Total"
                v-bind:options="options"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogPartidasTotales = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="mostrarDialogGuardarEstimacion()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-model="sheetActivos">
      <v-sheet class="text-center" height="450px">
        <div class="py-3">
          <v-card class="mx-auto"
            ><v-card-text>
              <p class="text-h4 text--primary">RAFS</p>
              <p>Formulario para agregar RAFS a Activos</p>
              <v-row>
                <v-col
                  cols="3"
                  v-for="(activos, index) in activosRaf"
                  :key="activos.activo"
                >
                  <v-text-field
                    dense
                    outlined
                    :value="activos"
                    v-model="activosRaf[index].raf"
                    :label="activos.activo"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile color="primary" @click="sheetActivos = !sheetActivos">
                <v-icon left> mdi-exit-to-app </v-icon>
                Salir
              </v-btn>

              <v-btn tile color="success" @click="guardarRAF">
                <v-icon left> mdi-content-save-cog-outline </v-icon>
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="sheetPedidos" v-if="activosPedidos">
      <v-sheet class="text-center" height="700px">
        <div class="py-3">
          <v-card class="mx-auto"
            ><v-card-text>
              <p class="text-h4 text--primary">PEDIDOS</p>
              <p>Formulario para agregar PEDIDOS a Activos</p>
              <v-row>
                <v-col
                  cols="3"
                  v-for="(pedidos, index) in activosPedidos"
                  :key="pedidos.activo"
                >
                  <v-text-field
                    dense
                    outlined
                    :value="pedidos"
                    v-model="activosPedidos[index].pedido"
                    :label="pedidos.activo"
                  ></v-text-field>
                  <v-text-field
                    dense
                    outlined
                    :value="pedidos"
                    v-model="activosPedidos[index].total"
                    :label="`Total ${pedidos.activo}`"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile color="primary" @click="sheetPedidos = !sheetPedidos">
                <v-icon left> mdi-exit-to-app </v-icon>
                Salir
              </v-btn>

              <v-btn tile color="success" @click="guardarPedido">
                <v-icon left> mdi-content-save-cog-outline </v-icon>
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <div class="tables-basic">
      <br />
      <h1 class="page-title mt-16 mb-6">Costeo Prorrateado</h1>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-6 pb-3">
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>

            <v-data-table :headers="headers" :items="costeos" :search="search">
              <template v-slot:[`item.contrato`]> 648812815 </template>
              <template v-slot:[`item.mes`]="{ item }">
                {{ moment(item.mes).add(1, "days").format("MMMM YYYY") }}
              </template>
              <template v-slot:[`item.estimacion`]="{ item }">
                <v-chip
                  class="ma-2"
                  dark
                  small
                  v-if="item.costeo_imp[0]"
                  color="black"
                >
                  {{ item.costeo_imp[0].no_estimacion }}
                </v-chip>
              </template>
              <template v-slot:[`item.total`]="{ item }">
                <v-chip
                  class="ma-2"
                  dark
                  small
                  v-if="item.costeo_imp[0]"
                  color="primary"
                >
                  ${{ costoToDollar(item.costeo_imp[0].total) }}
                </v-chip>
              </template>
              <template v-slot:[`item.restante`]="{ item }">
                <v-chip
                  class="ma-2"
                  dark
                  small
                  v-if="item.costeo_imp[0]"
                  color="blue"
                >
                  ${{ costoToDollar(item.costeo_imp[0].restante) }}
                </v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  @click="obtenerDatos(item._id)"
                  class="mx-2"
                  dark
                  x-small
                  tile
                  color="green"
                >
                  <v-icon small left>mdi-printer-eye</v-icon>Prorrateo
                </v-btn>

                <v-btn
                  @click="descargarCosteoImportado(item._id)"
                  class="mx-2"
                  dark
                  v-if="item.costeo_imp.length == 1"
                  x-small
                  tile
                  color="green"
                >
                  <v-icon small left>mdi-printer-eye</v-icon>Costeo
                </v-btn>

                <v-btn
                  @click="mostrarDialogImportCosteo(item)"
                  class="mx-2"
                  dark
                  x-small
                  v-if="item.costeo_imp.length == 0"
                  tile
                  color="dark"
                >
                  <v-icon small left>mdi-file-arrow-up-down</v-icon>Importar
                  Costeo
                </v-btn>
                <v-btn
                  @click="generarEstimacionZero(item)"
                  class="mx-2"
                  dark
                  x-small
                  v-if="item.estimacions.length == 0"
                  tile
                  color="dark"
                >
                  <v-icon small left>mdi-file-arrow-up-down</v-icon>Generar
                  Estimación 0
                </v-btn>

                <v-btn
                  @click="agregarRAF(item._id)"
                  class="mx-2"
                  dark
                  small
                  tile
                  v-if="item.costeo_imp.length == 1"
                  color="indigo"
                  outlined
                  >RAF
                </v-btn>
                <v-btn
                  @click="agregarPedido(item, item._id)"
                  class="mx-2"
                  dark
                  small
                  v-if="
                    item.costeo_imp.length !== 0 &&
                    item.costeo_imp[0].restante !== 0 &&
                    item.estimacions.length >= 1
                  "
                  tile
                  outlined
                  color="teal"
                  >PEDIDO
                </v-btn>

                <v-btn
                  @click="guardarEstimacion(item.mes, item.trazabilidad)"
                  class="mx-2"
                  dark
                  small
                  tile
                  outlined
                  v-if="item.pedido"
                  color="primary"
                  >Generar Estimacion
                </v-btn>
              </template>
              <template v-slot:[`item.estim`]="{ item }">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                  v-if="item.estimacions.length > 0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Estimaciones</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list v-for="(est, i) in item.estimacions" :key="i">
                      <v-list-item>
                        <v-list-item-action>
                          <v-btn
                            :loading="loadingSincrinize"
                            icon
                            color="green"
                            @click="descargarEstimacion(est._id, i)"
                          >
                            <v-icon>mdi-microsoft-excel</v-icon></v-btn
                          >
                        </v-list-item-action>

                        <v-list-item-subtitle v-if="i == 0"
                          >Estimacion 0</v-list-item-subtitle
                        >
                        <v-list-item-subtitle v-else
                          >Estimacion
                          {{ est.no_estimacion }}</v-list-item-subtitle
                        >
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import S3 from "aws-s3";
import api from "../../api/Costeo";
import catalago from "../../api/Catalogo";

export default {
  data() {
    return {
      headers: [
        { text: "Actions", value: "action", sortable: false },
        { text: "Mes", value: "mes", sortable: true },
        { text: "Estimacion", value: "estimacion", sortable: true },
        { text: "Total Estimacion", value: "total", sortable: true },
        { text: "Restante", value: "restante", sortable: true },
        { text: "Estimaciones", value: "estim", sortable: true },
      ],
      empresas: [],
      empresa: "",
      costeos: [],
      trazabilidad: [],
      activosRaf: [],
      activosPedidos: [],
      sheetActivos: false,
      sheetPedidos: false,
      direccion: "",
      search: "",
      id: "",
      dialogLoading: false,
      loadingSincrinize: false,
      urlCosteo: "",
      urlExcel: "",
      moment: moment,
      dialogNuevaUbicacion: false,
      ubicaciones: [],
      editedIndex: -1,
      submited: "",
      alert: "",
      dialogViewCosteo: false,
      dialogViewImportCosteo: false,
      costeoImportar: "",
      costeoAdjuntosTable: [],
      totalImportCosteo: "",
      dialogConfirmarGuardarCosteo: false,
      datos_costeo: [],
      dialogConfirmarGuardarCosteoMultiple: false,
      dialogPartidasTotales: false,
      partidasTotales: [],
      estimacionUnica: false,
      loadingPartida: false,
      sumaActivo: 0,
      sumaEstimacion: 0,
      dialogConfirmarGuardarEstimacion: false,
      options: {
        locale: "en-US",
        prefix: "$",
        suffix: "",
        length: 11,

        company_logo: {
          w: 110,
          h: 35,
        },

        precision: 2,
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Ubicacion" : "Editar Ubicacion";
    },
    config() {
      return {
        bucketName: "cicsagruas.net",
        dirName: "estimaciones" /* optional */,
        region: "us-west-1",
        accessKeyId: "AKIA5DNSJBDUIMGT267Y",
        secretAccessKey: "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",
        s3Url: this.baseUrl,
      };
    },
    baseUrl() {
      return "https://s3.us-west-1.amazonaws.com/cicsagruas.net";
    },
    S3Client() {
      return new S3(this.config);
    },
    newFileName() {
      return Math.random().toString().slice(2);
    },
    url() {
      return `${this.baseUrl}/${"estimaciones"}/`;
    },
  },
  created() {
    this.listar();
  },
  methods: {
    listar() {
      this.dialogLoading = true;
      let me = this;
      axios
        .get("/costeo/list")
        .then(function (response) {
          me.costeos = response.data;
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          me.dialogLoading = false;
        });
    },

    descargarCosteo(id) {
      // this.loading = true;
      axios({
        url: "/costeo/descargarCosteo?_id=" + id, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      });
    },

    descargarEstimacion(id, index) {
      if (index == 0) {
        this.loadingSincrinize = true;
        axios({
          url: "/estimacion/descargarEstimacionZero?_id=" + id, //your url
          method: "GET",
          responseType: "blob", // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "estimacion.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.loadingSincrinize = false;
        });
      } else {
        this.loadingSincrinize = true;
        axios({
          url: "/estimacion/descargarEstimacion?_id=" + id, //your url
          method: "GET",
          responseType: "blob", // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "estimacion.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.loadingSincrinize = false;
        });
      }
    },

    async agregarRAF(item) {
      this.dialogLoading = true;
      api
        .queryCosteo(item)
        .then((e) => {
          console.log(e.data)
          const activosPemex = [];
          this.trazabilidad = e.data.costeo;
          e.data.costeo.forEach((e) => {
            if (
              activosPemex.findIndex(
                (pd) => pd.activo == e.activo
              ) === -1
            ) {
              if(e.activoPemex!=='X'){
              activosPemex.push(e);
              }
            }
          });
          console.log(activosPemex)

          let activos = [];
          activosPemex.forEach((e) => {
            if (e.activo) {
              activos.push({
                activo: e.activo,
                raf: e.raf,
              });
            }
          });
          this.activosRaf = activos;
          this.sheetActivos = true;
          this.id = item;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },

    agregarPedido(item, id) {
      const activosPemex = [];
      item.costeo_imp[0].costeo.forEach((e) => {
        if (activosPemex.findIndex((pd) => pd.raf == e.raf) === -1) {
          activosPemex.push(e);
        }
      });
      let activos = [];
      activosPemex.forEach((e) => {
        if (e.raf) {
          activos.push({
            activo: e.raf,
            pedido: e.pedidoPemex,
            total: e.total,
          });
        }
      });
      this.activosPedidos = activos;
      this.sheetPedidos = true;
      this.trazabilidad = item;
      this.id = id;
    },

    guardarRAF() {
      //Agregando RAF a Trazabilidad
      let me = this;
      this.dialogLoading = true;
      this.trazabilidad.forEach((e) => {
        this.activosRaf.forEach((f) => {
          if (e.activoPemex === f.activo) {
            e.raf = f.raf;
          }
        });
      });
      axios
        .put("/costeo/costeoRAF", {
          _id: this.id,
          trazabilidad: this.trazabilidad,
        })
        .then(function () {
          me.addSuccessNotification("Activo actualizado");
          me.sheetActivos = false;
        })
        .catch(function () {
          me.addErrorNotification("Error, verifica la informacion agregada");
        })
        .finally(() => {
          me.dialogLoading = false;
        });

      //Filtrando partidas
    },

    guardarPedido() {
      //Agregando PEDIDO a Trazabilidad

      let sumaActivos = 0;
      this.activosPedidos.forEach((e) => {
        if (e.total !== undefined) {
          sumaActivos += parseFloat(e.total);
        }
      });
      this.sumaActivo = sumaActivos;
      console.log(sumaActivos,this.trazabilidad.costeo_imp[0].total)
      if (sumaActivos > this.trazabilidad.costeo_imp[0].total.toFixed(2)) {
        this.addErrorNotification();
        return;
      } else if (
        sumaActivos < this.trazabilidad.costeo_imp[0].total.toFixed(2)
      ) {
        this.dialogConfirmarGuardarCosteoMultiple = true;
      } else if (
        sumaActivos == this.trazabilidad.costeo_imp[0].total.toFixed(2)
      ) {
        this.sumaEstimacion = sumaActivos;
        this.estimacionUnica = true;
        this.dialogConfirmarGuardarEstimacion = true;
      }
      /*  let me = this;
      this.trazabilidad.forEach((e) => {
        this.activosPedidos.forEach((f) => {
          if (e.activoPemex === f.activo) {
            e.pedidoPemex = f.pedidoPemex;
          }
        });
      });
      axios
        .put("/costeo/costeoRAF", {
          _id: this.id,
          trazabilidad: this.trazabilidad,
          pedidoEstatu: true,
        })
        .then(function () {
          me.addSuccessNotification("Activo actualizado");
          me.sheetPedidos = false;
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
          me.addErrorNotification("Error, verifica la informacion agregada");
        }); */

      //Filtrando partidas
    },

    guardarEstimacion(item, trazabilidad) {
      let me = this;
      this.dialogLoading = true;
      let numberRandom = Math.random().toString().slice(2);
      let fileExtension = "xlsx";
      let nombreArchivo = `${this.url}${numberRandom}.${fileExtension}`;
      axios
        .post("/estimacion/add", {
          mes: item,
          costeo: trazabilidad,
          urlExcel: nombreArchivo,
        })
        .then((response) => {
          me.guardarExcel(response.data._id, numberRandom);
          me.addSuccessNotification("Costeo ingresado correctamente");
        })
        .catch((e) => {
          console.log(e);
          me.addErrorNotification();
        })
        .finally(() => {
          me.dialogLoading = false;
        });
    },

    guardarExcel(item, name) {
      axios({
        url: "/estimacion/descargarEstimacion?_id=" + item, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const myFile = new File([response.data], "estimacion.xlsx", {
          type: "application/xlsx",
        });
        this.subirArchivo(myFile, name);
      });
    },
    async subirArchivo(file, name) {
      this.S3Client.uploadFile(file, name).finally((e) => {
        console.log(e);
      });
    },
    vizualizarCosteo(item) {
      //this.urlCosteo = item
      let url = "https://view.officeapps.live.com/op/embed.aspx?src=" + item;
      this.urlCosteo = url;
      this.urlExcel = item;
      this.dialogViewCosteo = true;
    },

    descargarCosteoExcel() {
      window.open(this.urlExcel, "_blank");
    },

    importarCosteo() {
      let me = this;
      var file = this.costeoImportar;

      this.$papa.parse(file, {
        header: true,
        complete: function (results) {
          me.costeoAdjuntosTable = results.data;
          me.costeoAdjuntosTable.splice(me.costeoAdjuntosTable.length - 1, 1);
        },
      });
    },

    costoToDollar(text) {
      let number = parseFloat(text);
      return number.toLocaleString("en-US");
    },

    guardarCosteoImport() {
      let total = 0;
      this.costeoAdjuntosTable.forEach((e) => {
        let monto = e.monto_estimado.replace("$", "");
        monto = monto.replace(/(,)/gm, "");
        e.monto_estimado = monto;
        total += parseFloat(monto);
      });
      this.dialogConfirmarGuardarCosteo = true;
      this.totalImportCosteo = total;
    },

    mostrarDialogImportCosteo(item) {
      this.dialogViewImportCosteo = true;
      this.costeoAdjuntosTable = [];
      this.totalImportCosteo = "";
      this.datos_costeo = item;
    },

    obtenerDatos(id) {
      this.dialogLoading = true;
      axios({
        url: "/costeo/descargarProrrateo?_id=" + id, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.dialogLoading = false;
      });
    },

    descargarCosteoImportado(id) {
      this.dialogLoading = true;
      axios({
        url: "/costeo/descargarCosteo?_id=" + id, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "costeo.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.dialogLoading = false;
      });
    },

    async guardarProrrateoImportado() {
      let datos = {
        mes: this.datos_costeo.mes,
        no_estimacion: this.costeoAdjuntosTable[0].no_estimacion,
        prorrateo: this.datos_costeo._id,
        costeo: this.costeoAdjuntosTable,
        total: this.totalImportCosteo,
        restante: this.totalImportCosteo,
      };

      await api
        .importarCosteo(datos)
        .then(() => {
          this.dialogLoading = true;
          this.addSuccessNotification();
          (this.datos_costeo = []),
            (this.costeoAdjuntosTable = []),
            (this.totalImportCosteo = "");
          this.dialogConfirmarGuardarCosteo = false;
          this.dialogViewImportCosteo = false;
          this.listar();
        })
        .catch((err) => {
          console.log(err);
          this.addErrorNotification();
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },

    async actualizarCosto(index, partida, volumen) {
      this.loadingPartida = true;
      if (partida == 57.2) {
        partida = "57.20";
      } else if (partida == 57.1) {
        partida = "57.10";
      }
      await catalago
        .queryPartidas(partida)
        .then((e) => {
          this.partidasTotales[index][1].monto_estimado =
            Number(e.data.costo) * Number(volumen);
          this.partidasTotales[index].precio_unitario = e.data.costo;

          
        })
        .catch(() => {
          this.addErrorNotification();
        })
        .finally(() => {
          this.loadingPartida = false;
        });
    },

    dosDecimales(n) {
      let t = n.toString();
      let regex = /(\d*.\d{0,2})/;
      return t.match(regex)[0];
    },

    guardarPedidoEstim() {
      let costeo = this.trazabilidad.costeo_imp[0].costeo;
      let partidas = this.groupBy(costeo, "partida");
      let objPartidas = Object.entries(partidas);
      this.dialogPartidasTotales = true;
      this.dialogConfirmarGuardarCosteoMultiple = false;
      objPartidas.forEach((e) => {
        e[1].monto_estimado = parseFloat(e[1].monto_estimado.toFixed(2));
        e[1].volumen = parseFloat(e[1].volumen.toFixed(2));
        12;
      });
      this.partidasTotales = objPartidas;

      this.partidasTotales = this.partidasTotales.sort((a, b) => a[0] - b[0]);
      //UNA ESTIMACION
      if (this.trazabilidad.estimacions.length == 1) {
        console.log("first");
        this.trazabilidad.estimacions[0].costeo.forEach((e, index) => {
          this.partidasTotales[index][1].volumen = e.volumen;
        });
        //DOS ESTIMACIONES
      } else if (this.trazabilidad.estimacions.length == 2) {
        this.trazabilidad.estimacions[1].costeo.forEach((e, index) => {
          this.partidasTotales[index][1].volumen = parseFloat(
            this.partidasTotales[index][1].volumen - e.volumen
          ).toFixed(2);
        });
        //TRES ESTIMACIONES
      } else if (this.trazabilidad.estimacions.length == 3) {
        this.trazabilidad.estimacions[2].costeo.forEach((e, index) => {
          let totalJornada =
            (this.partidasTotales[index][1].volumen - e.volumen).toFixed(2) -
            this.trazabilidad.estimacions[1].costeo[index].volumen;
          this.partidasTotales[index][1].volumen = totalJornada.toFixed(2);
        });
      }
    },

    async actualizarTodasPartidas() {
      if (this.trazabilidad.estimacions) {
        this.loadingSincrinize = true;
        for (
          let i = 0;
          i < this.trazabilidad.estimacions[0].costeo.length;
          i++
        ) {
          await this.actualizarCosto(
            i,
            this.partidasTotales[i][1].partida,
            this.partidasTotales[i][1].volumen
          ).finally(() => {});
        }
        this.loadingSincrinize = false;
      }
    },

    async generarEstimacionZero(item) {
      this.dialogLoading = true;
      let costeo = item.costeo_imp[0].costeo;
      let partidas = this.groupBy(costeo, "partida");
      let objPartidas = Object.entries(partidas);
      let partidasTot = [];
      objPartidas.forEach((e) => {
        e[1].monto_estimado = parseFloat(e[1].monto_estimado.toFixed(2));
        e[1].volumen = parseFloat(e[1].volumen.toFixed(2));
      });

      objPartidas = objPartidas.sort((a, b) => a[0] - b[0]);

      objPartidas.forEach((e) => {
        if (e)
          partidasTot.push({
            monto_estimado: e[1].monto_estimado,
            partida: e[1].partida,
            volumen: e[1].volumen,
          });
      });

      let datos = {
        mes: item.costeo_imp[0].mes,
        prorrateo: item.costeo_imp[0].prorrateo,
        no_estimacion: item.costeo_imp[0].no_estimacion,
        total_completo: item.costeo_imp[0].total,
        total_estimacion: item.costeo_imp[0].total,
        costeo: partidasTot,
        pedidos: [],
      };

      await api
        .insertarEstimacion(datos)
        .then(() => {
          this.listar();
          this.addSuccessNotification("Estimación creada correctamente");
        })
        .finally(() => {
          this.limpiarEst();
          this.dialogLoading = false;
        });
    },

    async guardarEstimacionFinal() {
      this.dialogLoading = true;
      let partidas = [];
      if (!this.estimacionUnica) {
        this.partidasTotales.forEach((e) => {
          if (e)
            partidas.push({
              monto_estimado: e[1].monto_estimado,
              partida: e[1].partida,
              volumen: e[1].volumen,
            });
        });
      } else {
        let costeo = this.trazabilidad.costeo_imp[0].costeo;
        let partidasTot = this.groupBy(costeo, "partida");
        let objPartidas = Object.entries(partidasTot);
        objPartidas.forEach((e) => {
          e[1].monto_estimado = parseFloat(e[1].monto_estimado.toFixed(2));
          e[1].volumen = parseFloat(e[1].volumen.toFixed(2));
        });

        objPartidas = objPartidas.sort((a, b) => a[0] - b[0]);

        objPartidas.forEach((e) => {
          if (e)
            partidas.push({
              monto_estimado: e[1].monto_estimado,
              partida: e[1].partida,
              volumen: e[1].volumen,
            });
        });
      }
      partidas.forEach((e)=>{
        if(e.partida=='57.1'){
          e.partida = '57.10'
        }else if(e.partida == 57.20){
          e.partida = '57.20'
        }
      })
      let totalPedidos = 0;
      let activosP = [];
      this.activosPedidos.forEach((e) => {
        if (e.total !== undefined) {
          activosP.push(e);
          totalPedidos += parseFloat(e.total);
        }
      });

      let total = this.trazabilidad.costeo_imp[0].total;
      let estimacion = 0;
      if (this.trazabilidad.estimacions.length == 1) {
        estimacion = this.trazabilidad.costeo_imp[0].no_estimacion;
      } else if (this.trazabilidad.estimacions.length == 2) {
        estimacion = this.trazabilidad.costeo_imp[0].no_estimacion + "-A";
      } else if (this.trazabilidad.estimacions.length == 3) {
        estimacion = this.trazabilidad.costeo_imp[0].no_estimacion + "-B";
      }

      let totalRestante =
        this.trazabilidad.costeo_imp[0].restante.toFixed(2) - totalPedidos;
      let datos = {
        mes: new Date(this.trazabilidad.mes),
        prorrateo: this.trazabilidad._id,
        costeo: partidas,
        pedidos: activosP,
        total_estimacion: totalPedidos,
        total_completo: total,
        no_estimacion: estimacion,
      };

      let datos_restante = {
        _id: this.trazabilidad.costeo_imp[0]._id,
        restante: totalRestante,
      };
      let partidasDevengadas = []
      //Partidas activas
      let partidasActivas = [];
      await catalago.obtenerPartidasActivas().then((e) => {
        let total = 0;
        let volumen_total
        e.data.forEach((f) => {
         
          let reg_partidas = partidas.find(
            (res) => res.partida == f.numeroPartida
          );
          if (reg_partidas) {
            console.log(reg_partidas)
            let monto_devengado = parseFloat(f.importe_devengado);
            let jornada_devengado = parseFloat(f.jornada_devengado)
            let monto_partida = parseFloat(reg_partidas.monto_estimado);
            let volumen_partida = parseFloat(reg_partidas.volumen)
            
            total = (monto_devengado + monto_partida).toFixed(2);
            volumen_total = (volumen_partida+jornada_devengado)
            partidasDevengadas.push({
              jornada:parseFloat(volumen_total),
              monto:parseFloat(total),
            })

          } else {
            total = f.importe_devengado;
            volumen_total = f.jornada_devengado
          }

          partidasActivas.push({
            _id: f._id,
            importe_devengado: total,
            volumen_devengado: volumen_total
          });

        });

        partidas.forEach((e,index)=>{
          console.log(e,index)
          e.monto_devengado = partidasDevengadas[index].monto
          e.jornada_devengado = partidasDevengadas[index].jornada
        })

      });

      await api
        .insertarEstimacion(datos)
        .then(async () => {
          await catalago.updateMontoDevengado(partidasActivas).catch((err)=>console.log(err));

          await api.actualizarRestante(datos_restante).then(() => {
            this.listar();
            this.addSuccessNotification("Estimación realizada correctamente");
          });
        })
        .catch(() => {
          this.addErrorNotification();
        })
        .finally(() => {
          this.limpiarEst();
          this.dialogLoading = false;
          this.sheetPedidos = false;
          this.estimacionUnica = false;
          this.dialogLoading = false;
          this.dialogConfirmarGuardarEstimacion = false;
          this.dialogConfirmarGuardarCosteoMultiple = false;
          this.dialogPartidasTotales = false;
        });
    },

    limpiarEst() {
      this.partidasTotales = [];
      this.activosPedidos = [];
      this.trazabilidad = [];
      this.sumaActivo = 0;
      this.sumaEstimacion = 0;
    },

    groupBy(arr, prop) {
      return arr.reduce((groups, item) => {
        var val = item[prop];
        groups[val] = groups[val] || {
          partida: item.partida,
          monto_estimado: 0,
          volumen: 0,
        };
        groups[val].monto_estimado += item.monto_estimado;

        groups[val].volumen += parseFloat(item.volumen);
        return groups;
      }, {});
    },

    mostrarDialogGuardarEstimacion() {
      this.sumaEstimacion = 0;
      this.dialogConfirmarGuardarEstimacion = true;
      let total = 0;
      console.log(this.partidasTotales);
      this.partidasTotales.forEach((e) => {
        e[1].monto_estimado = parseFloat(e[1].monto_estimado).toFixed(2)
        total += Number(e[1].monto_estimado);
        
        /* total+= Number((this.roundedNumber((e[1].monto_estimado))))
         */
      });
      console.log(this.partidasTotales)
      this.sumaEstimacion = this.roundedNumber(total);
    },

    descargarEstimacionExcel(item) {
      console.log(item);
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    roundedNumber(numero, decimales = 2, usarComa = false) {
      console.log("s");
      var opciones = {
        maximumFractionDigits: decimales,
        useGrouping: false,
      };
      usarComa = usarComa ? "es" : "en";
      return new Intl.NumberFormat(usarComa, opciones).format(numero);
    },
  },
};
</script>
<style></style>
