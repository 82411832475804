import moment from "moment";
import jsPDF from "jspdf";
import logo_cicsa from "../../assets/logos/logo_cicsa.png";
import font_bold from "../../assets/Raleway-Bold.ttf";
import font_light from "../../assets/Raleway-Medium.ttf";

export const reporteFallaPdf = async(item, guardar = false) => {
  return new Promise( (resolve, reject) => {
    var doc = new jsPDF("p", "pt", "a4");
    var startX = 40;
    var startY = 20;
    doc.addImage(logo_cicsa, "JPEG", startX + 24, (startY += 5), 54, 40);
  
    doc.addFont(font_bold, "Raleway", "normal");
  
    doc.setFont("Raleway");
    doc.setFontSize(8);
    doc.roundedRect(55, 20, 500, 55, 5, 5);
  
    //ENCABEZADO
    doc.rect(130, 40, 38, 20);
    doc.rect(168, 40, 60, 20);
    doc.rect(228, 40, 45, 20);
    doc.rect(273, 40, 50, 20);
    doc.rect(323, 40, 30, 20);
    doc.rect(353, 40, 25, 20);
    doc.rect(378, 40, 45, 20);
    doc.rect(423, 40, 45, 20);
    doc.rect(468, 40, 40, 20);
    doc.rect(508, 40, 47, 20);
    doc.line(130, 20, 130, 75);
  
    doc.text("CORPORATIVO INDUSTRIAL Y COMERCIAL S.A. DE C.V.", 218, 31);
    doc.text("CLAVE:", 135, 53);
    doc.text("FECHA:", 233, 53);
    doc.text("PAG:", 327, 53);
    doc.text("VERSIÓN", 383, 53);
    doc.text("TIPO", 479, 53);
    doc.text("REPORTE DE FALLA", 288, 72);
    doc.addFont(font_light, "Raleway-Ligth", "normal");
    doc.setFont("Raleway-Ligth");
    doc.text("SOP-41-F-01", 175, 53);
    doc.text("Sept 2023", 278, 53);
    doc.text("1", 363, 53);
    doc.text("Segunda", 430, 53);
    doc.text("Formato", 515, 53);
  
    doc.setFont("Raleway");
  
    doc.text("Fecha", 308, 100);
    doc.roundedRect(335, 88, 90, 15, 3, 3);
    doc.text("Folio", 440, 100);
    doc.roundedRect(462, 88, 90, 15, 3, 3);
  
    doc.setFont("Raleway-Ligth");
    doc.text("R-" + item.folio_falla.toString(), 477, 100);
  
    let fechaFalla = moment(new Date(item.fechaFalla)).format("L");
    doc.text(fechaFalla, 350, 100);
  
    doc.setFontSize(10);
    doc.setLineWidth(1.5);
  
    //DATOS GENERALES
    doc.setFont("Raleway");
  
    doc.setFillColor("#E7E7E7");
    doc.roundedRect(55, 130, 500, 15, 3, 3, "F");
  
    doc.setFontSize(10);
    doc.text("Datos generales", 255, 140);
    doc.setFontSize(8);
    doc.setLineWidth(1);
    doc.text("Activo:", 55, 161);
    doc.line(145, 163, 300, 163);
    doc.text("Marca:", 55, 174);
    doc.line(145, 176, 300, 176);
    doc.text("Modelo:", 55, 187);
    doc.line(145, 189, 300, 189);
    doc.text("Numero de Serie:", 55, 200);
    doc.line(145, 202, 300, 202);
  
    doc.text("Operador:", 308, 161);
    doc.line(395, 163, 550, 163);
    doc.text("Estatus:", 308, 174);
    doc.line(395, 176, 550, 176);
    doc.text("Numero Economico:", 308, 187);
    doc.line(395, 189, 550, 189);
  
    doc.setFont("Raleway-Ligth");
    doc.setFontSize(8);
    doc.text(item.activo.nombreActivo, 150, 161);
    doc.text(item.activo.marca, 150, 174);
    doc.text(texto_truncado(item.activo.modelo, 32), 150, 187);
    doc.text(item.activo.numeroSerie, 150, 200);
  
    doc.text(texto_truncado(item.operador, 28), 403, 161);
    doc.text(item.activo.estatu, 403, 174);
    doc.text(item.activo.numeroEconomico, 403, 187);
  
    //tipo de falla
    doc.setFillColor("#E7E7E7");
    doc.setFont("Raleway");
  
    doc.roundedRect(55, 220, 500, 15, 3, 3, "F");
    doc.setFontSize(10);
  
    doc.text("Tipo de falla", 260, 230);
  
    doc.setFontSize(8);
    doc.setFont("Raleway-Ligth");
  
    doc.text("Mecánica", 85, 250);
    doc.text("Hidráulica", 175, 250);
    doc.text("Eléctrica", 275, 250);
    doc.text("Neumática", 365, 250);
    doc.text("Otro* Especificar", 455, 250);
  
    item.tipoFalla == "Mecánica"
      ? doc.roundedRect(100, 260, 10, 10, 3, 3, "F")
      : doc.roundedRect(100, 260, 10, 10, 3, 3);
  
    item.tipoFalla == "Hidráulica"
      ? doc.roundedRect(190, 260, 10, 10, 3, 3, "F")
      : doc.roundedRect(190, 260, 10, 10, 3, 3);
  
    item.tipoFalla == "Électrica"
      ? doc.roundedRect(290, 260, 10, 10, 3, 3, "F")
      : doc.roundedRect(290, 260, 10, 10, 3, 3);
  
    item.tipoFalla == "Neumática"
      ? doc.roundedRect(380, 260, 10, 10, 3, 3, "F")
      : doc.roundedRect(380, 260, 10, 10, 3, 3);
  
    if (
      item.tipoFalla !== "Mecánica" &&
      item.tipoFalla !== "Électrica" &&
      item.tipoFalla !== "Hidráulica" &&
      item.tipoFalla !== "Neumática"
    ) {
      doc.roundedRect(420, 257, 137, 15, 3, 3);
      doc.text(texto_truncado(item.tipoFalla, 28), 422, 268);
    } else {
      doc.roundedRect(480, 260, 10, 10, 3, 3);
    }
  
    //DESCRIPCION DE LA FALLA
    doc.setFont("Raleway");
  
    doc.setFillColor("#E7E7E7");
    doc.roundedRect(55, 290, 500, 15, 3, 3, "F");
    doc.setFontSize(10);
    doc.text("Descripción de la falla", 250, 300);
    doc.roundedRect(55, 310, 500, 60, 5, 5);
    doc.setFontSize(7);
    doc.setFont("Raleway-Ligth");
    doc.text(item.descripcionFalla, 58, 325);
  
    //DIAGNOSTICO DE MANTENIMIENTO
    doc.setFont("Raleway");
  
    doc.setFillColor("#E7E7E7");
    doc.roundedRect(55, 390, 500, 15, 3, 3, "F");
    doc.setFontSize(10);
    doc.text("Diagnóstico de mantenimiento", 240, 400);
    doc.setFontSize(8);
    doc.setFont("Raleway-Ligth");
    doc.text("Fecha de diagnóstico", 58, 420);
    doc.text("Requisiciones", 58, 540);
    doc.text("Mecanicos asigandos", 58, 590);
  
    doc.setFont("Raleway");
  
    if (item.fechaDiagnostico) {
      let fechaDiagnostico = moment(new Date(item.fechaDiagnostico)).format("L");
      doc.text(fechaDiagnostico, 150, 420);
    } else {
      if (item.fechaAsignacion && item.estatus == "Cerrado") {
        let fechaAsignacion = moment(new Date(item.fechaAsignacion)).format("L");
        doc.text(fechaAsignacion, 150, 420);
      }
    }
    doc.setFont("Raleway-Ligth");
    doc.line(148, 423, 250, 423);
    doc.text("Unidad operable:", 268, 420);
    doc.text("Si", 340, 420);
    item.activo.estatu == "Fuera de Servicio"
      ? doc.roundedRect(355, 412, 10, 10, 3, 3)
      : doc.roundedRect(355, 412, 10, 10, 3, 3, "F");
  
    doc.text("No", 375, 420);
    item.activo.estatu == "Operativo"
      ? doc.roundedRect(395, 412, 10, 10, 3, 3)
      : doc.roundedRect(395, 412, 10, 10, 3, 3, "F");
    doc.roundedRect(55, 435, 500, 80, 5, 5);
  
    doc.text("Prioridad", 435, 420);
    doc.line(478, 423, 553, 423);
  
    if (item.diagnostico) {
      doc.setFontSize(7);
      doc.text(item.diagnostico, 58, 452);
    }
    doc.setFontSize(8);
    doc.setFont("Raleway");
    if (item.prioridad) {
      doc.text(item.prioridad, 485, 420);
    }
    doc.roundedRect(55, 550, 500, 15, 3, 3);
    if (item.requisiciones) {
      doc.text(item.requisiciones, 58, 560);
    }
    doc.roundedRect(55, 600, 500, 15, 3, 3);
    if (item.mecanico) {
      doc.text(item.mecanico.toString(), 58, 610);
    }
  
    let fechaCreacion = moment(new Date(item.createdAt)).format("LLLL");
  
    doc.text("Solicita", 55, 680);
    doc.text("Área Operativa", 55, 720);
    doc.text("Área de Mantenimiento", 55, 770);
  
    doc.setFont("Raleway-Ligth");
    doc.text(item.operador, 55, 690);
    doc.text(fechaFalla, 55, 700);
    doc.text(item.usuarioCreador.nombreCompleto, 55, 730);
    doc.text(item.usuarioCreador.correo, 55, 740);
    doc.text(fechaCreacion, 55, 750);
    if (item.mecanico) {
      doc.text(item.mecanico.toString(), 55, 780);
    }
    if (item.fechaAsignacion) {
      let fechaAsignacion = moment(new Date(item.fechaAsignacion)).format("LLLL");
      doc.text(fechaAsignacion, 55, 790);
    }
    try{
      if (guardar == true) {
        var blob = doc.output("blob");
        var url = URL.createObjectURL(blob);
        var ventanaPopup = window.open("", "_blank", "width=900,height=800");
        ventanaPopup.document.write(
          '<embed src="' +
            url +
            '" type="application/pdf" width="100%" height="100%" />'
        );
        resolve()
      } else {
        var documento = doc.output("datauristring");
        resolve(documento)
      }
    }catch(error){
      reject(error);
    }
   
  })
};

const texto_truncado = (item, tamanio) => {
  if (item.length > tamanio) {
    return item.substring(0, tamanio) + "...";
  } else {
    return item;
  }
};
