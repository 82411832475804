import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import config from '@/config'
import 'font-awesome/css/font-awesome.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark:false,
        
        themes: {
            dark: {
                primary: '#B21E28',
                secondary: '#231F20',
                accent: '#8c9eff',
                error: '#ff5252',
              },
            light: config.light
        }
    }
});
