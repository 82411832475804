<template>
  <v-app class="pa-6">
    <Header v-if="logueado" />
    <Sidebar />

    <v-main class="content">
      <div>
        <transition appear name="fade">
          <router-view />
        </transition>

      </div>


    </v-main>
  </v-app>
</template>

<script>
import Header from '@/components/Header/Header';
import Sidebar from '@/components/Sidebar/Sidebar';
import './Layout.scss';

export default {
  name: 'Layout',
  components: { Header, Sidebar },
  data() {
    return {
    }
  },
  computed: {
    logueado() {
      return this.$store.state.usuario
    },

  },
  methods: {

  },
  created() {

  }
};
</script>


<style >
.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0
}
</style>