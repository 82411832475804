<template>
    <div>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div> <br><br>
            <v-card class="ma-9 custom-card">
                <v-responsive :aspect-ratio="16 / 9">
                    <v-card-text>
                        <h2>Historial de Prorrateos</h2>
                        <span class="text-info">Selecciona el mes</span>
                        <v-row>
                            <v-col cols="4" md="4" lg="4">
                                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined dense class="mt-4" v-model="mesServicio" label="Mes"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="es" v-model="mesServicio" type="month"
                                        @input="mostrarProrrateos"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-tabs v-model="tab" background-color="primary">
                            <v-tab v-for="(item, index) in activos" :key="index">
                                <span class="tab" v-if="item.activoPemex.length > 0">{{ item.activoPemex }}</span>
                                <span class="tab" v-else> Sin Prorratear</span>
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item v-for="(item, index) in activos" :key="index">
                                <v-card class="custom-card">
                                    <v-chip class="ma-2" color="#BBDEFB" small v-for="(equipo, index) in item.equipo"
                                        :key=index>
                                        {{ equipo }}
                                    </v-chip>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>

                        <v-expansion-panels style="border-radius: 20px" class="pa-6 ma-2">
                            <v-expansion-panel v-for="(item, index) in panels" :key="index">
                                <v-expansion-panel-header style=" background-color: #f0f0f0; ">
                                    <template v-slot:actions>
                                        <h6 class="" style="color: #62455b">{{ item }}</h6>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <br>
                                    <v-card>
                                        <v-card-title>
                                            <div v-if="item == 'Gruas'">
                                                <h3>Gruas Prorrateadas</h3>
                                            </div>
                                            <div v-if="item == 'Cargadores Frontales'">
                                                <h3>Cargadores Frontales</h3>
                                            </div>
                                            <div v-if="item == 'Montacargas'">
                                                <h3>Montacargas</h3>
                                            </div>
                                            <div v-if="item == 'Tractocamiones'">
                                                <h3>Tractocamiones</h3>
                                            </div>
                                            <div v-if="item == 'Camión con Grua'">
                                                <h3>Montacargas</h3>
                                            </div>
                                            <div v-if="item == 'Cuadrillas'">
                                                <h3>Montacargas</h3>
                                            </div>
                                            <v-spacer></v-spacer>
                                            <v-text-field v-if="item == 'Gruas'" v-model="searchGruas"
                                                append-icon="mdi-magnify" label="Buscar" single-line
                                                hide-details></v-text-field>
                                            <v-text-field v-if="item == 'Cargadores Frontales'" v-model="searchCF"
                                                append-icon="mdi-magnify" label="Buscar" single-line
                                                hide-details></v-text-field>
                                            <v-text-field v-if="item == 'Montacargas'" v-model="searchMt"
                                                append-icon="mdi-magnify" label="Buscar" single-line
                                                hide-details></v-text-field>
                                            <v-text-field v-if="item == 'Tractocamiones'" v-model="searchT"
                                                append-icon="mdi-magnify" label="Buscar" single-line
                                                hide-details></v-text-field>
                                            <v-text-field v-if="item == 'Camión con Grua'" v-model="searchCG"
                                                append-icon="mdi-magnify" label="Buscar" single-line
                                                hide-details></v-text-field>
                                            <v-text-field v-if="item == 'Cuadrillas'" v-model="searchCua"
                                                append-icon="mdi-magnify" label="Buscar" single-line
                                                hide-details></v-text-field>
                                        </v-card-title>
                                        <v-data-table :search="searchGruas" v-if="item == 'Gruas'"
                                            :headers="headersEquiposProrratear" :items="gruas" :items-per-page="20" dense
                                            class="elevation-1">
                                            <template v-slot:[`item.montoEstimado`]="{ item }">
                                                ${{ costoToDollar(item.montoEstimado.toFixed(2)) }}
                                            </template>
                                        </v-data-table>
                                        <v-data-table :search="searchCF" v-if="item == 'Cargadores Frontales'"
                                            :headers="headersEquiposProrratear" :items="cgf" :items-per-page="20" dense
                                            class="elevation-1">
                                            <template v-slot:[`item.montoEstimado`]="{ item }">
                                                ${{ costoToDollar(item.montoEstimado.toFixed(2)) }}
                                            </template>
                                        </v-data-table>
                                        <v-data-table :search="searchMt" v-if="item == 'Montacargas'"
                                            :headers="headersEquiposProrratear" :items="mtc" :items-per-page="20" dense
                                            class="elevation-1">
                                            <template v-slot:[`item.montoEstimado`]="{ item }">
                                                ${{ costoToDollar(item.montoEstimado.toFixed(2)) }}
                                            </template>
                                        </v-data-table>
                                        <v-data-table :search="searchT" v-if="item == 'Tractocamiones'"
                                            :headers="headersEquiposProrratear" :items="t" :items-per-page="20" dense
                                            class="elevation-1">
                                            <template v-slot:[`item.montoEstimado`]="{ item }">
                                                ${{ costoToDollar(item.montoEstimado.toFixed(2)) }}
                                            </template>
                                        </v-data-table>
                                        <v-data-table :search="searchCG" v-if="item == 'Camión con Grua'"
                                            :headers="headersEquiposProrratear" :items="m" :items-per-page="20" dense
                                            class="elevation-1">
                                            <template v-slot:[`item.montoEstimado`]="{ item }">
                                                ${{ costoToDollar(item.montoEstimado.toFixed(2)) }}
                                            </template>
                                        </v-data-table>
                                        <v-data-table :search="searchCua" v-if="item == 'Cuadrillas'"
                                            :headers="headersEquiposProrratear" :items="restante" :items-per-page="20" dense
                                            class="elevation-1">
                                            <template v-slot:[`item.montoEstimado`]="{ item }">
                                                ${{ costoToDollar(item.montoEstimado.toFixed(2)) }}
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-responsive>
            </v-card>
        </div>
    </div>
</template>

<script>
import Costeo from '../../api/Costeo'
export default {
    data() {
        return {
            menu2: false,
            mesServicio: "",
            loading: false,
            prorrateos: [],
            activos: [],
            gruas: [],
            cgf: [],
            mtc: [],
            t: [],
            m: [],
            restante: [],
            tab: null,
            panels: ['Gruas', 'Cargadores Frontales', 'Montacargas', 'Tractocamiones', 'Camión con Grua', 'Cuadrillas'],
            searchGruas: '',
            searchCF: '',
            searchMt: '',
            searchT: '',
            searchCG: '',
            searchCua: '',
            headersEquiposProrratear: [
                { text: "Activo", value: "activoEquipo", sortable: true },
                { text: "Fecha", value: "fechaServicio", sortable: true },
                { text: "Monto", value: "montoEstimado", sortable: true },
                { text: "Partida", value: "partida", sortable: true },
                { text: "Volumen", value: "volumen", sortable: true },
                { text: "Activo", value: "activoPemex", sortable: true },
                { text: "EPEP", value: "epep", sortable: true },
                { text: "PP", value: "programaPresupuestal", sortable: true },
            ],
        }
    },
    methods: {
        mostrarProrrateos() {
            const mes = new Date(this.mesServicio)
            this.menu2 = false
            this.loading = true
            Costeo.listarProrrateoMes(mes).then((e) => {
                this.prorrateos = e.data
                this.addSuccessNotification('Datos cargados correctamente')
                this.mostrarActivosPemex(e.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.loading = false
            })
        },

        mostrarActivosPemex(data) {
            const activosUnicos = new Set()
            const resultado = []
            let activos_array = []
            if (data.trazabilidad) {
                data.trazabilidad.forEach((e) => {
                    if (e.numeroDeSolicitud !== null && e.numeroDeSolicitud.length < 3) {
                        activosUnicos.add(e.activoPemex)

                    }

                })
                activos_array = Array.from(activosUnicos)


                data.trazabilidad.forEach(item => {
                    if (item.numeroDeSolicitud !== null && item.numeroDeSolicitud.length < 3) {
                        if (activos_array.includes(item.activoPemex)) {
                            let found = resultado.find(el => el.activoPemex === item.activoPemex);
                            if (!found) {
                                found = { activoPemex: item.activoPemex, equipo: [] };
                                resultado.push(found);
                            }
                            if (!found.equipo.includes(item.activoEquipo)) {
                                found.equipo.push(item.activoEquipo);
                            }
                        }
                    }

                });

                const gruas = []
                const cgf = []
                const mtc = []
                const t = []
                const m = []
                const restante = []
                data.trazabilidad.forEach((e) => {
                    if (e.numeroDeSolicitud == null) {
                        if (e.activoEquipo.substring(0, 2) == 'G-') {
                            gruas.push(e)
                        } else if (e.activoEquipo.substring(0, 4) == 'CGF-') {
                            cgf.push(e)
                        } else if (e.activoEquipo.substring(0, 4) == 'MTC-') {
                            mtc.push(e)
                        } else if (e.activoEquipo.substring(0, 2) == 'T-') {
                            t.push(e)
                        } else if (e.activoEquipo.substring(0, 2) == 'M-') {
                            m.push(e)
                        } else {
                            restante.push(e)
                        }
                    }
                    if (e.numeroDeSolicitud !== null && e.numeroDeSolicitud.length < 3) {
                        if (e.activoEquipo.substring(0, 2) == 'G-') {
                            gruas.push(e)
                        } else if (e.activoEquipo.substring(0, 4) == 'CGF-') {
                            cgf.push(e)
                        } else if (e.activoEquipo.substring(0, 4) == 'MTC-') {
                            mtc.push(e)
                        } else if (e.activoEquipo.substring(0, 2) == 'T-') {
                            t.push(e)
                        } else if (e.activoEquipo.substring(0, 2) == 'M-') {
                            m.push(e)
                        } else {
                            restante.push(e)
                        }
                    }


                })
                this.gruas = gruas
                this.cgf = cgf
                this.mtc = mtc
                this.t = t
                this.m = m
                this.restante = restante
            }

            this.activos = resultado
        },

        costoToDollar(text) {
            let number = parseFloat(text);
            return number.toLocaleString("en-US");
        },

        addSuccessNotification(mensaje) {
            this.$toast.success(mensaje, {
                position: "top-right",
                timeout: 2000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
            });
        },
    },

}
</script>

<style  lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

h1,

h2,
h3,
h4,
h5,
h6 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700; // Puedes ajustar esto según tus preferencias
    color: #333; // Color del texto para los encabezados
    // Agrega otros estilos como tamaño de fuente, márgenes, etc. según tus preferencias
}

span.text-info {
    font-family: 'Raleway', sans-serif;
    color: #333; // Color del texto para los encabezados
}

span.tab {
    font-family: 'Raleway', sans-serif;
    color: #f1f1f1; // Color del texto para los encabezados
    font-size: 10px;
}

// Estilos adicionales para hacer que los encabezados se vean atractivos
h1 {
    font-size: 36px;
    text-shadow: 2px 2px 2px #ccc;
}

h2 {
    font-size: 30px;
    text-shadow: 1px 1px 1px #ccc;
}

h3 {
    font-size: 24px;
    text-shadow: 1px 1px 1px #ccc;
}

h4 {
    font-size: 20px;
    text-shadow: 1px 1px 1px #ccc;
}

h5 {
    font-size: 18px;
    text-shadow: 1px 1px 1px #ccc;
}

h6 {
    font-size: 16px;
    text-shadow: 1px 1px 1px #ccc;
}

.custom-card {
    margin: 10px;
    border: 12px solid #ccc;
    border-radius: 60px;
    box-shadow: 0px 4px 8px rgba(207, 207, 207, 0.1);

}</style>