import axios from "axios";
import store from "../store/index";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 500 &&
      error.response.data.error === "Token no valido"
    ) {
      store.dispatch("salir"); // Asumiendo que tienes una mutación 'logout' para borrar el token
      //router.push('/login'); // Redirige al login
    }
    return Promise.reject(error);
  }
);

export default {
  obtenerActivosSelect() {
    return axios.get("/activos/obtenerActivosReporteFallas");
  },

  obtenerActivosPemex(page) {
    return axios.get("/horometro/listEquipos?page=" + page);
  },
  obtenerHorometroEquipo(equipo, page) {
    return axios.get(
      "/horometro/listHorometroEquipo?page=" + page + "&activo=" + equipo
    );
  },
  insertarOrometro(datos) {
    return axios.post("/horometro/add", datos);
  },
  updateUbicacionEquipo(datos) {
    return axios.put("/activos/updateUbicacion", datos);
  },
  listarReporteFallas() {
    return axios.get("/reporteFallas/listReporteFalla");
  },
  reporteFallaId(datos) {
    return axios.get("/reporteFallas/reporteFallaId?_id=" + datos);
  },
  listarTodosReportes(page, datos) {
    const token = store.state.token;
    return axios.post("/reporteFallas/list?page=" + page, datos, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  listarReportesCerrados() {
    return axios.get("/reporteFallas/listarReportesCerrados");
  },
  obtenerReportesDashboard() {
    return axios.get("/reporteFallas/reporteFallaDash");
  },
  obtenerReporteEquipos() {
    return axios.get("/reporteFallas/reporteFallaEquipo");
  },
  actualizarEstatusEquipo(datos) {
    return axios.put("/activos/updateEstatusEquipo", datos);
  },
  obtenerCountTipoReportes() {
    return axios.get("/reporteFallas/reporteFallaDashTipoFalla");
  },
  obtenerCountEstatusReportes() {
    return axios.get("/reporteFallas/reporteFallaDashEstatusFalla");
  },
  obtenerReportesMesCount() {
    return axios.get("/reporteFallas/reporteFallaDashMesCount");
  },
  obtenerReportesTipoUnidadCount() {
    return axios.get("/reporteFallas/reporteFallaDashTipoUnidadCount");
  },
  obtenerTipoUnidadMasFallas() {
    return axios.get("/reporteFallas/listEquiposMasReportes");
  },
  eliminarReporteFallas(_id) {
    return axios.delete("/reporteFallas/eliminar_equipo?_id=" + _id);
  },
  updateEstatusReporteFallas(_id, estatus) {
    return axios.put(
      "/reporteFallas/actualizar_estatus?_id=" + _id + "&estatus=" + estatus
    );
  },
  updateEstatusReporteFallasRevision(_id, estatus) {
    return axios.put(
      "/reporteFallas/actualizar_estatus_revision?_id=" +
        _id +
        "&estatus=" +
        estatus
    );
  },
  obtenerReportesFallasMtto(page, filtro) {
    const token = store.state.token;
    return axios.post("/reporteFallas/list_mtto/" + page, filtro, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  listarMecanicosActivos() {
    return axios.get("/mecanico/listActivos");
  },
  asignarMecanico(datos) {
    return axios.put("/reporteFallas/asignar_reporte", datos);
  },
  obtenerDetalleEquipoId(_id) {
    return axios.get("/activos/obtenerDetalleEquipoPorId?_id=" + _id);
  },
  obtenerReportesPorEquipo(equipo) {
    return axios.get(
      "/reporteFallas/obtener_reporte_por_equipo?equipo=" + equipo
    );
  },
  obtenerMotivosReporte(datos) {
    return axios.post("/reporteFallas/obtener_motivos_reporte", datos);
  },
  diagnosticarReporteFalla(datos) {
    return axios.put("/reporteFallas/diagnosticar_reporte", datos);
  },
  queryReporteFallaFolio(id) {
    return axios.get("/reporteFallas/query_folio?folio_falla=" + id);
  },
  dashboardReporteFallas() {
    return axios.put("reporteFallas/dashboard_graficas");
  },
  listFoliosRF() {
    return axios.get("/reporteFallas/list_folios_descripcion");
  },

  //OT
  nuevaOrdenTrabajo(datos) {
    return axios.post("/ot/add", datos);
  },
  listarOrdenTrabajo(page, filtro) {
    return axios.post("/ot/list/" + page, filtro);
  },
  updateOrdenTrabajo(datos) {
    return axios.put("/ot/update", datos);
  },
  pauseOrdenTrabajo(id, motivo) {
    return axios.put("/ot/pause?_id=" + id + "&motivo=" + motivo);
  },
  playOrdenTrabajo(id) {
    return axios.put("/ot/play?_id=" + id);
  },
  adjuntarEvidencia(datos) {
    return axios.put("/ot/adjuntarEvidencia", datos);
  },
  adjuntarEvidenciaDoc(datos) {
    return axios.put("/ot/adjuntarEvidenciaDoc", datos);
  },
  mostrarRefacciones() {
    return axios.get("/ot/obtener_refacciones");
  },
  agregarRequisiciones() {
    return axios.put("/ot/agregar_requi");
  },
  mostrarReportesDesglosado() {
    return axios.get("/ot/obtener_reportes_equipo");
  },
  descargarOtExcel(datos) {
    return axios.put("/ot/generate_excel_sop", datos);
  },
  descargarRfExcel(datos) {
    const token = store.state.token;
    return axios.put("/ot/generate_excel_rf_sop", datos, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  obtenerOtPorRF(rf) {
    return axios.get("/ot/obtener_ot_rf/" + rf);
  },
  agregarReporteFallaOT(datos) {
    return axios.put("/ot/asignar_rf_ot/", datos);
  },

  //DISPONIBILIDAD DE EQUIPOS
  cambiarEstatusEquipo(datos) {
    return axios.post("/disponibilidad/add", datos);
  },

  //Mostrar imagenes de ot
  obtenerImagenesOt(datos) {
    return axios.post("/ot/obtener_imagenes_ot", datos);
  },

  obtenerTotaOtPorCreador() {
    return axios.get("/ot/ot_total_creador");
  },
  obtenerEnEsperaOtPorCreador() {
    return axios.get("/ot/ot_en_espera_creador");
  },
  obtenerAbiertaOtPorCreador() {
    return axios.get("/ot/ot_abiertas_creador");
  },
  async downloadReportesFallas(datos) {
    return await axios.put("/reporteFallas/download_reportes_falla", datos, {
      responseType: "blob",
    });
  },
  obtenerCerradaOtPorCreador() {
    return axios.get("/ot/ot_cerradas_creador");
  },
  eliminarEvidenciaFotografica(datos) {
    return axios.put("/ot/delete_evidence_photo", datos);
  },
  async firmarSolicitudLog(_id) {
    const token = store.state.token;
    return await axios.put(
      "/reporteFallas/firma_log/" + _id,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  async firmarMtto(_id) {
    const token = store.state.token;
    return await axios.put(
      "/reporteFallas/firma_mtto/" + _id,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  async firmarOtMtto(_id) {
    const token = store.state.token;
    return await axios.put(
      "/ot/firma_mtto/" + _id,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  async reportFallaUsuarioCreador(personal, page, arr) {
    const token = store.state.token;
    return await axios.post(
      "reporteFallas/reporteFallaPorUsuarioCreador?creador=" +
        personal +
        "&page=" +
        page,
      arr,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
};
