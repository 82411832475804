<template>
  <v-container fluid>
    <div class="tables-basic">
      <br /><br /><br /><br />
      <h1>Prorrateo</h1>
      <v-dialog v-model="dialogApk" transition="dialog-bottom-transition" max-width="900">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Agregar activo APC-EK-BALAM</v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Activo</th>
                      <th class="text-left">PP</th>
                      <th class="text-left">EPEP</th>
                      <th class="text-left">Total</th>
                      <th class="text-left">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in apcEk_balam.children" :key="item.name">
                      <td v-if="item.total <= 10">{{ item.activo }}</td>
                      <td v-if="item.total <= 10">{{ item.pp }}</td>
                      <td v-if="item.total <= 10">{{ item.epep }}</td>
                      <td v-if="item.total <= 10">{{ item.total }}</td>
                      <td v-if="item.total <= 10">
                        <v-btn @click="guardarActivoApcEB(item)" icon color="success">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialogApk = false">Close</v-btn>
              <v-btn text @click="dialogApk = false">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogGuardarProrrateo" persistent max-width="490">
        <v-card>
          <v-card-title class="text-h5">
            ¿Seguro que decea guardar el prorrateo del mes seleccionado?
          </v-card-title>
          <v-card-text
            >Al dar click al boton confirmar, el registro de la trazabilidad se guardara
            con los registros EPEP,ACTIVO y PP prorrateados</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialogGuardarProrrateo = false">
              Cancelar
            </v-btn>
            <v-btn color="green darken-1" text @click="guardarCosteo"> Confirmar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-if="listaActivosPorProrratear"
        v-model="dialogActivosParaProrratear"
        max-width="790"
      >
        <v-card>
          <v-card-title v-if="listaActivosPorProrratear[0]">
            {{ listaActivosPorProrratear[0].activo }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
            v-if="tablasActivosDeglosado == true"
            :headers="tebsActivosGeneralDesglosado"
            :items="listaActivosPorProrratear"
            :items-per-page="15"
            dense
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.porcentaje`]="{ item }">
              {{ parseInt(item.porcentaje) }}%
            </template>
            <template v-slot:[`item.montoDesglosado`]="{ item }">
              ${{ costoToDollar(parseFloat(item.montoDesglosado).toFixed(2)) }}
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogActivosProrratearSocket" max-width="990">
        <v-card>
          <v-card-title>
            Activos
            <v-spacer></v-spacer>
            <v-btn icon color="green" @click="listarActivosProrrateo(mesServicio)">
              <v-icon>mdi-cached</v-icon>
            </v-btn>
          </v-card-title>
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header> <template v-slot:actions>
                      <h6 class="" style="color: #62455b">AEMS</h6>
                    </template>
                    <h6 class="" style="color: #62455b">
                      ${{ costoToDollar(parseFloat(totalesActivosProrratear.sumAems).toFixed(2)) }}
                    </h6></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="activosProrratearSocketHeader"
                  :items="activosProrratearSocket.aems"
                  disable-pagination
                  :hide-default-footer="true"
                  dense
                  class="elevation-1"
                >
                  <template v-slot:[`item.total`]="{ item }">
                    <b> ${{ costoToDollar(parseFloat(item.total).toFixed(2)) }}</b>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> <template v-slot:actions>
                      <h6 class="" style="color: #62455b">APAPCH</h6>
                    </template>
                    <h6 class="" style="color: #62455b">
                      ${{ costoToDollar(parseFloat(totalesActivosProrratear.sumApapch).toFixed(2)) }}
                    </h6></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="activosProrratearSocketHeader"
                  :items="activosProrratearSocket.apapch"
                  disable-pagination
                  :hide-default-footer="true"
                  dense
                  class="elevation-1"
                >
                  <template v-slot:[`item.total`]="{ item }">
                    <b> ${{ costoToDollar(parseFloat(item.total).toFixed(2)) }}</b>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> <template v-slot:actions>
                      <h6 class="" style="color: #62455b">APC</h6>
                    </template>
                    <h6 class="" style="color: #62455b">
                      ${{ costoToDollar(parseFloat(totalesActivosProrratear.sumapc).toFixed(2)) }}
                    </h6></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="activosProrratearSocketHeader"
                  :items="activosProrratearSocket.apc"
                  disable-pagination
                  :hide-default-footer="true"
                  dense
                  class="elevation-1"
                >
                  <template v-slot:[`item.total`]="{ item }">
                    <b> ${{ costoToDollar(parseFloat(item.total).toFixed(2)) }}</b>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> <template v-slot:actions>
                      <h6 class="" style="color: #62455b">APKMZ</h6>
                    </template>
                    <h6 class="" style="color: #62455b">
                      ${{ costoToDollar(parseFloat(totalesActivosProrratear.sumApkmz).toFixed(2)) }}
                    </h6></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="activosProrratearSocketHeader"
                  :items="activosProrratearSocket.apkmz"
                  disable-pagination
                  :hide-default-footer="true"
                  dense
                  class="elevation-1"
                >
                  <template v-slot:[`item.total`]="{ item }">
                    <b> ${{ costoToDollar(parseFloat(item.total).toFixed(2)) }}</b>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> <template v-slot:actions>
                      <h6 class="" style="color: #62455b">APLT</h6>
                    </template>
                    <h6 class="" style="color: #62455b">
                      ${{ costoToDollar(parseFloat(totalesActivosProrratear.sumAplt).toFixed(2)) }}
                    </h6></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="activosProrratearSocketHeader"
                  :items="activosProrratearSocket.aplt"
                  disable-pagination
                  :hide-default-footer="true"
                  dense
                  class="elevation-1"
                >
                  <template v-slot:[`item.total`]="{ item }">
                    <b> ${{ costoToDollar(parseFloat(item.total).toFixed(2)) }}</b>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> <template v-slot:actions>
                      <h6 class="" style="color: #62455b">SPEE</h6>
                    </template>
                    <h6 class="" style="color: #62455b">
                      ${{ costoToDollar(parseFloat(totalesActivosProrratear.sumSpee).toFixed(2)) }}
                    </h6></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="activosProrratearSocketHeader"
                  :items="activosProrratearSocket.spee"
                  disable-pagination
                  :hide-default-footer="true"
                  dense
                  class="elevation-1"
                >
                  <template v-slot:[`item.total`]="{ item }">
                    <b> ${{ costoToDollar(parseFloat(item.total).toFixed(2)) }}</b>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> <template v-slot:actions>
                      <h6 class="" style="color: #62455b">APC-EK-BALAM</h6>
                    </template>
                    <h6 class="" style="color: #62455b">
                      ${{ costoToDollar(parseFloat(totalesActivosProrratear.sumApc_ek_blam).toFixed(2)) }}
                    </h6></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="activosProrratearSocketHeader"
                  :items="activosProrratearSocket.apc_ek_blm"
                  disable-pagination
                  :hide-default-footer="true"
                  dense
                  class="elevation-1"
                >
                  <template v-slot:[`item.total`]="{ item }">
                    <b> ${{ costoToDollar(parseFloat(item.total).toFixed(2)) }}</b>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header> <template v-slot:actions>
                      <h6 class="" style="color: #62455b">Bloque Norte</h6>
                    </template>
                    <h6 class="" style="color: #62455b">
                      ${{ costoToDollar(parseFloat(totalesActivosProrratear.sumBn).toFixed(2)) }}
                    </h6></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="activosProrratearSocketHeader"
                  :items="activosProrratearSocket.bn"
                  disable-pagination
                  :hide-default-footer="true"
                  dense
                  class="elevation-1"
                >
                  <template v-slot:[`item.total`]="{ item }">
                    <b> ${{ costoToDollar(parseFloat(item.total).toFixed(2)) }}</b>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> <template v-slot:actions>
                      <h6 class="" style="color: #62455b">AEMS1</h6>
                    </template>
                    <h6 class="" style="color: #62455b">
                      ${{ costoToDollar(parseFloat(totalesActivosProrratear.sumAems1).toFixed(2)) }}
                    </h6></v-expansion-panel-header>              <v-expansion-panel-content>
                <v-data-table
                  :headers="activosProrratearSocketHeader"
                  :items="activosProrratearSocket.aems1"
                  disable-pagination
                  :hide-default-footer="true"
                  dense
                  class="elevation-1"
                >
                  <template v-slot:[`item.total`]="{ item }">
                    <b> ${{ costoToDollar(parseFloat(item.total).toFixed(2)) }}</b>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header> <template v-slot:actions>
                      <h6 class="" style="color: #62455b">AEMS3</h6>
                    </template>
                    <h6 class="" style="color: #62455b">
                      ${{ costoToDollar(parseFloat(totalesActivosProrratear.sumAems3).toFixed(2)) }}
                    </h6></v-expansion-panel-header>              <v-expansion-panel-content>
                <v-data-table
                  :headers="activosProrratearSocketHeader"
                  :items="activosProrratearSocket.aems3"
                  disable-pagination
                  :hide-default-footer="true"
                  dense
                  class="elevation-1"
                >
                  <template v-slot:[`item.total`]="{ item }">
                    <b> ${{ costoToDollar(parseFloat(item.total).toFixed(2)) }}</b>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogParaProrratear"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialogParaProrratear = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Salir</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <br />
          <template>
            <v-card>
              <v-tabs v-model="tab" background-color="black" dark>
                <v-tab v-for="item in activosProrrateadosEquipo" :key="item.tab">
                  {{ item.tab }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in activosProrrateadosEquipo" :key="item.tab">
                  <v-card flat>
                    <v-card-text>
                      <v-chip
                        class="ma-2"
                        small
                        color="#BBDEFB"
                        v-for="(equipo, index) in item.equipos"
                        :key="index"
                        >{{ equipo }}
                        <v-icon
                          small
                          v-if="
                            equipo.substring(0, 4) !== 'CABO' &&
                            equipo.substring(0, 4) !== 'MANI'
                          "
                          right
                          @click="mostrarProrrateoEquiposTab(equipo, item.tab)"
                        >
                          mdi-folder-eye
                        </v-icon>
                      </v-chip>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </template>

          <!--   <v-row> -->
          <!-- <v-col v-if="activosTotalGeneral[0]" cols="21" md="2" lg="2">
              <v-card color="#385F73" dark class="ml-2 mt-1">
                <v-card-title class="text-h6 text-center"> AEMS </v-card-title>

                <v-card-subtitle
                  >${{
                    costoToDollar(
                      parseFloat(activosTotalGeneral[0].monto).toFixed(2)
                    )
                  }}</v-card-subtitle
                >
                <v-card-actions>
                  <v-btn
                    text
                    @click="
                      mostrarProrrateosActivos(activosTotalGeneral[0].aems)
                    "
                  >
                    Desglosar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col v-if="activosTotalGeneral[1]" cols="2" md="2" lg="2">
              <v-card color="#385F73" dark class="ml-2 mt-1">
                <v-card-title class="text-h6"> APAPCH </v-card-title>

                <v-card-subtitle
                  >${{
                    costoToDollar(
                      parseFloat(activosTotalGeneral[1].monto).toFixed(2)
                    )
                  }}</v-card-subtitle
                >
                <v-card-actions>
                  <v-btn
                    text
                    @click="
                      mostrarProrrateosActivos(activosTotalGeneral[1].apapch)
                    "
                  >
                    Desglosar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col v-if="activosTotalGeneral[2]" cols="2" md="2" lg="2">
              <v-card color="#385F73" dark class="ml-2 mt-1">
                <v-card-title class="text-h6"> APC </v-card-title>

                <v-card-subtitle
                  >${{
                    costoToDollar(
                      parseFloat(activosTotalGeneral[2].monto).toFixed(2)
                    )
                  }}</v-card-subtitle
                >
                <v-card-actions>
                  <v-btn
                    text
                    @click="
                      mostrarProrrateosActivos(activosTotalGeneral[2].apc)
                    "
                  >
                    Desglosar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col v-if="activosTotalGeneral[3]" cols="2" md="2" lg="2">
              <v-card color="#385F73" dark class="ml-2 mt-1">
                <v-card-title class="text-h6"> APKMZ </v-card-title>

                <v-card-subtitle
                  >${{
                    costoToDollar(
                      parseFloat(activosTotalGeneral[3].monto).toFixed(2)
                    )
                  }}</v-card-subtitle
                >
                <v-card-actions>
                  <v-btn
                    text
                    @click="
                      mostrarProrrateosActivos(activosTotalGeneral[3].apkmz)
                    "
                  >
                    Desglosar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col v-if="activosTotalGeneral[4]" cols="2" md="2" lg="2">
              <v-card color="#385F73" dark class="ml-2 mt-1 card">
                <v-card-title class="text-h6"> APLT </v-card-title>

                <v-card-subtitle
                  >${{
                    costoToDollar(
                      parseFloat(activosTotalGeneral[4].monto).toFixed(2)
                    )
                  }}</v-card-subtitle
                >
                <v-card-actions>
                  <v-btn
                    text
                    @click="
                      mostrarProrrateosActivos(activosTotalGeneral[4].aplt)
                    "
                  >
                    Desglosar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col v-if="activosTotalGeneral[5]" cols="2" md="2" lg="2">
              <v-card color="#385F73" dark class="mx-2 mt-1">
                <v-card-title class="text-h6"> SPEE </v-card-title>

                <v-card-subtitle
                  >${{
                    costoToDollar(
                      parseFloat(activosTotalGeneral[5].monto).toFixed(2)
                    )
                  }}</v-card-subtitle
                >
                <v-card-actions>
                  <v-btn
                    text
                    @click="
                      mostrarProrrateosActivos(activosTotalGeneral[5].spee)
                    "
                  >
                    Desglosar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="2" md="2" lg="2"> </v-col>
            <v-col v-if="activosTotalGeneral[6]" cols="2" md="2" lg="2">
              <v-card color="#385F73" dark class="m3-2">
                <v-card-title class="text-h6"> APC-EK-BALAM </v-card-title>

                <v-card-subtitle
                  >${{
                    costoToDollar(
                      parseFloat(activosTotalGeneral[6].monto).toFixed(2)
                    )
                  }}</v-card-subtitle
                >
                <v-card-actions>
                  <v-btn
                    text
                    @click="mostrarProrrateosActivos(activosTotalGeneral[6].eb)"
                  >
                    Desglosar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col v-if="activosTotalGeneral[7]" cols="2" md="2" lg="2">
              <v-card color="#385F73" dark class="m3-2">
                <v-card-title class="text-h6"> BLOQUE NORTE </v-card-title>

                <v-card-subtitle
                  >${{
                    costoToDollar(
                      parseFloat(activosTotalGeneral[7].monto).toFixed(2)
                    )
                  }}</v-card-subtitle
                >
                <v-card-actions>
                  <v-btn
                    text
                    @click="mostrarProrrateosActivos(activosTotalGeneral[7].bn)"
                  >
                    Desglosar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col v-if="activosTotalGeneral[8]" cols="2" md="2" lg="2">
              <v-card color="#385F73" dark class="ml-2 mt-1">
                <v-card-title class="text-h6"> AEMS1 </v-card-title>

                <v-card-subtitle
                  >${{
                    costoToDollar(
                      parseFloat(activosTotalGeneral[8].monto).toFixed(2)
                    )
                  }}</v-card-subtitle
                >
                <v-card-actions>
                  <v-btn
                    text
                    @click="
                      mostrarProrrateosActivos(activosTotalGeneral[8].aems1)
                    "
                  >
                    Desglosar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col>
              <v-expansion-panels class="pa-6 ma-2">
                <v-expansion-panel>
                  <v-expansion-panel-header :class="`${gruaEstatus}`">
                    <template v-slot:actions>
                      <h6 class="" style="color: #62455b">Gruas</h6>
                    </template>
                    <h6 class="" style="color: #62455b" v-if="gruasEquipoTotalSearch[0]">
                      <p v-if="!gruasEquipoTotalSearch">Gruas</p>
                      {{ gruasEquipoTotalSearch[0].equipo }} : ${{
                        costoToDollar(
                          parseFloat(gruasEquipoTotalSearch[0].total).toFixed(2)
                        )
                      }}
                    </h6>

                    <h6 class="" style="color: #62455b">
                      Total seleccionado ${{
                        costoToDollar(parseFloat(this.sumaGruas).toFixed(2))
                      }}
                    </h6>
                    <h6 v-if="ppSearchDatos.montoDesglosado" style="color: red">
                      Total Programa P. ${{
                        costoToDollar(
                          parseFloat(this.ppSearchDatos.montoDesglosado).toFixed(2)
                        )
                      }}
                    </h6>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card>
                      <v-card-title>
                        <v-text-field
                          v-model="programaPP"
                          @input="obtenerDatosActivoPorPP()"
                          label="Programa Presupuestal"
                          class="mx-2"
                        ></v-text-field>
                        <v-btn color="success" class="mx-2" small @click="prorratear()"
                          >Guardar</v-btn
                        >
                        <v-btn
                          color="blue"
                          class="mx-2"
                          small
                          dark
                          @click="limpiarProrrateo('grua')"
                          >Limpiar</v-btn
                        >

                        <v-spacer></v-spacer>

                        <!--     <v-select
                          v-model="BuscarGruasParaProrratear"
                          :items="equiposGruaTotales"
                          label="Equipos"
                          single-line
                          @input="buscarEquiposProrratear()"
                          append-icon="mdi-close"
                          @click:append="BuscarGruasParaProrratear = ''"
                          hide-details
                        ></v-select>
 -->
                        <v-select
                          v-model="BuscarGruasParaProrratear"
                          single-line
                          hide-details
                          :items="equiposGruaTotales"
                          label="Equipos"
                          append-icon="mdi-close"
                          @click:append="BuscarGruasParaProrratear = ''"
                        >
                          <template #item="{ item }">
                            <span :style="`background-color: ${item.color}`">{{
                              item.text
                            }}</span>
                          </template>
                        </v-select>
                      </v-card-title>
                      <v-data-table
                        v-if="gruasProrratear"
                        v-model="gruasSelected"
                        :headers="headersEquiposProrratear"
                        :items="gruasProrratear"
                        :items-per-page="20"
                        :single-select="false"
                        item-key="_id"
                        :search="BuscarGruasParaProrratear"
                        :disabled="true"
                        show-select
                        dense
                        class="elevation-1"
                      >
                        <template v-slot:[`item.montoEstimado`]="{ item }">
                          ${{ costoToDollar(item.montoEstimado.toFixed(2)) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header :class="`${cargadoresFrontalesEstatus}`">
                    <template v-slot:actions>
                      <h6 class="" style="color: #62455b">Cargadores Frontales</h6>
                    </template>
                    <h6 class="" v-if="cfEquipoTotalSearch[0]">
                      <p v-if="!cfEquipoTotalSearch">Gruas</p>
                      {{ cfEquipoTotalSearch[0].equipo }} : ${{
                        costoToDollar(parseFloat(cfEquipoTotalSearch[0].total).toFixed(2))
                      }}
                    </h6>

                    <h6 class="" style="color: #62455b">
                      Total seleccionado ${{
                        costoToDollar(parseFloat(this.sumaCF).toFixed(2))
                      }}
                    </h6>
                    <h6
                      class=""
                      v-if="ppSearchDatosCF.montoDesglosado"
                      style="color: red"
                    >
                      Total Programa P. ${{
                        costoToDollar(
                          parseFloat(this.ppSearchDatosCF.montoDesglosado).toFixed(2)
                        )
                      }}
                    </h6>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card>
                      <v-card-title>
                        <v-text-field
                          v-model="programaPPCF"
                          @input="obtenerDatosActivoPorPPCF()"
                          label="Programa Presupuestal"
                          class="mx-2"
                        ></v-text-field>
                        <v-btn color="success" class="mx-2" small @click="prorratearCF()"
                          >Guardar</v-btn
                        >
                        <v-btn
                          color="blue"
                          class="mx-2"
                          small
                          dark
                          @click="limpiarProrrateo('cf')"
                          >Limpiar</v-btn
                        >

                        <v-spacer></v-spacer>

                        <v-select
                          v-model="BuscarCFParaProrratear"
                          single-line
                          hide-details
                          :items="equiposCGTotales"
                          label="Equipos"
                          append-icon="mdi-close"
                          @click:append="BuscarCFParaProrratear = ''"
                        >
                          <template #item="{ item }">
                            <span :style="`background-color: ${item.color}`">{{
                              item.text
                            }}</span>
                          </template>
                        </v-select>
                      </v-card-title>
                      <v-data-table
                        v-if="cargadoresFrontalesProrratear"
                        v-model="cargadoresFrontalesSelected"
                        :headers="headersEquiposProrratear"
                        :items="cargadoresFrontalesProrratear"
                        :items-per-page="20"
                        :single-select="false"
                        item-key="_id"
                        :search="BuscarCFParaProrratear"
                        :disabled="true"
                        show-select
                        dense
                        class="elevation-1"
                      >
                        <template v-slot:[`item.montoEstimado`]="{ item }">
                          ${{ costoToDollar(item.montoEstimado.toFixed(2)) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header :class="`${montacargasEstatus}`">
                    <template v-slot:actions>
                      <h6 class="" style="color: #62455b">Montacargas</h6>
                    </template>
                    <h6 class="" v-if="montacargasEquipoTotalSearch[0]">
                      <p v-if="!montacargasEquipoTotalSearch">Gruas</p>
                      {{ montacargasEquipoTotalSearch[0].equipo }} : ${{
                        costoToDollar(
                          parseFloat(montacargasEquipoTotalSearch[0].total).toFixed(2)
                        )
                      }}
                    </h6>

                    <h6 class="" style="color: #62455b">
                      Total seleccionado ${{
                        costoToDollar(parseFloat(this.sumaMontacargas).toFixed(2))
                      }}
                    </h6>
                    <h6
                      class=""
                      v-if="ppSearchDatosMontacargas.montoDesglosado"
                      style="color: red"
                    >
                      Total Programa P. ${{
                        costoToDollar(
                          parseFloat(
                            this.ppSearchDatosMontacargas.montoDesglosado
                          ).toFixed(2)
                        )
                      }}
                    </h6>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card>
                      <v-card-title>
                        <v-text-field
                          v-model="programaPPMontacargas"
                          @input="obtenerDatosActivoPorPPMontacargas()"
                          label="Programa Presupuestal"
                          class="mx-2"
                        ></v-text-field>
                        <v-btn
                          color="success"
                          class="mx-2"
                          small
                          @click="prorratearMontacargas()"
                          >Guardar</v-btn
                        >
                        <v-btn
                          color="blue"
                          class="mx-2"
                          small
                          dark
                          @click="limpiarProrrateo('montacargas')"
                          >Limpiar</v-btn
                        >

                        <v-spacer></v-spacer>

                        <v-select
                          v-model="BuscarMontacargasParaProrratear"
                          single-line
                          hide-details
                          :items="equiposMontacargasTotales"
                          label="Equipos"
                          append-icon="mdi-close"
                          @click:append="BuscarMontacargasParaProrratear = ''"
                        >
                          <template #item="{ item }">
                            <span :style="`background-color: ${item.color}`">{{
                              item.text
                            }}</span>
                          </template>
                        </v-select>
                      </v-card-title>
                      <v-data-table
                        v-if="montacargasProrratear"
                        v-model="montacargasSelected"
                        :headers="headersEquiposProrratear"
                        :items="montacargasProrratear"
                        :items-per-page="20"
                        :single-select="false"
                        item-key="_id"
                        :search="BuscarMontacargasParaProrratear"
                        :disabled="true"
                        show-select
                        dense
                        class="elevation-1"
                      >
                        <template v-slot:[`item.montoEstimado`]="{ item }">
                          ${{ costoToDollar(item.montoEstimado.toFixed(2)) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header :class="`${tractocamionEstatus}`">
                    <template v-slot:actions>
                      <h6 class="" style="color: #62455b">Tractocamion</h6>
                    </template>
                    <h6 class="" v-if="tractocamionEquipoTotalSearch[0]">
                      <p v-if="!tractocamionEquipoTotalSearch">Gruas</p>
                      {{ tractocamionEquipoTotalSearch[0].equipo }} : ${{
                        costoToDollar(
                          parseFloat(tractocamionEquipoTotalSearch[0].total).toFixed(2)
                        )
                      }}
                    </h6>

                    <h6 class="" style="color: #62455b">
                      Total seleccionado ${{
                        costoToDollar(parseFloat(this.sumaTractocamion).toFixed(2))
                      }}
                    </h6>
                    <h6
                      class=""
                      v-if="ppSearchDatosTractocamion.montoDesglosado"
                      style="color: red"
                    >
                      Total Programa P. ${{
                        costoToDollar(
                          parseFloat(
                            this.ppSearchDatosTractocamion.montoDesglosado
                          ).toFixed(2)
                        )
                      }}
                    </h6>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card>
                      <v-card-title>
                        <v-text-field
                          v-model="programaPPTractocamion"
                          @input="obtenerDatosActivoPorPPTractocamion()"
                          label="Programa Presupuestal"
                          class="mx-2"
                        ></v-text-field>
                        <v-btn
                          color="success"
                          class="mx-2"
                          small
                          @click="prorratearTractocamion()"
                          >Guardar</v-btn
                        >
                        <v-btn
                          color="blue"
                          class="mx-2"
                          small
                          dark
                          @click="limpiarProrrateo('tractocamion')"
                          >Limpiar</v-btn
                        >

                        <v-spacer></v-spacer>

                        <v-select
                          v-model="BuscarTractocamionParaProrratear"
                          single-line
                          hide-details
                          :items="equiposTractocamionTotales"
                          label="Equipos"
                          append-icon="mdi-close"
                          @click:append="BuscarTractocamionParaProrratear = ''"
                        >
                          <template #item="{ item }">
                            <span :style="`background-color: ${item.color}`">{{
                              item.text
                            }}</span>
                          </template>
                        </v-select>
                      </v-card-title>
                      <v-data-table
                        v-if="tractocamionProrratear"
                        v-model="tractomacionSelected"
                        :headers="headersEquiposProrratear"
                        :items="tractocamionProrratear"
                        :items-per-page="20"
                        :single-select="false"
                        item-key="_id"
                        :search="BuscarTractocamionParaProrratear"
                        :disabled="true"
                        show-select
                        dense
                        class="elevation-1"
                      >
                        <template v-slot:[`item.montoEstimado`]="{ item }">
                          ${{ costoToDollar(item.montoEstimado.toFixed(2)) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header :class="`${camionEstatus}`">
                    <template v-slot:actions>
                      <h6 class="" style="color: #62455b">Camión con Grua</h6>
                    </template>
                    <h6 class="" v-if="CamionEquipoTotalSearch[0]">
                      <p v-if="!CamionEquipoTotalSearch">Gruas</p>
                      {{ CamionEquipoTotalSearch[0].equipo }} : ${{
                        costoToDollar(
                          parseFloat(CamionEquipoTotalSearch[0].total).toFixed(2)
                        )
                      }}
                    </h6>

                    <h6 class="" style="color: #62455b">
                      Total seleccionado ${{
                        costoToDollar(parseFloat(this.sumaCamion).toFixed(2))
                      }}
                    </h6>
                    <h6
                      class=""
                      v-if="ppSearchDatosCamion.montoDesglosado"
                      style="color: red"
                    >
                      Total Programa P. ${{
                        costoToDollar(
                          parseFloat(this.ppSearchDatosCamion.montoDesglosado).toFixed(2)
                        )
                      }}
                    </h6>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card>
                      <v-card-title>
                        <v-text-field
                          v-model="programaPPCamion"
                          @input="obtenerDatosActivoPorPPCamion()"
                          label="Programa Presupuestal"
                          class="mx-2"
                        ></v-text-field>
                        <v-btn
                          color="success"
                          class="mx-2"
                          small
                          @click="prorratearCamion()"
                          >Guardar</v-btn
                        >
                        <v-btn
                          color="blue"
                          class="mx-2"
                          small
                          dark
                          @click="limpiarProrrateo('camion')"
                          >Limpiar</v-btn
                        >

                        <v-spacer></v-spacer>

                        <v-select
                          v-model="BuscarCamionParaProrratear"
                          single-line
                          hide-details
                          :items="equiposCamionTotales"
                          label="Equipos"
                          append-icon="mdi-close"
                          @click:append="BuscarCamionParaProrratear = ''"
                        >
                          <template #item="{ item }">
                            <span :style="`background-color: ${item.color}`">{{
                              item.text
                            }}</span>
                          </template>
                        </v-select>
                      </v-card-title>
                      <v-data-table
                        v-if="camionProrratear"
                        v-model="camionSelected"
                        :headers="headersEquiposProrratear"
                        :items="camionProrratear"
                        :items-per-page="20"
                        :single-select="false"
                        item-key="_id"
                        :search="BuscarCamionParaProrratear"
                        :disabled="true"
                        show-select
                        dense
                        class="elevation-1"
                      >
                        <template v-slot:[`item.montoEstimado`]="{ item }">
                          ${{ costoToDollar(item.montoEstimado.toFixed(2)) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <h6 class="" style="color: #62455b">Cuadrillas</h6>
                    </template>
                    <h6 class="" style="color: #62455b">
                      Total seleccionado ${{
                        costoToDollar(parseFloat(this.sumaCuadrilla).toFixed(2))
                      }}
                    </h6>
                    <h6
                      class=""
                      v-if="ppSearchDatosCuadrilla.montoDesglosado"
                      style="color: red"
                    >
                      Total Programa P. ${{
                        costoToDollar(
                          parseFloat(this.ppSearchDatosCuadrilla.montoDesglosado).toFixed(
                            2
                          )
                        )
                      }}
                    </h6>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card>
                      <v-card-title>
                        <v-text-field
                          v-model="programaPPCuadrilla"
                          @input="obtenerDatosActivoPorPPcuadrillas()"
                          label="Programa Presupuestal"
                          class="mx-2"
                        ></v-text-field>

                        <v-btn
                          color="success"
                          class="mx-2"
                          small
                          @click="prorratearCuadrillas()"
                          >Guardar</v-btn
                        >
                         <v-btn
                          color="blue"
                          class="mx-2"
                          small
                          dark
                          @click="limpiarProrrateo('cuadrillas')"
                          >Limpiar</v-btn
                        >
                      </v-card-title>

                      <v-data-table
                        v-model="cuadrillasSelected"
                        v-if="cuadrillasTotales"
                        :headers="headersCuadrillasProrratear"
                        :items="cuadrillasTotales"
                        :items-per-page="20"
                        :single-select="false"
                        item-key="titulo"
                        :disabled="true"
                        show-select
                        dense
                        class="elevation-1"
                      >
                        <template v-slot:[`item.total`]="{ item }">
                          ${{ costoToDollar(item.total.toFixed(2)) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogEquiposProrrateados" max-width="1090">
        <v-card>
          <v-card-title>
            PRORRATEADOS

            <v-spacer></v-spacer>
            <div
              v-if="sumaProrrateado > 0"
              style="font-weight: bold;,font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',sans-serif;">
              Total: ${{ costoToDollar(sumaProrrateado.toFixed(2)) }}
              <v-btn
                class="mx-2"
                icon
                dark
                color="indigo"
                @click="limpiarProrrateEquiposTab()"
              >
                <v-icon dark> mdi-minus </v-icon>
              </v-btn>
            </div>
          </v-card-title>
        </v-card>
        <v-data-table
          v-model="activosProrrateadosSelected"
          :headers="headersEquiposProrratear"
          :items="activosProrrateadosDesglosar"
          :items-per-page="20"
          :single-select="false"
          item-key="_id"
          show-select
          dense
          class="elevation-1"
        >
          <template v-slot:[`item.montoEstimado`]="{ item }">
            ${{ costoToDollar(item.montoEstimado.toFixed(2)) }}
          </template>
        </v-data-table>
      </v-dialog>

      <v-row>
        <v-col cols="12">
          <v-card class="employee-list">
            <br />
            <v-col class="pa-5" cols="12" md="12" lg="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    style="margin-top: -30px"
                    outlined
                    dense
                    v-model="mesServicio"
                    label="Mes"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="mesServicio"
                  type="month"
                  @input="mostrarTrazabilidadSinTeb"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-divider></v-divider>

            <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
              <v-card color="primary" dark>
                <v-card-text>
                  Por favor espere
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card>
          <v-card class="pa-6 ma-2">
            <h4>Registros para prorrateo</h4>
            <div v-if="totalProrratear" class="font-weight-medium">
              Total a prorratear: ${{
                costoToDollar(parseFloat(totalProrratear).toFixed(2))
              }}
            </div>

            <v-text-field
              v-model="buscarTrazabilidad"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <br />
            <v-data-table
              :headers="headers"
              :items="trazabilidadSinRegistrosTeb"
              dense
              :search="buscarTrazabilidad"
              class="elevation-1"
            >
              <template v-slot:[`item.montoEstimado`]="{ item }">
                ${{ costoToDollar(parseFloat(item.montoEstimado).toFixed(2)) }}
              </template>
            </v-data-table>
          </v-card>

          <v-card class="pa-6 ma-2">
            <h4>Registros Prorrateados</h4>
            <div v-if="totalProrratear" class="font-weight-medium">
              Total a prorratear: ${{
                costoToDollar(parseFloat(totalProrratear).toFixed(2))
              }}
            </div>

            <v-text-field
              v-model="buscarTrazabilidadProrrateada"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <br />
            <v-data-table
              :headers="headers"
              :items="trazabilidadRegistros"
              dense
              :search="buscarTrazabilidadProrrateada"
              class="elevation-1"
            >
              <template v-slot:[`item.montoEstimado`]="{ item }">
                ${{ costoToDollar(parseFloat(item.montoEstimado).toFixed(2)) }}
              </template>
            </v-data-table>
          </v-card>
          <v-card class="pa-6 ma-2">
            <h4>TEBS sin usar</h4>
            <v-text-field
              v-model="buscarTebs"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <br />
            <v-data-table
              :headers="tebsHeaders"
              :items="tebsSinUno"
              :search="buscarTebs"
              dense
              class="elevation-1"
            >
            </v-data-table>
          </v-card>

          <v-row>
            <v-col cols="12" md="5" lg="5">
              <v-card class="pa-6 ma-2">
                <h4>Activos : {{ totalPorActivos }}</h4>

                <br />
                <v-treeview hoverable :items="activosTotal">
                  <template v-slot:prepend="{ item }">
                    <span
                      v-if="item.total < 10"
                      style="text-align: center; color: red"
                      class="text-caption"
                      >{{ item.total }}</span
                    >
                    <span
                      v-else
                      style="text-align: center; color: green"
                      class="text-caption"
                      >{{ item.total }}</span
                    >

                    <v-divider class="mx-4" vertical></v-divider>
                    <span style="text-align: center" class="text-caption">{{
                      item.activo
                    }}</span
                    ><v-divider class="mx-4" vertical></v-divider
                    ><span style="text-align: center" class="text-caption">{{
                      item.pp
                    }}</span
                    ><v-divider class="mx-4" vertical></v-divider>
                    <span style="text-align: center" class="text-caption">{{
                      item.epep
                    }}</span>
                  </template>
                </v-treeview>
              </v-card>
            </v-col>

            <v-col cols="12" md="7" lg="7">
              <v-card class="pa-6 ma-2">
                <v-row>
                  <h4>Total General Activos</h4>

                  <v-col cols="12" md="7" lg="7">
                    <v-btn
                      @click="agregarAPCEK"
                      depressed
                      small
                      class="mx-2"
                      color="warning"
                    >
                      Agregar APC-EK-BALAM
                    </v-btn>
                    <v-btn
                      @click="mostrarDialogActivosDesglosado"
                      depressed
                      small
                      color="warning"
                    >
                      Activos Desglosados
                    </v-btn>
                  </v-col> </v-row
                ><br />

                <div class="yellow darken-2 text-center">
                  <span class="white--text"
                    >${{ costoToDollar(parseFloat(totalProrratear).toFixed(2)) }}</span
                  >
                </div>
                <v-data-table
                  v-if="tablaActivos == false"
                  :headers="tebsActivosGeneral"
                  :items="activosTotalGeneral"
                  dense
                  hide-default-footer
                  item-key="id"
                >
                  <template slot="body.append">
                    <tr class="pink--text">
                      <th class="title">Total</th>
                      <th class="title">{{ sumField("total") }}</th>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table
                  v-if="tablaActivos == true"
                  :headers="tebsActivosGeneralCompleto"
                  :items="activosTotalGeneral"
                  dense
                  hide-default-footer
                  item-key="id"
                >
                  <template v-slot:[`item.porcentaje`]="{ item }">
                    {{ parseInt(item.porcentaje) }}%
                  </template>
                  <template v-slot:[`item.monto`]="{ item }">
                    ${{ costoToDollar(parseFloat(item.monto).toFixed(2)) }}
                  </template>

                  <template slot="body.append">
                    <tr class="pink--text">
                      <th class="title">Total</th>
                      <th class="title">{{ sumField("total") }}</th>
                      <th class="title">{{ sumField("porcentaje") }}%</th>
                      <th class="title">${{ costoToDollar(sumField("monto")) }}</th>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <template>
            <v-row justify="center">
              <v-dialog v-model="dialogActivoDesglosado" scrollable width="1600px">
                <v-card>
                  <v-card-title>Activos Desglosado</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: 900px">
                    <v-row v-if="activosTotalGeneral[0]">
                      <v-col cols="12" md="12" lg="12">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-card-title> AEMS </v-card-title>
                            <v-data-table
                              v-if="tablasActivosDeglosado == true"
                              :headers="tebsActivosGeneralDesglosado"
                              :items="activosTotalGeneral[0].aems"
                              :items-per-page="15"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.porcentaje`]="{ item }">
                                {{ parseInt(item.porcentaje) }}%
                              </template>
                              <template v-slot:[`item.montoDesglosado`]="{ item }">
                                ${{
                                  costoToDollar(
                                    parseFloat(item.montoDesglosado).toFixed(2)
                                  )
                                }}
                              </template>
                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title">Total</th>
                                  <th class="title"></th>
                                  <th class="title"></th>
                                  <th class="title">
                                    {{ sumFieldAems("total") }}
                                  </th>
                                  <th class="title">
                                    {{
                                      parseFloat(sumFieldAems("porcentaje")).toFixed(2)
                                    }}%
                                  </th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        parseFloat(
                                          sumFieldAems("montoDesglosado")
                                        ).toFixed(2)
                                      )
                                    }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                          <div>
                            <v-card-title>
                              APAPCH
                              <v-spacer></v-spacer>
                            </v-card-title>
                            <v-data-table
                              :headers="tebsActivosGeneralDesglosado"
                              :items="activosTotalGeneral[1].apapch"
                              :items-per-page="15"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.porcentaje`]="{ item }">
                                {{ parseInt(item.porcentaje) }}%
                              </template>
                              <template v-slot:[`item.montoDesglosado`]="{ item }">
                                ${{
                                  costoToDollar(
                                    parseFloat(item.montoDesglosado).toFixed(2)
                                  )
                                }}
                              </template>
                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title">Total</th>
                                  <th class="title"></th>
                                  <th class="title"></th>
                                  <th class="title">
                                    {{ sumFieldsApapch("total") }}
                                  </th>
                                  <th class="title">
                                    {{
                                      parseFloat(sumFieldsApapch("porcentaje")).toFixed(
                                        2
                                      )
                                    }}%
                                  </th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        parseFloat(
                                          sumFieldsApapch("montoDesglosado")
                                        ).toFixed(2)
                                      )
                                    }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                          <div>
                            <v-card-title>
                              APKMZ
                              <v-spacer></v-spacer>
                            </v-card-title>
                            <v-data-table
                              :headers="tebsActivosGeneralDesglosado"
                              :items="activosTotalGeneral[3].apkmz"
                              :items-per-page="15"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.porcentaje`]="{ item }">
                                {{ parseInt(item.porcentaje) }}%
                              </template>
                              <template v-slot:[`item.montoDesglosado`]="{ item }">
                                ${{
                                  costoToDollar(
                                    parseFloat(item.montoDesglosado).toFixed(2)
                                  )
                                }}
                              </template>
                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title">Total</th>
                                  <th class="title"></th>
                                  <th class="title"></th>
                                  <th class="title">
                                    {{ sumFieldsApkmz("total") }}
                                  </th>
                                  <th class="title">
                                    {{
                                      parseFloat(sumFieldsApkmz("porcentaje")).toFixed(2)
                                    }}%
                                  </th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        parseFloat(
                                          sumFieldsApkmz("montoDesglosado")
                                        ).toFixed(2)
                                      )
                                    }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                          <div>
                            <v-card-title>
                              APC
                              <v-spacer></v-spacer>
                            </v-card-title>
                            <v-data-table
                              :headers="tebsActivosGeneralDesglosado"
                              :items="activosTotalGeneral[2].apc"
                              :items-per-page="15"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.porcentaje`]="{ item }">
                                {{ parseInt(item.porcentaje) }}%
                              </template>
                              <template v-slot:[`item.montoDesglosado`]="{ item }">
                                ${{
                                  costoToDollar(
                                    parseFloat(item.montoDesglosado).toFixed(2)
                                  )
                                }}
                              </template>
                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title">Total</th>
                                  <th class="title"></th>
                                  <th class="title"></th>
                                  <th class="title">
                                    {{ sumFieldsApc("total") }}
                                  </th>
                                  <th class="title">
                                    {{
                                      parseFloat(sumFieldsApc("porcentaje")).toFixed(2)
                                    }}%
                                  </th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        parseFloat(
                                          sumFieldsApc("montoDesglosado")
                                        ).toFixed(2)
                                      )
                                    }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                          <div>
                            <v-card-title>
                              APLT
                              <v-spacer></v-spacer>
                            </v-card-title>
                            <v-data-table
                              :headers="tebsActivosGeneralDesglosado"
                              :items="activosTotalGeneral[4].aplt"
                              :items-per-page="15"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.porcentaje`]="{ item }">
                                {{ parseInt(item.porcentaje) }}%
                              </template>
                              <template v-slot:[`item.montoDesglosado`]="{ item }">
                                ${{
                                  costoToDollar(
                                    parseFloat(item.montoDesglosado).toFixed(2)
                                  )
                                }}
                              </template>
                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title">Total</th>
                                  <th class="title"></th>
                                  <th class="title"></th>
                                  <th class="title">
                                    {{ sumFieldsAplt("total") }}
                                  </th>
                                  <th class="title">
                                    {{
                                      parseFloat(sumFieldsAplt("porcentaje")).toFixed(2)
                                    }}%
                                  </th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        parseFloat(
                                          sumFieldsAplt("montoDesglosado")
                                        ).toFixed(2)
                                      )
                                    }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                          <div>
                            <v-card-title>
                              SPEE
                              <v-spacer></v-spacer>
                            </v-card-title>
                            <v-data-table
                              :headers="tebsActivosGeneralDesglosado"
                              :items="activosTotalGeneral[5].spee"
                              :items-per-page="15"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.porcentaje`]="{ item }">
                                {{ parseInt(item.porcentaje) }}%
                              </template>
                              <template v-slot:[`item.montoDesglosado`]="{ item }">
                                ${{
                                  costoToDollar(
                                    parseFloat(item.montoDesglosado).toFixed(2)
                                  )
                                }}
                              </template>
                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title">Total</th>
                                  <th class="title"></th>
                                  <th class="title"></th>
                                  <th class="title">
                                    {{ sumFieldsApSpee("total") }}
                                  </th>
                                  <th class="title">
                                    {{
                                      parseFloat(sumFieldsApSpee("porcentaje")).toFixed(
                                        2
                                      )
                                    }}%
                                  </th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        parseFloat(
                                          sumFieldsApSpee("montoDesglosado")
                                        ).toFixed(2)
                                      )
                                    }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                          <div v-if="activosTotalGeneral[6]">
                            <v-card-title>
                              APC EK BALAM
                              <v-spacer></v-spacer>
                            </v-card-title>
                            <v-data-table
                              :headers="tebsActivosGeneralDesglosado"
                              :items="activosTotalGeneral[6].eb"
                              :items-per-page="15"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.porcentaje`]="{ item }">
                                {{ parseInt(item.porcentaje) }}%
                              </template>
                              <template v-slot:[`item.montoDesglosado`]="{ item }">
                                ${{
                                  costoToDollar(
                                    parseFloat(item.montoDesglosado).toFixed(2)
                                  )
                                }}
                              </template>
                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title">Total</th>
                                  <th class="title"></th>
                                  <th class="title"></th>
                                  <th class="title">
                                    {{ sumFieldsAeb("total") }}
                                  </th>
                                  <th class="title">
                                    {{
                                      parseFloat(sumFieldsAeb("porcentaje")).toFixed(2)
                                    }}%
                                  </th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        parseFloat(
                                          sumFieldsAeb("montoDesglosado")
                                        ).toFixed(2)
                                      )
                                    }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                          <div>
                             
                            <v-card-title>
                              AEMS1
                              <v-spacer></v-spacer>
                            </v-card-title>
                            <v-data-table
                              :headers="tebsActivosGeneralDesglosado"
                              :items="activosTotalGeneral[8].aems1"
                              :items-per-page="15"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.porcentaje`]="{ item }">
                                {{ parseInt(item.porcentaje) }}%
                              </template>
                              <template v-slot:[`item.montoDesglosado`]="{ item }">
                                ${{
                                  costoToDollar(
                                    parseFloat(item.montoDesglosado).toFixed(2)
                                  )
                                }}
                              </template>
                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title">Total</th>
                                  <th class="title"></th>
                                  <th class="title"></th>
                                  <th class="title">
                                    {{ sumFieldsAems1("total") }}
                                  </th>
                                  <th class="title">
                                    {{
                                      parseFloat(sumFieldsAems1("porcentaje")).toFixed(2)
                                    }}%
                                  </th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        parseFloat(
                                          sumFieldsAems1("montoDesglosado")
                                        ).toFixed(2)
                                      )
                                    }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                          <!-- Nuevo activo -->
                          <div>
                            <v-card-title>
                              AEMS3
                              <v-spacer></v-spacer>
                            </v-card-title>
                            <v-data-table
                              :headers="tebsActivosGeneralDesglosado"
                              :items="activosTotalGeneral[9].aems3"
                              :items-per-page="15"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.porcentaje`]="{ item }">
                                {{ parseInt(item.porcentaje) }}%
                              </template>
                              <template v-slot:[`item.montoDesglosado`]="{ item }">
                                ${{
                                  costoToDollar(
                                    parseFloat(item.montoDesglosado).toFixed(2)
                                  )
                                }}
                              </template>
                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title">Total</th>
                                  <th class="title"></th>
                                  <th class="title"></th>
                                  <th class="title">
                                    {{ sumFieldsAems3("total") }}
                                  </th>
                                  <th class="title">
                                    {{
                                      parseFloat(sumFieldsAems3("porcentaje")).toFixed(2)
                                    }}%
                                  </th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        parseFloat(
                                          sumFieldsAems3("montoDesglosado")
                                        ).toFixed(2)
                                      )
                                    }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                          <br />
                          <v-btn
                            @click="eliminarActivosDesglsados"
                            class="mx-2"
                            dark
                            small
                            color="primary"
                          >
                            <v-icon dark> mdi-delete-alert </v-icon>
                          </v-btn>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogActivoDesglosado = false"
                    >
                      Salir
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>

          <template>
            <v-row justify="center">
              <v-dialog v-model="dialogProrrateoDesglosado" scrollable width="1800px">
                <v-card>
                  <v-card-title>Prorrateos Desglosado</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: 900px">
                    <v-row>
                      <v-col cols="12" md="12" lg="12">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-card-title> AEMS </v-card-title>
                            <v-data-table
                              v-if="prorrateoTables.aems"
                              dense
                              :headers="prorrateoHeaders"
                              :items="prorrateoTables.aems"
                              item-key="name"
                              class="elevation-1"
                            >
                              <template v-slot:[`item.monto_total`]="{ item }">
                                ${{ costoToDollar(item.monto_total.toFixed(2)) }}
                              </template>
                              <template v-slot:[`item.monto_usado`]="{ item }">
                                ${{ costoToDollar(item.monto_usado.toFixed(2)) }}
                              </template>

                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title"></th>
                                  <th class="title"></th>

                                  <th class="title">Total</th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        sumFieldProrrateoAems("monto_usado").toFixed(2)
                                      )
                                    }}
                                  </th>
                                  <th class="title"></th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="12" lg="12">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-card-title> APAPCH </v-card-title>
                            <v-data-table
                              v-if="prorrateoTables.apapch"
                              dense
                              :headers="prorrateoHeaders"
                              :items="prorrateoTables.apapch"
                              item-key="name"
                              class="elevation-1"
                            >
                              <template v-slot:[`item.monto_total`]="{ item }">
                                ${{ costoToDollar(item.monto_total.toFixed(2)) }}
                              </template>
                              <template v-slot:[`item.monto_usado`]="{ item }">
                                ${{ costoToDollar(item.monto_usado.toFixed(2)) }}
                              </template>

                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title"></th>
                                  <th class="title"></th>

                                  <th class="title">Total</th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        sumFieldProrrateoApapch("monto_usado").toFixed(2)
                                      )
                                    }}
                                  </th>
                                  <th class="title"></th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="12" lg="12">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-card-title> APC </v-card-title>
                            <v-data-table
                              v-if="prorrateoTables.apapch"
                              dense
                              :headers="prorrateoHeaders"
                              :items="prorrateoTables.apc"
                              item-key="name"
                              class="elevation-1"
                            >
                              <template v-slot:[`item.monto_total`]="{ item }">
                                ${{ costoToDollar(item.monto_total.toFixed(2)) }}
                              </template>
                              <template v-slot:[`item.monto_usado`]="{ item }">
                                ${{ costoToDollar(item.monto_usado.toFixed(2)) }}
                              </template>

                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title"></th>
                                  <th class="title"></th>

                                  <th class="title">Total</th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        sumFieldProrrateoApc("monto_usado").toFixed(2)
                                      )
                                    }}
                                  </th>
                                  <th class="title"></th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="12" lg="12">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-card-title> APKMZ </v-card-title>
                            <v-data-table
                              v-if="prorrateoTables.apkmz"
                              dense
                              :headers="prorrateoHeaders"
                              :items="prorrateoTables.apkmz"
                              item-key="name"
                              class="elevation-1"
                            >
                              <template v-slot:[`item.monto_total`]="{ item }">
                                ${{ costoToDollar(item.monto_total.toFixed(2)) }}
                              </template>
                              <template v-slot:[`item.monto_usado`]="{ item }">
                                ${{ costoToDollar(item.monto_usado.toFixed(2)) }}
                              </template>

                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title"></th>
                                  <th class="title"></th>

                                  <th class="title">Total</th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        sumFieldProrrateoApkmz("monto_usado").toFixed(2)
                                      )
                                    }}
                                  </th>
                                  <th class="title"></th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="12" lg="12">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-card-title> APLT </v-card-title>
                            <v-data-table
                              v-if="prorrateoTables.aplt"
                              dense
                              :headers="prorrateoHeaders"
                              :items="prorrateoTables.aplt"
                              item-key="name"
                              class="elevation-1"
                            >
                              <template v-slot:[`item.monto_total`]="{ item }">
                                ${{ costoToDollar(item.monto_total.toFixed(2)) }}
                              </template>
                              <template v-slot:[`item.monto_usado`]="{ item }">
                                ${{ costoToDollar(item.monto_usado.toFixed(2)) }}
                              </template>

                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title"></th>
                                  <th class="title"></th>

                                  <th class="title">Total</th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        sumFieldProrrateoAplt("monto_usado").toFixed(2)
                                      )
                                    }}
                                  </th>
                                  <th class="title"></th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="12" lg="12">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-card-title> SPEE </v-card-title>
                            <v-data-table
                              v-if="prorrateoTables.spee"
                              dense
                              :headers="prorrateoHeaders"
                              :items="prorrateoTables.spee"
                              item-key="name"
                              class="elevation-1"
                            >
                              <template v-slot:[`item.monto_total`]="{ item }">
                                ${{ costoToDollar(item.monto_total.toFixed(2)) }}
                              </template>
                              <template v-slot:[`item.monto_usado`]="{ item }">
                                ${{ costoToDollar(item.monto_usado.toFixed(2)) }}
                              </template>

                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title"></th>
                                  <th class="title"></th>

                                  <th class="title">Total</th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        sumFieldProrrateoSpee("monto_usado").toFixed(2)
                                      )
                                    }}
                                  </th>
                                  <th class="title"></th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="12" lg="12">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-card-title> APC-EK-BALAM </v-card-title>
                            <v-data-table
                              v-if="prorrateoTables.apk_ek_blm"
                              dense
                              :headers="prorrateoHeaders"
                              :items="prorrateoTables.apk_ek_blm"
                              item-key="name"
                              class="elevation-1"
                            >
                              <template v-slot:[`item.monto_total`]="{ item }">
                                ${{ costoToDollar(item.monto_total.toFixed(2)) }}
                              </template>
                              <template v-slot:[`item.monto_usado`]="{ item }">
                                ${{ costoToDollar(item.monto_usado.toFixed(2)) }}
                              </template>

                              <template slot="body.append">
                                <tr class="pink--text">
                                  <th class="title"></th>
                                  <th class="title"></th>

                                  <th class="title">Total</th>
                                  <th class="title">
                                    ${{
                                      costoToDollar(
                                        sumFieldProrrateoApc_Ek_blm(
                                          "monto_usado"
                                        ).toFixed(2)
                                      )
                                    }}
                                  </th>
                                  <th class="title"></th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="12" lg="12">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-card-title> AEMS1 </v-card-title>
                            <v-data-table
                              v-if="prorrateoTables.aems1"
                              dense
                              :headers="prorrateoHeaders"
                              :items="prorrateoTables.aems1"
                              item-key="name"
                              class="elevation-1"
                            >
                              <template v-slot:[`item.monto_total`]="{ item }">
                                ${{ costoToDollar(item.monto_total.toFixed(2)) }}
                              </template>
                              <template v-slot:[`item.monto_usado`]="{ item }">
                                ${{ costoToDollar(item.monto_usado.toFixed(2)) }}
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogProrrateoDesglosado = false"
                    >
                      Salir
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>

          <v-col cols="12" md="5" lg="5">
            <v-card class="pa-6 ma-2">
              <div>
                <v-card-title>
                  <v-btn
                    class="mx-2"
                    depressed
                    small
                    color="success"
                    @click="prorrateoManual()"
                  >
                    Prorratear
                  </v-btn>

                  <v-btn
                    @click="dialogGuardarProrrateo = true"
                    class="mx-2"
                    dark
                    small
                    color="blue"
                  >
                    <v-icon>mdi-content-save</v-icon> </v-btn
                  ><br />
                  <!-- <v-btn
                    @click="dialogProrrateoDesglosado = true"
                    depressed
                    small
                    dark
                    color="purple"
                  >
                    Prorrateo Desglosados
                  </v-btn> -->
                </v-card-title>
                <div class="yellow darken-2 text-center">
                  <span class="white--text"
                    >${{ costoToDollar(parseFloat(totalProrratear).toFixed(2)) }}</span
                  >
                </div>
                <v-data-table
                  :headers="equiposTotalGeneral"
                  :items="equiposDesglosadosTable"
                  :items-per-page="15"
                  dense
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.total`]="{ item }">
                    {{ costoToDollar(item.total.toFixed(2)) }}
                  </template>

                  <template slot="body.append">
                    <tr class="pink--text">
                      <th class="title">Total</th>
                      <th class="title">
                        {{ costoToDollar(sumFieldEquiposTotalGeneral("total").toFixed(2)) }}
                      </th>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>

          <template>
            <v-row justify="center">
              <v-dialog v-model="dialogEquipoDesglosado" scrollable width="1900px">
                <v-card>
                  <v-card-title>Equipos Desglosado</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: 900px">
                    <v-row>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="equiposGeneralCargadorFrontal"
                              :items-per-page="20"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>

                              <template
                                slot="body.append"
                                v-if="equiposGeneralCargadorFrontal[1]"
                              >
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{
                                      costoToDollar(equiposGeneralCargadorFrontal.total)
                                    }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>

                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="equiposGeneralTracto"
                              :items-per-page="20"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template
                                slot="body.append"
                                v-if="equiposGeneralMontacargargas[1]"
                              >
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(equiposGeneralTracto.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalPersonalTecnico"
                              :items-per-page="40"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template
                                slot="body.append"
                                v-if="generalPersonalTecnico[1]"
                              >
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalPersonalTecnico.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalManoObra1"
                              :items-per-page="40"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template slot="body.append" v-if="generalManoObra1[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalManoObra1.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalManoObra2"
                              :items-per-page="40"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template slot="body.append" v-if="generalManoObra2[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalManoObra2.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalManoObra3"
                              :items-per-page="40"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template slot="body.append" v-if="generalManoObra3[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalManoObra3.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalManoObra4"
                              :items-per-page="40"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template slot="body.append" v-if="generalManoObra4[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalManoObra4.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalManoObra5"
                              :items-per-page="40"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template slot="body.append" v-if="generalManoObra5[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalManoObra5.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalManoObra6"
                              :items-per-page="40"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template slot="body.append" v-if="generalManoObra6[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalManoObra6.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>

                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalManoObra9"
                              :items-per-page="40"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template slot="body.append" v-if="generalManoObra9[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalManoObra9.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalManoObra10"
                              :items-per-page="40"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template slot="body.append" v-if="generalManoObra10[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalManoObra10.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalManoObra11"
                              :items-per-page="40"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template slot="body.append" v-if="generalManoObra11[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalManoObra11.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalManoObra7"
                              :items-per-page="40"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template slot="body.append" v-if="generalManoObra7[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalManoObra7.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalManoObra8"
                              :items-per-page="40"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>
                              <template slot="body.append" v-if="generalManoObra8[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalManoObra8.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="equiposGeneralMontacargargas"
                              :items-per-page="20"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>

                              <template
                                slot="body.append"
                                v-if="equiposGeneralMontacargargas[1]"
                              >
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{
                                      costoToDollar(equiposGeneralMontacargargas.total)
                                    }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="equiposGeneralGrua"
                              :items-per-page="20"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>

                              <template slot="body.append" v-if="equiposGeneralGrua[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(equiposGeneralGrua.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>

                      <v-col cols="4" md="4" lg="4">
                        <v-card class="pa-6 ma-2">
                          <div>
                            <v-data-table
                              :headers="equiposTotalGeneral"
                              :items="generalCamionGrua"
                              :items-per-page="20"
                              dense
                              hide-default-footer
                              class="elevation-1"
                            >
                              <template v-slot:[`item.equipo`]="{ item }">
                                {{ item[0] }}
                              </template>
                              <template v-slot:[`item.total`]="{ item }">
                                {{ costoToDollar(item[1].montoTotal) }}
                              </template>

                              <template slot="body.append" v-if="generalCamionGrua[1]">
                                <tr class="blue--text">
                                  <th class="title">Total</th>
                                  <th class="title">
                                    {{ costoToDollar(generalCamionGrua.total) }}
                                  </th>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogEquipoDesglosado = false"
                    >
                      Salir
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
          <v-btn
            class="button-fab-1 mx-2"
            @click="pruebaEnviarDatos"
            :loading="loadingProrrateoExcel"
            dark
            fab
            color="green"
            ><v-icon dark>mdi-microsoft-excel</v-icon></v-btn
          >
          <v-btn
            class="button-fab-2 mx-2"
            @click="mostrarActivosProrratearSocket"
            dark
            fab
            color="primary"
            ><v-icon dark>mdi-database-refresh</v-icon></v-btn
          >
          <v-btn
            class="button-fab-3 mx-2"
            @click="porcentajeMontoTotalCalculo"
            dark
            fab
            color="primary"
            ><v-icon dark>mdi-calculator</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import S3 from "aws-s3";
import io from "socket.io-client";
import api from "../../api/Costeo";

export default {
  data() {
    return {
      headers: [
        { text: "Estimacion", value: "numeroEstimacion", sortable: true },
        { text: "Partida", value: "partida", sortable: true },
        { text: "Volumen", value: "volumen", sortable: true },
        { text: "Monto Estimado", value: "montoEstimado", sortable: true },
        { text: "Equipo", value: "activoEquipo", sortable: true },
        { text: "Fecha Servicio", value: "fechaServicio", sortable: true },
        { text: "Tipo S", value: "tipoDeServicioSelec", sortable: true },
        { text: "Ubicacion", value: "ubicacion", sortable: true },
        { text: "Activo", value: "activoPemex", sortable: true },
        { text: "EPEP", value: "epep", sortable: true },
      ],
      activosHeaders: [
        { text: "Activo", value: "numeroEstimacion", sortable: true },
        { text: "Total", value: "partida", sortable: true },
        { text: "%", value: "volumen", sortable: true },
        { text: "Monto", value: "montoEstimado", sortable: true },
      ],
      headersCuadrillasProrratear: [
        { text: "Titulo", value: "titulo", sortable: true },
        { text: "Total", value: "total", sortable: true },
        { text: "Activo", value: "activo", sortable: true },
        { text: "EPEP", value: "epep", sortable: true },
        { text: "PP", value: "pp", sortable: true },
      ],
      tebsHeaders: [
        { text: "Fecha Solicitada", value: "fechaSolicitada", sortable: true },
        { text: "Fecha Recibida", value: "fechaRecibido", sortable: true },
        { text: "Teb", value: "teb", sortable: true },
        {
          text: "Programa Presupuesta",
          value: "programaPressL",
          sortable: true,
        },
        { text: "EPEP", value: "epepRegistro", sortable: true },
        { text: "Instalacion Destino", value: "instalacionD", sortable: true },
        {
          text: "Equipos Requeridos",
          value: "equiposRequeridosReg",
          sortable: true,
        },
        { text: "Activo", value: "activo", sortable: true },
      ],
      tebsActivosGeneral: [
        { text: "Activo", value: "activo", sortable: true },
        { text: "Total", value: "total", sortable: true },
      ],
      prorrateoHeaders: [
        { text: "Activo", value: "activo" },
        { text: "Prog Presup", value: "pp" },
        { text: "Elemento PP", value: "epep" },
        { text: "Monto prorrateado", value: "monto_usado" },
        { text: "Equipos prorrateados", value: "equipo" },
      ],
      tebsActivosGeneralCompleto: [
        { text: "Activo", value: "activo", sortable: true },
        { text: "Total", value: "total", sortable: true },
        { text: "Porcentaje", value: "porcentaje", sortable: true },
        { text: "Monto", value: "monto", sortable: true },
      ],
      tebsActivosGeneralDesglosado: [
        { text: "Activo", value: "activo", sortable: true },
        { text: "Programa Presupuestal", value: "pp", sortable: true },
        { text: "Elemento PP", value: "epep", sortable: true },
        { text: "Total", value: "total", sortable: true },
        { text: "%", value: "porcentaje", sortable: true },
        { text: "Monto", value: "montoDesglosado", sortable: true },
      ],
      equiposTotalGeneral: [
        { text: "Equipo", value: "equipo", sortable: true },
        { text: "Total", value: "total", sortable: true },
      ],
      activosProrratearSocketHeader: [
        { text: "Activo", value: "activo", sortable: true },
        { text: "Programa Presupuestal", value: "pp", sortable: true },
        { text: "Total", value: "total", sortable: true },
      ],
      headersEquiposProrratear: [
        { text: "Activo", value: "activoEquipo", sortable: true },
        { text: "Fecha", value: "fechaServicio", sortable: true },
        { text: "Monto", value: "montoEstimado", sortable: true },
        { text: "Partida", value: "partida", sortable: true },
        { text: "Volumen", value: "volumen", sortable: true },
        { text: "Activo", value: "activoPemex", sortable: true },
        { text: "EPEP", value: "epep", sortable: true },
        { text: "PP", value: "programaPresupuestal", sortable: true },
      ],
      equiposDesglosados: [],
      equiposDesglosadosTable: [],
      compania: "CORPORATIVO INDUSTRIAL Y COMERCIAL S.A. DE C.V.",
      contrato: "648228851",
      servicio:
        "SERVICIO DE TRANSPORTE DE CARGA Y DESCARGA DE MATERIALES EN PUERTO DOS BOCAS",
      periodo: [],
      epep: "",
      mostrarTabla: false,
      hover: false,
      e1: 1,
      menu2: false,
      listaActivosPorProrratear: [],
      botonCalculoEquipos: false,
      totalProrratear: "",
      dialogApk: false,
      dialogGuardarProrrateo: false,
      buscarTrazabilidad: "",
      buscarTrazabilidadProrrateada: "",
      trazabilidadRegistros: [],
      buscarTebs: "",
      tebEnUso: [],
      loadingProrrateoExcel: false,
      tebsSinUno: [],
      dialogEquipoDesglosado: false,
      dialogActivosParaProrratear: false,
      trazabilidadSinRegistrosTeb: [],
      trazabilidadConRegistrosTeb: [],
      activosTotal: [],
      dialogActivoDesglosado: false,
      activosTotalGeneral: [],
      apcEk_balam: [],
      equiposGeneralGrua: [],
      generalCamionGrua: [],
      equiposGeneralCargadorFrontal: [],
      equiposGeneralMontacargargas: [],
      equiposGeneralTracto: [],
      generalManoObra1: [],
      generalManoObra2: [],
      generalManoObra3: [],
      generalManoObra4: [],
      generalManoObra5: [],
      generalManoObra6: [],
      generalManoObra7: [],
      generalManoObra8: [],
      generalManoObra9: [],
      mostrarBotonProrrateoExcel: false,
      generalManoObra10: [],
      generalManoObra11: [],
      gruasSelected: [],
      generalPersonalTecnico: [],
      tablaActivos: false,
      trazabilidadActual: [],
      prorrateoTables: [],
      tablasActivosDeglosado: false,
      dialogLoading: false,
      gruasProrratear: [],
      dialogProrrateoDesglosado: false,
      idTrazabilidad: "",
      trazabilidadFueraServicio: [],
      totalesActivosProrratear:[],
      mesServicio: "",
      sumaGruas: 0,
      dialogParaProrratear: false,
      singleExpand: false,
      expanded: [],
      totalPorActivos: "",
      options: {
        locale: "en-US",
        prefix: "$",
        suffix: "",
        length: 11,

        company_logo: {
          w: 110,
          h: 35,
        },

        precision: 2,
      },
      programaPP: "",
      ppSearchDatos: [],
      BuscarGruasParaProrratear: "",
      gruasEquipoTotalSearch: [],
      gruaEstatus: "pendiente",

      //CARGADORES FRONTALES
      cargadoresProrratear: [],
      programaPPCF: "",
      cargadoresFrontalesProrratear: [],
      cargadoresFrontalesSelected: [],
      sumaCF: 0,
      cfEquipoTotalSearch: [],
      BuscarCFParaProrratear: "",
      ppSearchDatosCF: [],
      cargadoresFrontalesEstatus: "pendiente",

      //Montacargas
      programaPPMontacargas: "",
      montacargasProrratear: [],
      montacargasSelected: [],
      sumaMontacargas: 0,
      montacargasEquipoTotalSearch: [],
      BuscarMontacargasParaProrratear: "",
      ppSearchDatosMontacargas: [],
      montacargasEstatus: "pendiente",
      //Tractocamion
      programaPPTractocamion: "",
      tractocamionProrratear: [],
      tractomacionSelected: [],
      sumaTractocamion: 0,
      tractocamionEquipoTotalSearch: [],
      BuscarTractocamionParaProrratear: "",
      ppSearchDatosTractocamion: [],
      tractocamionEstatus: "pendiente",
      //Camion
      programaPPCamion: "",
      camionProrratear: [],
      camionSelected: [],
      sumaCamion: 0,
      CamionEquipoTotalSearch: [],
      BuscarCamionParaProrratear: "",
      ppSearchDatosCamion: [],
      camionEstatus: "pendiente",
      prorratearUnaVez: true,
      equiposGruaTotales: [],
      equiposCGTotales: [],
      equiposMontacargasTotales: [],
      equiposTractocamionTotales: [],
      equiposCamionTotales: [],

      //cuadrillas
      programaPPCuadrilla: "",
      cuadrillasTotales: [],
      cuadrillasSelected: [],
      cuadrillaEstatus: "pendiente",
      sumaCuadrilla: 0,
      ppSearchDatosCuadrilla: "",
      activosProrratearSocket: [],
      dialogActivosProrratearSocket: false,
      activosDelMes: [],
      tab: null,
      activosProrrateadosEquipo: [],
      mesLocalStorage: null,
      dialogEquiposProrrateados: false,
      activosProrrateadosSelected: [],
      activosProrrateadosDesglosar: [],
      sumaProrrateado: 0,
      activosTodos: [],
    };
  },
  watch: {
    gruasSelected(newGruas) {
      this.sumaGruas = 0;
      newGruas.forEach((e) => {
        if (!e.epep) {
          this.sumaGruas += e.montoEstimado;
        }
      });
      if (this.sumaGruas > this.ppSearchDatos.montoDesglosado) {
        this.addWarningNotification("Has revasado el monto del activo seleccionado");
      }
    },

    cargadoresFrontalesSelected(newCF) {
      this.sumaCF = 0;
      newCF.forEach((e) => {
        if (!e.epep) {
          this.sumaCF += e.montoEstimado;
        }
      });
      if (this.sumaCF > this.ppSearchDatosCF.montoDesglosado) {
        this.addWarningNotification("Has revasado el monto del activo seleccionado");
      }
    },
    montacargasSelected(newMont) {
      this.sumaMontacargas = 0;
      newMont.forEach((e) => {
        if (!e.epep) {
          this.sumaMontacargas += e.montoEstimado;
        }
      });
      if (this.sumaMontacargas > this.ppSearchDatosMontacargas.montoDesglosado) {
        this.addWarningNotification("Has revasado el monto del activo seleccionado");
      }
    },
    tractomacionSelected(newTract) {
      this.sumaTractocamion = 0;
      newTract.forEach((e) => {
        if (!e.epep) {
          this.sumaTractocamion += e.montoEstimado;
        }
      });
      if (this.sumaTractocamion > this.ppSearchDatosTractocamion.montoDesglosado) {
        this.addWarningNotification("Has revasado el monto del activo seleccionado");
      }
    },

    cuadrillasSelected(newCuad) {
      this.sumaCuadrilla = 0;
      newCuad.forEach((e) => {
        if (!e.epep) {
          this.sumaCuadrilla += e.total;
        }
      });
      if (this.sumaCuadrilla > this.ppSearchDatosCuadrilla.montoDesglosado) {
        this.addWarningNotification("Has revasado el monto del activo seleccionado");
      }
    },
    camionSelected(newCamion) {
      this.sumaCamion = 0;
      newCamion.forEach((e) => {
        if (!e.epep) {
          this.sumaCamion += e.montoEstimado;
        }
      });
      if (this.sumaCamion > this.ppSearchDatosCamion.montoDesglosado) {
        this.addWarningNotification("Has revasado el monto del activo seleccionado");
      }
    },
    activosProrrateadosSelected(newMonto) {
      this.sumaProrrateado = 0;
      newMonto.forEach((e) => {
        this.sumaProrrateado += e.montoEstimado;
      });
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo registro" : "Editar registro";
    },
    dateRangeText() {
      return this.periodo.join(" al ");
    },
    config() {
      return {
        bucketName: "cicsagruas.net",
        dirName: "costeos" /* optional */,
        region: "us-west-1",
        accessKeyId: "AKIA5DNSJBDUIMGT267Y",
        secretAccessKey: "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",
        s3Url: this.baseUrl,
      };
    },
    baseUrl() {
      return "https://s3.us-west-1.amazonaws.com/cicsagruas.net";
    },
    S3Client() {
      return new S3(this.config);
    },
    newFileName() {
      return Math.random().toString().slice(2);
    },
    url() {
      return `${this.baseUrl}/${"costeos"}/`;
    },
  },
  created() {
    this.listar();
  },
  methods: {
    listar() {},

    limpiar() {
      (this.arrendadora = ""), (this.editedIndex = -1);
      this.alert = false;
    },

    costoToDollar(text) {
      let number = parseFloat(text);
      return number.toLocaleString("en-US");
    },
    guardar() {
      let me = this;

      if (this.editedIndex > -1) {
        axios
          .put("/activos/update", {
            _id: this._id,
            nombreActivo: this.nombreActivo,
            tipoUnidad: this.tipoUnidad,
            marca: this.marca,
            modelo: this.modelo,
            anio: this.anio,
            capacidad: this.capacidad,
            numeroSerie: this.numeroSerie,
            placa: this.placa,
            estatu: this.estatu,
            detalle: this.detalle,
            observaciones: this.observaciones,
            personal: this.personal,
            propietario: this.propietario,
            factura: this.factura,
            numeroEconomico: this.numeroEconomico,
            claveMicrosip: this.claveMicrosip,
            costo: this.costo,
            ubicacion: this.ubicacion,
            documentacionInfo: this.documentacionAdjuntada,
            fechaDeAdquisicion: this.fechaAdquisicion,
            fotos: this.nombreAdjuntos,
            documentos: this.editarDocumentos,
            notas: this.notas,
            edited: this.personQueEdita,
            estatusActivo: this.estatusActivoN,
          })
          .then(function () {
            me.limpiar();
            me.listar();
            me.addSuccessNotification("Activo actualizado");
            me.ocultarNuevo();
          })
          .catch(function (error) {
            me.addErrorNotification("Error, verifica la informacion agregada" + error);
          });
      } else {
        axios
          .post("/costeo/add", {
            compania: this.compania,
            contrato: this.contrato,
            servicio: this.servicio,
            periodo: this.periodo,
            anio: this.anio,
            capacidad: this.capacidad,
          })
          .then(function () {
            me.limpiar();
            me.listar();
            me.addSuccessNotification("Activo actualizado");
            me.ocultarNuevo();
          })
          .catch(function (error) {
            me.addErrorNotification("Error, verifica la informacion agregada" + error);
          });
      }
    },

    editItem() {
      this.editedIndex = 1;

      this.mostrarNuevo();
    },
    mostrarNuevo() {
      // this.listarAgencia(this.$store.state.usuario.agencia)
      this.mostrarProgramaPress();
      this.verNuevo = 1;
    },
    ocultarNuevo() {
      this.verNuevo = 0;
      this.limpiar();
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },

    addWarningNotification(mensaje) {
      this.$toast.warning("Alerta, " + mensaje, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },

    mostrarTrazabilidadSinTeb() {
      this.dialogLoading = true;
            this.totalPorActivos = []
      this.activosTotalGeneral =[]
      this.activosTotal = []

      this.trazabilidadSinRegistrosTeb = []
      this.trazabilidadRegistros = []
      this.trazabilidadConRegistrosTeb=[]
      this.tebsSinUno = []
      let me = this;
      this.menu2 = false;
      let header = { Token: this.$store.state.token };
      this.tebMesLista = [];
      let total = 0;

      let idTraz = localStorage.getItem("idTraz");

      let configuracion = { headers: header };
      if (idTraz) {
        if (localStorage.getItem("mes") !== new Date(this.mesServicio).toISOString()) {

          this.addWarningNotification("Mes sin prorratear");
          this.dialogLoading = false;
          return;
        }
        let trazabNew = JSON.parse(localStorage.getItem("traz"));
        this.trazabilidadActual = trazabNew;
        this.idTrazabilidad = idTraz;

        trazabNew.forEach((e) => {
          if (e.tipoDeServicioSelec !== "FS") {
            this.trazabilidadRegistros.push(e);
          } else {
            e.numeroDeSolicitud = "X";
            this.trazabilidadFueraServicio.push(e);
          }
        });
        for (let i = 0; i < trazabNew.length; i++) {
          if (
            ((!trazabNew[i].numeroDeSolicitud || trazabNew[i].numeroDeSolicitud == "X" || trazabNew[i].numeroDeSolicitud == "x") &&
              !trazabNew[i].activoPemex &&
              trazabNew[i].tipoDeServicioSelec !== "FS") ||
            trazabNew[i].prorrateado == "SI"
          ) {
            this.trazabilidadSinRegistrosTeb.push(trazabNew[i]);
            total = trazabNew[i].montoEstimado + total;
          } else {
            this.trazabilidadConRegistrosTeb.push(trazabNew[i]);
          }
        }
        this.totalProrratear = Number.parseFloat(total)
          .toFixed(2)
          .toLocaleString("en-US");
        this.dialogLoading = false;
        this.mostrarTebSinUsar();
      } else {
        axios
          .get("/trazabilidad/queryTrazabilidad?mes=" + this.mesServicio, configuracion)
          .then(function (response) {
            me.trazabilidadActual = response.data.trazabilidad;
            me.idTrazabilidad = response.data._id;
            //me.mesLocalStorage = response.data.mes
            me.$store.dispatch("mes", response.data.mes);

            response.data.trazabilidad.forEach((e) => {
              if (e.tipoDeServicioSelec !== "FS") {
                me.trazabilidadRegistros.push(e);
              } else {
                e.numeroDeSolicitud = "X";
                me.trazabilidadFueraServicio.push(e);
              }
            });

            me.$store.dispatch("guardarFs", me.trazabilidadFueraServicio);
            const trazabilidadMes = response.data.trazabilidad;
           

            trazabilidadMes.forEach((e)=>{
              if((e.numeroDeSolicitud == '' || e.numeroDeSolicitud=='X' || e.numeroDeSolicitud=='x' || e.numeroDeSolicitud == null) && (e.tipoDeServicioSelec !== 'MANT' && e.tipoDeServicioSelec !== 'F/S' && e.tipoDeServicioSelec !== 'N/A'  && e.tipoDeServicioSelec !== 'MTTO'  && e.tipoDeServicioSelec !== 'FS')){
                me.trazabilidadSinRegistrosTeb.push(e);
                total = e.montoEstimado + total;
                
              }else{
                if(e.tipoDeServicioSelec === 'J' || e.tipoDeServicioSelec === 'D' || e.tipoDeServicioSelec === 'S'){
                  me.trazabilidadConRegistrosTeb.push(e);
                }
              }
            })
            console.log(total)


            /* trazabilidadMes.forEach((e)=>{
              if (e.numeroDeSolicitud.length > 4 && (e.tipoDeServicioSelec === 'J' || e.tipoDeServicioSelec === 'D' || e.tipoDeServicioSelec === 'S')) {
                console.log(e);
              }
            }) */
           /*  trazabilidadMes.forEach((trazabilidad) => {
              console.log(trazabilidadMes);

              if (
                (trazabilidad.numeroDeSolicitud.length>4) &&
                (trazabilidad.tipoDeServicioSelec == "J" || trazabilidad.tipoDeServicioSelec == "D" ||  trazabilidad.tipoDeServicioSelec == "S")
              ) {
                me.trazabilidadConRegistrosTeb.push(trazabilidad);
              } 
            }); */

            console.log(me.trazabilidadConRegistrosTeb);

            me.totalProrratear = Number.parseFloat(total)
              .toFixed(2)
              .toLocaleString("en-US");
            me.dialogLoading = false;
            me.mostrarTebSinUsar();
          })
          .catch(function () {
            me.dialogLoading = false;
          });
      }
    },
    mostrarTebSinUsar() {
      let hash = {};
      let tebSinRepetir = [];
      tebSinRepetir = this.trazabilidadConRegistrosTeb.filter((e) =>
        hash[e.numeroDeSolicitud] ? false : (hash[e.numeroDeSolicitud] = true)
      );
      this.e1 = 2;
      this.dialogLoading = true;
      let me = this;
      this.menu2 = false;
      let keys = [];
      let header = { Token: this.$store.state.token };
      let tebMesLista = [];
      let configuracion = { headers: header };
      let idTraz = localStorage.getItem("idTraz");
      if (idTraz) {
        tebMesLista = JSON.parse(localStorage.getItem("teb"));
        let tebRegistrosOrigSinRepetir = [];
        tebSinRepetir.forEach((e) => {
          tebRegistrosOrigSinRepetir.push(e.numeroDeSolicitud);
        });
        for (let i = 0; i < tebRegistrosOrigSinRepetir.length; i++) {
          if (tebMesLista.some((e) => e.teb === tebRegistrosOrigSinRepetir[i])) {
            keys.push(
              tebMesLista.findIndex((item) => item.teb === tebRegistrosOrigSinRepetir[i])
            );
          }
        }
        for (let i = 0; i < keys.length; i++) {
          tebMesLista[keys[i]] = [];
          /* reg.push(tebMesLista[keys[i]]); */
        }
        let teb = [];
        tebMesLista.forEach((e) => {
          if (e.teb) {
            teb.push(e);
          }
        });

        me.tebsSinUno = teb;
        me.totalActivos();
        me.dialogLoading = false;
      } else {
        axios
          .get("/teb/queryMes?mes=" + this.mesServicio, configuracion)
          .then(function (response) {
            tebMesLista = response.data.tebRegistros;
            let tebRegistrosOrigSinRepetir = [];
            tebSinRepetir.forEach((e) => {
              tebRegistrosOrigSinRepetir.push(e.numeroDeSolicitud);
            });

            const newArray = tebMesLista.filter(registro => {
              return !tebRegistrosOrigSinRepetir.some(elemento => registro.teb.includes(elemento));
            });
           
            
            me.tebsSinUno = newArray;
            me.totalActivos();
            me.dialogLoading = false;
          })
          .catch(function (e) {
            console.log("error" + e);

            me.dialogLoading = false;
          });
      }
    },

    totalActivos() {
      // AEMS
      let AEMSPP = [];
      let AEMS = [];
      let AEMSTotal = [];
      let conteoAEMS = 0;
      let activosTreview = [];
      AEMS = this.tebsSinUno.filter((e) => e.activo == "AEMS");

      for (let i = 0; i < AEMS.length; i++) {
        if (!AEMSPP.includes(AEMS[i].programaPressL)) {
          AEMSPP.push(AEMS[i].programaPressL);
        }
      }
      for (let i = 0; i < AEMSPP.length; i++) {
        let te = AEMS.filter((e) => e.programaPressL == AEMSPP[i]);
        conteoAEMS = te.length + conteoAEMS;

        AEMSTotal.push({
          activo: "AEMS",
          pp: te[0].programaPressL,
          epep: te[0].epepRegistro,
          total: te.length,
        });
      }

      //APAPCH
      let APAPCHPP = [];
      let APAPCH = [];
      let APAPCHTotal = [];
      let conteoAPAPCH = 0;
      APAPCH = this.tebsSinUno.filter((e) => e.activo == "APAPCH");

      for (let i = 0; i < APAPCH.length; i++) {
        if (!APAPCHPP.includes(APAPCH[i].programaPressL)) {
          APAPCHPP.push(APAPCH[i].programaPressL);
        }
      }
      for (let i = 0; i < APAPCHPP.length; i++) {
        let te = APAPCH.filter((e) => e.programaPressL == APAPCHPP[i]);
        conteoAPAPCH = te.length + conteoAPAPCH;

        APAPCHTotal.push({
          activo: "APAPCH",
          pp: te[0].programaPressL,
          epep: te[0].epepRegistro,
          total: te.length,
        });
      }

      //APC
      let APCPP = [];
      let APC = [];
      let APCTotal = [];
      let conteoAPC = 0;
      APC = this.tebsSinUno.filter((e) => e.activo == "APC");

      for (let i = 0; i < APC.length; i++) {
        if (!APCPP.includes(APC[i].programaPressL)) {
          APCPP.push(APC[i].programaPressL);
        }
      }
      for (let i = 0; i < APCPP.length; i++) {
        let te = APC.filter((e) => e.programaPressL == APCPP[i]);
        conteoAPC = te.length + conteoAPC;

        APCTotal.push({
          activo: "APC",
          pp: te[0].programaPressL,
          epep: te[0].epepRegistro,
          total: te.length,
        });
      }

      //APKMZPP
      let APKMZPP = [];
      let APKMZ = [];
      let APKMZTotal = [];
      let conteoAPKMZ = 0;
      APKMZ = this.tebsSinUno.filter((e) => e.activo == "APKMZ");

      for (let i = 0; i < APKMZ.length; i++) {
        if (!APKMZPP.includes(APKMZ[i].programaPressL)) {
          APKMZPP.push(APKMZ[i].programaPressL);
        }
      }
      for (let i = 0; i < APKMZPP.length; i++) {
        let te = APKMZ.filter((e) => e.programaPressL == APKMZPP[i]);
        conteoAPKMZ = te.length + conteoAPKMZ;

        APKMZTotal.push({
          activo: "APKMZ",
          pp: te[0].programaPressL,
          epep: te[0].epepRegistro,
          total: te.length,
        });
      }
      //APLT
      let APLTPP = [];
      let APLT = [];
      let APLTTotal = [];
      let conteoAPLT = 0;
      APLT = this.tebsSinUno.filter((e) => e.activo == "APLT");

      for (let i = 0; i < APLT.length; i++) {
        if (!APLTPP.includes(APLT[i].programaPressL)) {
          APLTPP.push(APLT[i].programaPressL);
        }
      }
      for (let i = 0; i < APLTPP.length; i++) {
        let te = APLT.filter((e) => e.programaPressL == APLTPP[i]);
        conteoAPLT = te.length + conteoAPLT;

        APLTTotal.push({
          activo: "APLT",
          pp: te[0].programaPressL,
          epep: te[0].epepRegistro,
          total: te.length,
        });
      }
      //SPEE
      let SPEEPP = [];
      let SPEE = [];
      let SPEETotal = [];
      let conteoSPEE = 0;
      SPEE = this.tebsSinUno.filter((e) => e.activo == "SPEE");

      for (let i = 0; i < SPEE.length; i++) {
        if (!SPEEPP.includes(SPEE[i].programaPressL)) {
          SPEEPP.push(SPEE[i].programaPressL);
        }
      }
      for (let i = 0; i < SPEEPP.length; i++) {
        let te = SPEE.filter((e) => e.programaPressL == SPEEPP[i]);
        conteoSPEE = te.length + conteoSPEE;

        SPEETotal.push({
          activo: "SPEE",
          pp: te[0].programaPressL,
          epep: te[0].epepRegistro,
          total: te.length,
        });
      }

      //APK EK BALAM
      let EBPP = [];
      let EB = [];
      let EBTotal = [];
      let conteoEB = 0;
      EB = this.tebsSinUno.filter((e) => e.activo == "APC-EK-BALAM");

      for (let i = 0; i < EB.length; i++) {
        if (!EBPP.includes(EB[i].programaPressL)) {
          EBPP.push(EB[i].programaPressL);
        }
      }
      for (let i = 0; i < EBPP.length; i++) {
        let te = EB.filter((e) => e.programaPressL == EBPP[i]);
        conteoEB = te.length + conteoEB;

        EBTotal.push({
          activo: "APC-EK-BALAM",
          pp: te[0].programaPressL,
          epep: te[0].epepRegistro,
          total: te.length,
        });
      }

      //Bloque Norte
      let BNPP = [];
      let BN = [];
      let BNTotal = [];
      let conteoBN = 0;
      BN = this.tebsSinUno.filter((e) => e.activo == "Bloque Norte");

      for (let i = 0; i < BN.length; i++) {
        if (!BNPP.includes(BN[i].programaPressL)) {
          BNPP.push(BN[i].programaPressL);
        }
      }
      for (let i = 0; i < BNPP.length; i++) {
        let te = BN.filter((e) => (e.programaPressL = BNPP[i]));
        conteoBN = te.length + conteoBN;

        BNTotal.push({
          activo: "APC-EK-BALAM",
          pp: te[0].programaPressL,
          epep: te[0].epepRegistro,
          total: te.length,
        });
      }
      
      //AEMS1
      let AEMS1PP = [];
      let AEMS1 = [];
      let AEMS1Total = [];
      let conteoAEMS1 = 0;
      AEMS1 = this.tebsSinUno.filter((e) => e.activo == "AEMS1");
      for (let i = 0; i < AEMS1.length; i++) {
        if (!AEMS1PP.includes(AEMS1[i].programaPressL)) {
          AEMS1PP.push(AEMS1[i].programaPressL);
        }
      }
      let test = AEMS1.filter((e)=>e.programaPressL=='3K2EE000RB0KBF00')
      console.log(test);
      for (let i = 0; i < AEMS1PP.length; i++) {
        let te = AEMS1.filter((e) => (e.programaPressL == AEMS1PP[i]));
        conteoAEMS1 = te.length + conteoAEMS1;

        AEMS1Total.push({
          activo: "AEMS1",
          pp: te[0].programaPressL,
          epep: te[0].epepRegistro,
          total: te.length,
        });
      }
      //NUEVO ACTIVO
      //AEMS3
      let AEMS3PP = [];
      let AEMS3 = [];
      let AEMS3Total = [];
      let conteoAEMS3 = 0;
      AEMS3 = this.tebsSinUno.filter((e) => e.activo == "AEMS3");
      for (let i = 0; i < AEMS3.length; i++) {
        if (!AEMS3PP.includes(AEMS3[i].programaPressL)) {
          AEMS3PP.push(AEMS3[i].programaPressL);
        }
      }
      for (let i = 0; i < AEMS3PP.length; i++) {
        let te = AEMS3.filter((e) => (e.programaPressL = AEMS3PP[i]));
        conteoAEMS3 = te.length + conteoAEMS3;

        AEMS3Total.push({
          activo: "AEMS3",
          pp: te[0].programaPressL,
          epep: te[0].epepRegistro,
          total: te.length,
        });
      }

      activosTreview = [
        {
          name: "AEMS -" + conteoAEMS,
          children: AEMSTotal,
          total: conteoAEMS,
        },
        {
          name: "APAPCH -" + conteoAPAPCH,
          children: APAPCHTotal,
          total: conteoAPAPCH,
        },
        {
          name: "APC -" + conteoAPC,
          children: APCTotal,
          total: conteoAPC,
        },
        {
          name: "APKMZ -" + conteoAPKMZ,
          children: APKMZTotal,
          total: conteoAPKMZ,
        },
        {
          name: "APLT -" + conteoAPLT,
          children: APLTTotal,
          total: conteoAPLT,
        },
        {
          name: "SPEE -" + conteoSPEE,
          children: SPEETotal,
          total: conteoSPEE,
        },
        {
          name: "APC-EK-BALAM -" + conteoEB,
          children: EBTotal,
          total: conteoEB,
        },
        {
          name: "BLOQUE NORTE -" + conteoBN,
          children: BNTotal,
          total: conteoBN,
        },
        {
          name: "AEMS1 -" + conteoAEMS1,
          children: AEMS1Total,
          total: conteoAEMS1,
        },
        //NUEVO ACTIVO
        {
          name: "AEMS3 -" + conteoAEMS3,
          children: AEMS3Total,
          total: conteoAEMS3,
        },
      ];

      let aemsTotalGeneral = AEMSTotal.filter((e) => e.total >= 10);
      let apapchTotalGeneral = APAPCHTotal.filter((e) => e.total >= 10);
      let apcTotalGeneral = APCTotal.filter((e) => e.total >= 10);
      let apkmzTotalGeneral = APKMZTotal.filter((e) => e.total >= 10);
      let apltTotalGeneral = APLTTotal.filter((e) => e.total >= 10);
      let speeTotalGeneral = SPEETotal.filter((e) => e.total >= 10);
      let ebTotalGeneral = EBTotal.filter((e) => e.total >= 10);
      let bnTotalGeneral = BNTotal.filter((e) => e.total >= 10);
      let aems1TotalGeneral = AEMS1Total.filter((e) => e.total >= 10);
      //NUEVO ACTIVO
      let aems3TotalGeneral = AEMS3Total.filter((e) => e.total >= 10);

      let idTraz = localStorage.getItem("idTraz");
      if (idTraz) {
        this.activosTotalGeneral = JSON.parse(localStorage.getItem("activosGeneral"));
      } else {
        this.activosTotalGeneral.push(
          {
            id: 0,
            aems: aemsTotalGeneral,
            activo: "AEMS",
          },
          {
            id: 1,
            apapch: apapchTotalGeneral,
            activo: "APAPCH",
          },
          {
            id: 2,
            apc: apcTotalGeneral,
            activo: "APC",
          },
          {
            id: 3,
            apkmz: apkmzTotalGeneral,
            activo: "APKMZ",
          },
          {
            id: 4,
            aplt: apltTotalGeneral,
            activo: "APLT",
          },
          {
            id: 5,
            spee: speeTotalGeneral,
            activo: "SPEE",
          },
          {
            id: 6,
            eb: ebTotalGeneral,
            activo: "APC-EK-BALAM",
          },
          {
            id: 7,
            bn: bnTotalGeneral,
            activo: "BLOQUE NORTE",
          },
          {
            id: 8,
            aems1: aems1TotalGeneral,
            activo: "AEMS1",
          },
          //NUEVO ACTIVO
          {
            id: 9,
            aems3: aems3TotalGeneral,
            activo: "AEMS3",
          },
          
        );
      }
      let sumaGeneralActivosAems = 0;
      let sumaGeneralActivosApapch = 0;
      let sumaGeneralActivosApc = 0;
      let sumaGeneralActivosApkmz = 0;
      let sumaGeneralActivosAplt = 0;
      let sumaGeneralActivosSpee = 0;
      let sumaGeneralActivosEB = 0;
      let sumaGeneralActivosBN = 0;
      let sumaGeneralActivosAems1 = 0;
      //NUEVO ACTIVO
      let sumaGeneralActivosAems3 = 0;
      aemsTotalGeneral.forEach(
        (e) => (sumaGeneralActivosAems = e.total + sumaGeneralActivosAems)
      );
      apapchTotalGeneral.forEach(
        (e) => (sumaGeneralActivosApapch = e.total + sumaGeneralActivosApapch)
      );
      apcTotalGeneral.forEach(
        (e) => (sumaGeneralActivosApc = e.total + sumaGeneralActivosApc)
      );
      apkmzTotalGeneral.forEach(
        (e) => (sumaGeneralActivosApkmz = e.total + sumaGeneralActivosApkmz)
      );
      apltTotalGeneral.forEach(
        (e) => (sumaGeneralActivosAplt = e.total + sumaGeneralActivosAplt)
      );
      speeTotalGeneral.forEach(
        (e) => (sumaGeneralActivosSpee = e.total + sumaGeneralActivosSpee)
      );
      ebTotalGeneral.forEach(
        (e) => (sumaGeneralActivosEB = e.total + sumaGeneralActivosEB)
      );
      bnTotalGeneral.forEach(
        (e) => (sumaGeneralActivosBN = e.total + sumaGeneralActivosBN)
      );
      aems1TotalGeneral.forEach(
        (e) => (sumaGeneralActivosAems1 = e.total + sumaGeneralActivosAems1)
      );
      //NUEVO ACTIVO
      aems3TotalGeneral.forEach(
        (e) => (sumaGeneralActivosAems3 = e.total + sumaGeneralActivosAems3)
      );

      this.activosTotalGeneral[0].total = sumaGeneralActivosAems;
      this.activosTotalGeneral[1].total = sumaGeneralActivosApapch;
      this.activosTotalGeneral[2].total = sumaGeneralActivosApc;
      this.activosTotalGeneral[3].total = sumaGeneralActivosApkmz;
      this.activosTotalGeneral[4].total = sumaGeneralActivosAplt;
      this.activosTotalGeneral[5].total = sumaGeneralActivosSpee;
      this.activosTotalGeneral[6].total = sumaGeneralActivosEB;
      this.activosTotalGeneral[7].total = sumaGeneralActivosBN;
      this.activosTotalGeneral[8].total = sumaGeneralActivosAems1;
      //NUEVO ACTIVO
      this.activosTotalGeneral[9].total = sumaGeneralActivosAems3;

      this.activosTotal = activosTreview;
      this.totalPorActivos =
        AEMS.length +
        APAPCH.length +
        APC.length +
        APKMZ.length +
        APLT.length +
        SPEE.length +
        EB.length +
        AEMS1.length;
        //NUEVO ACTIVO
        AEMS3.length;
    },
    agregarAPCEK() {
      this.dialogApk = true;

      this.apcEk_balam = this.activosTotal[6];
    },

    sumFieldProrrateoAems(key) {
      return this.prorrateoTables.aems.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldProrrateoApapch(key) {
      return this.prorrateoTables.apapch.reduce((a, b) => a + (b[key] || 0), 0);
    },

    sumFieldProrrateoApc(key) {
      return this.prorrateoTables.apc.reduce((a, b) => a + (b[key] || 0), 0);
    },

    sumFieldProrrateoApkmz(key) {
      return this.prorrateoTables.apkmz.reduce((a, b) => a + (b[key] || 0), 0);
    },

    sumFieldProrrateoAplt(key) {
      return this.prorrateoTables.aplt.reduce((a, b) => a + (b[key] || 0), 0);
    },

    sumFieldProrrateoSpee(key) {
      return this.prorrateoTables.spee.reduce((a, b) => a + (b[key] || 0), 0);
    },

    sumFieldProrrateoApc_Ek_blm(key) {
      return this.prorrateoTables.apk_ek_blm.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldProrrateoAems1(key) {
      return this.prorrateoTables.aems1.reduce((a, b) => a + (b[key] || 0), 0);
    },

    sumField(key) {
      return this.activosTotalGeneral.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldAems(key) {
      return this.activosTotalGeneral[0].aems.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldsApapch(key) {
      return this.activosTotalGeneral[1].apapch.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldsApc(key) {
      return this.activosTotalGeneral[2].apc.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldsApkmz(key) {
      return this.activosTotalGeneral[3].apkmz.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldsAplt(key) {
      return this.activosTotalGeneral[4].aplt.reduce((a, b) => a + (b[key] || 0), 0);
    },

    sumFieldsApSpee(key) {
      return this.activosTotalGeneral[5].spee.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldsAeb(key) {
      return this.activosTotalGeneral[6].eb.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldsAems1(key) {
      return this.activosTotalGeneral[8].aems1.reduce((a, b) => a + (b[key] || 0), 0);
    },
      //NUEVO ACTIVO
    sumFieldsAems3(key) {
      return this.activosTotalGeneral[9].aems3.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldEquiposTotalGeneral(key) {
      return this.equiposDesglosadosTable.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldEquiposTotalGeneralGruas(key) {
      return this.equiposGeneralGrua.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldEquiposTotalGeneralCargadorFrontal(key) {
      return this.equiposGeneralCargadorFrontal[1].reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldEquiposTotalGeneralMontacargas(key) {
      return this.equiposGeneralMontacargargas[1].reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumFieldEquiposTotalPersonalGeneral(key) {
      return this.generalPersonalTecnico[1].reduce((a, b) => a + (b[key] || 0), 0);
    },

    async obtenerTodosActivosDeslglosados() {
      let activosDesglosados = [];
      this.activosTotalGeneral[0].aems.forEach((e) => {
        let obj = {
          activo: e.activo,
          epep: e.epep,
          pp: e.pp,
          total: e.montoDesglosado,
          fecha: this.mesServicio,
        };
        activosDesglosados.push(obj);
      });
      this.activosTotalGeneral[1].apapch.forEach((e) => {
        let obj = {
          activo: e.activo,
          epep: e.epep,
          pp: e.pp,
          total: e.montoDesglosado,
          fecha: this.mesServicio,
        };
        activosDesglosados.push(obj);
      });
      this.activosTotalGeneral[2].apc.forEach((e) => {
        let obj = {
          activo: e.activo,
          epep: e.epep,
          pp: e.pp,
          total: e.montoDesglosado,
          fecha: this.mesServicio,
        };
        activosDesglosados.push(obj);
      });
      this.activosTotalGeneral[3].apkmz.forEach((e) => {
        let obj = {
          activo: e.activo,
          epep: e.epep,
          pp: e.pp,
          total: e.montoDesglosado,
          fecha: this.mesServicio,
        };
        activosDesglosados.push(obj);
      });
      this.activosTotalGeneral[4].aplt.forEach((e) => {
        let obj = {
          activo: e.activo,
          epep: e.epep,
          pp: e.pp,
          total: e.montoDesglosado,
          fecha: this.mesServicio,
        };
        activosDesglosados.push(obj);
      });
      this.activosTotalGeneral[5].spee.forEach((e) => {
        let obj = {
          activo: e.activo,
          epep: e.epep,
          pp: e.pp,
          total: e.montoDesglosado,
          fecha: this.mesServicio,
        };
        activosDesglosados.push(obj);
      });
      this.activosTotalGeneral[6].eb.forEach((e) => {
        let obj = {
          activo: e.activo,
          epep: e.epep,
          pp: e.pp,
          total: e.montoDesglosado,
          fecha: this.mesServicio,
        };
        activosDesglosados.push(obj);
      });
      this.activosTotalGeneral[7].bn.forEach((e) => {
        let obj = {
          activo: e.activo,
          epep: e.epep,
          pp: e.pp,
          total: e.montoDesglosado,
          fecha: this.mesServicio,
        };
        activosDesglosados.push(obj);
      });
      this.activosTotalGeneral[8].aems1.forEach((e) => {
        let obj = {
          activo: e.activo,
          epep: e.epep,
          pp: e.pp,
          total: e.montoDesglosado,
          fecha: this.mesServicio,
        };
        
        activosDesglosados.push(obj);
      });
      this.activosTotalGeneral[9].aems3.forEach((e) => {
        let obj = {
          activo: e.activo,
          epep: e.epep,
          pp: e.pp,
          total: e.montoDesglosado,
          fecha: this.mesServicio,
        };
        
        activosDesglosados.push(obj);
      });
      console.log(this.activosTotalGeneral)

     
      await api
        .insertarActivosDesglosados(activosDesglosados)
        .then((e) => {
          console.log(e.statusText);
        })
        .catch((error) => {
          this.addWarningNotification("no se ingresaron los registros" + error);
        });
    },

    mostrarDialogActivosDesglosado() {
      this.dialogActivoDesglosado = true;
    },

    guardarActivoApcEB(item) {

      if (this.activosTotalGeneral.length == 6) {
        this.activosTotalGeneral.push({
          activo: "APC-EK-BALAM",
          id: 6,
          eb: [item],
          total: item.total,
        });
        this.eliminarRegistro(item);
      } else {
        this.activosTotalGeneral[6].eb.push(item);
        this.activosTotalGeneral[6].total =
          this.activosTotalGeneral[6].total + item.total;
      }

      this.eliminarRegistro(item);
    },

    eliminarRegistro(item) {
      let i = this.apcEk_balam.children.indexOf(item);
      if (i != -1) {
        this.apcEk_balam.children.splice(i, 1);
      }
    },
    porcentajeMontoTotalCalculo() {
      this.mostrarActivosPorEquipo();

      let idTraz = localStorage.getItem("idTraz");
      if (!idTraz) {
        let sumaTotalActivos = 0;
        this.activosTotalGeneral.forEach((e) => (sumaTotalActivos += parseInt(e.total)));
        this.activosTotalGeneral.forEach((e) => {
          e.porcentaje = (e.total / sumaTotalActivos) * 100;
          e.monto = this.totalProrratear * (e.total / sumaTotalActivos);
        });
        this.tablaActivos = true;
        this.montoTotalActivosDesglosado();
        this.mostrarEquiposTotal();
        this.obtenerTodosActivosDeslglosados();
      } else {
        let sumaTotalActivos = 0;
        this.activosTotalGeneral.forEach((e) => (sumaTotalActivos += parseInt(e.total)));
        this.activosTotalGeneral.forEach((e) => {
          e.porcentaje = (e.total / sumaTotalActivos) * 100;
        });
        this.tablaActivos = true;
        this.montoTotalActivosDesglosado();
        this.mostrarEquiposTotal();
        this.obtenerTodosActivosDeslglosados();
        this.validarProrrateadosCompleto();
      }

       this.listarActivosProrrateo(this.mesServicio)
    },
    montoTotalActivosDesglosado() {
      let idTraz = localStorage.getItem("idTraz");
      if (!idTraz) {
        this.activosTotalGeneral[0].aems.forEach((e) => {
          e.porcentaje =
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[0].total)) * 100;
          e.montoDesglosado =
            parseFloat(this.activosTotalGeneral[0].monto) *
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[0].total));
        });

        this.activosTotalGeneral[1].apapch.forEach((e) => {
          e.porcentaje =
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[1].total)) * 100;
          e.montoDesglosado =
            parseFloat(this.activosTotalGeneral[1].monto) *
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[1].total));
        });
        this.activosTotalGeneral[2].apc.forEach((e) => {
          e.porcentaje =
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[2].total)) * 100;
          e.montoDesglosado =
            parseFloat(this.activosTotalGeneral[2].monto) *
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[2].total));
        });
        this.activosTotalGeneral[3].apkmz.forEach((e) => {
          e.porcentaje =
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[3].total)) * 100;
          e.montoDesglosado =
            parseFloat(this.activosTotalGeneral[3].monto) *
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[3].total));
        });
        this.activosTotalGeneral[4].aplt.forEach((e) => {
          e.porcentaje =
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[4].total)) * 100;
          e.montoDesglosado =
            parseFloat(this.activosTotalGeneral[4].monto) *
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[4].total));
        });
        if (this.activosTotalGeneral[5]) {
          this.activosTotalGeneral[5].spee.forEach((e) => {
            e.porcentaje =
              (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[5].total)) * 100;
            e.montoDesglosado =
              parseFloat(this.activosTotalGeneral[5].monto) *
              (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[5].total));
          });
        }
        if (this.activosTotalGeneral[6]) {
          this.activosTotalGeneral[6].eb.forEach((e) => {
            e.porcentaje =
              (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[6].total)) * 100;
            e.montoDesglosado =
              parseFloat(this.activosTotalGeneral[6].monto) *
              (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[6].total));
          });
        }

        if (this.activosTotalGeneral[8]) {
          this.activosTotalGeneral[8].aems1.forEach((e) => {
            e.porcentaje =
              (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[8].total)) * 100;
            e.montoDesglosado =
              parseFloat(this.activosTotalGeneral[8].monto) *
              (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[8].total));
          });
        }
        //AGREGAR ACTIVO
        if (this.activosTotalGeneral[9]) {
          this.activosTotalGeneral[9].aems3.forEach((e) => {
            e.porcentaje =
              (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[9].total)) * 100;
            e.montoDesglosado =
              parseFloat(this.activosTotalGeneral[9].monto) *
              (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[9].total));
          });
        }
        


        
        this.tablasActivosDeglosado = true;
      } else {
        this.activosTotalGeneral[0].aems.forEach((e) => {
          e.porcentaje =
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[0].total)) * 100;
        });
        this.activosTotalGeneral[1].apapch.forEach((e) => {
          e.porcentaje =
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[1].total)) * 100;
        });
        this.activosTotalGeneral[2].apc.forEach((e) => {
          e.porcentaje =
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[2].total)) * 100;
        });
        this.activosTotalGeneral[3].apkmz.forEach((e) => {
          e.porcentaje =
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[3].total)) * 100;
        });
        this.activosTotalGeneral[4].aplt.forEach((e) => {
          e.porcentaje =
            (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[4].total)) * 100;
        });
        if (this.activosTotalGeneral[5]) {
          this.activosTotalGeneral[5].spee.forEach((e) => {
            e.porcentaje =
              (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[5].total)) * 100;
          });
        }
        if (this.activosTotalGeneral[6]) {
          this.activosTotalGeneral[6].eb.forEach((e) => {
            e.porcentaje =
              (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[6].total)) * 100;
          });
        }

        if (this.activosTotalGeneral[8]) {
          this.activosTotalGeneral[8].aems1.forEach((e) => {
            e.porcentaje =
              (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[8].total)) * 100;
          });
        }

        if (this.activosTotalGeneral[9]) {
          this.activosTotalGeneral[9].aems3.forEach((e) => {
            e.porcentaje =
              (parseFloat(e.total) / parseFloat(this.activosTotalGeneral[9].total)) * 100;
          });
        }

        this.tablasActivosDeglosado = true;
      }
    },

    mostrarEquiposTotal() {
      let grua = [];
      let total = 0;
      let cargador_frontal = [];
      let montacarga = [];
      let tracto = [];
      let mano_obra = [];
      let personal = [];
      let equiposDesglosados = [];
      let camion = [];

      this.trazabilidadSinRegistrosTeb.forEach((e) => {
        if (e.activoEquipo.substr(0, 2) == "G-") {
          grua.push(e);
          total = e.montoEstimado + total;
        }
      });
      equiposDesglosados.push({ grua: { equipos: grua, total: total } });
      total = 0;
      this.trazabilidadSinRegistrosTeb.forEach((e) => {
        if (
          e.activoEquipo.substr(0, 3) == "CFG" ||  e.activoEquipo.substr(0, 3) == "CGF"
        ) {
          cargador_frontal.push(e);
          total = e.montoEstimado + total;
        }
      });
      equiposDesglosados.push({
        cargador_frontal: { equipos: cargador_frontal, total: total },
      });
      total = 0;
      this.trazabilidadSinRegistrosTeb.forEach((e) => {
        if (e.activoEquipo.substr(0, 4) == "MTC-") {
          montacarga.push(e);
          total = e.montoEstimado + total;
        }
      });
      equiposDesglosados.push({
        montacarga: { equipos: montacarga, total: total },
      });
      total = 0;
      this.trazabilidadSinRegistrosTeb.forEach((e) => {
        if (
          e.activoEquipo.substr(0, 2) == "T-"
        ) {
          tracto.push(e);
          total = e.montoEstimado + total;
        }
      });
      equiposDesglosados.push({ tracto: { equipos: tracto, total: total } });
      total = 0;
      this.trazabilidadSinRegistrosTeb.forEach((e) => {
        if (
          e.activoEquipo.substr(0, 4) == "CABO" ||
          e.activoEquipo.substr(0, 6) == "MANIOB"
        ) {
          personal.push(e);
          total = e.montoEstimado + total;
        }
      });
      equiposDesglosados.push({
        personal: { equipos: personal, total: total },
      });
      total = 0;
      this.trazabilidadSinRegistrosTeb.forEach((e) => {
        if (e.activoEquipo.substr(0, 6) == "PERSON") {
          mano_obra.push(e);
          total = e.montoEstimado + total;
        }
      });
      equiposDesglosados.push({
        mano_obra: { equipos: mano_obra, total: total },
      });
      total = 0;
      this.trazabilidadSinRegistrosTeb.forEach((e) => {
        if (e.activoEquipo.substr(0, 2) == "M-") {
          camion.push(e);
          total = e.montoEstimado + total;
        }
      });
      equiposDesglosados.push({ camion: { equipos: camion, total: total } });

      this.equiposDesglosados = equiposDesglosados;
      this.equiposDesglosadosTable = [
        {
          equipo: "Grúa",
          total: equiposDesglosados[0].grua.total,
        },
        {
          equipo: "Cargador Frontal",
          total: equiposDesglosados[1].cargador_frontal.total,
        },
        {
          equipo: "Montacargas",
          total: equiposDesglosados[2].montacarga.total,
        },
        {
          equipo: "Tractocamion",
          total: equiposDesglosados[3].tracto.total,
        },
        {
          equipo: "Personal",
          total: equiposDesglosados[4].personal.total,
        },
        {
          equipo: "Mano de Obra",
          total: equiposDesglosados[5].mano_obra.total,
        },
        {
          equipo: "Camion con Grua",
          total: equiposDesglosados[6].camion.total,
        },
      ];
      //this.dialogEquipoDesglosado = true;
      let grua_total = [];
      let cargador_frontal_total = [];
      let montacarga_total = [];
      let tractoc = [];
      let personalTecnico = [];
      let mano_obra1 = [];
      let mano_obra2 = [];
      let mano_obra3 = [];
      let mano_obra4 = [];
      let mano_obra5 = [];
      let mano_obra6 = [];
      let mano_obra7 = [];
      let mano_obra8 = [];
      let mano_obra9 = [];
      let mano_obra10 = [];
      let mano_obra11 = [];
      let camion_grua = [];

      //    let objeto2=[]
      this.trazabilidadSinRegistrosTeb.forEach((fila) => {
        if (fila.activoEquipo.substr(0, 2) == "G-") {
          if (!Object.prototype.hasOwnProperty.call(grua_total, fila.activoEquipo)) {
            grua_total[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          grua_total[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          grua_total[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (
          fila.activoEquipo.substr(0, 3) == "CFG" ||  fila.activoEquipo.substr(0, 3) == "CGF"
        ) {
          if (
            !Object.prototype.hasOwnProperty.call(
              cargador_frontal_total,
              fila.activoEquipo
            )
          ) {
            cargador_frontal_total[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          cargador_frontal_total[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          cargador_frontal_total[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (fila.activoEquipo.substr(0, 4) == "MTC-") {
          if (
            !Object.prototype.hasOwnProperty.call(montacarga_total, fila.activoEquipo)
          ) {
            montacarga_total[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          montacarga_total[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          montacarga_total[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (
          fila.activoEquipo.substr(0, 2) == "T-"
        ) {
          if (!Object.prototype.hasOwnProperty.call(tractoc, fila.activoEquipo)) {
            tractoc[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          tractoc[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          tractoc[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (fila.activoEquipo.substr(0, 2) == "M-") {
          if (!Object.prototype.hasOwnProperty.call(camion_grua, fila.activoEquipo)) {
            camion_grua[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          camion_grua[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          camion_grua[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }

        if (
          fila.activoEquipo.substr(0, 11) == "PERSONAL 01" ||
          fila.activoEquipo.substr(0, 11) == "PERSONAL 02" ||
          fila.activoEquipo.substr(0, 11) == "PERSONAL 03" ||
          fila.activoEquipo.substr(0, 11) == "PERSONAL 04"
        ) {
          if (!Object.prototype.hasOwnProperty.call(personalTecnico, fila.activoEquipo)) {
            personalTecnico[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          personalTecnico[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          personalTecnico[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }

        if (
          fila.activoEquipo.substr(0, 7) == "CABO 01" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 1" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 2" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 3" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 4"
        ) {
          if (!Object.prototype.hasOwnProperty.call(mano_obra1, fila.activoEquipo)) {
            mano_obra1[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          mano_obra1[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          mano_obra1[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }

        if (
          fila.activoEquipo.substr(0, 7) == "CABO 02" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 5" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 6" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 7" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 8"
        ) {
          if (!Object.prototype.hasOwnProperty.call(mano_obra2, fila.activoEquipo)) {
            mano_obra2[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          mano_obra2[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          mano_obra2[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (
          fila.activoEquipo.substr(0, 7) == "CABO 03" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 9" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 10" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 11" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 12"
        ) {
          if (!Object.prototype.hasOwnProperty.call(mano_obra3, fila.activoEquipo)) {
            mano_obra3[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          mano_obra3[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          mano_obra3[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (
          fila.activoEquipo.substr(0, 7) == "CABO 04" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 13" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 14" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 15" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 16"
        ) {
          if (!Object.prototype.hasOwnProperty.call(mano_obra4, fila.activoEquipo)) {
            mano_obra4[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          mano_obra4[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          mano_obra4[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (
          fila.activoEquipo.substr(0, 7) == "CABO 05" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 17" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 18" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 19" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 20"
        ) {
          if (!Object.prototype.hasOwnProperty.call(mano_obra5, fila.activoEquipo)) {
            mano_obra5[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          mano_obra5[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          mano_obra5[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (
          fila.activoEquipo.substr(0, 7) == "CABO 06" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 21" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 22" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 23" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 24"
        ) {
          if (!Object.prototype.hasOwnProperty.call(mano_obra6, fila.activoEquipo)) {
            mano_obra6[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          mano_obra6[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          mano_obra6[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (
          fila.activoEquipo.substr(0, 7) == "CABO 07" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 25" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 26" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 27" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 28" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 29" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 30"
        ) {
          if (!Object.prototype.hasOwnProperty.call(mano_obra7, fila.activoEquipo)) {
            mano_obra7[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          mano_obra7[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          mano_obra7[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (
          fila.activoEquipo.substr(0, 7) == "CABO 08" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 31" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 32" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 33" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 34" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 35" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 36"
        ) {
          if (!Object.prototype.hasOwnProperty.call(mano_obra8, fila.activoEquipo)) {
            mano_obra8[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          mano_obra8[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          mano_obra8[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (
          fila.activoEquipo.substr(0, 7) == "CABO 09" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 37" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 38" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 39" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 40" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 41" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 42"
        ) {
          if (!Object.prototype.hasOwnProperty.call(mano_obra9, fila.activoEquipo)) {
            mano_obra9[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          mano_obra9[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          mano_obra9[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (
          fila.activoEquipo.substr(0, 7) == "CABO 10" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 43" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 44" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 45" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 46"
        ) {
          if (!Object.prototype.hasOwnProperty.call(mano_obra10, fila.activoEquipo)) {
            mano_obra10[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          mano_obra10[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          mano_obra10[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
        if (
          fila.activoEquipo.substr(0, 7) == "CABO 11" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 47" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 48" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 49" ||
          fila.activoEquipo.substr(0, 14) == "MANIOBRISTA 50"
        ) {
          if (!Object.prototype.hasOwnProperty.call(mano_obra11, fila.activoEquipo)) {
            mano_obra11[fila.activoEquipo] = {
              equipo: [],
              montoTotal: 0,
            };
          }
          mano_obra11[fila.activoEquipo].equipo.push({
            datosEquipo: fila.activoEquipo,
            monto: fila.montoEstimado,
          });

          mano_obra11[fila.activoEquipo].montoTotal += fila.montoEstimado;
        }
      });

      this.equiposGeneralGrua = Object.entries(grua_total);
      this.equiposGeneralCargadorFrontal = Object.entries(cargador_frontal_total);
      this.equiposGeneralMontacargargas = Object.entries(montacarga_total);
      this.equiposGeneralTracto = Object.entries(tractoc);
      this.generalPersonalTecnico = Object.entries(personalTecnico);
      this.generalCamionGrua = Object.entries(camion_grua);
      this.generalManoObra1 = Object.entries(mano_obra1);
      this.generalManoObra2 = Object.entries(mano_obra2);
      this.generalManoObra3 = Object.entries(mano_obra3);
      this.generalManoObra4 = Object.entries(mano_obra4);
      this.generalManoObra5 = Object.entries(mano_obra5);
      this.generalManoObra6 = Object.entries(mano_obra6);
      this.generalManoObra7 = Object.entries(mano_obra7);
      this.generalManoObra8 = Object.entries(mano_obra8);
      this.generalManoObra9 = Object.entries(mano_obra9);
      this.generalManoObra10 = Object.entries(mano_obra10);
      this.generalManoObra11 = Object.entries(mano_obra11);

      this.equiposGeneralGrua = this.equiposGeneralGrua.sort(
        (a, b) => b[1].montoTotal - a[1].montoTotal
      );
      this.equiposGeneralGrua.forEach((e) => {
        this.equiposGruaTotales.push({
          text:
            e[0] + " --- $" + this.costoToDollar(parseFloat(e[1].montoTotal).toFixed(2)),
          value: e[0],
          color: "white",
          total: parseFloat(e[1].montoTotal).toFixed(2),
        });
      });

      this.equiposGeneralCargadorFrontal = this.equiposGeneralCargadorFrontal.sort(
        (a, b) => b[1].montoTotal - a[1].montoTotal
      );
      this.equiposGeneralCargadorFrontal.forEach((e) => {
        this.equiposCGTotales.push({
          text:
            e[0] + " --- $" + this.costoToDollar(parseFloat(e[1].montoTotal).toFixed(2)),
          value: e[0],
          color: "white",
          total: parseFloat(e[1].montoTotal).toFixed(2),
        });
      });
      this.equiposGeneralMontacargargas = this.equiposGeneralMontacargargas.sort(
        (a, b) => b[1].montoTotal - a[1].montoTotal
      );
      this.equiposGeneralMontacargargas.forEach((e) => {
        this.equiposMontacargasTotales.push({
          text:
            e[0] + " --- $" + this.costoToDollar(parseFloat(e[1].montoTotal).toFixed(2)),
          value: e[0],
          color: "white",
          total: parseFloat(e[1].montoTotal).toFixed(2),
        });
      });
      this.equiposGeneralTracto = this.equiposGeneralTracto.sort(
        (a, b) => b[1].montoTotal - a[1].montoTotal
      );
      this.equiposGeneralTracto.forEach((e) => {
        this.equiposTractocamionTotales.push({
          text:
            e[0] + " --- $" + this.costoToDollar(parseFloat(e[1].montoTotal).toFixed(2)),
          value: e[0],
          color: "white",
          total: parseFloat(e[1].montoTotal).toFixed(2),
        });
      });
      this.generalCamionGrua = this.generalCamionGrua.sort(
        (a, b) => b[1].montoTotal - a[1].montoTotal
      );
      this.generalCamionGrua.forEach((e) => {
        this.equiposCamionTotales.push({
          text:
            e[0] + " --- $" + this.costoToDollar(parseFloat(e[1].montoTotal).toFixed(2)),
          value: e[0],
          color: "white",
          total: parseFloat(e[1].montoTotal).toFixed(2),
        });
      });

      let totalP = 0;
      this.generalPersonalTecnico.forEach((e) => {
        totalP += parseFloat(e[1].montoTotal);
      });
      this.generalPersonalTecnico.total = totalP;

      let totalG = 0;
      this.equiposGeneralGrua.forEach((e) => {
        totalG += parseFloat(e[1].montoTotal);
      });
      this.equiposGeneralGrua.total = totalG;

      let totalC = 0;
      this.equiposGeneralCargadorFrontal.forEach((e) => {
        totalC += parseFloat(e[1].montoTotal);
      });
      this.equiposGeneralCargadorFrontal.total = totalC;
      let totalM = 0;
      this.equiposGeneralMontacargargas.forEach((e) => {
        totalM += parseFloat(e[1].montoTotal);
      });
      this.equiposGeneralMontacargargas.total = totalM;
      let totalT = 0;
      this.equiposGeneralTracto.forEach((e) => {
        totalT += parseFloat(e[1].montoTotal);
      });
      this.equiposGeneralTracto.total = totalT;
      let totalM1 = 0;
      this.generalManoObra1.forEach((e) => {
        totalM1 += parseFloat(e[1].montoTotal);
      });
      this.generalManoObra1.total = totalM1;

      let totalM2 = 0;
      this.generalManoObra2.forEach((e) => {
        totalM2 += parseFloat(e[1].montoTotal);
      });
      this.generalManoObra2.total = totalM2;

      let totalM3 = 0;
      this.generalManoObra3.forEach((e) => {
        totalM3 += parseFloat(e[1].montoTotal);
      });
      this.generalManoObra3.total = totalM3;

      let totalM4 = 0;
      this.generalManoObra4.forEach((e) => {
        totalM4 += parseFloat(e[1].montoTotal);
      });
      this.generalManoObra4.total = totalM4;

      let totalM5 = 0;
      this.generalManoObra5.forEach((e) => {
        totalM5 += parseFloat(e[1].montoTotal);
      });
      this.generalManoObra5.total = totalM5;

      let totalM6 = 0;
      this.generalManoObra6.forEach((e) => {
        totalM6 += parseFloat(e[1].montoTotal);
      });
      this.generalManoObra6.total = totalM6;

      let totalM7 = 0;
      this.generalManoObra7.forEach((e) => {
        totalM7 += parseFloat(e[1].montoTotal);
      });
      this.generalManoObra7.total = totalM7;

      let totalM8 = 0;
      this.generalManoObra8.forEach((e) => {
        totalM8 += parseFloat(e[1].montoTotal);
      });
      this.generalManoObra8.total = totalM8;

      let totalM9 = 0;
      this.generalManoObra9.forEach((e) => {
        totalM9 += parseFloat(e[1].montoTotal);
      });
      this.generalManoObra9.total = totalM9;

      let totalM10 = 0;
      this.generalManoObra10.forEach((e) => {
        totalM10 += parseFloat(e[1].montoTotal);
      });
      this.generalManoObra10.total = totalM10;

      let totalM11 = 0;
      this.generalManoObra11.forEach((e) => {
        totalM11 += parseFloat(e[1].montoTotal);
      });
      this.generalManoObra11.total = totalM11;

      let totalM12 = 0;
      this.generalCamionGrua.forEach((e) => {
        totalM12 += parseFloat(e[1].montoTotal);
      });
      this.generalCamionGrua.total = totalM12;
    },
    prorrateoCalculo() {
      let activos = [];
      this.botonCalculoEquipos = true;

      //console.log(this.trazabilidadRegistros);
      //console.log(this.trazabilidadSinRegistrosTeb);

      this.activosTotalGeneral[0].aems.forEach((e) => {
        activos.push(e);
      });
      this.activosTotalGeneral[1].apapch.forEach((e) => {
        activos.push(e);
      });
      this.activosTotalGeneral[2].apc.forEach((e) => {
        activos.push(e);
      });
      this.activosTotalGeneral[3].apkmz.forEach((e) => {
        activos.push(e);
      });
      this.activosTotalGeneral[4].aplt.forEach((e) => {
        activos.push(e);
      });
      this.activosTotalGeneral[5].spee.forEach((e) => {
        activos.push(e);
      });
      this.activosTotalGeneral[6].eb.forEach((e) => {
        activos.push(e);
      });
      this.activosTotalGeneral[8].aems1.forEach((e) => {
        activos.push(e);
      });
      this.activosTotalGeneral[9].aems3.forEach((e) => {
        activos.push(e);
      });
      //Segmento de codigo para eliminar los registros que ya cuentan con un activo
      //Funcion para prorratear las cuadrillas de cabos 02
      let arrayManoObra2 = [];
      activos.forEach((e) => {
        if (e.montoDesglosado >= this.generalManoObra2.total) {
          arrayManoObra2.push(e);
        }
      });

      let mano_obra_ordenado2 = arrayManoObra2.sort(
        (a, b) => a.montoDesglosado - b.montoDesglosado
      );

      let activo2 = mano_obra_ordenado2[0].activo;
      let epep2 = mano_obra_ordenado2[0].epep;
      let pp2 = mano_obra_ordenado2[0].pp;

      for (let i = 0; i < this.trazabilidadSinRegistrosTeb.length; i++) {
        if (
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 5" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 6" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 7" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 8" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "CABO 02"
        ) {
          this.trazabilidadSinRegistrosTeb[i].activoPemex = activo2;
          this.trazabilidadSinRegistrosTeb[i].epep = epep2;
          this.trazabilidadSinRegistrosTeb[i].programaPresupuestal = pp2;

          this.trazabilidadSinRegistrosTeb.splice(i--, 1);
        }
      }

      this.totalProrratear = this.totalProrratear - this.generalManoObra2.total;
      this.activosTotalGeneral.forEach((e) => {
        if (e.activo == activo2) {
          e.monto = e.monto - this.generalManoObra2.total;
          e[activo2.toLowerCase()].forEach((f) => {
            if (f.epep == epep2) {
              f.montoDesglosado = f.montoDesglosado - this.generalManoObra2.total;
            }
          });
        }
      });
      this.equiposDesglosadosTable[4].total =
        this.equiposDesglosadosTable[4].total - this.generalManoObra2.total;
      //Funcion para prorratear las cuadrillas de cabos 03
      let arrayManoObra3 = [];
      activos.forEach((e) => {
        if (e.montoDesglosado >= this.generalManoObra3.total) {
          arrayManoObra3.push(e);
        }
      });

      let mano_obra_ordenado3 = arrayManoObra3.sort(
        (a, b) => a.montoDesglosado - b.montoDesglosado
      );

      let activo3 = mano_obra_ordenado3[0].activo;
      let epep3 = mano_obra_ordenado3[0].epep;
      let pp3 = mano_obra_ordenado3[0].pp;
      for (let i = 0; i < this.trazabilidadSinRegistrosTeb.length; i++) {
        if (
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 9" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 10" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 11" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 12" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "CABO 03"
        ) {
          this.trazabilidadSinRegistrosTeb[i].activoPemex = activo3;
          this.trazabilidadSinRegistrosTeb[i].epep = epep3;
          this.trazabilidadSinRegistrosTeb[i].programaPresupuestal = pp3;

          this.trazabilidadSinRegistrosTeb.splice(i--, 1);
        }
      }

      this.totalProrratear = this.totalProrratear - this.generalManoObra3.total;
      this.activosTotalGeneral.forEach((e) => {
        if (e.activo == activo3) {
          e.monto = e.monto - this.generalManoObra3.total;
          e[activo3.toLowerCase()].forEach((f) => {
            if (f.epep == epep3) {
              f.montoDesglosado = f.montoDesglosado - this.generalManoObra3.total;
            }
          });
        }
      });
      this.equiposDesglosadosTable[4].total =
        this.equiposDesglosadosTable[4].total - this.generalManoObra3.total;
      //Funcion para prorratear las cuadrillas de cabos 05
      let arrayManoObra5 = [];
      activos.forEach((e) => {
        if (e.montoDesglosado >= this.generalManoObra5.total) {
          arrayManoObra5.push(e);
        }
      });

      let mano_obra_ordenado5 = arrayManoObra5.sort(
        (a, b) => a.montoDesglosado - b.montoDesglosado
      );

      let activo5 = mano_obra_ordenado5[0].activo;
      let epep5 = mano_obra_ordenado5[0].epep;
      let pp5 = mano_obra_ordenado5[0].pp;
      for (let i = 0; i < this.trazabilidadSinRegistrosTeb.length; i++) {
        if (
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 17" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 18" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 19" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 20" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "CABO 05"
        ) {
          this.trazabilidadSinRegistrosTeb[i].activoPemex = activo5;
          this.trazabilidadSinRegistrosTeb[i].epep = epep5;
          this.trazabilidadSinRegistrosTeb[i].programaPresupuestal = pp5;

          this.trazabilidadSinRegistrosTeb.splice(i--, 1);
        }
      }

      this.totalProrratear = this.totalProrratear - this.generalManoObra5.total;
      this.activosTotalGeneral.forEach((e) => {
        if (e.activo == activo5) {
          e.monto = e.monto - this.generalManoObra5.total;
          e[activo5.toLowerCase()].forEach((f) => {
            if (f.epep == epep5) {
              f.montoDesglosado = f.montoDesglosado - this.generalManoObra5.total;
            }
          });
        }
      });
      this.equiposDesglosadosTable[4].total =
        this.equiposDesglosadosTable[4].total - this.generalManoObra5.total;

      //Funcion para prorratear las cuadrillas de cabos 07
      let arrayManoObra7 = [];
      activos.forEach((e) => {
        if (e.montoDesglosado >= this.generalManoObra7.total) {
          arrayManoObra7.push(e);
        }
      });

      let mano_obra_ordenado7 = arrayManoObra7.sort(
        (a, b) => a.montoDesglosado - b.montoDesglosado
      );

      let activo7 = mano_obra_ordenado7[0].activo;
      let epep7 = mano_obra_ordenado7[0].epep;
      let pp7 = mano_obra_ordenado7[0].pp;
      for (let i = 0; i < this.trazabilidadSinRegistrosTeb.length; i++) {
        if (
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 25" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 26" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 27" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 28" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 29" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 30" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "CABO 07"
        ) {
          this.trazabilidadSinRegistrosTeb[i].activoPemex = activo7;
          this.trazabilidadSinRegistrosTeb[i].epep = epep7;
          this.trazabilidadSinRegistrosTeb[i].programaPresupuestal = pp7;

          this.trazabilidadSinRegistrosTeb.splice(i--, 1);
        }
      }

      this.totalProrratear = this.totalProrratear - this.generalManoObra7.total;
      this.activosTotalGeneral.forEach((e) => {
        if (e.activo == activo7) {
          e.monto = e.monto - this.generalManoObra7.total;
          e[activo7.toLowerCase()].forEach((f) => {
            if (f.epep == epep7) {
              f.montoDesglosado = f.montoDesglosado - this.generalManoObra7.total;
            }
          });
        }
      });
      this.equiposDesglosadosTable[4].total =
        this.equiposDesglosadosTable[4].total - this.generalManoObra7.total;

      //Funcion para prorratear las cuadrillas de cabos 09
      let arrayManoObra9 = [];
      activos.forEach((e) => {
        if (e.montoDesglosado >= this.generalManoObra9.total) {
          arrayManoObra9.push(e);
        }
      });

      let mano_obra_ordenado9 = arrayManoObra9.sort(
        (a, b) => a.montoDesglosado - b.montoDesglosado
      );

      let activo9 = mano_obra_ordenado9[0].activo;
      let epep9 = mano_obra_ordenado9[0].epep;
      let pp9 = mano_obra_ordenado9[0].pp;
      for (let i = 0; i < this.trazabilidadSinRegistrosTeb.length; i++) {
        if (
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 37" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 38" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 39" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 40" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 41" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 42" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "CABO 09"
        ) {
          this.trazabilidadSinRegistrosTeb[i].activoPemex = activo9;
          this.trazabilidadSinRegistrosTeb[i].epep = epep9;
          this.trazabilidadSinRegistrosTeb[i].programaPresupuestal = pp9;

          this.trazabilidadSinRegistrosTeb.splice(i--, 1);
        }
      }

      this.totalProrratear = this.totalProrratear - this.generalManoObra9.total;
      this.activosTotalGeneral.forEach((e) => {
        if (e.activo == activo9) {
          e.monto = e.monto - this.generalManoObra9.total;
          e[activo9.toLowerCase()].forEach((f) => {
            if (f.epep == epep9) {
              f.montoDesglosado = f.montoDesglosado - this.generalManoObra9.total;
            }
          });
        }
      });
      this.equiposDesglosadosTable[4].total =
        this.equiposDesglosadosTable[4].total - this.generalManoObra9.total;

      //Funcion para prorratear las cuadrillas de cabos 08
      let arrayManoObra8 = [];
      activos.forEach((e) => {
        if (e.montoDesglosado >= this.generalManoObra8.total) {
          arrayManoObra8.push(e);
        }
      });

      let mano_obra_ordenado8 = arrayManoObra8.sort(
        (a, b) => a.montoDesglosado - b.montoDesglosado
      );

      let activo8 = mano_obra_ordenado8[0].activo;
      let epep8 = mano_obra_ordenado8[0].epep;
      let pp8 = mano_obra_ordenado8[0].pp;
      for (let i = 0; i < this.trazabilidadSinRegistrosTeb.length; i++) {
        if (
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 31" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 32" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 33" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 34" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 35" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 36" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "CABO 08"
        ) {
          this.trazabilidadSinRegistrosTeb[i].activoPemex = activo8;
          this.trazabilidadSinRegistrosTeb[i].epep = epep8;
          this.trazabilidadSinRegistrosTeb[i].programaPresupuestal = pp8;

          this.trazabilidadSinRegistrosTeb.splice(i--, 1);
        }
      }

      this.totalProrratear = this.totalProrratear - this.generalManoObra8.total;
      this.activosTotalGeneral.forEach((e) => {
        if (e.activo == activo8) {
          e.monto = e.monto - this.generalManoObra8.total;
          e[activo8.toLowerCase()].forEach((f) => {
            if (f.epep == epep8) {
              f.montoDesglosado = f.montoDesglosado - this.generalManoObra8.total;
            }
          });
        }
      });
      this.equiposDesglosadosTable[4].total =
        this.equiposDesglosadosTable[4].total - this.generalManoObra8.total;

      //Funcion para prorratear las cuadrillas de cabos 10
      let arrayManoObra10 = [];
      activos.forEach((e) => {
        if (e.montoDesglosado >= this.generalManoObra10.total) {
          arrayManoObra10.push(e);
        }
      });
      let mano_obra_ordenado10 = arrayManoObra10.sort(
        (a, b) => a.montoDesglosado - b.montoDesglosado
      );
      let activo10 = mano_obra_ordenado10[0].activo;
      let epep10 = mano_obra_ordenado10[0].epep;
      let pp10 = mano_obra_ordenado10[0].pp;

      for (let i = 0; i < this.trazabilidadSinRegistrosTeb.length; i++) {
        if (
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 43" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 44" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 45" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "CABO 10" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 46"
        ) {
          this.trazabilidadSinRegistrosTeb[i].activoPemex = activo10;
          this.trazabilidadSinRegistrosTeb[i].epep = epep10;
          this.trazabilidadSinRegistrosTeb[i].programaPresupuestal = pp10;
          this.trazabilidadSinRegistrosTeb.splice(i--, 1);
        }
      }
      this.totalProrratear = this.totalProrratear - this.generalManoObra10.total;
      this.activosTotalGeneral.forEach((e) => {
        if (e.activo == activo10) {
          e.monto = e.monto - this.generalManoObra10.total;
          e[activo10.toLowerCase()].forEach((f) => {
            if (f.epep == epep10) {
              f.montoDesglosado = f.montoDesglosado - this.generalManoObra10.total;
            }
          });
        }
      });
      this.equiposDesglosadosTable[4].total =
        this.equiposDesglosadosTable[4].total - this.generalManoObra10.total;

      //Funcion para prorratear las cuadrillas de cabos 11
      let arrayManoObra11 = [];
      activos.forEach((e) => {
        if (e.montoDesglosado >= this.generalManoObra11.total) {
          arrayManoObra11.push(e);
        }
      });

      let mano_obra_ordenado11 = arrayManoObra11.sort(
        (a, b) => a.montoDesglosado - b.montoDesglosado
      );

      let activo11 = mano_obra_ordenado11[0].activo;
      let epep11 = mano_obra_ordenado11[0].epep;
      let pp11 = mano_obra_ordenado11[0].pp;

      for (let i = 0; i < this.trazabilidadSinRegistrosTeb.length; i++) {
        if (
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 47" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 48" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 49" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "CABO 11" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "MANIOBRISTA 50" ||
          this.trazabilidadSinRegistrosTeb[i].activoEquipo == "CABO 11 "
        ) {
          this.trazabilidadSinRegistrosTeb[i].activoPemex = activo11;
          this.trazabilidadSinRegistrosTeb[i].epep = epep11;
          this.trazabilidadSinRegistrosTeb[i].programaPresupuestal = pp11;

          this.trazabilidadSinRegistrosTeb.splice(i--, 1);
        }
      }

      this.totalProrratear = this.totalProrratear - this.generalManoObra11.total;
      this.activosTotalGeneral.forEach((e) => {
        if (e.activo == activo11) {
          e.monto = e.monto - this.generalManoObra11.total;
          e[activo11.toLowerCase()].forEach((f) => {
            if (f.epep == epep11) {
              f.montoDesglosado = f.montoDesglosado - this.generalManoObra11.total;
            }
          });
        }
      });
      this.equiposDesglosadosTable[4].total =
        this.equiposDesglosadosTable[4].total - this.generalManoObra11.total;

      //PERSONAL
      let arrayPersonal = [];
      this.generalPersonalTecnico.forEach((e) => {
        activos.forEach((f) => {
          if (f.montoDesglosado >= e[1].montoTotal) {
            arrayPersonal.push({
              equipo: e[0],
              monto: e[1].montoTotal,
              montoActivo: f.montoDesglosado,
              activo: f.activo,
              epep: f.epep,
              pp: f.pp,
            });
          }
        });
      });
      let arregloFiltrado = [];
      arrayPersonal.forEach((fila) => {
        if (!Object.prototype.hasOwnProperty.call(arregloFiltrado, fila.equipo)) {
          arregloFiltrado[fila.equipo] = [];
        }
        arregloFiltrado[fila.equipo].push({
          datosEquipo: fila,
        });
      });
      let arregloConvertido = Object.entries(arregloFiltrado);
      let preciosAgrupados = [];
      let totalP = [];
      arregloConvertido.forEach((e) => {
        preciosAgrupados = e[1].sort(
          (a, b) => a.datosEquipo.montoActivo - b.datosEquipo.montoActivo
        );
        totalP.push(preciosAgrupados);
      });

      let personalProrrateo = [];
      for (let i = 0; i < this.generalPersonalTecnico.length; i++) {
        personalProrrateo.push(totalP[i][i]);
      }
      // this.trazabilidadSinRegistrosTeb.forEach((e)=>{
      //   personalProrrateo.forEach((f)=>{
      //     if(e.activoEquipo==f.datosEquipo.equipo){
      //       e.activoPemex = f.datosEquipo.activo
      //       e.epep = f.datosEquipo.epep
      //       e.programaPresupuestal = f.datosEquipo.pp
      //      // this.eliminarRegistroTrazabilidadSinTeb(e)
      //     }
      //   })
      // })
      for (let i = 0; i < this.trazabilidadSinRegistrosTeb.length; i++) {
        for (let j = 0; j < personalProrrateo.length; j++) {
          if (
            this.trazabilidadSinRegistrosTeb[i].activoEquipo ==
            personalProrrateo[j].datosEquipo.equipo
          ) {
            this.trazabilidadSinRegistrosTeb[i].activoPemex =
              personalProrrateo[j].datosEquipo.activo;
            this.trazabilidadSinRegistrosTeb[i].epep =
              personalProrrateo[j].datosEquipo.epep;
            this.trazabilidadSinRegistrosTeb[i].programaPresupuestal =
              personalProrrateo[j].datosEquipo.programaPresupuestal;
            this.trazabilidadSinRegistrosTeb.splice(i--, 1);
          }
        }
      }

      this.totalProrratear = this.totalProrratear - this.generalPersonalTecnico.total;
      this.activosTotalGeneral.forEach((e) => {
        personalProrrateo.forEach((f) => {
          if (e.activo == f.datosEquipo.activo) {
            e.monto = e.monto - f.datosEquipo.monto;
            e[f.datosEquipo.activo.toLowerCase()].forEach((g) => {
              if (f.datosEquipo.epep == g.epep) {
                g.montoDesglosado = g.montoDesglosado - f.datosEquipo.monto;
              }
            });
          }
        });
      });

      let result =
        this.equiposDesglosadosTable[5].total.toFixed(2) -
        this.generalPersonalTecnico.total.toFixed(2);

      this.equiposDesglosadosTable[5].total = result;

      //GRUAS

      let activosOrdenados = activos.sort(
        (a, b) => b.montoDesglosado - a.montoDesglosado
      );
      this.trazabilidadSinRegistrosTeb = this.trazabilidadSinRegistrosTeb.sort((a, b) =>
        a.activoEquipo.localeCompare(b.activoEquipo)
      );

      let trazabilidad = 0;
      let activoT = 0;

      let costeoDetalle = [];
      // let indexCosteo = 0;

      activosOrdenados.forEach((e) => {
        this.trazabilidadSinRegistrosTeb.forEach((f) => {
          if (
            f.epep.length == 0 &&
            f.montoEstimado <= e.montoDesglosado &&
            f.activoEquipo.substr(0, 2) == "G-"
          ) {
            let monto = e.montoDesglosado;
            trazabilidad = trazabilidad + f.montoEstimado;
            activoT = activoT + e.montoDesglosado;
            f.numeroDeSolicitud = "X";
            f.epep = e.epep;
            f.activoPemex = e.activo;
            f.programaPresupuestal = e.pp;
            e.montoDesglosado = e.montoDesglosado - f.montoEstimado;

            costeoDetalle.push({
              epep: f.epep,
              pp: f.programaPresupuestal,
              equipo: f.activoEquipo,
              monto: parseFloat(f.montoEstimado),
              montoDesglosado: parseFloat(monto),
              activo: e.activo,
              montoActivo: parseFloat(e.montoDesglosado),
            });
            //this.eliminarRegistroTrazabilidadSinTeb(f);
          } else {
            return;
          }
        });

        // indexCosteo++;
      });

      this.totalProrratear = this.totalProrratear - this.equiposGeneralGrua.total;
      let resultGrua =
        this.equiposDesglosadosTable[0].total.toFixed(2) -
        this.equiposGeneralGrua.total.toFixed(2);
      this.equiposDesglosadosTable[0].total = resultGrua;

      activosOrdenados = activos.sort((a, b) => b.montoDesglosado - a.montoDesglosado);
      this.trazabilidadSinRegistrosTeb = this.trazabilidadSinRegistrosTeb.sort((a, b) =>
        a.activoEquipo.localeCompare(b.activoEquipo)
      );

      activosOrdenados.forEach((e) => {
        this.trazabilidadSinRegistrosTeb.forEach((f) => {
          if (
            f.epep.length == 0 &&
            f.montoEstimado <= e.montoDesglosado &&
            (f.activoEquipo.substr(0, 3) == "CFG"  || f.activoEquipo.substr(0, 3) == "CGF")
          ) {
            let monto = e.montoDesglosado;
            trazabilidad = trazabilidad + f.montoEstimado;
            activoT = activoT + e.montoDesglosado;
            f.numeroDeSolicitud = "X";
            f.epep = e.epep;
            f.activoPemex = e.activo;
            f.programaPresupuestal = e.pp;
            e.montoDesglosado = e.montoDesglosado - f.montoEstimado;

            costeoDetalle.push({
              epep: f.epep,
              pp: f.programaPresupuestal,
              equipo: f.activoEquipo,
              monto: parseFloat(f.montoEstimado),
              montoDesglosado: parseFloat(monto),
              activo: e.activo,
              montoActivo: parseFloat(e.montoDesglosado),
            });
            //this.eliminarRegistroTrazabilidadSinTeb(f);
          } else {
            return;
          }
        });
        // indexCosteo++;
      });

      this.totalProrratear =
        this.totalProrratear - this.equiposGeneralCargadorFrontal.total;
      let resultCargador =
        this.equiposDesglosadosTable[1].total.toFixed(2) -
        this.equiposGeneralCargadorFrontal.total.toFixed(2);
      this.equiposDesglosadosTable[1].total = resultCargador;

      activosOrdenados = activos.sort((a, b) => b.montoDesglosado - a.montoDesglosado);
      this.trazabilidadSinRegistrosTeb = this.trazabilidadSinRegistrosTeb.sort((a, b) =>
        a.activoEquipo.localeCompare(b.activoEquipo)
      );

      activosOrdenados.forEach((e) => {
        this.trazabilidadSinRegistrosTeb.forEach((f) => {
          if (
            f.epep.length == 0 &&
            f.montoEstimado <= e.montoDesglosado &&
            f.activoEquipo.substr(0, 2) == "M-"
          ) {
            let monto = e.montoDesglosado;
            trazabilidad = trazabilidad + f.montoEstimado;
            activoT = activoT + e.montoDesglosado;
            f.numeroDeSolicitud = "X";
            f.epep = e.epep;
            f.activoPemex = e.activo;
            f.programaPresupuestal = e.pp;
            e.montoDesglosado = e.montoDesglosado - f.montoEstimado;
            costeoDetalle.push({
              epep: f.epep,
              pp: f.programaPresupuestal,
              equipo: f.activoEquipo,
              monto: parseFloat(f.montoEstimado),
              montoDesglosado: parseFloat(monto),
              activo: e.activo,
              montoActivo: parseFloat(e.montoDesglosado),
            });
            //this.eliminarRegistroTrazabilidadSinTeb(f);
          } else {
            return;
          }
        });

        // indexCosteo++;
      });

      this.totalProrratear = this.totalProrratear - this.generalCamionGrua.total;
      let resultCamion =
        this.equiposDesglosadosTable[6].total.toFixed(2) -
        this.generalCamionGrua.total.toFixed(2);
      this.equiposDesglosadosTable[6].total = resultCamion;

      activosOrdenados = activos.sort((a, b) => b.montoDesglosado - a.montoDesglosado);
      this.trazabilidadSinRegistrosTeb = this.trazabilidadSinRegistrosTeb.sort((a, b) =>
        a.activoEquipo.localeCompare(b.activoEquipo)
      );

      activosOrdenados.forEach((e) => {
        this.trazabilidadSinRegistrosTeb.forEach((f) => {
          if (
            f.epep.length == 0 &&
            f.montoEstimado <= e.montoDesglosado &&
            f.activoEquipo.substr(0, 4) == "MTC-"
          ) {
            let monto = e.montoDesglosado;
            trazabilidad = trazabilidad + f.montoEstimado;
            activoT = activoT + e.montoDesglosado;
            f.numeroDeSolicitud = "X";
            f.epep = e.epep;
            f.activoPemex = e.activo;
            f.programaPresupuestal = e.pp;
            e.montoDesglosado = e.montoDesglosado - f.montoEstimado;

            costeoDetalle.push({
              epep: f.epep,
              pp: f.programaPresupuestal,
              equipo: f.activoEquipo,
              monto: parseFloat(f.montoEstimado),
              montoDesglosado: parseFloat(monto),
              activo: e.activo,
              montoActivo: parseFloat(e.montoDesglosado),
            });
            //this.eliminarRegistroTrazabilidadSinTeb(f);
          } else {
            return;
          }
        });

        // indexCosteo++;
      });

      this.totalProrratear =
        this.totalProrratear - this.equiposGeneralMontacargargas.total;
      let resultMont =
        this.equiposDesglosadosTable[2].total.toFixed(2) -
        this.equiposGeneralMontacargargas.total.toFixed(2);
      this.equiposDesglosadosTable[2].total = resultMont;

      activosOrdenados = activos.sort((a, b) => b.montoDesglosado - a.montoDesglosado);
      this.trazabilidadSinRegistrosTeb = this.trazabilidadSinRegistrosTeb.sort((a, b) =>
        a.activoEquipo.localeCompare(b.activoEquipo)
      );
      activosOrdenados.forEach((e) => {
        this.trazabilidadSinRegistrosTeb.forEach((f) => {
          if (
            f.epep.length == 0 &&
            f.montoEstimado <= e.montoDesglosado &&
            f.activoEquipo.substr(0, 2) == "T-"
          ) {
            let monto = e.montoDesglosado;
            trazabilidad = trazabilidad + f.montoEstimado;
            activoT = activoT + e.montoDesglosado;
            f.numeroDeSolicitud = "X";
            f.epep = e.epep;
            f.activoPemex = e.activo;
            f.programaPresupuestal = e.pp;
            e.montoDesglosado = e.montoDesglosado - f.montoEstimado;
            costeoDetalle.push({
              epep: f.epep,
              pp: f.programaPresupuestal,
              equipo: f.activoEquipo,
              monto: parseFloat(f.montoEstimado),
              montoDesglosado: parseFloat(monto),
              activo: e.activo,
              montoActivo: parseFloat(e.montoDesglosado),
            });
            //this.eliminarRegistroTrazabilidadSinTeb(f);
          } else {
            return;
          }
        });

        // indexCosteo++;
      });
      this.totalProrratear = this.totalProrratear - this.equiposGeneralTracto.total;
      let resultTract =
        this.equiposDesglosadosTable[3].total.toFixed(2) -
        this.equiposGeneralTracto.total.toFixed(2);
      this.equiposDesglosadosTable[3].total = resultTract;

      /*
        //Cargador Frontal

        let activosOrdenadosCargador = activos.sort(
        (a, b) => b.montoDesglosado - a.montoDesglosado
      );
      let trazabilidadCarg = 0;
      let activoTCarg = 0;
      activosOrdenadosCargador.forEach((e) => {
       // console.log(e.montoDesglosado.toFixed(2));

        this.trazabilidadSinRegistrosTeb.forEach((f) => {
          if (
            f.epep.length == 0 &&
            f.montoEstimado <= e.montoDesglosado &&
            (f.activoEquipo.substr(0, 3) == "CGF" ||
              f.activoEquipo.substr(0, 8) == "CARGADOR")
          ) {
            trazabilidadCarg = trazabilidadCarg + f.montoEstimado;
            activoTCarg = activoTCarg + e.montoDesglosado;
            f.epep = e.epep;
            f.activoPemex = e.activo;
            f.programaPresupuestal = e.pp;
            e.montoDesglosado = e.montoDesglosado - f.montoEstimado;
         //   console.log( f.activoEquipo,f.epep, f.activoPemex,f.programaPresupuestal,e.montoDesglosado.toFixed(2), f.montoEstimado.toFixed(2));

            this.eliminarRegistroTrazabilidadSinTeb(f);
          } else {
            return;
          }
        });
      });
    


         //CAMION CON GRUA

         let activosOrdenadosCamionGrua = activos.sort(
        (a, b) => b.montoDesglosado - a.montoDesglosado
      );
      let trazabilidadCamionGrua = 0;
      let activoTCamionGrua = 0;
      activosOrdenadosCamionGrua.forEach((e) => {
       // console.log(e.montoDesglosado.toFixed(2));

        this.trazabilidadSinRegistrosTeb.forEach((f) => {
          if (
            f.epep.length == 0 &&
            f.montoEstimado <= e.montoDesglosado &&
            f.activoEquipo.substr(0, 6) == "CAMIÓN"
          ) {
              trazabilidadCamionGrua = trazabilidadCamionGrua + f.montoEstimado;
              activoTCamionGrua = activoTCamionGrua + e.montoDesglosado;
            f.epep = e.epep;
            f.activoPemex = e.activo;
            f.programaPresupuestal = e.pp;
            e.montoDesglosado = e.montoDesglosado - f.montoEstimado;
         //   console.log( f.activoEquipo,f.epep, f.activoPemex,f.programaPresupuestal,e.montoDesglosado.toFixed(2), f.montoEstimado.toFixed(2));

            this.eliminarRegistroTrazabilidadSinTeb(f);
          } else {
            return;
          }
        });
      });
     


      //MONTACARGAS
      let activosOrdenadosMont = activos.sort(
        (a, b) => b.montoDesglosado - a.montoDesglosado
      );

      let trazabilidadMont = 0;
      let activoTMont = 0;

      activosOrdenadosMont.forEach((e) => {
        this.trazabilidadSinRegistrosTeb.forEach((f) => {
          if (
            f.epep.length == 0 &&
            f.montoEstimado <= e.montoDesglosado &&
            f.activoEquipo.substr(0, 10) == "MONTACARGA"
          ) {
            trazabilidadMont = trazabilidadMont + f.montoEstimado;
            activoTMont = activoTMont + e.montoDesglosado;
            f.epep = e.epep;
            f.activoPemex = e.activo;
            f.programaPresupuestal = e.pp;
            e.montoDesglosado = e.montoDesglosado - f.montoEstimado;

            this.eliminarRegistroTrazabilidadSinTeb(f);
          } else {
            return;
          }
        });
      });
   

      


      //Tractocamion

      let activosOrdenadosTract = activos.sort(
        (a, b) => b.montoDesglosado - a.montoDesglosado
      );
      let trazabilidadTract = 0;
      let activoTTract = 0;

      activosOrdenadosTract.forEach((e) => {
        this.trazabilidadSinRegistrosTeb.forEach((f) => {
          if (
            f.epep.length == 0 &&
            f.montoEstimado <= e.montoDesglosado &&
            f.activoEquipo.substr(0, 6) == "TRACTO"
          ) {
            trazabilidadTract = trazabilidadTract + f.montoEstimado;
            activoTTract = activoTTract + e.montoDesglosado;
            f.epep = e.epep;
            f.activoPemex = e.activo;
            f.programaPresupuestal = e.pp;
            e.montoDesglosado = e.montoDesglosado - f.montoEstimado;
            this.eliminarRegistroTrazabilidadSinTeb(f);
          } else {
            return;
          }
        });
      });
    
      //Prorrateo Equipos Sobrantes
    */

      this.mostrarBotonProrrateoExcel = true;

      let activosPendienteParaProrratear = activos.sort(
        (a, b) => b.montoDesglosado - a.montoDesglosado
      );

      activosPendienteParaProrratear.forEach((e) => {
        this.trazabilidadSinRegistrosTeb.forEach((f) => {
          if (f.montoEstimado <= e.montoDesglosado && f.epep.length == 0) {
            //let monto = e.montoDesglosado;
            f.activoPemex = e.activo;
            f.epep = e.epep;
            f.programaPresupuestal = e.pp;
            e.montoDesglosado = e.montoDesglosado - f.montoEstimado;
          } else {
            return;
          }
        });
        //indexCosteo++;
      });
      let index = 0;

      this.trazabilidadSinRegistrosTeb.forEach((e) => {
        if (!e.epep) {
          let montoTotal = activosPendienteParaProrratear[index].montoDesglosado;
          e.numeroDeSolicitud = "X";
          e.activoPemex = activosPendienteParaProrratear[index].activo;
          e.epep = activosPendienteParaProrratear[index].epep;
          e.programaPresupuestal = activosPendienteParaProrratear[index].pp;
          activosPendienteParaProrratear[index].montoDesglosado =
            activosPendienteParaProrratear[index].montoDesglosado - e.montoEstimado;

          costeoDetalle.push({
            epep: activosPendienteParaProrratear[index].epep,
            montoDesglosado: montoTotal,
            montoActivo: activosPendienteParaProrratear[index].montoDesglosado,
            monto: parseFloat(e.montoEstimado),
            pp: activosPendienteParaProrratear[index].epep,
            equipo: e.activoEquipo,
          });

          index++;

          //  indexCosteo++;
        }
      });
      this.trazabilidadRegistros = [
        ...this.trazabilidadRegistros,
        ...this.trazabilidadFueraServicio,
      ];
      this.trazabilidadRegistros = this.trazabilidadRegistros.sort((a, b) =>
        a.activoEquipo.localeCompare(b.activoEquipo)
      );
      this.trazabilidadRegistros = this.trazabilidadRegistros.sort(
        (a, b) => a.partida - b.partida
      );

      // this.trazabilidadRegistros = this.trazabilidadRegistros.sort((a,b)=>b.fechaServicio - a.fechaServicio)
      this.prorrateoTables = costeoDetalle;
      let epepT = [];
      costeoDetalle.forEach((e) => {
        if (!Object.prototype.hasOwnProperty.call(epepT, e.epep)) {
          epepT[e.epep] = {
            epep: [],
            montoTotal: 0,
            total: e.montoDesglosado,
            equipo: [],
          };
        }

        epepT[e.epep].epep.push({
          datosEpep: e.epep,
          monto: e.monto,
          pp: e.pp,
          equipo: e.equipo,
          activo: e.activo,
        });

        epepT[e.epep].montoTotal += e.monto;

        if (!epepT[e.epep].equipo.includes(e.equipo)) {
          epepT[e.epep].equipo.push(e.equipo);
        }
      });

      epepT = Object.entries(epepT);
      epepT.forEach((e) => {
        e[2] = e[1].epep[0].activo;
        e[3] = e[1].epep[0].pp;
        e[4] = e[1].montoTotal;
        e[5] = e[1].total;
        e[6] = e[1].equipo.join(" - ");
      });

      let activosDesgloseProrrateo = [];
      activosDesgloseProrrateo.aems = [];
      activosDesgloseProrrateo.apapch = [];
      activosDesgloseProrrateo.apc = [];
      activosDesgloseProrrateo.apkmz = [];
      activosDesgloseProrrateo.aplt = [];
      activosDesgloseProrrateo.spee = [];
      activosDesgloseProrrateo.apk_ek_blm = [];
      activosDesgloseProrrateo.aems1 = [];

      let totalUsadoAems = 0;
      let totallProgramaAems = 0;
      let totalUsadoAems1 = 0;
      let totallProgramaAems1 = 0;
      epepT.forEach((e) => {
        if (e[2] === "AEMS") {
          totalUsadoAems += e[4];
          totallProgramaAems += e[5];
          activosDesgloseProrrateo.aems.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: e[4],
            monto_total: e[5],
            equipo: e[6],
            totalUsado: totalUsadoAems,
            totallProgramaAems: totallProgramaAems,
          });
        } else if (e[2] == "APAPCH") {
          activosDesgloseProrrateo.apapch.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: e[4],
            monto_total: e[5],
            equipo: e[6],
          });
        } else if (e[2] == "APC") {
          activosDesgloseProrrateo.apc.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: parseFloat(e[4]),
            monto_total: parseFloat(e[5]),
            equipo: e[6],
          });
        } else if (e[2] == "APKMZ") {
          activosDesgloseProrrateo.apkmz.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: parseFloat(e[4]),
            monto_total: parseFloat(e[5]),
            equipo: e[6],
          });
        } else if (e[2] == "APLT") {
          activosDesgloseProrrateo.aplt.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: parseFloat(e[4]),
            monto_total: parseFloat(e[5]),
            equipo: e[6],
          });
        } else if (e[2] == "SPEE") {
          activosDesgloseProrrateo.spee.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: parseFloat(e[4]),
            monto_total: parseFloat(e[5]),
            equipo: e[6],
          });
        } else if (e[2] == "APC-EK-BALAM") {
          activosDesgloseProrrateo.apk_ek_blm.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: parseFloat(e[4]),
            monto_total: parseFloat(e[5]),
            equipo: e[6],
          });
        } else if (e[2] === "AEMS1") {
          totalUsadoAems1 += e[4];
          totallProgramaAems1 += e[5];
          activosDesgloseProrrateo.aems1.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: parseFloat(e[4]),
            monto_total: parseFloat(e[5]),
            equipo: e[6],
            totalUsado: totalUsadoAems1,
            totallProgramaAems: totallProgramaAems1,
          });
        }
      });
      this.prorrateoTables = activosDesgloseProrrateo;
    },

    pruebaEnviarDatos() {
      this.loadingProrrateoExcel = true;
      let config = {
        responseType: "blob",
      };
      let fs = JSON.parse(localStorage.getItem("trazFs"));
      let datos = [...this.trazabilidadRegistros, ...fs];
      axios
        .put("/trazabilidad/pruebaDatos", { datos: datos }, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "prorrateo.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.loadingProrrateoExcel = false;
        });
    },

    mostrarActivosPorEquipo() {
      let obj = [];
      this.trazabilidadRegistros.forEach((e) => {
        if (e.prorrateado == "SI") {
          obj.push(e);
        }
      });
      let equiposSinRepetir = [];
      obj.forEach((p) => {
        if (
          equiposSinRepetir.findIndex(
            (pd) => pd.activoEquipo === p.activoEquipo && pd.activoPemex === p.activoPemex
          ) === -1
        ) {
          equiposSinRepetir.push(p);
        }
      });
      let vizualizarEquipos = [];
      vizualizarEquipos.push({ tab: "AEMS", equipos: [] });
      vizualizarEquipos.push({ tab: "APAPCH", equipos: [] });
      vizualizarEquipos.push({ tab: "APC", equipos: [] });
      vizualizarEquipos.push({ tab: "APKMZ", equipos: [] });
      vizualizarEquipos.push({ tab: "APLT", equipos: [] });
      vizualizarEquipos.push({ tab: "SPEE", equipos: [] });
      vizualizarEquipos.push({ tab: "APC-EK-BALAM", equipos: [] });
      vizualizarEquipos.push({ tab: "Bloque Norte", equipos: [] });
      vizualizarEquipos.push({ tab: "AEMS1", equipos: [] });
      vizualizarEquipos.push({ tab: "AEMS3", equipos: [] });
      equiposSinRepetir.forEach((e) => {
        if (e.activoPemex == "AEMS") {
          vizualizarEquipos[0].tab = "AEMS";
          vizualizarEquipos[0].equipos.push(e.activoEquipo);
        } else if (e.activoPemex == "APAPCH") {
          vizualizarEquipos[1].tab = "APAPCH";
          vizualizarEquipos[1].equipos.push(e.activoEquipo);
        } else if (e.activoPemex == "APC") {
          vizualizarEquipos[2].tab = "APC";
          vizualizarEquipos[2].equipos.push(e.activoEquipo);
        } else if (e.activoPemex == "APKMZ") {
          vizualizarEquipos[3].tab = "APKMZ";
          vizualizarEquipos[3].equipos.push(e.activoEquipo);
        } else if (e.activoPemex == "APLT") {
          vizualizarEquipos[4].tab = "APLT";
          vizualizarEquipos[4].equipos.push(e.activoEquipo);
        } else if (e.activoPemex == "SPEE") {
          vizualizarEquipos[5].tab = "SPEE";
          vizualizarEquipos[5].equipos.push(e.activoEquipo);
        } else if (e.activoPemex == "APC-EK-BALAM") {
          vizualizarEquipos[6].tab = "APC-EK-BALAM";
          vizualizarEquipos[6].equipos.push(e.activoEquipo);
        } else if (e.activoPemex == "Bloque Norte") {
          vizualizarEquipos[7].tab = "Bloque Norte";
          vizualizarEquipos[7].equipos.push(e.activoEquipo);
        } else if (e.activoPemex == "AEMS1") {
          vizualizarEquipos[8].tab = "AEMS1";
          vizualizarEquipos[8].equipos.push(e.activoEquipo);
        }else if (e.activoPemex == "AEMS3") {
          vizualizarEquipos[9].tab = "AEMS3";
          vizualizarEquipos[9].equipos.push(e.activoEquipo);
        }
      });

      this.activosProrrateadosEquipo = vizualizarEquipos;
    },

    async guardarProrrateoLocalStore() {
      this.dialogLoading = true;

      this.$store
        .dispatch("guardarTraz", this.trazabilidadRegistros)
        .then(() => {
          console.log("listo");
        })
        .finally(() => {
          this.dialogLoading = false;
        });
      this.$store
        .dispatch("guardarTeb", this.tebsSinUno)
        .then(() => {
          console.log("listo");
        })
        .finally(() => {
          this.dialogLoading = false;
        });
      this.$store.dispatch("guardarIdTraz", this.idTrazabilidad);
      this.$store.dispatch("guardarActivosGeneral", this.activosTotalGeneral);
    },

    guardarCosteo() {
   /*    let prueba = [];
      let costeoPartidas = [];
      let costeoPartidasArr = [] */
    /*   this.trazabilidadRegistros.forEach((e) => {
        if (costeoPartidas.findIndex((pd) => pd.partida == e.partida) === -1) {
          costeoPartidas.push(e);
        }
      });
      costeoPartidas.forEach((e) => {
        costeoPartidasArr.push(e.partida);
      });

      //Filtrando PP
      let costeoEpep = [];
      let costeoEpepArr = [];
      this.trazabilidadRegistros.forEach((e) => {
        if (costeoEpep.findIndex((pd) => pd.epep == e.epep) === -1) {
          costeoEpep.push(e);
        }
      });
      costeoEpep.forEach((e) => {
        costeoEpepArr.push(e.epep);
      });

      this.trazabilidadRegistros.forEach((e) => {
        costeoPartidasArr.forEach((f) => {
          costeoEpepArr.forEach((g) => {
            if (e.partida == f && e.epep == g) {
              if (!prueba.some((h) => h.epep == g && h.partida == f)) {
                prueba.push(e);
              }
            }
          });
        });
      });

      prueba.forEach((e) => {
        e.totalVolumen = 0;
        e.totalMontoEstimado = 0;
        this.trazabilidadRegistros.forEach((f) => {
          if (e.partida == f.partida && e.epep == f.epep) {
            e.totalMontoEstimado += parseFloat(f.montoEstimado);
            e.totalVolumen += parseFloat(f.volumen);
          }
        });
      }); */
      let fs = JSON.parse(localStorage.getItem("trazFs"));
      let datos = [...this.trazabilidadRegistros, ...fs];
      let me = this;
      let numberRandom = Math.random().toString().slice(2);
      let fileExtension = "xlsx";
      let nombreArchivo = `${this.url}${numberRandom}.${fileExtension}`;
      axios
        .post("/costeo/add", {
          mes: this.mesServicio,
          trazabilidad: datos,
          urlExcel: nombreArchivo,
        })
        .then((response) => {
          me.guardarExcel(response.data._id, numberRandom);
          me.addSuccessNotification("Costeo ingresado correctamente");
          me.mesServicio = "";
          datos = [];
        })
        .catch((e) => {
          console.log(e);
          me.addErrorNotification();
        })
        .finally(() => {
          me.dialogLoading = false;
          me.dialogGuardarProrrateo = false;
        });
    },

    guardarExcel(item, name) {
      axios({
        url: "/costeo/descargarProrrateo?_id=" + item, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const myFile = new File([response.data], "prorrateo.xlsx", {
          type: "application/xlsx",
        });
        this.subirArchivo(myFile, name);
      });
    },

    async subirArchivo(file, name) {
      this.S3Client.uploadFile(file, name).finally((e) => {
        console.log(e);
      });
    },

    test() {
      this.trazabilidadActual.forEach((e) => {
        if (e.programaPresupuestal.substring(0, 3) == "3K2") {
          e.activoPemex = "AEMS1";
        }
      });

      this.corregirTrazabilidad(
        this.idTrazabilidad,
        this.mesServicio,
        this.trazabilidadActual
      );
    },

    corregirTrazabilidad(id, mes, trazabilidad) {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };

      axios
        .put(
          "/trazabilidad/update",
          {
            _id: id,
            mes: mes,
            trazabilidad: trazabilidad,
          },
          configuracion
        )
        .then(function () {
          me.limpiar();
          // me.listar();
          me.addSuccessNotification("Trazabilidad actualizada");
          me.dialogTimer = false;
          //me.ocultarNuevo();
        })
        .catch(function (e) {
          me.dialogTimer = false;
          me.addErrorNotification2("Error, verifica la informacion agregada " + e);
        })
        .finally(() => {
          me.dialogTimer = false;
        });
    },

    eliminarRegistroTrazabilidadSinTeb(item) {
      let i = this.trazabilidadSinRegistrosTeb.indexOf(item);
      if (i != -1) {
        this.trazabilidadSinRegistrosTeb.splice(i, 1);
      }
    },
    prorrateoManual() {
      this.dialogParaProrratear = true;
      if (this.prorratearUnaVez == true) {
        this.trazabilidadSinRegistrosTeb = this.trazabilidadSinRegistrosTeb.sort((a, b) =>
          a.activoEquipo.localeCompare(b.activoEquipo)
        );
        this.trazabilidadSinRegistrosTeb = this.trazabilidadSinRegistrosTeb.sort(
          (a, b) => a.partida - b.partida
        );
        this.trazabilidadSinRegistrosTeb.forEach((e) => {
          if (e.activoEquipo.substring(0, 2) == "G-") {
            this.gruasProrratear.push(e);
          }
        });
        this.trazabilidadSinRegistrosTeb.forEach((e) => {
          if (e.activoEquipo.substring(0, 3) == "CFG" || e.activoEquipo.substring(0, 3) == "CGF") {
            this.cargadoresFrontalesProrratear.push(e);
          }
        });
        this.trazabilidadSinRegistrosTeb.forEach((e) => {
          if (e.activoEquipo.substring(0, 4) == "MTC-") {
            this.montacargasProrratear.push(e);
          }
        });
        this.trazabilidadSinRegistrosTeb.forEach((e) => {
          if (e.activoEquipo.substring(0, 2) == "T-") {
            this.tractocamionProrratear.push(e);
          }
        });
        this.trazabilidadSinRegistrosTeb.forEach((e) => {
          if (e.activoEquipo.substring(0, 2) == "M-") {
            this.camionProrratear.push(e);
          }
        });
        const cuadrilla1 = [];
        cuadrilla1.detalle = [];
        cuadrilla1.total = 0;
        cuadrilla1.epep = "";
        cuadrilla1.pp = "";
        cuadrilla1.activo = "";
        const cuadrilla2 = [];
        cuadrilla2.detalle = [];
        cuadrilla2.total = 0;
        cuadrilla2._id = "";
        cuadrilla2.epep = "";
        cuadrilla2.pp = "";
        cuadrilla2.activo = "";
        const cuadrilla3 = [];
        cuadrilla3.detalle = [];
        cuadrilla3.total = 0;
        cuadrilla3._id = "";
        cuadrilla3.epep = "";
        cuadrilla3.pp = "";
        cuadrilla3.activo = "";
        const cuadrilla4 = [];
        cuadrilla4.detalle = [];
        cuadrilla4.total = 0;
        cuadrilla4._id = "";
        cuadrilla4.epep = "";
        cuadrilla4.pp = "";
        cuadrilla4.activo = "";
        const cuadrilla5 = [];
        cuadrilla5.detalle = [];
        cuadrilla5.total = 0;
        cuadrilla5._id = "";
        cuadrilla5.epep = "";
        cuadrilla5.pp = "";
        cuadrilla5.activo = "";
        const cuadrilla6 = [];
        cuadrilla6.detalle = [];
        cuadrilla6.total = 0;
        cuadrilla6._id = "";
        cuadrilla6.epep = "";
        cuadrilla6.pp = "";
        cuadrilla6.activo = "";
        const cuadrilla7 = [];
        cuadrilla7.detalle = [];
        cuadrilla7.total = 0;
        cuadrilla7._id = "";
        cuadrilla7.epep = "";
        cuadrilla7.pp = "";
        cuadrilla7.activo = "";
        const cuadrilla8 = [];
        cuadrilla8.detalle = [];
        cuadrilla8.total = 0;
        cuadrilla8._id = "";
        cuadrilla8.epep = "";
        cuadrilla8.pp = "";
        cuadrilla8.activo = "";
        const cuadrilla9 = [];
        cuadrilla9.detalle = [];
        cuadrilla9.total = 0;
        cuadrilla9._id = "";
        cuadrilla9.epep = "";
        cuadrilla9.pp = "";
        cuadrilla9.activo = "";
        const cuadrilla10 = [];
        cuadrilla10.detalle = [];
        cuadrilla10.total = 0;
        cuadrilla10._id = "";
        cuadrilla10.epep = "";
        cuadrilla10.pp = "";
        cuadrilla10.activo = "";
        const cuadrilla11 = [];
        cuadrilla11.detalle = [];
        cuadrilla11.total = 0;
        cuadrilla11._id = "";
        cuadrilla11.epep = "";
        cuadrilla11.pp = "";
        cuadrilla11.activo = "";
        const cuadrilla12 = [];
        cuadrilla12.detalle = [];
        cuadrilla12.total = 0;
        cuadrilla12._id = "";
        cuadrilla12.epep = "";
        cuadrilla12.pp = "";
        cuadrilla12.activo = "";
        const cuadrilla13 = [];
        cuadrilla13.detalle = [];
        cuadrilla13.total = 0;
        cuadrilla13._id = "";
        cuadrilla13.epep = "";
        cuadrilla13.pp = "";
        cuadrilla13.activo = "";
        const cuadrilla14 = [];
        cuadrilla14.detalle = [];
        cuadrilla14.total = 0;
        cuadrilla14._id = "";
        cuadrilla14.epep = "";
        cuadrilla14.pp = "";
        cuadrilla14.activo = "";
        this.trazabilidadSinRegistrosTeb.forEach((e) => {
          if (
            e.activoEquipo.substring(0, 15) == "CABO 01" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 01" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 02" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 03" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 04" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 05"
          ) {
            cuadrilla1.detalle.push(e);
            cuadrilla1.total += e.montoEstimado;
            cuadrilla1.titulo = "Cuadrilla 1";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 02" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 06" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 07" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 08" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 09" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 10"
          ) {
            cuadrilla2.detalle.push(e);
            cuadrilla2.total += e.montoEstimado;
            cuadrilla2.titulo = "Cuadrilla 2";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 03" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 11" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 12" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 13" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 14" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 15"
          ) {
            cuadrilla3.detalle.push(e);
            cuadrilla3.total += e.montoEstimado;
            cuadrilla3.titulo = "Cuadrilla 3";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 04" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 16" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 17" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 18" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 19" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 20"
          ) {
            cuadrilla4.detalle.push(e);
            cuadrilla4.total += e.montoEstimado;
            cuadrilla4.titulo = "Cuadrilla 4";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 05" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 21" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 22" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 23" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 24" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 25"
          ) {
            cuadrilla5.detalle.push(e);
            cuadrilla5.total += e.montoEstimado;
            cuadrilla5.titulo = "Cuadrilla 5";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 06" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 26" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 27" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 28" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 29" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 30"
          ) {
            cuadrilla6.detalle.push(e);
            cuadrilla6.total += e.montoEstimado;
            cuadrilla6.titulo = "Cuadrilla 6";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 07" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 31" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 32" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 33" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 34" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 35"
          ) {
            cuadrilla7.detalle.push(e);
            cuadrilla7.total += e.montoEstimado;
            cuadrilla7.titulo = "Cuadrilla 7";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 08" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 36" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 37" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 38" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 39" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 40"
          ) {
            cuadrilla8.detalle.push(e);
            cuadrilla8.total += e.montoEstimado;
            cuadrilla8.titulo = "Cuadrilla 8";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 09" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 41" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 42" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 43" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 44" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 45"
          ) {
            cuadrilla9.detalle.push(e);
            cuadrilla9.total += e.montoEstimado;
            cuadrilla9.titulo = "Cuadrilla 9";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 10" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 46" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 47" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 48" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 49" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 50"
          ) {
            cuadrilla10.detalle.push(e);
            cuadrilla10.total += e.montoEstimado;
            cuadrilla10.titulo = "Cuadrilla 10";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 11" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 51" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 52" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 53" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 54" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 55" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 56"
          ) {
            cuadrilla11.detalle.push(e);
            cuadrilla11.total += e.montoEstimado;
            cuadrilla11.titulo = "Cuadrilla 11";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 12" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 57" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 58" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 59" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 60" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 61" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 62"
          ) {
            cuadrilla12.detalle.push(e);
            cuadrilla12.total += e.montoEstimado;
            cuadrilla12.titulo = "Cuadrilla 12";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 13" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 63" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 64" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 65" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 66" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 67" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 68"
          ) {
            cuadrilla13.detalle.push(e);
            cuadrilla13.total += e.montoEstimado;
            cuadrilla13.titulo = "Cuadrilla 13";
          } else if (
            e.activoEquipo.substring(0, 15) == "CABO 14" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 69" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 70" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 71" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 72" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 73" ||
            e.activoEquipo.substring(0, 15) == "MANIOBRISTA 74"
          ) {
            cuadrilla14.detalle.push(e);
            cuadrilla14.total += e.montoEstimado;
            cuadrilla14.titulo = "Cuadrilla 14";
          }
        });
        let cuadrillas = [];
        cuadrillas = JSON.parse(localStorage.getItem("cuadrillas"));

        if (cuadrillas) {
          console.log(cuadrillas.length);
          this.cuadrillasTotales = cuadrillas;
        } else {
          this.cuadrillasTotales.push(
            Object.assign({}, cuadrilla1),
            Object.assign({}, cuadrilla2),
            Object.assign({}, cuadrilla3),
            Object.assign({}, cuadrilla4),
            Object.assign({}, cuadrilla5),
            Object.assign({}, cuadrilla6),
            Object.assign({}, cuadrilla7),
            Object.assign({}, cuadrilla8),
            Object.assign({}, cuadrilla9),
            Object.assign({}, cuadrilla10),
            Object.assign({}, cuadrilla11),
            Object.assign({}, cuadrilla12),
            Object.assign({}, cuadrilla13),
            Object.assign({}, cuadrilla14)
          );
        }
      }

      this.prorratearUnaVez = false;
    },
    mostrarProrrateosActivos(items) {
      this.listaActivosPorProrratear = items;
      this.dialogActivosParaProrratear = true;
    },
    obtenerDatosActivoPorPP() {
      if (this.activosTotalGeneral[0].aems.find((e) => e.pp == this.programaPP)) {
        this.ppSearchDatos = this.activosTotalGeneral[0].aems.find(
          (e) => e.pp == this.programaPP
        );
      } else if (
        this.activosTotalGeneral[1].apapch.find((e) => e.pp == this.programaPP)
      ) {
        this.ppSearchDatos = this.activosTotalGeneral[1].apapch.find(
          (e) => e.pp == this.programaPP
        );
      } else if (this.activosTotalGeneral[2].apc.find((e) => e.pp == this.programaPP)) {
        this.ppSearchDatos = this.activosTotalGeneral[2].apc.find(
          (e) => e.pp == this.programaPP
        );
      } else if (this.activosTotalGeneral[3].apkmz.find((e) => e.pp == this.programaPP)) {
        this.ppSearchDatos = this.activosTotalGeneral[3].apkmz.find(
          (e) => e.pp == this.programaPP
        );
      } else if (this.activosTotalGeneral[4].aplt.find((e) => e.pp == this.programaPP)) {
        this.ppSearchDatos = this.activosTotalGeneral[4].aplt.find(
          (e) => e.pp == this.programaPP
        );
      } else if (this.activosTotalGeneral[5].spee.find((e) => e.pp == this.programaPP)) {
        this.ppSearchDatos = this.activosTotalGeneral[5].spee.find(
          (e) => e.pp == this.programaPP
        );
      } else if (this.activosTotalGeneral[6].eb.find((e) => e.pp == this.programaPP)) {
        this.ppSearchDatos = this.activosTotalGeneral[6].eb.find(
          (e) => e.pp == this.programaPP
        );
      } else if (this.activosTotalGeneral[7].bn.find((e) => e.pp == this.programaPP)) {
        this.ppSearchDatos = this.activosTotalGeneral[7].bn.find(
          (e) => e.pp == this.programaPP
        );
      } else if (this.activosTotalGeneral[8].aems1.find((e) => e.pp == this.programaPP)) {
        this.ppSearchDatos = this.activosTotalGeneral[8].aems1.find(
          (e) => e.pp == this.programaPP
        );
      } else if (this.activosTotalGeneral[9].aems3.find((e) => e.pp == this.programaPP)) {
        this.ppSearchDatos = this.activosTotalGeneral[9].aems3.find(
          (e) => e.pp == this.programaPP
        );
      }else {
        this.addErrorNotification("Programa presupuestal no encontrado");
        this.ppSearchDatos = [];
      }
    },

    async prueba(arr) {
      for (const activo of this.activosTotalGeneral[0].aems) {
        if (arr.programaPresupuestal == activo.pp) {
          arr.activoPemex = "";
          arr.epep = "";
          arr.programaPresupuestal = "";
          activo.montoDesglosado = activo.montoDesglosado + arr.montoEstimado;
          await api.limpiarInDb(activo.pp, this.mesServicio, activo.montoDesglosado);

          this.activosTotalGeneral[0].monto =
            this.activosTotalGeneral[0].monto + arr.montoEstimado;
        }
      }
      for (const activo of this.activosTotalGeneral[1].apapch) {
        if (arr.programaPresupuestal == activo.pp) {
          arr.activoPemex = "";
          arr.epep = "";
          arr.programaPresupuestal = "";
          activo.montoDesglosado = activo.montoDesglosado + arr.montoEstimado;
          await api.limpiarInDb(activo.pp, this.mesServicio, activo.montoDesglosado);

          this.activosTotalGeneral[1].monto =
            this.activosTotalGeneral[1].monto + arr.montoEstimado;
        }
      }

      for (const activo of this.activosTotalGeneral[2].apc) {
        if (arr.programaPresupuestal == activo.pp) {
          arr.activoPemex = "";
          arr.epep = "";
          arr.programaPresupuestal = "";
          activo.montoDesglosado = activo.montoDesglosado + arr.montoEstimado;
          await api.limpiarInDb(activo.pp, this.mesServicio, activo.montoDesglosado);

          this.activosTotalGeneral[2].monto =
            this.activosTotalGeneral[2].monto + arr.montoEstimado;
        }
      }

      for (const activo of this.activosTotalGeneral[3].apkmz) {
        if (arr.programaPresupuestal == activo.pp) {
          arr.activoPemex = "";
          arr.epep = "";
          arr.programaPresupuestal = "";
          activo.montoDesglosado = activo.montoDesglosado + arr.montoEstimado;
          await api.limpiarInDb(activo.pp, this.mesServicio, activo.montoDesglosado);
          this.activosTotalGeneral[3].monto =
            this.activosTotalGeneral[3].monto + arr.montoEstimado;
        }
      }

      for (const activo of this.activosTotalGeneral[4].aplt) {
        if (arr.programaPresupuestal == activo.pp) {
          arr.activoPemex = "";
          arr.epep = "";
          arr.programaPresupuestal = "";
          activo.montoDesglosado = activo.montoDesglosado + arr.montoEstimado;
          await api.limpiarInDb(activo.pp, this.mesServicio, activo.montoDesglosado);
          this.activosTotalGeneral[4].monto =
            this.activosTotalGeneral[4].monto + arr.montoEstimado;
        }
      }

      for (const activo of this.activosTotalGeneral[4].aplt) {
        if (arr.programaPresupuestal == activo.pp) {
          arr.activoPemex = "";
          arr.epep = "";
          arr.programaPresupuestal = "";
          activo.montoDesglosado = activo.montoDesglosado + arr.montoEstimado;
          await api.limpiarInDb(activo.pp, this.mesServicio, activo.montoDesglosado);
          this.activosTotalGeneral[4].monto =
            this.activosTotalGeneral[4].monto + arr.montoEstimado;
        }
      }

      for (const activo of this.activosTotalGeneral[5].spee) {
        if (arr.programaPresupuestal == activo.pp) {
          arr.activoPemex = "";
          arr.epep = "";
          arr.programaPresupuestal = "";
          activo.montoDesglosado = activo.montoDesglosado + arr.montoEstimado;
          await api.limpiarInDb(activo.pp, this.mesServicio, activo.montoDesglosado);
          this.activosTotalGeneral[5].monto =
            this.activosTotalGeneral[5].monto + arr.montoEstimado;
        }
      }

      for (const activo of this.activosTotalGeneral[6].eb) {
        if (arr.programaPresupuestal == activo.pp) {
          arr.activoPemex = "";
          arr.epep = "";
          arr.programaPresupuestal = "";
          activo.montoDesglosado = activo.montoDesglosado + arr.montoEstimado;
          await api.limpiarInDb(activo.pp, this.mesServicio, activo.montoDesglosado);
          this.activosTotalGeneral[6].monto =
            this.activosTotalGeneral[6].monto + arr.montoEstimado;
        }
      }

      for (const activo of this.activosTotalGeneral[7].bn) {
        if (arr.programaPresupuestal == activo.pp) {
          arr.activoPemex = "";
          arr.epep = "";
          arr.programaPresupuestal = "";
          activo.montoDesglosado = activo.montoDesglosado + arr.montoEstimado;
          await api.limpiarInDb(activo.pp, this.mesServicio, activo.montoDesglosado);
          this.activosTotalGeneral[7].monto =
            this.activosTotalGeneral[7].monto + arr.montoEstimado;
        }
      }

      for (const activo of this.activosTotalGeneral[8].aems1) {
        if (arr.programaPresupuestal == activo.pp) {
          arr.activoPemex = "";
          arr.epep = "";
          arr.programaPresupuestal = "";
          activo.montoDesglosado = activo.montoDesglosado + arr.montoEstimado;
          await api.limpiarInDb(activo.pp, this.mesServicio, activo.montoDesglosado);
          this.activosTotalGeneral[8].monto =
            this.activosTotalGeneral[8].monto + arr.montoEstimado;
        }
      }
      for (const activo of this.activosTotalGeneral[9].aems3) {
        if (arr.programaPresupuestal == activo.pp) {
          arr.activoPemex = "";
          arr.epep = "";
          arr.programaPresupuestal = "";
          activo.montoDesglosado = activo.montoDesglosado + arr.montoEstimado;
          await api.limpiarInDb(activo.pp, this.mesServicio, activo.montoDesglosado);
          this.activosTotalGeneral[9].monto =
            this.activosTotalGeneral[9].monto + arr.montoEstimado;
        }
      }
    },

    limpiarTab(arr) {
      this.activosTotalGeneral[0].aems.forEach((f) => {
        if (arr.programaPresupuestal == f.pp) {
          f.montoDesglosado = f.montoDesglosado + arr.montoEstimado;
          this.activosTotalGeneral[0].monto =
            this.activosTotalGeneral[0].monto + arr.montoEstimado;
        }
      });
      this.activosTotalGeneral[1].apapch.forEach((f) => {
        if (arr.programaPresupuestal == f.pp) {
          f.montoDesglosado = f.montoDesglosado + arr.montoEstimado;
          this.activosTotalGeneral[1].monto =
            this.activosTotalGeneral[1].monto + arr.montoEstimado;
        }
      });
      this.activosTotalGeneral[2].apc.forEach((f) => {
        if (arr.programaPresupuestal == f.pp) {
          f.montoDesglosado = f.montoDesglosado + arr.montoEstimado;
          this.activosTotalGeneral[2].monto =
            this.activosTotalGeneral[2].monto + arr.montoEstimado;
        }
      });
      this.activosTotalGeneral[3].apkmz.forEach((f) => {
        if (arr.programaPresupuestal == f.pp) {
          f.montoDesglosado = f.montoDesglosado + arr.montoEstimado;
          this.activosTotalGeneral[3].monto =
            this.activosTotalGeneral[3].monto + arr.montoEstimado;
        }
      });
      this.activosTotalGeneral[4].aplt.forEach((f) => {
        if (arr.programaPresupuestal == f.pp) {
          f.montoDesglosado = f.montoDesglosado + arr.montoEstimado;
          this.activosTotalGeneral[4].monto =
            this.activosTotalGeneral[4].monto + arr.montoEstimado;
        }
      });
      this.activosTotalGeneral[5].spee.forEach((f) => {
        if (arr.programaPresupuestal == f.pp) {
          f.montoDesglosado = f.montoDesglosado + arr.montoEstimado;
          this.activosTotalGeneral[5].monto =
            this.activosTotalGeneral[5].monto + arr.montoEstimado;
        }
      });
      this.activosTotalGeneral[6].eb.forEach((f) => {
        if (arr.programaPresupuestal == f.pp) {
          f.montoDesglosado = f.montoDesglosado + arr.montoEstimado;
          this.activosTotalGeneral[6].monto =
            this.activosTotalGeneral[6].monto + arr.montoEstimado;
        }
      });
      this.activosTotalGeneral[7].bn.forEach((f) => {
        if (arr.programaPresupuestal == f.pp) {
          f.montoDesglosado = f.montoDesglosado + arr.montoEstimado;
          this.activosTotalGeneral[7].monto =
            this.activosTotalGeneral[7].monto + arr.montoEstimado;
        }
      });
      this.activosTotalGeneral[8].aems1.forEach((f) => {
        if (arr.programaPresupuestal == f.pp) {
          f.montoDesglosado = f.montoDesglosado + arr.montoEstimado;
          this.activosTotalGeneral[8].monto =
            this.activosTotalGeneral[8].monto + arr.montoEstimado;
        }
      });
      this.activosTotalGeneral[9].aems3.forEach((f) => {
        if (arr.programaPresupuestal == f.pp) {
          f.montoDesglosado = f.montoDesglosado + arr.montoEstimado;
          this.activosTotalGeneral[9].monto =
            this.activosTotalGeneral[9].monto + arr.montoEstimado;
        }
      });
      this.$store.dispatch("guardarCuadrillas", this.cuadrillasTotales);
      /*  const socket = io("https://cma.cicsagruas.com/");
      socket.emit("activosProrratear"); */
    },

    async limpiarProrrateo(equipo) {
      if (!(await this.verificarConexion())) return;
      if (equipo == "cf") {
        for (const cf of this.cargadoresFrontalesSelected) {
          this.dialogLoading = true;
          await this.prueba(cf).then(() => {
            this.dialogLoading = false;
          });
        }
        let c = this.cargadoresFrontalesProrratear.some((e) => !e.epep);
        if (c) {
          this.cargadoresFrontalesEstatus = "pendiente";
        } else {
          this.cargadoresFrontalesEstatus = "finalizado";
          this.programaPPCF = "";
          this.obtenerDatosActivoPorPP();
        }
        this.equiposCGTotales.forEach((e) => {
          if (e.value == this.BuscarCFParaProrratear) {
            e.color = "white";
          }
        });
        this.equiposCGTotales = this.equiposCGTotales.sort((a, b) => b.total - a.total);
        this.equiposCGTotales = this.equiposCGTotales.sort((a, b) =>
          b.color.localeCompare(a.color)
        );
        this.cargadoresFrontalesSelected = [];
        this.buscarEquiposProrratear();
      } else if (equipo == "grua") {
        for (const gruas of this.gruasSelected) {
          this.dialogLoading = true;
          await this.prueba(gruas).then(() => {
            this.dialogLoading = false;
          });
        }

        let c = this.gruasProrratear.some((e) => !e.epep);
        if (c) {
          this.gruaEstatus = "pendiente";
        } else {
          this.gruaEstatus = "finalizado";
          this.programaPP = "";
          this.obtenerDatosActivoPorPP();
        }
        this.equiposGruaTotales.forEach((e) => {
          if (e.value == this.BuscarGruasParaProrratear) {
            e.color = "white";
          }
        });
        this.equiposGruaTotales = this.equiposGruaTotales.sort(
          (a, b) => b.total - a.total
        );
        this.equiposGruaTotales = this.equiposGruaTotales.sort((a, b) =>
          b.color.localeCompare(a.color)
        );
        this.gruasSelected = [];
        this.buscarEquiposProrratear();
      } else if (equipo == "montacargas") {
        for (const montacargas of this.montacargasSelected) {
          this.dialogLoading = true;
          await this.prueba(montacargas).then(() => {
            this.dialogLoading = false;
          });
        }
        let c = this.montacargasProrratear.some((e) => !e.epep);
        if (c) {
          this.montacargasEstatus = "pendiente";
        } else {
          this.montacargasEstatus = "finalizado";
          this.programaPPMontacargas = "";
          this.obtenerDatosActivoPorPPMontacargas();
        }
        this.equiposMontacargasTotales.forEach((e) => {
          if (e.value == this.BuscarMontacargasParaProrratear) {
            e.color = "white";
          }
        });
        this.equiposMontacargasTotales = this.equiposMontacargasTotales.sort(
          (a, b) => b.total - a.total
        );
        this.equiposMontacargasTotales = this.equiposMontacargasTotales.sort((a, b) =>
          b.color.localeCompare(a.color)
        );
        this.montacargasSelected = [];
        this.buscarEquiposProrratearMontacargas();
      } else if (equipo == "tractocamion") {
        for (const tracto of this.tractomacionSelected) {
          this.dialogLoading = true;
          await this.prueba(tracto).then(() => {
            this.dialogLoading = false;
          });
        }
        let c = this.tractocamionProrratear.some((e) => !e.epep);
        if (c) {
          this.tractocamionEstatus = "pendiente";
        } else {
          this.tractocamionEstatus = "finalizado";
          this.programaPPTractocamion = "";
          this.obtenerDatosActivoPorPPTractocamion();
        }
        this.equiposTractocamionTotales.forEach((e) => {
          if (e.value == this.BuscarTractocamionParaProrratear) {
            e.color = "white";
          }
        });
        this.equiposTractocamionTotales = this.equiposTractocamionTotales.sort(
          (a, b) => b.total - a.total
        );
        this.equiposTractocamionTotales = this.equiposTractocamionTotales.sort((a, b) =>
          b.color.localeCompare(a.color)
        );
        this.tractomacionSelected = [];
        this.buscarEquiposProrratearTractocamion();
      } else if (equipo == "camion") {
        for (const camion of this.camionSelected) {
          this.dialogLoading = true;
          await this.prueba(camion).then(() => {
            this.dialogLoading = false;
          });
        }
        let c = this.equiposCamionTotales.some((e) => !e.epep);
        if (c) {
          this.cuadrillaEstatus = "pendiente";
        } else {
          this.cuadrillaEstatus = "finalizado";
          this.programaPPCamion = "";
          this.obtenerDatosActivoPorPPCamion();
        }
        this.equiposCamionTotales.forEach((e) => {
          if (e.value == this.BuscarCamionParaProrratear) {
            e.color = "white";
          }
        });
        this.equiposCamionTotales = this.equiposCamionTotales.sort(
          (a, b) => b.total - a.total
        );
        this.equiposCamionTotales = this.equiposCamionTotales.sort((a, b) =>
          b.color.localeCompare(a.color)
        );
        this.camionSelected = [];
        this.buscarEquiposProrratearCamion();
      } else if (equipo == "cuadrillas") {
        for (const activo of this.cuadrillasSelected) {
          let arr = {
            programaPresupuestal: activo.pp,
            montoEstimado: activo.total,
          };
          await api.sumar(activo.pp, this.mesServicio, activo.total).then(async () => {
            this.dialogLoading = false;
            this.limpiarTab(arr);
            await this.listarActivosTodos();
          });
        }
        this.cuadrillasSelected.forEach((f) => {
          let index = this.cuadrillasTotales.findIndex((e) => e.titulo == f.titulo);
          console.log(this.cuadrillasTotales[index]);
          this.cuadrillasTotales[index].activo = "";
          this.cuadrillasTotales[index].epep = "";
          this.cuadrillasTotales[index].pp = "";


          console.log(index);
          f.activo = "";
          f.epep = "";
          f.pp = "";
          f.detalle.forEach((e) => {
            let busqueda = this.trazabilidadRegistros.findIndex((r) => r._id === e._id);
            this.trazabilidadRegistros[busqueda].activoPemex = "";
            this.trazabilidadRegistros[busqueda].epep = "";
            this.trazabilidadRegistros[busqueda].programaPresupuestal = "";

            e.activoPemex = "";
            e.epep = "";
            e.programaPresupuestal = "";
          });
        });
      }
      this.$store.dispatch("guardarCuadrillas", this.cuadrillasTotales);
      this.porcentajeMontoTotalCalculo();
      this.guardarProrrateoLocalStore();
      this.validarProrrateadosCompleto();
    },

    async prorratear() {
      if (!(await this.verificarConexion())) return;
      this.gruasProrratear.forEach((e) => {
        this.gruasSelected.forEach((f) => {
          if (!e.activoPemex) {
            if (e._id == f._id) {
              e.activoPemex = this.ppSearchDatos.activo;
              e.epep = this.ppSearchDatos.epep;
              e.programaPresupuestal = this.ppSearchDatos.pp;
              e.numeroDeSolicitud = "X";
              e.prorrateado = "SI";
            }
          }
        });
      });
      await this.prorratearInDb(this.ppSearchDatos.pp, this.sumaGruas);
      this.totalProrratear = this.totalProrratear - this.sumaGruas;
      /*   const socket = io("https://cma.cicsagruas.com/");
      socket.emit("activosProrratear"); */
      let gruas = [];
      this.gruasProrratear.forEach((e) => {
        if (e.activoEquipo == this.BuscarGruasParaProrratear) {
          gruas.push(e);
        }
      });

      const even = gruas.some((e) => e.activoPemex == "");
      if (!even) {
        this.equiposGruaTotales.forEach((e) => {
          if (e.value == this.BuscarGruasParaProrratear) {
            e.color = "#64FFDA";
          }
        });
        this.equiposGruaTotales = this.equiposGruaTotales.sort((a, b) =>
          b.color.localeCompare(a.color)
        );
      }

      this.activosTotalGeneral[0].aems.forEach((e) => {
        if (e.pp == this.programaPP) {
          e.montoDesglosado = e.montoDesglosado - this.sumaGruas;
          this.activosTotalGeneral[0].monto =
            this.activosTotalGeneral[0].monto - this.sumaGruas;
        }
      });
      this.activosTotalGeneral[1].apapch.forEach((e) => {
        if (e.pp == this.programaPP) {
          e.montoDesglosado = e.montoDesglosado - this.sumaGruas;
          this.activosTotalGeneral[1].monto =
            this.activosTotalGeneral[1].monto - this.sumaGruas;
        }
      });
      this.activosTotalGeneral[2].apc.forEach((e) => {
        if (e.pp == this.programaPP) {
          e.montoDesglosado = e.montoDesglosado - this.sumaGruas;
          this.activosTotalGeneral[2].monto =
            this.activosTotalGeneral[2].monto - this.sumaGruas;
        }
      });
      this.activosTotalGeneral[3].apkmz.forEach((e) => {
        if (e.pp == this.programaPP) {
          e.montoDesglosado = e.montoDesglosado - this.sumaGruas;
          this.activosTotalGeneral[3].monto =
            this.activosTotalGeneral[3].monto - this.sumaGruas;
        }
      });
      this.activosTotalGeneral[4].aplt.forEach((e) => {
        if (e.pp == this.programaPP) {
          e.montoDesglosado = e.montoDesglosado - this.sumaGruas;
          this.activosTotalGeneral[4].monto =
            this.activosTotalGeneral[4].monto - this.sumaGruas;
        }
      });
      this.activosTotalGeneral[5].spee.forEach((e) => {
        if (e.pp == this.programaPP) {
          e.montoDesglosado = e.montoDesglosado - this.sumaGruas;
          this.activosTotalGeneral[5].monto =
            this.activosTotalGeneral[5].monto - this.sumaGruas;
        }
      });
      this.activosTotalGeneral[6].eb.forEach((e) => {
        if (e.pp == this.programaPP) {
          e.montoDesglosado = e.montoDesglosado - this.sumaGruas;
          this.activosTotalGeneral[6].monto =
            this.activosTotalGeneral[6].monto - this.sumaGruas;
        }
      });
      this.activosTotalGeneral[7].bn.forEach((e) => {
        if (e.pp == this.programaPP) {
          e.montoDesglosado = e.montoDesglosado - this.sumaGruas;
          this.activosTotalGeneral[7].monto =
            this.activosTotalGeneral[7].monto - this.sumaGruas;
        }
      });
      this.activosTotalGeneral[8].aems1.forEach((e) => {
        if (e.pp == this.programaPP) {
          e.montoDesglosado = e.montoDesglosado - this.sumaGruas;
          this.activosTotalGeneral[8].monto =
            this.activosTotalGeneral[8].monto - this.sumaGruas;
        }
      });
      let c = this.gruasProrratear.some((e) => !e.epep);
      if (c) {
        this.gruaEstatus = "pendiente";
      } else {
        this.gruaEstatus = "finalizado";
        this.programaPP = "";
        this.obtenerDatosActivoPorPP();
      }
      this.gruasSelected = [];
      this.mostrarActivosPorEquipo();
      this.buscarEquiposProrratear();
      this.guardarProrrateoLocalStore();
    },

    buscarEquiposProrratear() {
      let total = 0;
      let equipo = "";
      this.gruasEquipoTotalSearch = [];
      let re = new RegExp(this.BuscarGruasParaProrratear, "gi");
      this.gruasProrratear.forEach((e) => {
        if (e.activoEquipo.match(re) && !e.epep) {
          total += e.montoEstimado;
          equipo = e.activoEquipo;
        }
      });
      this.gruasEquipoTotalSearch.push({
        equipo,
        total,
      });
    },

    obtenerDatosActivoPorPPCF() {
      if (this.activosTotalGeneral[0].aems.find((e) => e.pp == this.programaPPCF)) {
        this.ppSearchDatosCF = this.activosTotalGeneral[0].aems.find(
          (e) => e.pp == this.programaPPCF
        );
      } else if (
        this.activosTotalGeneral[1].apapch.find((e) => e.pp == this.programaPPCF)
      ) {
        this.ppSearchDatosCF = this.activosTotalGeneral[1].apapch.find(
          (e) => e.pp == this.programaPPCF
        );
      } else if (this.activosTotalGeneral[2].apc.find((e) => e.pp == this.programaPPCF)) {
        this.ppSearchDatosCF = this.activosTotalGeneral[2].apc.find(
          (e) => e.pp == this.programaPPCF
        );
      } else if (
        this.activosTotalGeneral[3].apkmz.find((e) => e.pp == this.programaPPCF)
      ) {
        this.ppSearchDatosCF = this.activosTotalGeneral[3].apkmz.find(
          (e) => e.pp == this.programaPPCF
        );
      } else if (
        this.activosTotalGeneral[4].aplt.find((e) => e.pp == this.programaPPCF)
      ) {
        this.ppSearchDatosCF = this.activosTotalGeneral[4].aplt.find(
          (e) => e.pp == this.programaPPCF
        );
      } else if (
        this.activosTotalGeneral[5].spee.find((e) => e.pp == this.programaPPCF)
      ) {
        this.ppSearchDatosCF = this.activosTotalGeneral[5].spee.find(
          (e) => e.pp == this.programaPPCF
        );
      } else if (this.activosTotalGeneral[6].eb.find((e) => e.pp == this.programaPPCF)) {
        this.ppSearchDatosCF = this.activosTotalGeneral[6].eb.find(
          (e) => e.pp == this.programaPPCF
        );
      } else if (this.activosTotalGeneral[7].bn.find((e) => e.pp == this.programaPPCF)) {
        this.ppSearchDatosCF = this.activosTotalGeneral[7].bn.find(
          (e) => e.pp == this.programaPPCF
        );
      } else if (
        this.activosTotalGeneral[8].aems1.find((e) => e.pp == this.programaPPCF)
      ) {
        this.ppSearchDatosCF = this.activosTotalGeneral[8].aems1.find(
          (e) => e.pp == this.programaPPCF
        );
      }else if (
        this.activosTotalGeneral[9].aems3.find((e) => e.pp == this.programaPPCF)
      ) {
        this.ppSearchDatosCF = this.activosTotalGeneral[9].aems3.find(
          (e) => e.pp == this.programaPPCF
        );
      } else {
        this.addErrorNotification("Programa presupuestal no encontrado");
        this.ppSearchDatosCF = [];
      }
    },

    async prorratearCF() {
      if (!(await this.verificarConexion())) return;
      this.cargadoresFrontalesProrratear.forEach((e) => {
        this.cargadoresFrontalesSelected.forEach((f) => {
          if (!e.activoPemex) {
            if (e._id == f._id) {
              e.activoPemex = this.ppSearchDatosCF.activo;
              e.epep = this.ppSearchDatosCF.epep;
              e.programaPresupuestal = this.ppSearchDatosCF.pp;
              e.numeroDeSolicitud = "X";
              e.prorrateado = "SI";
            }
          }
        });
      });
      await this.prorratearInDb(this.ppSearchDatosCF.pp, this.sumaCF);
      this.totalProrratear = this.totalProrratear - this.sumaCF;
      /*  const socket = io("https://cma.cicsagruas.com/");
      socket.emit("activosProrratear"); */
      let cf = [];
      this.cargadoresFrontalesProrratear.forEach((e) => {
        if (e.activoEquipo == this.BuscarCFParaProrratear) {
          cf.push(e);
        }
      });

      const even = cf.some((e) => e.activoPemex == "");
      if (!even) {
        this.equiposCGTotales.forEach((e) => {
          if (e.value == this.BuscarCFParaProrratear) {
            e.color = "#64FFDA";
          }
        });
        this.equiposCGTotales = this.equiposCGTotales.sort((a, b) =>
          b.color.localeCompare(a.color)
        );
      }

      this.activosTotalGeneral[0].aems.forEach((e) => {
        if (e.pp == this.programaPPCF) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCF;
          this.activosTotalGeneral[0].monto =
            this.activosTotalGeneral[0].monto - this.sumaCF;
        }
      });
      this.activosTotalGeneral[1].apapch.forEach((e) => {
        if (e.pp == this.programaPPCF) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCF;
          this.activosTotalGeneral[1].monto =
            this.activosTotalGeneral[1].monto - this.sumaCF;
        }
      });
      this.activosTotalGeneral[2].apc.forEach((e) => {
        if (e.pp == this.programaPPCF) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCF;
          this.activosTotalGeneral[2].monto =
            this.activosTotalGeneral[2].monto - this.sumaCF;
        }
      });
      this.activosTotalGeneral[3].apkmz.forEach((e) => {
        if (e.pp == this.programaPPCF) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCF;
          this.activosTotalGeneral[3].monto =
            this.activosTotalGeneral[3].monto - this.sumaCF;
        }
      });
      this.activosTotalGeneral[4].aplt.forEach((e) => {
        if (e.pp == this.programaPPCF) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCF;
          this.activosTotalGeneral[4].monto =
            this.activosTotalGeneral[4].monto - this.sumaCF;
        }
      });
      this.activosTotalGeneral[5].spee.forEach((e) => {
        if (e.pp == this.programaPPCF) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCF;
          this.activosTotalGeneral[5].monto =
            this.activosTotalGeneral[5].monto - this.sumaCF;
        }
      });
      this.activosTotalGeneral[6].eb.forEach((e) => {
        if (e.pp == this.programaPPCF) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCF;
          this.activosTotalGeneral[6].monto =
            this.activosTotalGeneral[6].monto - this.sumaCF;
        }
      });
      this.activosTotalGeneral[7].bn.forEach((e) => {
        if (e.pp == this.programaPPCF) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCF;
          this.activosTotalGeneral[7].monto =
            this.activosTotalGeneral[7].monto - this.sumaCF;
        }
      });
      this.activosTotalGeneral[8].aems1.forEach((e) => {
        if (e.pp == this.programaPPCF) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCF;
          this.activosTotalGeneral[8].monto =
            this.activosTotalGeneral[8].monto - this.sumaCF;
        }
      });
      let c = this.cargadoresFrontalesProrratear.some((e) => !e.epep);
      if (c) {
        this.cargadoresFrontalesEstatus = "pendiente";
      } else {
        this.cargadoresFrontalesEstatus = "finalizado";
        this.programaPPCF = "";
        this.obtenerDatosActivoPorPP();
      }
      this.cargadoresFrontalesSelected = [];
      this.obtenerDatosActivoPorPPCF();
      this.mostrarActivosPorEquipo();
      this.guardarProrrateoLocalStore();
    },
    buscarEquiposProrratearCF() {
      let total = 0;
      let equipo = "";
      this.cfEquipoTotalSearch = [];
      let re = new RegExp(this.BuscarCFParaProrratear, "gi");
      this.cargadoresFrontalesProrratear.forEach((e) => {
        if (e.activoEquipo.match(re) && !e.epep) {
          total += e.montoEstimado;
          equipo = e.activoEquipo;
        }
      });
      this.cfEquipoTotalSearch.push({
        equipo,
        total,
      });
    },

    buscarEquiposProrratearMontacargas() {
      let total = 0;
      let equipo = "";
      this.montacargasEquipoTotalSearch = [];
      let re = new RegExp(this.BuscarMontacargasParaProrratear, "gi");
      this.montacargasProrratear.forEach((e) => {
        if (e.activoEquipo.match(re) && !e.epep) {
          total += e.montoEstimado;
          equipo = e.activoEquipo;
        }
      });
      this.montacargasEquipoTotalSearch.push({
        equipo,
        total,
      });
    },

    obtenerDatosActivoPorPPMontacargas() {
      if (
        this.activosTotalGeneral[0].aems.find((e) => e.pp == this.programaPPMontacargas)
      ) {
        this.ppSearchDatosMontacargas = this.activosTotalGeneral[0].aems.find(
          (e) => e.pp == this.programaPPMontacargas
        );
      } else if (
        this.activosTotalGeneral[1].apapch.find((e) => e.pp == this.programaPPMontacargas)
      ) {
        this.ppSearchDatosMontacargas = this.activosTotalGeneral[1].apapch.find(
          (e) => e.pp == this.programaPPMontacargas
        );
      } else if (
        this.activosTotalGeneral[2].apc.find((e) => e.pp == this.programaPPMontacargas)
      ) {
        this.ppSearchDatosMontacargas = this.activosTotalGeneral[2].apc.find(
          (e) => e.pp == this.programaPPMontacargas
        );
      } else if (
        this.activosTotalGeneral[3].apkmz.find((e) => e.pp == this.programaPPMontacargas)
      ) {
        this.ppSearchDatosMontacargas = this.activosTotalGeneral[3].apkmz.find(
          (e) => e.pp == this.programaPPMontacargas
        );
      } else if (
        this.activosTotalGeneral[4].aplt.find((e) => e.pp == this.programaPPMontacargas)
      ) {
        this.ppSearchDatosMontacargas = this.activosTotalGeneral[4].aplt.find(
          (e) => e.pp == this.programaPPMontacargas
        );
      } else if (
        this.activosTotalGeneral[5].spee.find((e) => e.pp == this.programaPPMontacargas)
      ) {
        this.ppSearchDatosMontacargas = this.activosTotalGeneral[5].spee.find(
          (e) => e.pp == this.programaPPMontacargas
        );
      } else if (
        this.activosTotalGeneral[6].eb.find((e) => e.pp == this.programaPPMontacargas)
      ) {
        this.ppSearchDatosMontacargas = this.activosTotalGeneral[6].eb.find(
          (e) => e.pp == this.programaPPMontacargas
        );
      } else if (
        this.activosTotalGeneral[7].bn.find((e) => e.pp == this.programaPPMontacargas)
      ) {
        this.ppSearchDatosMontacargas = this.activosTotalGeneral[7].bn.find(
          (e) => e.pp == this.programaPPMontacargas
        );
      } else if (
        this.activosTotalGeneral[8].aems1.find((e) => e.pp == this.programaPPMontacargas)
      ) {
        this.ppSearchDatosMontacargas = this.activosTotalGeneral[8].aems1.find(
          (e) => e.pp == this.programaPPMontacargas
        );
      } 
      else if (
        this.activosTotalGeneral[9].aems3.find((e) => e.pp == this.programaPPMontacargas)
      ) {
        this.ppSearchDatosMontacargas = this.activosTotalGeneral[9].aems3.find(
          (e) => e.pp == this.programaPPMontacargas
        );
      } else {
        this.addErrorNotification("Programa presupuestal no encontrado");
        this.ppSearchDatosCF = [];
      }
    },

    async prorratearMontacargas() {
      if (!(await this.verificarConexion())) return;
      this.montacargasProrratear.forEach((e) => {
        this.montacargasSelected.forEach((f) => {
          if (!e.activoPemex) {
            if (e._id == f._id) {
              e.activoPemex = this.ppSearchDatosMontacargas.activo;
              e.epep = this.ppSearchDatosMontacargas.epep;
              e.programaPresupuestal = this.ppSearchDatosMontacargas.pp;
              e.numeroDeSolicitud = "X";
              e.prorrateado = "SI";
            }
          }
        });
      });
      await this.prorratearInDb(this.ppSearchDatosMontacargas.pp, this.sumaMontacargas);
      this.totalProrratear = this.totalProrratear - this.sumaMontacargas;
      /*  const socket = io("https://cma.cicsagruas.com/");
      socket.emit("activosProrratear"); */
      let mc = [];
      this.montacargasProrratear.forEach((e) => {
        if (e.activoEquipo == this.BuscarMontacargasParaProrratear) {
          mc.push(e);
        }
      });

      const even = mc.some((e) => e.activoPemex == "");
      if (!even) {
        this.equiposMontacargasTotales.forEach((e) => {
          if (e.value == this.BuscarMontacargasParaProrratear) {
            e.color = "#64FFDA";
          }
        });
        this.equiposMontacargasTotales = this.equiposMontacargasTotales.sort((a, b) =>
          b.color.localeCompare(a.color)
        );
      }

      this.activosTotalGeneral[0].aems.forEach((e) => {
        if (e.pp == this.programaPPMontacargas) {
          e.montoDesglosado = e.montoDesglosado - this.sumaMontacargas;
          this.activosTotalGeneral[0].monto =
            this.activosTotalGeneral[0].monto - this.sumaMontacargas;
        }
      });
      this.activosTotalGeneral[1].apapch.forEach((e) => {
        if (e.pp == this.programaPPMontacargas) {
          e.montoDesglosado = e.montoDesglosado - this.sumaMontacargas;
          this.activosTotalGeneral[1].monto =
            this.activosTotalGeneral[1].monto - this.sumaMontacargas;
        }
      });
      this.activosTotalGeneral[2].apc.forEach((e) => {
        if (e.pp == this.programaPPMontacargas) {
          e.montoDesglosado = e.montoDesglosado - this.sumaMontacargas;
          this.activosTotalGeneral[2].monto =
            this.activosTotalGeneral[2].monto - this.sumaMontacargas;
        }
      });
      this.activosTotalGeneral[3].apkmz.forEach((e) => {
        if (e.pp == this.programaPPMontacargas) {
          e.montoDesglosado = e.montoDesglosado - this.sumaMontacargas;
          this.activosTotalGeneral[3].monto =
            this.activosTotalGeneral[3].monto - this.sumaMontacargas;
        }
      });
      this.activosTotalGeneral[4].aplt.forEach((e) => {
        if (e.pp == this.programaPPMontacargas) {
          e.montoDesglosado = e.montoDesglosado - this.sumaMontacargas;
          this.activosTotalGeneral[4].monto =
            this.activosTotalGeneral[4].monto - this.sumaMontacargas;
        }
      });
      this.activosTotalGeneral[5].spee.forEach((e) => {
        if (e.pp == this.programaPPMontacargas) {
          e.montoDesglosado = e.montoDesglosado - this.sumaMontacargas;
          this.activosTotalGeneral[5].monto =
            this.activosTotalGeneral[5].monto - this.sumaMontacargas;
        }
      });
      this.activosTotalGeneral[6].eb.forEach((e) => {
        if (e.pp == this.programaPPMontacargas) {
          e.montoDesglosado = e.montoDesglosado - this.sumaMontacargas;
          this.activosTotalGeneral[6].monto =
            this.activosTotalGeneral[6].monto - this.sumaMontacargas;
        }
      });
      this.activosTotalGeneral[7].bn.forEach((e) => {
        if (e.pp == this.programaPPMontacargas) {
          e.montoDesglosado = e.montoDesglosado - this.sumaMontacargas;
          this.activosTotalGeneral[7].monto =
            this.activosTotalGeneral[7].monto - this.sumaMontacargas;
        }
      });
      this.activosTotalGeneral[8].aems1.forEach((e) => {
        if (e.pp == this.programaPPMontacargas) {
          e.montoDesglosado = e.montoDesglosado - this.sumaMontacargas;
          this.activosTotalGeneral[8].monto =
            this.activosTotalGeneral[8].monto - this.sumaMontacargas;
        }
      });
      let c = this.montacargasProrratear.some((e) => !e.epep);
      if (c) {
        this.montacargasEstatus = "pendiente";
      } else {
        this.montacargasEstatus = "finalizado";
        this.programaPPMontacargas = "";
        this.obtenerDatosActivoPorPPMontacargas();
      }
      this.montacargasSelected = [];
      this.buscarEquiposProrratearMontacargas();
      this.mostrarActivosPorEquipo();
      this.guardarProrrateoLocalStore();
    },

    buscarEquiposProrratearTractocamion() {
      let total = 0;
      let equipo = "";
      this.tractocamionEquipoTotalSearch = [];
      let re = new RegExp(this.BuscarTractocamionParaProrratear, "gi");
      this.tractocamionProrratear.forEach((e) => {
        if (e.activoEquipo.match(re) && !e.epep) {
          total += e.montoEstimado;
          equipo = e.activoEquipo;
        }
      });
      this.tractocamionEquipoTotalSearch.push({
        equipo,
        total,
      });
    },

    obtenerDatosActivoPorPPTractocamion() {
      if (
        this.activosTotalGeneral[0].aems.find((e) => e.pp == this.programaPPTractocamion)
      ) {
        this.ppSearchDatosTractocamion = this.activosTotalGeneral[0].aems.find(
          (e) => e.pp == this.programaPPTractocamion
        );
      } else if (
        this.activosTotalGeneral[1].apapch.find(
          (e) => e.pp == this.programaPPTractocamion
        )
      ) {
        this.ppSearchDatosTractocamion = this.activosTotalGeneral[1].apapch.find(
          (e) => e.pp == this.programaPPTractocamion
        );
      } else if (
        this.activosTotalGeneral[2].apc.find((e) => e.pp == this.programaPPTractocamion)
      ) {
        this.ppSearchDatosTractocamion = this.activosTotalGeneral[2].apc.find(
          (e) => e.pp == this.programaPPTractocamion
        );
      } else if (
        this.activosTotalGeneral[3].apkmz.find((e) => e.pp == this.programaPPTractocamion)
      ) {
        this.ppSearchDatosTractocamion = this.activosTotalGeneral[3].apkmz.find(
          (e) => e.pp == this.programaPPTractocamion
        );
      } else if (
        this.activosTotalGeneral[4].aplt.find((e) => e.pp == this.programaPPTractocamion)
      ) {
        this.ppSearchDatosTractocamion = this.activosTotalGeneral[4].aplt.find(
          (e) => e.pp == this.programaPPTractocamion
        );
      } else if (
        this.activosTotalGeneral[5].spee.find((e) => e.pp == this.programaPPTractocamion)
      ) {
        this.ppSearchDatosTractocamion = this.activosTotalGeneral[5].spee.find(
          (e) => e.pp == this.programaPPTractocamion
        );
      } else if (
        this.activosTotalGeneral[6].eb.find((e) => e.pp == this.programaPPTractocamion)
      ) {
        this.ppSearchDatosTractocamion = this.activosTotalGeneral[6].eb.find(
          (e) => e.pp == this.programaPPTractocamion
        );
      } else if (
        this.activosTotalGeneral[7].bn.find((e) => e.pp == this.programaPPTractocamion)
      ) {
        this.ppSearchDatosTractocamion = this.activosTotalGeneral[7].bn.find(
          (e) => e.pp == this.programaPPTractocamion
        );
      } else if (
        this.activosTotalGeneral[8].aems1.find((e) => e.pp == this.programaPPTractocamion)
      ) {
        this.ppSearchDatosTractocamion = this.activosTotalGeneral[8].aems1.find(
          (e) => e.pp == this.programaPPTractocamion
        );
      }else if (
        this.activosTotalGeneral[9].aems3.find((e) => e.pp == this.programaPPTractocamion)
      ) {
        this.ppSearchDatosTractocamion = this.activosTotalGeneral[9].aems3.find(
          (e) => e.pp == this.programaPPTractocamion
        );
      } else {
        this.addErrorNotification("Programa presupuestal no encontrado");
        this.ppSearchDatosTractocamion = [];
      }
    },

    async prorratearCuadrillas() {
      if (!(await this.verificarConexion())) return;
      await this.cuadrillasTotales.forEach((e) => {
        this.cuadrillasSelected.forEach((f) => {
          if (e.titulo == f.titulo) {
            e.activo = this.ppSearchDatosCuadrilla.activo;
            e.epep = this.ppSearchDatosCuadrilla.epep;
            e.pp = this.ppSearchDatosCuadrilla.pp;
            e.detalle.forEach((g) => {
              let busqueda = this.trazabilidadRegistros.findIndex((r) => r._id === g._id);
              this.trazabilidadRegistros[
                busqueda
              ].activoPemex = this.ppSearchDatosCuadrilla.activo;
              this.trazabilidadRegistros[
                busqueda
              ].epep = this.ppSearchDatosCuadrilla.epep;
              this.trazabilidadRegistros[
                busqueda
              ].programaPresupuestal = this.ppSearchDatosCuadrilla.pp;
              this.trazabilidadRegistros[busqueda].numeroDeSolicitud = "X";
              this.trazabilidadRegistros[busqueda].prorrateado = "SI";
              g.activoPemex = this.ppSearchDatosCuadrilla.activo;
              g.epep = this.ppSearchDatosCuadrilla.epep;
              g.programaPresupuestal = this.ppSearchDatosCuadrilla.pp;
              g.numeroDeSolicitud = "X";
              g.prorrateado = "SI";
            });
          }
        });
      });
      await this.prorratearInDb(this.ppSearchDatosCuadrilla.pp, this.sumaCuadrilla);
      this.totalProrratear = this.totalProrratear - this.sumaCuadrilla;
      /*   const socket = io("https://cma.cicsagruas.com/");
      socket.emit("activosProrratear"); */

      this.ppSearchDatosCuadrilla.montoDesglosado =
        this.ppSearchDatosCuadrilla.montoDesglosado - this.sumaCuadrilla;
      this.cuadrillasSelected = [];
      switch (this.ppSearchDatosCuadrilla.activo) {
        case "AEMS":
          this.activosTotalGeneral[0].monto -= this.sumaCuadrilla;
          break;
        case "APAPCH":
          this.activosTotalGeneral[1].monto -= this.sumaCuadrilla;
          break;
        case "APC":
          this.activosTotalGeneral[2].monto -= this.sumaCuadrilla;
          break;
        case "APKMZ":
          this.activosTotalGeneral[3].monto -= this.sumaCuadrilla;
          break;
        case "APLT":
          this.activosTotalGeneral[4].monto -= this.sumaCuadrilla;
          break;
        case "SPEE":
          this.activosTotalGeneral[5].monto -= this.sumaCuadrilla;
          break;
        case "APC-EK-BALAM":
          this.activosTotalGeneral[6].monto -= this.sumaCuadrilla;
          break;
        case "BLOQUE NORTE":
          this.activosTotalGeneral[7].monto -= this.sumaCuadrilla;
          break;
        case "AEMS1":
          this.activosTotalGeneral[8].monto -= this.sumaCuadrilla;
          break;
      }
      this.mostrarActivosPorEquipo();
      /* this.buscarEquiposProrratear(); */
      this.guardarProrrateoLocalStore();
      this.$store.dispatch("guardarCuadrillas", this.cuadrillasTotales);
    },

    async prorratearTractocamion() {
      if (!(await this.verificarConexion())) return;
      this.tractocamionProrratear.forEach((e) => {
        this.tractomacionSelected.forEach((f) => {
          if (!e.activoPemex) {
            if (e._id == f._id) {
              e.activoPemex = this.ppSearchDatosTractocamion.activo;
              e.epep = this.ppSearchDatosTractocamion.epep;
              e.programaPresupuestal = this.ppSearchDatosTractocamion.pp;
              e.numeroDeSolicitud = "X";
              e.prorrateado = "SI";
            }
          }
        });
      });
      await this.prorratearInDb(this.ppSearchDatosTractocamion.pp, this.sumaTractocamion);
      this.totalProrratear = this.totalProrratear - this.sumaTractocamion;
      /*   const socket = io("https://cma.cicsagruas.com/");
      socket.emit("activosProrratear"); */
      let tc = [];
      this.tractocamionProrratear.forEach((e) => {
        if (e.activoEquipo == this.BuscarTractocamionParaProrratear) {
          tc.push(e);
        }
      });

      const even = tc.some((e) => e.activoPemex == "");
      if (!even) {
        this.equiposTractocamionTotales.forEach((e) => {
          if (e.value == this.BuscarTractocamionParaProrratear) {
            e.color = "#64FFDA";
          }
        });
        this.equiposTractocamionTotales = this.equiposTractocamionTotales.sort((a, b) =>
          b.color.localeCompare(a.color)
        );
      }
      this.activosTotalGeneral[0].aems.forEach((e) => {
        if (e.pp == this.programaPPTractocamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaTractocamion;
          this.activosTotalGeneral[0].monto =
            this.activosTotalGeneral[0].monto - this.sumaTractocamion;
        }
      });
      this.activosTotalGeneral[1].apapch.forEach((e) => {
        if (e.pp == this.programaPPTractocamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaTractocamion;
          this.activosTotalGeneral[1].monto =
            this.activosTotalGeneral[1].monto - this.sumaTractocamion;
        }
      });
      this.activosTotalGeneral[2].apc.forEach((e) => {
        if (e.pp == this.programaPPTractocamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaTractocamion;
          this.activosTotalGeneral[2].monto =
            this.activosTotalGeneral[2].monto - this.sumaTractocamion;
        }
      });
      this.activosTotalGeneral[3].apkmz.forEach((e) => {
        if (e.pp == this.programaPPTractocamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaTractocamion;
          this.activosTotalGeneral[3].monto =
            this.activosTotalGeneral[3].monto - this.sumaTractocamion;
        }
      });
      this.activosTotalGeneral[4].aplt.forEach((e) => {
        if (e.pp == this.programaPPTractocamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaTractocamion;
          this.activosTotalGeneral[4].monto =
            this.activosTotalGeneral[4].monto - this.sumaTractocamion;
        }
      });
      this.activosTotalGeneral[5].spee.forEach((e) => {
        if (e.pp == this.programaPPTractocamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaTractocamion;
          this.activosTotalGeneral[5].monto =
            this.activosTotalGeneral[5].monto - this.sumaTractocamion;
        }
      });
      this.activosTotalGeneral[6].eb.forEach((e) => {
        if (e.pp == this.programaPPTractocamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaTractocamion;
          this.activosTotalGeneral[6].monto =
            this.activosTotalGeneral[6].monto - this.sumaTractocamion;
        }
      });
      this.activosTotalGeneral[7].bn.forEach((e) => {
        if (e.pp == this.programaPPTractocamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaTractocamion;
          this.activosTotalGeneral[7].monto =
            this.activosTotalGeneral[7].monto - this.sumaTractocamion;
        }
      });
      this.activosTotalGeneral[8].aems1.forEach((e) => {
        if (e.pp == this.programaPPTractocamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaTractocamion;
          this.activosTotalGeneral[8].monto =
            this.activosTotalGeneral[8].monto - this.sumaTractocamion;
        }
      });
      let c = this.tractocamionProrratear.some((e) => !e.epep);
      if (c) {
        this.tractocamionEstatus = "pendiente";
      } else {
        this.tractocamionEstatus = "finalizado";
        this.programaPPTractocamion = "";
        this.obtenerDatosActivoPorPPTractocamion();
      }
      this.tractomacionSelected = [];
      this.buscarEquiposProrratearTractocamion();
      this.mostrarActivosPorEquipo();
      this.guardarProrrateoLocalStore();
    },

    buscarEquiposProrratearCamion() {
      let total = 0;
      let equipo = "";
      this.CamionEquipoTotalSearch = [];
      let re = new RegExp(this.BuscarCamionParaProrratear, "gi");
      this.camionProrratear.forEach((e) => {
        if (e.activoEquipo.match(re) && !e.epep) {
          total += e.montoEstimado;
          equipo = e.activoEquipo;
        }
      });
      this.CamionEquipoTotalSearch.push({
        equipo,
        total,
      });
    },

    obtenerDatosActivoPorPPCamion() {
      if (this.activosTotalGeneral[0].aems.find((e) => e.pp == this.programaPPCamion)) {
        this.ppSearchDatosCamion = this.activosTotalGeneral[0].aems.find(
          (e) => e.pp == this.programaPPCamion
        );
      } else if (
        this.activosTotalGeneral[1].apapch.find((e) => e.pp == this.programaPPCamion)
      ) {
        this.ppSearchDatosCamion = this.activosTotalGeneral[1].apapch.find(
          (e) => e.pp == this.programaPPCamion
        );
      } else if (
        this.activosTotalGeneral[2].apc.find((e) => e.pp == this.programaPPCamion)
      ) {
        this.ppSearchDatosCamion = this.activosTotalGeneral[2].apc.find(
          (e) => e.pp == this.programaPPCamion
        );
      } else if (
        this.activosTotalGeneral[3].apkmz.find((e) => e.pp == this.programaPPCamion)
      ) {
        this.ppSearchDatosCamion = this.activosTotalGeneral[3].apkmz.find(
          (e) => e.pp == this.programaPPCamion
        );
      } else if (
        this.activosTotalGeneral[4].aplt.find((e) => e.pp == this.programaPPCamion)
      ) {
        this.ppSearchDatosCamion = this.activosTotalGeneral[4].aplt.find(
          (e) => e.pp == this.programaPPCamion
        );
      } else if (
        this.activosTotalGeneral[5].spee.find((e) => e.pp == this.programaPPCamion)
      ) {
        this.ppSearchDatosCamion = this.activosTotalGeneral[5].spee.find(
          (e) => e.pp == this.programaPPCamion
        );
      } else if (
        this.activosTotalGeneral[6].eb.find((e) => e.pp == this.programaPPCamion)
      ) {
        this.ppSearchDatosCamion = this.activosTotalGeneral[6].eb.find(
          (e) => e.pp == this.programaPPCamion
        );
      } else if (
        this.activosTotalGeneral[7].bn.find((e) => e.pp == this.programaPPCamion)
      ) {
        this.ppSearchDatosCamion = this.activosTotalGeneral[7].bn.find(
          (e) => e.pp == this.programaPPCamion
        );
      } else if (
        this.activosTotalGeneral[8].aems1.find((e) => e.pp == this.programaPPCamion)
      ) {
        this.ppSearchDatosCamion = this.activosTotalGeneral[8].aems1.find(
          (e) => e.pp == this.programaPPCamion
        );
      } else if (
        this.activosTotalGeneral[9].aems3.find((e) => e.pp == this.programaPPCamion)
      ) {
        this.ppSearchDatosCamion = this.activosTotalGeneral[9].aems3.find(
          (e) => e.pp == this.programaPPCamion
        );
      }else {
        this.addErrorNotification("Programa presupuestal no encontrado");
        this.ppSearchDatosCamion = [];
      }
    },

    async prorratearCamion() {
      if (!(await this.verificarConexion())) return;
      this.camionProrratear.forEach((e) => {
        this.camionSelected.forEach((f) => {
          if (!e.activoPemex) {
            if (e._id == f._id) {
              e.activoPemex = this.ppSearchDatosCamion.activo;
              e.epep = this.ppSearchDatosCamion.epep;
              e.programaPresupuestal = this.ppSearchDatosCamion.pp;
              e.numeroDeSolicitud = "X";
              e.prorrateado = "SI";
            }
          }
        });
      });
      this.prorratearInDb(this.ppSearchDatosCamion.pp, this.sumaCamion);
      this.totalProrratear = this.totalProrratear - this.sumaCamion;
      /*    const socket = io("https://cma.cicsagruas.com/");
      socket.emit("activosProrratear"); */

      let cmn = [];
      this.camionProrratear.forEach((e) => {
        if (e.activoEquipo == this.BuscarCamionParaProrratear) {
          cmn.push(e);
        }
      });

      const even = cmn.some((e) => e.activoPemex == "");
      if (!even) {
        this.equiposCamionTotales.forEach((e) => {
          if (e.value == this.BuscarCamionParaProrratear) {
            e.color = "#64FFDA";
          }
        });
        this.equiposCamionTotales = this.equiposCamionTotales.sort((a, b) =>
          b.color.localeCompare(a.color)
        );
      }
      this.activosTotalGeneral[0].aems.forEach((e) => {
        if (e.pp == this.programaPPCamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCamion;
          this.activosTotalGeneral[0].monto =
            this.activosTotalGeneral[0].monto - this.sumaCamion;
        }
      });
      this.activosTotalGeneral[1].apapch.forEach((e) => {
        if (e.pp == this.programaPPCamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCamion;
          this.activosTotalGeneral[1].monto =
            this.activosTotalGeneral[1].monto - this.sumaCamion;
        }
      });
      this.activosTotalGeneral[2].apc.forEach((e) => {
        if (e.pp == this.programaPPCamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCamion;
          this.activosTotalGeneral[2].monto =
            this.activosTotalGeneral[2].monto - this.sumaCamion;
        }
      });
      this.activosTotalGeneral[3].apkmz.forEach((e) => {
        if (e.pp == this.programaPPCamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCamion;
          this.activosTotalGeneral[3].monto =
            this.activosTotalGeneral[3].monto - this.sumaCamion;
        }
      });
      this.activosTotalGeneral[4].aplt.forEach((e) => {
        if (e.pp == this.programaPPCamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCamion;
          this.activosTotalGeneral[4].monto =
            this.activosTotalGeneral[4].monto - this.sumaCamion;
        }
      });
      this.activosTotalGeneral[5].spee.forEach((e) => {
        if (e.pp == this.programaPPCamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCamion;
          this.activosTotalGeneral[5].monto =
            this.activosTotalGeneral[5].monto - this.sumaCamion;
        }
      });
      this.activosTotalGeneral[6].eb.forEach((e) => {
        if (e.pp == this.programaPPCamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCamion;
          this.activosTotalGeneral[6].monto =
            this.activosTotalGeneral[6].monto - this.sumaCamion;
        }
      });
      this.activosTotalGeneral[7].bn.forEach((e) => {
        if (e.pp == this.programaPPCamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCamion;
          this.activosTotalGeneral[7].monto =
            this.activosTotalGeneral[7].monto - this.sumaCamion;
        }
      });
      this.activosTotalGeneral[8].aems1.forEach((e) => {
        if (e.pp == this.programaPPCamion) {
          e.montoDesglosado = e.montoDesglosado - this.sumaCamion;
          this.activosTotalGeneral[8].monto =
            this.activosTotalGeneral[8].monto - this.sumaCamion;
        }
      });
      let c = this.camionProrratear.some((e) => !e.epep);
      if (c) {
        this.camionEstatus = "pendiente";
      } else {
        this.camionEstatus = "finalizado";
        this.programaPPCamion = "";
        this.obtenerDatosActivoPorPPCamion();
      }
      this.camionSelected = [];
      this.buscarEquiposProrratearCamion();
      this.mostrarActivosPorEquipo();
      this.guardarProrrateoLocalStore();
    },

    obtenerDatosActivoPorPPcuadrillas() {
      if (
        this.activosTotalGeneral[0].aems.find((e) => e.pp == this.programaPPCuadrilla)
      ) {
        this.ppSearchDatosCuadrilla = this.activosTotalGeneral[0].aems.find(
          (e) => e.pp == this.programaPPCuadrilla
        );
      } else if (
        this.activosTotalGeneral[1].apapch.find((e) => e.pp == this.programaPPCuadrilla)
      ) {
        this.ppSearchDatosCuadrilla = this.activosTotalGeneral[1].apapch.find(
          (e) => e.pp == this.programaPPCuadrilla
        );
      } else if (
        this.activosTotalGeneral[2].apc.find((e) => e.pp == this.programaPPCuadrilla)
      ) {
        this.ppSearchDatosCuadrilla = this.activosTotalGeneral[2].apc.find(
          (e) => e.pp == this.programaPPCuadrilla
        );
      } else if (
        this.activosTotalGeneral[3].apkmz.find((e) => e.pp == this.programaPPCuadrilla)
      ) {
        this.ppSearchDatosCuadrilla = this.activosTotalGeneral[3].apkmz.find(
          (e) => e.pp == this.programaPPCuadrilla
        );
      } else if (
        this.activosTotalGeneral[4].aplt.find((e) => e.pp == this.programaPPCuadrilla)
      ) {
        this.ppSearchDatosCuadrilla = this.activosTotalGeneral[4].aplt.find(
          (e) => e.pp == this.programaPPCuadrilla
        );
      } else if (
        this.activosTotalGeneral[5].spee.find((e) => e.pp == this.programaPPCuadrilla)
      ) {
        this.ppSearchDatosCuadrilla = this.activosTotalGeneral[5].spee.find(
          (e) => e.pp == this.programaPPCuadrilla
        );
      } else if (
        this.activosTotalGeneral[6].eb.find((e) => e.pp == this.programaPPCuadrilla)
      ) {
        this.ppSearchDatosCuadrilla = this.activosTotalGeneral[6].eb.find(
          (e) => e.pp == this.programaPPCuadrilla
        );
      } else if (
        this.activosTotalGeneral[7].bn.find((e) => e.pp == this.programaPPCuadrilla)
      ) {
        this.ppSearchDatosCuadrilla = this.activosTotalGeneral[7].bn.find(
          (e) => e.pp == this.programaPPCuadrilla
        );
      } else if (
        this.activosTotalGeneral[8].aems1.find((e) => e.pp == this.programaPPCuadrilla)
      ) {
        this.ppSearchDatosCuadrilla = this.activosTotalGeneral[8].aems1.find(
          (e) => e.pp == this.programaPPCuadrilla
        );
      } else if (
        this.activosTotalGeneral[9].aems3.find((e) => e.pp == this.programaPPCuadrilla)
      ) {
        this.ppSearchDatosCuadrilla = this.activosTotalGeneral[9].aems3.find(
          (e) => e.pp == this.programaPPCuadrilla
        );
      }  else {
        this.addErrorNotification("Programa presupuestal no encontrado");
        this.ppSearchDatosCuadrilla = [];
      }
    },

    prorrateoDesglosado() {
      let costeoDetalle = this.trazabilidadSinRegistrosTeb;
      this.prorrateoTables = costeoDetalle;
      let epepT = [];
      costeoDetalle.forEach((e) => {
        if (e.epep) {
          if (!Object.prototype.hasOwnProperty.call(epepT, e.epep)) {
            epepT[e.epep] = {
              epep: [],
              montoTotal: 0,
              total: e.montoEstimado,
              equipo: [],
            };
          }

          epepT[e.epep].epep.push({
            datosEpep: e.epep,
            monto: e.montoEstimado,
            pp: e.programaPresupuestal,
            equipo: e.activoEquipo,
            activo: e.activoPemex,
          });

          epepT[e.epep].montoTotal += e.montoEstimado;

          if (!epepT[e.epep].equipo.includes(e.activoEquipo)) {
            epepT[e.epep].equipo.push(e.activoEquipo);
          }
        }
      });

      epepT = Object.entries(epepT);
      epepT.forEach((e) => {
        e[2] = e[1].epep[0].activo;
        e[3] = e[1].epep[0].pp;
        e[4] = e[1].montoTotal;
        e[5] = e[1].total;
        e[6] = e[1].equipo.join(" - ");
      });
      let activosDesgloseProrrateo = [];
      activosDesgloseProrrateo.aems = [];
      activosDesgloseProrrateo.apapch = [];
      activosDesgloseProrrateo.apc = [];
      activosDesgloseProrrateo.apkmz = [];
      activosDesgloseProrrateo.aplt = [];
      activosDesgloseProrrateo.spee = [];
      activosDesgloseProrrateo.apk_ek_blm = [];
      activosDesgloseProrrateo.aems1 = [];

      epepT.forEach((e) => {
        if (e[2] === "AEMS") {
          activosDesgloseProrrateo.aems.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: e[4],
            monto_total: e[5],
            equipo: e[6],
          });
        } else if (e[2] == "APAPCH") {
          activosDesgloseProrrateo.apapch.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: e[4],
            monto_total: e[5],
            equipo: e[6],
          });
        } else if (e[2] == "APC") {
          activosDesgloseProrrateo.apc.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: parseFloat(e[4]),
            monto_total: parseFloat(e[5]),
            equipo: e[6],
          });
        } else if (e[2] == "APKMZ") {
          activosDesgloseProrrateo.apkmz.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: parseFloat(e[4]),
            monto_total: parseFloat(e[5]),
            equipo: e[6],
          });
        } else if (e[2] == "APLT") {
          activosDesgloseProrrateo.aplt.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: parseFloat(e[4]),
            monto_total: parseFloat(e[5]),
            equipo: e[6],
          });
        } else if (e[2] == "SPEE") {
          activosDesgloseProrrateo.spee.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: parseFloat(e[4]),
            monto_total: parseFloat(e[5]),
            equipo: e[6],
          });
        } else if (e[2] == "APC-EK-BALAM") {
          activosDesgloseProrrateo.apk_ek_blm.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: parseFloat(e[4]),
            monto_total: parseFloat(e[5]),
            equipo: e[6],
          });
        } else if (e[2] === "AEMS1") {
          activosDesgloseProrrateo.aems1.push({
            activo: e[2],
            pp: e[3],
            epep: e[0],
            monto_usado: parseFloat(e[4]),
            monto_total: parseFloat(e[5]),
            equipo: e[6],
          });
        }
      });
      this.prorrateoTables = activosDesgloseProrrateo;
    },
    async eliminarActivosDesglsados() {
      await api.deleteAllActivosDesglosados(this.mesServicio).then((e) => {
        console.log(e.statusText);
        /*  this.$store.dispatch("guardarCuadrillas", []) */
        localStorage.removeItem("cuadrillas");
        this.$store.dispatch("guardarTraz", []);
        this.$store.dispatch("guardarIdTraz", "");
        this.$store.dispatch("guardarActivosGeneral", []);
        this.$store.dispatch("guardarTeb", []);
        this.$store.dispatch("guardarFs", []);
        this.$store.dispatch("mes", "");
      });
      this.$router.push("costeo").catch((err) => {
        console.log(err);
      });
    },
    limpiarTotalesGruas() {
      this.BuscarGruasParaProrratear = "";
    },
    async prorratearInDb(pp, total) {
      await api.prorratearInDb(pp, this.mesServicio, total).then((e) => {
        console.log(e.statusText);
      });
    },
    async listarActivosProrrateo(mes) {
      await api.listar(mes).then((e) => {
        console.log(e)
        let activosDesg = e.data;
        let aems = activosDesg.filter((e) => e.activo == "AEMS");
        let apapch = activosDesg.filter((e) => e.activo == "APAPCH");
        let apc = activosDesg.filter((e) => e.activo == "APC");
        let apkmz = activosDesg.filter((e) => e.activo == "APKMZ");
        let aplt = activosDesg.filter((e) => e.activo == "APLT");
        let spee = activosDesg.filter((e) => e.activo == "SPEE");
        let apc_ek_blm = activosDesg.filter((e) => e.activo == "APC-EK-BALAM");
        let bn = activosDesg.filter((e) => e.activo == "BLOQUE NORTE");
        let aems1 = activosDesg.filter((e) => e.activo == "AEMS1");
        let aems3 = activosDesg.filter((e) => e.activo == "AEMS3");
        let activosTotal = {
          aems: aems,
          apapch: apapch,
          apc: apc,
          apkmz,
          aplt,
          spee,
          apc_ek_blm,
          bn,
          aems1,
          aems3,
        };
        let sumAems = aems.reduce((acc, o) => acc + o.total, 0);
        let sumApapch = apapch.reduce((acc, o) => acc + o.total, 0);
        let sumapc = apc.reduce((acc, o) => acc + o.total, 0);
        let sumApkmz = apkmz.reduce((acc, o) => acc + o.total, 0);
        let sumAplt = aplt.reduce((acc, o) => acc + o.total, 0);
        let sumSpee = spee.reduce((acc, o) => acc + o.total, 0);
        let sumApc_ek_blam = apc_ek_blm.reduce((acc, o) => acc + o.total, 0);
        let sumBn = bn.reduce((acc, o) => acc + o.total, 0);
        let sumAems1 = aems1.reduce((acc, o) => acc + o.total, 0);
        let sumAems3 = aems3.reduce((acc, o) => acc + o.total, 0);

        let totalesActivosProrratear = {
          sumAems,sumApapch,sumapc,sumApkmz,sumAplt,sumSpee, sumApc_ek_blam, sumBn, sumAems1, sumAems3
        }
        console.log(activosTotal)
        this.activosProrratearSocket = activosTotal;
        this.totalesActivosProrratear = totalesActivosProrratear;
      });
    },
    mostrarActivosProrratearSocket() {
      this.listarActivosProrrateo(this.mesServicio);

      this.dialogActivosProrratearSocket = true;
    },
    pruebaSocket() {
      const socket = io("https://cma.cicsagruas.com/");
      socket.on("server:response", (data) => {
        this.activosProrratearSocket = data;
      });
    },
    async listarActivosTodos() {
      await api.listar(this.mesServicio).then((e) => {
        this.activosTodos = e.data;
      });
    },
    mostrarProrrateoEquiposTab(equipo, activo) {
      const busqueda = [];
      this.activosProrrateadosSelected = [];
      this.trazabilidadRegistros.forEach((e) => {
        if (
          e.activoPemex == activo &&
          e.activoEquipo == equipo &&
          e.prorrateado == "SI"
        ) {
          busqueda.push(e);
        }
      });
      this.dialogEquiposProrrateados = true;
      console.log(busqueda);
      this.activosProrrateadosDesglosar = busqueda;
    },
    async verificarConexion() {
      let resp = null;
      await api
        .conexion()
        .then(() => {
          resp = true;
        })
        .catch(() => {
          this.addWarningNotification("Sin internet, verifica tu conexión");
          resp = false;
        });
      return resp;
    },

    async limpiarProrrateEquiposTab() {
      this.porcentajeMontoTotalCalculo();
      await this.listarActivosTodos();
      for (const activos of this.activosProrrateadosSelected) {
        if (!(await this.verificarConexion())) return;
        this.dialogLoading = true;
        let busqueda = this.trazabilidadRegistros.findIndex((g) => g._id == activos._id);
        let activoBusqueda = this.activosTodos.find(
          (e) => e.pp == this.trazabilidadRegistros[busqueda].programaPresupuestal
        );
        this.limpiarTab(this.trazabilidadRegistros[busqueda]);
        let total =
          activoBusqueda.total + this.trazabilidadRegistros[busqueda].montoEstimado;
        this.trazabilidadRegistros[busqueda].activoPemex = "";
        this.trazabilidadRegistros[busqueda].epep = "";
        this.trazabilidadRegistros[busqueda].programaPresupuestal = "";
        delete this.trazabilidadRegistros[busqueda].prorrateado;
        await api
          .limpiarInDb(activoBusqueda.pp, this.mesServicio, total)
          .then(async () => {
            this.dialogLoading = false;
            await this.listarActivosTodos();
            this.equiposGruaTotales = this.equiposGruaTotales.sort(
              (a, b) => b.total - a.total
            );
          });
      }

      this.porcentajeMontoTotalCalculo();

      this.guardarProrrateoLocalStore();
      this.dialogEquiposProrrateados = false;
      this.validarProrrateadosCompleto();
    },
    pruebaG() {
      const busqueda = this.gruasProrratear.find(
        (e) => e.activoEquipo == "GRÚA TERRESTRE G-36"
      );
      console.log(busqueda);
    },
    validarProrrateadosCompleto() {
      this.equiposGruaTotales.forEach((f) => {
        const even = this.trazabilidadRegistros.some(
          (e) => e.activoPemex == "" && e.activoEquipo == f.value
        );
        even ? (f.color = "white") : (f.color = "#64FFDA");
      });
      this.equiposGruaTotales = this.equiposGruaTotales.sort((a, b) =>
        b.color.localeCompare(a.color)
      );
      this.equiposCGTotales.forEach((f) => {
        const even = this.trazabilidadRegistros.some(
          (e) => e.activoPemex == "" && e.activoEquipo == f.value
        );
        even ? (f.color = "white") : (f.color = "#64FFDA");
      });
      this.equiposCGTotales = this.equiposCGTotales.sort((a, b) =>
        b.color.localeCompare(a.color)
      );
      this.equiposCGTotales.forEach((f) => {
        const even = this.trazabilidadRegistros.some(
          (e) => e.activoPemex == "" && e.activoEquipo == f.value
        );
        even ? (f.color = "white") : (f.color = "#64FFDA");
      });
      this.equiposCGTotales = this.equiposCGTotales.sort((a, b) =>
        b.color.localeCompare(a.color)
      );
      this.equiposMontacargasTotales.forEach((f) => {
        const even = this.trazabilidadRegistros.some(
          (e) => e.activoPemex == "" && e.activoEquipo == f.value
        );
        even ? (f.color = "white") : (f.color = "#64FFDA");
      });
      this.equiposMontacargasTotales = this.equiposMontacargasTotales.sort((a, b) =>
        b.color.localeCompare(a.color)
      );
      this.equiposTractocamionTotales.forEach((f) => {
        const even = this.trazabilidadRegistros.some(
          (e) => e.activoPemex == "" && e.activoEquipo == f.value
        );
        even ? (f.color = "white") : (f.color = "#64FFDA");
      });
      this.equiposTractocamionTotales = this.equiposTractocamionTotales.sort((a, b) =>
        b.color.localeCompare(a.color)
      );
      this.equiposCamionTotales.forEach((f) => {
        const even = this.trazabilidadRegistros.some(
          (e) => e.activoPemex == "" && e.activoEquipo == f.value
        );
        even ? (f.color = "white") : (f.color = "#64FFDA");
      });
      this.equiposCamionTotales = this.equiposCamionTotales.sort((a, b) =>
        b.color.localeCompare(a.color)
      );
    },
  },
};
</script>
<style>
.pendiente {
  background-color: #ffc897;
}
.finalizado {
  background-color: #75c9a3;
}
.button-fab-1 {
  bottom: 50px;
  height: 60px;
  position: fixed;
  right: 20px;
  width: 60px;
}
.button-fab-2 {
  bottom: 50px;
  height: 60px;
  position: fixed;
  right: 90px;
  width: 60px;
}
.button-fab-3 {
  bottom: 50px;
  height: 60px;
  position: fixed;
  right: 160px;
  width: 60px;
}
</style>
