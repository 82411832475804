<template>
  <v-container fluid>
    <v-overlay :value="loadingTable">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-bottom-sheet v-model="sheetHorometro">
      <v-sheet height="100%">
        <div class="py-3">
          <v-card class="mx-auto"
            ><v-card-text>
              <v-row>
                <v-col cols="10" sm="10" lg="10">
                  <h2 style="text-align: left">Fuera de Servicio</h2>
                </v-col>
              </v-row>

              <p style="text-align: left">
                Fechas y equipos registrados en fuera de servicio
              </p>
              <v-row>
                <v-col cols="12" sm="12" lg="12">
                  <v-list
                    subheader
                    two-line
                    style="height: 400px; overflow-y: scroll"
                  >
                    <v-list-item v-for="item in equiposFs" :key="item._id">
                      <v-icon dense color="red darken-2" class="pr-6">
                        mdi-checkbox-blank-circle
                      </v-icon>

                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <p class="font-weight-black">
                            Equipo {{ item.activoEquipo }}
                          </p>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          >Fecha :{{ item.fechaServicio }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <div class="text-center">
                  <v-progress-circular
                    v-if="loadingTable"
                    :size="50"
                    indeterminate
                    color="red"
                  ></v-progress-circular>
                </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <v-bottom-sheet v-model="sheetMtto">
      <v-sheet height="100%">
        <div class="py-3">
          <v-card class="mx-auto"
            ><v-card-text>
              <v-row>
                <v-col cols="10" sm="10" lg="10">
                  <h2 style="text-align: left">Equipos en Mtto</h2>
                </v-col>
              </v-row>

              <p style="text-align: left">
                Fechas y equipos registrados en mantenimiento
              </p>
              <v-row>
                <v-col cols="12" sm="12" lg="12">
                  <v-list
                    subheader
                    two-line
                    style="height: 400px; overflow-y: scroll"
                  >
                    <v-list-item v-for="item in equiposMtto" :key="item._id">
                      <v-icon dense color="orange darken-2" class="pr-6">
                        mdi-checkbox-blank-circle
                      </v-icon>

                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <p class="font-weight-black">
                            Equipo {{ item.activoEquipo }}
                          </p>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          >Fecha :{{ item.fechaServicio }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <div class="text-center">
                  <v-progress-circular
                    v-if="loadingTable"
                    :size="50"
                    indeterminate
                    color="red"
                  ></v-progress-circular>
                </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <v-bottom-sheet v-model="sheetJornadas">
      <v-sheet height="100%">
        <div class="py-3">
          <v-card class="mx-auto"
            ><v-card-text>
              <v-row>
                <v-col cols="10" sm="10" lg="10">
                  <h2 style="text-align: left">Totales</h2>
                </v-col>
              </v-row>

              <p style="text-align: left">
                Monto total de equipos registrados en jornada y disponibiladad
              </p>
              <v-row>
                <v-col cols="12" sm="12" lg="12">
                  <v-list
                    subheader
                    two-line
                    style="height: 400px; overflow-y: scroll"
                  >
                    <v-list-item v-for="item in equiposJD" :key="item._id">
                      <v-icon dense color="green darken-2" class="pr-6">
                        mdi-checkbox-blank-circle
                      </v-icon>

                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <p class="font-weight-black">Equipo {{ item[0] }}</p>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          >Total :
                          <b>
                            ${{ costoToDollar(item[1].montoTotal) }}</b
                          ></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <div class="text-center">
                  <v-progress-circular
                    v-if="loadingTable"
                    :size="50"
                    indeterminate
                    color="red"
                  ></v-progress-circular>
                </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <div class="tables-basic">
      <br /><br />
      <h1 class="page-title mt-15 mb-1">Dashboard Costeo</h1>

      <div>
        <div class="pa-1">
          <div class="pa-3">
            <div class="my-5">
              <v-row>
                <v-col lg="3" sm="6" md="7" cols="12">
                  <br />
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        style="margin-top: -30px"
                        dense
                        v-model="mesServicio"
                        label="Mes"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                    locale="es"
                      v-model="mesServicio"
                      type="month"
                      @input="trazabilidadMes"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="3" sm="6" md="7" cols="12">
                  <v-card max-width="500">
                    <v-sheet
                      class="v-sheet--offset mx-auto"
                      color="primary"
                      elevation="12"
                    >
                      <div
                        class="text-h6 font-weight-light white--text"
                        style="text-align: center"
                      >
                        Monto Total
                      </div>
                    </v-sheet>
                    <br />
                    <v-card-text class="pt-0">
                      <div
                        class="subheading font-weight-light grey--text"
                        style="text-align: center"
                      >
                        <h2>
                          ${{ costoToDollar(parseFloat(total).toFixed(2)) }}
                        </h2>
                      </div>
                      <v-divider class="my-2"></v-divider>
                      <v-icon class="mr-2"> mdi-cash </v-icon>
                      <span class="text-caption grey--text"
                        >Moneda Nacional</span
                      >
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col lg="3" sm="6" md="7" cols="12">
                  <v-card max-width="500">
                    <v-sheet
                      class="v-sheet--offset mx-auto"
                      color="primary"
                      elevation="12"
                    >
                      <div
                        class="text-h6 font-weight-light white--text"
                        style="text-align: center"
                      >
                        Fuera de Servicio
                      </div>
                    </v-sheet>
                    <br />
                    <v-card-text class="pt-0">
                      <div
                        class="subheading font-weight-light grey--text"
                        style="text-align: center"
                      >
                        <h2>
                          ${{
                            costoToDollar(parseFloat(fueraServicio).toFixed(2))
                          }}
                        </h2>
                      </div>
                      <v-divider class="my-2"></v-divider>
                      <v-icon @click="listarFueraDeServicio()" class="mr-2">
                        mdi-database-eye
                      </v-icon>
                      <span class="text-caption grey--text">Ver detalles</span>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col lg="3" sm="6" md="7" cols="12">
                  <v-card max-width="500">
                    <v-sheet
                      class="v-sheet--offset mx-auto"
                      color="primary"
                      elevation="12"
                    >
                      <div
                        class="text-h6 font-weight-light white--text"
                        style="text-align: center"
                      >
                        Mantenimiento
                      </div>
                    </v-sheet>
                    <br />
                    <v-card-text class="pt-0">
                      <div
                        class="subheading font-weight-light grey--text"
                        style="text-align: center"
                      >
                        <h2>
                          ${{ costoToDollar(parseFloat(mtto).toFixed(2)) }}
                        </h2>
                      </div>
                      <v-divider class="my-2"></v-divider>
                      <v-icon @click="listarMtto()" class="mr-2">
                        mdi-database-eye
                      </v-icon>
                      <span class="text-caption grey--text">Ver detalles</span>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col lg="3" sm="6" md="7" cols="12">
                  <v-card max-width="500">
                    <v-sheet
                      class="v-sheet--offset mx-auto"
                      color="primary"
                      elevation="12"
                    >
                      <div
                        class="text-h6 font-weight-light white--text"
                        style="text-align: center"
                      >
                        Jornada y disponibilidad
                      </div>
                    </v-sheet>
                    <br />
                    <v-card-text class="pt-0">
                      <div
                        class="subheading font-weight-light grey--text"
                        style="text-align: center"
                      >
                        <h2>
                          ${{ costoToDollar(parseFloat(jor_dis).toFixed(2)) }}
                        </h2>
                      </div>
                      <v-divider class="my-2"></v-divider>
                      <v-icon @click="listarJornadas()" class="mr-2">
                        mdi-database-eye
                      </v-icon>
                      <span class="text-caption grey--text">Ver detalles</span>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-card
                    class="mx-auto text-center"
                    
                    color="white"
                    max-width="2900"
                  >
                    <v-card-text>
                      <v-sheet color="secondary">
                        <GraficaLine :chart-data="chartDataTotalMeses"/>
                      </v-sheet>
                    </v-card-text>

               
                    <v-divider></v-divider>

                    <v-card-actions class="justify-center">
                      <v-btn block text> Montos del contrato en curso</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="5" lg="5">
                  <v-card
                    class="mx-auto text-center"
                    dark
                    color="SECONDARY"
                    max-width="800"
                  >
                    <v-card-text>
                      <v-sheet color="rgba(0, 0, 0, .12)">
                        <GraficaDona :chartData="chartDataEquipos" />
                      </v-sheet>
                    </v-card-text>


                    <v-divider></v-divider>

                    <v-card-actions class="justify-center">
                      <v-btn block text> Montos Totales por Equipos</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="5" lg="5">
                  <v-card
                    class="mx-auto text-center"
                    dark
                    color="primar"
                    max-width="800"
                  >
                    <v-card-text>
                      <v-sheet color="rgba(0, 0, 0, .12)">
                        <GraficaDona :chartData="chartDataUbicacion" />
                      </v-sheet>
                    </v-card-text>

                  

                    <v-divider></v-divider>

                    <v-card-actions class="justify-center">
                      <v-btn block text> Montos Totales por Ubicación</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-card width="400">
                    <v-card-text>
                      <div class="font-weight-bold ml-8 mb-2">
                        Monto por tipo de Equipos
                      </div>

                      <v-timeline align-top dense>
                        <v-timeline-item
                          v-for="(item, index) in tipoEquipoTotales"
                          :key="index"
                          color="#3b5274"
                          small
                        >
                          <div>
                            <div class="font-weight-normal">
                              <strong>{{ item.equipo }}</strong>
                            </div>
                            <div>${{ costoToDollar(item.total) }}</div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </v-card-text>
                  </v-card>
                </v-col>
              
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Costeo from "../../api/Costeo";
import moment from "moment";
import GraficaDona from "../../components/Graficas/GraficaDona.vue";
import GraficaLine from "../../components/Graficas/GraficaLine.vue"

export default {
  components: {
    GraficaDona,
    GraficaLine
  },
  data() {
    return {
      moment,
      tipoEquipoTotales: [],

      mesServicio: "",
      menu2: false,
      fueraServicio: 0,
      mtto: 0,
      jor_dis: 0,
      total: 0,
      loadingTable: false,
      sheetHorometro: false,
      equiposFs: [],
      sheetMtto: false,
      equiposMtto: [],
      sheetJornadas: false,
      equiposJD: [],
      chartDataEquipos: {
        labels: [],
        datasets: [
          {
            backgroundColor: [
              "#230f2b",
              "#f21d41",
              "#840943",
              "#322c8e",
              "#3b8952",
              "#3b8952",
            ],
            data: [],
          },
        ],
      },


      chartDataUbicacion: {
        labels: [],
        datasets: [
          {
            backgroundColor: [
              "#230f2b",
              "#f21d41",
              "#840943",
              "#322c8e",
              "#3b8952",
              "#3b8952",
            ],
            data: [],
          },
        ],
      },

      chartDataTotalMeses: {
        labels: ['Octubre',
          'Noviembre',
          'Diciemnbre',
          'Enero',
          'Febrero',
          'Marzo',
          'Abril'],
        datasets: [
          
          {
            borderColor: "green",
           
            backgroundColor: [
             
              "#72ae95",
            ],
            data: [],
          },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.trazabilidadMes();
  },
  methods: {
    trazabilidadMes() {
      this.menu2 = false;
      this.loadingTable = true;
      this.listarMontosTotales()
      Costeo.listarTrazabilidadDashboard(this.mesServicio)
        .then((e) => {
          this.fueraServicio = e.data[0].fuera_servicio;
          this.mtto = e.data[0].mtto;
          this.jor_dis = e.data[0].jor_dis;
          this.total = e.data[0].total;
          let equipos = [
            { total: 0, equipo: "GRUAS" },
            { total: 0, equipo: "MONTACARGAS" },
            { total: 0, equipo: "CAMION CON GRÚA" },
            { total: 0, equipo: "TRACTOCAMIÓN" },
            { total: 0, equipo: "CARGADORES FRONTALES" },
            { total: 0, equipo: "CUADRILLAS" },
          ];
          e.data[1].forEach((e) => {
            if (e[0].substring(0, 2) == "G-") {
              equipos[0].total += e[1].montoTotal;
            } else if (e[0].substring(0, 4) == "MTC-") {
              equipos[1].total += e[1].montoTotal;
            } else if (e[0].substring(0, 2) == "M-") {
              equipos[2].total += e[1].montoTotal;
            } else if (e[0].substring(0, 2) == "T-") {
              equipos[3].total += e[1].montoTotal;
            } else if (e[0].substring(0, 4) == "CFG-") {
              equipos[4].total += e[1].montoTotal;
            } else if (
              e[0].substring(0, 4) == "CABO" ||
              e[0].substring(0, 4) == "MANI"
            ) {
              equipos[5].total += e[1].montoTotal;
            }
          });

          let labelsGrafica = [];
          let totalesGrafica = [];
          let totalesTipoEquipoLine = [];
          equipos.forEach((e) => {
            if (e.total > 0) {
              labelsGrafica.push(e.equipo);
              totalesGrafica.push(e.total.toFixed(2));
              totalesTipoEquipoLine.push({
                total: e.total,
                equipo: e.equipo,
              });
            }
          });

          let graficaLabelsUbicacion = [];
          let graficaDataUbicacion = [];
          e.data[2].forEach((e) => {
            graficaLabelsUbicacion.push(e[0]);
            graficaDataUbicacion.push(e[1].montoTotal);
          });
          this.chartDataEquipos.labels = labelsGrafica;
          this.chartDataEquipos.datasets[0].data = totalesGrafica;
          this.tipoEquipoTotales = totalesTipoEquipoLine;
          this.chartDataUbicacion.labels = graficaLabelsUbicacion;
          this.chartDataUbicacion.datasets[0].data = graficaDataUbicacion;
        })
        .catch((err) => {
          this.addErrorNotification("Mes sin registros");
          console.log(err);
          this.fueraServicio = 0;
          this.mtto = 0;
          this.jor_dis = 0;
          this.total = 0;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    async listarTrazabilidadDashboard() {
      await Costeo.listarTrazabilidadDashboard(this.mesServicio)
        .then((e) => {
          console.log(e.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async listarFueraDeServicio() {
      this.sheetHorometro = true;
      this.loadingTable = true;
      this.equiposFs = [];
      await Costeo.listarTrazabilidadDashboardFs(this.mesServicio)
        .then((e) => {
          this.equiposFs = e.data;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    async listarMtto() {
      this.sheetMtto = true;
      this.loadingTable = true;
      this.equiposMtto = [];
      await Costeo.dashboardCosteoEquiposMtto(this.mesServicio)
        .then((e) => {
          this.equiposMtto = e.data;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    async listarJornadas() {
      this.loadingTable = true;
      this.sheetJornadas = true;
      this.equiposJD = [];
      await Costeo.dashboardCosteoEquiposJornada(this.mesServicio)
        .then((e) => {
          this.equiposJD = e.data;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    async listarMontosTotales(){
      Costeo.dashboardTotalMeses().then((e)=>{
        let totalesMeses = []
        e.data.forEach((e)=>{
          totalesMeses.push(e.total)
        })
        this.chartDataTotalMeses.datasets[0].data = totalesMeses
      }).catch((err)=>{
        console.log(err)
      })
    },
    addErrorNotification(mensaje) {
      this.$toast.error("Error," + mensaje, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },

    costoToDollar(text) {
      let number = parseFloat(text);
      return number.toLocaleString("en-US");
    },
  },
};
</script>
<style scoped></style>
