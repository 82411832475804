<template>
    <div ref="chart" :style="{ width: '100%', height: height }"></div>
</template>
  
<script>
import * as echarts from 'echarts';

export default {
    props: {
        chartTitle: String,
        fechas: Array,
        data: Array,
        height: String,
    },
    mounted() {
        // Inicializa ECharts y crea el gráfico
        const chart = this.$refs.chart;
        const myChart = echarts.init(chart);
        const colors = [
            '#ff7070ff',  // Rojo
            '#91cc75',    // Verde
            '#5c7bd9ff',  // Azul
            '#ffcc6b',    // Naranja
            '#6f6b76',    // Gris
            '#c17f89',    // Rosa
            '#3a9d9c',    // Turquesa
            '#c7a4ff',    // Lila
        ];

        const option = {
            title: {
                x: 'center',
            },
            xAxis: {
                type: 'category',
                data: this.fechas
            },
            yAxis: {
                type: 'value'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c}',
            },
            // legend: {
            //     orient: 'horizontal',
            //     left: 10,
            //     // data: this.data.map(item => item.name),
            // },

            color: colors,
            series: [
                {
                    name: this.chartTitle,
                    type: 'line',
                    smooth: true,
                    radius: '65%',
                    center: ['50%', '60%'],
                    data: this.data,
                    label: {
                        formatter: function (params) {
                            return `${params.name} (Total: ${params.value})`;
                        }
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 10,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ],
        };

        // Establece los datos en el gráfico
        myChart.setOption(option);

    },
};
</script>



