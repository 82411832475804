const fecha = (fecha)=>{
    let mes = ''
    let inicio = (fecha[0]).split('-')
    let fin = (fecha[1]).split('-')
    switch(new Date(fecha[1]).getMonth()){
        case 0:
            mes='Enero'
            break;
        case 1:
            mes='Febrero'
            break;
        case 2:
            mes='Marzo'
            break;
        case 3:
            mes='Abril'
            break;
        case 4:
            mes='Mayo'
            break;
        case 5:
            mes='Junio'
            break;
        case 6:
            mes='Julio'
            break;
        case 7:
            mes='Agosto'
            break;
        case 8:
            mes='Septiembre'
            break;
        case 9:
            mes="Octubre"
            break
        case 10:
            mes='Noviembre'
            break;
        case 11:
            mes='Diciembre'
            break

    }
    let periodo = `Del ${inicio[2]} al ${fin[2]} de ${mes} del ${new Date(fecha[1]).getFullYear()}`
    return periodo
} 
const fechaUnDia = (fecha)=>{
    let mes = ''
    let inicio = (fecha[0]).split('-')
   /*  let fin = (fecha[1]).split('-') */
    switch(new Date(fecha[1]).getMonth()){
        case 0:
            mes='Enero'
            break;
        case 1:
            mes='Febrero'
            break;
        case 2:
            mes='Marzo'
            break;
        case 3:
            mes='Abril'
            break;
        case 4:
            mes='Mayo'
            break;
        case 5:
            mes='Junio'
            break;
        case 6:
            mes='Julio'
            break;
        case 7:
            mes='Agosto'
            break;
        case 8:
            mes='Septiembre'
            break;
        case 9:
            mes="Octubre"
            break
        case 10:
            mes='Noviembre'
            break;
        case 11:
            mes='Diciembre'
            break

    }
    let periodo = `Del ${inicio[2]} de ${mes} del ${new Date(fecha[1]).getFullYear()}`
    return periodo
}

export default {fecha,fechaUnDia}