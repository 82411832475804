<template>
  <v-container fluid class="pa-6 ma-2">
    <div class="dashboard-page">
      <br />

      <v-row no-gutters class="d-flex justify-space-between mt-16 mb-6">
      </v-row>
    </div>
    <v-row>
      <v-col lg="12" sm="12" md="12" cols="12">
        <v-card class="mx-1 mb-10">
          <v-card-title class="primary white--text text-h5">
            Trabajos Realizados
          </v-card-title>
          <v-card-text class="pa-3 pt-0">
            <v-row class="pa-4" justify="space-between">
              <v-col cols="3">
               
               
    
  
                <v-treeview
                  rounded
                  hoverable
                  :search="search"
       
                  :active.sync="activeS"
               
                  v-model="idSelect"
                
                  activatable
                  :items="reportes"
                  :open.sync="open"
                >
                  <template v-slot:prepend="{ item }">
                    <div v-if="!item.children">
                      <v-col class="">
                        <div>
                          <v-icon
                            v-if="
                              item.objetosAgrupados[0].estatus_equipo ==
                              'Operativo'
                            "
                            color="green"
                          >
                            mdi-circle
                          </v-icon>
                          <v-icon
                            v-if="
                              item.objetosAgrupados[0].estatus_equipo ==
                              'Fuera de Servicio'
                            "
                            color="red"
                          >
                            mdi-circle
                          </v-icon>
                          <span
                            class="ml-4"
                            style="font-size: 14px; font-weight: bold"
                            >{{ item.numeroEconomico }}</span
                          >
                        </div>
                      </v-col>
                    </div>
                  </template>
                </v-treeview>
              </v-col>

              <v-divider vertical></v-divider>
              <v-col>
                <v-scroll-y-transition mode="out-in">
                  <div
                    v-if="!selected"
                    class="text-h6 grey--text text--lighten-1 font-weight-light"
                  >
                    Selecciona un equipo para ver sus actividades
                  </div>
                  <v-card
                    v-else
                    :key="selected.id"
                    class="pt-6"
                    flat
                    full-width="1800"
                  >
                    <v-card-text>
                      <h3 class="text-h5 text-center mb-2">
                        Reportes Generados
                      </h3>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-row class="text-left" tag="v-card-text">
                      <v-col>
                        <v-expansion-panels>
                          <v-expansion-panel
                            v-for="(item, i) in selected.objetosAgrupados"
                            :key="i"
                          >
                            <v-expansion-panel-header disable-icon-rotate>
                              <span class="styled-span">
                                {{
                                  moment(item.fechaFalla)
                                    .add(1, "days")
                                    .format("Do MMMM YYYY")
                                }}
                                /// Reporte de falla con folio #{{
                                  item.folio_falla
                                }}
                              </span>
                              <template v-slot:actions>
                                <v-chip
                                  v-if="item.estatus == 'En revisión'"
                                  small
                                  class="ma-2"
                                  color="orange"
                                >
                                  {{ item.estatus }}
                                </v-chip>
                                <v-chip
                                  v-if="item.estatus == 'Pendiente'"
                                  small
                                  class="ma-2"
                                  color="yellow"
                                >
                                  {{ item.estatus }}
                                </v-chip>
                                <v-chip
                                  v-if="item.estatus == 'Asignado'"
                                  small
                                  dark
                                  class="ma-2"
                                  color="purple"
                                >
                                  {{ item.estatus }}
                                </v-chip>
                                <v-chip
                                  v-if="item.estatus == 'Cerrado'"
                                  small
                                  dark
                                  class="ma-2"
                                  color="green"
                                >
                                  {{ item.estatus }}
                                </v-chip>
                              </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <span class="styled-description">
                                <b>Descripción de la falla: </b>
                                {{ item.descripcionFalla }} <br />
                                <b>Tipo de falla: </b> {{ item.tipoFalla }}
                                <br />
                                <b>Prioridad: </b> {{ item.prioridad }} <br />
                                <div v-if="item.estatus == 'Asignado' || item.estatus=='Pendiente'">
                                  <b>Asignado a: </b>
                                  {{ item.mecanico.toString() }} <br />
                                </div>
                                <b>Diagnostico: </b> {{ item.diagnostico }}
                                <br />
                                <br />
                                <div v-if="item.ordenes_trabajo.length>0">
                                  <v-toolbar color="primary" dense dark>
                                    <v-toolbar-title
                                      >Ordenes de Trabajo</v-toolbar-title
                                    >
                                  </v-toolbar>

                                  <v-list two-line>
                                    <v-list-item-group
                                      active-class="pink--text"
                                    >
                                      <template
                                        v-for="(
                                          ot, index
                                        ) in item.ordenes_trabajo"
                                      >
                                        <v-list-item :key="index">
                                          <template>
                                            <v-list-item-content>
                                              <v-list-item-title
                                                > <span class="styled-description">
                                                  <b>Trabajo a realizar: </b>{{ot.trabajos}} <br>
                                                  <b>Tipo de falla: </b>{{ot.tipo_falla}} <br>
                                                  <b>Estatus: </b>{{ot.estatus}} <br>
                                                  </span>
                                                </v-list-item-title
                                              >
                                              <v-list-item-subtitle
                                                >{{  }}
                                                </v-list-item-subtitle
                                              >
                                            </v-list-item-content>

                                            <v-list-item-action>
                                              <v-list-item-action-text
                                                >#OT-{{ot.folio_ot}}</v-list-item-action-text
                                              >

                                            </v-list-item-action>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </v-list-item-group>
                                  </v-list>
                                </div>
                              </span>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-scroll-y-transition>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import Mtto from "../../api/Mtto";
export default {
  data() {
    return {
      moment,
      reportes: [],
      active: [],
      avatar: null,
      open: [],
      users: [],
      activeS: [],
      idSelect : null,
      search: "",
      caseSensitive: false,
    };
  },

  created() {
    this.listar();
  },

  computed: {
    selected() {
      if (!this.activeS.length) return undefined;

      const id = this.activeS[0];
   
      let reportes = []
      
      if(this.reportes[0].children.find((user) => user.id === id)){
         reportes = this.reportes[0].children.find((user) => user.id === id)
      }else if(this.reportes[1].children.find((user) => user.id === id)){
        reportes = this.reportes[1].children.find((user) => user.id === id)
      }else if(this.reportes[2].children.find((user) => user.id === id)){
        reportes = this.reportes[2].children.find((user) => user.id === id)
      }else if(this.reportes[3].children.find((user) => user.id === id)){
        reportes = this.reportes[3].children.find((user) => user.id === id)
      }else if(this.reportes[4].children.find((user) => user.id === id)){
        reportes = this.reportes[4].children.find((user) => user.id === id)
      }else if(this.reportes[5].children.find((user) => user.id === id)){
        reportes = this.reportes[5].children.find((user) => user.id === id)
      }
      

      return reportes
    },
    filter () {
        return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
      },
    
  },

  methods: {
    async listar() {
      await Mtto.mostrarReportesDesglosado().then((e) => {
        this.reportes = e.data;
        console.log(this.reportes);
      });
    },
    // mostrarDatos(item) {
    //   console.log(this.activeS)
    //   this.selected = [];
    //   this.selected = item.objetosAgrupados;
    // },
  },
};
</script>

<style lang="scss" scoped>
.styled-span {
  display: inline-block;
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 4px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #696969;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.styled-description {
  display: block;
  background-color: #f9f9f9;
  padding: 12px;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #555;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  line-height: 1.5;
}
</style>
