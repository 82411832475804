<template>
  <v-container fluid>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <div class="tables-basic">
      <br /><br /><br /><br />
      <v-dialog
      v-model="dialogEditEquipoUbicacion"
      max-width="520"
    >
      <v-card>
        <v-card-title class="text-h6">
          Editar ubicación del equipo
        </v-card-title>
        <v-card-text>Seleccione la nueva ubicación del equipo</v-card-text>
     
        <div class="pa-2 ma-1">
          <v-select
          :items="ubicacionesContrato"
          label="Ubicaciones"
          v-model="ubicacion"
        ></v-select>
             
        </div>
          
  
    
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="dialogEditEquipoUbicacion = false"
          >
            Salir
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="updateUbicacionEquipo"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-card class="mx-auto">
        <v-btn
          small
          dark
          absolute
          top
          right
          fab
          @click="searchEquipo = !searchEquipo"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
       
        
        <v-col cols="12" sm="12" md="12" v-if="searchEquipo">
          <v-text-field
            @input="buscarFiltro"
            v-model="buscar"
            label="Buscar"
        
          ></v-text-field>
        </v-col>

        <v-list v-for="item in equiposActivosLista" :key="item._id">
          <v-layout wrap :class="`pa-3 ma-3 reporte ${item.estatu}`">
            <v-list-item style="max-width: 100%">
              <v-avatar class="ma-3" size="100" tile>
                <v-img style="border-radius: 8px" :src="item.fotos[1]"></v-img>
              </v-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-text="item.numeroEconomico"
                ></v-list-item-title>

                <v-list-item-subtitle
                  v-text="item.nombreActivo"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-text="item.ubicacion.ubicacion"
                ></v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-row>
                    <v-col cols="6">
                      <v-btn icon>
                        <v-icon @click="editItem(item)" color="green lighten-1"
                          >mdi-information</v-icon
                        >
                      </v-btn>
                      <v-btn icon>
                        <v-icon
                          @click="dialogEditEquipoStatus(item)"
                          color="green lighten-1"
                          >mdi-pencil</v-icon
                        >
                      </v-btn>
                      <v-btn icon>
                        <v-icon
                          @click="mostrarUbicaciones(item)"
                          color="green lighten-1"
                          >mdi-google-maps</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-layout>

          <v-divider></v-divider>
        </v-list>

        <div
          v-if="equiposActivosLista.length"
          v-observe-visibility="handleScrolledToBottom"
        ></div>
      
        <v-dialog v-model="editDialogStatus" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Cambiar estatus de equipo</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      label="Estatus"
                      required
                      :items="['Fuera de Servicio', 'Operativo']"
                      v-model="equipoStatus"
                    ></v-select>
                  </v-col>
                  <v-col v-if="equipoStatus == 'Fuera de Servicio'" cols="12">
                    <v-text-field
                      label="Motivo*"
                      required
                      v-model="observaciones"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="equipoStatus == 'Fuera de Servicio'" cols="12">
                    <v-menu
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Fecha de Fuera de Servicio*"
                          v-model="inicio_operacion"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="inicio_operacion"
                        @input="dateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indica campo requerido</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="cerrarDialogoCambiarEstatus()"
              >
                Cerrar
              </v-btn>
              <v-btn color="blue darken-1" text @click="cambiarEstatusEquipo()">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Equipo </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="guardar"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card class="mx-auto">
            <v-img :src="foto" height="500px" dark>
              <v-row class="fill-height">
                <v-spacer></v-spacer>

                <v-card-title class="white--text pl-12 pt-12">
                  <div class="text-h6 pl-12 pt-12">
                    {{ nombreEquipo }}
                  </div>
                </v-card-title>
              </v-row>
            </v-img>

            <v-list two-line>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">
                    mdi-folder-information-outline
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{
                    datosEquipo.numeroEconomico
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    datosEquipo.tipoUnidad
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-action></v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ datosEquipo.marca }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    datosEquipo.modelo
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-numeric </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Numero de Serie</v-list-item-title>
                  <v-list-item-subtitle>{{
                    datosEquipo.numeroSerie
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action></v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Placa</v-list-item-title>
                  <v-list-item-subtitle>{{
                    datosEquipo.placa
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-clipboard-text-clock </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Inicio de Operación</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-menu
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="inicio_operacion"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="inicio_operacion"
                        @input="dateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-clipboard-text-clock </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Horas de uso promedio</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field
                      v-model="horas_promedio"
                      type="number"
                    ></v-text-field>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import mttoApi from '../../api/Mtto'
export default {
  validations: {
    razonSocial: {
      required,
    },

    arrendadora: {
      required,
    },
  },
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      id: "",
      arrendadoras: [],
      search: "",
      nombreEquipo: "",
      dateMenu: false,
      searchEquipo: false,
      dialogNuevaArrendadora: false,
      editDialogStatus: false,
      razonSocial: "",
      equipoStatus: "",
      inicio_operacion: "",
      horas_promedio: "",
      arrendadora: "",
      horasProm: "",
      buscar: "",
      datosEquipo: [],
      foto: "",
      equiposActivosLista: [],
      editedIndex: -1,
      nombreComercial: "",
      representante: "",
      submited: "",
      alert: "",
      page: 1,
      lastpage: 1,
      loadingReportes: true,
      loadingInputSearch: false,
      disabledInputSearch: false,
      dialogEditEquipoUbicacion: false,
      ubicacionesContrato:[],
      equipoItem : [],
      ubicacion:'',
      overlay:false
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nueva Arrendadora"
        : "Editar Arrendadora";
    },
  },
  created() {
    this.listar();
    this.$store.commit("setRuta", "Equipos");
  },
  methods: {
    async listar() {
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      this.overlay=true
      axios
        .get(
          "/activos/listActivosPemexFilter?page=" +
            this.page +
            "&filter=" +
            this.buscar.toUpperCase(),
          configuracion
        )
        .then((e) => {
          this.equiposActivosLista.push(...e.data.docs);
          this.lastpage = e.data.totalPages;
        })
        .finally(()=>{
          this.overlay=false
        })
    
    },

    async buscarFiltro() {
      this.page = 1;
      this.equiposActivosLista = [];
      await this.listar();
    },

    handleScrolledToBottom(isVisible) {
      if (!isVisible) {
        return;
      }
      if (this.page >= this.lastpage) {
        this.loadingReportes = false;
        return;
      }
      this.page++;
      this.listar();
    },

    limpiar() {
      (this.equipoStatus = ""), (this.observaciones = "");
    },

    mostrarBuscar() {
      if (this.searchEquipo) {
        this.searchEquipo = false;
      } else if (this.searchEquipo == false) {
        this.searchEquipo = true;
      }
    },

    mostrarUbicaciones(item){
      this.equipoItem = item
      this.dialogEditEquipoUbicacion = true
      this.ubicacion = item.ubicacion._id
      this.ubicacionesContrato = [{
        text:'TMDB',
        value :'620802607422680c1e59055c'
      },
      {
        text:'Base Mangos',
        value:'620801fb7422680c1e590558'
      },
      {
        text:'Base Carmen',
        value:'6208050e7422680c1e590570'
      }
    ]
    },
    updateUbicacionEquipo(){
      let datos = {
        _id:this.equipoItem._id,
        ubicacion:this.ubicacion
      }
      mttoApi.updateUbicacionEquipo(datos).then(()=>{
        this.addSuccessNotification('Ubicación actualizado correctamente')
        this.equipoItem = []
        this.dialogEditEquipoUbicacion = false
        this.buscarFiltro()
      })
      .catch((e)=>{
        console.log(e);
      })
    },

    cerrarDialogoCambiarEstatus() {
      this.editDialogStatus = false;
      this.limpiar();
    },

    dialogEditEquipoStatus(item) {
      this.editDialogStatus = true;
      this.equipoStatus = item.estatu;
      this.observaciones = item.observaciones;
      this.id = item._id;
    },



    editItem(item) {
      this.dialog = true;
      this.id = item._id;
      this.foto = item.fotos[0];
      this.nombreEquipo = item.nombreActivo;
      this.datosEquipo = item;
      this.horas_promedio = item.horas_promedio;
      this.inicio_operacion = item.inicio_operacion;
    },

    guardar() {
      let me = this;

      axios
        .put("/activos/updateMtto", {
          _id: this.id,
          inicio_operacion: this.inicio_operacion,
          horas_promedio: this.horas_promedio,
        })
        .then(function () {
          me.dialog = false;
          me.listar();
          me.addSuccessNotification("Registro actualizado");
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    cambiarEstatusEquipo() {
      let me = this;
      axios
        .put("/activos/updateEstatus", {
          _id: this.id,
          estatu: this.equipoStatus,
          observaciones: this.observaciones,
        })
        .then(function () {
          me.dialog = false;
          me.listar();
          me.addSuccessNotification("Registro actualizado");
          me.editDialogStatus = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    cerrarModalArrendadora() {
      this.dialogNuevaArrendadora = false;
      this.limpiar();
      this.editedIndex = -1;
    },
  },
};
</script>
<style>
.reporte.Operativo {
  border-left: 4px solid #3cd1c2;
}
.reporte.Fuera {
  border-left: 4px solid orange;
}
.reporte.Cancelado {
  border-left: 4px solid #f83e70;
}
</style>
