<template>
  <v-container fluid>
    <div class="tables-basic">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div >
      <br /><br><br>
     

      <v-dialog v-model="dialogCambiarUbicacion" max-width="600">
        <v-card>
          <v-card-title class="text-h5" v-if="estatu == 'Operativo'">
            Cambiar estatus del equipo {{ equipoR }} a
            <span style="color: red"> &nbsp; Fuera de Servicio</span>
          </v-card-title>
          <v-card-title class="text-h5" v-if="estatu == 'Fuera de Servicio'">
            Cambiar estatus del equipo {{ equipoR }} a
            <span style="color: green"> &nbsp; Operativo</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <br />

                  <v-text-field
                    v-model="fecha_fs"
                    type="date"
                    label="Fecha"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    v-model="reportes"
                    label="Reportes Falla"
                    outlined
                    :menu-props="{ top: true, offsetY: true }"
                    @input="agregarReporte()"
                    chips
                    multiple
                    :items="folioReportesFalla"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="reporte"
                    disabled
                    auto-grow
                    label="Reporte"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="dialogCambiarUbicacion = false"
            >
              Salir
            </v-btn>

            <v-btn
              color="green darken-1"
              text
              @click="actualizarEstatusEquipo()"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card class="mx-auto">
        <h1 class="pa-1 ma-2 grey--text">Equipos</h1>
        <template>
          <vue-good-table
            class="pa-6 ma-2"
            :columns="headersReportesFallas"
            :rows="reportesFallas"

            styleClass="vgt-table bordered condensed"
            :search-options="{
              enabled: true,
              placeholder: 'Buscar...',
            }"
            :pagination-options="{
              enabled: true,
              perPage: 20,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'numeroEconomico'">
                <v-icon
                  small
                  color="red darken-2"
                  v-if="props.row.estatu == 'Fuera de Servicio'"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                <v-icon
                  small
                  color="green darken-2"
                  v-if="props.row.estatu == 'Operativo'"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                <span style="font-size: 13px">
                  {{ props.row.numeroEconomico }}
                </span>
              </div>

              <span
                style="font-size: 13px"
                v-if="props.column.field == 'nombreActivo'"
              >
                {{ props.row.nombreActivo }}
              </span>
              <span style="font-size: 13px" v-if="props.column.field == 'anio'">
                {{ props.row.anio }}
              </span>
              <span
                style="font-size: 13px"
                v-if="props.column.field == 'numeroSerie'"
              >
                {{ props.row.numeroSerie }}
              </span>
              <span
                style="font-size: 13px"
                v-if="props.column.field == 'ubicacion.ubicacion'"
              >
                {{ props.row.ubicacion.ubicacion }}
              </span>
              <span
                style="font-size: 13px"
                v-if="props.column.field == 'children.estatus'"
              >
                {{ props.row.children[0].estatus }}
              </span>

              <div v-if="props.column.field == 'children.descripcionFalla'">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header style="font-size: 13px">
                      Desglosar Reportes
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        @click="fueraDeServicio(props.row, 'Fuera de Servicio')"
                        small
                        color="green lighten-2"
                      >
                        <v-icon>mdi-file-check</v-icon>
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        @click="fueraDeServicio(props.row, 'Operativo')"
                        small
                        color="red lighten-2"
                      >
                        <v-icon>mdi-file-remove</v-icon>
                      </v-btn>
                      <v-simple-table style="" dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Folio</th>
                              <th class="text-left">Reporte</th>
                              <th class="text-left">Fuera de Servicio</th>
                              <th class="text-left">Dias Fuera de Servicio</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in props.row.children"
                              :key="item._id"
                            >
                              <td style="width: 40px; font-size: 13px">
                                {{ item.folio_falla }}
                              </td>
                              <td style="font-size: 13px">
                                {{ item.descripcionFalla }}
                              </td>
                              <td style="font-size: 13px" v-if="item.fechaFs">
                                {{
                                  moment(item.fechaFs)
                                    .add(1, "days")
                                    .format("L")
                                }}
                              </td>
                              <td
                                style="font-size: 13px"
                                v-if="item.fechaOperativo"
                              >
                                {{ fecha(item.fechaFs, item.fechaOperativo) }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </template>
            >
          </vue-good-table>
        </template>
      </v-card>
    </div>
  </div>

  </v-container>
</template>

<script>
import Mtto from "../../api/Mtto";
import moment from "moment";
export default {
  name: "Dashboard",

  data() {
    return {
      moment,
      loading: false,
      dialogCambiarUbicacion: false,
      equipoR: "",
      estatu: "",
      fecha_fs: "",
      idEquipo: "",
      reporte: [],
      reportesFallas: [],
      reportes: [],
      reportesFallaSelected: [],
      estatus: ["Fuera de Servicio", "Operativo"],
      folioReportesFalla: [],
      headersReportesFallas: [
        {
          label: "Unidad",
          align: "start",
          sortable: true,
          field: "numeroEconomico",
          width: "90px",
          filterOptions: {
            styleClass: "class1",
            enabled: true,
            placeholder: "Equipo",
          },
        },
        {
          label: "Unidad",
          field: "nombreActivo",
          width: "70px",
          filterOptions: {
            styleClass: "class1",
            enabled: true,
            placeholder: "Unidad",
          },
        },
        { label: "Año", field: "anio", width: "80px" },
        { label: "Serie", field: "numeroSerie", width: "50px" },
        {
          label: "Ubicacion",
          field: "ubicacion.ubicacion",
          width: "120px",
          filterOptions: {
            styleClass: "class1",
            enabled: true,
            placeholder: "Ubicaciones",
          },
        },

        {
          label: "Observacion",
          field: "children.descripcionFalla",
          width: "690px",
        },
      ],
    };
  },
  created() {
    this.listReportesDashboard();
  },
  methods: {
    listReportesDashboard() {
      this.loading = true;
      Mtto.obtenerReporteEquipos()
        .then((e) => {
          this.reportesFallas = [];
          let equipos = [];
          equipos = e.data[0];
          let reportes = [];
          reportes = e.data[1];
          console.log(e);
          equipos.forEach((e) => {
            e.children = [];
            reportes.forEach((f) => {
              if (e.numeroEconomico === f.activo.numeroEconomico) {
                e.children.push(f);
              }
            });
          });

          //let datosSalida = [];
          equipos.forEach((e) => {
            if (e.children.length > 0) {
              this.reportesFallas.push(e);
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fueraDeServicio(item, estatu) {
      this.reportes = [];
      this.folioReportesFalla = [];
      this.reporte = "";
      this.dialogCambiarUbicacion = true;
      this.estatu = estatu;
      this.reportesFallaSelected = item.children;
      this.equipoR = item.numeroEconomico;
      // this.reporte = item.descripcionFalla;
      console.log(item);
      if (estatu == "Fuera de Servicio") {
        item.children.forEach((e) => {
          if (e.fechaFs && !e.fechaOperativo) {
            this.folioReportesFalla.push(e.folio_falla);
          }
        });
        this.idEquipo = item._id;
      } else {
        item.children.forEach((e) => {
          if (!e.fechaFs) {
            this.folioReportesFalla.push(e.folio_falla);
          }
        });
        this.idEquipo = item._id;
      }
    },

    agregarReporte() {
      let desc = [];
      this.reportesFallaSelected.forEach((e) => {
        this.reportes.forEach((f) => {
          if (e.folio_falla == f) {
            desc.push(e.descripcionFalla);
          }
        });
      });
      desc.join("");
      this.reporte = desc;
    },
    actualizarEstatusEquipo() {
      if (this.reportes.length < 1) {
        this.addErrorNotification(" reportes no ingresados");
        return;
      }
      let estatu = "";
      if (this.estatu == "Operativo") {
        estatu = "Fuera de Servicio";
      } else {
        estatu = "Operativo";
      }

      let datos = {
        _id: this.idEquipo,
        estatu: estatu,
        fecha: this.fecha_fs,
        reportes: this.reportes,
      };
      console.log(datos);
      Mtto.actualizarEstatusEquipo(datos)
        .then(() => {
          this.addSuccessNotification("Registro actualizado con exito");
          this.listReportesDashboard();
          this.dialogCambiarUbicacion = false;
        })
        .catch((e) => {
          this.addErrorNotification("Error verifique los campos ingresados", e);
        });
    },
    fecha(f1, f2) {
      const diff = new Date(f2).getTime() - new Date(f1).getTime();
      return diff / (1000 * 60 * 60 * 24);
    },
    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification(mensaje) {
      this.$toast.error("Error, verifique los campos ingresados" + mensaje, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
  computed: {
    esRoot() {
      return (
        this.$store.state.usuario && this.$store.state.usuario.rol == "root"
      );
    },
    esUsuario() {
      return (
        this.$store.state.usuario && this.$store.state.usuario.rol == "usuario"
      );
    },
    esCapturista() {
      return (
        this.$store.state.usuario &&
        this.$store.state.usuario.rol == "capturista"
      );
    },
    esActivosFijos() {
      return (
        this.$store.state.usuario && this.$store.state.usuario.rol == "activos"
      );
    },
    esMtto() {
      return (
        this.$store.state.usuario && this.$store.state.usuario.rol == "mtto"
      );
    },
  },

  mounted() {
    setTimeout(() => {
      this.apexLoading = true;
    });
  },
};
</script>
