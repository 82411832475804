<template>
  <v-container fluid class="pa-2 ma-2">

    <div class="dashboard-page">

      <v-bottom-sheet v-model="detalles_ot_estatus">
        <v-sheet height="450px">
          <div class="py-3 texto text-center">
            Total de ordenes de trabajo emitidas por usuario creador
          </div>
          <v-divider inset></v-divider>


          <v-virtual-scroll height="400" :items="estatus_ot_tipo" item-height="60">
            <template v-slot:default="{ item }">
              <v-list-item :key="item">
                <v-list-item-avatar>
                  <v-icon class="primary lighten-1" dark>
                    mdi-account-hard-hat
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="texto">
                    {{ item.usuarioCreador.nombreCompleto }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ item.usuarioCreador.correo }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action class="texto">
                  Total: {{ item.totalReportes }}
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-sheet>
      </v-bottom-sheet>

      <v-bottom-sheet v-model="detalles_ot_mecanico">
        <v-sheet height="450px">
          <div class="py-3 texto text-center">
            TRABAJOS REALIZADOS Y POR REALIZAR DE {{ mecanico_ot_data._id }}
          </div>
          <div class="texto text-center">
            {{ mecanico_ot_data.categoria }}
          </div>

          <v-divider inset></v-divider>


          <v-virtual-scroll height="350" :items="mecanico_ot_data.data" item-height="60">
            <template v-slot:default="{ item }">
              <v-list-item :key="item.folio_ot">
                <v-list-item-avatar>
                  <v-icon class="primary lighten-1" dark>
                    mdi-account-hard-hat
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="texto">
                    Folio OT-{{ item.folio_ot }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="item.trabajos">{{ item.trabajos }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-else>Trabajo pendiente de asignar</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action class="texto">
                  <v-chip label outlined class="texto" small v-if="item.estatus == 'Cerrado'" color="green">
                    Cerrado
                  </v-chip>
                  <v-chip label outlined class="texto" small v-if="item.estatus == 'Normal'" color="blue">
                    Normal
                  </v-chip>
                  <v-chip label outlined class="texto" small v-if="item.estatus == 'En espera'" color="orange"
                    style="color:#202020">
                    En espera
                  </v-chip>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-sheet>
      </v-bottom-sheet>

      <br />
      <v-row no-gutters class="d-flex justify-space-between mt-16 mb-6">
        <h1 class="page-title">Dashboard Mantenimiento</h1>
      </v-row>

      <v-row>
        <v-col cols="12" lg="4" sm="4" md="4">
          <v-card class="mx-1 mb-1" style="border-radius: 15px; background-color: #e9e9e9">
            <v-card-text class="pa-2 pt-0">
              <v-card-title class="pa-6 pb-3 texto"> Estatus de RF </v-card-title>
              <div v-if="dataLoaded">
                <Echarts chartTitle="Activos FS" :data="graficaEstatus" height="260px" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" sm="4" md="4">
          <v-card class="mx-1 mb-1" style="border-radius: 15px; background-color: #e9e9e9">
            <v-card-text class="pa-2 pt-0">
              <v-card-title class="pa-6 pb-3 texto"> Tipos de de Falla </v-card-title>
              <div v-if="dataLoaded">
                <Echarts chartTitle="Activos FS" :data="graficaTipoFalla" height="260px" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" sm="4" md="4">
          <v-card class="mx-1 mb-1" style="border-radius: 15px; background-color: #e9e9e9">
            <v-card-text class="pa-2 pt-0">
              <v-card-title class="pa-6 pb-3 texto"> Prioridad </v-card-title>
              <div v-if="dataLoaded">
                <Echarts chartTitle="Activos FS" :data="graficaPrioridad" height="260px" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="12" sm="12" md="12">
          <v-card class="mx-1 mb-1" style="border-radius: 15px">
            <v-card-text class="pa-2 pt-0">
              <v-card-title class="pa-6 pb-3 texto">
                Grafica por fecha de Falla
              </v-card-title>
              <div v-if="dataLoaded">
                <LineCHarts chartTitle="Grafica Fecha" :fechas="graficaLineasFechasFalla[0]"
                  :data="graficaLineasFechasFalla[1]" height="300px" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="12" sm="12" md="12">
          <v-card class="mx-1 mb-1" style="border-radius: 15px">
            <v-card-text class="pa-2 pt-0">
              <v-card-title class="pa-6 pb-3 texto">
                Grafica por reportes de falla cerrados
              </v-card-title>
              <div v-if="dataLoaded">
                <LineCHarts chartTitle="Grafica Fecha" :fechas="graficaLineasFechasFallaCerrados[0]"
                  :data="graficaLineasFechasFallaCerrados[1]" height="300px" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="12" sm="12" md="12">
          <v-card class="mx-1 mb-1" style="border-radius: 15px; background-color: #e9e9e9">
            <v-card-text class="pa-2 pt-0">
              <v-card-title class="pa-6 pb-3 texto">
                Reportes por Tipo de Unidad
              </v-card-title>
              <div v-if="dataLoaded">
                <EchartsHalf chartTitle="Grafica Tipo Unidad" :data="graficaTipoUnidad" height="300px" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3" sm="3" md="3">
          <v-card max-width="500" style="border-radius: 15px; background-color: #e9e9e9">
            <v-card-title class="pa-6 pb-3 texto" style="text-align: center">
              Ordenes de trabajo emitidas
            </v-card-title>
            <v-card-text class="pt-0">
              <div class="subheading font-weight-light grey--text" style="text-align: center">
                <h2>
                  {{ estatusOt[3].value }}
                </h2>
              </div>
              <v-divider class="my-2"></v-divider>
              <v-btn @click="obtenerReportesAgrupadosPorCreador" icon color="primary">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <span class="grey--text texto">Ver detalles</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" sm="3" md="3">
          <v-card max-width="500" style="border-radius: 15px; background-color: #e9e9e9">
            <v-card-title class="pa-6 pb-3 texto" style="text-align: center">
              Ordenes de trabajo en espera
            </v-card-title>
            <v-card-text class="pt-0">
              <div class="subheading font-weight-light grey--text" style="text-align: center">
                <h2>
                  {{ estatusOt[1].value }}
                </h2>
              </div>
              <v-divider class="my-2"></v-divider>
              <v-btn @click="obtenerReportesEnEsperaAgrupadosPorCreador" icon color="primary">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <span class="grey--text texto">Ver detalles</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" sm="3" md="3">
          <v-card max-width="500" style="border-radius: 15px; background-color: #e9e9e9">
            <v-card-title class="pa-6 pb-3 texto" style="text-align: center">
              Ordenes de trabajo abiertas
            </v-card-title>
            <v-card-text class="pt-0">
              <div class="subheading font-weight-light grey--text" style="text-align: center">
                <h2>
                  {{ estatusOt[2].value }}
                </h2>
              </div>
              <v-divider class="my-2"></v-divider>
              <v-btn @click="obtenerReportesAbiertosAgrupadosPorCreador" icon color="primary">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <span class="grey--text texto">Ver detalles</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" sm="3" md="3">
          <v-card max-width="500" style="border-radius: 15px; background-color: #e9e9e9">
            <v-card-title class="pa-6 pb-3 texto" style="text-align: center">
              Ordenes de trabajo cerradas
            </v-card-title>
            <v-card-text class="pt-0">
              <div class="subheading font-weight-light grey--text" style="text-align: center">
                <h2>
                  {{ estatusOt[0].value }}
                </h2>
              </div>
              <v-divider class="my-2"></v-divider>
              <v-btn @click="obtenerReportesCerradosAgrupadosPorCreador" icon color="primary">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <span class="grey--text texto">Ver detalles</span>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="12" sm="12" md="12">
          <v-card class="mx-1 mb-1" style="border-radius: 15px">
            <template>
              <vue-good-table :columns="headersReportesFallas" :rows="reportesFallas"
                styleClass="vgt-table bordered condensed" :search-options="{
                  enabled: true,
                  placeholder: 'Buscar...',
                }" :pagination-options="{
                  enabled: true,
                  perPage: 20,
                }">
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field == 'numeroEconomico'">
                    <v-icon small color="red darken-2" v-if="props.row.estatu == 'Fuera de Servicio'">
                      mdi-checkbox-blank-circle
                    </v-icon>
                    <v-icon small color="green darken-2" v-if="props.row.estatu == 'Operativo'">
                      mdi-checkbox-blank-circle
                    </v-icon>
                    <span style="font-size: 13px">
                      {{ props.row.numeroEconomico }}
                    </span>
                  </div>

                  <span style="font-size: 13px" v-if="props.column.field == 'nombreActivo'">
                    {{ props.row.nombreActivo }}
                  </span>
                  <span style="font-size: 13px" v-if="props.column.field == 'anio'">
                    {{ props.row.anio }}
                  </span>
                  <span style="font-size: 13px" v-if="props.column.field == 'numeroSerie'">
                    {{ props.row.numeroSerie }}
                  </span>
                  <span style="font-size: 13px" v-if="props.column.field == 'ubicacion.ubicacion'">
                    {{ props.row.ubicacion.ubicacion }}
                  </span>
                  <span style="font-size: 13px" v-if="props.column.field == 'children.estatus'">
                    {{ props.row.children[0].estatus }}
                  </span>

                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header style="font-size: 13px"
                        v-if="props.column.field == 'children.descripcionFalla'">
                        Desglosar Reportes
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table style="" dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Folio</th>
                                <th class="text-left">Reporte</th>
                                <th class="text-left">Tipo de Falla</th>
                                <th class="text-left">Fecha de Falla</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in props.row.children" :key="item._id">
                                <td style="width: 40px; font-size: 13px">
                                  {{ item.folio_falla }}
                                </td>
                                <td style="font-size: 13px">
                                  {{ item.descripcionFalla }}
                                </td>
                                <td style="font-size: 13px">
                                  {{ item.tipoFalla }}
                                </td>
                                <td style="font-size: 13px">
                                  {{ moment(new Date(item.createdAt)).format("LL") }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
                >
              </vue-good-table>
            </template>
          </v-card>
        </v-col>
        <v-col cols="12" lg="5" sm="5" md="5">
          <v-card class="mx-1 mb-1" style="border-radius: 15px">
            <v-card-text class="pa-2 pt-0">
              <v-card-title class="pa-6 pb-3 texto"> Requisiciones </v-card-title>
              <v-data-table :loading="mostrarTabla" style="border-radius: 10px" dense :headers="headersRequis"
                :items="tablaRequisicionesPendientes" :items-per-page="12" item-key="nombreComercial" class="">
                <template v-slot:[`header.numeroEconomico`]="{ header }">
                  <span class="texto" style="font-size: 14px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                  </span>
                </template>
                <template v-slot:[`header.folio_ot`]="{ header }">
                  <span class="texto" style="font-size: 14px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                  </span>
                </template>
                <template v-slot:[`header.requisiciones`]="{ header }">
                  <span class="texto" style="font-size: 14px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                  </span>
                </template>
                <template v-slot:[`header.estatus`]="{ header }">
                  <span class="texto" style="font-size: 14px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                  </span>
                </template>

                <template v-slot:[`item.numeroEconomico`]="{ item }">
                  <div>
                    <span class="texto"
                      style="
                                                                                                                                                                                                                                                                                                            color: #202020;
                                                                                                                                                                                                                                                                                                            font-size: 10px;
                                                                                                                                                                                                                                                                                                            font-weight: bold;
                                                                                                                                                                                                                                                                                                            text-transform: uppercase;
                                                                                                                                                                                                                                                                                                          ">{{
                                                                                                                                                                                                                                                                                                            item.activo.numeroEconomico
                                                                                                                                                                                                                                                                                                          }}</span>
                  </div>
                </template>

                <template v-slot:[`item.folio_ot`]="{ item }">
                  <div>
                    <span class="texto"
                      style="
                                                                                                                                                                                                                                                                                                            color: #202020;
                                                                                                                                                                                                                                                                                                            font-size: 10px;
                                                                                                                                                                                                                                                                                                            font-weight: bold;
                                                                                                                                                                                                                                                                                                            text-transform: uppercase;
                                                                                                                                                                                                                                                                                                          ">OT-{{
                                                                                                                                                                                                                                                                                                            item.folio_ot
                                                                                                                                                                                                                                                                                                          }}</span>
                  </div>
                </template>
                <template v-slot:[`item.requisiciones`]="{ item }">
                  <div>
                    <v-chip v-for="(data, index) in item.requisiciones" :key="index" x-small label outlined
                      class="texto" style="color: #202020; font-size: 9px; cursor: pointer">
                      {{ data }}
                    </v-chip>
                  </div>
                </template>

                <template v-slot:[`item.estatus`]="{ item }">
                  <v-chip x-small label outlined class="texto" v-if="item.estatus == 'Cerrado'" color="green"
                    style="color: #202020; font-size: 9px">
                    Cerrado
                  </v-chip>
                  <v-chip x-small label outlined class="texto" v-if="item.estatus == 'Normal'" color="blue"
                    style="color: #202020; font-size: 9px">
                    Normal
                  </v-chip>
                  <v-chip x-small label dense outlined class="texto" v-if="item.estatus == 'En espera'" color="orange"
                    style="color: #202020; font-size: 9px">
                    En espera
                  </v-chip>
                </template>
              </v-data-table>
              <br />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="7" sm="7" md="7">
          <v-card class="mx-1 mb-1" style="border-radius: 15px">
            <v-card-text class="pa-2 pt-0">
              <v-card-title class="pa-6 pb-3 texto">
                Trabajos por Mecanicos
              </v-card-title>
              <v-data-table :loading="mostrarTabla" style="border-radius: 10px" dense :headers="headersMecanicos"
                :items="tablaTrabajosPendientesMec" :items-per-page="12" item-key="nombreComercial" class="">
                <template v-slot:[`header.tecnico`]="{ header }">
                  <span class="texto" style="font-size: 14px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                  </span>
                </template>
                <template v-slot:[`header.categoria`]="{ header }">
                  <span class="texto" style="font-size: 14px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                  </span>
                </template>
                <template v-slot:[`header.trabajos_pendientes`]="{ header }">
                  <span class="texto" style="font-size: 14px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                  </span>
                </template>
                <template v-slot:[`header.detalles`]="{ header }">
                  <span class="texto" style="font-size: 14px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                  </span>
                </template>

                <template v-slot:[`item.tecnico`]="{ item }">
                  <div>
                    <span class="texto"
                      style="color:#202020;font-size: 10px; font-weight: bold;text-transform:uppercase;">{{ item._id
                      }}</span>
                  </div>
                </template>
                <template v-slot:[`item.categoria`]="{ item }">
                  <div>
                    <span class="texto"
                      style="color:#202020;font-size: 10px; font-weight: bold;text-transform:uppercase;">{{
                        item.categoria
                      }}
                    </span>
                  </div>
                </template>

                <template v-slot:[`item.trabajos_pendientes`]="{ item }">
                  <div>
                    <v-chip small label outlined class="texto" style="color: #202020; font-size: 11px">
                      Total : {{ item.count }}
                    </v-chip>
                  </div>
                </template>
                <template v-slot:[`item.detalles`]="{ item }">
                  <v-btn class="mx-2" dark x-small color="primary" @click="obtenerDetallesMecanico(item)"> Ver
                    detalles
                  </v-btn>
                </template>
              </v-data-table>
              <br />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row> </v-row>
    </div>
  </v-container>
</template>

<script>
import Mtto from "../../api/Mtto";
import moment from "moment";

import Echarts from "../Charts/Echarts.vue";
import LineCHarts from "../Charts/EchartLine.vue";
import EchartsHalf from "../Charts/EchartsHalf.vue";
export default {
  name: "Dashboard",
  components: {
    Echarts,
    LineCHarts,
    EchartsHalf,
  },
  data() {
    return {
      detalles_ot_estatus: false,
      detalles_ot_mecanico: false,
      dataLoaded: false,
      mostrarTabla: false,
      reportesFallas: [],
      moment,
      graficaEstatus: [],
      graficaTipoFalla: [],
      estatus_ot_tipo: [],
      mecanico_ot_data: [],
      graficaPrioridad: [],
      graficaLineasFechasFalla: [],
      graficaLineasFechasFallaCerrados: [],

      graficaTipoUnidad: [],
      tablaRequisicionesPendientes: [],
      tablaTrabajosPendientesMec: [],
      estatusOt: [],
      headersRequis: [
        { text: "Equipo", value: "numeroEconomico", sortable: false },
        { text: "Folio", value: "folio_ot", sortable: false },
        { text: "Requisiciones", value: "requisiciones", sortable: false },
        { text: "Estatus", value: "estatus", sortable: false },
      ],
      headersMecanicos: [
        { text: "Tecnico", value: "tecnico", sortable: false },
        { text: "Categoria", value: "categoria", sortable: false },
        { text: "Trabajos Pendientes", value: "trabajos_pendientes", sortable: false },
        { text: "Detalles", value: "detalles", sortable: false },
      ],
      headersReportesFallas: [
        {
          label: "Unidad",
          align: "start",
          sortable: true,
          field: "numeroEconomico",
          width: "50px",
          filterOptions: {
            styleClass: "class1",
            enabled: true,
            placeholder: "Equipo",
          },
        },
        {
          label: "Unidad",
          field: "nombreActivo",
          width: "70px",
          filterOptions: {
            styleClass: "class1",
            enabled: true,
            placeholder: "Unidad",
          },
        },
        { label: "Serie", field: "numeroSerie", width: "50px" },
        {
          label: "Ubicacion",
          field: "ubicacion.ubicacion",
          width: "80px",
          filterOptions: {
            styleClass: "class1",
            enabled: true,
            placeholder: "Ubicaciones",
          },
        },

        {
          label: "Observacion",
          field: "children.descripcionFalla",
          width: "390px",
        },
      ],
    };
  },



  created() {
    this.listReportesDashboard();
    this.dashboardGraficas()
  },
  methods: {

    dashboardGraficas() {
      console.log('first')
      Mtto.dashboardReporteFallas().then((e) => {
        this.graficaEstatus = e.data[0];
        this.graficaTipoFalla = e.data[1];
        this.graficaPrioridad = e.data[2];
        this.graficaLineasFechasFalla = e.data[3];
        this.graficaTipoUnidad = e.data[4];
        this.tablaRequisicionesPendientes = e.data[5];
        this.tablaTrabajosPendientesMec = e.data[6];
        this.estatusOt = e.data[7];
        this.graficaLineasFechasFallaCerrados = e.data[10];
        console.log(e.data);
        this.dataLoaded = true;
      }).finally(() => {
      });
    },

    sumField(key) {
      return this.activos.reduce((a, b) => a + (b[key] || 0), 0);
    },

    getRandomInt(min, max) {
      let rand = min - 0.5 + Math.random() * (max - min + 1);
      return Math.round(rand);
    },



    obtenerReportesAgrupadosPorCreador() {
      this.detalles_ot_estatus = true;
      Mtto.obtenerTotaOtPorCreador().then((e) => {
        this.estatus_ot_tipo = e.data;
      });
    },

    obtenerReportesEnEsperaAgrupadosPorCreador() {
      this.detalles_ot_estatus = true;

      Mtto.obtenerEnEsperaOtPorCreador().then((e) => {
        this.estatus_ot_tipo = e.data;
      });
    },

    obtenerReportesAbiertosAgrupadosPorCreador() {
      this.detalles_ot_estatus = true;

      Mtto.obtenerAbiertaOtPorCreador().then((e) => {
        this.estatus_ot_tipo = e.data;
      });
    },

    obtenerReportesCerradosAgrupadosPorCreador() {
      this.detalles_ot_estatus = true;
      Mtto.obtenerCerradaOtPorCreador().then((e) => {
        this.estatus_ot_tipo = e.data;
      });
    },
    obtenerDetallesMecanico(item) {
      this.detalles_ot_mecanico = true
      this.mecanico_ot_data = item
    },

    fecha(f1, f2) {
      const diff = new Date(f2).getTime() - new Date(f1).getTime();
      return diff / (1000 * 60 * 60 * 24);
    },

    listReportesDashboard() {
      Mtto.obtenerReportesDashboard().then((e) => {
        let equipos = [];
        equipos = e.data[0];

        let reportes = [];
        reportes = e.data[1];

        equipos.forEach((e) => {
          e.children = [];
          reportes.forEach((f) => {
            if (e.numeroEconomico === f.activo.numeroEconomico) {
              e.children.push(f);
            }
          });
        });

        //let datosSalida = [];
        equipos.forEach((e) => {
          if (e.children.length > 0) {
            this.reportesFallas.push(e);
          }
        });
      }).finally(() => {
      });
    },

    //GRAFICA ECHARTS
  },
  computed: {
    esRoot() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "root";
    },
    esUsuario() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "usuario";
    },
    esCapturista() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "capturista";
    },
    esActivosFijos() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "activos";
    },
    esJefeMtto() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "jefe_mtto";
    },
    esMtto() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "mtto";
    },
  },
};
</script>

<style src="./Dashboard.scss" lang="scss" />
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

.texto {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.texto_simple {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
}


.v-data-table>>>.v-data-table-header {
  background-color: rgb(23, 23, 87) !important;
}
</style>
