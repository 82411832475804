<template>
  <v-container fluid>
    <div class="tables-basic">
      <br /><br /><br /><br />
      <v-dialog v-model="mostrarDialogTebsRepetidos" persistent max-width="1200px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Elementos PEP y Activos</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Programa Presupuestal"
                    required
                    v-model="pp_rep"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="EPEP" required v-model="epep_rep"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Activo"
                    required
                    v-model="activo_rep"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card>
                <v-card-title>
                  <v-text-field
                    v-model="searchElementos"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  v-if="epepsRepeat"
                  dense
                  :headers="headersEpep"
                  :items="epepsRepeat"
                  item-key="name"
                  :search="searchElementos"
                  class="elevation-1"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn @click="eliminarElemento(item._id)" small icon color="primary">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="mostrarDialogTebsRepetidos = false">
              Salir
            </v-btn>
            <v-btn color="blue darken-1" text @click="guardarPepRepetidos()">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col cols="12" md="12" lg="1">
          <h2 style="margin-top: -20px" class="mb-5">Teb</h2>
        </v-col>
        <v-col cols="12" md="12" lg="3">
          <v-text-field
            v-model="compania"
            style="margin-top: -20px"
            label="Compañia"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="1">
          <v-text-field
            v-model="contrato"
            style="margin-top: -20px"
            label="Contrato"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="servicio"
            style="margin-top: -20px"
            label="Servicio"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card class="employee-list">
            <div class="pa-3" v-if="verNuevo == 0">
              <v-btn
                @click="mostrarNuevo()"
                class="mb-5"
                rounded
                color="primary"
                small
                dark
                >Nuevo Registro</v-btn
              >
              <v-container class="my-5">
                <v-card
                  flat
                  class="pa-2"
                  v-for="tebs in listadoTebMensual"
                  :key="tebs._id"
                >
                  <v-layout row wrap :class="`pa-3 tebs ${tebs.estatus}`">
                    <v-flex xs12 md1>
                      <v-btn
                        style="margin-top: 5px"
                        @click="obtenerDatos(tebs._id)"
                        icon
                        color="success"
                      >
                        <v-icon>mdi-microsoft-excel</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="caption grey--text">Mes</div>
                      <div>
                        {{ moment(tebs.mes).add(1, "days").format("MMMM YYYY") }}
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <div class="caption grey--text">Compañia</div>
                      <div>Corporativo Industrial y Comercial S.A. de C.V.</div>
                    </v-flex>
                    <v-flex xs12 sm4 md2>
                      <div class="caption grey--text">Contrato</div>
                      <div>{{ tebs.contrato }}</div>
                    </v-flex>
                    <v-flex xs8 sm6 md2>
                      <div class="caption grey--text">Estatus</div>
                      <v-chip small>{{ tebs.estatus }}</v-chip>
                    </v-flex>
                    <v-flex xs4 sm6 md2>
                      <v-btn
                        color="primary"
                        fab
                        x-small
                        dark
                        @click="editItem(tebs)"
                        style="margin-top: 10px"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        color="primary"
                        fab
                        x-small
                        dark
                        @click="editItem(tebs)"
                        style="margin-left: 10px; margin-top: 10px"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-container>
            </div>
            <div v-if="verNuevo">
              <div>
                <v-card-title>{{ formTitle }}</v-card-title>
              </div>
              <div class="pa-5">
                <v-flex xs11 sm11 lg11 x11>
                  <v-row>
                    <v-col cols="12" md="12" lg="3">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            style="margin-top: -30px"
                            outlined
                            dense
                            v-model="mesServicio"
                            label="Mes"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="mesServicio"
                          type="month"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <v-text-field
                        style="margin-top: -30px"
                        outlined
                        dense
                        v-model="fechaSolicitada"
                        label="Fecha Solicitada"
                        prepend-icon="mdi-calendar"
                        type="date"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <v-text-field
                        style="margin-top: -30px"
                        outlined
                        dense
                        v-model="fechaRecibido"
                        label="Fecha Recibido"
                        prepend-icon="mdi-calendar"
                        type="date"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <v-autocomplete
                        v-model="instalacionD"
                        :items="inst_dest"
                        style="margin-top: -30px"
                        label="Instalación/Destino"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" lg="2">
                      <v-text-field
                        label="Programa Presupuestal"
                        :counter="15"
                        :rules="pprules"
                        v-model="programaPressL"
                        outlined
                        dense
                        style="margin-top: -30px"
                        append-icon="mdi-briefcase-search-outline"
                        @click:append="buscarDatos()"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="2"
                      v-if="
                        programaPressL == 'CA1M0000PH5S440' ||
                        programaPressL == 'C00M0000PHES0100'
                      "
                    >
                      <v-select
                        :items="TebEspecial"
                        v-model="teb"
                        label="TEB"
                        outlined
                        dense
                        style="margin-top: -30px"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="2" v-else>
                      <v-text-field
                        label="TEB"
                        v-model="teb"
                        :counter="12"
                        :rules="tebRules"
                        outlined
                        dense
                        style="margin-top: -30px"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <v-text-field
                        v-model="epepRegistro"
                        style="margin-top: -30px"
                        outlined
                        dense
                        label="EPEP"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <v-text-field
                        outlined
                        dense
                        v-model="activo"
                        disabled
                        label="Activo"
                        style="margin-top: -30px"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="3">
                      <v-combobox
                        v-model="equiposRequeridosReg"
                        multiple
                        :search-input.sync="search"
                        outlined
                        dense
                        :items="equiposActivosLista"
                        style="margin-top: -30px"
                        label="Equipo"
                        small-chips
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No se encontraron resultados "<strong>{{ search }}</strong
                                >". Presiona <kbd>enter</kbd> para crear un nuevo item
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col cols="12" lg="7">
                      <v-file-input
                        v-model="tebImportDatos"
                        style="margin-top: -30px"
                        label="Adjuntar archivo en formato csv"
                      ></v-file-input>
                    </v-col>

                    <v-btn
                      style="margin-top: -30px"
                      small
                      color="primary"
                      @click="obtnerDatosTebImportado()"
                    >
                      Importar registros
                    </v-btn>
                    <v-btn
                      style="margin-top: -30px"
                      small
                      color="primary"
                      @click="agregarTebImportada()"
                    >
                      Importar registros
                    </v-btn>
                    <v-col cols="12" lg="7">
                      <v-text-field
                        label="Observaciones"
                        v-model="observaciones"
                        outlined
                        dense
                        style="margin-top: -30px"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="1">
                      <v-btn
                        class="mx-2"
                        small
                        style="margin-top: -30px"
                        @click="agregarRegistro"
                        fab
                        dark
                        color="primary"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" lg="1"> </v-col>
                  </v-row>
                  <div style="margin-top: 20px">
                    <template>
                      <v-text-field
                        style="margin-top: 10px"
                        v-model="searchTeb"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field
                      ><br />
                      <v-data-table
                        :headers="headers"
                        :items="tebListaRegistro"
                        dense
                        :items-per-page="numberPage"
                        :search="searchTeb"
                        class="elevation-1"
                      >
                        <template v-slot:top>
                          <v-dialog v-model="dialog" max-width="600px">
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">Editar Item</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" lg="12">
                                      <v-text-field
                                        type="date"
                                        dense
                                        v-model="editedItemTebs.fechaSolicitada"
                                        label="Fecha Solicitada"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="12">
                                      <v-text-field
                                        type="date"
                                        dense
                                        v-model="editedItemTebs.fechaRecibido"
                                        label="Fecha Recibido"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="12">
                                      <v-text-field
                                        label="TEB"
                                        dense
                                        v-model="editedItemTebs.teb"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="12">
                                      <v-text-field
                                        label="Programa Presupuestal"
                                        dense
                                        v-model="editedItemTebs.programaPressL"
                                        append-icon="mdi-briefcase-search-outline"
                                        @click:append="buscarDatosProps(editedItemTebs)"
                                        :loading="loading"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="12">
                                      <v-text-field
                                        dense
                                        v-model="editedItemTebs.epepRegistro"
                                        label="EPEP"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="12">
                                      <v-text-field
                                        dense
                                        v-model="editedItemTebs.activo"
                                        disabled
                                        label="Activo"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="12">
                                      <v-text-field
                                        dense
                                        v-model="editedItemTebs.instalacionD"
                                        label="Instalación/Destino"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="12">
                                      <p>Equipos Seleccionados: {{ equiposCheckBox }}</p>
                                      <v-combobox
                                        dense
                                        v-model="editedItemTebs.equiposRequeridosReg"
                                        :search-input.sync="search"
                                        :items="equiposActivosLista"
                                        label="Equipo"
                                        multiple
                                        small-chips
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                No se encontraron resultados "<strong>{{
                                                  search
                                                }}</strong
                                                >". Presiona <kbd>enter</kbd> para crear
                                                un nuevo item
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </template>
                                      </v-combobox>
                                    </v-col>
                                    <v-col cols="12" lg="12">
                                      <v-text-field
                                        dense
                                        v-model="editedItemTebs.observaciones"
                                        label="Observaciones"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                  Cancel
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                            @click="eliminarRegistro(item)"
                            small
                            icon
                            color="primary"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn
                            @click="editarRegistroItem(item)"
                            small
                            icon
                            color="primary"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:[`item.index`]="{ item }">
                          {{ tebListaRegistro.indexOf(item) + 1 }}
                        </template>
                      </v-data-table>
                    </template>
                  </div>

                  <br />
                  <v-btn color="primary" text @click="ocultarNuevo()"> Salir </v-btn>
                  <v-btn color="primary" text @click="guardar()"> Guardar </v-btn>
                  <!--  <v-btn color="primary" text @click="limpiarTeb()">
                    Limpiar
                  </v-btn>   -->
                </v-flex>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-btn class="button-fab-1 mx-2" @click="mostrarDialogEpep()" dark fab color="green"
        ><v-icon dark>mdi-database-refresh</v-icon></v-btn
      >
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import api from "../../api/Catalogo";
import Costeo from "../../api/Costeo";
export default {
  data() {
    return {
      moment: moment,
      headers: [
        { text: "#", value: "index" },
        { text: "Acciones", value: "actions", width: "90px" },
        { text: "Fecha Solicitada", value: "fechaSolicitada", sortable: true },
        { text: "Fecha Recibida", value: "fechaRecibido", sortable: true },
        { text: "Teb", value: "teb", sortable: true },
        {
          text: "Programa Presupuestal",
          value: "programaPressL",
          sortable: true,
          width: "200px",
        },
        { text: "EPEP", value: "epepRegistro", sortable: true, width: "200px" },
        {
          text: "Instalación/Destino",
          value: "instalacionD",
          sortable: true,
          width: "200px",
        },
        {
          text: "Equipos Requeridos",
          value: "equiposRequeridosReg",
          sortable: true,
          width: "200px",
        },
        {
          text: "Activo",
          value: "activo",
          sortable: true,
          width: "220px",
        },
        {
          text: "Observacion",
          value: "observaciones",
          sortable: true,
          width: "220px",
        },
      ],

      headersEpep: [
        { text: "Acciones", value: "actions", sortable: true },
        { text: "Programa Presupuestal", value: "pp" },
        { text: "Epep", value: "epep" },
        { text: "Activo", value: "activo", sortable: true },
      ],

      TebEspecial: ["Cobos", "Arbol Grande"],
      searchTeb: "",
      compania: "CORPORATIVO INDUSTRIAL Y COMERCIAL S.A. DE C.V.",
      contrato: "648812815",
      servicio:
        "SERVICIO DE TRANSPORTE DE CARGA Y DESCARGA DE MATERIALES EN PUERTO DOS BOCAS",
      periodo: "",
      tebRules: [(v) => (v && v.length <= 12) || "TEB solo puede tener 12"],
      epep: [],
      numberPage: 15,
      fab: false,
      listadoTebMensual: [],
      equiposCheckBox: "",
      equiposActivosLista: [],
      epepsRepeat: [],
      activo: "",
      mostrarTabla: false,
      hover: false,
      nombreContrato: "INTEGRAL EQ. TERRESTRE DB",
      inst_dest: [
        "MONOBOYAS",
        "PROCESO",
        "REMANENTE",
        "LOGISTICA",
        "INSPECCION TUBULAR",
        "URREP",
        "PLANTA DE LODOS",
        "SEYBAPLAYA",
        "ÁREA DE CAJAS",
        "TALLER DE ÁRBOLES",
        "VALVULAS",
        "COSECO",
        "PATRIMONIAL",
        "REMANENTE 1",
        "TALLER DE MANTENIMIENTO LITORAL",
        "TALLER DE MANTENIMIENTO ABKATUN",
        "VENTAS",
        "ALMACEN GENERAL",
        "PROCESOS",
        "TANQUES 1",
        "TANQUES 2",
        "CD. PEMEX",
        "CENTRO DE ATENCIÓN A EVACUADOS POR HURACANES",
        "ARBOL GRANDE",
        "COBOS",
        "ALMACEN COMALCALCO",
      ],
      partida: "",
      ordenServicio: "",
      activoCosteo: "",
      pprules: [(v) => (v && v.length <= 15) || "PP solo puede tener 15 caracteres"],
      teb: "",
      search: "",
      loading: false,
      partidas: [],
      registroCosteo: [],
      programasPressLista: [],
      programaPress: "",
      epepRegistro: "",
      observaciones: "",
      costo: "",
      equiposRequeridosReg: "",
      costoUnitario: "",
      instalacion_destino: "",
      volumen: "",
      montoEstimado: "",
      numeroEstimacion: "",
      registroTep: [],
      listaInstDestPorPress: [],
      activoEquipo: "",
      tipoDeServicio: ["J", "D", "FS", "MTTO"],
      ubicacionServicio: ["TMDB", "Tinaja", "Seybaplaya"],
      equiposRequiredos: [
        "GRUA",
        "GRUA O MONTACARGA Y SERVICIO DE MANIOBRISTA",
        "GRUA O MONTACARGAS",
        "GRUA Y  CUADRILLA",
        "GRUA Y MANIOBRISTAS",
        "GRUA Y MANIOBRISTAS",
      ],
      tebImportDatos: "",
      tebsAdjuntosTabla: [],
      instalacionD: "",
      numeroDeSolicitud: "",
      programaPressL: "",
      date: [],
      menu: false,
      modal: false,
      documentos: [],
      menu2: false,
      menuFechaSolicitada: false,
      menuFechaRecibido: false,
      fechaRecibido: new Date().toISOString().slice(0, 10),
      abilitarEdicion: false,
      fechaSolicitada: new Date().toISOString().slice(0, 10),
      mostrarDialogTebsRepetidos: false,
      mesServicio: "",
      dialog: false,
      verNuevo: 0,
      pp_rep: "",
      epep_rep: "",
      activo_rep: "",
      editedIndex: -1,
      tebLista: [],
      searchElementos: "",
      tebListaRegistro: [],
      alert: "",
      options: {
        locale: "en-US",
        prefix: "$",
        page: 0,
        hasMore: false,
        isLoading: false,
        suffix: "",
        length: 11,
        precision: 2,
      },
      rowTeb: "",
      editedItemTebs: {
        fechaSolicitada: "",
        fechaRecibida: "",
        teb: "",
        epepRegistro: "",
        instalacionD: "",
        equiposRequeridosReg: "",
        observaciones: "",
        activo: "",
      },
      defaultItem: {
        fechaSolicitada: "",
        fechaRecibida: "",
        teb: "",
        epepRegistro: "",
        instalacionD: "",
        equiposRequeridosReg: "",
        observaciones: "",
        activo: "",
      },
    };
  },

  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo registro" : "Editar registro";
    },
    dateRangeText() {
      return this.periodo.join(" al ");
    },
  },
  created() {
    this.listar();
  },

  methods: {
    listar() {
      let me = this;

      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/teb/list", configuracion)
        .then(function (response) {
          me.listadoTebMensual = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    moreData() {
      this.page++;
    },

    limpiar() {
      (this.mesServicio = ""), (this.tebListaRegistro = []), (this.editedIndex = -1);
      this.alert = false;
    },

    guardar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      console.log(this.tebListaRegistro);
      if (this.editedIndex > -1) {
        axios
          .put(
            "/teb/update",
            {
              _id: this._id,
              mes: this.mesServicio,
              contrato: this.contrato,
              servicio: this.servicio,
              tebRegistros: this.tebListaRegistro,
            },
            configuracion
          )
          .then(function () {
            me.limpiar();
            me.listar();
            me.addSuccessNotification("Activo actualizado");
            me.ocultarNuevo();
          })
          .catch(function (error) {
            console.log(error);
            me.addErrorNotification("Error, verifica la informacion agregada");
          });
      } else {
        axios
          .post(
            "/teb/add",
            {
              mes: this.mesServicio,
              contrato: this.contrato,
              servicio: this.servicio,
              tebRegistros: this.tebListaRegistro,
            },
            configuracion
          )
          .then(function () {
            me.limpiar();
            me.listar();
            me.addSuccessNotification("Activo actualizado");
            me.ocultarNuevo();
          })
          .catch(function (error) {
            console.log(error);
            me.addErrorNotification("Error, verifica la informacion agregada");
          });
      }
    },

    eliminarRegistro(item) {
      let i = this.tebListaRegistro.indexOf(item);
      if (i != -1) {
        this.tebListaRegistro.splice(i, 1);
      }
    },

    editarRegistroItem(item) {
      this.rowTeb = this.tebListaRegistro.indexOf(item);
      this.mostrarInstDestItem(item);
      this.editedItemTebs = Object.assign({}, item);
      this.equiposCheckBox = this.editedItemTebs.equiposRequeridosReg;
      let arr = this.editedItemTebs.equiposRequeridosReg.split(",");
      this.editedItemTebs.equiposRequeridosReg = arr;

      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItemTebs = Object.assign({}, this.defaultItem);
      });
    },
    save() {
      Object.assign(this.tebListaRegistro[this.rowTeb], this.editedItemTebs);

      this.tebListaRegistro[this.rowTeb].equiposRequeridosReg = this.tebListaRegistro[
        this.rowTeb
      ].equiposRequeridosReg.toString();

      this.close();
    },

    editItem(tebs) {
      this._id = tebs._id;
      (this.mesServicio = new Date(tebs.mes).toISOString().substr(0, 7)),
        (this.tebListaRegistro = tebs.tebRegistros);

      this.editedIndex = 1;

      this.mostrarNuevo();
    },
    mostrarNuevo() {
      // this.listarAgencia(this.$store.state.usuario.agencia)
      this.mostrarProgramaPress();
      this.mostrarActivos();

      this.mostrarTeb(this.page);
      this.verNuevo = 1;
    },
    ocultarNuevo() {
      this.verNuevo = 0;
      this.limpiar();
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },

    addErrorNotificationMessage(msg) {
      this.$toast.error(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },

    buscarDatos() {
      this.loading = true;
      this.mostrarPpEncontrados(this.programaPressL);

      Costeo.buscarActivo(this.programaPressL)
        .then((e) => {
          this.activo = e.data.activo;
        })
        .catch(() => {
          this.addErrorNotificationMessage("Programa no encontrado");
          this.activo = "";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buscarDatosProps(item) {
      /*   let me = this; */
      /*       let header = { Token: this.$store.state.token };
       */ this.loading = true;
      /*     let configuracion = { headers: header };
      let claveId = item.programaPressL.substr(0, 3); */
      Costeo.buscarActivo(item.programaPressL)
        .then((e) => {
          item.activo = e.data.activo;
        })
        .catch(() => {
          this.addErrorNotificationMessage("Programa no encontrado");
          this.activo = "";
        })
        .finally(() => {
          this.loading = false;
        });
      /* axios
        .get("/claves/query?idAsignacion=" + claveId, configuracion)
        .then(function (response) {
          me.loading = false;
          let activoPemex = response.data.activoAbreb;
          item.activo = activoPemex;
        })
        .catch(function () {
          me.addErrorNotification();
          me.loading = false;
        }); */
    },

    descargarExcel() {
      axios({
        url: "/teb/tebExcel", //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },

    obtenerDatos(id) {
      axios({
        url: "/teb/listDatosReg?_id=" + id, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },

    changeIcon() {
      let me = this;
      let header = { Token: this.$store.state.token };

      let configuracion = { headers: header };
      if (me.volumen && me.partida) {
        axios
          .get("/partidas/query?partida=" + this.partida, configuracion)
          .then(function (response) {
            me.partidas = response.data;
            if (me.partidas) {
              me.costoUnitario = me.partidas.costo;
              me.montoEstimado = me.costoUnitario * me.volumen;
            } else {
              me.addErrorNotification();
              me.partida = "";
              (me.costoUnitario = ""), (me.montoEstimado = "");
            }
          })
          .catch(function () {});
      }
    },

    editarRegistrosItem(item) {
      this.fechaSolicitada = item.fechaSolicitada;
      this.fechaRecibido = item.fechaRecibido;
      this.teb = item.teb;
      this.programaPressL = item.programaPressL;
      this.epep = item.epepRegistro;
      this.instalacionD = item.instalacionD;
      this.equiposRequeridosReg = item.equiposRequeridosReg;
      this.observaciones = item.observaciones;
      this.abilitarEdicion = true;
    },

    agregarRegistro() {
      if (
        this.mesServicio == "" ||
        this.fechaSolicitada == "" ||
        this.programaPressL == "" ||
        this.activo == "" ||
        this.fechaRecibido == "" ||
        this.teb == "" ||
        this.epepRegistro == "" ||
        this.instalacionD == "" ||
        this.equiposRequeridosReg == ""
      ) {
        this.addErrorNotificationMessage("Registros incompletos, verifique porfavor");
      } else {
        for (let i = 0; i < this.tebListaRegistro.length; i++) {
          if (this.tebListaRegistro[i].teb == this.teb) {
            this.addErrorNotificationMessage("TEB repetido, verifique porfavor");
            return;
          }
        }

        this.tebListaRegistro.push({
          fechaSolicitada: this.fechaSolicitada,
          fechaRecibido: this.fechaRecibido,
          teb: this.teb,
          programaPressL: this.programaPressL,
          epepRegistro: this.epepRegistro,
          instalacionD: this.instalacionD,
          equiposRequeridosReg: this.equiposRequeridosReg.toString(),
          observaciones: this.observaciones,
          activo: this.activo,
        });
        this.teb = "";
        /*  this.programaPressL = "";
        this.activo = "";
       
        this.epepRegistro = "";
        */
        this.equiposRequeridosReg = "";
        this.observaciones = "";
      }
    },
    mostrarInstDest() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };

      axios
        .get("/programasPress/query?teb=" + this.teb, configuracion)
        .then(function (response) {
          me.listaInstDestPorPress = response.data.inst_dest_items;
          me.epep = response.data.epep;
          me.programaPressL = response.data.programaPresupuestal;
          me.activoCosteo = response.data.activo;
        })
        .catch(function () {});
    },
    mostrarInstDestItem(item) {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };

      axios
        .get("/programasPress/query?teb=" + item.teb, configuracion)
        .then(function (response) {
          item.programaPressL = response.data.programaPresupuestal;
          me.epep = response.data.epep;
        })
        .catch(function () {});
    },

    mostrarProgramaPress() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let presupuestoArray = [];
      let configuracion = { headers: header };
      axios
        .get("/programasPress/list", configuracion)
        .then(function (response) {
          presupuestoArray = response.data;
          presupuestoArray.map((x) => {
            me.programasPressLista.push({
              text: x.programaPresupuestal,
              value: x.programaPresupuestal,
            });
          });
        })
        .catch(function () {});
    },
    mostrarTeb() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let tebArray = [];

      let configuracion = { headers: header };
      axios
        .get("/programasPress/list", configuracion)
        .then(function (response) {
          tebArray = response.data;
          tebArray.map((x) => {
            me.tebLista.push({ text: x.teb, value: x.teb });
          });
        })
        .catch(function () {});
    },

    mostrarActivos() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let equiposActivos = [];
      let configuracion = { headers: header };
      axios
        .get("/activos/listActivosPemex", configuracion)
        .then(function (response) {
          equiposActivos = response.data;
          equiposActivos.map((x) => {
            me.equiposActivosLista.push(x.numeroEconomico);
          });
          me.equiposActivosLista.push("MANIOBRISTA 01");
          me.equiposActivosLista.push("MANIOBRISTA 02");
          me.equiposActivosLista.push("MANIOBRISTA 03");
          me.equiposActivosLista.push("MANIOBRISTA 04");
          me.equiposActivosLista.push("MANIOBRISTA 05");
          me.equiposActivosLista.push("MANIOBRISTA 06");
          me.equiposActivosLista.push("MANIOBRISTA 07");
          me.equiposActivosLista.push("MANIOBRISTA 08");
          me.equiposActivosLista.push("MANIOBRISTA 09");
          me.equiposActivosLista.push("MANIOBRISTA 10");
          me.equiposActivosLista.push("MANIOBRISTA 11");
          me.equiposActivosLista.push("MANIOBRISTA 12");
          me.equiposActivosLista.push("MANIOBRISTA 13");
          me.equiposActivosLista.push("MANIOBRISTA 14");
          me.equiposActivosLista.push("MANIOBRISTA 15");
          me.equiposActivosLista.push("MANIOBRISTA 16");
          me.equiposActivosLista.push("MANIOBRISTA 17");
          me.equiposActivosLista.push("MANIOBRISTA 18");
          me.equiposActivosLista.push("MANIOBRISTA 19");
          me.equiposActivosLista.push("MANIOBRISTA 20");
          me.equiposActivosLista.push("MANIOBRISTA 21");
          me.equiposActivosLista.push("MANIOBRISTA 22");
          me.equiposActivosLista.push("MANIOBRISTA 23");
          me.equiposActivosLista.push("MANIOBRISTA 24");
          me.equiposActivosLista.push("MANIOBRISTA 25");
          me.equiposActivosLista.push("MANIOBRISTA 26");
          me.equiposActivosLista.push("MANIOBRISTA 27");
          me.equiposActivosLista.push("MANIOBRISTA 28");
          me.equiposActivosLista.push("MANIOBRISTA 29");
          me.equiposActivosLista.push("MANIOBRISTA 30");
          me.equiposActivosLista.push("MANIOBRISTA 31");
          me.equiposActivosLista.push("MANIOBRISTA 32");
          me.equiposActivosLista.push("MANIOBRISTA 33");
          me.equiposActivosLista.push("MANIOBRISTA 34");
          me.equiposActivosLista.push("MANIOBRISTA 35");
          me.equiposActivosLista.push("MANIOBRISTA 36");
          me.equiposActivosLista.push("MANIOBRISTA 37");
          me.equiposActivosLista.push("MANIOBRISTA 38");
          me.equiposActivosLista.push("MANIOBRISTA 39");
          me.equiposActivosLista.push("MANIOBRISTA 40");
          me.equiposActivosLista.push("MANIOBRISTA 41");
          me.equiposActivosLista.push("MANIOBRISTA 42");
          me.equiposActivosLista.push("MANIOBRISTA 43");
          me.equiposActivosLista.push("MANIOBRISTA 44");
          me.equiposActivosLista.push("MANIOBRISTA 45");
          me.equiposActivosLista.push("MANIOBRISTA 46");
          me.equiposActivosLista.push("MANIOBRISTA 47");
          me.equiposActivosLista.push("MANIOBRISTA 48");
          me.equiposActivosLista.push("MANIOBRISTA 49");
          me.equiposActivosLista.push("MANIOBRISTA 50");
          me.equiposActivosLista.push("CABO 01");
          me.equiposActivosLista.push("CABO 02");
          me.equiposActivosLista.push("CABO 03");
          me.equiposActivosLista.push("CABO 04");
          me.equiposActivosLista.push("CABO 05");
          me.equiposActivosLista.push("CABO 06");
          me.equiposActivosLista.push("CABO 07");
          me.equiposActivosLista.push("CABO 08");
          me.equiposActivosLista.push("CABO 09");
          me.equiposActivosLista.push("CABO 10");
          me.equiposActivosLista.push("CABO 11");
          me.equiposActivosLista.push("GRUA");
          me.equiposActivosLista.push("RABON");
          me.equiposActivosLista.push("MONTACARGAS");
          me.equiposActivosLista.push("CARGADOR FRONTAL");
        })
        .catch(function () {});
    },

    obtnerDatosTebImportado() {
      let me = this;
      var file = this.tebImportDatos;

      this.$papa.parse(file, {
        header: true,
        complete: function (results) {
          me.tebsAdjuntosTabla = results.data;
          me.tebsAdjuntosTabla.splice(me.tebsAdjuntosTabla.length - 1, 1);
        },
      });
    },

    agregarTebImportada() {
      this.tebListaRegistro = [...this.tebListaRegistro, ...this.tebsAdjuntosTabla];
    },

    async guardarPepRepetidos() {
      let datos = {
        epep: this.epep_rep,
        activo: this.activo_rep,
        pp: this.pp_rep,
      };
      await api.ingresarEpep(datos).then(() => {
        this.addSuccessNotification("Datos ingresados correctamente");
        this.epep_rep = "";
        this.activo_rep = "";
        this.pp_rep = "";
        this.listarPepRepetidos();
      });
    },

    mostrarDialogEpep() {
      this.mostrarDialogTebsRepetidos = true;
      this.listarPepRepetidos();
    },

    async listarPepRepetidos() {
      await api.obtenerEpep().then((e) => {
        this.epepsRepeat = e.data;
      });
    },
    async mostrarPpEncontrados(epep) {
      await api.queryEpep(epep).then((e) => {
        this.epepRegistro = e.data.epep;
      });
    },
    async eliminarElemento(item) {
      api
        .deleteEpep(item)
        .then(() => {
          this.listarPepRepetidos();
          this.addSuccessNotification("Elemento eliminado correctamente");
        })
        .catch((e) => {
          this.addErrorNotification();
          console.log(e);
        });
    },
    limpiarTeb() {
      this.tebListaRegistro = [];
    },
  },
};
</script>
<style>
.tebs.Activo {
  border-left: 4px solid #3cd1c2;
}
.app_more {
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
}
.app_more:hover {
  text-decoration: underline;
}
.app_more,
.app_more:active {
  color: #3cd1c2;
}
.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid rgb(123, 90, 243);
  border-top-color: #fff;
  position: absolute;
  bottom: 0;
  left: calc(50%-20px);
  animation: spinner-animation 1.2s linear infinite;
}
.tebs.Revision {
  border-left: 4px solid orange;
}
.tebs.Finalizado {
  border-left: 4px solid rgb(123, 90, 243);
}
.v-chip.Activo {
  background: #f83e70;
  color: black;
}
#chips-container .v-chip.activo {
  color: white;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
