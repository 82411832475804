<template>
  <v-container fluid>
    <v-overlay :value="loadingTable">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Por favor espere
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogGuardarTrazabilidad" persistent max-width="490">
      <v-card>
        <v-card-title class="text-h5"> Aperturar un nuevo mes </v-card-title>
        <v-card-text>
          <v-col cols="12" md="12" lg="12">
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
              offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="mesServicio" label="Mes" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker locale="es" v-model="mesServicio" type="month" @input="mostrarTepMes"></v-date-picker>
            </v-menu>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogGuardarTrazabilidad = false">
            Salir
          </v-btn>
          <v-btn color="green darken-1" text @click="aperturarMes()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog transition="dialogEditarTrazabilidad" max-width="1200" v-model="dialogLoadingTrazabilidad">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>Editar Registro</v-toolbar>
          <v-card-text><br />
            <v-container>
              <v-row class="mx-2">
                <v-col class="mt-2" cols="6" sm="6" md="6">
                  <v-autocomplete :items="tebMesLista" @input="mostrarDatosTeb()" v-model="numeroDeSolicitudEditar" dense
                    label="TEB">
                    <template v-slot:append-outer>
                      <v-slide-x-reverse-transition mode="out-in">
                        <v-btn @click="limpiarTeb()" icon color="gray">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-slide-x-reverse-transition>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="mt-2" cols="6" sm="6" md="6">
                  <v-text-field type="date" v-model="fechaServicioEditar" dense label="Fecha Servicio"></v-text-field>
                </v-col>
                <v-col class="mt-2" cols="6" sm="6" md="6">
                  <v-autocomplete v-model="ordenServicioEditar" @input="mostrarEquiposDeOrdenEditar(ordenServicioEditar)"
                    :items="osList" dense label="Orden de Servicio"></v-autocomplete>
                </v-col>
                <v-col class="mt-2" cols="6" sm="6" md="6">
                  <v-select dense label="Tipo de Servicio" :items="tipoDeServicio"
                    v-model="tipoServicioEditar"></v-select>
                </v-col>
                <v-col class="mt-2" cols="6" sm="6" md="6">
                  <v-text-field v-model="estimacionEditar" dense label="Estimacion"></v-text-field>
                </v-col>
                <v-col class="mt-2" cols="6" sm="6" md="6">
                  <v-text-field type="number" @input="costoPartidasMontoEstimado" v-model="volumenEditar" dense
                    label="Volumen"></v-text-field>
                </v-col>
                <v-col class="mt-2" cols="6" sm="6" md="6">
                  <v-autocomplete type="number" @input="costoPartidasMontoEstimado" :items="partidasList"
                    v-model="partidasEditar" dense label="Partida"></v-autocomplete>
                </v-col>

                <v-col class="mt-2" cols="6" sm="6" md="6">
                  <v-select :items="ubicacionesOs" v-model="ubicacionEditar" dense label="Ubicación"></v-select>
                </v-col>
                <v-col class="mt-2" cols="6" sm="6" md="6">
                  <v-autocomplete :items="equiposTrazabilidad" v-model="equipoEditar" dense
                    label="Equipo"></v-autocomplete>
                </v-col>
                <v-col class="mt-2" cols="6" sm="6" md="6">
                  <v-combobox v-model="instalacion_destino_editar" dense :items="inst_dest" :search-input.sync="search"
                    hide-selected label="Instalación/Destino" persistent-hint small-chips>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No se encontraron resultados "<strong>{{
                              search
                            }}</strong>". Presiona <kbd>enter</kbd> para crear un nuevo
                            item
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col class="mt-2" cols="12" sm="12" md="12">
                  <v-text-field v-model="observacionesEditar" dense label="Observaciones"></v-text-field>
                </v-col>
                <v-col class="mt-2" cols="3" sm="3" md="3">
                  <v-text-field type="number" disabled outlined dense v-model="montoEstimadoInfo"
                    label="Monto Estimado"></v-text-field>
                </v-col>
                <v-col class="mt-2" cols="3" sm="3" md="3">
                  <v-text-field disabled outlined dense v-model="programaPresupuestalSelec"
                    label="Programa Presupuestal"></v-text-field>
                </v-col>
                <v-col class="mt-2" cols="3" sm="3" md="3">
                  <v-text-field disabled outlined dense v-model="epepSelecc" label="EPEP"></v-text-field>
                </v-col>
                <v-col class="mt-2" cols="3" sm="3" md="3">
                  <v-text-field disabled outlined dense v-model="activoPemex" label="Activo Pemex"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialogLoadingTrazabilidad = false">Salir</v-btn>
            <v-btn text @click="updateRegistroTrazabilidad()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="mostrarDialogTebsEncontrados" max-width="990">
      <v-card>
        <v-toolbar color="primary" dark>Registros Encontrados <v-spacer></v-spacer> <v-btn :loading="loadingSincrinize"
            icon dark @click="updateTrazabilidadTeb()">
            <v-icon>mdi-cached</v-icon>
          </v-btn></v-toolbar>
        <v-card-text>
          <v-data-table dense :headers="headers2" :items="tebEncontrados" class="elevation-1"></v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="mostrarDialogTebsEncontrados = false">
            Salir
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mostrarDialogActivosFilter" max-width="490">
      <v-card>
        <v-toolbar color="primary" dark>Equipos</v-toolbar>
        <v-card-text>
          <v-col class="mt-2" cols="12" sm="12" md="12">
            <v-autocomplete :items="equiposTrazabilidad" v-model="equipoSelecFiltro" dense
              label="Equipo"></v-autocomplete>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="mostrarDialogActivosFilter = false">
            Salir
          </v-btn>
          <v-btn color="primary" text @click="agregarEquipoFiltro('trazabilidad.activoEquipo')">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="mostrarDialogUnidadFilter" max-width="490">
      <v-card>
        <v-toolbar color="primary" dark>Unidad</v-toolbar>
        <v-card-text>
          <v-col class="mt-2" cols="12" sm="12" md="12">
            <v-select :items="unidadesLista" v-model="unidadSelecFiltro" dense label="Unidad"></v-select>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="mostrarDialogUnidadFilter = false">
            Salir
          </v-btn>
          <v-btn color="primary" text @click="agregarEquipoFiltro('trazabilidad.tipoDeServicioSelec')">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mostrarDialogTebdFilter" max-width="490">
      <v-card>
        <v-toolbar color="primary" dark>TEB</v-toolbar>
        <v-card-text>
          <v-col class="mt-2" cols="12" sm="12" md="12">
            <v-autocomplete :items="tebMesLista" v-model="tebSelecFiltro" dense label="TEB"></v-autocomplete>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="mostrarDialogTebdFilter = false">
            Salir
          </v-btn>
          <v-btn color="primary" text @click="agregarEquipoFiltro('trazabilidad.numeroDeSolicitud')">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mostrarDialogInstalacionFilter" max-width="490">
      <v-card>
        <v-toolbar color="primary" dark>Instalación</v-toolbar>
        <v-card-text>
          <v-col class="mt-2" cols="12" sm="12" md="12">
            <v-autocomplete :items="inst_dest" v-model="instalacionSelecFiltro" dense
              label="Instalación"></v-autocomplete>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="mostrarDialogInstalacionFilter = false">
            Salir
          </v-btn>
          <v-btn color="primary" text @click="agregarEquipoFiltro('trazabilidad.instalacion_destino')">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mostrarDialogPartidaFilter" max-width="490">
      <v-card>
        <v-toolbar color="primary" dark>Partidas</v-toolbar>
        <v-card-text>
          <v-col class="mt-2" cols="12" sm="12" md="12">
            <v-autocomplete :items="partidasList" v-model="partidaSelecFiltro" dense label="Partida"></v-autocomplete>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="mostrarDialogPartidaFilter = false">
            Salir
          </v-btn>
          <v-btn color="primary" text @click="agregarEquipoFiltro('trazabilidad.partida')">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mostrarDialogFechaFilter" max-width="490">
      <v-card>
        <v-toolbar color="primary" dark>Fecha</v-toolbar>
        <v-card-text>
          <v-col class="mt-2" cols="12" sm="12" md="12">
            <v-menu v-model="menuFechaFiltro" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="fechaSelecFiltro" label="Fecha de Servicio" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker locale="es" v-model="fechaSelecFiltro" @input="menuFechaFiltro = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="mostrarDialogFechaFilter = false">
            Salir
          </v-btn>
          <v-btn color="primary" text @click="agregarEquipoFiltro('trazabilidad.fechaServicio')">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="mostrarDialogTebUbicaciones" scrollable max-width="1600px">
      <v-card>
        <v-card-title>Partidas por ubicaciones <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-row class="pl-8 mt-2">
          <v-col cols="3" sm="3" md="3">
            <v-autocomplete :items="tebMensualServ" label="Selecciona el TEB" outlined v-model="tebSeleccionadoMS" dense
              @input="obtenerDatosTeb"></v-autocomplete>
          </v-col>
          <v-col cols="2" sm="2" md="2">
            <v-text-field disabled outlined dense v-model="activoTebMensual" label="Activo"></v-text-field>
          </v-col>
          <v-col cols="2" sm="2" md="2">
            <v-text-field disabled outlined dense v-model="ppTebMensual" label="Programa Presupuestal"></v-text-field>
          </v-col>
          <v-col cols="2" sm="2" md="2">
            <v-text-field disabled outlined dense v-model="epepTebMensual" label="EPEP"></v-text-field>
          </v-col>
          <v-col cols="3" sm="3" md="3">
            <v-text-field disabled outlined dense v-model="instalacionMensua" label="Instalación"></v-text-field>
          </v-col>
        </v-row>
        <v-card-text style="height: 800px">
          <br />
          <v-data-table v-if="tebEncontrados" v-model="regAgregarTebSelected" :headers="headers2" :items="tebEncontrados"
            :items-per-page="20" :single-select="false" item-key="_id" :disabled="true" show-select dense
            class="elevation-1">

          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="mostrarDialogTebUbicaciones = false">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text @click="actualziarPartidasSinTebPorUbicaciones()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <div class="tables-basic">
      <br /><br /><br /><br />
      <h2 class="mb-5">Trazabilidad</h2>

      <v-row>
        <v-col cols="12">
          <div class="employee-list">
            <div class="pa-5" v-if="verNuevo == 0">
              <div class="pa-3" v-if="verNuevo == 0">
                <v-btn @click="dialogGuardarTrazabilidad = true" class="mb-5" rounded color="primary" small dark>Aperturar
                  Mes</v-btn>
                <v-container class="my-5">
                  <v-row>
                    <v-col v-for="trazabilidad in trazabilidadLista" :key="trazabilidad._id" lg="4" sm="6" md="7"
                      cols="12">
                      <v-card max-width="500">
                        <v-sheet class="v-sheet--offset mx-auto" color="grey" elevation="12">
                          <div class="text-h6 font-weight-bold white--text" style="text-align: center">
                            {{ moment(trazabilidad.mes).add(1, 'days').format("MMM YYYY") }}
                          </div>
                        </v-sheet>
                        <br />
                        <v-card-text class="pt-0">
                          <div class="subheading font-weight-light grey--text" style="text-align: center">
                            <v-card-actions class="justify-center">

                              <v-btn icon @click="editItem(trazabilidad)" large>
                                <v-icon size="80" color="primary">mdi-folder-file</v-icon>
                              </v-btn>
                            </v-card-actions>
                          </div>
                          <br>
                          <v-divider class="my-2"></v-divider>
                          <div class="text-center">
                            <v-btn :loading="loading" @click="obtenerDatos(trazabilidad._id)" icon color="success">
                              <v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                            <span class="justify-align-center"> <b> ${{
                              costoToDollar(parseFloat((trazabilidad.total)).toFixed(2)) }}</b></span>
                            <v-btn :loading="loading" @click="obtenerGenerador(trazabilidad._id)" icon color="primary">
                              <v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                            <span class="justify-align-center"><b> Generador </b></span>
                          </div>



                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </div>
            <v-dialog v-model="dialogVizualisarTrazabilidadImportada" width="2000">
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Trazabilidad importadas
                </v-card-title>

                <v-card-text>
                  <v-data-table :headers="headersImportado" :items="trazabilidadsAdjuntosTabla" :items-per-page="15"
                    class="elevation-1">
                  </v-data-table>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="agregarTrazabilidadImportada()">
                    Importar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <div v-if="verNuevo">
              <div>
                <v-card-title>{{ formTitle }}</v-card-title>
              </div>
              <div class="pa-5">
                <v-flex xs11 sm11 lg11 x11>
                  <v-row>
                    <v-col cols="12" md="12" lg="2">
                      <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field style="margin-top: -30px" outlined dense v-model="mesServicio" label="Mes"
                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="mesServicio" type="month"
                          @input="mostrarTepMes"></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="6" lg="2">
                      <v-autocomplete outlined @input="mostrarFechaTeb" dense :items="tebMesLista"
                        style="margin-top: -30px" v-model="numeroDeSolicitud"
                        label="Numero de Solicitud"></v-autocomplete>
                    </v-col>
                    <v-col cols="6" lg="2">
                      <v-menu v-model="menuServicio" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field style="margin-top: -30px" outlined dense v-model="fechaServicio"
                            label="Fecha de Servicio" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="fechaServicio" @input="menuServicio = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <v-autocomplete :items="osList" @input="mostrarEquiposDeOrden(ordenServicio)"
                        style="margin-top: -30px" outlined dense v-model="ordenServicio"
                        label="Ordenes de Servicio"></v-autocomplete>
                    </v-col>

                    <v-col cols="6" lg="1">
                      <v-select :items="tipoDeServicio" v-model="tipoDeServicioSelec" style="margin-top: -30px" outlined
                        dense label="Tipo de Servicio"></v-select>
                    </v-col>
                    <v-col cols="6" lg="2">
                      <v-text-field outlined dense style="margin-top: -30px" v-model="numeroEstimacion" type="number"
                        label="Estimacion"></v-text-field>
                    </v-col>

                    <v-col cols="6" lg="2">
                      <v-text-field outlined dense style="margin-top: -30px" v-model="volumen" @input="changeIcon"
                        label="Volumen" />
                    </v-col>

                    <v-col cols="6" lg="1">
                      <v-autocomplete :items="partidasList" style="margin-top: -30px" outlined dense v-model="partida"
                        label="Partidas" @input="changeIconSinHorario"></v-autocomplete>
                    </v-col>

                    <v-col cols="6" lg="2">
                      <v-text-field outlined dense style="margin-top: -30px" v-model="montoEstimadoInfo" disabled
                        label="Monto Estimado" />
                    </v-col>

                    <v-col cols="6" lg="2">
                      <v-select :items="ubicacionesOs" v-model="ubicacion" style="margin-top: -30px" outlined dense
                        label="Ubicación"></v-select>
                    </v-col>

                    <v-col cols="6" lg="3">
                      <v-autocomplete :append-icon="marker ? 'mdi-timer-sand' : 'mdi-timer-off-outline'"
                        @click:append="toggleMarker" :items="osEquiposinTraz" style="margin-top: -30px" outlined dense
                        v-model="activoEquipo" label="Equipos"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-combobox v-model="instalacion_destino" outlined dense :items="inst_dest"
                        style="margin-top: -30px" :search-input.sync="search" hide-selected label="Instalación/Destino"
                        persistent-hint small-chips>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No se encontraron resultados "<strong>{{
                                  search
                                }}</strong>". Presiona <kbd>enter</kbd> para crear un
                                nuevo item
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" lg="5">
                      <v-text-field outlined dense style="margin-top: -30px" v-model="descripcion"
                        label="Observaciones" />
                    </v-col>

                    <v-col cols="12" lg="1">
                      <v-btn style="margin-top: -50px" small color="primary" @click="agregarRegistro">
                        Agregar
                        <template v-slot:loader>
                          <span class="custom-loader">
                            <v-icon light>mdi-cached</v-icon>
                          </span>
                        </template>
                      </v-btn>
                    </v-col> </v-row><br />
                  <v-toolbar color="primary" dark>
                    <v-row>
                      <v-col class="mt-2" cols="6" sm="6" md="6">
                        <v-menu bottom left v-model="filtrosMenu">
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox v-bind="attrs" v-on="on" v-model="filtros" small-chips dense label="Filtro"
                              multiple single-line @click="filtrosMenu = true" class="mx-3 mt-3"
                              prepend-icon="mdi-filter-variant-plus" @click:prepend="filtrosMenu = true">
                              <template v-if="filtros.length > 0" v-slot:selection="{
                                attrs,
                                item,
                                select,
                                selected,
                              }">
                                <v-chip v-bind="attrs" :input-value="selected" small close @click="select"
                                  @click:close="remove(item)">
                                  <strong>{{ item.text }}</strong>&nbsp;
                                </v-chip>
                              </template>
                            </v-combobox>
                          </template>

                          <v-list>
                            <v-list-item v-for="(item, i) in itemsFilter" :key="i" @click="mostrarFiltro(item)">
                              <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                      <v-col class="mt-2" cols="6" sm="6" md="6">
                        <div class="text-h6 pa-2">Monto:{{ costoToDollar(parseFloat(monto_total_tra).toFixed(2)) }} ---
                          Jornada: ${{ jornada_total_tra.toFixed(2) }}</div>


                      </v-col>
                    </v-row>
                  </v-toolbar>
                  <v-data-table :headers="headers" :items="registroCosteo" dense :items-per-page="15" hide-default-footer
                    class="elevation-1">
                    <template v-slot:[`item.activoEquipo`]="{ item }">

                      <div>
                        <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>

                          <template v-slot:activator="{ on, attrs }">

                            <span v-bind="attrs" v-on="on" link style="color: #3768d4; font-size: 12px">{{
                              item.activoEquipo }}</span>
                          </template>



                          <v-card>
                            <v-list dense dark color="primary">
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Acciones para :</v-list-item-title>
                                  <v-list-item-subtitle>{{ item.activoEquipo }} -
                                    {{
                                      item.fechaServicio
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>

                            <v-divider></v-divider>

                            <v-list>
                              <v-list-item-group v-model="selectedItem" color="#3768D4">
                                <v-list-item @click="dialogTrazabilidadRegistro(item)">
                                  <v-list-item-icon>
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>Editar</v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="mostrarDialogDelete(item)">
                                  <v-list-item-icon>
                                    <v-icon>mdi-delete</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>Eliminar</v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </div>
                    </template>

                    <template v-slot:[`item.fechaServicio`]="{ item }">
                      <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on, attrs }">

                          <span v-bind="attrs" v-on="on" link style=" font-size: 12px">{{ item.fechaServicio }}</span>
                        </template>
                        <span>{{ item.usuarioCreador }}</span>
                      </v-tooltip>

                    </template>
                    <template v-slot:[`item.numeroDeSolicitud`]="{ item }">
                      <span style="color: gray; font-size: 12px">{{
                        item.numeroDeSolicitud
                      }}</span>
                    </template>
                    <template v-slot:[`item.tipoDeServicioSelec`]="{ item }">
                      <span style="color: gray; font-size: 12px; text-align: center">{{ item.tipoDeServicioSelec }}</span>
                    </template>
                    <template v-slot:[`item.numeroEstimacion`]="{ item }">
                      <span style="color: gray; font-size: 12px">{{
                        item.numeroEstimacion
                      }}</span>
                    </template>
                    <template v-slot:[`item.montoEstimado`]="{ item }">
                      <b>
                        <span style="color: gray; font-size: 12px">${{ roundedNumber(item.montoEstimado) }}</span></b>
                    </template>
                    <template v-slot:[`item.volumen`]="{ item }">
                      <b>
                        <span style="color: gray; font-size: 12px">{{
                          item.volumen
                        }}</span></b>
                    </template>
                    <template v-slot:[`item.partida`]="{ item }">
                      <b>
                        <span style="color: gray; font-size: 12px">{{
                          item.partida
                        }}</span></b>
                    </template>
                    <template v-slot:[`item.ubicacion`]="{ item }">
                      <span style="color: gray; font-size: 12px">{{
                        item.ubicacion
                      }}</span>
                    </template>
                    <template v-slot:[`item.ordenServicio`]="{ item }">
                      <span style="color: gray; font-size: 12px">{{
                        item.ordenServicio
                      }}</span>
                    </template>
                    <template v-slot:[`item.instalacion_destino`]="{ item }">
                      <span style="color: gray; font-size: 12px">{{
                        item.instalacion_destino
                      }}</span>
                    </template> </v-data-table><br />
                  <div class="text-center">
                    <v-pagination v-model="page" :length="totalPages" :total-visible="7"
                      @input="paginacionTrazabilidad()"></v-pagination>
                  </div>
                  <!--   <v-col cols="12" lg="1">
                    <v-btn
                      style="margin-top: -50px"
                      small
                      color="primary"
                      @click="mostrarDialogDelete"
                    >
                      Eliminar Registro
                      <template v-slot:loader>
                        <span>
                          <v-icon>mdi-delete</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-col> -->
                  <v-row>
                    <!--  <v-col cols="12" lg="6" sm="6">
                      <v-file-input
                        v-model="trazabilidadImportDatos"
                        style="margin-top: -30px"
                        label="Adjuntar archivo en formato csv"
                      ></v-file-input>
                    </v-col> -->
                    <!--  <v-col cols="12" lg="2" sm="2">
                      <v-btn
                        style="margin-top: -30px"
                        small
                        color="primary"
                        @click="obtnerDatosTrazabilidadImportada()"
                      >
                        Importar registros
                      </v-btn>
                    </v-col> -->
                    <!--    <v-col cols="12" lg="2" sm="2">
                      <v-btn
                        style="margin-top: -30px"
                        small
                        color="primary"
                        @click="limpiarTrazabilidadFull()"
                      >
                        Limpiar
                      </v-btn>
                    </v-col> -->
                    <!--    <v-col
                      v-if="trazabilidadsAdjuntosTabla.length > 0"
                      cols="12"
                      lg="2"
                      sm="2"
                    >
                      <v-btn
                        style="margin-top: -30px"
                        small
                        color="primary"
                        @click="vizualizarTrazabilidadImportada()"
                      >
                        Ver registros importados
                      </v-btn>
                    </v-col> --><br><br><br>
                    <v-col cols="12" lg="2" sm="2">
                      <v-btn style="" small color="primary" @click="agregarTeb()">
                        Agregar TEB de Observaciones
                      </v-btn>

                      <!--     <v-btn
                        style="margin-top: -30px"
                        small
                        color="primary"
                        @click="onUpdate()"
                      >
                      corregirTrazabilidad TEB
                      </v-btn> -->
                    </v-col>
                    <v-col cols="12" lg="2" sm="2">
                      <v-btn small color="primary" @click="agregarTebUbicacionMensual()">
                        Agregar TEB de Ubicaciones
                      </v-btn>


                    </v-col>
                  </v-row>
                  <v-col cols="12" lg="1"> </v-col>
                  <v-row> </v-row>

                  <v-col cols="12" lg="1">
                    <v-row justify="center">
                      <v-dialog v-model="dialogDeleteRecord" persistent max-width="760">
                        <v-card>
                          <v-card-title class="text-h4">
                            Eliminar registro para el equipo {{ dialogEquipo }}?
                            <h2 v-if="dialogUsuario" style="color: red">
                              Caputarado por {{ dialogUsuario }}
                            </h2>
                          </v-card-title>
                          <v-card-text>Esta a punto de eliminar el registro con los
                            siguientes datos: <br /><strong>Equipo: </strong>{{ dialogEquipo }} <br /><strong>Partida:
                            </strong>{{ dialogPartida }} <br /><strong>Volumen: </strong>{{ dialogVolumen }}
                            <br /><strong>Monto: </strong>{{ dialogMonto }} <br /><strong>Teb: </strong>{{ dialogTeb }}
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialogDeleteRecord = false">
                              Salir
                            </v-btn>
                            <v-btn color="green darken-1" text :disabled="dialogTimer" :loading="dialogTimer"
                              @click="eliminarItemTrazabilidad()">
                              Confirmar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </v-col>

                  <v-row>
                    <v-col cols="12" lg="12">
                      <template> </template>
                    </v-col>
                  </v-row>

                  <br />

                  <v-btn color="primary" text @click="ocultarNuevo()">
                    Salir
                  </v-btn>
                  <!--  <v-btn
                    :disabled="dialogTimer"
                    :loading="dialogTimer"
                    color="primary"
                    text
                    @click="guardar()"
                  >
                    Guardar
                  </v-btn> -->

                  <!--    <v-btn
                    :disabled="dialogTimer"
                    :loading="dialogTimer"
                    color="primary"
                    
                    @click="dialogGuardar()"
                  >
                    Guardar
                  </v-btn> -->

                  <!--  <v-btn

                    color="primary"
                    
                    @click="guardar2()"
                  >
                    Editar
                  </v-btn> -->

                  <v-dialog v-model="dialogTimer" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                      <v-card-text>
                        Por favor espere
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Catalogo from "../../api/Catalogo";
import Costeo from "../../api/Costeo";

export default {
  data() {
    return {
      moment: moment,
      headers: [
        {
          text: "Equipo",
          value: "activoEquipo",
          sortable: true,
          width: "150px",
        },
        {
          text: "Fecha",
          value: "fechaServicio",
          sortable: true,
          width: "120px",
        },
        { text: "Teb", value: "numeroDeSolicitud", sortable: true },
        {
          text: "Unidad",
          value: "tipoDeServicioSelec",
          sortable: true,
          width: "100px",
        },
        {
          text: "Estimación",
          value: "numeroEstimacion",
          sortable: true,
          width: "140px",
        },
        {
          text: "Monto Estimado",
          value: "montoEstimado",
          sortable: true,
          width: "180px",
        },
        { text: "Volumen", value: "volumen", sortable: true, width: "140px" },
        { text: "Partida", value: "partida", sortable: true, width: "140px" },
        {
          text: "Ubicación",
          value: "ubicacion",
          sortable: true,
          width: "140px",
        },
        { text: "OS", value: "ordenServicio", sortable: true, width: "380px" },
        {
          text: "Instalacíon",
          value: "instalacion_destino",
          sortable: true,
          width: "210px",
        },
        { text: "Descripcion", value: "descripcion", sortable: true },
      ],

      headers2: [
        {
          text: "Equipo",
          value: "activoEquipo",
          sortable: true,
          width: "150px",
        },
        {
          text: "Fecha",
          value: "fechaServicio",
          sortable: true,
          width: "120px",
        },

        { text: "Volumen", value: "volumen", sortable: true, width: "140px" },
        { text: "Partida", value: "partida", sortable: true, width: "140px" },
        { text: "Teb", value: "numeroDeSolicitud", sortable: true },
        { text: "Activo", value: "activoPemex", sortable: true },
        { text: "EPEP", value: "epep", sortable: true },
        { text: "PP", value: "programaPresupuestal", sortable: true },
        { text: "Descripcion", value: "descripcion", sortable: true },
      ],
      headersImportado: [
        { text: "Estimacion", value: "numeroEstimacion", sortable: false },
        {
          text: "Numero de Solicitud",
          value: "numeroDeSolicitud",
          sortable: true,
        },
        {
          text: "Instalacion/Destino",
          value: "instalacion_destino",
          sortable: true,
        },
        { text: "Equipo", value: "activoEquipo", sortable: true },
        {
          text: "Tipo de Servicio",
          value: "tipoDeServicioSelec",
          sortable: true,
        },
        { text: "Volumen", value: "volumen", sortable: true },
        { text: "Partida", value: "partida", sortable: true },
        { text: "Monto Estimado", value: "montoEstimado", sortable: true },
        { text: "Fecha de Servicio", value: "fechaServicio", sortable: true },
        { text: "Descripcion", value: "descripcion", sortable: true },
        { text: "Orden de Servicio", value: "ordenServicio", sortable: true },
        { text: "PP", value: "programaPresupuestal", sortable: true },
        { text: "Activo", value: "activoPemex", sortable: true },
        { text: "RAF", value: "raf", sortable: true },
        { text: "usuarioCreador", value: "usuarioCreador", sortable: true },
      ],
      page: 0,
      descripcion: "",
      loading: false,
      dialogDeleteRecord: false,
      partidasList: [],
      trazabilidadLista: [],
      dialogTimer: false,
      campoSeleccionadoPrueba: [],
      raf: "",
      osList: [],
      dialogEquipo: "",
      dialogVolumen: "",
      dialogPartida: "",
      dialogUsuario: "",
      dialogMonto: "",
      dialogFecha: "",
      dialogTeb: "",
      osEquiposinTraz: [],
      loader: null,
      campoSeleccionado: "",
      epep: "",
      ubicacionesOs: [],
      usuarioCreadorTrazabilidad: "",
      activoPemex: "",
      showDeleteAction: false,
      search: "",
      programaPresupuestalSelec: "",
      tipoDeServicioSelec: "",
      equiposActivosLista: [],
      mostrarTabla: false,
      hover: false,
      nombreContrato: "INTEGRAL EQ. TERRESTRE DB",
      inst_dest: [
        "MONOBOYAS",
        "PROCESO",
        "REMANENTE",
        "LOGISTICA",
        "INSPECCION TUBULAR",
        "URREP",
        "PLANTA DE LODOS",
        "SEYBAPLAYA",
        "ÁREA DE CAJAS",
        "TALLER DE ÁRBOLES",
        "VALVULAS",
        "COSECO",
        "PATRIMONIAL",
        "REMANENTE 1",
        "TALLER DE MANTENIMIENTO LITORAL",
        "TALLER DE MANTENIMIENTO LITORAL",
        "VENTAS",
        "ALMACEN GENERAL",
        "PROCESOS",
        "TANQUES 1",
        "TANQUES 2",
        "CD. PEMEX",
        "CENTRO DE ATENCIÓN A EVACUADOS POR HURACANES",
      ],
      partida: "",
      ordenServicio: "",
      activoCosteo: "",
      cantidad: "",
      partidas: [],
      epepSelecc: "",
      costoPartida: "",
      registroCosteo: [],
      monto_total_tra: 0,
      jornada_total_tra: 0,
      programasPressLista: [],
      programaPress: "",
      costo: "",
      costoUnitario: "",
      instalacion_destino: "",
      tebMesLista: [],
      volumen: "",
      montoEstimado: "",
      numeroEstimacion: "",
      listaInstDestPorPress: [],
      activoEquipo: "",
      tipoDeServicio: ["J", "D", "FS", "MTTO", "S", "HORA"],
      ubicacionServicio: [
        "TMDB",
        "Almacen de Bienes de Consumo",
        "Terminal Martima Arbol Grande",
        "Terminal Maritima Cobos",
      ],
      ubicacion: "",
      numeroDeSolicitud: "",
      date: [],
      menu: false,
      modal: false,
      documentos: [],
      menu2: false,
      menuServicio: false,
      fechaServicio: "",
      mesServicio: "",
      verNuevo: 0,
      editedIndex: -1,
      alert: "",
      totalPages: 0,
      dialogGuardarTrazabilidad: false,
      dialogLoading: false,
      tebsSinUno: [],
      options: {
        locale: "en-US",
        prefix: "$",
        suffix: "",
        length: 11,
        descripcion: "",
        company_logo: {
          w: 110,
          h: 35,
        },

        precision: 2,
      },
      trazabilidadImportDatos: "",
      trazabilidadsAdjuntosTabla: [],
      dialogVizualisarTrazabilidadImportada: false,
      trazabilidadTotalAmbos: [],
      karen: 0,
      kristell: 0,
      modo_edicion: false,
      selectedItem: 1,
      dialogLoadingTrazabilidad: false,
      equipoEditar: "",
      fechaServicioEditar: "",
      instalacionEditar: "",
      estimacionEditar: "",
      numeroDeSolicitudEditar: "",
      ordenServicioEditar: "",
      tipoServicioEditar: "",
      volumenEditar: 0,
      partidasEditar: 0,
      ubicacionEditar: "",
      instalacion_destino_editar: "",
      idEditar: "",
      idRegistroTrazabilidad: "",
      tebId: "",
      itemsFilter: [
        "Equipos",
        "Fecha",
        "TEB",
        "Unidad",
        "Partida",
        "Instalacion",
      ],
      unidadesLista: [
        'J', 'D', 'FS', 'MTTO', 'S', 'HORA'
      ],
      filtrosMenu: false,
      mostrarDialogActivosFilter: false,
      mostrarDialogUnidadFilter: false,
      mostrarDialogTebdFilter: false,
      mostrarDialogInstalacionFilter: false,
      mostrarDialogPartidaFilter: false,
      mostrarDialogFechaFilter: false,
      filtros: [],
      equipoSelecFiltro: "",
      unidadSelecFiltro: "",
      tebSelecFiltro: "",
      instalacionSelecFiltro: "",
      partidaSelecFiltro: "",
      fechaSelecFiltro: "",
      menuFechaFiltro: false,
      montoEstimadoInfo: 0,
      mostrarDialogTebsEncontrados: false,
      tebEncontrados: [],
      loadingTable: false,
      regTrazConTebAgregados: [],
      loadingSincrinize: false,
      registrosCosteoTodo: [],
      observacionesEditar: '',
      mostrarDialogTebUbicaciones: false,
      regAgregarTeb: [],
      regAgregarTebSelected: [],
      tebMensualServ: [],
      tebMensualServData: [],
      tebSeleccionadoMS: '',
      ppTebMensual: '',
      activoTebMensual: '',
      epepTebMensual: '',
      instalacionMensua: '',
      equiposTrazabilidad: [],
      marker: true,
    };
  },
  watch: {

    regAgregarTebSelected(newMont) {
      this.tebEncontrados.forEach((e) => {
        e.activoPemex = ''
        e.numeroDeSolicitud = ''
        e.programaPresupuestal = ''
        e.epep = ''
      })
      if (!this.tebSeleccionadoMS) {
        this.addErrorNotification2('TEB NO SELECCIONADO')
      } else {
        newMont.forEach((e) => {

          e.activoPemex = this.activoTebMensual
          e.numeroDeSolicitud = this.tebSeleccionadoMS
          e.programaPresupuestal = this.ppTebMensual
          e.epep = this.epepTebMensual
        })
      }
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo registro" : "Editar registro";
    },
    dateRangeText() {
      return this.periodo.join(" al ");
    },
  },
  created() {
    this.listar();
    this.listarUbicaciones();
  },
  methods: {
    listar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      this.dialogLoading = true;
      axios
        .get("/trazabilidad/list/", configuracion)
        .then(function (response) {

          me.trazabilidadLista = response.data;
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          me.dialogLoading = false;
        });
    },

    onSelect(selectedRows) {
      this.campoSeleccionado = selectedRows[0];
    },

    onDelete() {
      let id_largo = this.$refs.grid._data.selected[this.campoSeleccionado];
      let id_corto = id_largo.substr(id_largo.length - 7);
      // this.$refs.grid.deleteRecord(id_corto)
      if (this.campoSeleccionado) {
        let itemReg = this.$refs.grid._data.table[parseInt(id_corto)];
        this.dialogEquipo = itemReg.activoEquipo;
        this.dialogVolumen = itemReg.volumen;

        this.dialogMonto = itemReg.montoEstimado;
        this.dialogFecha = itemReg.fechaServicio;
        this.dialogTeb = itemReg.numeroDeSolicitud;
      } else {
        this.addErrorNotification2("Ningun registro seleccionado");
      }
    },

    eliminarRegistroFilter() {
      this.dialogTimer = true;
      let id_largo = this.$refs.grid._data.selected[this.campoSeleccionado];
      let id_corto = id_largo.substr(id_largo.length - 7);
      let idNumber = parseInt(id_corto);
      this.$refs.grid.deleteRecord(idNumber);
      this.campoSeleccionado = "";
      this.itemReg = [];
      //this.guardar()
      // this.dialogDeleteRecord = false;
    },

    eliminarRegistroFilter2() {
      let itemReg = this.$refs.grid.$data.table[this.campoSeleccionado];
      this.eliminarRegistro2(itemReg);
      this.dialogDeleteRecord = false;
    },

    eliminarRegistro2(item) {
      let i = this.registroCosteo.indexOf(item);
      if (i != -1) {
        this.registroCosteo.splice(i, 1);
      }
    },

    costoToDollar(text) {
      let number = parseFloat(text);
      return number.toLocaleString("en-US");
    },

    limpiar() {
      this.arrendadora = "";
      //  this.editedIndex = -1
      this.alert = false;
      this.numeroDeSolicitud = "";
      this.fechaServicio = "";
      this.ordenServicio = "";
      this.tipoDeServicioSelec = "";
      this.numeroEstimacion = "";
      this.volumen = "";
      this.partida = "";
      this.ubicacion = "";
      this.activoEquipo = "";
      this.instalacion_destino = "";
      this.descripcion = "";
      this.trazabilidadTotalAmbos = [];
      this.dialogGuardarTrazabilidad = false;
    },

    delRecord() {
      if (this.campoSeleccionado) {
        this.$refs.grid.deleteRecord(this.campoSeleccionado);
        this.campoSeleccionado = "";
      } else {
        this.addErrorNotification2("Error, registro no seleccionado");
      }
    },

    async guardar2() {
      this.dialogLoading = true;
      await Costeo.listQueryTrazabilidad(this._id)
        .then((e) => {
          for (let i = 0; i < e.data.trazabilidad.length; i++) {
            if (
              this.registroCosteo[i].activoEquipo !=
              e.data.trazabilidad[i].activoEquipo ||
              this.registroCosteo[i].descripcion !=
              e.data.trazabilidad[i].descripcion ||
              this.registroCosteo[i].fechaServicio !=
              e.data.trazabilidad[i].fechaServicio ||
              this.registroCosteo[i].instalacion_destino !=
              e.data.trazabilidad[i].instalacion_destino ||
              this.registroCosteo[i].numeroDeSolicitud !=
              e.data.trazabilidad[i].numeroDeSolicitud ||
              this.registroCosteo[i].partida !=
              e.data.trazabilidad[i].partida ||
              this.registroCosteo[i].tipoDeServicioSelec !=
              e.data.trazabilidad[i].tipoDeServicioSelec ||
              this.registroCosteo[i].ubicacion !=
              e.data.trazabilidad[i].ubicacion ||
              this.registroCosteo[i].volumen != e.data.trazabilidad[i].volumen
            ) {
              console.log(i);
            }
          }
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },

    guardar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      this.dialogTimer = true;

      if (this.editedIndex > -1) {
        this.corregirTrazabilidad();
        axios
          .put(
            "/trazabilidad/update",
            {
              _id: this._id,
              mes: this.mesServicio,
              trazabilidad: this.registroCosteo,
            },
            configuracion
          )
          .then(function () {
            me.limpiar();
            // me.listar();
            if (!me.modo_edicion) {
              Costeo.deleteHistorialTrazabilidad();
            }
            me.addSuccessNotification("Trazabilidad actualizada");
            me.dialogTimer = false;
            //me.ocultarNuevo();
          })
          .catch(function (e) {
            me.dialogTimer = false;
            me.addErrorNotification2(
              "Error, verifica la informacion agregada " + e
            );
          })
          .finally(() => {
            me.dialogTimer = false;
          });
      } else {
        axios
          .post(
            "/trazabilidad/add",
            {
              mes: this.mesServicio,
              trazabilidad: this.registroCosteo,
            },
            configuracion
          )
          .then(function () {
            me.limpiar();
            me.listar();
            me.dialogTimer = false;
            me.addSuccessNotification("Trazabilidad agregada");
            me.ocultarNuevo();
          })
          .catch(function (error) {
            me.addErrorNotification2(
              "Error, verifica la informacion agregada " + error
            );
            console.log(error);
          })
          .finally(() => {
            me.dialogTimer = false;
          });
      }
    },

    editItem(trazabilidad) {
      this.totalPages = 0;
      this.dialogLoading = true;
      this.page = 0
      // this.editItemTrazTodo(trazabilidad)
      Costeo.listQueryTrazabilidad(trazabilidad._id, this.page)
        .then((e) => {
          this._id = trazabilidad._id;
          this.page = 1

          if (e.data[0]) {
            this.totalPages = Math.ceil(trazabilidad.count / 15);
            this.registroCosteo = e.data[0].trazabilidad;
            this.monto_total_tra = e.data[0].total_monto
            this.jornada_total_tra = e.data[0].total_jornada
          }

          this.mostrarProgramaPress();
          this.obtenerEquiposTrazabilidad()
          this.mostrarPartidas();
          this.mostrarOs();
          (this.mesServicio = new Date(trazabilidad.mes)
            .toISOString()
            .substr(0, 7)),
            this.mostrarTepMes();

          this.verNuevo = 1;
          this.editedIndex = 1;
        })
        .catch((err) => {
          this.addErrorNotification2("error al cargar la trazabilidad" + err);
        })
        .finally(() => {
          this.dialogLoading = false;
        });

      // this.mostrarNuevo();
    },

    async editItemTrazTodo(trazabilidad) {
      await Costeo.queryTrazabili(trazabilidad._id).then((e) => {
        this.registrosCosteoTodo = e.data.trazabilidad
        this.registrosCosteoTodo.forEach((f) => {
          if (!f.numeroDeSolicitud) {
            (f.activoPemex = ""), (f.epep = "");
            f.programaPresupuestal = "";
          }
        })
      })
      let datos = {
        _id: trazabilidad._id,
        trazabilidad: this.registrosCosteoTodo
      }
      await Costeo.updateTodo(datos)
    },

    mostrarNuevo() {
      // this.listarAgencia(this.$store.state.usuario.agencia)
      this.mostrarProgramaPress();
      this.mostrarActivos();
      this.mostrarPartidas();
      this.mostrarOs();
      this.editedIndex = -1;
      this.limpiar();
      this.registroCosteo = [];
      this.verNuevo = 1;
    },
    ocultarNuevo() {
      this.verNuevo = 0;
      this.listar()
      this.limpiar();
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification2(msg) {
      this.$toast.error(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },

    mostrarPartidas() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let partidasListArr = [];
      let configuracion = { headers: header };
      axios
        .get("/partidas/list", configuracion)
        .then(function (response) {
          partidasListArr = response.data;
          partidasListArr.map((x) => {
            me.partidasList.push({
              text: x.numeroPartida,
              value: x.numeroPartida,
            });
          });
        })
        .catch(function () { });
    },
    mostrarOs() {
      let me = this;
      this.osList = [];
      let header = { Token: this.$store.state.token };
      let osListArr = [];
      let configuracion = { headers: header };
      axios
        .get("/os/osActivos", configuracion)
        .then(function (response) {
          osListArr = response.data;
          osListArr.map((x) => {
            let mesSub = x.periodo[0].substring(0, 7);
            if (me.mesServicio == mesSub) {
              me.osList.push(x.os);
            }
          });
        })
        .catch(function () { });
    },
    limpiarTrazabilidadFull() {
      this.registroCosteo = [];
    },

    mostrarEquiposDeOrden(item) {
      let me = this;
      let equiposArray = [];

      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/os/queryOs?os=" + item, configuracion)
        .then(function (response) {
          response.data.partidasEquipo.forEach((e) => {

            equiposArray.push(e.equipo);
          });
          let equipos = [];
          for (let i = 0; i < equiposArray.length; i++) {
            equipos = equipos.concat(equiposArray[i]);
          }
          let equiposSinHorario = []
          equipos.forEach((e) => {

            let equipo = e.split(" ")
            equiposSinHorario.push(equipo[0])

          })
          me.osEquiposinTraz = equiposSinHorario;
        })

        .catch(function (e) {
          console.log(e);
        });
    },

    mostrarActivosOs() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let osListArr = [];
      let configuracion = { headers: header };

      axios
        .get("/os/queryos?os=" + this.ordenServicio, configuracion)
        .then(function (response) {
          osListArr = response.data.equipos;
          osListArr.map((x) => {
            if (x) {
              me.osEquiposinTraz.push(x);
            }
          });
        })

        .catch(function (err) {
          console.log(err);
        });
    },

    changeIcon() {

      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      console.log('partidas')
      if (me.volumen && me.partida) {
        axios
          .get("/partidas/query?partida=" + this.partida, configuracion)
          .then(function (response) {
            me.partidas = response.data;

            if (me.partidas.equipoPartidas.length == 1) {

              me.osEquiposinTraz = me.partidas.equipoPartidas[0].equipo;
            } else if (me.partidas.equipoPartidas.length == 2) {

              let horario1 = me.partidas.equipoPartidas[0].horario;
              let eq1 = [];
              me.partidas.equipoPartidas[0].equipo.forEach((e) => {
                eq1.push(e + horario1);
              });

              let horario2 = me.partidas.equipoPartidas[1].horario;
              let eq2 = [];
              me.partidas.equipoPartidas[1].equipo.forEach((e) => {
                eq2.push(e + horario2);
              });

              let arr = [...eq1, ...eq2];
              me.osEquiposinTraz = arr;
            } else if (me.partidas.equipoPartidas.length == 3) {

              //   let arr = [
              //     ...me.partidas.equipoPartidas[0].equipo,
              //     ...me.partidas.equipoPartidas[1].equipo,
              //     ...me.partidas.equipoPartidas[2].equipo,
              //   ];
              //   me.osEquiposinTraz = arr;
              // } else if (me.partidas.equipoPartidas.length == 4) {
              //   let arr = [
              //     ...me.partidas.equipoPartidas[0].equipo,
              //     ...me.partidas.equipoPartidas[1].equipo,
              //     ...me.partidas.equipoPartidas[2].equipo,
              //     ...me.partidas.equipoPartidas[3].equipo,
              //   ];
              //   me.osEquiposinTraz = arr;
              let horario1 = me.partidas.equipoPartidas[0].horario;
              let eq1 = [];
              me.partidas.equipoPartidas[0].equipo.forEach((e) => {
                eq1.push(e + horario1);
              });

              let horario2 = me.partidas.equipoPartidas[1].horario;
              let eq2 = [];
              me.partidas.equipoPartidas[1].equipo.forEach((e) => {
                eq2.push(e + horario2);
              });

              let arr = [...eq1, ...eq2];
              me.osEquiposinTraz = arr;

            } else if (me.partidas.equipoPartidas.length == 0) {
              me.mostrarEquiposDeOrden(me.ordenServicio);
            }
            // const equiposSinHorario = []
            // me.osEquiposinTraz.forEach((e) => {
            //   const equipo = e.split('(')
            //   equiposSinHorario.push(equipo[0])
            // })

            // me.osEquiposinTraz = equiposSinHorario

            /*             console.log(me.osEquiposinTraz);
             */ //Ahora los equipos los jalo desde la orden de servicio
            //   me.osEquiposinTraz = response.data.equipoPartidas[0].equipo;
            //   if (response.data.equipoPartidas[1]) {
            //     for (
            //       let i = 0;
            //       i < response.data.equipoPartidas[1].equipo.length;
            //       i++
            //      ) {
            //        me.osEquiposinTraz.push(
            //          response.data.equipoPartidas[1].equipo[i]
            //        );
            //      }
            //    }

            if (me.partidas) {
              me.costoUnitario = me.partidas.costo;
              me.montoEstimado = me.costoUnitario * me.volumen;
              me.montoEstimadoInfo = me.roundedNumber(me.montoEstimado)

            } else {
              me.addErrorNotification();

              me.partida = "";
              (me.costoUnitario = ""), (me.montoEstimado = "");
            }
          })
          .catch(function () {
            me.osEquiposinTraz = [];
          });
      }
    },

    changeIconSinHorario() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      console.log('partidas')
      if (me.volumen && me.partida) {
        axios
          .get("/partidas/query?partida=" + this.partida, configuracion)
          .then(function (response) {
            me.partidas = response.data;

            if (me.partidas.equipoPartidas.length == 1) {

              me.osEquiposinTraz = me.partidas.equipoPartidas[0].equipo;
            } else if (me.partidas.equipoPartidas.length == 2) {

              let eq1 = [];
              me.partidas.equipoPartidas[0].equipo.forEach((e) => {
                eq1.push(e);
              });

              let eq2 = [];
              me.partidas.equipoPartidas[1].equipo.forEach((e) => {
                eq2.push(e );
              });

              let arr = [...eq1, ...eq2];
              me.osEquiposinTraz = arr;
            } else if (me.partidas.equipoPartidas.length == 3) {

              //   let arr = [
              //     ...me.partidas.equipoPartidas[0].equipo,
              //     ...me.partidas.equipoPartidas[1].equipo,
              //     ...me.partidas.equipoPartidas[2].equipo,
              //   ];
              //   me.osEquiposinTraz = arr;
              // } else if (me.partidas.equipoPartidas.length == 4) {
              //   let arr = [
              //     ...me.partidas.equipoPartidas[0].equipo,
              //     ...me.partidas.equipoPartidas[1].equipo,
              //     ...me.partidas.equipoPartidas[2].equipo,
              //     ...me.partidas.equipoPartidas[3].equipo,
              //   ];
              //   me.osEquiposinTraz = arr;
              let eq1 = [];
              me.partidas.equipoPartidas[0].equipo.forEach((e) => {
                eq1.push(e );
              });

              let eq2 = [];
              me.partidas.equipoPartidas[1].equipo.forEach((e) => {
                eq2.push(e );
              });

              let arr = [...eq1, ...eq2];
              me.osEquiposinTraz = arr;

            } else if (me.partidas.equipoPartidas.length == 0) {
              me.mostrarEquiposDeOrden(me.ordenServicio);
            }
            // const equiposSinHorario = []
            // me.osEquiposinTraz.forEach((e) => {
            //   const equipo = e.split('(')
            //   equiposSinHorario.push(equipo[0])
            // })

            // me.osEquiposinTraz = equiposSinHorario

            /*             console.log(me.osEquiposinTraz);
             */ //Ahora los equipos los jalo desde la orden de servicio
            //   me.osEquiposinTraz = response.data.equipoPartidas[0].equipo;
            //   if (response.data.equipoPartidas[1]) {
            //     for (
            //       let i = 0;
            //       i < response.data.equipoPartidas[1].equipo.length;
            //       i++
            //      ) {
            //        me.osEquiposinTraz.push(
            //          response.data.equipoPartidas[1].equipo[i]
            //        );
            //      }
            //    }

            if (me.partidas) {
              me.costoUnitario = me.partidas.costo;
              me.montoEstimado = me.costoUnitario * me.volumen;
              me.montoEstimadoInfo = me.roundedNumber(me.montoEstimado)

            } else {
              me.addErrorNotification();

              me.partida = "";
              (me.costoUnitario = ""), (me.montoEstimado = "");
            }
          })
          .catch(function () {
            me.osEquiposinTraz = [];
          });
      }
    },


    mostrarDatoSelecc(...args) {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/partidas/query?partida=" + args[0], configuracion)
        .then(function (response) {
          args[2].costoUnitario = response.data.costo;
          args[2].montoEstimado = response.data.costo * args[2].volumen;
        })
        .catch(function () {
          me.addErrorNotification();

          (args[2].costoUnitario = 0), (args[2].partida = 0);
          args[2].montoEstimado = 0;
        });
    },
    mostrarDatoSeleccVolumen(...args) {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/partidas/query?partida=" + args[2].partida, configuracion)
        .then(function (response) {
          let suma = 0;
          for (let i = 0; i < me.registroCosteo.length; i++) {
            if (
              me.registroCosteo[i].activoEquipo == args[2].activoEquipo &&
              me.registroCosteo[i].fechaServicio == args[2].fechaServicio &&
              me.registroCosteo[i].partida == args[2].partida
            ) {
              suma += parseFloat(me.registroCosteo[i].volumen);
            }
          }
          if (suma > 1) {
            me.addErrorNotification();

            args[2].volumen = args[1];
          } else {
            args[2].costoUnitario = response.data.costo;
            args[2].montoEstimado = response.data.costo * args[2].volumen;
          }
        })
        .catch(function () {
          me.addErrorNotification();
          (args[2].costoUnitario = 0), (args[2].partida = 0);
        });
    },

    mostrarDatosSeleccEquipo(newVal, oldVal, row) {
      let me = this;
      let index = this.registroCosteo.findIndex((i) => i.$id === row.$id);
      this.registroCosteo[index].activoEquipo = newVal;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/partidas/query?partida=" + row.partida, configuracion)
        .then(function () {
          let suma = 0;
          for (let i = 0; i < me.registroCosteo.length; i++) {
            console.log(me.registroCosteo.length);
            if (
              me.registroCosteo[i].activoEquipo == row.activoEquipo &&
              me.registroCosteo[i].fechaServicio == row.fechaServicio
            ) {
              suma += parseFloat(me.registroCosteo[i].volumen);
            }
          }

          if (suma > 1) {
            me.addErrorNotification();
            row.activoEquipo = oldVal;
          }
        })
        .catch(function (e) {
          console.log(e);
        });
    },

    mostrarDatosSeleccFecha(newVal, oldVal, row) {
      let me = this;
      let index = this.registroCosteo.findIndex((i) => i.$id === row.$id);
      this.registroCosteo[index].fechaServicio = newVal;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/partidas/query?partida=" + row.partida, configuracion)
        .then(function () {
          let suma = 0;
          for (let i = 0; i < me.registroCosteo.length; i++) {
            console.log(me.registroCosteo.length);
            if (
              me.registroCosteo[i].activoEquipo == row.activoEquipo &&
              me.registroCosteo[i].fechaServicio == row.fechaServicio
            ) {
              suma += parseFloat(me.registroCosteo[i].volumen);
            }
          }

          if (suma > 1) {
            me.addErrorNotification();
            row.fechaServicio = oldVal;
          }
        })
        .catch(function (e) {
          console.log(e);
        });
    },

    eliminarRegistro(item) {
      let i = this.registroCosteo.indexOf(item);
      if (i != -1) {
        this.registroCosteo.splice(i, 1);
      }
    },

    agregarRegistro() {
      if (this.numeroDeSolicitud == '' || this.numeroDeSolicitud == null) {
        this.activoPemex = ''
        this.programaPresupuestalSelec = ''
        this.epepSelecc = ''
      }
      let datos = {
        numeroEstimacion: this.numeroEstimacion,
        numeroDeSolicitud: this.numeroDeSolicitud,
        instalacion_destino: this.instalacion_destino,
        activoEquipo: this.activoEquipo,
        tipoDeServicioSelec: this.tipoDeServicioSelec,
        activo: this.activoCosteo,
        volumen: this.volumen,
        partida: this.partida,
        costoUnitario: this.costoUnitario,
        montoEstimado: this.montoEstimado,
        fechaServicio: this.fechaServicio,
        descripcion: this.descripcion,
        ubicacion: this.ubicacion,
        ordenServicio: this.ordenServicio,
        programaPresupuestal: this.programaPresupuestalSelec,
        epep: this.epepSelecc,
        activoPemex: this.activoPemex,
        raf: this.raf,
        usuarioCreador: this.$store.state.usuario.personal.nombreCompleto,
        idTrazabilidad: this._id,
      };

      if (this.partida == 11 || this.partida == 12 || this.partida == 13 || this.partida == 14 || this.partida == 15 || this.partida == 16 || this.partida == 23 || this.partida == 24 || this.partida == 26 || this.partida == 27 || this.partida == 29 || this.partida == 30 || this.partida == 32 || this.partida == 33 || this.partida == 50.01 || this.partida == 50.02 || this.partida == 51.01 || this.partida == 51.02 || this.partida == 52.01 || this.partida == 52.02 || this.partida == 53.01 || this.partida == 53.02 || this.partida == 54.01 || this.partida == 54.02 || this.partida == 55.01 || this.partida == 56.01 || this.partida == 56.02) {
        this.agregarRegistrosNormal(datos)
      } else if (this.partida == 25 || this.partida == 28 || this.partida == 31 || this.partida == 34 || this.partida == 50.03 || this.partida == 50.04 || this.partida == 50.05 || this.partida == 51.03 || this.partida == 51.04 || this.partida == 51.05 || this.partida == 52.03 || this.partida == 52.04 || this.partida == 52.05 || this.partida == 53.03 || this.partida == 53.04 || this.partida == 54.03 || this.partida == 54.04) {
        this.agregarRegistrosMayorUno(datos)
      } else {
        if (this.partida == 1 || this.partida == 57.01) {
          let partidas = [{ partida: 1 }, { partida: 57.01 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 2 || this.partida == 57.02) {
          let partidas = [{ partida: 2 }, { partida: 57.02 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 3 || this.partida == 57.03) {
          let partidas = [{ partida: 3 }, { partida: 57.03 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 4 || this.partida == 57.04) {
          let partidas = [{ partida: 4 }, { partida: 57.04 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 5 || this.partida == 57.05) {
          let partidas = [{ partida: 5 }, { partida: 57.05 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 6 || this.partida == 57.06) {
          let partidas = [{ partida: 6 }, { partida: 57.06 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 7 || this.partida == 57.07) {
          let partidas = [{ partida: 7 }, { partida: 57.07 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 8 || this.partida == 57.08) {
          let partidas = [{ partida: 8 }, { partida: 57.08 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 9 || this.partida == 57.09) {
          let partidas = [{ partida: 9 }, { partida: 57.09 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 10 || this.partida == 57.10) {
          let partidas = [{ partida: 10 }, { partida: 57.10 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 17 || this.partida == 57.11) {
          let partidas = [{ partida: 17 }, { partida: 57.11 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 18 || this.partida == 57.12) {
          let partidas = [{ partida: 18 }, { partida: 57.12 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 19 || this.partida == 57.13) {
          let partidas = [{ partida: 19 }, { partida: 57.13 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 20 || this.partida == 57.14) {
          let partidas = [{ partida: 20 }, { partida: 57.14 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 21 || this.partida == 57.15) {
          let partidas = [{ partida: 21 }, { partida: 57.15 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 22 || this.partida == 57.16) {
          let partidas = [{ partida: 22 }, { partida: 57.16 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 35 || this.partida == 57.17) {
          let partidas = [{ partida: 35 }, { partida: 57.17 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 36 || this.partida == 57.18) {
          let partidas = [{ partida: 36 }, { partida: 57.18 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 37 || this.partida == 57.19) {
          let partidas = [{ partida: 37 }, { partida: 57.19 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 38 || this.partida == 57.20) {
          let partidas = [{ partida: 38 }, { partida: 57.20 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 39 || this.partida == 57.21) {
          let partidas = [{ partida: 39 }, { partida: 57.21 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 40 || this.partida == 57.22) {
          let partidas = [{ partida: 40 }, { partida: 57.22 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 41 || this.partida == 57.23) {
          let partidas = [{ partida: 41 }, { partida: 57.23 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 42 || this.partida == 57.24) {
          let partidas = [{ partida: 42 }, { partida: 57.24 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 43 || this.partida == 57.25) {
          let partidas = [{ partida: 43 }, { partida: 57.25 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 44 || this.partida == 57.26) {
          let partidas = [{ partida: 44 }, { partida: 57.26 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 45 || this.partida == 57.27) {
          let partidas = [{ partida: 45 }, { partida: 57.27 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 46 || this.partida == 57.28) {
          let partidas = [{ partida: 46 }, { partida: 57.28 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 47 || this.partida == 57.29) {
          let partidas = [{ partida: 47 }, { partida: 57.29 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 48 || this.partida == 57.30) {
          let partidas = [{ partida: 48 }, { partida: 57.30 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        } else if (this.partida == 49 || this.partida == 57.31) {
          let partidas = [{ partida: 49 }, { partida: 57.31 }]
          let dat = [datos, partidas]
          this.agregarRegistrosEspecial(dat)
        }

      }
    },

    limpiarTrazabilidad() {
      // this.volumen = ''
      // this.partida = ''
      this.montoEstimado = '';
      this.montoEstimadoInfo = 0

      // this.volumenEditar = ''
      // this.partidasEditar = ''
    },

    async agregarRegistrosNormal(datos) {
      this.loadingTable = true
      await Costeo.pushTrazabilidad(datos).then((e) => {
        if (e.data.mensaje == "mayor") {
          this.addErrorNotification2(
            "Error, equipo y partida con un total de " +
            e.data.suma +
            " jornadas"
          );
        } else {
          this.addSuccessNotification(
            "Registro ingresado correctamente, volumen = " + e.data.suma
          );

          this.paginacionTrazabilidad();
        }
      }).finally(() => {
        this.limpiarTrazabilidad()
        this.loadingTable = false
      })
    },



    async agregarRegistrosMayorUno(datos) {
      this.loadingTable = true
      await Costeo.pushTrazabilidadMayorUno(datos).then(() => {
        this.paginacionTrazabilidad();
        this.addSuccessNotification('Registro ingresado correctamente')
      }).catch((err) => {
        console.log(err)
        this.addErrorNotification2('Error, verifica con el administrador ', +err)
      }).finally(() => {
        this.limpiarTrazabilidad()
        this.loadingTable = false
      })
    },

    async agregarRegistrosEspecial(datos) {
      this.loadingTable = true
      await Costeo.pushTrazabilidadEspecial(datos).then((e) => {
        if (e.data.mensaje == "mayor") {
          this.addErrorNotification2(
            "Error, equipo y partida con un total de " +
            e.data.suma +
            " jornadas"
          );
        } else {
          this.addSuccessNotification(
            "Registro ingresado correctamente, volumen = " + e.data.suma
          );

          this.paginacionTrazabilidad();
        }
      }).catch((err) => {

        this.addErrorNotification2('Error, verifica con el administrador ', +err)
      }).finally(() => {
        this.limpiarTrazabilidad()
        this.loadingTable = false
      })
    },


    mostrarInstDest() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };

      axios
        .get(
          "/programasPress/query?programaPresupuestal=" + this.programaPress,
          configuracion
        )
        .then(function (response) {
          me.listaInstDestPorPress = response.data.inst_dest_items;
          me.epep = response.data.epep;
          me.activoCosteo = response.data.activo;
        })
        .catch(function () { });
    },
    mostrarProgramaPress() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let presupuestoArray = [];
      let configuracion = { headers: header };
      axios
        .get("/programasPress/list", configuracion)
        .then(function (response) {
          presupuestoArray = response.data;
          presupuestoArray.map((x) => {
            me.programasPressLista.push({
              text: x.programaPresupuestal,
              value: x.programaPresupuestal,
            });
          });
        })
        .catch(function () { });
    },
    mostrarTepMes() {
      this.menu2 = false;
      let me = this;
      let header = { Token: this.$store.state.token };
      let tebMesArray = [];
      this.tebMesLista = [];
      let configuracion = { headers: header };
      axios
        .get("/teb/queryMes?mes=" + this.mesServicio, configuracion)
        .then(function (response) {
          tebMesArray = response.data.tebRegistros;
          me.tebId = response.data._id;
          tebMesArray.map((x) => {
            me.tebMesLista.push(x.teb);
          });
        })
        .catch(function (e) {
          console.log("error" + e);
        });
    },

    obtenerDatos(id) {
      this.loading = true;
      axios({
        url: "/trazabilidad/listDatosReg?_id=" + id, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      });
    },

    obtenerGenerador(id) {
      this.loading = true;
      axios({
        url: "/trazabilidad/obtenerGenerador?_id=" + id, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "generador.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      });
    },

    mostrarFechaTeb() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let tebMesArray = [];
      let configuracion = { headers: header };
      axios
        .get("/teb/queryMes?mes=" + this.mesServicio, configuracion)
        .then(function (response) {
          tebMesArray = response.data.tebRegistros;

          let arr = tebMesArray.find(
            (element) => element.teb == me.numeroDeSolicitud
          );

          let date = arr.fechaRecibido.split("/");
          let date2 = new Date(date[2], date[1] - 1, date[0]);
          if (arr.fechaRecibido.includes("-")) {
            me.fechaServicio = arr.fechaRecibido.replace("/", "-");
          } else {
            me.fechaServicio = date2.toISOString().substr(0, 10);
          }
          /*   me.fechaServicio = arr.fechaRecibido.replace('/','-')
            console.log(new Date(arr.fechaRecibido.replace('/','-'))) */
          me.programaPresupuestalSelec = arr.programaPressL;
          me.epepSelecc = arr.epepRegistro;
          me.activoPemex = arr.activo;
          me.activoEquipo = arr.equiposRequeridosReg;
          me.instalacion_destino = arr.instalacionD;
        })
        .catch(function (e) {
          console.log("error" + e);
        });
    },

    async agregarTebUbicacionMensual() {
      this.tebEncontrados = []
      this.mostrarDialogTebUbicaciones = true
      this.tebMensualServ = this.tebMesLista
      await Costeo.buscarTebMes(this.mesServicio).then((e) => {
        this.tebMensualServData = e.data.tebRegistros
      })
      await Costeo.trazabilidadMesCompleto(this.mesServicio).then((e) => {
        e.data.trazabilidad.forEach((e) => {
          if (e.descripcion == 'TEB MENSUAL' && !e.numeroDeSolicitud) {
            this.tebEncontrados.push(e)
          }
        })
      })
    },


    async agregarTebUbicacion() {
      let encontrados = [];
      this.dialogLoading = true
      await Costeo.trazabilidadMesCompleto(this.mesServicio).then(async (e) => {
        let trazabilidad = e.data.trazabilidad
        await Costeo.buscarTebMes(this.mesServicio).then((e) => {
          let sinTeb = [];
          e.data.tebRegistros.forEach((f) => {
            if (e.observaciones == 'TEB MENSUAL' || e.observaciones == 'SERVICIO' || e.observaciones == 'MENSUAL') {
              console.log(f)
              this.tebMensualServ.push(f.teb)
              this.tebMensualServData.push(f)
            }
          })

          for (let reg of trazabilidad) {
            if (reg.numeroDeSolicitud == '' || reg.numeroDeSolicitud == null) {
              sinTeb.push(reg);
            }
          }

          let equiposIndivuales = [];
          e.data.tebRegistros.forEach((e) => {
            let equipos = e.equiposRequeridosReg.split(", ");
            if (e.observaciones == 'TEB MENSUAL') {

              equipos.forEach((f) => {
                let equipo = ''
                if (f == 'MANIOBRISTA 1') {
                  equipo = 'MANIOBRISTA 01'
                } else if (f == 'MANIOBRISTA 2') {
                  equipo = 'MANIOBRISTA 02'
                } else if (f == 'MANIOBRISTA 3') {
                  equipo = 'MANIOBRISTA 03'
                } else if (f == 'MANIOBRISTA 4') {
                  equipo = 'MANIOBRISTA 04'
                } else if (f == 'MANIOBRISTA 5') {
                  equipo = 'MANIOBRISTA 05'
                } else if (f == 'MANIOBRISTA 6') {
                  equipo = 'MANIOBRISTA 06'
                } else if (f == 'MANIOBRISTA 7') {
                  equipo = 'MANIOBRISTA 07'
                } else if (f == 'MANIOBRISTA 8') {
                  equipo = 'MANIOBRISTA 08'
                } else if (f == 'MANIOBRISTA 9') {
                  equipo = 'MANIOBRISTA 09'
                } else {
                  equipo = f
                }
                equiposIndivuales.push({
                  equipo: equipo,
                  activo: e.activo,
                  epep: e.epepRegistro,
                  pp: e.programaPressL,
                  teb: e.teb,
                  instalacionD: e.instalacionD,
                  fechaSolicitada: e.fechaRecibido,
                });
              });
            }
          });
          sinTeb.forEach((e) => {
            if (e.instalacion_destino == 'INSPECCIÓN TUBULAR') {
              e.instalacion_destino = 'INSPECCION TUBULAR'
            }
            if (e.activoEquipo == 'CGF-32') {
              e.activoEquipo = 'CFG-32'
            } else if (e.activoEquipo == 'CGF-34') {
              e.activoEquipo = 'CFG-34'
            }
          })
          let testP = []
          sinTeb.forEach((e) => {
            testP.push(e)

            let enc = equiposIndivuales.find(
              (reg) =>
                reg.equipo == e.activoEquipo &&
                reg.instalacionD == e.instalacion_destino
            );
            if (enc != undefined) {
              encontrados.push(e);
            }
          });
          this.tebEncontrados = encontrados

          this.mostrarDialogTebUbicaciones = true
          /* this.registroCosteo.forEach((e)=>{
            encontrados.forEach((f)=>{
              if(e._id==f._id){
                e.activoPemex = f.activo
                e.numeroDeSolicitud = f.teb
                e.programaPresupuestal = f.pp
                e.epep = f.epep
                e.activo = f.activo
              }
            })
          }) */
        }).finally(() => {
          this.dialogLoading = false
        })
      })


    },
    mostrarActivos() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let equiposActivos = [];
      let configuracion = { headers: header };
      axios
        .get("/activos/listActivosPemex", configuracion)
        .then(function (response) {
          equiposActivos = response.data;

          equiposActivos.map((x) => {
            me.equiposActivosLista.push(x.numeroEconomico);
          });
          me.equiposActivosLista.push("MANIOBRISTA 01");
          me.equiposActivosLista.push("MANIOBRISTA 02");
          me.equiposActivosLista.push("MANIOBRISTA 03");
          me.equiposActivosLista.push("MANIOBRISTA 04");
          me.equiposActivosLista.push("MANIOBRISTA 05");
          me.equiposActivosLista.push("MANIOBRISTA 06");
          me.equiposActivosLista.push("MANIOBRISTA 07");
          me.equiposActivosLista.push("MANIOBRISTA 08");
          me.equiposActivosLista.push("MANIOBRISTA 09");
          me.equiposActivosLista.push("MANIOBRISTA 10");
          me.equiposActivosLista.push("MANIOBRISTA 11");
          me.equiposActivosLista.push("MANIOBRISTA 12");
          me.equiposActivosLista.push("MANIOBRISTA 13");
          me.equiposActivosLista.push("MANIOBRISTA 14");
          me.equiposActivosLista.push("MANIOBRISTA 15");
          me.equiposActivosLista.push("MANIOBRISTA 16");
          me.equiposActivosLista.push("MANIOBRISTA 17");
          me.equiposActivosLista.push("MANIOBRISTA 18");
          me.equiposActivosLista.push("MANIOBRISTA 19");
          me.equiposActivosLista.push("MANIOBRISTA 20");
          me.equiposActivosLista.push("MANIOBRISTA 21");
          me.equiposActivosLista.push("MANIOBRISTA 22");
          me.equiposActivosLista.push("MANIOBRISTA 23");
          me.equiposActivosLista.push("MANIOBRISTA 24");
          me.equiposActivosLista.push("MANIOBRISTA 25");
          me.equiposActivosLista.push("MANIOBRISTA 26");
          me.equiposActivosLista.push("MANIOBRISTA 27");
          me.equiposActivosLista.push("MANIOBRISTA 28");
          me.equiposActivosLista.push("MANIOBRISTA 29");
          me.equiposActivosLista.push("MANIOBRISTA 30");
          me.equiposActivosLista.push("MANIOBRISTA 31");
          me.equiposActivosLista.push("MANIOBRISTA 32");
          me.equiposActivosLista.push("MANIOBRISTA 33");
          me.equiposActivosLista.push("MANIOBRISTA 34");
          me.equiposActivosLista.push("MANIOBRISTA 35");
          me.equiposActivosLista.push("MANIOBRISTA 36");
          me.equiposActivosLista.push("MANIOBRISTA 37");
          me.equiposActivosLista.push("MANIOBRISTA 38");
          me.equiposActivosLista.push("MANIOBRISTA 39");
          me.equiposActivosLista.push("MANIOBRISTA 40");
          me.equiposActivosLista.push("MANIOBRISTA 41");
          me.equiposActivosLista.push("MANIOBRISTA 42");
          me.equiposActivosLista.push("MANIOBRISTA 43");
          me.equiposActivosLista.push("MANIOBRISTA 44");
          me.equiposActivosLista.push("MANIOBRISTA 45");
          me.equiposActivosLista.push("MANIOBRISTA 46");
          me.equiposActivosLista.push("MANIOBRISTA 47");
          me.equiposActivosLista.push("MANIOBRISTA 48");
          me.equiposActivosLista.push("MANIOBRISTA 49");
          me.equiposActivosLista.push("MANIOBRISTA 50");
          me.equiposActivosLista.push("CABO 01");
          me.equiposActivosLista.push("CABO 02");
          me.equiposActivosLista.push("CABO 03");
          me.equiposActivosLista.push("CABO 04");
          me.equiposActivosLista.push("CABO 05");
          me.equiposActivosLista.push("CABO 06");
          me.equiposActivosLista.push("CABO 07");
          me.equiposActivosLista.push("CABO 08");
          me.equiposActivosLista.push("CABO 09");
          me.equiposActivosLista.push("CABO 10");
          me.equiposActivosLista.push("CABO 11");
          me.equiposActivosLista.push(" -37");
        })
        .catch(function () { });
    },

    listarUbicaciones() {
      Catalogo.obtenerUbicacionesOs().then((e) => {
        this.ubicacionesOs = e.data;
      });
    },

    obtnerDatosTrazabilidadImportada() {
      let me = this;
      var file = this.trazabilidadImportDatos;

      this.$papa.parse(file, {
        header: true,
        complete: function (results) {
          me.trazabilidadsAdjuntosTabla = results.data;
          me.trazabilidadsAdjuntosTabla.splice(
            me.trazabilidadsAdjuntosTabla.length - 1,
            1
          );
        },
      });
    },
    vizualizarTrazabilidadImportada() {
      this.dialogVizualisarTrazabilidadImportada = true;
      this.trazabilidadsAdjuntosTabla.forEach((e) => {
        parseFloat(e.montoEstimado);
      });
    },
    agregarTrazabilidadImportada() {
      this.dialogVizualisarTrazabilidadImportada = false;
      this.registroCosteo = [
        ...this.registroCosteo,
        ...this.trazabilidadsAdjuntosTabla,
      ];
    },

    async dialogGuardar() {
      if (this.modo_edicion == true) {
        this.guardar();
        return;
      }
      Costeo.listHistorialTrazabilidad().then((e) => {
        this.trazabilidadTotalAmbos = e.data;
        this.karen = 0;
        this.kristell = 0;
        e.data.forEach((e) => {
          if (e.usuarioCreador == "Karen Domínguez") {
            this.karen++;
          } else {
            this.kristell++;
          }
        });
        this.dialogGuardarTrazabilidad = true;
      });
    },

    async historialTrazabilidadAdd(datos) {
      this.dialogLoading = true;
      await Costeo.insertarTrazabilidadHistorial(datos).finally(() => {
        this.dialogLoading = false;
      });
    },
    async guardarTrazabilidadMult() {
      this.dialogLoading = true;
      await Costeo.listQueryTrazabilidad(this._id)
        .then((e) => {
          this.registroCosteo = [
            ...e.data.trazabilidad,
            ...this.trazabilidadTotalAmbos,
          ];
          this.guardar();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },

    async agregarTeb() {
      // let encontrados = [];
      this.dialogLoading = true
      await Costeo.trazabilidadMesCompleto(this.mesServicio).then(async (e) => {
        let trazabilidad = e.data.trazabilidad
        let agregarTebReg = []
        trazabilidad.forEach((e) => {
          if (e.descripcion.substring(0, 3) == 'TEB' && (e.numeroDeSolicitud == '' || e.numeroDeSolicitud == null)) {
            e.idTrazabilidad = e._id
            agregarTebReg.push(e)
          }
        })
        this.tebEncontrados = agregarTebReg
        await Costeo.buscarTebMes(this.mesServicio).then((e) => {
          agregarTebReg.forEach((f) => {
            let busqueda = e.data.tebRegistros.find((e) => e.teb == f.descripcion)
            if (busqueda) {
              f.activoPemex = busqueda.activo
              f.epep = busqueda.epepRegistro
              f.programaPresupuestal = busqueda.programaPressL
              f.numeroDeSolicitud = busqueda.teb
              f._id = this._id
            }

          })
          agregarTebReg.forEach((e) => {
            if (e.numeroDeSolicitud) {
              this.regTrazConTebAgregados.push(e)
            }
          })
          /*   for(const traz of agregarTebReg){
              await  this.editarRegistrosMayorUno(traz)
            } */
          /* this.registroCosteo.forEach((e)=>{
            encontrados.forEach((f)=>{ 
              if(e._id==f._id){
                e.activoPemex = f.activo
                e.numeroDeSolicitud = f.teb
                e.programaPresupuestal = f.pp
                e.epep = f.epep
                e.activo = f.activo
              }
            })
          }) */
        }).finally(() => {
          this.dialogLoading = false
          this.mostrarDialogTebsEncontrados = true
        })
      })

    },

    async updateTrazabilidadTeb() {
      for (const traz of this.regTrazConTebAgregados) {
        this.loadingSincrinize = true

        await this.editarRegistrosMayorUno(traz).then(() => {

        }).catch((err) => {
          this.addErrorNotification2(err)
        }).finally(() => {
          this.loadingSincrinize = false
        })

      }
    },

    imprimirTrazabilidSinTeb() {
      this.registroCosteo.forEach((e) => {
        if (!e.numeroDeSolicitud) {
          console.log(e);
        }
      });
    },

    corregirTrazabilidad() {
      this.registroCosteo.forEach((e) => {
        if (!e.numeroDeSolicitud) {
          (e.activoPemex = ""), (e.epep = "");
          e.programaPresupuestal = "";
        }
      });
    },

    obtenerEquiposTrazabilidad() {
      Costeo.obtenerEquiposTrazabilidad(this._id).then((e) => {
        let equiposSinHorario = []
        e.data.forEach((e) => {
          let equipo = e.split("(")
          equiposSinHorario.push(equipo[0])
        })
        this.equiposTrazabilidad = equiposSinHorario
      })
    },



    paginacionTrazabilidad() {

      Costeo.listQueryTrazabilidad(this._id, this.page - 1, this.filtros).then((e) => {
        this.totalPages = Math.ceil(e.data[0].count / 15);
        this.registroCosteo = e.data[0].trazabilidad;
        this.monto_total_tra = e.data[0].total_monto
        this.jornada_total_tra = e.data[0].total_jornada

      });
    },

    dialogTrazabilidadRegistro(item) {

      this.mostrarEquiposDeOrdenEditar(item.ordenServicio);
      this.dialogLoadingTrazabilidad = true;
      this.numeroDeSolicitudEditar = item.numeroDeSolicitud;
      this.equipoEditar = item.activoEquipo;
      this.fechaServicioEditar = item.fechaServicio;
      this.instalacionEditar = item.instalacion_destino;
      this.estimacionEditar = item.numeroEstimacion;
      this.ordenServicioEditar = item.ordenServicio;
      this.tipoServicioEditar = item.tipoDeServicioSelec;
      this.volumenEditar = item.volumen;
      this.partidasEditar = item.partida;
      this.ubicacionEditar = item.ubicacion;
      this.instalacion_destino_editar = item.instalacion_destino;
      this.montoEstimado = item.montoEstimado
      this.programaPresupuestalSelec = item.programaPresupuestal;
      this.epepSelecc = item.epep;
      this.activoPemex = item.activoPemex;
      this.idRegistroTrazabilidad = item._id;
      this.observacionesEditar = item.descripcion
      this.montoEstimadoInfo = this.roundedNumber(item.montoEstimado)
    },

    updateRegistroTrazabilidad() {
      if (this.numeroDeSolicitudEditar == '' || this.numeroDeSolicitudEditar == null) {
        this.activoPemex = ''
        this.programaPresupuestalSelec = ''
        this.epepSelecc = ''
      }
      console.log(this.activoPemex)
      let datos = {
        activoEquipo: this.equipoEditar,
        activoPemex: this.activoPemex,
        epep: this.epepSelecc,
        fechaServicio: this.fechaServicioEditar,
        instalacion_destino: this.instalacion_destino_editar,
        descripcion: this.observacionesEditar,
        montoEstimado: this.montoEstimado,
        numeroDeSolicitud: this.numeroDeSolicitudEditar,
        numeroEstimacion: this.estimacionEditar,
        ordenServicio: this.ordenServicioEditar,
        partida: this.partidasEditar,
        programaPresupuestal: this.programaPresupuestalSelec,
        tipoDeServicioSelec: this.tipoServicioEditar,
        ubicacion: this.ubicacionEditar,
        volumen: this.volumenEditar,
        idTrazabilidad: this.idRegistroTrazabilidad,
        _id: this._id,
      };

      if (this.partidasEditar == 11 || this.partidasEditar == 12 || this.partidasEditar == 13 || this.partidasEditar == 14 || this.partidasEditar == 15 || this.partidasEditar == 16 || this.partidasEditar == 23 || this.partidasEditar == 24 || this.partidasEditar == 26 || this.partidasEditar == 27 || this.partidasEditar == 29 || this.partidasEditar == 30 || this.partidasEditar == 32 || this.partidasEditar == 33 || this.partidasEditar == 50.01 || this.partidasEditar == 50.02 || this.partidasEditar == 51.01 || this.partidasEditar == 51.02 || this.partidasEditar == 52.01 || this.partidasEditar == 52.02 || this.partidasEditar == 53.01 || this.partidasEditar == 53.02 || this.partidasEditar == 54.01 || this.partidasEditar == 54.02 || this.partidasEditar == 55.01 || this.partidasEditar == 56.01 || this.partidasEditar == 56.02) {
        this.editarTrazabilidadNormal(datos)
      } else if (this.partidasEditar == 25 || this.partidasEditar == 28 || this.partidasEditar == 31 || this.partidasEditar == 34 || this.partidasEditar == 50.03 || this.partidasEditar == 50.04 || this.partidasEditar == 50.05 || this.partidasEditar == 51.03 || this.partidasEditar == 51.04 || this.partidasEditar == 51.05 || this.partidasEditar == 52.03 || this.partidasEditar == 52.04 || this.partidasEditar == 52.05 || this.partidasEditar == 53.03 || this.partidasEditar == 53.04 || this.partidasEditar == 54.03 || this.partidasEditar == 54.04) {
        this.editarRegistrosMayorUno(datos)
      } else {
        if (this.partidasEditar == 1 || this.partidasEditar == 57.01) {
          let partidas = [{ partida: 1 }, { partida: 57.01 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 2 || this.partidasEditar == 57.02) {
          let partidas = [{ partida: 2 }, { partida: 57.02 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 3 || this.partidasEditar == 57.03) {
          let partidas = [{ partida: 3 }, { partida: 57.03 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 4 || this.partidasEditar == 57.04) {
          let partidas = [{ partida: 4 }, { partida: 57.04 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 5 || this.partidasEditar == 57.05) {
          let partidas = [{ partida: 5 }, { partida: 57.05 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 6 || this.partidasEditar == 57.06) {
          let partidas = [{ partida: 6 }, { partida: 57.06 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 7 || this.partidasEditar == 57.07) {
          let partidas = [{ partida: 7 }, { partida: 57.07 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 8 || this.partidasEditar == 57.08) {
          let partidas = [{ partida: 8 }, { partida: 57.08 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 9 || this.partidasEditar == 57.09) {
          let partidas = [{ partida: 9 }, { partida: 57.09 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 10 || this.partidasEditar == 57.10) {
          let partidas = [{ partida: 10 }, { partida: 57.10 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 17 || this.partidasEditar == 57.11) {
          let partidas = [{ partida: 17 }, { partida: 57.11 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 18 || this.partidasEditar == 57.12) {
          let partidas = [{ partida: 18 }, { partida: 57.12 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 19 || this.partidasEditar == 57.13) {
          let partidas = [{ partida: 19 }, { partida: 57.13 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 20 || this.partidasEditar == 57.14) {
          let partidas = [{ partida: 20 }, { partida: 57.14 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 21 || this.partidasEditar == 57.15) {
          let partidas = [{ partida: 21 }, { partida: 57.15 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 22 || this.partidasEditar == 57.16) {
          let partidas = [{ partida: 22 }, { partida: 57.16 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 35 || this.partidasEditar == 57.17) {
          let partidas = [{ partida: 35 }, { partida: 57.17 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 36 || this.partidasEditar == 57.18) {
          let partidas = [{ partida: 36 }, { partida: 57.18 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 37 || this.partidasEditar == 57.19) {
          let partidas = [{ partida: 37 }, { partida: 57.19 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 38 || this.partidasEditar == 57.20) {
          let partidas = [{ partida: 38 }, { partida: 57.20 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 39 || this.partidasEditar == 57.21) {
          let partidas = [{ partida: 39 }, { partida: 57.21 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 40 || this.partidasEditar == 57.22) {
          let partidas = [{ partida: 40 }, { partida: 57.22 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 41 || this.partidasEditar == 57.23) {
          let partidas = [{ partida: 41 }, { partida: 57.23 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 42 || this.partidasEditar == 57.24) {
          let partidas = [{ partida: 42 }, { partida: 57.24 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 43 || this.partidasEditar == 57.25) {
          let partidas = [{ partida: 43 }, { partida: 57.25 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 44 || this.partidasEditar == 57.26) {
          let partidas = [{ partida: 44 }, { partida: 57.26 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 45 || this.partidasEditar == 57.27) {
          let partidas = [{ partida: 45 }, { partida: 57.27 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 46 || this.partidasEditar == 57.28) {
          let partidas = [{ partida: 46 }, { partida: 57.28 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 47 || this.partidasEditar == 57.29) {
          let partidas = [{ partida: 47 }, { partida: 57.29 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 48 || this.partidasEditar == 57.30) {
          let partidas = [{ partida: 48 }, { partida: 57.30 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        } else if (this.partidasEditar == 49 || this.partidasEditar == 57.31) {
          let partidas = [{ partida: 49 }, { partida: 57.31 }]
          let dat = [datos, partidas]
          this.editarRegistrosEspecial(dat)
        }

      }

    },

    async editarTrazabilidadNormal(datos) {
      this.loadingTable = true
      await Costeo.editarRegistroTrazabilidad(datos).then((e) => {
        if (e.data.mensaje == "mayor") {
          this.addErrorNotification2(
            "Error, equipo y partida con un total de " +
            e.data.suma +
            " jornadas"
          );
        } else {
          this.addSuccessNotification(
            "Registro ingresado correctamente, volumen = " + e.data.suma
          );
          this.dialogLoadingTrazabilidad = false
          this.paginacionTrazabilidad();
        }
      }).finally(() => {
        this.loadingTable = false
        this.limpiarTrazabilidad()

      })
    },

    async editarRegistrosMayorUno(datos) {
      this.loadingTable = true
      await Costeo.editarRegistroTrazabilidadMasDeUno(datos).then(() => {
        this.paginacionTrazabilidad();
        this.dialogLoadingTrazabilidad = false
        this.addSuccessNotification('Registro editado correctamente')
      }).catch((err) => {
        console.log(err)
        this.addErrorNotification2('Error, verifica con el administrador ', +err)
      }).finally(() => {
        this.limpiarTrazabilidad()
        this.loadingTable = false
      })
    },

    async editarRegistrosEspecial(datos) {
      this.loadingTable = true
      await Costeo.editarRegistroTrazabilidadEspecial(datos).then((e) => {
        if (e.data.mensaje == "mayor") {
          this.addErrorNotification2(
            "Error, equipo y partida con un total de " +
            e.data.suma +
            " jornadas"
          );
        } else {
          this.addSuccessNotification(
            "Registro editado correctamente, volumen = " + e.data.suma
          );
          this.dialogLoadingTrazabilidad = false

          this.paginacionTrazabilidad();
        }
      }).catch((err) => {
        console.log(err)
        this.addErrorNotification2('Error, verifica con el administrador ', +err)
      }).finally(() => {
        this.limpiarTrazabilidad()
        this.loadingTable = false
      })
    },

    mostrarDialogDelete(item) {
      this.dialogDeleteRecord = true;
      //let id_largo = this.$refs.grid._data.selected[this.campoSeleccionado]
      //  let id_corto = parseInt(id_largo.substr(id_largo.length - 7))

      this.dialogEquipo = item.activoEquipo;
      this.dialogVolumen = item.volumen;
      this.dialogMonto = this.costoToDollar(
        parseFloat(item.montoEstimado).toFixed(2)
      );
      this.dialogTeb = item.numeroDeSolicitud;
      this.dialogPartida = item.partida;
      this.dialogUsuario = item.usuarioCreador;
      this.idTrazabilidad = item._id;
    },

    eliminarItemTrazabilidad() {
      Costeo.eliminarRegistroTrazabilidad(this._id, this.idTrazabilidad)
        .then(() => {
          this.addSuccessNotification("Registro eliminado correctamente");
          this.paginacionTrazabilidad();
          this.dialogDeleteRecord = false;
        })
        .catch((err) => {
          console.log(err);
          this.addErrorNotification2(
            "Error al eliminar el registro, verifique con el administrador"
          );
        });
    },
    mostrarDatosTeb() {
      Costeo.buscarTeb(this.numeroDeSolicitudEditar, this.tebId).then((e) => {
        this.fechaServicioEditar = e.data[0].tebRegistros[0].fechaSolicitada;
        this.instalacion_destino_editar =
          e.data[0].tebRegistros[0].instalacionD;
        this.programaPresupuestalSelec =
          e.data[0].tebRegistros[0].programaPressL;
        this.epepSelecc = e.data[0].tebRegistros[0].epepRegistro;
        this.equipoEditar = e.data[0].tebRegistros[0].equiposRequeridosReg;
        this.activoPemex = e.data[0].tebRegistros[0].activo;
      });
    },

    mostrarEquiposDeOrdenEditar(item) {
      let me = this;
      let equiposArray = [];
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/os/queryOs?os=" + item, configuracion)
        .then(function (response) {
          response.data.partidasEquipo.forEach((e) => {
            console.log(e)
            equiposArray.push(e.equipo);
          });
          let equipos = [];
          for (let i = 0; i < equiposArray.length; i++) {
            equipos = equipos.concat(equiposArray[i]);
          }
          me.osEquiposinTraz = equipos;
        })

        .catch(function (e) {
          console.log(e);
        });
    },

    costoPartidasMontoEstimado() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };

      if (me.volumenEditar && me.partidasEditar) {
        axios
          .get("/partidas/query?partida=" + this.partidasEditar, configuracion)
          .then(function (response) {
            me.partidas = response.data;

            if (me.partidas.equipoPartidas.length == 1) {
              me.osEquiposinTraz = me.partidas.equipoPartidas[0].equipo;
            } else if (me.partidas.equipoPartidas.length == 2) {
              let horario1 = me.partidas.equipoPartidas[0].horario;
              let eq1 = [];
              me.partidas.equipoPartidas[0].equipo.forEach((e) => {
                eq1.push(e + horario1);
              });
              let horario2 = me.partidas.equipoPartidas[1].horario;
              let eq2 = [];
              me.partidas.equipoPartidas[1].equipo.forEach((e) => {
                eq2.push(e + horario2);
              });

              let arr = [...eq1, ...eq2];

              me.osEquiposinTraz = arr;
            } else if (me.partidas.equipoPartidas.length == 3) {
              let arr = [
                ...me.partidas.equipoPartidas[0].equipo,
                ...me.partidas.equipoPartidas[1].equipo,
                ...me.partidas.equipoPartidas[2].equipo,
              ];
              me.osEquiposinTraz = arr;
            } else if (me.partidas.equipoPartidas.length == 4) {
              let arr = [
                ...me.partidas.equipoPartidas[0].equipo,
                ...me.partidas.equipoPartidas[1].equipo,
                ...me.partidas.equipoPartidas[2].equipo,
                ...me.partidas.equipoPartidas[3].equipo,
              ];
              me.osEquiposinTraz = arr;
            } else if (me.partidas.equipoPartidas.length == 0) {
              me.mostrarEquiposDeOrden(me.ordenServicioEditar);
            }


            if (me.partidas) {
              me.costoUnitario = me.partidas.costo;
              me.montoEstimado =
                me.costoUnitario * me.volumenEditar
                ;
              me.montoEstimadoInfo = me.roundedNumber(me.montoEstimado)

            } else {
              me.addErrorNotification();

              me.partida = "";
              (me.costoUnitario = ""), (me.montoEstimado = "");
            }
          })
          .catch(function () {
            me.osEquiposinTraz = [];
          });
      }
    },

    limpiarTeb() {
      this.numeroDeSolicitudEditar = "";
      this.programaPresupuestalSelec = "";
      this.epepSelecc = "";
      this.activoPemex = "";
    },

    mostrarFiltro(item) {
      switch (item) {
        case "Equipos": {
          this.obtenerEquiposTrazabilidad();
          this.mostrarDialogActivosFilter = true;
          break
        }
        case "Unidad": {
          this.mostrarDialogUnidadFilter = true;
          break
        }
        case "TEB": {
          this.mostrarDialogTebdFilter = true;
          break
        }
        case "Instalacion": {
          this.mostrarDialogInstalacionFilter = true;
          break
        }
        case "Partida": {
          this.mostrarDialogPartidaFilter = true;
          break
        }
        case "Fecha": {
          this.mostrarDialogFechaFilter = true;
          break
        }
      }
    },
    remove(item) {
      this.filtros.splice(this.filtros.indexOf(item), 1);
      this.paginacionTrazabilidad()
    },

    agregarEquipoFiltro(filtro) {
      let indexEquipo;
      let indexUnidad;
      let indexTeb;
      let indexInstalacion;
      let indexPartida;
      let indexFecha;
      if (filtro == "trazabilidad.activoEquipo") {
        indexEquipo = this.filtros.findIndex((e) => e.filtro == "trazabilidad.activoEquipo");
      }
      if (filtro == "trazabilidad.tipoDeServicioSelec") {
        indexUnidad = this.filtros.findIndex((e) => e.filtro == "trazabilidad.tipoDeServicioSelec");
      }
      if (filtro == "trazabilidad.numeroDeSolicitud") {
        indexTeb = this.filtros.findIndex((e) => e.filtro == "trazabilidad.numeroDeSolicitud");
      }
      if (filtro == "trazabilidad.instalacion_destino") {
        indexInstalacion = this.filtros.findIndex((e) => e.filtro == "trazabilidad.instalacion_destino");
      }
      if (filtro == "trazabilidad.partida") {
        indexPartida = this.filtros.findIndex((e) => e.filtro == "trazabilidad.partida")
      }
      if (filtro == "trazabilidad.fechaServicio") {
        indexFecha = this.filtros.findIndex((e) => e.filtro == "trazabilidad.fechaServicio")
      }

      switch (filtro) {
        case "trazabilidad.activoEquipo": {
          if (indexEquipo === -1) {
            this.filtros.push({
              filtro: filtro,
              text: this.equipoSelecFiltro,
            });
          } else {
            this.filtros[indexEquipo] = {
              filtro: filtro,
              text: this.equipoSelecFiltro,
            };
          }
          break
        }
        case "trazabilidad.tipoDeServicioSelec": {
          if (indexUnidad === -1) {
            this.filtros.push({
              filtro: filtro,
              text: this.unidadSelecFiltro,
            });

          } else {
            this.filtros[indexUnidad] = {
              filtro: filtro,
              text: this.unidadSelecFiltro,
            };

          }
          break
        }
        case "trazabilidad.numeroDeSolicitud": {
          if (indexTeb === -1) {
            this.filtros.push({
              filtro: filtro,
              text: this.tebSelecFiltro,
            });

          } else {
            this.filtros[indexUnidad] = {
              filtro: filtro,
              text: this.tebSelecFiltro,
            };

          }
          break
        }
        case "trazabilidad.instalacion_destino": {
          if (indexInstalacion === -1) {
            this.filtros.push({
              filtro: filtro,
              text: this.instalacionSelecFiltro,
            });

          } else {
            this.filtros[indexInstalacion] = {
              filtro: filtro,
              text: this.instalacionSelecFiltro,
            };

          }
          break
        }
        case "trazabilidad.partida": {
          if (indexPartida === -1) {
            this.filtros.push({
              filtro: filtro,
              text: this.partidaSelecFiltro,
            });

          } else {
            this.filtros[indexPartida] = {
              filtro: filtro,
              text: this.partidaSelecFiltro,
            };

          }
          break
        }
        case "trazabilidad.fechaServicio": {
          if (indexFecha === -1) {
            this.filtros.push({
              filtro: filtro,
              text: this.fechaSelecFiltro,
            });

          } else {
            this.filtros[indexPartida] = {
              filtro: filtro,
              text: this.fechaSelecFiltro,
            };

          }
          break
        }
      }
      this.mostrarDialogActivosFilter = false;
      this.mostrarDialogUnidadFilter = false
      this.mostrarDialogTebdFilter = false
      this.mostrarDialogInstalacionFilter = false
      this.mostrarDialogPartidaFilter = false
      this.mostrarDialogFechaFilter = false
      this.paginacionTrazabilidad();

    },

    aperturarMes() {
      let datos = {
        mes: this.mesServicio,
        trazabilidad: []
      }
      Costeo.aperturarMes(datos).then(() => {
        this.listar()
        this.mesServicio = ''
        this.dialogGuardarTrazabilidad = false
      })
    },
    salirTrazabilidad() {
      this.listar()
      this.dialogGuardarTrazabilidad = false
    },

    toggleMarker() {
      this.marker = !this.marker
      if (this.marker) {
        this.changeIconSinHorario()
      } else {
        this.changeIcon()

      }
    },
    actualziarPartidasSinTebPorUbicaciones() {
      this.dialogLoading = true
      let datos = []
      this.tebEncontrados.forEach((e) => {
        if (e.numeroDeSolicitud) {
          datos.push({
            _id: e._id,
            activoPemex: e.activoPemex,
            epep: e.epep,
            numeroDeSolicitud: e.numeroDeSolicitud,
            programaPresupuestal: e.programaPresupuestal,
            idTrazabilidad: this._id,
          })
        }
      })

      Costeo.updateTebMensual(datos).then(() => {
        this.addSuccessNotification('Datos editados correctamente')
      }).catch((err) => {
        this.addErrorNotification2(err)
      }).finally(() => {
        this.mostrarDialogTebUbicaciones = false
        this.dialogLoading = false
      })
    },

    obtenerDatosTeb() {
      let datos = this.tebMensualServData.find((e) => e.teb == this.tebSeleccionadoMS)
      console.log(datos)
      this.activoTebMensual = datos.activo
      this.ppTebMensual = datos.programaPressL
      this.epepTebMensual = datos.epepRegistro
      this.instalacionMensua = datos.instalacionD
    },
    roundedNumber(numero, decimales = 2, usarComa = false) {
      var opciones = {
        maximumFractionDigits: decimales,
        useGrouping: false
      };
      usarComa = usarComa ? "es" : "en";
      return new Intl.NumberFormat(usarComa, opciones).format(numero);
    }
  },
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.v-data-table {
  overflow-x: auto;
}
</style>
