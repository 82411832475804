<template>
  <v-app>
    <v-container style="background-color: #ffffff;" fluid>
      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">

        </v-col>

        <v-col cols="12" lg="5" class=" d-flex align-center justify-center">

          <v-container>
            <div class="center-container ma-5">
              <v-img src="@/assets/logos/logo_cicsa.png" max-width="250"></v-img>
            </div>
            <v-row class="flex-column ma-10">

              <h4 class="text-center my-5" style="font-size: 30px;font-weight: bold;color: #333;">
                Control de mantenimiento y activos fijos
              </h4>
              <v-form>
                <v-col>
                  <v-text-field class="texto" dense v-model="email" outlined label="Usuario" required></v-text-field>
                  <v-text-field class="texto" dense v-model="password" outlined type="password" label="Contraseña"
                    hint="Al menos 6 caracteres" required></v-text-field>
                </v-col>
                <v-col class="d-flex justify-space-between">
                  <v-btn :loading="loading" :disabled="loading" class="text-capitalize texto" block color="primary"
                    @click="ingresar()">
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                    <v-icon left>
                      mdi-login
                    </v-icon>
                    Iniciar Sesión</v-btn>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      createFullName: 'John Smith',
      createEmail: 'john@flatlogic.com',
      createPassword: '123456',
      password: '',
      loading: false,
      passRules: [
        v => !!v || 'Password is required',
        v => v.length >= 6 || 'Min 6 characters'
      ]
    }
  },
  methods: {

    ingresar() {
      let me = this
      this.loading = true
      axios.post('usuario/login', { usuario: this.email.trim(), password: this.password })
        .then(respuesta => {
          return respuesta.data
        })
        .then(data => {
          //localStorage.setItem("token", data.tokenReturn)
          this.$store.dispatch("guardarToken", data.tokenReturn)
          this.$router.push('dashboard')
        })
        .catch(function (error) {
          if (error.response.status == 404) {
            me.addErrorNotification('No existe el usuario o las credenciales son incorrectas')
          } else {
            me.addErrorNotification('error en el servidor' + error)
          }
        }).finally(() => {
          this.loading = false
        })
    },
    addErrorNotification(error) {
      this.$toast.error(error, {
        position: 'bottom-right',
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true
      });
    },
  },
  created() {
  }
}
</script>

<style scoped src="./Login.scss" lang="scss" />