<template>
  <v-container fluid>
    <br /><br /><br />
    <h1>Importar</h1>
    <p>Activos</p>

    <v-file-input
      truncate-length="15"
      v-model="activos"
      label="Subir Archivo csv"
    ></v-file-input>
    <v-btn @click="guardar()" tile color="success">
      <v-icon left> mdi-upload </v-icon>
      Importar
    </v-btn>
    <br /><br /><br />
    <p>Catalogo Claves</p>

    <v-file-input
      truncate-length="15"
      v-model="claves"
      label="Subir Archivo csv"
    ></v-file-input>
    <v-btn @click="guardarClaves()" tile color="success">
      <v-icon left> mdi-upload </v-icon>
      Importar
    </v-btn>
    <br /><br /><br />
    <p>AMEF</p>

    <v-file-input
      truncate-length="15"
      v-model="amef"
      label="Subir Archivo csv"
    ></v-file-input>
    <v-btn @click="guardarAmef()" tile color="success">
      <v-icon left> mdi-upload </v-icon>
      Importar
    </v-btn>
    <br /><br /><br />
    <p>Trazabilidad</p>

    <v-file-input
      truncate-length="15"
      v-model="trazabilidad"
      label="Subir Archivo csv"
    ></v-file-input>
    <v-btn @click="csvfileTraz()" tile color="success">
      <v-icon left> mdi-upload </v-icon>
      Importar
    </v-btn>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      activos: "",
      claves: "",
      trazabilidad:'',
      trazabilidadAdjuntos:[],
      amef:[],
      activosAdjuntos: [],
      clavesAdjuntos: [],
      amefAdjuntos:[],
      datos: [],
      nssFaltante: [],
      nss: [],
      configuracion: {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
        columns: null, //or array of strings
      },
    };
  },
  computed: {},
  created() {
    this.listar();
  },
  methods: {
    csvfile() {
      let me = this;
      var file = this.activos;

      this.$papa.parse(file, {
        header: true,
        complete: function (results) {
          me.activosAdjuntos = results.data;
          me.activosAdjuntos.splice(me.activosAdjuntos.length - 1, 1);
        },
      });
    },
    csvfileClaves() {
      let me = this;
      var file = this.claves;

      this.$papa.parse(file, {
        header: true,
        complete: function (results) {
          me.clavesAdjuntos = results.data;
          me.clavesAdjuntos.splice(me.clavesAdjuntos.length - 1, 1);
        },
      });
    },
    csvfileAmef() {
      let me = this;
      var file = this.amef;
      
      this.$papa.parse(file, {
        header: true,
        complete: function (results) {
          me.amefAdjuntos = results.data;
          console.log(me.amefAdjuntos);
          me.amefAdjuntos.splice(me.amefAdjuntos.length, 1);
        },
      });
    },

    csvfileTraz() {
      let me = this;
      var file = this.trazabilidad;
      
      this.$papa.parse(file, {
        header: true,
        complete: function (results) {
          me.trazabilidadAdjuntos = results.data;
          console.log(me.trazabilidadAdjuntos);
          me.trazabilidadAdjuntos.splice(me.trazabilidadAdjuntos.length, 1);
        },
      });
    },

    listar() {
      let me = this;
      axios
        .get("/activos/listTractocamion/")
        .then(function (response) {
          me.datos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    guardar() {
      this.csvfile();
      for (let i = 0; i < this.activosAdjuntos.length; i++) {
        let precio = this.activosAdjuntos[i].costo;
        var number = Number(precio.replace(/[^0-9.-]+/g, ""));
        let fecha = new Date(this.activosAdjuntos[i].fechaDeAdquisicion);
        console.log(fecha);
        axios
          .post("/activos/add", {
            nombreActivo: this.activosAdjuntos[i].nombreActivo,
            tipoUnidad: this.activosAdjuntos[i].tipoUnidad,
            marca: this.activosAdjuntos[i].marca,
            modelo: this.activosAdjuntos[i].modelo,
            anio: this.activosAdjuntos[i].anio,
            capacidad: this.activosAdjuntos[i].capacidad,
            numeroSerie: this.activosAdjuntos[i].numeroSerie,
            placa: this.activosAdjuntos[i].placa,
            estatu: this.activosAdjuntos[i].estatu,
            observaciones: this.activosAdjuntos[i].observaciones,
            detalle: this.activosAdjuntos[i].detalle,
            personal: this.activosAdjuntos[i].personal,
            propietario: this.activosAdjuntos[i].propietario,
            factura: this.activosAdjuntos[i].factura,
            numeroEconomico: this.activosAdjuntos[i].numeroEconomico,
            claveMicrosip: this.activosAdjuntos[i].claveMicrosip,
            costo: number,
            ubicacion: this.activosAdjuntos[i].ubicacion,
            fechaDeAdquisicion: fecha,
            notas: this.activosAdjuntos[i].notas,
          })
          .then(function () {
            console.log("Success");
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    guardarClaves() {
      this.csvfileClaves();
      for (let i = 0; i < this.clavesAdjuntos.length; i++) {
        axios
          .post("/claves/add", {
            idAsignacion: this.clavesAdjuntos[i].idAsignacion,
            cveAsignacion: this.clavesAdjuntos[i].cveAsignacion,
            claveCampo: this.clavesAdjuntos[i].claveCampo,
            campoCabAsig: this.clavesAdjuntos[i].campoCabAsig,
            activo: this.clavesAdjuntos[i].activo,
            subdireccion: this.clavesAdjuntos[i].subdireccion,
            region: this.clavesAdjuntos[i].region,
            activoAbreb: this.clavesAdjuntos[i].activoAbreb,
            region2: this.clavesAdjuntos[i].region2,
            activoAbreb2: this.clavesAdjuntos[i].activoAbreb2,
          })
          .then(function () {
            console.log("Success");
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },

    guardarAmef() {
      console.log('object');
      this.csvfileAmef();
      for (let i = 0; i < this.amefAdjuntos.length; i++) {
        axios
          .post("/amef/add", {
            tipo_equipo: this.amefAdjuntos[i].tipo_equipo,
            componente: this.amefAdjuntos[i].componente,
            funcion_estandar: this.amefAdjuntos[i].funcion_estandar,
            funcion_secundaria: this.amefAdjuntos[i].funcion_secundaria,
            falla_funcional: this.amefAdjuntos[i].falla_funcional,
            modo_falla: this.amefAdjuntos[i].modo_falla,
            frecuencia_falla_anio: this.amefAdjuntos[i].frecuencia_falla_anio,
            efecto_falla: this.amefAdjuntos[i].efecto_falla,
            consecuencia_falla: this.amefAdjuntos[i].consecuencia_falla,
            severidad: this.amefAdjuntos[i].severidad,
            deteccion: this.amefAdjuntos[i].deteccion,
            nivel_riesgo: this.amefAdjuntos[i].nivel_riesgo,
            costo_directo_falla: this.amefAdjuntos[i].costo_directo_falla,
            accion_mantenimiento_preventivo: this.amefAdjuntos[i].accion_mantenimiento_preventivo,
            herramientas: this.amefAdjuntos[i].herramientas,
            frecuencia_aplicacion: this.amefAdjuntos[i].frecuencia_aplicacion,
            responsable: this.amefAdjuntos[i].responsable,
            costo_actividad_mtto_propuesta: this.amefAdjuntos[i].costo_actividad_mtto_propuesta,
          })
          .then(function () {
            console.log("Success");
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
};
</script>
