<template>
  <v-container fluid>
    <div class="tables-basic">
      <br />
      <h1 class="page-title mt-16 mb-6">Ordenes de Servicio</h1>

      <v-btn
        class="button-fab-1 mx-2"
        @click="mostrarDialogAddSupervisor"
        dark
        fab
        color="primary"
        ><v-icon dark>mdi-folder-account</v-icon></v-btn
      >
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-6 pb-3">
              <v-btn
                @click="mostrarDialogNuevaOrdenServicio()"
                rounded
                color="primary"
                small
                dark
                >Nueva Orden de Servicio</v-btn
              >
              <v-row>
                <v-dialog v-model="dialogNuevaEmpresa" persistent max-width="1600px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="os"
                              label="Orden de Servicio"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" lg="12">
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  label="Perido del Servicio"
                                  v-model="dateRangeText"
                                  prepend-icon="mdi-calendar-range-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="pa-0"
                                  hide-details
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="fechas" range scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog.save(date)"
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-dialog>
                          </v-col>

                          <v-col cols="12">
                            <v-combobox
                              v-model="ubicacion"
                              append-icon="mdi-map-marker-plus"
                              @click:append="dialogNuevaUbicacion()"
                              :items="ubicacionesOs"
                              :search-input.sync="searchUbi"
                              hide-selected
                              label="Ubicaciones"
                              persistent-hint
                              small-chips
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      No se encontraron resultados "<strong>{{
                                        search
                                      }}</strong
                                      >". Presiona <kbd>enter</kbd> para crear una nueba
                                      ubicacion
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-combobox>
                          </v-col>

                          <v-col cols="12">
                            <v-menu
                              v-model="fechaMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  dense
                                  v-model="fechaRecepcion"
                                  label="Fecha de Recepción"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="fechaRecepcion"
                                @input="fechaMenu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="observaciones"
                             
                              label="Obervaciones"
                            ></v-text-field>
                          </v-col>


                          <v-col cols="12">
                            <v-btn tile color="primary" small @click="mostrarPartidas">
                              <v-icon left> mdi-file-plus </v-icon>
                              Agregar Partida
                            </v-btn>
                          </v-col>

                          <v-col cols="12" v-if="mostrarTablaPartidas == true">
                            <v-simple-table dense>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">Acciones</th>
                                    <th class="text-left">Unidad</th>
                                    <th class="text-left">Partida</th>
                                    <th class="text-left">Equipos</th>
                                    <th class="text-left">Dias a restar</th>
                                    <th class="text-left">Cantidad</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="item in partidasEquipos" :key="item.partida">
                                    
                                    <td>
                                      <v-icon
                                class="mr-2"
                                @click="
                                  eliminarPartida(item)"
                              >
                                mdi-delete
                              </v-icon>
                                    </td>
                                    <td>
                                     <!--  <v-text-field
                                        dense
                                        v-model="item.unidad"
                                      ></v-text-field> -->
                                      <v-select
                                        :items="tiposServicio"
                                        v-model="item.unidad"
                                        dense
                                      ></v-select>
                                    </td>

                                    <td>
                                      <v-autocomplete
                                        v-model="item.partida"
                                        @input="mostrarEquiposPartidas(item)"
                                        :items="listaPartidas"
                                        dense
                                      ></v-autocomplete>
                                    </td>
                                    <v-autocomplete
                                      :items="item.equipos"
                                      v-model="item.equipo"
                                      dense
                                      chips
                                      small-chips
                                      multiple
                                    ></v-autocomplete>
                                    <td>
                                      <v-text-field
                                      type="number"
                                        dense
                                        v-model="item.dias"
                                      ></v-text-field>
                                    </td>
                                    <td v-if="item.unidad != 'Jornada'" >
                                      <v-text-field
                                      type="number"
                                        dense
                                        v-model="item.cant"
                                      ></v-text-field>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="cerrarModalEmpresa()">
                        Cerrar
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="guardar()">
                        Guardar
                      </v-btn>
                    <!--   <v-btn color="blue darken-1" text @click="test()">
                        TEST
                      </v-btn> -->
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogUbicacion" width="500">
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Nueba ubicación
                    </v-card-title>
                    <br />
                    <v-card-text>
                      <v-text-field
                        v-model="ubicacionOs"
                        label="Ubicación"
                      ></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="guardarNuevaUbicacion()">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog
                  v-model="dialogAddSupervisor"
                  transition="dialog-bottom-transition"
                  max-width="800"
                >
                  <template>
                    <v-card>
                      <v-toolbar color="primary" dark>
                        <v-toolbar-title>Supervisores de Contrato</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn
                            class="mx-2"
                            @click="mostrarFormAddSupervisor = !mostrarFormAddSupervisor"
                            icon
                            dark
                            color="white"
                          >
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </v-toolbar-items>
                      </v-toolbar>

                      <v-card-text>
                        <v-row v-if="mostrarFormAddSupervisor">
                          <v-col cols="5">
                            <v-text-field
                              v-model="nombreCompletoSupervisor"
                              label="Nombre completo"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="5">
                            <v-text-field
                              v-model="fichaSupervisor"
                              label="Ficha"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-btn
                              class="mt-3"
                              @click="guardarSupervisor()"
                              tile
                              color="primary"
                            >
                              <v-icon left> mdi-content-save </v-icon>
                              Guardar
                            </v-btn>
                          </v-col> </v-row
                        ><br />
                        <v-data-table
                          :headers="headersSupervisores"
                          :items="supervisores"
                          :items-per-page="5"
                          dense
                          class="elevation-1"
                        >
                          <template v-slot:[`item.estatus`]="{ item }">
                            <v-chip
                              class="ma-2"
                              v-if="item.estatus"
                              color="green"
                              small
                              text-color="white"
                            >
                              Activo
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              v-if="!item.estatus"
                              color="red"
                              small
                              text-color="white"
                            >
                              Inactivo
                            </v-chip>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <div>
                              <v-btn
                                class="ma-2"
                                text
                                v-if="item.estatus"
                                small
                                @click="updateEstatusSupervisor(item._id, false)"
                                icon
                                color="red lighten-2"
                              >
                                <v-icon>mdi-account-cancel</v-icon>
                              </v-btn>

                              <v-btn
                                class="ma-2"
                                text
                                v-if="!item.estatus"
                                small
                                @click="updateEstatusSupervisor(item._id, true)"
                                icon
                                color="green lighten-2"
                              >
                                <v-icon>mdi-account-check</v-icon>
                              </v-btn>
                            </div>
                          </template>
                        </v-data-table>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialogAddSupervisor = false">Salir</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>

                <v-dialog
                  v-model="dialogMonto"
                  transition="dialog-bottom-transition"
                  max-width="850"
                >
                  <template>
                    <v-card>
                      <v-toolbar color="primary" dark>
                        <v-toolbar-title>Monto de Orden de Servicio {{ osSelect }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>

                      <v-card-text>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              v-model="montoMinimo"
                              label="Monto Minimo"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="5">
                            <v-text-field
                              v-model="montoMaximo"
                              label="Monto Maximo"
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="2">
                            <v-btn
                              class="mt-3"
                              @click="updateMonto()"
                              tile
                              color="primary"
                            >
                              <v-icon left> mdi-content-save </v-icon>
                              Guardar
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialogMonto = false">Salir</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-row>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="partidas"
              :search="search"
              item-key="nombreComercial"
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-icon @click="editItem(item)" small class="mr-2"
                  >mdi-border-color</v-icon
                >
                <v-icon @click="descargarOS(item)" class="mr-2"> mdi-file-pdf-box</v-icon>
                <v-icon @click="mostrarDialogMonto(item)" class="mr-2">
                  mdi-cash-plus</v-icon
                >
             
              </template>
              <template v-slot:[`item.mes`]="{ item }">
                {{ moment(item.periodo[0]).add(1, "days").format("MMMM YYYY") }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import jsPDF from "jspdf";
import Catalogo from "../../api/Catalogo";
import fecha from "../../utils/fechas";
import partidas from "../../utils/partidas.json";
import calculos from "../../utils/helpers";
import api from "../../api/Catalogo";

export default {
  data() {
    return {
      moment: moment,
      headers: [
        { text: "Actions", value: "action", sortable: false },
        { text: "Orden de Servicio", value: "os", sortable: true },
        { text: "Equipos", value: "equipos", sortable: true },
        { text: "Mes", value: "mes", sortable: true },
      ],
      headersPartidas: [
        { text: "Unidad", value: "unidad", sortable: false },
        { text: "Partida", value: "os", sortable: true },
        { text: "Equipos", value: "equipos", sortable: true },
      ],
      headersSupervisores: [
        { text: "Nombre Completo", value: "nombre_completo", sortable: false },
        { text: "Ficha", value: "ficha", sortable: true },
        { text: "Estatus", value: "estatus", sortable: true },
        { text: "Acciones", value: "actions", sortable: true },
      ],
      partidas: [],
      listaDePartidasParaEquipos: [],
      listaPartidas: [],
      partidasEquipos: [],
      ubicacionesOs: [],
      osSelect:'',
      observaciones:'',
      search: "",
      ubicacion: "",
      dialogUbicacion: false,
      mostrarTablaPartidas: false,
      os: "",
      dialogNuevaEmpresa: false,
      contrato: "",
      numeroPartida: "",
      editedIndex: -1,
      searchUbi: "",
      equiposRequeridosOC: [],
      equiposActivosLista: [],
      ubicacionOs: "",
      concepto: "",
      equiposRequeridosReg: [],
      representante: "",
      searchEquipo: "",
      modal: false,
      ubicaciones: [
        "Terminal Marítima Dos Bocas, Paraíso Tabasco",
        "Almacen de Bienes de Consumo, Comalcalco Tabasco",
        "Terminal Marítima Arbol Grande, Madero Tamaulipas",
        "Terminal Marítima Cobos, Tuxpan",
      ],
      date: "",
      submited: "",
      tipoDeServicio: "",
      tipoDeServicios: ["Mensual", "Predeterminada"],
      fechaMenu: false,
      fechaRecepcion: "",
      alert: "",
      costo: "",
      mostrarFormAddSupervisor: false,
      montoMinimo: "0",
      montoMaximo: "0",
      dialogAddSupervisor: false,
      dialogMonto: false,
      tiposServicio:['Jornada', 'Hora', 'Km','Servicio'],
      fichaSupervisor: "",
      nombreCompletoSupervisor: "",
      supervisores: [],
      supervisorActivo: [],
      mes: "",
      fechas: [],
      agregarMasRegistros: false,
      menu2: false,
      options: {
        locale: "en-US",
        prefix: "$",
        suffix: "",
        length: 11,

        company_logo: {
          w: 110,
          h: 35,
        },

        precision: 2,
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva OS" : "Editar OS";
    },
    dateRangeText() {
      return this.fechas.join(" al ");
    },
  },
  created() {
    this.listar();
    this.mostrarActivos();
    this.listarUbicaciones();
    this.obtenerSupervisorActivo();
  },
  methods: {
    listar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/os/list/", configuracion)
        .then(function (response) {
          me.partidas = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    limpiar() {
      (this.mes = ""), (this.os = "");
      this.observaciones = ''
    },

    selectPartidas() {
      let me = this;

      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      let partidasArray = [];
      axios
        .get("/partidas/list", configuracion)
        .then((response) => {
          partidasArray = response.data;
          partidasArray.map(function (x) {
            me.listaPartidas.push({ text: x.numeroPartida, value: x.numeroPartida });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    mostrarPartidas() {
      this.mostrarTablaPartidas = true;
      this.agregarMasRegistros = true;
      this.selectPartidas();
      this.partidasEquipos.push({ unidad: "Jornada", partida: "", dias:0, cant:0 });
    },

    mostrarDialogNuevaOrdenServicio() {
      this.editedIndex = -1;
      this.os = "";
      this.fechas = [];
      this.ubicacion = "";
      this.fechaRecepcion = "";
      this.partidasEquipos = [];
      this.dialogNuevaEmpresa = true;
    },

  
    mostrarEquiposPartidas(item) {
      let me = this;
      let index = this.partidasEquipos.indexOf(item);
      let equipos = [];
     /*  let total = []; */
      axios
        .get("/partidas/query?partida=" + item.partida)
        .then((response) => {
          if(response.data.equipoPartidas.length>1){
          response.data.equipoPartidas.forEach((e) => {

            e.equipo.forEach((f)=>{
              equipos.push(f+' '+e.horario)
            })
          });
        }else{

          equipos= response.data.equipoPartidas[0].equipo
        }

         /*  if (equipos.length == 2) {
            console.log(total)

             total = [...equipos[0], ...equipos[1]];
          } else if (equipos.length == 3) {
            total = [...equipos[0], ...equipos[1], ...equipos[2]];
          } else {
            total = equipos[0];
          }
          console.log(total)
          me.partidasEquipos[index].equipos = total; */
          me.partidasEquipos[index].equipos = equipos;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    mostrarActivos() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let equiposActivos = [];
      let configuracion = { headers: header };
      axios
        .get("/activos/listActivosPemex", configuracion)
        .then(function (response) {
          equiposActivos = response.data;
          equiposActivos.map((x) => {
            me.equiposActivosLista.push(x.nombreActivo + " " + x.numeroEconomico);
          });
          me.equiposActivosLista.push("01 MANIOBRISTA");
          me.equiposActivosLista.push("01 MANIOBRISTA");
          me.equiposActivosLista.push("01 MANIOBRISTA");
          me.equiposActivosLista.push("01 MANIOBRISTA");
          me.equiposActivosLista.push("01 MANIOBRISTA");
          me.equiposActivosLista.push("01 MANIOBRISTA");
          me.equiposActivosLista.push("01 MANIOBRISTA");
          me.equiposActivosLista.push("MANIOBRISTA 2");
          me.equiposActivosLista.push("MANIOBRISTA 3");
          me.equiposActivosLista.push("MANIOBRISTA 4");
          me.equiposActivosLista.push("MANIOBRISTA 5");
          me.equiposActivosLista.push("MANIOBRISTA 6");
          me.equiposActivosLista.push("MANIOBRISTA 7");
          me.equiposActivosLista.push("MANIOBRISTA 8");
          me.equiposActivosLista.push("MANIOBRISTA 9");
          me.equiposActivosLista.push("MANIOBRISTA 10");
          me.equiposActivosLista.push("MANIOBRISTA 11");
          me.equiposActivosLista.push("MANIOBRISTA 12");
          me.equiposActivosLista.push("MANIOBRISTA 13");
          me.equiposActivosLista.push("MANIOBRISTA 14");
          me.equiposActivosLista.push("MANIOBRISTA 15");
          me.equiposActivosLista.push("MANIOBRISTA 16");
          me.equiposActivosLista.push("MANIOBRISTA 17");
          me.equiposActivosLista.push("MANIOBRISTA 18");
          me.equiposActivosLista.push("MANIOBRISTA 19");
          me.equiposActivosLista.push("MANIOBRISTA 20");
          me.equiposActivosLista.push("MANIOBRISTA 21");
          me.equiposActivosLista.push("MANIOBRISTA 22");
          me.equiposActivosLista.push("MANIOBRISTA 23");
          me.equiposActivosLista.push("MANIOBRISTA 24");
          me.equiposActivosLista.push("MANIOBRISTA 25");
          me.equiposActivosLista.push("MANIOBRISTA 26");
          me.equiposActivosLista.push("MANIOBRISTA 27");
          me.equiposActivosLista.push("MANIOBRISTA 28");
          me.equiposActivosLista.push("MANIOBRISTA 29");
          me.equiposActivosLista.push("MANIOBRISTA 30");
          me.equiposActivosLista.push("MANIOBRISTA 31");
          me.equiposActivosLista.push("MANIOBRISTA 32");
          me.equiposActivosLista.push("MANIOBRISTA 33");
          me.equiposActivosLista.push("MANIOBRISTA 34");
          me.equiposActivosLista.push("MANIOBRISTA 35");
          me.equiposActivosLista.push("MANIOBRISTA 36");
          me.equiposActivosLista.push("MANIOBRISTA 37");
          me.equiposActivosLista.push("MANIOBRISTA 38");
          me.equiposActivosLista.push("MANIOBRISTA 39");
          me.equiposActivosLista.push("MANIOBRISTA 40");
          me.equiposActivosLista.push("MANIOBRISTA 41");
          me.equiposActivosLista.push("MANIOBRISTA 42");
          me.equiposActivosLista.push("MANIOBRISTA 43");
          me.equiposActivosLista.push("MANIOBRISTA 44");
          me.equiposActivosLista.push("MANIOBRISTA 45");
          me.equiposActivosLista.push("MANIOBRISTA 46");
          me.equiposActivosLista.push("MANIOBRISTA 47");
          me.equiposActivosLista.push("MANIOBRISTA 48");
          me.equiposActivosLista.push("MANIOBRISTA 49");
          me.equiposActivosLista.push("MANIOBRISTA 50");

          me.equiposActivosLista.push("MANIOBRISTA 66");
          me.equiposActivosLista.push("MANIOBRISTA 67");
          me.equiposActivosLista.push("MANIOBRISTA 68");
          me.equiposActivosLista.push("MANIOBRISTA 69");
          me.equiposActivosLista.push("MANIOBRISTA 70");
          me.equiposActivosLista.push("MANIOBRISTA 71");

          me.equiposActivosLista.push("MANIOBRISTA 72");
          me.equiposActivosLista.push("MANIOBRISTA 73");
          me.equiposActivosLista.push("MANIOBRISTA 74");
          me.equiposActivosLista.push("MANIOBRISTA 75");
          me.equiposActivosLista.push("MANIOBRISTA 76");
          me.equiposActivosLista.push("MANIOBRISTA 77");

          me.equiposActivosLista.push("MANIOBRISTA 78");
          me.equiposActivosLista.push("MANIOBRISTA 79");
          me.equiposActivosLista.push("MANIOBRISTA 80");
          me.equiposActivosLista.push("MANIOBRISTA 81");
          me.equiposActivosLista.push("MANIOBRISTA 82");
          me.equiposActivosLista.push("MANIOBRISTA 83");

          me.equiposActivosLista.push("CABO 01");
          me.equiposActivosLista.push("CABO 02");
          me.equiposActivosLista.push("CABO 03");
          me.equiposActivosLista.push("CABO 04");
          me.equiposActivosLista.push("CABO 05");
          me.equiposActivosLista.push("CABO 06");
          me.equiposActivosLista.push("CABO 07");
          me.equiposActivosLista.push("CABO 08");
          me.equiposActivosLista.push("CABO 09");
          me.equiposActivosLista.push("CABO 10");
          me.equiposActivosLista.push("CABO 11");
          me.equiposActivosLista.push("CABO 14");
          me.equiposActivosLista.push("CABO 15");
          me.equiposActivosLista.push("CABO 16");
        })
        .catch(function () {});
    },
    guardar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      if (this.editedIndex > -1) {
        axios
          .put(
            "/os/update",
            {
              _id: this._id,
              os: this.os,
              recepcion: this.fechaRecepcion,
              periodo: this.fechas,
              ubicacion: this.ubicacion,
              partidasEquipo: this.partidasEquipos,
              observaciones:this.observaciones
            },
            configuracion
          )
          .then(function () {
            me.listar();
            me.dialogNuevaEmpresa = false;
            me.addSuccessNotification("OS actualizada");
            me.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        axios
          .post(
            "/os/add",
            {
              os: this.os,
              recepcion: this.fechaRecepcion,
              periodo: this.fechas,
              ubicacion: this.ubicacion,
              partidasEquipo: this.partidasEquipos,
              observaciones:this.observaciones,
              contrato: "648812815",
            },
            configuracion
          )
          .then(function () {
            me.listar();
            me.dialogNuevaEmpresa = false;
            me.addSuccessNotification("OS agregada");
            me.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    editItem(item) {
      this._id = item._id;
      this.os = item.os;
      this.observaciones = item.observaciones
      //   this.mostrarEquiposPartidas(item.partidasEquipo[0])
      this.fechas = item.periodo;

      (this.fechaRecepcion = new Date(item.recepcion).toISOString().substr(0, 10)),
        this.selectPartidas();
      this.partidasEquipos = item.partidasEquipo;
      item.partidasEquipo.forEach((e) => this.mostrarEquiposPartidas(e));

      this.ubicacion = item.ubicacion;
      this.mostrarTablaPartidas = true;

      this.editedIndex = 1;

      this.dialogNuevaEmpresa = true;
    },
    descargarOS(item) {
      var doc = new jsPDF("p", "pt", "a4");
      var startX = 40;
      var startY = 20;
      //Calculos
      //Calculo de periodo del servicio
      let periodo = ''
      if(item.periodo[0]==item.periodo[1]){
        periodo = fecha.fechaUnDia(item.periodo)
      }else{
       periodo = fecha.fecha(item.periodo);
      }
      //busqueda de partidas en el json
      let partidasEncontradas = [];
      partidas.forEach((e) => {
        if (item.partidasEquipo.some((r) => r.partida == e.partida)) {
          partidasEncontradas.push(e);
        }
      });
      //AGRUPANDO POR TITULO
      const groups = partidasEncontradas.reduce((groups, item) => {
        const group = groups[item.titulo] || [];
        group.push(item);
        groups[item.titulo] = group;
        return groups;
      }, {});
      let partidasGroup = Object.entries(groups);
      partidasGroup.forEach((e) => {
        e[1].forEach((f) => {
          let { equipo,dias,cant,unidad } = item.partidasEquipo.find((e) => e.partida == f.partida);
          f.equipo = equipo;
          f.diasR = dias;
          f.cantidad = cant;
          f.unidad = unidad
        });
      });



      doc.addImage(
        "https://i.ibb.co/KwGNn1B/LOGO-Pemex-EP.webp",
        "JPEG",
        startX + 15,
        (startY += 5),
        100,
        80
      );
      doc.setFontSize(8.5);

      doc.setFont(undefined, "italic");
      doc.setLineWidth(1.5);
      doc.rect(42, 33, 515, 785);

      doc.text("ORDEN DE SERVICIO", 230, 95);
      doc.setFont(undefined, "bold");
      doc.setFontSize(7);
      doc.text('ANEXO "I"', 518, 43);
      doc.setFontSize(6);
      doc.text("CONTRATO: 648812815", 485, 55);
      doc.text("PROVEEDOR: CORPORATIVO INDUSTRIAL Y COMERCIAL S.A. DE C.V.", 348, 67);

      doc.setLineWidth(1);

      //Numero de Orden de Servicio
      doc.setFont(undefined, "normal");
      doc.setFontSize(6);
      doc.text(item.os, 45, 119);
      doc.text("Periodo del servicio:", 45, 140);
      //Periodo del Servicio
      doc.text(periodo, 45, 153);
      doc.setFont(undefined, "bold");
      doc.setFontSize(7);
      doc.text("Contrato: 648228851", 223, 133);
      doc.setFont(undefined, "normal");
      doc.text(
        "“Servicio de transporte de carga y descarga de materiales en Puerto de Dos Bocas”",
        293,
        133
      );
      //Ubicacion de los servicios
      if(item.ubicacion.length >= 105){
        doc.setFontSize(6);

        doc.setFont(undefined, "bold");
        doc.text("Ubicación de los servicios:", 60, 173);
        doc.setFont(undefined, "normal");
        doc.text(item.ubicacion, 140, 173);

      } else if(item.ubicacion.length >= 95){
        doc.setFont(undefined, "bold");
        doc.text("Ubicación de los servicios:", 60, 173);
        doc.setFont(undefined, "normal");
        doc.text(item.ubicacion, 150, 173);

      }else if(item.ubicacion.length >= 70){
        doc.setFont(undefined, "bold");
        doc.text("Ubicación de los servicios:", 80, 173);
        doc.setFont(undefined, "normal");
        doc.text(item.ubicacion, 170, 173);

      }else {
        doc.setFont(undefined, "bold");
        doc.text("Ubicación de los servicios:", 190, 173);
        doc.setFont(undefined, "normal");
        doc.text(item.ubicacion, 280, 173);
      }
      doc.setFont(undefined, "bold");
      doc.text("CANT.", 63, 197);
      doc.text("UNIDAD", 121, 197);
      doc.setFontSize(6);
      doc.text("DESCRIPCIÓN DEL SERVICIO Y CRITERIOS DE ACEPTACIÓN DE", 169, 190);
      doc.text('ACUERDO AL ANEXO "C".', 225, 202);
      doc.text("FECHA DE RECEPCION:", 424, 190);
      //Fecha de Recepcion
      doc.text(moment(item.recepcion).add(1, "days").format("LL"), 425, 202);
      doc.line(42, 103, 557, 103);
      doc.rect(42, 105, 177, 25);
      doc.rect(42, 132, 177, 25);
      doc.rect(221, 105, 336, 52);
      doc.rect(42, 159, 515, 22);
      doc.rect(42, 183, 60, 22);
      doc.rect(104, 183, 60, 22);

      doc.rect(166, 183, 194, 22);
      doc.rect(362, 183, 195, 22);
      if (item.partidasEquipo.length > 21) {
        doc.rect(42, 207, 60, 611);
        doc.rect(104, 207, 60, 611);
        doc.rect(166, 207, 391, 611);
      } else {
        doc.rect(42, 207, 60, 540);
        doc.rect(104, 207, 60, 540);
        doc.rect(166, 207, 391, 500);
      }
      let starX2 = 50;
      let starY2 = 200;
      let partidasMontoTotal = 0;
      partidasGroup.forEach((e) => {
        if (starY2 > 770) {
          doc.addPage();
          starX2 = 50;
          starY2 = 200;
          startX = 40;
          startY = 20;
          doc.addImage(
            "https://i.ibb.co/KwGNn1B/LOGO-Pemex-EP.webp",
            "JPEG",
            startX + 15,
            (startY += 5),
            100,
            80
          );
          doc.setFontSize(8.5);

          doc.setFont(undefined, "italic");
          doc.setLineWidth(1.5);
          doc.rect(42, 33, 515, 785);

          doc.text("ORDEN DE SERVICIO", 230, 95);
          doc.setFont(undefined, "bold");
          doc.setFontSize(7);
          doc.text('ANEXO "I"', 518, 43);
          doc.setFontSize(6);
          doc.text("CONTRATO: 648812815", 485, 55);
          doc.text("PROVEEDOR: CORPORATIVO INDUSTRIAL Y COMERCIAL S.A. DE C.V.", 348, 67);
          doc.setLineWidth(1);

          //Numero de Orden de Servicio
          doc.setFont(undefined, "normal");
          doc.setFontSize(6);
          doc.text(item.os, 45, 119);
          doc.text("Periodo del servicio:", 45, 140);
          //Periodo del Servicio
          doc.text(periodo, 45, 153);
          doc.setFont(undefined, "bold");
          doc.setFontSize(7);
          doc.text("Contrato: 648228851", 223, 133);
          doc.setFont(undefined, "normal");
          doc.text(
            "“Servicio de transporte de carga y descarga de materiales en Puerto de Dos Bocas”",
            293,
            133
          );
          doc.setFont(undefined, "bold");
          doc.text("Ubicación de los servicios:", 190, 173);
          doc.setFont(undefined, "normal");
          //Ubicacion de los servicios
          doc.text(item.ubicacion, 280, 173);
          doc.setFont(undefined, "bold");
          doc.text("CANT.", 63, 197);
          doc.text("UNIDAD", 121, 197);
          doc.setFontSize(6);
          doc.text("DESCRIPCIÓN DEL SERVICIO Y CRITERIOS DE ACEPTACIÓN DE", 169, 190);
          doc.text('ACUERDO AL ANEXO "C".', 225, 202);
          doc.text("FECHA DE RECEPCION:", 424, 190);
          doc.text(moment(item.recepcion).add(1, "days").format("LL"), 425, 202);
          doc.line(42, 103, 557, 103);
          doc.rect(42, 105, 177, 25);
          doc.rect(42, 132, 177, 25);
          doc.rect(221, 105, 336, 52);
          doc.rect(42, 159, 515, 22);
          doc.rect(42, 183, 60, 22);
          doc.rect(104, 183, 60, 22);

          doc.rect(166, 183, 194, 22);
          doc.rect(362, 183, 195, 22);
          doc.rect(42, 207, 60, 540);
          doc.rect(104, 207, 60, 540);
          doc.rect(166, 207, 391, 500);

          doc.setFont(undefined, "bold");
          doc.text(e[0], starX2 + 125, (starY2 += 15));
          doc.setFont(undefined, "normal");
        } else {
          doc.setFont(undefined, "bold");
          doc.text(e[0], starX2 + 125, (starY2 += 15));
          doc.setFont(undefined, "normal");
        }
        if (item.partidasEquipo.length > 30) {
          e[1].forEach((f) => {
            let total = 0;
            let totalPartida = 0 
            if (
              f.partida == 11 ||
              f.partida == 13 ||
              f.partida == 15 ||
              f.partida == 12 ||
              f.partida == 14 ||
              f.partida == 16
            ) {
              total = (calculos.calculoTotalFechas(
                f.dias,
                item.periodo,
                f.partida,
                item.partidasEquipo)-f.diasR
              );
              totalPartida = f.costo * total;
            } else if(f.tipo=='Hora'  || f.unidad == 'Km' || f.tipo=='Servicio'){
              
              total = f.cantidad
              totalPartida = f.costo * total;

            } else{
              total =
                (calculos.calculoTotalFechas(
                  f.dias,
                  item.periodo,
                  f.partida,
                  item.partidasEquipo
                ) * f.equipo.length)-f.diasR;
                totalPartida = f.costo * total;
            }
            partidasMontoTotal += totalPartida;
            doc.text(total.toString(), starX2 + 20, (starY2 += 10));
            doc.text("JORNADA", starX2 + 65, starY2);
            doc.text(
              "(Partida No. " + f.partida + ") " + f.jornada,
              starX2 + 150,
              starY2,{align:'justify'}
            );
            doc.setFont(undefined, "bold");
            doc.text(f.tipo, starX2 + 160, (starY2 += 13));
            doc.setFont(undefined, "normal");
            if (f.tipo == "Personal") {
              f.equipo[0].split(" ");
              let tEquipo = f.equipo[0].split(" ")[0];
              let equipo = f.equipo.length + " " + tEquipo;
              doc.text(equipo, starX2 + 190, starY2);
            } else {
              if(f.equipo[0].length>8 && f.equipo.length>1){
                doc.text( calculos.calculoEquiposHorarios(f.equipo), starX2 + 190, starY2);
              }else if(f.equipo[0].length>8 && f.equipo.length==1){
                doc.text(f.equipo.toString(), starX2 + 190, starY2);
              }else{

                doc.text(f.equipo.toString() + " " + f.horario, starX2 + 190, starY2);
              }
            }
          });
        } else {
          e[1].forEach((f) => {
            let total = 0;
            let totalPartida = 0
            if(f.unidad == 'Hora' || f.unidad == 'Km' || f.unidad == 'Servicio'){
             
              total = f.cantidad
              totalPartida = f.costo * total;
            }else{
            total =
             (calculos.calculoTotalFechas(
                f.dias,
                item.periodo,
                f.partida,
                item.partidasEquipo
              ) * f.equipo.length)-f.diasR;
              totalPartida = f.costo * total;
            }
            partidasMontoTotal += totalPartida;
            doc.text(total.toString(), starX2 + 20, (starY2 += 18));
            if (f.tipo == "SERVICIO" || f.tipo == "HORA") {
              doc.text(f.tipo, starX2 + 65, starY2);
            } else {
              doc.text("JORNADA", starX2 + 65, starY2);
            }
            var strArr = doc.splitTextToSize( "(Partida No. " + f.partida + ") " + f.jornada, 350)
            doc.text(
              strArr,
              starX2 + 150,
              starY2
            );
            doc.setFont(undefined, "bold");
            if (f.tipo == "SERVICIO" || f.tipo == "HORA") {
              doc.text("", starX2 + 190, starY2);
            } else if(f.equipo[0] == 'LOWBOY'){
              doc.text("", starX2 + 190, starY2);
            }else {
              doc.text(f.tipo, starX2 + 160, (starY2 += 13));
            }
            doc.setFont(undefined, "normal");
            if (f.tipo == "Personal") {
              f.equipo[0].split(" ");
              let tEquipo = f.equipo[0].split(" ")[0];
              let equipo = f.equipo.length + " " + tEquipo;
              doc.text(equipo, starX2 + 190, starY2);
            } else if (f.tipo == "SERVICIO" || f.tipo == "HORA" || f.equipo[0] == 'LOWBOY') {
              doc.text("", starX2 + 190, starY2);
            } else {
              if(f.equipo[0].length>8 && f.equipo.length>1){
               
                let equipo = calculos.calculoEquiposHorarios(f.equipo)
                if(Array.isArray(equipo)){
                  
                  equipo.forEach((e,i)=>{
                   if(i>0){
                    doc.text(e, starX2 + 190, starY2+=8);
                   }else{
                    doc.text(e, starX2 + 190, starY2);
                   }
                   
                  })
                }else{
                  doc.text(equipo, starX2 + 190, starY2);
                }
              }else if(f.equipo[0].length>8 && f.equipo.length==1){
                doc.text(f.equipo.toString(), starX2 + 190, starY2);
              }else{
                doc.text(f.equipo.toString() + " " + f.horario, starX2 + 190, starY2);
              }
            }
          });
        }
      });

      doc.rect(166, 709, 391, 38);
      doc.rect(42, 749, 257, 67);
      doc.rect(301, 749, 256, 67);
      doc.setFont(undefined, "bold");
      if(item.observaciones){
        doc.text(item.observaciones, 185, 617);
      }
      doc.text("OBSERVACIONES:", 185, 717);
      doc.text("MONTO MAXIMO=", 185, 728);
      if (item.montoMaximo) {
        doc.text("$" + item.montoMaximo + " MN", 240, 728);
        doc.text("$" + item.montoMinimo + " MN", 240, 741);
      } else {
        doc.text(
          "$" +
            this.costoToDollar(parseFloat(partidasMontoTotal).toFixed(2)).toString() +
            " MN",
          240,
          728
        );
        doc.text("$0", 240, 741);
      }

      doc.text("MONTO MINIMO=", 185, 741);
      doc.setFontSize(7);
      doc.text("Solicitado por", 155, 770);
      doc.text(this.supervisorActivo.nombre_completo.toUpperCase(), 110, 790);
      doc.text("Supervisor de contrato PEP", 135, 800);
      doc.setFont(undefined, "normal");
      doc.text('Ficha', 135, 810);
      doc.text(this.supervisorActivo.ficha, 155, 810);

      doc.setFont(undefined, "bold");
      doc.setFontSize(7);
      doc.text("Recibido", 415, 770);
      doc.text("ING. MAGDA NAYEK RAVELL GONZALEZ", 360, 790);
      doc.text("Representante Técnico del proveedor", 368, 800);
      doc.text("Corporativo Industrial y Comercial S.A. de C.V.", 350, 810);
      doc.output('dataurlnewwindow');

    },
    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    cerrarModalEmpresa() {
      this.dialogNuevaEmpresa = false;
      this.limpiar();
      this.editedIndex = -1;
    },
    dialogNuevaUbicacion() {
      this.dialogUbicacion = true;
      this.ubicacionOs = "";
    },

    listarUbicaciones() {
      Catalogo.obtenerUbicacionesOs().then((e) => {
        this.ubicacionesOs = e.data;
      });
    },

    guardarNuevaUbicacion() {
      let datos = {
        ubicacion: this.ubicacionOs,
      };
      Catalogo.insertarUbicacionOs(datos)
        .then(() => {
          this.addSuccessNotification("Datos ingresado correctamente");
          this.listarUbicaciones();
          this.dialogUbicacion = false;
        })
        .catch((e) => {
          this.addErrorNotification(e);
          this.dialogUbicacion = false;
        });
    },
    async mostrarDialogAddSupervisor() {
      await this.listarSupervisores();
      this.dialogAddSupervisor = true;
    },
    async guardarSupervisor() {
      const datos = {
        nombre_completo: this.nombreCompletoSupervisor,
        ficha: this.fichaSupervisor,
      };
      await api
        .insertarSupervisor(datos)
        .then(() => {
          this.addSuccessNotification("Datos ingresados correctamente");
          this.mostrarFormAddSupervisor = false;
          this.listarSupervisores();
        })
        .catch(() => {
          this.addErrorNotification();
        });
    },
    async listarSupervisores() {
      await api.listarSupervisores().then((e) => {
        this.supervisores = e.data;
      });
    },
    async updateEstatusSupervisor(_id, estatus) {
      let datos = {
        _id,
        estatus,
      };
      await api
        .actualizarEstatus(datos)
        .then(() => {
          this.listarSupervisores();
          this.obtenerSupervisorActivo();
          this.addSuccessNotification("Supervisor actualziado correctamente");
        })
        .catch((err) => {
          this.addErrorNotification();
          console.log(err);
        });
    },
    async obtenerSupervisorActivo() {
      await api.obtenerSupervisorActivo().then((e) => {
        this.supervisorActivo = e.data;
      });
    },
    costoToDollar(text) {
      let number = parseFloat(text);
      return number.toLocaleString("en-US");
    },
    mostrarDialogMonto(item) {
      this.osSelect = item.os
      this.dialogMonto = true;
      //busqueda de partidas en el json
      if (item.montoMaximo && item.montoMinimo) {
        this.montoMaximo = item.montoMaximo;
        this.montoMinimo = item.montoMinimo;
        this.idOrdenServicio = item._id;
        return;
      }else{
        this.montoMaximo = 0
        this.montoMinimo = 0
      }
      let partidasEncontradas = [];
      partidas.forEach((e) => {
        if (item.partidasEquipo.some((r) => r.partida == e.partida)) {
          partidasEncontradas.push(e);
        }
      });
      const groups = partidasEncontradas.reduce((groups, item) => {
        const group = groups[item.titulo] || [];
        group.push(item);
        groups[item.titulo] = group;
        return groups;
      }, {});
      let partidasMontoTotal = 0;
      let partidasGroup = Object.entries(groups);
      partidasGroup.forEach((e) => {
        e[1].forEach((f) => {
          let { equipo } = item.partidasEquipo.find((e) => e.partida == f.partida);
          f.equipo = equipo;
        });
      });

      partidasGroup.forEach((e) => {
        if (item.partidasEquipo.length > 30) {
          e[1].forEach((f) => {
          let total = 0;
          let totalPartida = 0
          if (
              f.partida == 11 ||
              f.partida == 13 ||
              f.partida == 15 ||
              f.partida == 12 ||
              f.partida == 14 ||
              f.partida == 16
            ) {
              total = (calculos.calculoTotalFechas(
                f.dias,
                item.periodo,
                f.partida,
                item.partidasEquipo)-f.diasR
              );
              totalPartida = f.costo * total;
            } else if(f.tipo=='Hora'  || f.unidad == 'Km' || f.tipo=='SERVICIO'){
              total = f.cantidad
              totalPartida = f.costo * total;

            } else{
              total =
                (calculos.calculoTotalFechas(
                  f.dias,
                  item.periodo,
                  f.partida,
                  item.partidasEquipo
                ) * f.equipo.length)-f.diasR;
                totalPartida = f.costo * total;
            }
            partidasMontoTotal += totalPartida;
         });
        }else{
          e[1].forEach((f) => {
            let total = 0;
            let totalPartida = 0
            if(f.unidad == 'Hora' || f.unidad == 'Km' || f.tipo=='SERVICIO'){
              total = f.cantidad
              totalPartida = f.costo * total;

            }else{
            total =
             (calculos.calculoTotalFechas(
                f.dias,
                item.periodo,
                f.partida,
                item.partidasEquipo
              ) * f.equipo.length)-f.diasR;
              totalPartida = f.costo * total;
            }
            partidasMontoTotal += totalPartida;

          })
        }
       
      });
      this.montoMaximo = this.costoToDollar(parseFloat(partidasMontoTotal).toFixed(2));
      this.idOrdenServicio = item._id;
    },

    eliminarPartida(item){
      let i = this.partidasEquipos.indexOf(item);
      if (i != -1) {
        this.partidasEquipos.splice(i, 1);
      }
    },

   /*  test(){
      console.log(this.partidasEquipos)
    }, */

    mostrarDialogMonto2(item){
      console.log(item)
    },

    updateMonto() {
      let datos = {
        _id: this.idOrdenServicio,
        montoMaximo: this.montoMaximo,
        montoMinimo: this.montoMinimo,
      };
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("/os/updateMontoOrden", { datos }, configuracion)
        .then(() => {
          this.listar()
          this.addSuccessNotification("Orden de servicio actualizada");
          this.dialogMonto = false;
        })
        .catch((err) => {
          this.addErrorNotification();
          console.log(err);
        });
    },
  },
};
</script>
