import jsPDF from "jspdf";


export const orden_salida_pdf = ()=>{
    var doc = new jsPDF("p", "mm", "a4");
    var startX = 20;
    var startY = 10;
    
    doc.addImage(
      "https://i.ibb.co/xfG9TpF/Logotipo-apaisado-vectores-CICSA-Recuperado.png",
      startX + 3,
      startY + 3,
      35,
      9
    );

    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);

    // Título de la primera sección
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("RELACION DE SALIDA", 80, 20);
    
    // Título de la segunda sección
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("RELACION DE SALIDA", 80, 150);

    // Define el contenido del PDF
    const contenido = [
      { etiqueta: "Fecha y hora de salida:", x: 25, y: 35 },
      { etiqueta: "No. económico:", x: 25, y: 45 },
      { etiqueta: "Tipo de unidad", x: 25, y: 55 },
      { etiqueta: "No. de serie:", x: 25, y: 65 },
      { etiqueta: "No. placa", x: 25, y: 75 },
      { etiqueta: "Marca:", x: 115, y: 35 },
      { etiqueta: "Contratante/cliente:", x: 115, y: 45 },
      { etiqueta: "Lugar de destino:", x: 115, y: 55 },
      { etiqueta: "Periodo de renta:", x: 115, y: 65 },
      { etiqueta: "Nombre del operador:", x: 115, y: 75},
      //Contenido segunda sección
      { etiqueta: "Fecha y hora de salida:", x: 25, y: 165 },
      { etiqueta: "No. económico:", x: 25, y: 175 },
      { etiqueta: "Tipo de unidad", x: 25, y: 185 },
      { etiqueta: "No. de serie:", x: 25, y: 195 },
      { etiqueta: "No. placa", x: 25, y: 205 },
      { etiqueta: "Marca:", x: 115, y: 165 },
      { etiqueta: "Contratante/cliente:", x: 115, y: 175 },
      { etiqueta: "Lugar de destino:", x: 115, y: 185 },
      { etiqueta: "Periodo de renta:", x: 115, y: 195 },
      { etiqueta: "Nombre del operador:", x: 115, y: 205},
    ];
    
    //Rectángulos de texto
    doc.roundedRect(60, 31, 40, 6, 2, 2);
    doc.roundedRect(60, 41, 40, 6, 2, 2);
    doc.roundedRect(60, 51, 40, 6, 2, 2);
    doc.roundedRect(60, 61, 40, 6, 2, 2);
    doc.roundedRect(60, 71, 40, 6, 2, 2);
    //Rectángulos de texto 2
    doc.roundedRect(60, 161, 40, 6, 2, 2);
    doc.roundedRect(60, 171, 40, 6, 2, 2);
    doc.roundedRect(60, 181, 40, 6, 2, 2);
    doc.roundedRect(60, 191, 40, 6, 2, 2);
    doc.roundedRect(60, 201, 40, 6, 2, 2);

    //Rectágulos de texto
    doc.roundedRect(155, 31, 35, 6, 2, 2);
    doc.roundedRect(155, 41, 35, 6, 2, 2);
    doc.roundedRect(155, 51, 35, 6, 2, 2);
    doc.roundedRect(155, 61, 35, 6, 2, 2);
    doc.roundedRect(155, 71, 35, 6, 2, 2);
    //Rectágulos de texto 2
    doc.roundedRect(155, 161, 35, 6, 2, 2);
    doc.roundedRect(155, 171, 35, 6, 2, 2);
    doc.roundedRect(155, 181, 35, 6, 2, 2);
    doc.roundedRect(155, 191, 35, 6, 2, 2);
    doc.roundedRect(155, 201, 35, 6, 2, 2);

    // Agrega el contenido al PDF
    contenido.forEach(({ etiqueta, x, y }) => {
      doc.setFont("helvetica", "normal");
      doc.setFontSize(9);
      doc.text(etiqueta, x, y);
    });

    // Título de la segunda sección
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("RELACIÓN DE ENTRADA", 80, 90);
    // Título de la segunda sección 2
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("RELACIÓN DE ENTRADA", 80, 220);

    // Agrega rectángulos y texto de la segunda sección
    doc.roundedRect(55, 95, 135, 6, 2, 2);
    doc.roundedRect(55, 105, 135, 6, 2, 2);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    doc.setTextColor("#fff");
    doc.text("Fecha de ingreso:", 25,100);
    doc.text("Hora de ingreso:", 25, 110);
    // Agrega rectángulos y texto de la segunda sección
    doc.roundedRect(55, 225, 135, 6, 2, 2);
    doc.roundedRect(55, 235, 135, 6, 2, 2);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    doc.setTextColor("#fff");
    doc.text("Fecha de ingreso:", 25,230);
    doc.text("Hora de ingreso:", 25, 240);

    // Agrega rectángulo y texto de las firmas
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.setTextColor("#fff");
    doc.text("ELABORÓ", 30, 122);
    doc.text("NOMBRE Y FIRMA", 25, 135);

    doc.text("AUTORIZA SALIDA", 70, 122);
    doc.text("NOMBRE Y FIRMA", 70, 135);

    doc.text("VIGILANCIA", 120, 122);
    doc.text("NOMBRE Y FIRMA", 115, 135);

    doc.text("ACTIVO FIJO", 165, 122);
    doc.text("NOMBRE Y FIRMA", 160, 135);

    // Agrega rectángulo y texto de las firmas 2
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.setTextColor("#fff");
    doc.text("ELABORÓ", 30, 252);
    doc.text("NOMBRE Y FIRMA", 25, 265);

    doc.text("AUTORIZA SALIDA", 70, 252);
    doc.text("NOMBRE Y FIRMA", 70, 265);

    doc.text("VIGILANCIA", 120, 252);
    doc.text("NOMBRE Y FIRMA", 115, 265);

    doc.text("ACTIVO FIJO", 165, 252);
    doc.text("NOMBRE Y FIRMA", 160, 265);

    doc.setFontSize(9);
      doc.setTextColor.apply(this, [128, 128, 128]);
    let colorBlanco = "#4A4A4A"; // Color blanco en formato hexadecimal
    let colorRojo = "#B21E28";
    doc.setTextColor(colorBlanco);
    doc.text("www.", 55, 330);
    let textoWwwWidth =
      doc.getStringUnitWidth("www.") * doc.internal.getFontSize();
    doc.setTextColor(colorRojo);
    doc.text("cicsagruas", 255 + textoWwwWidth, 830);
    let textoCicsagruasWidth =
      doc.getStringUnitWidth("cicsagruas") * doc.internal.getFontSize();
    doc.setTextColor(colorBlanco);
    doc.text(".com", 255 + textoWwwWidth + textoCicsagruasWidth, 830);

    doc.setTextColor("#292929");
    var blob = doc.output("blob");
    var url = URL.createObjectURL(blob);
    var ventanaPopup = window.open("", "_blank", "width=900,height=700");
    ventanaPopup.document.write(
      '<embed src="' +
        url +
        '" type="application/pdf" width="100%" height="100%" />'
    );

}