<template>
  <v-container fluid>
    <div class="tables-basic">
      <br />
      <h1 class="page-title mt-16 mb-6">Partidas</h1>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-6 pb-3">
              <v-row>
                <v-col cols="3">
                  <v-dialog
                  v-model="dialogNuevaEmpresa"
                  persistent
                  max-width="1000px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      rounded
                      color="primary"
                      small
                      dark
                      >Nueva Partida</v-btn
                    >
                    
                 
                    
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              :items="numero_contratos"
                              label="Numero de Contrato"
                              v-model="contrato"
                            ></v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="numeroPartida"
                              type="number"
                              label="Numero de Partida"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              :items="jornadas"
                              label="Jornada"
                              v-model="jornada"
                            ></v-select>
                          </v-col>
                          <v-col cols="12">
                             <vuetify-money  
                                dense
                                
                                v-model="importe_devengado"
                                label="Importe Devengado"
                                v-bind:options="options"
                              />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="jornada_devengado"
                              type="number"
                              label="Jornada Devengado"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <vuetify-money
                              cleareble="true"
                              v-model="costo"
                              label="Costo de la Unidad"
                              v-bind:options="options"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="concepto"
                              label="Concepto"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row v-if="editedIndex == 1">
                          <v-col cols="5">
                            <v-combobox
                              v-model="equiposRequeridosReg"
                              multiple
                              :search-input.sync="searchEquipo"
                              :items="equiposActivosLista"
                              label="Equipo"
                              small-chips
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      No se encontraron resultados "<strong>{{
                                        searchEquipo
                                      }}</strong
                                      >". Presiona <kbd>enter</kbd> para crear
                                      un nuevo item
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-combobox>
                          </v-col>
                          <v-col cols="5">
                            <v-select
                              v-model="horario"
                              :items="horarios"
                              label="Horario"
                            ></v-select>
                          </v-col>
                          <v-col cols="1">
                            <v-btn
                              @click="agregarEquiposPartida"
                              small
                              class="mx-2"
                              fab
                              dark
                              color="indigo"
                            >
                              <v-icon dark> mdi-plus </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12">
                            <v-simple-table dense>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">Equipos</th>
                                    <th class="text-left">Horarios</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="item in equipoPartidas"
                                    :key="item.horarios"
                                  >
                                    <td>
                                      <v-combobox
                                        v-model="item.equipo"
                                        multiple
                                        :search-input.sync="searchEquipo"
                                        :items="equiposActivosLista"
                                        small-chips
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                No se encontraron resultados
                                                "<strong>{{
                                                  searchEquipo
                                                }}</strong
                                                >". Presiona
                                                <kbd>enter</kbd> para crear un
                                                nuevo item
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </template>
                                      </v-combobox>
                                    </td>
                                    <td>
                                      <v-select
                                        v-model="item.horario"
                                        :items="horarios"
                                      ></v-select>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="cerrarModalEmpresa()"
                      >
                        Cerrar
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="guardar()">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                </v-col>
                <v-col cols="9">
                  <v-select
                v-model="numero_contrato_seleccionado"
               
                :items="numero_contratos"
                @input="listar"
               
                label="Numero de Contrato"
              
              >
              <template v-slot:selection="data">
                  <v-chip
                 
                    :input-value="data.text"
                 
                  >
                    <v-avatar left>
                      <v-icon color="green" v-if="data.item.estatus=='Activo'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <v-icon color="orange" v-if="data.item.estatus=='Pendiente'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <v-icon color="red" v-if="data.item.estatus=='Finalizado'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                    </v-avatar>
                    {{ data.item.text }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                 
                  <template >
                    <v-list-item-avatar>
                       <v-icon color="green" v-if="data.item.estatus=='Activo'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <v-icon color="orange" v-if="data.item.estatus=='Pendiente'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <v-icon color="red" v-if="data.item.estatus=='Finalizado'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.text"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.estatus"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
                    
              </v-select>
                </v-col>

        
              </v-row>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="partidas"
              :search="search"
              item-key="nombreComercial"
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-icon @click="editItem(item)" small class="mr-2"
                  >mdi-border-color</v-icon
                >
                <template>
                  <v-icon class="mr-2">mdi-delete</v-icon>
                </template>
              </template>
              <template v-slot:[`item.estatus`]="{ item }">
                <v-icon color="green" v-if="item.numero_contrato.estatus=='Activo'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <v-icon color="orange" v-if="item.numero_contrato.estatus=='Pendiente'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <v-icon color="red" v-if="item.numero_contrato.estatus=='Finalizado'">
                        mdi-checkbox-blank-circle
                      </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import Costeo from "../../api/Costeo";
export default {
  data() {
    return {
      headers: [
        { text: "Actions", value: "action", sortable: false },
        { text: "Contrato", value: "numero_contrato.numero_contrato", sortable: true },
        { text: "Numero de Partida", value: "numeroPartida", sortable: true },
        { text: "Costo", value: "costo", sortable: true },
        { text: "Concepto", value: "concepto", sortable: true },
        { text: "Estatus", value: "estatus", sortable: true },
      ],
      partidas: [],
      search: "",
      dialogNuevaEmpresa: false,
      contrato: "",
      numeroPartida: "",
      jornada:"",
      editedIndex: -1,
      concepto: "",
      representante: "",
      importe_devengado:0,
      submited: "",
      numero_contrato_seleccionado:'',
      alert: "",
      jornadas:['JOR.','SERVICIO','KM','HORA'],
      contratos:[],
      numero_contratos: [],
      jornada_devengado:"",
      horario: "",
      costo: "",
      horarios: [
        "(08:00 a 18:00)",
        "(08:00 a 20:00)",
        "(07:00 a 19:00)",
        "(19:00 a 07:00)",
        "(00:00 a 24:00)",
        "(08:00 a 16:00)",
        "(00:00 a 08:00 )",
        "( 16:00 a 00:00)",
        "( 07:00 a 23:00)",
        "(20:00 a 06:00)",
        "(07:00 a 22:00)"
      ],
      searchEquipo: "",
      equiposRequeridosReg: [],
      equiposActivosLista: [],
      equipoPartidas: [],
      options: {
        locale: "en-US",
        prefix: "$",
        suffix: "",
        length: 11,

        company_logo: {
          w: 110,
          h: 35,
        },

        precision: 2,
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Partida" : "Editar Partida";
    },
  },
  created() {
    this.listar();
    this.mostrarActivos();
    this.mostrarNumerosDeContrato();
  },
  methods: {
    async listar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      console.log(this.numero_contrato_seleccionado);
      await axios
        .get("/contrato/obtenerContratoActivo")
        .then(function (response) {
          me.contratos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
        console.log(me.contratos);
        let id = ''
        if(this.numero_contrato_seleccionado==''){
          id = me.contratos._id
        }else{
          id = this.numero_contrato_seleccionado
        }
        console.log(id);
      await axios
        .get("/partidas/listContratos?id="+id, configuracion)
        .then(function (response) {
          me.partidas = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    limpiar() {
      this.jornada = ''
      this.importe_devengado = 0
      this.editedIndex = -1;
    },
    guardar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      if (this.editedIndex > -1) {
        axios
          .put(
            "/partidas/update",
            {
              _id: this._id,
              numeroPartida: this.numeroPartida,
              costo: this.costo,
              concepto: this.concepto,
              equipoPartidas: this.equipoPartidas,
              numero_contrato: this.contrato,
              jornada_abrev:this.jornada,
              importe_devengado:this.importe_devengado,
              jornada_devengado:this.jornada_devengado
            },
            configuracion
          )
          .then(function (response) {
            console.log(response);
            me.listar();
            me.dialogNuevaEmpresa = false;
            me.addSuccessNotification("Empresa actualizada");
            me.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        axios
          .post(
            "/partidas/add",
            {
              numero_contrato: this.contrato,
              numeroPartida: this.numeroPartida,
              costo: this.costo,
              concepto: this.concepto,
              jornada_abrev:this.jornada
            },
            configuracion
          )
          .then(function (response) {
            console.log(response);
            me.listar();
            me.dialogNuevaEmpresa = false;
            me.addSuccessNotification("Empresa agregada");
            me.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    editItem(item) {
      this._id = item._id;
      this.contrato = item.numero_contrato._id;
      this.numeroPartida = item.numeroPartida;
      this.costo = item.costo;
      this.concepto = item.concepto;
      this.equipoPartidas = item.equipoPartidas;
      this.jornada = item.jornada_abrev
      this.importe_devengado = item.importe_devengado
      this.jornada_devengado = item.jornada_devengado
      this.editedIndex = 1;

      this.dialogNuevaEmpresa = true;
    },

    agregarEquiposPartida() {
      this.equipoPartidas.push({
        equipo: this.equiposRequeridosReg,
        horario: this.horario,
      });
    },

    mostrarNumerosDeContrato() {
      Costeo.obtenerNumerosdeContrato().then((response) => {
        this.numero_contratos = response.data;
      });
    },

    mostrarActivos() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let equiposActivos = [];
      let configuracion = { headers: header };
      axios
        .get("/activos/listActivosPemex", configuracion)
        .then(function (response) {
          equiposActivos = response.data;
          equiposActivos.map((x) => {
            me.equiposActivosLista.push(
               x.numeroEconomico
            );
          });

          me.equiposActivosLista.push("MANIOBRISTA 01");
          me.equiposActivosLista.push("MANIOBRISTA 02");
          me.equiposActivosLista.push("MANIOBRISTA 03");
          me.equiposActivosLista.push("MANIOBRISTA 04");
          me.equiposActivosLista.push("MANIOBRISTA 05");
          me.equiposActivosLista.push("MANIOBRISTA 06");
          me.equiposActivosLista.push("MANIOBRISTA 07");
          me.equiposActivosLista.push("MANIOBRISTA 08");
          me.equiposActivosLista.push("MANIOBRISTA 09");
          me.equiposActivosLista.push("MANIOBRISTA 10");
          me.equiposActivosLista.push("MANIOBRISTA 11");
          me.equiposActivosLista.push("MANIOBRISTA 12");
          me.equiposActivosLista.push("MANIOBRISTA 13");
          me.equiposActivosLista.push("MANIOBRISTA 14");
          me.equiposActivosLista.push("MANIOBRISTA 15");
          me.equiposActivosLista.push("MANIOBRISTA 16");
          me.equiposActivosLista.push("MANIOBRISTA 17");
          me.equiposActivosLista.push("MANIOBRISTA 18");
          me.equiposActivosLista.push("MANIOBRISTA 19");
          me.equiposActivosLista.push("MANIOBRISTA 20");
          me.equiposActivosLista.push("MANIOBRISTA 21");
          me.equiposActivosLista.push("MANIOBRISTA 22");
          me.equiposActivosLista.push("MANIOBRISTA 23");
          me.equiposActivosLista.push("MANIOBRISTA 24");
          me.equiposActivosLista.push("MANIOBRISTA 25");
          me.equiposActivosLista.push("MANIOBRISTA 26");
          me.equiposActivosLista.push("MANIOBRISTA 27");
          me.equiposActivosLista.push("MANIOBRISTA 28");
          me.equiposActivosLista.push("MANIOBRISTA 29");
          me.equiposActivosLista.push("MANIOBRISTA 30");
          me.equiposActivosLista.push("MANIOBRISTA 31");
          me.equiposActivosLista.push("MANIOBRISTA 32");
          me.equiposActivosLista.push("MANIOBRISTA 33");
          me.equiposActivosLista.push("MANIOBRISTA 34");
          me.equiposActivosLista.push("MANIOBRISTA 35");
          me.equiposActivosLista.push("MANIOBRISTA 36");
          me.equiposActivosLista.push("MANIOBRISTA 37");
          me.equiposActivosLista.push("MANIOBRISTA 38");
          me.equiposActivosLista.push("MANIOBRISTA 39");
          me.equiposActivosLista.push("MANIOBRISTA 40");
          me.equiposActivosLista.push("MANIOBRISTA 41");
          me.equiposActivosLista.push("MANIOBRISTA 42");
          me.equiposActivosLista.push("MANIOBRISTA 43");
          me.equiposActivosLista.push("MANIOBRISTA 44");
          me.equiposActivosLista.push("MANIOBRISTA 44");
          me.equiposActivosLista.push("MANIOBRISTA 45");
          me.equiposActivosLista.push("MANIOBRISTA 46");
          me.equiposActivosLista.push("MANIOBRISTA 47");
          me.equiposActivosLista.push("MANIOBRISTA 48");
          me.equiposActivosLista.push("MANIOBRISTA 49");
          me.equiposActivosLista.push("MANIOBRISTA 50");

          me.equiposActivosLista.push("MANIOBRISTA 51");
          me.equiposActivosLista.push("MANIOBRISTA 52");
          me.equiposActivosLista.push("MANIOBRISTA 53");
          me.equiposActivosLista.push("MANIOBRISTA 54");
          me.equiposActivosLista.push("MANIOBRISTA 55");
          me.equiposActivosLista.push("MANIOBRISTA 56");
          me.equiposActivosLista.push("MANIOBRISTA 57");
          me.equiposActivosLista.push("MANIOBRISTA 58");
          me.equiposActivosLista.push("MANIOBRISTA 59");
          me.equiposActivosLista.push("MANIOBRISTA 60");
          me.equiposActivosLista.push("MANIOBRISTA 61");
          me.equiposActivosLista.push("MANIOBRISTA 62");
          me.equiposActivosLista.push("MANIOBRISTA 63");
          me.equiposActivosLista.push("MANIOBRISTA 64");
          me.equiposActivosLista.push("MANIOBRISTA 65");
          

          me.equiposActivosLista.push("MANIOBRISTA 66");
          me.equiposActivosLista.push("MANIOBRISTA 67");
          me.equiposActivosLista.push("MANIOBRISTA 68");
          me.equiposActivosLista.push("MANIOBRISTA 69");
          me.equiposActivosLista.push("MANIOBRISTA 70");
          me.equiposActivosLista.push("MANIOBRISTA 71");

          me.equiposActivosLista.push("MANIOBRISTA 72");
          me.equiposActivosLista.push("MANIOBRISTA 73");
          me.equiposActivosLista.push("MANIOBRISTA 74");
          me.equiposActivosLista.push("MANIOBRISTA 75");
          me.equiposActivosLista.push("MANIOBRISTA 76");
          me.equiposActivosLista.push("MANIOBRISTA 77");

          me.equiposActivosLista.push("MANIOBRISTA 78");
          me.equiposActivosLista.push("MANIOBRISTA 79");
          me.equiposActivosLista.push("MANIOBRISTA 80");
          me.equiposActivosLista.push("MANIOBRISTA 81");
          me.equiposActivosLista.push("MANIOBRISTA 82");
          me.equiposActivosLista.push("MANIOBRISTA 83");
          
          me.equiposActivosLista.push("CABO 01");
          me.equiposActivosLista.push("CABO 02");
          me.equiposActivosLista.push("CABO 03");
          me.equiposActivosLista.push("CABO 04");
          me.equiposActivosLista.push("CABO 05");
          me.equiposActivosLista.push("CABO 06");
          me.equiposActivosLista.push("CABO 07");
          me.equiposActivosLista.push("CABO 08");
          me.equiposActivosLista.push("CABO 09");
          me.equiposActivosLista.push("CABO 10");
          me.equiposActivosLista.push("CABO 11");
          me.equiposActivosLista.push("CABO 12");
          me.equiposActivosLista.push("CABO 13");

          me.equiposActivosLista.push("CABO 14");
          me.equiposActivosLista.push("CABO 15");
          me.equiposActivosLista.push("CABO 16");
          me.equiposActivosLista.push("04 Tecnicos en Operaciones");
          me.equiposActivosLista.push("G-220 TON");
          me.equiposActivosLista.push("G-400 TON");
          me.equiposActivosLista.push("G-750 TON");
          me.equiposActivosLista.push("LOWBOY");
        })
        .catch(function () {});
    },
    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    cerrarModalEmpresa() {
      this.dialogNuevaEmpresa = false;
      this.limpiar();
      this.editedIndex = -1;
    },
  },
};
</script>
