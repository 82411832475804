import axios from "axios";

export default {
  //----ENDPOINTS CONTRATO----//

  obtenerContratos() {
    return axios.get("/contrato/list");
  },
  insertarContratos(datos) {
    return axios.post("/contrato/add", datos);
  },
  finalizarContrato(datos) {
    return axios.put("/contrato/finalizarContrato", datos);
  },
  activarContrato(datos) {
    return axios.put("/contrato/activarContrato", datos);
  },
  obtenerNumerosdeContrato() {
    return axios.get("/contrato/obtenerNumerosContratos");
  },
  obtenerContratoActivo() {
    return axios.get("/contrato/obtenerContratoActivo");
  },

  //----ENDPOINTS PARTIDA-----//

  //ENDOPINTS INSERTAR MULTIPLES ACTIVOS PEMEX

  insertarActivosDesglosados(datos) {
    return axios.post("/activos_desglosados/add", datos);
  },
  deleteAllActivosDesglosados(dato) {
    return axios.delete("/activos_desglosados/deleteAll?fecha=" + dato);
  },
  prorratearInDb(dato, fecha, total) {
    return axios.get(
      "/activos_desglosados/prorratearDb?pp=" +
        dato +
        "&fecha=" +
        fecha +
        "&total=" +
        total
    );
  },
  limpiarInDb(dato, fecha, total) {
    return axios.get(
      "/activos_desglosados/limpiarDb?pp=" +
        dato +
        "&fecha=" +
        fecha +
        "&total=" +
        total
    );
  },
  listar(fecha) {
    return axios.get("/activos_desglosados/list?fecha=" + fecha);
  },
  conexion() {
    return axios.get(
      "https://apigmg.gruasmoviles.com.mx/apigerencia/revision_internet"
    );
  },
  findOne(fecha, pp) {
    return axios.get(
      "/activos_desglosados/findOne?fecha=" + fecha + "&pp=" + pp
    );
  },
  sumar(dato, fecha, total) {
    return axios.get(
      "/activos_desglosados/sumar?pp=" +
        dato +
        "&fecha=" +
        fecha +
        "&total=" +
        total
    );
  },
  importarCosteo(datos) {
    return axios.post("/costeo_imp/add", datos);
  },
  actualizarRestante(datos) {
    return axios.put("/costeo_imp/update_restante", datos);
  },
  queryCosteo(id) {
    return axios.get("/costeo/query?_id=" + id);
  },

  //ENPOINTS ESTIMACION
  insertarEstimacion(datos) {
    return axios.post("/estimacion/add", datos);
  },

  //Historial Trazabilidad Endoponts
  insertarTrazabilidadHistorial(datos) {
    return axios.post("/historial_trazabilidad/add", datos);
  },
  listHistorialTrazabilidad() {
    return axios.get("/historial_trazabilidad/list");
  },
  deleteHistorialTrazabilidad() {
    return axios.delete("/historial_trazabilidad/deleteAll");
  },

  //Trazabilidad
  listQueryTrazabilidad(id, page, datos) {
    return axios.put(
      "/trazabilidad/queryId?_id=" + id + "&page=" + page,
      datos
    );
  },
  listQueryTrazabilidaMes(mes) {
    return axios.get("/trazabilidad/queryTrazabilidadMes?mes=" + mes);
  },
  dashboardCosteoEquiposFs(mes) {
    return axios.get("/trazabilidad/costeoDashboardEquiposFS?mes=" + mes);
  },
  dashboardCosteoEquiposMtto(mes) {
    return axios.get("/trazabilidad/costeoDashboardEquiposMtto?mes=" + mes);
  },
  dashboardCosteoEquiposJornada(mes) {
    return axios.get("/trazabilidad/costeoDashboardJornada?mes=" + mes);
  },
  dashboardTotalMeses() {
    return axios.get("/trazabilidad/costeoDashboardTotalMonto");
  },
  pushTrazabilidad(datos) {
    return axios.post("/trazabilidad/pushRegistro", datos);
  },
  pushTrazabilidadMayorUno(datos) {
    return axios.post("/trazabilidad/pushRegistroMayorUno", datos);
  },
  pushTrazabilidadEspecial(datos) {
    return axios.post("/trazabilidad/pushRegistroEspecial", datos);
  },
  editarRegistroTrazabilidad(datos) {
    return axios.post("/trazabilidad/updateRegistros", datos);
  },
  editarRegistroTrazabilidadEspecial(datos) {
    return axios.put("/trazabilidad/updateRegistrosEspecial", datos);
  },
  editarRegistroTrazabilidadMasDeUno(datos) {
    return axios.put("/trazabilidad/updateRegistrosMasDeUno", datos);
  },
  eliminarRegistroTrazabilidad(_id, idTrazabilidad) {
    return axios.put(
      "/trazabilidad/deleteRegistro?_id=" +
        _id +
        "&idTrazabilidad=" +
        idTrazabilidad
    );
  },
  aperturarMes(datos) {
    return axios.post("/trazabilidad/aperturarMes", datos);
  },
  trazabilidadMesCompleto(mes) {
    return axios.get("/trazabilidad/trazabilidadMes?mes=" + mes);
  },
  queryTrazabili(_id) {
    return axios.get("/trazabilidad/queryTrazabil?_id=" + _id);
  },
  updateTodo(datos) {
    return axios.put("/trazabilidad/updateTodo", datos);
  },
  updateTebMensual(datos) {
    return axios.put("/trazabilidad/updateTebMensual", datos);
  },
  obtenerEquiposTrazabilidad(_id) {
    return axios.get("/trazabilidad/obtenerEquiposTrazabilidad?_id=" + _id);
  },

  //TEB
  buscarTebMes(mes) {
    return axios.get("teb/queryMesT?mes=" + mes);
  },
  buscarTeb(teb, _id) {
    return axios.get("/teb/buscarTeb?_id=" + _id + "&teb=" + teb);
  },
  buscarActivo(pp) {
    return axios.get("/pp_activo/query?pp=" + pp);
  },

  // Trazabilidad Indice

  insertarTrazabilidadIndice(datos) {
    return axios.post("/trazabilidad_ind/add", datos);
  },
  listarTrazabilidadIndice() {
    return axios.get("/trazabilidad_ind/list");
  },
  listarTrazabilidadDashboard(mes) {
    return axios.get("/trazabilidad/queryDashboard?mes=" + mes);
  },
  listarTrazabilidadDashboardFs(mes) {
    return axios.get("/trazabilidad/costeoDashboardEquiposFS?mes=" + mes);
  },

  // PRORRATEO
  listarProrrateoMes(mes) {
    return axios.get("/costeo/listar_prorrateo?mes=" + mes);
  },
};
