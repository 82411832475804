<template>
     <v-container fluid>
    <div class="tables-basic"><br>
      <h1 class="page-title mt-16 mb-6">Usuarios</h1>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-6 pb-3">
            
              <v-row >
    <v-dialog
      v-model="dialogNuevaEmpresa"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
           <v-btn  v-bind="attrs"  v-on="on" rounded color="primary" small dark>Nueva Usuario</v-btn>
      </template>
      <v-card>
        <v-card-title>
         <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select :items="roles" v-model="rol" label="Rol"></v-select>
                  
              </v-col>
            
              <v-col cols="12">
                <v-col>
                    <v-autocomplete  :items="personales" v-model="personal" label="Personal"></v-autocomplete>
                </v-col>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="usuario" label="Correo" ></v-text-field>
              </v-col>
                <v-col cols="12">
                <v-text-field  @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" v-model="password" label="Contraseña" ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="cerrarModalEmpresa()"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="guardar()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
            
              dense
              :headers="headers"
              :items="usuarios"
              :search="search"
              item-key="nombreComercial"
              
              >
                <template v-slot:[`item.action`]="{item}">
          <v-icon @click="editItem(item)" small class="mr-2" >mdi-border-color</v-icon>
          <template >
            <v-icon  class="mr-2" >mdi-delete</v-icon>
          </template>
        </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
     </v-container>
</template>

<script>
import axios from 'axios'
import {required} from "vuelidate/lib/validators"
import emailjs from "emailjs-com"
export default {
    validations:{
        razonSocial:{
            required
        },
    
        rfc:{
            required,
        },
        nombreComercial:{
            required,
        },
        representante:{
            required,
        },
    },
    data(){
        return{
            headers: [
                { text: "Actions", value: "action", sortable: false },
                { text: "Nombre", value: "personal.nombreCompleto", sortable: true },
                { text: "Departamento", value: "personal.departamento.departamento", sortable: true },
                { text: "Empresa", value: "personal.empresa.nombreComercial", sortable: true },
                { text: "Usuario", value: "usuario", sortable: true },  
                { text: "Rol", value: "rol", sortable: true },            
            ],
            empresas:[],
            search:'',
            dialogNuevaEmpresa:false,
            roles:['root','capturista','usuario','pemex','mtto','activos','jefe_mtto','mecanico'],
            personales:[],
            personal:'',
            usuarios:[],
            showPassword:false,
            rol:'',
             editedIndex: -1,
            usuario:'',
            password:'',
            submited:'',
            alert:'',
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "Nuevo Usuario" : "Editar Usuario";
        },
    },
    created(){
        this.listar()
        this.selectPersonal(),
         this.emailIn();
    },
    methods:{
        listar(){
            let me = this;
              let header={"Token":this.$store.state.token};
              let configuracion= {headers: header}
            axios.get('/usuario/list/',configuracion).then(function (response){
                me.usuarios = response.data
            }).catch(function(error){
                console.log(error)
            })
        },
        emailIn() {
      emailjs.init("user_QA8qMBuyHCQIHTgR0AsPx");
    },
        limpiar(){
            this.personal='',
            this.password='',
            this.usuario='',
            this.rol=''
            this.editedIndex = -1;
            
        },
        guardar(){
            let me = this;
              let header={"Token":this.$store.state.token};
              let configuracion= {headers: header}
            if(this.editedIndex>-1){
               
                axios.put('/usuario/update/',{'_id':this._id,'personal':this.personal,'password':this.password,'usuario':this.usuario,'rol':this.rol},configuracion)
                .then(function(response){
                    console.log(response)
                    me.listar()
                    me.dialogNuevaEmpresa=false;
                    me.addSuccessNotification('Usuario actualizada')
                    me.limpiar()
                })
                .catch(function(error){
                    console.log(error)
                })
            }else{
          
            axios.post('/usuario/add',{'personal':this.personal,'password':this.password,'usuario':this.usuario,'rol':this.rol},configuracion)
            .then(function(response){
                me.listar()
                me.dialogNuevaEmpresa=false;
                me.addSuccessNotification('Usuario agregada')
                me.enviar(response.data.usuario, me.password);
                me.limpiar()
            })
            .catch(function(error){
                console.log(error)
            })
            }
        },

        enviar(email,password) {
          var template_params = {
            email: email,
            password: password
          };

          emailjs.send("service_8owmxkf", "altaUsuarioCIC", template_params).then(
            function (response) {
              console.log(
                "SUCCESS. status=%d, text=%s",
                response.status,
                response.text
              );
            },
            function (err) {
              alert("Ocurrió un problema al enviar el correo");
              console.log("FAILED. error=", err);
            }
          ); 
    },

        editItem(item) {
            this._id = item._id;
            this.personal = item.personal._id;
           
            this.usuario = item.usuario;
            this.rol = item.rol;
            this.editedIndex = 1;
            this.password = item.password
            this.dialogNuevaEmpresa = true;
        },
        addSuccessNotification(mensaje) {
                this.$toast.success(mensaje, {
                position: 'top-right',
                timeout: 2000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true
            });
        },
          selectPersonal(){
            let me = this;
            let personalArray=[];
            axios.get('/personal/list').then((response)=>{
              personalArray=response.data;
              personalArray.map(function(x){
                me.personales.push({text:x.nombreCompleto, value:x._id})
              });
            }).catch(function(error){
              console.log(error);
            })
          },
         addErrorNotification() {
                this.$toast.error("Error, verifique los campos ingresados", {
                position: 'top-right',
                timeout: 2000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true
            });   
        },
        cerrarModalEmpresa(){
            this.dialogNuevaEmpresa =false;
            this.limpiar()
            this.editedIndex = -1
        }

    }
}
</script>
