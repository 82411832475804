import axios from "axios";

let token = process.env.VUE_APP_TOKEN_MICROSIP;
let configuracion = {
  headers: {
    Authorization: `Bearer ` + token,
  },
};

export default {
  async obtenerRequisicionesMtto() {
    console.log(configuracion);
    return await axios.get(
      "https://api-microsip.ddns.net/api_microsip/cicsa/listaRequisicionesCicsa",
      configuracion
    );
  },
  obtenerRequisicionesDetMtto(datos) {
    return axios.post(
      "https://api-microsip.ddns.net/api_microsip/cicsa/listaRequisicionesDetCicsa",
      datos,
      configuracion
    );
  },
  guiaRequisicionFolio(datos) {
    return axios.post(
      "https://api-microsip.ddns.net/api_microsip/cicsa/mostrarSeguimientoRequi",
      datos,
      configuracion
    );
  },
};
