import axios from "axios";

export default {
  evidenciasOt: async (file) => {
    try {
      const formData = new FormData();
      formData.append("upload_preset", "evidencias_ot");
      formData.append("file", file);

      const url = "https://api.cloudinary.com/v1_1/cicsa/image/upload";
      const { data } = await axios.post(url, formData);
      console.log(data);
      return data.secure_url;
    } catch (error) {
      console.log(error);
    }
  },

  avatarUnidades: async (file) => {
    try {
      const formData = new FormData();
      formData.append("upload_preset", "avatar_unidades");
      formData.append("file", file);

      const url = "https://api.cloudinary.com/v1_1/cicsa/image/upload";
      const { data } = await axios.post(url, formData);
      return data.secure_url;
    } catch (error) {
      console.log(error);
    }
  },

  evidenciasDocOt: async (file) => {
    try {
      const formData = new FormData();
      formData.append("upload_preset", "evidencias_doc_ot");
      formData.append("file", file);

      const url = "https://api.cloudinary.com/v1_1/cicsa/image/upload";
      const { data } = await axios.post(url, formData);
      return data.secure_url;
    } catch (error) {
      console.log(error);
    }
  },
};
