import Vue from "vue";
import Vuex from "vuex";
import router from "../Routes";
import decode from "jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
  namespace: true,
  state: {
    drawer: true,
    token: null,
    usuario: null,
    _id: null,
    ruta: "Inicio",
    count: 1,
    trazabilidadParaProrratear: [],
    tebProrratear: [],
    activosGeneral: [],
    idTrazabilidad: "",
    mes: null,
    cuadrillas: [],
    trazFs: [],
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUsuario(state, usuario) {
      state.usuario = usuario;
    },
    setId(state, _id) {
      state._id = _id;
    },
    setRuta(state, ruta) {
      state.ruta = ruta;
    },
    setTrazabilidadProrrat(state, trazabilidadParaProrratear) {
      state.trazabilidadParaProrratear = trazabilidadParaProrratear;
    },
    setIdTrazabilidad(state, idTrazabilidad) {
      state.idTrazabilidad = idTrazabilidad;
    },
    setTebProrrat(state, tebProrratear) {
      state.tebProrratear = tebProrratear;
    },
    setActivosGeneral(state, activosGeneral) {
      state.activosGeneral = activosGeneral;
    },
    setCuadrillas(state, cuadrillas) {
      state.cuadrillas = cuadrillas;
    },
    setTrazFs(state, trazFs) {
      state.trazFs = trazFs;
    },
    setMes(state, mes) {
      state.mes = mes;
    },
    increment(state, n) {
      state.count += n;
    },
  },
  actions: {
    TOGGLE_DRAWER({ commit }) {
      commit("toggleDrawer");
    },
    guardarToken({ commit }, token) {
      commit("setToken", token);
      commit("setUsuario", decode(token));
      localStorage.setItem("token", token);
    },
    guardarTraz({ commit }, trazabilidadParaProrratear) {
      commit("setTrazabilidadProrrat", trazabilidadParaProrratear);
      localStorage.setItem("traz", JSON.stringify(trazabilidadParaProrratear));
    },
    guardarFs({ commit }, trazFs) {
      commit("setTrazFs", trazFs);
      localStorage.setItem("trazFs", JSON.stringify(trazFs));
    },
    guardarTeb({ commit }, tebProrratear) {
      commit("setTebProrrat", tebProrratear);
      localStorage.setItem("teb", JSON.stringify(tebProrratear));
    },
    guardarIdTraz({ commit }, idTrazabilidad) {
      commit("setIdTrazabilidad", idTrazabilidad);
      localStorage.setItem("idTraz", idTrazabilidad);
    },
    guardarActivosGeneral({ commit }, activosGeneral) {
      commit("setActivosGeneral", activosGeneral);
      localStorage.setItem("activosGeneral", JSON.stringify(activosGeneral));
    },
    guardarCuadrillas({ commit }, cuadrillas) {
      commit("setCuadrillas", cuadrillas);
      localStorage.setItem("cuadrillas", JSON.stringify(cuadrillas));
    },
    mes({ commit }, mes) {
      commit("setMes", mes);
      localStorage.setItem("mes", mes);
    },

    autoLogin({ commit }) {
      let token = localStorage.getItem("token");
      let _id = localStorage.getItem("_id");
      if (token) {
        commit("setToken", token);
        commit("setId", _id);
        commit("setUsuario", decode(token));
      }
      router.push({ name: "Dashboard" });

      //  router.back()
    },
    salir({ commit }) {
      commit("setToken", null);
      commit("setUsuario", null);
      localStorage.removeItem("token");
      router.push({ name: "login" });
    },
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer;
    },
  },
});
