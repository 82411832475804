<template>
  <v-container fluid>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="tables-basic">
      <v-dialog transition="dialog-bottom-transition" max-width="375" v-model="dialog_info_reporte">
        <template>
          <v-card max-width="375" class="mx-auto">
            <v-img v-if="info_reporte.activo" :src="info_reporte.activo.fotos[2]" height="300px" dark>
              <v-row class="fill-height">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-btn dark class="mr-4" icon @click="dialog_info_equipo = false">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-card-title>

                <v-spacer></v-spacer>

                <v-card-title class="white--text pl-12 pt-12"> </v-card-title>
              </v-row>
            </v-img>

            <v-list two-line>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary"> mdi-apps </v-icon>
                </v-list-item-icon>
                <v-list-item-content v-if="info_reporte.activo">
                  <v-list-item-title><span style="font-size: 12px">Tipo de Unidad</span></v-list-item-title>
                  <v-list-item-subtitle>{{
                    info_reporte.activo.tipoUnidad
                    }}</v-list-item-subtitle>
                  <v-list-item-title><span style="font-size: 12px">Numero de Serie</span></v-list-item-title>
                  <v-list-item-subtitle>{{
                    info_reporte.activo.numeroSerie
                    }}</v-list-item-subtitle>
                  <v-list-item-title><span style="font-size: 12px">Numero Economico</span></v-list-item-title>
                  <v-list-item-subtitle>{{
                    info_reporte.activo.numeroEconomico
                    }}</v-list-item-subtitle>
                  <v-list-item-title><span style="font-size: 12px">Estatus</span></v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip small color="green" v-if="info_reporte.activo.estatu == 'Operativo'" text-color="white">
                      {{ info_reporte.activo.estatu }}
                    </v-chip></v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-chip small v-if="info_reporte.activo.estatu == 'Fuera de Servicio'" color="red"
                      text-color="white">
                      {{ info_reporte.activo.estatu }}
                    </v-chip></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary"> mdi-information </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title><span style="font-size: 12px">Descripción de la falla</span></v-list-item-title>
                  <v-list-item-subtitle>{{
                    info_reporte.descripcionFalla
                    }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary"> mdi-badge-account </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title><span style="font-size: 12px">Solicitante</span></v-list-item-title>
                  <v-list-item-subtitle v-if="info_reporte.usuarioCreador">{{
                    info_reporte.usuarioCreador.nombreCompleto
                    }}</v-list-item-subtitle>
                  <v-list-item-title><span style="font-size: 12px">Correo</span></v-list-item-title>
                  <v-list-item-subtitle v-if="info_reporte.usuarioCreador">{{
                    info_reporte.usuarioCreador.correo
                    }}</v-list-item-subtitle>
                  <v-list-item-title><span style="font-size: 12px">Telefono</span></v-list-item-title>
                  <v-list-item-subtitle v-if="info_reporte.usuarioCreador">{{
                    info_reporte.usuarioCreador.telefono
                    }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogLoader" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Espere por favor
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCorreos" max-width="400px">
        <v-card>
          <v-card-title>Ingrese los correos</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 200px">
            <br />
            <v-textarea outlined name="input-7-4" label="Ingrese los correos electronicos"
              v-model="correos"></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="dialogCorreos = false">
              Cerrar
            </v-btn>
            <v-btn color="blue darken-1" text @click="enviarEmailReporteFallas">
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogFirmaLog" max-width="600px">
        <v-card>
          <v-card-title>Firmar reporte de fallas</v-card-title>
          <v-card-subtitle>Para firmar la solicitud deberá tener registrada su firma en la base de
            datos</v-card-subtitle>
          <v-divider></v-divider>

          <v-card-text>
            <div v-if="info_reporte.usuarioCreador && info_reporte.activo">
              <div style="margin-top: 15px;">
                <span style="font-size: 15px; font-weight: 400 ; color:black">Información del RF</span>
                <div class="">
                  <span>Folio #{{ info_reporte.folio_falla }}</span>
                </div>
              </div>
              <div style="margin-top: 15px;">
                <span style="font-size: 15px; font-weight: 400 ; color:black">Descripción</span>
                <div class="">
                  <span>{{ info_reporte.descripcionFalla }}</span>
                </div>
              </div>

              <div style="margin-top: 15px;">
                <span style="font-size: 15px; font-weight: 400 ; color:black">Creado por</span>
                <div class="">
                  <span>{{ info_reporte.usuarioCreador.nombreCompleto }}</span>
                </div>
              </div>

              <div style="margin-top: 15px;">
                <span style="font-size: 15px; font-weight: 400 ; color:black">Equipo</span>
                <div class="">
                  <span>{{ info_reporte.activo.tipoUnidad }} {{ info_reporte.activo.numeroEconomico }}</span>
                </div>
              </div>
              <div>

              </div>
              <div style="margin-top: 25px;">
                <span style="font-size: 15px; font-weight: 400 ; color:black">Firmado por:</span>
                <v-list subheader two-line>
                  <v-list-item v-if="info_reporte.firma_log.firmado">
                    <v-list-item-avatar>
                      <v-icon class="grey lighten-1" dark>
                        mdi-account-check
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title style="font-size:15px">{{ info_reporte.firma_log.full_name
                        }}</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 10px;">Firmado por Logística
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 10px;">Firmado el {{
                        moment(info_reporte.firma_log.fecha_firma).format('LLLL') }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="info_reporte.firma_mtto.firmado">
                    <v-list-item-avatar>
                      <v-icon class="grey lighten-1" dark>
                        mdi-account-check
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title style="font-size:15px">{{ info_reporte.firma_mtto.full_name
                        }}</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 10px;">{{
                        moment(info_reporte.firma_mtto.fecha_firma).format('LLLL') }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                  </v-list-item>
                </v-list>
                <div v-if="!info_reporte.firma_log.firmado">
                  <p style="text-align: center; margin-top:40px; font-weight: bold;">
                    Solicitud pendiente de firmar</p>
                  <v-btn color="primary" block @click="firmarLogistica">
                    Firmar
                  </v-btn>
                </div>
              </div>
            </div>

          </v-card-text>
          <v-divider></v-divider>

        </v-card>
      </v-dialog>
      <v-dialog persistent scrollable v-model="dialogReporteDeFallas" width="1100" height="800">
        <v-card>
          <v-card-title>Nuevo Reporte de Fallas</v-card-title>
          <v-divider></v-divider> <br />
          <v-card-text style="height: 400px">
            <v-row>
              <v-col cols="12" lg="3" sm="12">
                <v-autocomplete v-model="numeroEconomicoBusqueda" prepend-icon="mdi-pier-crane" :items="activosSelect"
                  menu-props="auto" @input="listarActivoPorId" label="Equipo" append-icon="mdi-search">
                  <template v-slot:selection="data">
                    <v-chip :input-value="data.text">
                      <v-avatar left>
                        <v-icon color="green" v-if="data.item.estatus == 'Operativo'">
                          mdi-checkbox-blank-circle
                        </v-icon>
                        <v-icon color="orange" v-if="data.item.estatus == 'Pendiente'">
                          mdi-checkbox-blank-circle
                        </v-icon>
                        <v-icon color="red" v-if="data.item.estatus == 'Fuera de Servicio'">
                          mdi-checkbox-blank-circle
                        </v-icon>
                      </v-avatar>
                      {{ data.item.text }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-avatar>
                        <v-icon color="green" v-if="data.item.estatus == 'Operativo'">
                          mdi-checkbox-blank-circle
                        </v-icon>
                        <v-icon color="orange" v-if="data.item.estatus == 'Pendiente'">
                          mdi-checkbox-blank-circle
                        </v-icon>
                        <v-icon color="red" v-if="data.item.estatus == 'Fuera de Servicio'">
                          mdi-checkbox-blank-circle
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          data.item.text
                          }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          data.item.estatus
                          }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" lg="3" sm="12">
                <v-text-field type="date" v-model="fechaFalla" :min="fechaActual" @input="test"
                  prepend-icon="mdi-clipboard-text-clock" label="Fecha de la Falla"></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" sm="12">
                <v-text-field v-model="operador" prepend-icon="mdi-account" label="Operador"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="3">
                <v-select :items="tipoDeFallas" menu-props="auto" label="Tipo de atención" v-model="tipoDeFalla"
                  prepend-icon="mdi-lightbulb-alert"></v-select>
              </v-col>
              <v-col v-if="tipoDeFalla == 'Otro'" cols="12" lg="3" sm="12">
                <v-text-field v-model="otraFalla" prepend-icon="mdi-plus" label="Falla"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select :items="prioridades" menu-props="auto" label="Prioridad" v-model="prioridad"
                  prepend-icon="mdi-priority-high"></v-select>
              </v-col>
              <v-col cols="12" lg="9" sm="12">
                <v-textarea clearable outlined v-model="descripcion" clear-icon="mdi-close-circle"
                  label="Descripcion de la falla" prepend-icon="mdi-file-document-alert"></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="dialogReporteDeFallas = false">
              Cerrar
            </v-btn>
            <v-btn color="blue darken-1" text @click="guardar"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogEstatusUnidad" max-width="500px">
        <v-card>
          <v-card-title class="texto">Cambiar estatus de unidad {{ ne_cambio_estatus }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 320px">
            <br />
            <p class="texto" style="font-size: 14px; color: #666; margin-bottom: 10px">
              Reporte de falla
            </p>
            <v-text-field outlined disabled name="input-7-4" dense v-model="reporte_cambio_estatus"></v-text-field>
            <p class="texto" style="font-size: 14px; color: #666; margin-bottom: 10px">
              Fecha de cambio de estatus
            </p>
            <v-text-field outlined type="datetime-local" name="input-7-4" dense
              v-model="fecha_cambio_estatus"></v-text-field>
            <p class="texto" style="font-size: 14px; color: #666; margin-bottom: 10px"> La unidad <b>{{
              ne_cambio_estatus
                }} </b> actualmente tiene el estatus de: <b> {{ estatus_cambio_estatus ? 'Fuera de Servicio' :
                  'Operativo'
                }}</b> desea cambiar su estatus a <b> {{ estatus_cambio_estatus ? 'Operativo' :
                  'Fuera de Servicio' }}</b> ?
            </p>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="dialogEstatusUnidad = false">
              Cerrar
            </v-btn>
            <v-btn color="blue darken-1" text @click="cambiarEstatus()">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog persistent scrollable v-model="dialogSeguimientoReporteFalla" width="1100" height="800">
        <v-card>
          <v-card-title class="texto"><b> R-{{ reporte_falla_seguimiento.folio_falla
              }}</b> </v-card-title>
          <v-divider></v-divider> <br />
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="5" sm="5">
                <div v-if="reporte_falla_seguimiento.activo">
                  <v-img v-if="reporte_falla_seguimiento.activo.avatar" :src="reporte_falla_seguimiento.activo.avatar">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-2"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <div v-else :src="reporte_falla_seguimiento.activo.avatar">
                    <v-img v-if="reporte_falla_seguimiento.activo.avatar"
                      src="https://res.cloudinary.com/cicsa/image/upload/v1669661111/logos/jgb0ztkvoromgyjcv5zk.jpg">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-2"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" lg="7" sm="7">
                <v-row>
                  <v-col cols="12" lg="12" sm="12">
                    <h1 class="texto " style="font-weight: bold; font-size:15px; text-align:center">Información
                      del
                      RF</h1>
                  </v-col>
                  <v-expansion-panels style="border-radius: 15px; " accordion focusable>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <p class="texto " style="font-weight: bold; font-size:13px; text-align:center">
                          RF-{{ reporte_falla_seguimiento.folio_falla }} / {{ reporte_falla_seguimiento.estatus }}
                        </p>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>

                          <v-col cols="12" lg="6" sm="12">
                            <span class="texto " style="font-weight: bold; font-size:12px">Folio</span>
                            <div class="input-filter_seguimiento">
                              <span class="texto ml-3" style="font-size:12px">
                                <b>{{ reporte_falla_seguimiento.folio_falla }}</b>
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="12" lg="6" sm="12">
                            <span class="texto " style="font-weight: bold; font-size:12px">Numero Economico</span>
                            <div class="input-filter_seguimiento " style="font-size:12px"
                              v-if="reporte_falla_seguimiento.activo">
                              <span class="texto ml-3">
                                <b>{{ reporte_falla_seguimiento.activo.numeroEconomico }}</b>
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="12" lg="12" sm="12">
                            <span class="texto " style="font-weight: bold; font-size:12px">Falla:</span>
                            <div class="">
                              <span class="texto ml-3" style="font-size:12px">
                                <b>{{ reporte_falla_seguimiento.descripcionFalla }}</b>
                              </span>
                            </div>
                          </v-col>

                          <v-col cols="12" lg="12" sm="12" v-if="reporte_falla_seguimiento.mecanico">
                            <span class="texto " style="font-weight: bold; font-size:12px">Mecanicos asignados para
                              diagnostico:</span>
                            <div class="texto">
                              <v-chip class="ma-2" color="indigo" text-color="white" small
                                v-for="(item, index) in reporte_falla_seguimiento.mecanico" :key="index">
                                <v-avatar left>
                                  <v-icon>mdi-account</v-icon>
                                </v-avatar>
                                {{ item }}
                              </v-chip>
                            </div>
                          </v-col>

                          <v-col cols="12" lg="12" sm="12" v-if="reporte_falla_seguimiento.fechaAsignacion">
                            <span class="texto " style="font-weight: bold; font-size:12px">Fecha de Asignación</span>
                            <div class="input-filter_seguimiento " style="font-size:12px">
                              <span class=" ml-3">
                                <b>{{ moment(reporte_falla_seguimiento.fechaAsignacion)
                                  .add(1, "days")
                                  .format("Do MMMM YYYY") }}</b>
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="12" lg="12" sm="12" v-if="reporte_falla_seguimiento.diagnostico">
                            <span class="texto " style="font-weight: bold; font-size:12px;">Diagnostico:</span>
                            <div class="">
                              <span class="texto ml-3" style="font-size:13px; text-align: center;">
                                <b>{{ reporte_falla_seguimiento.diagnostico }}</b>
                              </span>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>



                </v-row> <br>
                <v-divider></v-divider>

                <v-row>
                  <v-col cols="12" lg="12" sm="12" v-if="reporte_falla_seguimiento.ot"> <br>
                    <h1 v-if="reporte_falla_seguimiento.ot.length > 0" class="texto "
                      style="font-weight: bold; font-size:15px; text-align:center">Información de las OT
                      relacionadas</h1>
                  </v-col>
                  <v-expansion-panels style="border-radius: 15px; " accordion focusable>
                    <v-expansion-panel v-for="(item, index) in reporte_falla_seguimiento.ot" :key="index">
                      <v-expansion-panel-header>
                        <p class="texto " style="font-weight: bold; font-size:13px; text-align:center">
                          OT-{{ item.folio_ot }} / {{ item.estatus }}
                        </p>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" lg="12" sm="12">
                            <div>
                              <span class="texto" style="font-weight: bold; font-size:12px;">Tecnicos:</span>
                              <div class="">
                                <v-chip class="ma-2" color="indigo" text-color="white" small
                                  v-for="(mecanico, index) in item.tecnico" :key="index">
                                  <v-avatar left>
                                    <v-icon>mdi-account</v-icon>
                                  </v-avatar>
                                  {{ mecanico }}
                                </v-chip>
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="12" lg="6" sm="12">
                            <span class="texto " style="font-weight: bold; font-size:12px">Hora de llegada</span>
                            <div class="input-filter_seguimiento " style="font-size:12px">
                              <span class=" ml-3">
                                <b>{{ item.hora_apertura }}</b>
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="12" lg="6" sm="12">
                            <span class="texto " style="font-weight: bold; font-size:12px">Hora de Salida</span>
                            <div class="input-filter_seguimiento " style="font-size:12px">
                              <span class=" ml-3">
                                <b>{{ item.hora_vencimiento }}</b>
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="12" lg="12" sm="12">
                            <span class="texto " style="font-weight: bold; font-size:12px">Tipo de Intervención</span>
                            <div class="input-filter_seguimiento " style="font-size:12px">
                              <span class=" ml-3">
                                <b>{{ item.tipo_intervencion }}</b>
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="12" lg="12" sm="12">
                            <span class="texto " style="font-weight: bold; font-size:12px;">Procedimiento:</span>
                            <div class="">
                              <span class="texto ml-3" style="font-size:13px; text-align: center;">
                                <b>{{ item.procedimiento_trabajo }}</b>
                              </span>
                            </div>
                          </v-col>

                          <v-col cols="12" lg="12" sm="12">
                            <span class="texto " style="font-weight: bold; font-size:12px;">Observaciones:</span>
                            <div class="">
                              <span class="texto ml-3" style="font-size:13px; text-align: center;">
                                <b>{{ item.observaciones }}</b>
                              </span>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>

              </v-col>
            </v-row>
          </v-card-text>


          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="dialogSeguimientoReporteFalla = false">
              Cerrar
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>
      <br />

      <div>
        <div class="pa-1 mt-3">
          <div class="pa-3">
            <div class="my-5">
              <v-card-title>
                <h4 style="
                                                                                                                  font-size: 24px;
                                                                                                                  font-weight: bold;
                                                                                                                  color: #333;
                     
                                                                                                                ">
                  Reportes de fallas
                </h4>
                <v-spacer></v-spacer>
                <span class="texto" style="font-size: 14px; color: #666; margin-right: 20px">Todos los reportes</span>
                <v-switch @click="listarTodosReportes" v-model="usuario_activo"></v-switch>
              </v-card-title>
              <p style="font-size: 14px; color: #666; margin-bottom: 10px " class="texto">
                En la siguiente tabla se muestran todos los reportes de fallas
                generados en el sistema:
              </p>
              <div class="input-filter">

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" color="primary" class="mb-2">
                      <v-icon>mdi-filter-plus</v-icon>
                    </v-btn>
                  </template>

                  <v-list>

                    <v-list-item dense @click="showFilterFolio = true">
                      <v-list-item-title> <span class="texto">Folio</span> </v-list-item-title>
                    </v-list-item>
                    <v-list-item dense @click="showFilterFecha = true">
                      <v-list-item-title> <span class="texto">Fecha</span></v-list-item-title>
                    </v-list-item>
                    <v-list-item dense @click="showFilterNumeroEconomico = true">
                      <v-list-item-title> <span class="texto">Numero Economico</span></v-list-item-title>
                    </v-list-item>
                    <v-list-item dense @click="showFilterOperador = true">
                      <v-list-item-title> <span class="texto">Operador</span></v-list-item-title>
                    </v-list-item>
                    <v-list-item dense @click="showFilterTipoUnidad = true">
                      <v-list-item-title> <span class="texto">Tipo de Unidad</span></v-list-item-title>
                    </v-list-item>
                    <v-list-item dense @click="showFilterTipoFalla = true">
                      <v-list-item-title> <span class="texto">Tipo de Falla</span></v-list-item-title>
                    </v-list-item>
                    <v-list-item dense @click="showFilterEstatus = true">
                      <v-list-item-title> <span class="texto">Estatus</span></v-list-item-title>
                    </v-list-item>
                  </v-list>



                </v-menu>
                <div class="text-center">

                </div>
                <div v-if="arr.length == 0" class="filter-label">
                  <span>Ningun filtro seleccionado...</span>
                </div>
                <div v-else class="filter-chips">
                  <v-chip class="ml-2" v-for="(item, index) in arr" :key="index" close close-icon="mdi-close-circle"
                    @click:close="eliminarFiltro(index)" color="primary" small>
                    {{ item.label }}
                  </v-chip>

                </div>
                <v-dialog v-model="showFilterNumeroEconomico" transition="dialog-bottom-transition" max-width="350">
                  <v-card>
                    <v-toolbar color="primary" dark>Busqueda por Numero Economico</v-toolbar>
                    <v-card-text class="mt-10">
                      <v-autocomplete return-object v-model="numero_economico_filter" outlined dense
                        prepend-icon="mdi-pier-crane" :items="activosSelect" label="Equipo" append-icon="mdi-search">



                      </v-autocomplete>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="filtroLabelPush('numero_economico')">Aplicar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="showFilterFolio" transition="dialog-bottom-transition" max-width="350">
                  <v-card>
                    <v-toolbar color="primary" dark>Busqueda por Folio</v-toolbar>
                    <v-card-text class="mt-10">
                      <v-text-field v-model="folio_filter" outlined dense
                        label="Ingrese el folio que decea filtrar"></v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="filtroLabelPush('folio')">Aplicar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="showFilterOperador" transition="dialog-bottom-transition" max-width="350">
                  <v-card>
                    <v-toolbar color="primary" dark>Busqueda por Operador</v-toolbar>
                    <v-card-text class="mt-10">
                      <v-text-field v-model="operador_filter" outlined dense
                        label="Ingrese el operador que decea filtrar"></v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="filtroLabelPush('operador')">Aplicar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="showFilterEstatus" transition="dialog-bottom-transition" max-width="350">
                  <v-card>
                    <v-toolbar color="primary" dark>Busqueda por Estauts</v-toolbar>
                    <v-card-text class="mt-10">
                      <v-select v-model="estatus_filter" :items="['En revisión', 'Asignado', 'Pendiente', 'Cerrado']"
                        outlined dense label="Ingrese el estatus que decea filtrar"></v-select>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="filtroLabelPush('estatus')">Aplicar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="showFilterTipoFalla" transition="dialog-bottom-transition" max-width="350">
                  <v-card>
                    <v-toolbar color="primary" dark>Busqueda por Tipo de falla</v-toolbar>
                    <v-card-text class="mt-10">
                      <v-select v-model="tipo_falla_filter"
                        :items="['Mecánica', 'Hidráulica', 'Electrica', 'Neumática']" outlined dense
                        label="Ingrese el tipo de falla que decea filtrar"></v-select>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="filtroLabelPush('tipoFalla')">Aplicar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="showFilterTipoUnidad" transition="dialog-bottom-transition" max-width="350">
                  <v-card>
                    <v-toolbar color="primary" dark>Busqueda por Tipo de unidad</v-toolbar>
                    <v-card-text class="mt-10">
                      <v-select v-model="tipo_unidad_filter" :items=tiposDeUnidad outlined dense
                        label="Ingrese el tipo de unidad que decea filtrar"></v-select>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="filtroLabelPush('tipoUnidad')">Aplicar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="showFilterFecha" transition="dialog-bottom-transition" max-width="350">
                  <v-card>
                    <v-toolbar color="primary" dark>Busqueda por Fecha</v-toolbar>
                    <v-card-text class="mt-10">
                      <v-text-field v-model="fecha_filter" type="date" outlined dense
                        label="Ingrese la fecha que decea filtrar"></v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="filtroLabelPush('fecha')">Aplicar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>



              </div>
              <template>
                <v-data-table style="border-radius: 15px;" dense :headers="headers" :items="reportes"
                  :items-per-page="10" hide-default-footer class="elevation-5 mt-5">
                  <template v-slot:[`header.folio_falla`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.prioridad`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.fechaFalla`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.usuarioCreador.nombreCompleto`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.tipoFalla`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.activo.numeroEconomico`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.operador`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.estatus`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                    </span>
                  </template>

                  <template v-slot:[`item.folio_falla`]="{ item }">
                    <span class="text--secondary; texto">#R-{{ item.folio_falla }}</span>
                  </template>
                  <template v-slot:[`item.fechaFalla`]="{ item }">
                    <span style="color: gray; font-size: 12px">{{
                      moment(item.fechaFalla)
                        .add(1, "days")
                        .format("Do MMMM YYYY")
                    }}</span>
                  </template>
                  <template v-slot:[`item.usuarioCreador.nombreCompleto`]="{ item }">
                    <span class="texto" style="
                                                                                                                      font-size:13px
                                                                                                                    ">
                      {{ item.usuarioCreador.nombreCompleto }}
                    </span>
                  </template>
                  <template v-slot:[`item.operador`]="{ item }">

                    <span class="d-inline-block text-truncate texto"
                      style="
                                                                                                                          max-width: 140px;                                                                                                      ">
                      {{ item.operador.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`item.activo.numeroEconomico`]="{ item }">
                    <v-chip v-if="item.activo.estatu == 'Operativo'" small class="ma-2" color="green" outlined pill>
                      <v-icon left> mdi-crane </v-icon>
                      {{ item.activo.numeroEconomico }}
                    </v-chip>
                    <v-chip v-if="item.activo.estatu == 'Fuera de Servicio'" small class="ma-2" color="red" outlined
                      pill>
                      <v-icon left> mdi-crane </v-icon>
                      {{ item.activo.numeroEconomico }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.tipoFalla`]="{ item }">
                    <span class="texto" style="font-weight: bold; font-size: 13px;">
                      {{ item.tipoFalla }}
                    </span>
                  </template>
                  <template v-slot:[`item.prioridad`]="{ item }">
                    <span class="texto" style="
                                                                                                                      font-weight: bold;
                                                                                                                      font-size: 13px;
                                                                                                                    ">
                      {{ item.prioridad }}
                    </span>
                  </template>
                  <template v-slot:[`item.estatus`]="{ item }">
                    <div v-if="item.estatus == 'Cerrado'">
                      <v-icon small color="gray darken-2"> mdi-check </v-icon>
                      <span @click="seguiminetoReporteFalla(item)" class="texto"
                        style="
                                                                                                                        color: #7cb380;
                                                                                                                        font-size: 13px;
                                                                                                                        cursor:pointer;
                                                                                                                        text-decoration: underline;
                                                                                                                      ">
                        {{ item.estatus }}
                      </span>
                    </div>
                    <div v-if="item.estatus == 'Pendiente'">
                      <v-icon small color="gray darken-2">
                        mdi-progress-clock
                      </v-icon>
                      <span @click="seguiminetoReporteFalla(item)" class="texto"
                        style="
                                                                                                                        color: #ffb347;
                                                                                                                        cursor:pointer;
                                                                                                                        text-decoration: underline;
                                                                                                                        font-size: 13px;
                                                                                                                      ">
                        {{ item.estatus }}
                      </span>
                    </div>
                    <div v-if="item.estatus == 'Asignado'">
                      <v-icon small color="gray darken-2">
                        mdi-account-hard-hat
                      </v-icon>
                      <span @click="seguiminetoReporteFalla(item)" class="texto"
                        style="
                                                                                                                        color: #d8bfd8;
                                                                                                                        cursor:pointer;
                                                                                                                        text-decoration: underline;
                                                                                                                        font-size: 13px;
                                                                                                                      ">
                        {{ item.estatus }}
                      </span>
                    </div>
                    <div v-if="item.estatus == 'En revisión'">
                      <v-icon small color="gray darken-2">
                        mdi-eye-check
                      </v-icon>
                      <span @click="seguiminetoReporteFalla(item)" class="texto"
                        style="
                                                                                                                        color: #e0c122;
                                                                                                                        cursor:pointer;
                                                                                                                        text-decoration: underline                                                                                                         ;
                                                                                                                        font-size: 13px;
                                                                                                                      ">
                        {{ item.estatus }}
                      </span>
                    </div>
                  </template>
                  <template v-slot:[`item.options`]="{ item }">
                    <div>
                      <v-menu :close-on-content-click="false" :nudge-width="10" offset-x>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="ma-2" text dense icon color="gray" v-bind="attrs" v-on="on" link>
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>

                        </template>

                        <v-card width="400px">
                          <v-list dense dark color="dark">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title class="texto">Opciones para :</v-list-item-title>
                                <!-- <v-list-item-subtitle v-if="item.activo.numeroEconomico"
                                      >{{ item.activo.numeroEconomico }}
                                    </v-list-item-subtitle> -->
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>

                          <v-divider></v-divider>

                          <v-list class="texto">
                            <v-list-item-group v-model="selectedItem" color="#3768D4" dense>
                              <v-list-item v-if="item.estatus == 'Borrador'" dense @click="editItem(item)">
                                <v-list-item-icon>
                                  <v-icon>mdi-file-edit</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-subtitle>Editar</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense @click="mostrarDialogInfoReporte(item)">
                                <v-list-item-icon>
                                  <v-icon>mdi-information</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-subtitle>Información</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense @click="mostrarDialogCorreos(item)">
                                <v-list-item-icon>
                                  <v-icon>mdi-email</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-subtitle>Enviar
                                    notificacion</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense @click="mostrarDialogFirmaLog(item)">
                                <v-list-item-icon>
                                  <v-icon>mdi-account-key</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-subtitle>Firmar</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- <v-list-item dense @click="generarReporteLayout(item, true)">
                                <v-list-item-icon>
                                  <v-icon>mdi-file-pdf-box</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-subtitle>Generar Reporte</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item> -->
                              <v-list-item dense @click="generarExcelReporte(item)">
                                <v-list-item-icon>
                                  <v-icon>mdi-microsoft-excel</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-subtitle>Generar Reporte SOP-20-F-01</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="item.activo.estatu == 'Operativo'" dense
                                @click="mostrarDialogCambiarEstatus(item)">
                                <v-list-item-icon>
                                  <v-icon>mdi-close-octagon</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-subtitle>Cambiar equipo a F/S </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense v-if="item.estatus == 'Borrador'" @click="eliminarReporte(item._id)">
                                <v-list-item-icon>
                                  <v-icon>mdi-delete-sweep</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-subtitle>Eliminar Reporte</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense v-if="item.estatus == 'Borrador'" @click="
                                actualizarEstatus(item, 'En revisión')
                                ">
                                <v-list-item-icon>
                                  <v-icon>mdi-update</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-subtitle>Actualizar
                                    estatus</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- <v-list-item
                                    dense
                                    v-if="item.ot.length>0"
                                    @click="
                                      actualizarEstatus(item, 'En revisión')
                                    "
                                  >
                                    <v-list-item-icon>
                                      <v-icon>mdi-tools</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        >Ordenes de Trabajo</v-list-item-subtitle
                                      >
                                    </v-list-item-content>
                                  </v-list-item> -->
                            </v-list-item-group>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </div>
                  </template>
                </v-data-table>
                <v-pagination v-if="!this.usuario_activo && !this.reportes_cerrados" :total-visible="7" class="mt-5"
                  v-model="page" @input="listar()" :length="pageCount"></v-pagination>
                <v-pagination v-if="this.usuario_activo" :total-visible="7" class="mt-5" v-model="page"
                  @input="listarTodosReportes()" :length="pageCount"></v-pagination>
              </template>
            </div>
          </div>
        </div>
      </div>

      <v-btn class="button-fab" dark @click="mostrarNuevo()" fab color="primary"><v-icon>mdi-plus</v-icon></v-btn>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import axios from "axios";
import emailjs from "emailjs-com";
import MttoApi from "../../api/Mtto";
import EmailApi from "../../api/Email";
//import WhatsAppApi from "../../api/WhatsAppApi";
import { reporteFallaPdf } from "../../utils/reportes/ReporteFallaPdf";

export default {
  name: "app",
  mounted() {
    // Obtener la fecha actual
    let fechaActual = new Date();

    // Restar dos días a la fecha actual
    fechaActual.setDate(fechaActual.getDate() - 2);
    console.log(fechaActual);
    // Formatear la fecha en el formato "YYYY-MM-DD"
    this.fechaActual = this.formatoFecha(fechaActual);
  },
  data() {
    return {
      e1: 1,
      moment,
      tipoDeFalla: "",
      drawer: false,
      tipoDeFallas: [
        "Mecánica",
        "Hojalatería",
        "Estructural",
        "Hidráulica",
        "Carpintería",
        "Soldadura",
        "Électrica",
        "Neumática",
        "Sistema de aire",
        "Otro",
      ],
      prioridad: "",
      estatusEquipo: "",
      mecanicosActivos: [],
      componente: "",
      folio: "",
      activosSelect: [],
      numeroEconomicoBusqueda: "",
      mostrarDetalleDeActivo: false,
      activoPorQuery: [],
      cycle: true,
      id: "",
      dialogCancelar: false,
      fechaFalla: "",
      buscarEquipo: "",
      buscar: "",
      otraFalla: "",
      editedIndex: false,
      folioReporte: "",
      mecanico: "",
      searchEquipo: false,
      descripcion: "",
      reportes_cerrados: false,
      reportes: [],
      motivo_cancelacion: "",
      datosReporteFalla: [],
      componentes: [],
      trabajos: "",
      loadingReportes: false,
      idEquipo: "",
      dialogLoader: false,
      dialogCorreos: false,
      correos: "",
      reportesItemSelected: [],
      fechaActual: "",
      overlay: false,
      documentoReporteFalla: "",

      folioDisabled: false,
      accionMostrarFinalizado: false,
      page: 1,
      pageCount: 0,
      //Nuevos
      search: "",
      dialog_info_reporte: false,
      info_reporte: [],
      numeroEconomicoBusqueda2: "",
      selectedItem: 1,
      dialogReporteDeFallas: false,
      dialogSeguimientoReporteFalla: false,
      arr: [],
      headers: [
        { text: "Folio", value: "folio_falla" },
        { text: "Fecha", value: "fechaFalla" },
        { text: "Prioridad", value: "prioridad" },
        {
          text: "Solicitante",
          align: "start",
          sortable: false,
          value: "usuarioCreador.nombreCompleto",
        },
        { text: "Numero Economico", value: "activo.numeroEconomico" },
        { text: "Operador", value: "operador" },
        { text: "Tipo de Falla", value: "tipoFalla" },
        { text: "Estatus", value: "estatus" },
        { text: "", value: "options" },
      ],
      usuario_activo: false,
      tiposDeUnidad: [
        "Autos y Pick Ups",
        "Motocicletas",
        "Tractocamion",
        "Plataforma",
        "Grúa Terrestre",
        "Montacargas",
        "Cargador Frontal",
      ],
      prioridades: ["Baja", "Media", "Alta", "Critica"],
      operador: "",
      showFilterFolio: false,
      showFilterOperador: false,
      showFilterNumeroEconomico: false,
      showFilterEstatus: false,
      showFilterTipoFalla: false,
      showFilterFecha: false,
      showFilterTipoUnidad: false,
      folio_filter: '',
      numero_economico_filter: '',
      estatus_filter: '',
      tipo_falla_filter: '',
      fecha_filter: '',
      operador_filter: '',
      tipo_unidad_filter: '',
      reporte_falla_seguimiento: [],
      dialogEstatusUnidad: false,
      ne_cambio_estatus: '',
      equipo_cambio_estatus: '',
      fecha_cambio_estatus: '',
      reporte_cambio_estatus: '',
      estatus_cambio_estatus: '',
      dialogFirmaLog: false,
    };
  },
  computed: {
    filterItemsActivos() {
      return this.reportes.filter((item) => {
        return item.activo.numeroEconomico
          .toLowerCase()
          .includes(this.buscarEquipo.toLowerCase());
      });
    },
  },
  created() {
    this.listar();
    this.emailIn();
    this.$store.commit("setRuta", "Reporte de Fallas");
    this.selectMecanicos();
    this.listarActivos();
  },
  methods: {
    limpiar() {
      this.numeroEconomicoBusqueda = "";
      this.fechaFalla = "";
      this.operador = "";
      this.id = "";
      this.tipoDeFalla = "";
      this.descripcion = "";
      this.folio = "";
      this.activoPorQuery.fotos = [];
    },

    emailIn() {
      emailjs.init("user_QA8qMBuyHCQIHTgR0AsPx");
    },

    enviar(folio, descripcion, activo, tipoFalla, usuario, correo) {
      var template_params = {
        folio: folio,
        descripcion: descripcion,
        activo: activo,
        tipoFalla: tipoFalla,
        usuario: usuario,
        correo: correo,
      };

      emailjs
        .send("service_8owmxkf", "reporte_falla_mtto", template_params)
        .then(
          function (response) {
            console.log(
              "SUCCESS. status=%d, text=%s",
              response.status,
              response.text
            );
          },
          function (err) {
            alert("Ocurrió un problema al enviar el correo");
            console.log("FAILED. error=", err);
          }
        );
    },

    editItem(item) {
      const fechaOriginal = item.fechaFalla;
      const fecha = new Date(fechaOriginal);
      fecha.setHours(fecha.getHours() + 5); // Agregar 5 horas para convertir a GMT-5
      const fechaFormateada = fecha.toISOString().slice(0, 10);
      this.dialogReporteDeFallas = true;
      this.editedIndex = true;
      this.numeroEconomicoBusqueda = item.activo._id;
      this.numeroEconomicoBusqueda2 = item.activo.numeroEconomico;
      this.fechaFalla = fechaFormateada;
      this.operador = item.operador;
      this.id = item._id;
      this.prioridad = item.prioridad;
      this.tipoDeFalla = item.tipoFalla;
      this.descripcion = item.descripcionFalla;
      this.folio = item.folio;
      this.listarActivoPorNumeroEconomico2();
    },

    test() {
      console.log(this.fechaFalla);
    },

    listarActivoPorNumeroEconomico() {
      let me = this;
      axios
        .get(
          "/activos/queryNumeroEconomico?numeroEconomico=" +
          this.numeroEconomicoBusqueda
        )
        .then(function (response) {
          console.log(response.data);
          me.activoPorQuery = response.data;
          me.mostrarDetalleDeActivo = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    listarActivoPorNumeroEconomico2() {
      let me = this;
      axios
        .get(
          "/activos/queryNumeroEconomico?numeroEconomico=" +
          this.numeroEconomicoBusqueda2
        )
        .then(function (response) {
          console.log(response.data);
          me.activoPorQuery = response.data;
          me.mostrarDetalleDeActivo = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    mostrarNuevo() {
      this.dialogReporteDeFallas = true;
      this.limpiar();
    },
    ocultarNuevo() { },

    listarActivosT() {
      let me = this;
      axios
        .get("/reporteFallas/list")
        .then(function (response) {
          me.reportes = response.data;
          let tractos = [];
          for (let i = 0; i < me.reportes.length; i++) {
            if (me.reportes[i].activo.tipoUnidad == "Cargador Frontal") {
              tractos.push(me.reportes[i]);
            }
          }
          me.reportes = tractos;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    listarActivos() {
      let me = this;
      MttoApi.obtenerActivosSelect().then((res) => {
        me.activosSelect = res.data;
      });
    },

    enviarEmailReporteFallas() {
      this.generarReporteLayout(this.reportesItemSelected, false);
      this.dialogLoader = true;
      const folio = this.reportesItemSelected.folio_falla;

      const fechaOriginal = this.reportesItemSelected.fechaFalla;
      const fecha = new Date(fechaOriginal);
      fecha.setHours(fecha.getHours() + 5); // Agregar 5 horas para convertir a GMT-5
      const fechaFormateada = fecha.toISOString().slice(0, 10);

      const fechaReal = fechaFormateada;
      const numeroEconomico = this.reportesItemSelected.activo.numeroEconomico;

      //Información
      const tipoFalla = this.reportesItemSelected.tipoFalla;
      const operador = this.reportesItemSelected.operador;
      const descripcion = this.reportesItemSelected.descripcionFalla;
      const numeroSerie = this.reportesItemSelected.activo.numeroSerie;
      const estatusEquipo = this.reportesItemSelected.activo.estatu;
      const usuarioCreador =
        this.reportesItemSelected.usuarioCreador.nombreCompleto;
      const corres = this.correos;
      const documento = this.documentoReporteFalla;
      EmailApi.enviarEmailReporteFalla(
        folio,
        fechaReal,
        numeroEconomico,
        tipoFalla,
        operador,
        descripcion,
        numeroSerie,
        estatusEquipo,
        usuarioCreador,
        corres,
        documento
      )
        .then(() => {
          this.addSuccessNotification("Notificación enviada correctamente");
          this.dialogCorreos = false;
          (this.reportesItemSelected = []), (this.correos = "");
          this.documentoReporteFalla = "";
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dialogLoader = false;
        });
    },

    mostrarDialogCorreos(item) {
      this.dialogCorreos = true;
      this.reportesItemSelected = item;
    },

    async generarReporteLayout(item, valor) {
      this.overlay = true
      try {
        this.documentoReporteFalla = await reporteFallaPdf(item, valor);
      } catch (error) {
        console.error('Error al guardar el PDF:', error);
      } finally {
        this.overlay = false
      }

    },

    async listar() {
      this.overlay = true
      await MttoApi.reportFallaUsuarioCreador(this.$store.state.usuario.personal._id, this.page, this.arr).then((e) => {
        this.reportes = e.data.reg;
        this.pageCount = e.data.totalPaginas
      })
        .finally(() => {
          this.overlay = false;
          this.folioDisabled = false;
        });
    },

    listarFallaEquipoPorId() {
      MttoApi.obtenerFallasAmef(
        this.activoPorQuery.nombreActivo,
        this.componente
      )
        .then((e) => {
          this.fallasAmef = e.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    listarTodosReportes() {
      if (!this.usuario_activo && !this.reportes_cerrados) {
        this.listar();
      } else if (this.usuario_activo) {
        this.overlay = true;
        MttoApi.listarTodosReportes(this.page, this.arr)
          .then((e) => {
            this.reportes = e.data.reg;
            this.pageCount = e.data.totalPaginas
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.overlay = false;
          });
      } else {
        this.listarReportesCerrados();
      }
    },
    listarReportesCerrados() {
      if (!this.usuario_activo && !this.reportes_cerrados) {
        this.listar();
      } else if (this.reportes_cerrados) {
        this.overlay = true;
        MttoApi.listarReportesCerrados()
          .then((e) => {
            this.reportes = e.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.overlay = false;
          });
      } else {
        this.listarTodosReportes();
      }
    },

    listarActivoPorId() {
      let me = this;
      axios
        .get("/activos/queryEquipoId?_id=" + this.numeroEconomicoBusqueda)
        .then(function (response) {
          me.activoPorQuery = response.data;
          MttoApi.obtenerComponentesAmef(response.data.nombreActivo).then(
            (e) => {
              me.componentes = e.data;
              console.log(me.componentes);
            }
          );
          me.mostrarDetalleDeActivo = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    guardar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      let tipoFallaRegistrado = "";
      if (this.editedIndex == true) {
        if (this.tipoDeFallas == "Otro") {
          tipoFallaRegistrado = this.otraFalla;
        } else {
          tipoFallaRegistrado = this.tipoDeFalla;
        }
        axios
          .put(
            "/reporteFallas/update",
            {
              _id: this.id,
              activo: this.activoPorQuery._id,
              fechaFalla: this.fechaFalla,
              operador: this.operador,
              tipoFalla: tipoFallaRegistrado,
              descripcionFalla: this.descripcion,
              prioridad: this.prioridad,
            },
            configuracion
          )
          .then(function () {
            me.listar();
            me.dialogReporteDeFallas = false;
            me.addSuccessNotification("Reporte de falla editado correctamente");
            me.editedIndex = false;
          })
          .catch(function (error) {
            console.log(error);
            me.addErrorNotification();
          });
      } else {
        if (this.tipoDeFalla === "Otro") {
          if (this.otraFalla.length > 0) {
            tipoFallaRegistrado = this.otraFalla;
          } else {
            this.addErrorNotification();
          }
        } else {
          tipoFallaRegistrado = this.tipoDeFalla;
        }
        axios
          .post(
            "/reporteFallas/add",
            {
              activo: this.activoPorQuery._id,
              fechaFalla: this.fechaFalla,
              operador: this.operador,
              tipoFalla: tipoFallaRegistrado,
              descripcionFalla: this.descripcion,
              usuarioCreador: this.$store.state.usuario.personal._id,
              folio: this.folio,
              prioridad: this.prioridad,
            },
            configuracion
          )
          .then((response) => {


            //WhatsAppApi.notificacionNuevoReporteFalla(folio_reporte, equipo, tipo_falla, fecha_falla, descripcion);

            me.reportesItemSelected = response.data;
            me.correos = response.data.usuarioCreador.correo;
            me.enviarEmailReporteFallas();
            me.dialogReporteDeFallas = false;
            me.listar();
            me.addSuccessNotification(
              "Reporte de falla ingresado correctamente"
            );
          })
          .catch(function (error) {
            console.log(error);
            me.addErrorNotification();
          });
      }
    },

    mostrarDialogInfoReporte(item) {
      this.dialog_info_reporte = true;
      console.log(item);
      this.info_reporte = item;
    },

    cancelarItem(reporte) {
      this.folioReporte = reporte;
      this.dialogCancelar = true;
    },

    cancelar() {
      let me = this;

      if (this.motivo_cancelacion == "") {
        this.addErrorNotification();
      } else {
        axios
          .put("/reporteFallas/cancelar", {
            _id: this.folioReporte._id,
            motivoCancelacion: this.motivo_cancelacion,
          })
          .then(function () {
            me.dialogCancelar = false;
            //  me.dialog=false

            me.listar();
            me.addSuccessNotification(
              "Reporte de falla cancelado correctamente"
            );
            me.editedIndex = false;
          })
          .catch(function (error) {
            console.log(error);
            me.addErrorNotification();
          });
      }
    },

    modificarEstatusSinAsignar(id) {
      let me = this;
      axios
        .put("/reporteFallas/updateEstatusSinAsignar", {
          _id: id,
        })
        .then(function () {
          me.listar();
          me.dialogReporteDeFallas = false;
          me.addSuccessNotification("Reporte de falla editado correctamente");
        })
        .catch(function (error) {
          console.log(error);
          me.addErrorNotification();
        });
    },

    selectMecanicos() {
      let me = this;
      let mecaniccArray = [];

      axios
        .get("/mecanico/listActivos")
        .then((response) => {
          mecaniccArray = response.data;
          mecaniccArray.map(function (x) {
            me.mecanicosActivos.push({
              text: `${x.categoria} ${x.nombreMecanico} `,
              value: x._id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async eliminarReporte(_id) {
      await MttoApi.eliminarReporteFallas(_id)
        .then(() => {
          this.addSuccessNotification(
            "Reporte de fallas eliminado correctamente"
          );
          this.listar();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async actualizarEstatus(item, estatus) {
      this.correos =
        "cgonzalez@cicsagruas.net, jhernandez@cicsagruas.net, aroman@cicsagruas.net, cvalencia@cicsagruas.net";
      this.reportesItemSelected = item;
      //this.enviarEmailReporteFallas();
      await MttoApi.updateEstatusReporteFallasRevision(item._id, estatus)
        .then(() => {
          this.addSuccessNotification("Estatus actualziado");
          this.listar();
        })
        .catch((e) => {
          this.addErrorNotification();
          console.log(e);
        });
    },

    formatoFecha(fecha) {
      const year = fecha.getFullYear();
      const month = String(fecha.getMonth() + 1).padStart(2, "0");
      const day = String(fecha.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },


    async generarExcelReporte(item) {
      this.overlay = true
      const estatus = item.activo.estatu
      let operativo = ''
      let fuera_servicio = ''
      const fechaCompleta = item.createdAt;
      const fechaSolo = fechaCompleta.slice(0, 10);

      let fechaSoloAsignacion = "";
      if (item.fechaAsignacion) {
        const fechaCompletaApertura = item.fechaAsignacion;
        fechaSoloAsignacion = fechaCompletaApertura.slice(0, 10);
      }

      if (estatus == 'Operativo') {
        operativo = 'X'
        fuera_servicio = ''
      } else if (estatus == 'Fuera de Servicio') {
        operativo = ''
        fuera_servicio = 'X'
      }

      let mecanico = "";
      let hidraulico = "";
      let electrico = "";
      let neumatico = "";
      let otro = "";

      if (item.tipoFalla == "Électrica") {
        electrico = "X";
      } else if (item.tipoFalla == "Mecánica") {
        mecanico = "X";
      } else if (item.tipoFalla == "Neumática") {
        neumatico = "X";
      } else if (item.tipoFalla == "Hidráulica") {
        hidraulico = "X";
      } else {
        otro = item.tipoFalla;
      }

      try {
        const datos = {
          fecha: fechaSolo,
          folio: item.folio_falla,
          numero_economico: item.activo.numeroEconomico,
          modelo: item.activo.modelo,
          tipoUnidad: item.activo.tipoUnidad,
          solicita: item.usuarioCreador.nombreCompleto,
          numero_serie: item.activo.numeroSerie,
          operador: item.operador,
          ubicacion: 'TMDB',
          descripcion_falla: item.descripcionFalla,
          fecha_diagnostico: fechaSoloAsignacion,
          electrico,
          mecanico,
          neumatico,
          hidraulico,
          otro,
          operativo,
          fuera_servicio,
          diagnostico: item.diagnostico
        };


        await MttoApi.descargarRfExcel(datos).then((response) => {
          const base64Data = response.data;
          const binaryData = atob(base64Data);
          const blob = new Blob([
            new Uint8Array(binaryData.length).map((_, i) =>
              binaryData.charCodeAt(i)
            ),
          ]);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "RF-" + item.folio_falla + ".xlsx"; // Nombre del archivo
          document.body.appendChild(a);

          a.click();

          window.URL.revokeObjectURL(url);
        }).finally(() => {
          this.overlay = false
        });


      } catch (error) {
        console.error(error);
      }
    },

    filtroLabelPush(tipo) {
      if (tipo == 'folio') {
        let existe = this.arr.findIndex((e) => e.field == "folio");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "folio_falla",
          value: this.folio_filter,
          label: "Folio : " + this.folio_filter,
          collection: "rf"
        });
      } else if (tipo == 'numero_economico') {
        let existe = this.arr.findIndex((e) => e.field == "numero_economico");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "_id",
          value: this.numero_economico_filter.value,
          label: "N.E. : " + this.numero_economico_filter.text,
          collection: "activo"
        });
      } else if (tipo == 'estatus') {
        let existe = this.arr.findIndex((e) => e.field == "estatus");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "estatus",
          value: this.estatus_filter,
          label: "Estatus : " + this.estatus_filter,
          collection: "rf"
        });
      } else if (tipo == 'tipoFalla') {
        let existe = this.arr.findIndex((e) => e.field == "tipoFalla");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "tipoFalla",
          value: this.tipo_falla_filter,
          label: "Tipo de falla : " + this.tipo_falla_filter,
          collection: "rf"
        });
      } else if (tipo == 'fecha') {
        const fecha = new Date(this.fecha_filter)
        let existe = this.arr.findIndex((e) => e.field == "fecha");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "fechaFalla",
          value: fecha,
          label: "Fecha : " + this.fecha_filter,
          collection: "rf"
        });
      } else if (tipo == 'tipoUnidad') {
        let existe = this.arr.findIndex((e) => e.field == "tipoUnidad");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "tipoUnidad",
          value: this.tipo_unidad_filter,
          label: "Tipo de unidad : " + this.tipo_unidad_filter,
          collection: "activo"
        });
      } else if (tipo == 'operador') {
        let existe = this.arr.findIndex((e) => e.field == "operador");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "operador",
          value: {
            $regex: this.operador_filter,
            $options: 'i'
          },
          label: "Operador : " + this.operador_filter,
          collection: "rf"
        });
      }

      this.showFilterFolio = false
      this.showFilterNumeroEconomico = false
      this.showFilterFolio = false
      this.showFilterEstatus = false
      this.showFilterTipoFalla = false
      this.showFilterFecha = false
      this.showFilterOperador = false
      this.showFilterTipoUnidad = false
      this.listarTodosReportes()
    },

    eliminarFiltro(index) {
      this.arr.splice(index, 1)
      this.listarTodosReportes()
    },

    seguiminetoReporteFalla(item) {
      this.reporte_falla_seguimiento = item
      if (item.folio_falla) {
        this.overlay = true
        MttoApi.obtenerOtPorRF(item.folio_falla).then((e) => {
          this.reporte_falla_seguimiento.ot = e.data
          this.dialogSeguimientoReporteFalla = true

        }).finally(() => {
          this.overlay = false
        })
      } else {
        this.dialogSeguimientoReporteFalla = true

      }
    },

    mostrarDialogCambiarEstatus(item) {
      if (item.activo.estatu == 'Operativo') {
        this.estatus_cambio_estatus = false
      } else if (item.activo.estatu == 'Fuera de Servicio') {
        this.estatus_cambio_estatus = true
      }
      this.ne_cambio_estatus = item.activo.numeroEconomico
      this.equipo_cambio_estatus = item.activo._id
      this.reporte_cambio_estatus = item.folio_falla
      this.dialogEstatusUnidad = true
    },

    cambiarEstatus() {
      this.overlay = true
      if (!this.fecha_cambio_estatus) {
        this.addErrorNotification()
        this.overlay = false
        return
      }
      let estado = ''
      if (this.estatus_cambio_estatus) {
        estado = "Operativo";
      } else {
        estado = "Fuera de Servicio";
      }
      const data = {
        fecha: this.fecha_cambio_estatus,
        estado: estado,
        equipo: this.equipo_cambio_estatus,
        documento: this.reporte_cambio_estatus,
        tipo_doc: 'RF'
      }
      MttoApi.cambiarEstatusEquipo(data).then(() => {
        this.listar()
        this.dialogEstatusUnidad = false
        this.addSuccessNotification('Datos actualzados correctamente')
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.overlay = false
      })
    },


    //FIRMAR REPORTES
    mostrarDialogFirmaLog(item) {
      this.info_reporte = item
      this.dialogFirmaLog = true
    },

    firmarLogistica() {
      if (this.info_reporte.usuarioCreador.nombreCompleto !== this.$store.state.usuario.personal.nombreCompleto) {
        this.addWarningNotification("No está permitido firmar solicitudes hechas por otro usuario. Asegúrate de firmar solo tus propias solicitudes.")
        return
      }

      MttoApi.firmarSolicitudLog(this.info_reporte._id).then((e) => {
        if (e.data.message == 'Sin firma') {
          this.addWarningNotification("No se encuentra su firma en  el sistema, favor de validar con el depto de desarrollo")
        } else {
          this.listar()

          this.addSuccessNotification("Solicitud firmada correctamente")
          this.dialogFirmaLog = false
        }
      })
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addWarningNotification(msg) {
      this.$toast.warning(msg, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

.texto {
  font-family: 'Raleway', sans-serif;
}

.v-data-table>>>.v-data-table-header {
  background-color: rgb(23, 23, 87) !important;
}

.input-filter {
  background-color: rgb(241, 241, 241);
  border-radius: 20px;
  padding: -5px;
  width: 100%;
  height: 40px;
}

.input-filter_seguimiento {
  background-color: rgb(226, 226, 226);
  border-radius: 20px;
  padding: -45px;
  width: 100%;
  height: 25px;
}

.input-filter_title {
  background-color: rgb(29, 29, 29);
  border-radius: 20px;
  padding: -45px;
  color: white;
  width: 100%;
  text-align: center;
  height: 25px;
}

.button-fab {
  bottom: 50px;
  height: 60px;
  position: fixed;
  right: 20px;
  width: 60px;
}

.filter-label {
  color: #b1adad;
  padding: -20px;
  margin-top: -40px;
  margin-left: 40px;
  font-size: 13px;
  font-weight: normal;
}

.filter-chips {
  color: #b1adad;
  padding: -10px;
  margin-top: -41px;
  margin-left: 40px;
}


.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.Revision {
  border-left: 4px solid #3cd1c2;
}

.sin_asignar {
  border-left: 4px solid orange;
}

.Asignado {
  border-left: 4px solid rgb(24, 230, 110);
}

.Pendiente {
  border-left: 4px solid black;
}

.Cancelado {
  border-left: 4px solid #f83e70;
}

.app_more {
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
}

.app_more:hover {
  text-decoration: underline;
}

.app_more,
.app_more:active {
  color: #3cd1c2;
}

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid rgb(123, 90, 243);
  border-top-color: #fff;
  position: absolute;
  bottom: 0;
  left: calc(50%-20px);
  animation: spinner-animation 1.2s linear infinite;
}

.reporte.iniciado {
  border-left: 4px solid #3cd1c2;
}

.reporte.Pendiente {
  border-left: 4px solid orange;
}

.reporte.Cancelado {
  border-left: 4px solid #f83e70;
}

.reporte.finalizado {
  border-left: 4px solid #000000;
}

.v-chip.Activo {
  background: #f83e70;
  color: black;
}

#chips-container .v-chip.activo {
  color: white;
}

#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

.custom-chip {
  border: 1px solid green;
  border-radius: 20px;
  padding: 5px 10px;
  font-weight: bold;
}
</style>
