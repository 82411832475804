const calculoTotalFechas = (dias, periodo, partida, equipo) => {
  let fechaInicial = new Date(periodo[0]).setDate(
    new Date(periodo[0]).getDate() + 1
  );
  let fechaFinal = new Date(periodo[1]).setDate(
    new Date(periodo[1]).getDate() + 1
  );
  let diff = new Date(fechaFinal).getTime() - new Date(fechaInicial).getTime();
  let periodoDias = diff / (1000 * 60 * 60 * 24) + 1;
  let total = 0;

  if (
    dias == "LUN-DOM" &&
    partida !== 11 &&
    partida !== 13 &&
    partida !== 15 &&
    partida !== 12 &&
    partida !== 14 &&
    partida !== 16
  ) {
    total = periodoDias * 1;
  } else if (dias == "LUN-DOM" && equipo.length < 30) {
    total = periodoDias * 1;
  } else if (dias == "LUN-SAB" && partida != 11) {
    for (let i = periodo[0].split("-")[2]; i <= periodo[1].split("-")[2]; i++) {
      let fecha = new Date(fechaInicial).setDate(i);
      if (new Date(fecha).getDay() !== 0) {
        total += 1;
      }
    }
  } else if (dias == "LUN-VIER" && partida != 11) {
    for (let i = periodo[0].split("-")[2]; i <= periodo[1].split("-")[2]; i++) {
      let fecha = new Date(fechaInicial).setDate(i);
      if (new Date(fecha).getDay() !== 0 && new Date(fecha).getDay() !== 6) {
        total += 1;
      }
    }
  } else if (dias == "LUN-DOM" && partida == 11) {
    for (let i = periodo[0].split("-")[2]; i <= periodo[1].split("-")[2]; i++) {
      let fecha = new Date(fechaInicial).setDate(i);

      if (new Date(fecha).getDay() == 2 || new Date(fecha).getDay() == 3) {
        total += 1;
      }
    }
    total = periodoDias * 3;
  } else if (dias == "LUN-DOM" && partida == 13 && equipo.length > 30) {
    for (let i = periodo[0].split("-")[2]; i <= periodo[1].split("-")[2]; i++) {
      let fecha = new Date(fechaInicial).setDate(i);

      if (
        new Date(fecha).getDay() == 0 ||
        new Date(fecha).getDay() == 1 ||
        new Date(fecha).getDay() == 4
      ) {
        total += 1;
      }
      
    }
    total = periodoDias * 3;
  } else if (dias == "LUN-DOM" && partida == 15 && equipo.length > 30) {
    for (let i = periodo[0].split("-")[2]; i <= periodo[1].split("-")[2]; i++) {
      let fecha = new Date(fechaInicial).setDate(i);

      if (new Date(fecha).getDay() == 5 || new Date(fecha).getDay() == 6) {
        total += 1;
      }
    }
    total = periodoDias * 4;
  } else if (dias == "LUN-DOM" && partida == 12 && equipo.length > 30) {
    for (let i = periodo[0].split("-")[2]; i <= periodo[1].split("-")[2]; i++) {
      let fecha = new Date(fechaInicial).setDate(i);

      if (new Date(fecha).getDay() == 2 || new Date(fecha).getDay() == 3) {
        total += 1;
      }
    }
    total = periodoDias * 15 
  } else if (dias == "LUN-DOM" && partida == 14 && equipo.length > 30) {
    for (let i = periodo[0].split("-")[2]; i <= periodo[1].split("-")[2]; i++) {
      let fecha = new Date(fechaInicial).setDate(i);

      if (
        new Date(fecha).getDay() == 0 ||
        new Date(fecha).getDay() == 1 ||
        new Date(fecha).getDay() == 4
      ) {
        total += 1;
      }
    }
    total = periodoDias * 15 
  } else if (dias == "LUN-DOM" && partida == 16 && equipo.length > 30) {
    for (let i = periodo[0].split("-")[2]; i <= periodo[1].split("-")[2]; i++) {
      let fecha = new Date(fechaInicial).setDate(i);

      if (new Date(fecha).getDay() == 5 || new Date(fecha).getDay() == 6) {
        total += 1;
      }
    }
    total = periodoDias * 20;
  }
  return total;
};

const calculoEquiposHorarios = (equipos) => {
  let equipoHorario = [];

  equipos.forEach((e) => {
    let horario = e.substring(e.length - 15);
    let equipo = e.replace(horario, "");
    equipoHorario.push({
      equipo,
      horario,
    });
  });
  let equiposTotal = []
  equipoHorario.forEach((reg)=>{
        if (!Object.prototype.hasOwnProperty.call(equiposTotal, reg.horario)) {
            equiposTotal[reg.horario] = []
        }
        equiposTotal[reg.horario].push({
          equipo: reg.equipo,
          horario: reg.horario,
        });
  })
  let arregloConvertido = Object.entries(equiposTotal);
  if(arregloConvertido.length==1){
    let equipo = []
    arregloConvertido[0][1].forEach((e)=>{
        equipo.push(e.equipo)
    })
    let nombre = equipo.toString() + ' '+arregloConvertido[0][0]
    return nombre
  }else if(arregloConvertido.length>1){
    let equipoTotal = []
    arregloConvertido.forEach((e)=>{
        let equipo = []
        e[1].forEach((f)=>{
            equipo.push(f.equipo)
        })
        let nombreEquipo = equipo.toString() + ' '+e[0]
        equipoTotal.push(nombreEquipo)
    })
   return equipoTotal

  }

};


export default {
  calculoTotalFechas,
  calculoEquiposHorarios,
};
