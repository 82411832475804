<template>
  <v-container fluid class="pa-2 ma-2">
    <div class="dashboard-page">
      <br />
      <v-row no-gutters class="d-flex justify-space-between mt-16 mb-6">
        <h1 class="page-title">Dashboard</h1>
      </v-row>
      <v-overlay style="z-index:9999" :value="mostrarTabla">
        <v-progress-circular persistent indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-row>
        <v-col lg="4" sm="12" md="4" cols="12">
          <v-card class="mx-1 mb-1" style="border-radius: 15px;">
            <v-card-text class="pa-2 pt-0">
              <v-card-title class="pa-6 pb-3 texto">
                Activos por Estatus
              </v-card-title>
              <div v-if="dataLoaded">
                <Echarts chartTitle="Activos por estatus" :data="grafica_activos_estatus"
                  v-on:chart-click="handleChartClick" height="260px" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" sm="12" md="4" cols="12">
          <v-card class="mx-1 mb-1" style="border-radius: 15px;">
            <v-card-text class="pa-2 pt-0">
              <v-card-title class="pa-6 pb-3 texto">
                Activos por Empresa
              </v-card-title>
              <div v-if="dataLoaded">
                <Echarts chartTitle="Activos por empresa" :data="grafica_activos_empresa" height="260px"
                  v-on:chart-click="handleChartClick" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" sm="12" md="4" cols="12">
          <v-card class="mx-1 mb-1" style="border-radius: 15px;">
            <v-card-text class="pa-2 pt-0">
              <v-card-title class="pa-6 pb-3 texto">
                Activos F/S
              </v-card-title>
              <div v-if="dataLoaded">
                <Echarts chartTitle="Activos FS" :data="grafica_activos_estatus_fs" v-on:chart-click="handleChartClick"
                  height="260px" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>



      <v-row>
        <v-col lg="6" sm="12" md="6" cols="12">
          <v-card class="mx-1 mb-1" style="border-radius: 15px;">
            <v-card-text class="pa-1">
              <v-card-title class="pa-3 pb-3 texto">
                Tipo de Unidad
              </v-card-title>
              <v-row>
                <v-col>
                  <div v-if="dataLoaded">
                    <Echarts chartTitle="Tipo de unidad" :data="grafica_activos_tipoUnidad" height="560px"
                      v-on:chart-click="handleChartClick" />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="6" sm="12" md="6" cols="12">
          <v-card class="mx-1 mb-1" style="border-radius: 15px;">
            <v-card-text class="pa-1">
              <v-card-title class="pa-3 pb-3 texto">
                Desglose de Unidades <v-btn @click="reloadData" icon color="green">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </v-card-title>
              <v-row>
                <v-col class="pa-4">
                  <v-data-table :loading="mostrarTabla" style="border-radius: 10px;" dense :headers="headersDesgloce"
                    :items="activosDesgloce" :items-per-page="12" item-key="nombreComercial" hide-default-footer class="">
                    <template v-slot:[`header.nombreActivo`]="{ header }">
                      <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                        header.text.toUpperCase() }}
                      </span>
                    </template>
                    <template v-slot:[`header.estatu`]="{ header }">
                      <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                        header.text.toUpperCase() }}
                      </span>
                    </template>
                    <template v-slot:[`header.marca`]="{ header }">
                      <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                        header.text.toUpperCase() }}
                      </span>
                    </template>
                    <template v-slot:[`header.numeroSerie`]="{ header }">
                      <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                        header.text.toUpperCase() }}
                      </span>
                    </template>
                    <template v-slot:[`header.modelo`]="{ header }">
                      <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                        header.text.toUpperCase() }}
                      </span>
                    </template>
                    <template v-slot:[`header.fotosAdjuntos`]="{ header }">
                      <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                        header.text.toUpperCase() }}
                      </span>
                    </template>
                    <template v-slot:[`header.opciones`]="{ header }">
                      <span class="texto" style="text-align:center;font-size:14px; color:rgb(231, 231, 231)">{{
                        header.text.toUpperCase() }} </span>
                    </template>

                    <template v-slot:[`item.nombreActivo`]="{ item }">

                      <div>

                        <v-avatar v-if="item.avatar" color="grey" size="40" tile class="ma-1">
                          <img :src="item.avatar">
                        </v-avatar>
                        <span class="texto"
                          style="color: #202020; font-size: 10px; font-weight: bold; text-transform: uppercase;">{{
                            `${item.tipoUnidad}
                                                    ${item.numeroEconomico}` }}</span>
                      </div>
                    </template>

                    <template v-slot:[`item.marca`]="{ item }">
                      <div>
                        <span class="texto"
                          style="color: #202020; font-size: 10px; font-weight: bold; text-transform: uppercase;">{{
                            item.marca }}</span>
                      </div>
                    </template>
                    <template v-slot:[`item.modelo`]="{ item }">
                      <div>
                        <span class="texto"
                          style="color: #202020; font-size: 10px; font-weight: bold; text-transform: uppercase;">{{
                            item.modelo }}</span>
                      </div>
                    </template>

                    <template v-slot:[`item.estatu`]="{ item }">
                      <v-chip label outlined class="texto" small v-if="item.estatu == 'N/D'" color="gray">
                        N/D
                      </v-chip>
                      <v-chip label outlined class="texto" small v-if="item.estatu == 'Operativo'" color="green">
                        Operativo
                      </v-chip>
                      <v-chip label outlined class="texto" small v-if="item.estatu == 'Fuera de Servicio'" color="orange">
                        Fuera de Servicio
                      </v-chip>
                      <v-chip class="texto" label outlined small v-if="item.estatu == 'No Localizado'" color="black">
                        No Localizado
                      </v-chip>
                      <v-badge v-if="item.estatu == 'Baja'" :value="hover" color="deep-purple accent-6"
                        v-bind:content="item.observaciones" left transition="slide-x-transition">
                        <v-hover v-model="hover">
                          <v-chip small v-if="item.estatu == 'Baja'" color="red" text-color="white">
                            Baja
                          </v-chip>
                        </v-hover>
                      </v-badge>
                    </template>
                  </v-data-table> <br>
                  <div class="text-center">
                    <v-pagination v-model="page" :length="totalPages" :total-visible="7"
                      @input="listarEquipos()"></v-pagination>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


      <v-col cols="12">
        <v-card class="mx-1 mb-1" style="border-radius: 15px;">
          <v-card-title class="pa-6 pb-0">
            <v-row no-gutters>
              <v-col cols="7" sm="4" md="4" lg="5" class="d-flex align-center">
                <p>Relación de estatus de las unidades</p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-6">
            <v-row>
              <v-col>
                <v-select v-on:change="listar()" v-model="tipoUnidad" :items="subfamilia"
                  label="Tipo de Unidad"></v-select>
              </v-col>
              <v-col col="4">
                <v-select v-on:change="listar()" v-model="estado" :items="status" label="Estatus"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table dense :headers="headers" :items="activos">
                  <template v-slot:[`header.nombreActivo`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                      header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.estatu`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                      header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.marca`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                      header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.numeroSerie`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                      header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.modelo`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                      header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.ubicacion.empresa.nombreComercial`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                      header.text.toUpperCase() }}
                    </span>

                  </template>
                  <template v-slot:[`header.personal.nombreCompleto`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                      header.text.toUpperCase() }}
                    </span>

                  </template>
                  <template v-slot:[`header.personal.departamento.departamento`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                      header.text.toUpperCase() }}
                    </span>

                  </template>
                  <template v-slot:[`header.costos`]="{ header }">
                    <span class="texto" style="font-size:14px; color:rgb(231, 231, 231)">{{
                      header.text.toUpperCase() }}
                    </span>

                  </template>
                  <template v-slot:[`item.estatu`]="{ item }">
                    <v-chip small v-if="item.estatu == 'N/D'" color="gray" text-color="black">
                      N/D
                    </v-chip>
                    <v-chip small v-if="item.estatu == 'Operativo'" color="green" text-color="white">
                      Operativo
                    </v-chip>
                    <v-chip small v-if="item.estatu == 'Fuera de Servicio'" color="orange" text-color="white">
                      Fuera de Servicio
                    </v-chip>
                    <v-chip small v-if="item.estatu == 'No Localizado'" color="red" text-color="white">
                      No Localizado
                    </v-chip>
                  </template>
                  <template v-slot:[`item.costos`]="{ item }">
                    ${{ item.costo.toLocaleString("en-US") }}
                  </template>
                  <template slot="body.append">
                    <tr class="pink--text">
                      <th class="title">Total</th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title">
                        ${{ sumField("costo").toLocaleString("en-US") }}
                      </th>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </div>
  </v-container>
</template>
  
<script>
import moment from "moment";
import Echarts from "../Charts/Echarts.vue";
import axios from "axios"
import apiCatalogo from "../../api/Catalogo"
export default {
  name: "Dashboard",
  components: {
    Echarts
  },
  data() {
    return {

      grafica_activos_estatus: [],
      grafica_activos_empresa: [],
      grafica_activos_estatus_fs: [],
      grafica_activos_tipoUnidad: [],
      dataLoaded: false,
      value: this.getRandomInt(10, 90),
      value2: this.getRandomInt(10, 90),
      mainApexAreaSelect: "Daily",
      totalActivos: "",
      moment,
      activos: [],

      tipoUnidad: "",
      estado: "",
      arr: [],
      totalActivosFs: "",
      totalActivosPorTipo: [],
      tiposDeUnidad: [
        "Autos y Pick Ups",
        "Motocicletas",
        "Tractocamion",
        "Plataforma",
        "Grúa Terrestre",
        "Montacargas",
        "Cargador Frontal",
      ],

      totalActivosEmpresa: [],
      status: ["Fuera de Servicio", "N/D", "No Localizado", "Operativo"],
      subfamilia: [
        "AUTOBUS",
        "AUTOMOVIL",
        "CAMIÓN CON GRÚA ARTICULADA",
        "CAMIÓN CON HIAB - PIPA",
        "CAMIÓN TIPO VOLTEO",
        "CAMIONETA",
        "CARGADOR FRONTAL",
        "GRÚA TERRESTRE",
        "MONTACARGAS",
        "MOTOCICLETA",
        "PIPA",
        "PLATAFORMA",
        "JEEP DOLLY TANDEM",
        "REMOLQUE LOWBOY",
        "SEMIREMOLQUE TIPO VOLTEO",
        "SEMIREMOLQUE TIPO DOLLY",
        "SEMIREMOLQUE TIPO CAMA BAJA",
        "TIPO VAN",
        "TRACTOCAMIÓN",
      ],
      totalEmpresa: [],
      headers: [
        { text: "Marca", value: "marca", sortable: true },
        { text: "Modelo", value: "modelo", sortable: true },
        { text: "Numero de serie", value: "numeroSerie", sortable: true },
        {
          text: "Empresa",
          value: "ubicacion.empresa.nombreComercial",
          sortable: true,
        },
        { text: "Resguardo", value: "personal.nombreCompleto", sortable: true },
        {
          text: "Departamento",
          value: "personal.departamento.departamento",
          sortable: true,
        },
        { text: "Costo", value: "costos", sortable: true },
        { text: "Estatus", value: "estatu", sortable: true },

      ],

      headersDesgloce: [
        { text: "Activo", value: "nombreActivo", sortable: false },
        { text: "Marca", value: "marca", sortable: false },
        { text: "Modelo", value: "modelo", sortable: false },
        { text: "Estatus", value: "estatu", sortable: false },

      ],

      totalActivosPorTipoUnidad: [],
      activosDesgloce: [],
      page: 1,
      totalPages: 0,
      mostrarTabla: false,


    };
  },

  mounted() {
    apiCatalogo.activosPorEstatusGrafica().then((response) => {
      this.grafica_activos_estatus = response.data[0];
      this.grafica_activos_empresa = response.data[1];
      this.grafica_activos_estatus_fs = response.data[2];
      this.grafica_activos_tipoUnidad = response.data[3]
      this.dataLoaded = true;
    }).catch((error) => {
      console.error('Error al cargar los datos', error);
    });
  },

  created() {
    this.listarEquipos()

    this.listar();

  },
  methods: {
    sumField(key) {
      // sum data in give key (property)
      return this.activos.reduce((a, b) => a + (b[key] || 0), 0);
    },

    getRandomInt(min, max) {
      let rand = min - 0.5 + Math.random() * (max - min + 1);
      return Math.round(rand);
    },

    handleChartClick(data) {
      console.log(data)
      this.arr = []
      if (data.chartTitle == 'Activos por estatus') {
        this.arr.push({
          field: "estatu",
          value: data.selectedValue,
        });
      } else if (data.chartTitle == 'Tipo de unidad') {
        this.arr.push({
          field: "tipoUnidad",
          value: data.selectedValue,
        });
      } else if (data.chartTitle == 'Activos por empresa') {
        if (data.selectedValue == 'CICSA') {
          this.arr.push({
            field: "empresa",
            value: "6207fa7a7422680c1e59051f"
          });
        } else if (data.selectedValue == 'AMLS') {
          this.arr.push({
            field: "empresa",
            value: "6207f8cb7422680c1e59051c"
          });
        } else if (data.selectedValue == 'GMG') {
          this.arr.push({
            field: "empresa",
            value: "6207fd387422680c1e590532"
          });
        } else if (data.selectedValue == 'ARRENMAK') {
          this.arr.push({
            field: "empresa",
            value: "6207fbba7422680c1e590527"
          });
        }
      }
      else if (data.chartTitle == 'Activos FS') {
        if (data.selectedValue == 'CICSA') {
          this.arr.push({
            field: "empresa",
            value: "6207fa7a7422680c1e59051f"
          });
          this.arr.push({
            field: "estatu",
            value: "Fuera de Servicio"
          })
        } else if (data.selectedValue == 'AMLS') {
          this.arr.push({
            field: "empresa",
            value: "6207f8cb7422680c1e59051c"
          });
          this.arr.push({
            field: "estatu",
            value: "Fuera de Servicio"
          })
        } else if (data.selectedValue == 'GMG') {
          this.arr.push({
            field: "empresa",
            value: "6207fd387422680c1e590532"
          });
          this.arr.push({
            field: "estatu",
            value: "Fuera de Servicio"
          })
        } else if (data.selectedValue == 'ARRENMAK') {
          this.arr.push({
            field: "empresa",
            value: "6207fbba7422680c1e590527"
          });
          this.arr.push({
            field: "estatu",
            value: "Fuera de Servicio"
          })
        }
      }
      this.listarEquipos()
    },
    fecha(f1, f2) {
      const diff = new Date(f2).getTime() - new Date(f1).getTime();
      return diff / (1000 * 60 * 60 * 24);
    },

    listar() {
      let me = this;
      this.mostrarTabla = true
      axios
        .get(
          "/activos/queryEstadoUnidad?nombreActivo=" +
          this.tipoUnidad +
          "&estatu=" +
          this.estado
        )
        .then(function (response) {
          me.activos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        }).finally(() => {
          this.mostrarTabla = false
        });
    },

    reloadData() {
      this.arr = []
      this.listarEquipos()
    },

    listarEquipos() {
      let me = this;
      this.mostrarTabla = true
      axios
        .post("/activos/list?page=" + this.page, this.arr)
        .then(function (response) {
          me.activosDesgloce = response.data.reg;
          me.totalPages = response.data.totalPaginas
          console.log(me.activosDesgloce)
        }).catch(function (error) {
          console.log(error);
        }).finally(() => {
          this.mostrarTabla = false
        });

    },

  },



};
</script>
  
  <style src="./Dashboard.scss" lang="scss" />
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

.v-data-table>>>.v-data-table-header {
  background-color: rgb(23, 23, 87) !important;
}

.texto {
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  font-weight: bold;
}
</style>
  