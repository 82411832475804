<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular
        persistent
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogVistaPrevia" width="800" color="primary">
      <v-card>
        <v-toolbar color="primary" dark>Vista Previa</v-toolbar>

        <v-card-text>
          <br />
          <h2>Orden de Salida de Equipos</h2>
          <table>
            <tr>
              <th>Fecha De Ingreso</th>
              <td id="fechaSalida">
                {{
                  moment(orden_salida.fecha_salida)
                    .add(1, "days")
                    .format("Do MMMM YYYY, H:MM")
                    .toUpperCase()
                }}
              </td>
            </tr>
            <tr>
              <th>Fecha de Salida</th>
              <td id="fechaSalida">
                {{
                  moment(orden_salida.ingreso)
                    .add(1, "days")
                    .format("Do MMMM YYYY, H:MM")
                    .toUpperCase()
                }}
              </td>
            </tr>
            <tr>
              <th># Economico</th>
              <td v-if="orden_salida.equipo" id="numEconomico">
                {{ orden_salida.equipo.numeroEconomico }}
              </td>
            </tr>
            <tr>
              <th>Tipo de Unidad</th>
              <td v-if="orden_salida.equipo" id="tipoUnidad">
                {{ orden_salida.equipo.tipoUnidad }}
              </td>
            </tr>
            <tr>
              <th># De Serie</th>
              <td v-if="orden_salida.equipo" id="tipoUnidad">
                {{ orden_salida.equipo.numeroSerie }}
              </td>
            </tr>
            <tr>
              <th># Placa</th>
              <td v-if="orden_salida.equipo" id="tipoUnidad">
                {{ orden_salida.equipo.placa }}
              </td>
            </tr>
            <tr>
              <th>Marca</th>
              <td v-if="orden_salida.equipo" id="tipoUnidad">
                {{ orden_salida.equipo.marca }}
              </td>
            </tr>
            <tr>
              <th>Contrato/Cliente</th>
              <td id="contratoCliente">{{ orden_salida.cliente_contrato }}</td>
            </tr>
            <tr>
              <th>Lugar De Destino</th>
              <td id="contratoCliente">{{ orden_salida.destino }}</td>
            </tr>
            <tr>
              <th>Periodo de Renta</th>
              <td id="contratoCliente">{{ orden_salida.periodo_renta }}</td>
            </tr>
            <tr>
              <th>Objeto del Servicio</th>
              <td id="contratoCliente">{{ orden_salida.objeto_servicio }}</td>
            </tr>
            <tr>
              <th>Nombre del Operador</th>
              <td id="contratoCliente">{{ orden_salida.operador }}</td>
            </tr>
          </table>
          <br />
          <div class="row">
            <div class="col-4" style="font-size: 12px">
              <div
                style="border: 1px solid #ccc; padding: 5px; border-radius: 3px"
              >
                <div
                  style="display: flex; align-items: center; margin-bottom: 5px"
                >
                  <label
                    for="autorizar1"
                    style="font-size: 12px; margin-bottom: 0; font-weight: bold"
                  >
                    Elaboró</label
                  >
                </div>
                <p
                  v-if="orden_salida.usuarioCreador"
                  style="font-style: italic; font-size: 10px; margin-bottom: 0"
                >
                  {{ orden_salida.usuarioCreador.nombreCompleto }}
                </p>
                <p
                  v-if="orden_salida.usuarioCreador"
                  style="font-style: italic; font-size: 10px; margin-bottom: 0"
                >
                  {{ orden_salida.usuarioCreador.correo }}
                </p>
              </div>
            </div>

            <div class="col-4" style="font-size: 12px">
              <div
                style="border: 1px solid #ccc; padding: 5px; border-radius: 3px"
              >
                <div
                  style="display: flex; align-items: center; margin-bottom: 5px"
                >
                  <v-checkbox
                    v-model="autorizado"
                    color="red"
                    @change="autorizarSalida(orden_salida._id)"
                    hide-details
                    style="margin-top: -8px"
                  ></v-checkbox>
                  <label
                    for="autorizar1"
                    style="
                      font-size: 12px;
                      margin-bottom: 5px;
                      font-weight: bold;
                    "
                  >
                    Autoriza salida</label
                  >
                </div>
                <p
                  v-if="orden_salida.usuarioAprobo"
                  style="font-style: italic; font-size: 10px; margin-bottom: 0"
                >
                  {{ orden_salida.usuarioAprobo.nombreCompleto }}
                </p>
                <p
                  v-if="orden_salida.usuarioAprobo"
                  style="font-style: italic; font-size: 10px; margin-bottom: 0"
                >
                  {{ orden_salida.usuarioAprobo.correo }}
                </p>
              </div>
            </div>
            <div class="col-4" style="font-size: 12px">
              <div
                style="border: 1px solid #ccc; padding: 5px; border-radius: 3px"
              >
                <div
                  style="display: flex; align-items: center; margin-bottom: 5px"
                >
                  <v-checkbox
                    v-model="autorizadoAf"
                    color="red"
                    v-if="this.$store.state.usuario.rol == 'activos' || 'root'"
                    @change="autorizarSalidaAF(orden_salida._id)"
                    hide-details
                    style="margin-top: -8px"
                  ></v-checkbox>
                  <label
                    for="autorizar1"
                    style="
                      font-size: 12px;
                      margin-bottom: 5px;
                      font-weight: bold;
                    "
                  >
                    Activos Fijos</label
                  >
                </div>
                <p
                  v-if="orden_salida.usuarioActivosFijos"
                  style="font-style: italic; font-size: 10px; margin-bottom: 0"
                >
                  {{ orden_salida.usuarioActivosFijos.nombreCompleto }}
                </p>
                <p
                  v-if="orden_salida.usuarioActivosFijos"
                  style="font-style: italic; font-size: 10px; margin-bottom: 0"
                >
                  {{ orden_salida.usuarioActivosFijos.correo }}
                </p>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogVistaPrevia = false">
            Salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" v-model="dialogOrdenSalida">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogOrdenSalida = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Salida de equipos</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-stepper v-model="e6" vertical>
          <v-stepper-step :complete="e6 > 1" step="1">
            <span style="font-weight: bold; font-size: 1.2em"
              >Ingrese los datos requeridos</span
            >
            <small style="font-style: italic; color: #999999"
              >Todos los datos son requeridos</small
            >
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card color="grey lighten-5" class="mb-12" height="400px">
              <v-row class="pa-6 ma-2">
                <v-col cols="4" sm="4">
                  <p
                    class="text"
                    style="
                      text-align: center;
                      font-family: Arial, sans-serif;
                      font-size: 18px;
                      color: #666666;
                      font-weight: bold;
                    "
                  >
                    Numero Económico del Equipo
                  </p>

                  <v-autocomplete
                    v-model="equipo"
                    :items="activosSelect"
                    menu-props="auto"
                    dense
                    small-chips
                    solo
                  >
                    <template v-slot:selection="data">
                      <v-chip :input-value="data.text">
                        <v-avatar left>
                          <v-icon
                            color="green"
                            v-if="data.item.estatus == 'Operativo'"
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon
                            color="orange"
                            v-if="data.item.estatus == 'Pendiente'"
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon
                            color="red"
                            v-if="data.item.estatus == 'Fuera de Servicio'"
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                        </v-avatar>
                        {{ data.item.text }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-avatar>
                          <v-icon
                            color="green"
                            v-if="data.item.estatus == 'Operativo'"
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon
                            color="orange"
                            v-if="data.item.estatus == 'Pendiente'"
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon
                            color="red"
                            v-if="data.item.estatus == 'Fuera de Servicio'"
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.text
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            data.item.estatus
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" sm="4">
                  <p
                    class="text"
                    style="
                      text-align: center;
                      font-family: Arial, sans-serif;
                      font-size: 18px;
                      color: #666666;
                      font-weight: bold;
                    "
                  >
                    Fecha y hora de salida
                  </p>
                  <v-text-field
                    format="YYYY-MM-DDTHH:mm:ss"
                    type="datetime-local"
                    dense
                    v-model="fecha_hora_salida"
                    solo
                  ></v-text-field>
                </v-col>

                <v-col cols="4" sm="4">
                  <p
                    class="text"
                    style="
                      text-align: center;
                      font-family: Arial, sans-serif;
                      font-size: 18px;
                      color: #666666;
                      font-weight: bold;
                    "
                  >
                    Contratante/Cliente
                  </p>
                  <v-text-field
                    dense
                    v-model="contrato_cliente"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="4" sm="4">
                  <p
                    class="text"
                    style="
                      text-align: center;
                      font-family: Arial, sans-serif;
                      font-size: 18px;
                      color: #666666;
                      font-weight: bold;
                    "
                  >
                    Lugar de destino
                  </p>
                  <v-text-field
                    dense
                    v-model="lugar_destino"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="4" sm="4">
                  <p
                    class="text"
                    style="
                      text-align: center;
                      font-family: Arial, sans-serif;
                      font-size: 18px;
                      color: #666666;
                      font-weight: bold;
                    "
                  >
                    Objeto del servicio
                  </p>
                  <v-text-field
                    dense
                    v-model="objeto_servicio"
                    solo
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <p
                    class="text"
                    style="
                      text-align: center;
                      font-family: Arial, sans-serif;
                      font-size: 18px;
                      color: #666666;
                      font-weight: bold;
                    "
                  >
                    Nombre del Operador
                  </p>
                  <v-text-field dense v-model="operador" solo></v-text-field>
                </v-col>
                <v-col cols="4">
                  <p
                    class="text"
                    style="
                      text-align: center;
                      font-family: Arial, sans-serif;
                      font-size: 18px;
                      color: #666666;
                      font-weight: bold;
                    "
                  >
                    Periodo de Renta
                  </p>
                  <v-text-field
                    dense
                    v-model="periodo_renta"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <p
                    class="text"
                    style="
                      text-align: center;
                      font-family: Arial, sans-serif;
                      font-size: 18px;
                      color: #666666;
                      font-weight: bold;
                    "
                  >
                    Fecha y hora de ingreso
                  </p>
                  <v-text-field
                    format="YYYY-MM-DDTHH:mm:ss"
                    type="datetime-local"
                    dense
                    v-model="fecha_hora_ingreso"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
            <v-btn color="primary" @click="save_and_update()"> {{editar? 'Editar' : 'Guardar'}} </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-dialog>
    <div class="tables-basic pa-3">
      <br />
      <h1 class="page-title mt-16 mb-6">Orden de Salida</h1>
      <v-data-table
        dense
        :headers="headers"
        :items="ordenes"
        :items-per-page="30"
        style="background-color: #fff"
      >
        <template v-slot:[`item.equipo`]="{ item }">
          <span style="color: gray; font-size: 12px">
            {{ item.equipo.numeroEconomico }}</span
          >
        </template>
        <template v-slot:[`item.fecha_salida`]="{ item }">
          <span style="color: gray; font-size: 12px">{{
            moment(item.fecha_salida).add(1, "days").format("Do MMMM YYYY, h:mm a").toUpperCase()
          }}</span>
        </template>
        <template v-slot:[`item.fecha_ingreso`]="{ item }">
          <span style="color: gray; font-size: 12px">{{
            moment(item.ingreso).add(1, "days").format("Do MMMM YYYY, h:mm a").toUpperCase()
          }}</span>
        </template>
        <template v-slot:[`item.cliente_contrato`]="{ item }">
          <span style="color: gray; font-size: 12px">{{
            item.cliente_contrato
          }}</span>
        </template>
        <template v-slot:[`item.destino`]="{ item }">
          <span style="color: gray; font-size: 12px">{{ item.destino }}</span>
        </template>
        <template v-slot:[`item.objeto_servicio`]="{ item }">
          <span style="color: gray; font-size: 12px">{{
            item.objeto_servicio
          }}</span>
        </template>
        <template v-slot:[`item.operador`]="{ item }">
          <span style="color: gray; font-size: 12px">{{ item.operador }}</span>
        </template>
        <template v-slot:[`item.estatus`]="{ item }">
          <v-chip
            class="ma-2"
            small
            color="#FFCC66"
            v-if="item.usuarioAprobo && !item.usuarioActivosFijos"
          >
            Aprobado
          </v-chip>
          <v-chip
            class="ma-2"
            small
            dark
            color="#336699   "
            v-if="!item.usuarioAprobo && !item.usuarioActivosFijos && item.estatus !== 'cancelado'"
          >
            Normal
          </v-chip>
          <v-chip
            class="ma-2"
            small
            dark
            color="#66CC66      "
            v-if="item.usuarioActivosFijos"
          >
            Aprobado Activos Fijos
          </v-chip>
          <v-chip
            class="ma-2"
            small
            dark
            color="red"
            v-if="item.estatus == 'cancelado'"
          >
            Cancelado
          </v-chip>
        </template>
        <template v-slot:[`item.opciones`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                offset-y
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link  @click="vistaPrevia(item)">
                <v-list-item-action >
                  <v-icon color="blue darken-2"> mdi-file-eye </v-icon>
                </v-list-item-action>
                <v-list-item-title
                  ><span style="color: gray; font-size: 13px"
                    >Vista Previa</span
                  ></v-list-item-title
                >
              </v-list-item>
              <v-list-item v-if="item.estatus!=='cancelado'" link  @click="descargar()">
                <v-list-item-action >
                  <v-icon color="blue darken-2"> mdi-download </v-icon>
                </v-list-item-action>
                <v-list-item-title
                  ><span style="color: gray; font-size: 13px"
                    >Descargar</span
                  ></v-list-item-title
                >
              </v-list-item>
              <v-list-item  v-if="item.estatus!=='cancelado'" link  @click="editItem(item)">
                <v-list-item-action >
                  <v-icon color="blue darken-2"> mdi-pencil </v-icon>
                </v-list-item-action>
                <v-list-item-title
                  ><span style="color: gray; font-size: 13px"
                    >Editar</span
                  ></v-list-item-title
                >
              </v-list-item>
            <v-list-item v-if="item.estatus!=='cancelado' && !item.usuarioAprobo && !item.usuarioActivosFijos" link  @click="cancelar(item._id)">
                <v-list-item-action >
                  <v-icon color="blue darken-2"> mdi-cancel </v-icon>
                </v-list-item-action>
                <v-list-item-title
                  ><span style="color: gray; font-size: 13px"
                    >Cancelar</span
                  ></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
    <v-btn
      class="button-fab-1 mx-2"
      @click="mostrarDialogNuevaOse()"
      dark
      fab
      color="primary"
      ><v-icon dark>mdi-plus</v-icon></v-btn
    >
  </v-container>
</template>

<script>
import MttoApi from "../../api/Mtto";
import ActivosApi from "../../api/AcitvosFijos";
import moment from "moment";
import { orden_salida_pdf } from "../../utils/reportes/OrdenSalida";

export default {
  data() {
    return {
      headers: [
        {
          text: "Equipo",
          value: "equipo",
        },
        { text: "Fecha de Salida", value: "fecha_salida" },
        { text: "Fecha de Ingreso", value: "fecha_ingreso" },
        { text: "Cliente / Contrato", value: "cliente_contrato" },
        { text: "Destino", value: "destino" },
        { text: "Objeto del Servicio", value: "objeto_servicio" },
        { text: "Operador", value: "operador" },
        { text: "Estatus", value: "estatus" },
        { text: "Opciones", value: "opciones" },
      ],
      moment: moment,
      loading: false,
      dialogOrdenSalida: false,
      e6: 1,
      numeroEconomicoBusqueda: "",
      activosSelect: [],
      equipo: "",
      fecha_hora_salida: "",
      contrato_cliente: "",
      dialogVistaPrevia: false,
      lugar_destino: "",
      objeto_servicio: "",
      operador: "",
      fecha_hora_ingreso: "",
      periodo_renta: "",
      ordenes: [],
      orden_salida: [],
      autorizado: false,
      autorizadoAf: false,
      editar:false,
      id:'',
    };
  },
  watch: {},
  computed: {},
  created() {
    this.listarActivos();
    this.listarOrdenesSalida();
  },
  methods: {
    mostrarDialogNuevaOse() {
      this.dialogOrdenSalida = true;
      this.editar = false
      this.limpiar()
    },
    listarActivos() {
      let me = this;
      MttoApi.obtenerActivosSelect().then((res) => {
        me.activosSelect = res.data;
      });
    },

    listarOrdenesSalida() {
      ActivosApi.obtenerOrdenesSalida().then((e) => {
        this.ordenes = e.data;
      });
    },

    save_and_update(){
      this.editar ? this.editarOsAf() : this.guardar()
    },

    guardar() {
      const datos = {
        equipo: this.equipo,
        fecha_salida: this.fecha_hora_salida,
        cliente_contrato: this.contrato_cliente,
        destino: this.lugar_destino,
        objeto_servicio: this.objeto_servicio,
        operador: this.operador,
        fecha_ingreso: this.fecha_hora_ingreso,
        periodo_renta: this.periodo_renta,
        usuarioCreador: this.$store.state.usuario.personal._id,
      };
      ActivosApi.ingresarOrdenSalida(datos)
        .then(() => {
          this.addSuccessNotification();
          this.dialogOrdenSalida = false;
          this.listarOrdenesSalida();
        })
        .catch(() => {
          this.addErrorNotification2("Ingrese los campos requeridos");
        });
    },

    editItem(item){
      this.editar = true
      this.dialogOrdenSalida = true
      this.equipo = item.equipo._id
      this.contrato_cliente = item.cliente_contrato
      this.lugar_destino = item.destino
      this.objeto_servicio = item.objeto_servicio
      this.operador = item.operador
      this.periodo_renta = item.periodo_renta
      this.fecha_hora_salida = item.fecha_salida
      this.fecha_hora_ingreso = item.fecha_ingreso
      this.id = item._id
    },

    editarOsAf(){
      const datos  = {
        equipo: this.equipo,
        fecha_salida: this.fecha_hora_salida,
        cliente_contrato: this.contrato_cliente,
        destino: this.lugar_destino,
        objeto_servicio: this.objeto_servicio,
        operador: this.operador,
        fecha_ingreso: this.fecha_hora_ingreso,
        periodo_renta: this.periodo_renta,
        _id:this.id
      }
      ActivosApi.editarOsAf(datos).then(()=>{
        this.addSuccessNotification();
          this.dialogOrdenSalida = false;
          this.listarOrdenesSalida();
          this.id = ''
      }).catch(()=>{
        this.addErrorNotification2("Ingrese los campos requeridos");
      })
    },

    vistaPrevia(item) {
      this.dialogVistaPrevia = true;
      this.orden_salida = item;
      this.orden_salida.usuarioAprobo
        ? (this.autorizado = true)
        : (this.autorizado = false);
      this.orden_salida.usuarioActivosFijos
        ? (this.autorizadoAf = true)
        : (this.autorizadoAf = false);
    },

    autorizarSalida(item) {
      const datos = {
        usuarioAprobo: this.$store.state.usuario.personal._id,
        _id: item,
        accion: this.autorizado,
      };
      ActivosApi.aprobarOs(datos).then((e) => {
        this.orden_salida = e.data;
        this.addSuccessNotification("Orden de salida actualizada");
        this.listarOrdenesSalida();
      });
    },

    autorizarSalidaAF(item) {
      const datos = {
        usuarioActivosFijos: this.$store.state.usuario.personal._id,
        _id: item,
        accion: this.autorizadoAf,
      };
      ActivosApi.aprobarOsAf(datos).then((e) => {
        this.orden_salida = e.data;
        this.addSuccessNotification("Orden de salida actualizada");
        this.listarOrdenesSalida();
      });
    },

    cancelar(id){
      ActivosApi.cancelarOsAf(id).then(()=>{
        this.addSuccessNotification("Orden de salida actualizada");
        this.listarOrdenesSalida();
      })
    },

    descargar(){
      orden_salida_pdf()
    },

    limpiar(){
      this.equipo = ''
      this.contrato_cliente = ''
      this.lugar_destino = ''
      this.objeto_servicio = ''
      this.operador = ''
      this.periodo_renta = ''
      this.fecha_hora_salida = ''
      this.fecha_hora_ingreso = ''
      this._id
    },

    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification2(mensaje) {
      this.$toast.error(mensaje, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
};
</script>
<style scoped>
h2 {
  text-align: center;
  color: #333;
}
table {
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
  margin-top: 20px;
}
th,
td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.signature-line {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.signature-box {
  flex-basis: 30%;
  border: 1px solid #ddd;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signature-box h3 {
  margin-bottom: 10px;
  color: #666;
}
.signature-box p {
  text-align: center;
  margin: 0;
}
.signature-box p.name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.signature-box p.email {
  font-size: 14px;
  color: #888;
}
</style>
