import axios from "axios";

export default {
  obtenerOrdenesSalida() {
    return axios.get("/ose/list");
  },
  queryOrdenesSalida(equipo) {
    return axios.get("/ose/query?equipo=" + equipo);
  },
  ingresarOrdenSalida(datos) {
    return axios.post("/ose/add", datos);
  },
  aprobarOs(datos) {
    return axios.put("/ose/autorizar", datos);
  },
  aprobarOsAf(datos) {
    return axios.put("/ose/autorizarAF", datos);
  },
  editarOsAf(datos) {
    return axios.put("/ose/editar", datos);
  },
  cancelarOsAf(id) {
    return axios.put("/ose/cancelar?_id=" + id);
  },
};
