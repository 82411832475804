<template>
  <v-container fluid>
    <v-dialog v-model="dialogViewCosteo" transition="dialog-bottom-transition">
      <v-card>
        <v-card>
          <v-card-title class="text-h5 black lighten-2 white--text">
            Estimacion
          </v-card-title>
          <v-card-text
            ><br /><br />
            <div class="text--primary">
              <iframe
                :src="urlCosteo"
                style="width: 100%; height: 600px"
                frameborder="0"
              ></iframe>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="descargarEstimacionExcel"
              text
              color="green accent-4"
            >
              Descargar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogPrimeraFirma"
      transition="dialog-bottom-transition"
      width="500"
    >
      <v-card>
        <v-card>
          <v-card-title class="text-h5 black lighten-2 white--text">
            Primera Firma
          </v-card-title>
          <v-card-text>
            <v-file-input
              accept="application/pdf"
              placeholder="Adjunte la estimación Firmada"
              prepend-icon="mdi-draw"
              label="C.P. ANDRES GARCIA CASTILLO"
              v-model="firma1"
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="adjuntarEstimacionPrimeraFirma"
              small
              outlined
              tile
              color="success"
              dark
            >
              <v-icon left> mdi-content-save </v-icon>
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogSegundaFirma"
      transition="dialog-bottom-transition"
      width="500"
    >
      <v-card>
        <v-card>
          <v-card-title class="text-h5 black lighten-2 white--text">
            Segunda Firma
          </v-card-title>
          <v-card-text>
            <v-file-input
              accept="application/pdf"
              placeholder="Adjunte la estimación Firmada"
              prepend-icon="mdi-draw"
              label="LIC. ALFONSO HIGINIO GARCÍA ROMERO"
              v-model="firma1"
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="adjuntarEstimacionSegundaFirma"
              small
              outlined
              tile
              color="success"
              dark
            >
              <v-icon left> mdi-content-save</v-icon>
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogTerceraFirma"
      transition="dialog-bottom-transition"
      width="500"
    >
      <v-card>
        <v-card>
          <v-card-title class="text-h5 black lighten-2 white--text">
            Tercera Firma
          </v-card-title>
          <v-card-text>
            <v-file-input
              accept="application/pdf"
              placeholder="Adjunte la estimación Firmada"
              prepend-icon="mdi-draw"
              label="ING. AGUSTÍN PEÑA BOCARANDO			"
              v-model="firma1"
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="adjuntarEstimacionTerceraFirma"
              small
              outlined
              tile
              color="success"
              dark
            >
              <v-icon left> mdi-content-save</v-icon>
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>

    <div class="tables-basic">
      <br />
      <h1 class="page-title mt-16 mb-6">Estimaciones</h1>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-6 pb-3">
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>

            <v-data-table :headers="headers" :items="costeos" :search="search">
              <template v-slot:[`item.estatus`]="{ item }">
                <v-chip
                  class="ma-2"
                  color="orange"
                  label
                  text-color="white"
                  v-if="item.estatus == 'Pendiente de Firmas'"
                >
                  <v-icon left> mdi-account-clock-outline </v-icon>
                  {{ item.estatus }}
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="yellow"
                  label
                  v-if="item.estatus == 'Firma 1'"
                >
                  <v-icon left> mdi-account-clock-outline </v-icon>
                  {{ item.estatus }}
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="purple"
                  dark
                  label
                  v-if="item.estatus == 'Firma 2'"
                >
                  <v-icon left> mdi-account-clock-outline </v-icon>
                  {{ item.estatus }}
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="pink"
                  dark
                  label
                  v-if="item.estatus == 'Firma 3'"
                >
                  <v-icon left> mdi-account-clock-outline </v-icon>
                  {{ item.estatus }}
                </v-chip>
              </template>
              <template v-slot:[`item.contrato`]> 648228851 </template>
              <template v-slot:[`item.mes`]="{ item }">
                {{ moment(item.mes).add(1, "days").format("MMMM YYYY") }}
              </template>

              <template v-slot:[`item.firmados`]="{ item }">
                <v-btn small class="mx-2" v-if="item.urlEstimacion1" @click="descargarEstimacionFirmadaExcel(item.urlEstimacion1)" tile color="yellow">
                  <v-icon left> mdi-microsoft-excel </v-icon>
                  Firma 1
                </v-btn>
                <v-btn small dark class="mx-2" @click="descargarEstimacionFirmadaExcel(item.urlEstimacion2)" v-if="item.urlEstimacion2" tile color="purple">
                  <v-icon left> mdi-microsoft-excel </v-icon>
                  Firma 2
                </v-btn>
              
                <v-btn small dark class="mx-2" @click="descargarEstimacionFirmadaExcel(item.urlEstimacion3)"  v-if="item.urlEstimacion3" tile color="pink">
                  <v-icon left> mdi-microsoft-excel </v-icon>
                  Firma 3
                </v-btn>
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  @click="vizualizarCosteo(item.urlExcel)"
                  class="mx-2"
                  dark
                  small
                  tile
                  outlined
                  color="green"
                >
                  <v-icon left>mdi-printer-eye</v-icon>Estimacion
                </v-btn>
                <v-btn
                  @click="primeraFirma(item)"
                  class="mx-2"
                  dark
                  small
                  tile
                  outlined
                  color="blue"
                  v-if="item.estatus == 'Pendiente de Firmas'"
                >
                  <v-icon left>mdi-pencil</v-icon>Primera Firma
                </v-btn>
                <v-btn
                  @click="segundaFirma(item)"
                  class="mx-2"
                  dark
                  small
                  tile
                  outlined
                  color="purple"
                  v-if="item.estatus == 'Firma 1'"
                >
                  <v-icon left>mdi-pencil</v-icon>Segunda Firma
                </v-btn>
                <v-btn
                  @click="terceraFirma(item)"
                  class="mx-2"
                  dark
                  small
                  tile
                  outlined
                  color="pink"
                  v-if="item.estatus == 'Firma 2'"
                >
                  <v-icon left>mdi-pencil</v-icon>Tercera Firma
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      headers: [
        { text: "Actions", width: "25%", value: "action" },
        { text: "Firmados", width: "30%", value: "firmados" },
        { text: "Mes", width: "20%", value: "mes" },

        { text: "Contrato", value: "contrato", sortable: true },
        { text: "Estatus", width: "15%", value: "estatus" },
      ],
      empresas: [],
      empresa: "",
      costeos: [],
      trazabilidad: [],
      activosRaf: [],
      activosPedidos: [],
      sheetActivos: false,
      sheetPedidos: false,
      direccion: "",
      dialogPrimeraFirma: false,
      dialogSegundaFirma: false,
      dialogTerceraFirma: false,

      search: "",
      firma1: "",
      id: "",
      urlCosteo: "",
      urlExcel: "",
      moment: moment,
      dialogNuevaUbicacion: false,
      ubicaciones: [],
      editedIndex: -1,
      submited: "",
      alert: "",
      dialogViewCosteo: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Ubicacion" : "Editar Ubicacion";
    },
  },
  created() {
    this.listar();
  },
  methods: {
    listar() {
      let me = this;
      axios
        .get("/estimacion/list")
        .then(function (response) {
          me.costeos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    descargarCosteo(id) {
      // this.loading = true;
      axios({
        url: "/costeo/descargarCosteo?_id=" + id, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      });
    },

    descargarEstimacion(id) {
      // this.loading = true;
      axios({
        url: "/costeo/descargarEstimacion?_id=" + id, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "estimacion.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      });
    },

    async adjuntarEstimacionPrimeraFirma() {
      let me = this;
      try {
        if (this.firma1) {
          const formData = new FormData();
          formData.append("upload_preset", "estimaciones");
          formData.append("file", this.firma1);

          const url = "https://api.cloudinary.com/v1_1/cicsa/image/upload";
          const { data } = await axios.post(url, formData);

          axios
            .put("/estimacion/actuliazarFirma1", {
              _id: this.id,
              firma1: data.secure_url,
            })
            .then(() => {
              me.addSuccessNotification();
              me.dialogPrimeraFirma = false;
              me.id = "";
              me.listar();
            });
        } else {
          this.addErrorNotification();
        }
      } catch (error) {
        console.log(error);
      }
    },

    async adjuntarEstimacionSegundaFirma() {
      let me = this;
      try {
        if (this.firma1) {
          const formData = new FormData();
          formData.append("upload_preset", "estimaciones");
          formData.append("file", this.firma1);

          const url = "https://api.cloudinary.com/v1_1/cicsa/image/upload";
          const { data } = await axios.post(url, formData);

          axios
            .put("/estimacion/actuliazarFirma2", {
              _id: this.id,
              firma2: data.secure_url,
            })
            .then(() => {
              me.addSuccessNotification();
              me.dialogSegundaFirma = false;
              me.id = "";
              me.listar();
            });
        } else {
          this.addErrorNotification();
        }
      } catch (error) {
        console.log(error);
      }
    },

    async adjuntarEstimacionTerceraFirma() {
      let me = this;
      try {
        if (this.firma1) {
          const formData = new FormData();
          formData.append("upload_preset", "estimaciones");
          formData.append("file", this.firma1);

          const url = "https://api.cloudinary.com/v1_1/cicsa/image/upload";
          const { data } = await axios.post(url, formData);

          axios
            .put("/estimacion/actuliazarFirma3", {
              _id: this.id,
              firma3: data.secure_url,
            })
            .then(() => {
              me.addSuccessNotification();
              me.dialogTerceraFirma = false;
              me.id = "";
              me.listar();
            });
        } else {
          this.addErrorNotification();
        }
      } catch (error) {
        console.log(error);
      }
    },

    agregarRAF(item, id) {
      const activosPemex = [];
      item.forEach((e) => {
        if (
          activosPemex.findIndex((pd) => pd.activoPemex == e.activoPemex) === -1
        ) {
          activosPemex.push(e);
        }
      });
      let activos = [];
      activosPemex.forEach((e) => {
        if (e.activoPemex) {
          activos.push({
            activo: e.activoPemex,
            raf: e.raf,
          });
        }
      });

      this.activosRaf = activos;
      this.sheetActivos = true;
      this.trazabilidad = item;
      this.id = id;
    },

    agregarPedido(item, id) {
      const activosPemex = [];
      item.forEach((e) => {
        if (
          activosPemex.findIndex((pd) => pd.activoPemex == e.activoPemex) === -1
        ) {
          activosPemex.push(e);
        }
      });
      let activos = [];
      activosPemex.forEach((e) => {
        if (e.activoPemex) {
          activos.push({
            activo: e.activoPemex,
            pedidoPemex: e.pedidoPemex,
          });
        }
      });

      this.activosPedidos = activos;
      this.sheetPedidos = true;
      this.trazabilidad = item;
      this.id = id;
    },

    primeraFirma(item) {
      this.id = item._id;
      this.dialogPrimeraFirma = true;
    },

    segundaFirma(item) {
      this.id = item._id;
      this.dialogSegundaFirma = true;
    },

    terceraFirma(item) {
      this.id = item._id;
      this.dialogTerceraFirma = true;
    },

    guardarRAF() {
      //Agregando RAF a Trazabilidad
      let me = this;
      this.trazabilidad.forEach((e) => {
        this.activosRaf.forEach((f) => {
          if (e.activoPemex === f.activo) {
            e.raf = f.raf;
          }
        });
      });
      axios
        .put("/costeo/costeoRAF", {
          _id: this.id,
          trazabilidad: this.trazabilidad,
        })
        .then(function () {
          me.addSuccessNotification("Activo actualizado");
          me.sheetActivos = false;
        })
        .catch(function (error) {
          console.log(error);
          me.addErrorNotification("Error, verifica la informacion agregada");
        });

      //Filtrando partidas
    },
    guardarPedido() {
      //Agregando PEDIDO a Trazabilidad
      let me = this;
      this.trazabilidad.forEach((e) => {
        this.activosPedidos.forEach((f) => {
          if (e.activoPemex === f.activo) {
            e.pedidoPemex = f.pedidoPemex;
          }
        });
      });
      axios
        .put("/costeo/costeoRAF", {
          _id: this.id,
          trazabilidad: this.trazabilidad,
          pedidoEstatu: true,
        })
        .then(function () {
          me.addSuccessNotification("Activo actualizado");
          me.sheetPedidos = false;
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
          me.addErrorNotification("Error, verifica la informacion agregada");
        });

      //Filtrando partidas
    },

    vizualizarCosteo(item) {
      //this.urlCosteo = item
      console.log(item);
      let url = "https://view.officeapps.live.com/op/embed.aspx?src=" + item;
      this.urlCosteo = url;
      this.urlExcel = item;
      this.dialogViewCosteo = true;
    },

    descargarEstimacionExcel() {
      window.open(this.urlExcel, "_blank");
    },

    descargarEstimacionFirmadaExcel(item) {
      window.open(item, "_blank");
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
};
</script>
