import axios from "axios";

export default {
  //----ENDPOINTS DEPARTATAMENTOS----//

  obtenerDepartamentos() {
    return axios.get("/departamentos/list");
  },

  //----ENDPOINTS PARTIDAS-----//
  obtenerPartidasActivas() {
    return axios.get("/partidas/list");
  },
  queryPartidas(partida) {
    return axios.get("/partidas/query?partida=" + partida);
  },
  updateMontoDevengado(partida) {
    return axios.put("/partidas/updateMontoDevengado", partida);
  },

  //-----ENDPOINTS TIPO EQUIPO----//

  insertarTipoEquipo(datos) {
    return axios.post("/tipoUnidad/add", datos);
  },
  obtenerTipoUnidadSelect() {
    return axios.get("/tipoUnidad/list");
  },

  //-----ENDPOINTS COMPONENTES----//

  insertarComponente(datos) {
    return axios.post("/componente/add", datos);
  },
  obtenerComponenteSelect() {
    return axios.get("/componente/list");
  },
  //-----ENDPOINTS UBICACIONES OS----//

  insertarUbicacionOs(datos) {
    return axios.post("/ubicacionOs/add", datos);
  },
  obtenerUbicacionesOs() {
    return axios.get("/ubicacionOs/list");
  },

  //ENPOINTS SUPERVISOR DE CONTRATO
  insertarSupervisor(datos) {
    return axios.post("/supervisor/add", datos);
  },
  listarSupervisores() {
    return axios.get("/supervisor/list");
  },
  actualizarEstatus(datos) {
    return axios.put("/supervisor/update_estatus", datos);
  },
  obtenerSupervisorActivo() {
    return axios.get("/supervisor/find_supervisor_activo");
  },
  //ENPONT ORDEN DE SERVICIO

  actualizarMontoOs(datos) {
    return axios.get("/os/updateMontoOrden", datos);
  },
  test() {
    return axios.get("/os/list");
  },

  //ENPOINTS PROGRMA PRESUPUESTALES REPETIDOS
  ingresarEpep(datos) {
    return axios.post("/epep/add", datos);
  },
  obtenerEpep() {
    return axios.get("/epep/list");
  },
  deleteEpep(id) {
    return axios.delete("/epep/delete_one?_id=" + id);
  },
  queryEpep(epep) {
    return axios.get("/epep/query?pp=" + epep);
  },

  //ACTIVOS
  queryIdActivo(_id) {
    return axios.get("/activos/query?_id=" + _id);
  },
  agregarAvatar(datos) {
    return axios.put("/activos/updateAvatar", datos);
  },
  obtenerAvatar(numero_economico) {
    return axios.get(
      "/activos/obtenerAvatar?numeroEconomico=" + numero_economico
    );
  },

  //GRAFICAS ECHARTS
  activosPorEstatusGrafica() {
    return axios.get("/activos/grafica_activo_estatus");
  },
  activosDesgloce(page) {
    return axios.post("/activos/list?page=" + page);
  },
  corregirEmpresaList(datos) {
    return axios.put("/activos/corregir_empresa", datos);
  },
};
