import jsPDF from "jspdf";

export const generarReporteFotografico = (item) => {
  console.log(item);
  const diaInicio = item.fecha_inicio.split("-")[2];
  const diaFin = item.fecha_fin.split("-")[2];
  const fechaDate = new Date(item.fecha_fin);
  const opciones = { month: "long" };

  const mes = new Intl.DateTimeFormat("es-ES", opciones).format(fechaDate);
  const mesMayus = mes.toUpperCase();
  var doc = new jsPDF();
  createHeader(doc, 3);
  doc.setFontSize(9);
  doc.setFont(undefined, "bold");
  doc.text(`Paraíso, Tabasco a ${diaInicio} de ${mes} del 2023`, 130, 40);

  const pageSize = doc.internal.pageSize;
  const pageWidth = pageSize.width;
  const titleText = `MANTENIMIENTOS DEL MES DE ${mesMayus} CTO. 648812815`;
  const titleTextWidth =
    (doc.getStringUnitWidth(titleText) * doc.internal.getFontSize()) /
    doc.internal.scaleFactor;
  const titleX = (pageWidth - titleTextWidth) / 2;
  const titleY = 50;
  doc.text(titleText, titleX, titleY);
  doc.setFont(undefined, "normal");

  const secondText = `Equipo: ${item.numeroEconomico}`;
  const secondTextWidth =
    (doc.getStringUnitWidth(secondText) * doc.internal.getFontSize()) /
    doc.internal.scaleFactor;
  const secondX = (pageWidth - secondTextWidth) / 2;
  const secondY = titleY + 5;

  doc.text(secondText, secondX, secondY);

  const subtitle = `SEMANA ${item.semana} DEL ${diaInicio} AL ${diaFin} DE ${mesMayus} DEL 2023`;
  const subtitleTextWidth =
    (doc.getStringUnitWidth(subtitle) * doc.internal.getFontSize()) /
    doc.internal.scaleFactor;
  const subtitleX = (pageWidth - subtitleTextWidth) / 2;
  const subtitleY = titleY + 10;
  doc.setFontSize(10);
  doc.text("1", 190, 290); // Ajusta la posición y el texto según tus
  doc.text(subtitle, subtitleX, subtitleY);

  let images = item.fotos;

  const imagen0 = {
    src: item.avatar,
    info: item.numeroEconomico,
    index: 0,
  };
  images.unshift(imagen0);

  const imagesPerGroup = 4; // Cantidad de imágenes por grupo
  const groupSize = Math.ceil(images.length / imagesPerGroup);
  let y = 0;
  let startY = 70; // La posición Y de inicio en la primera página

  for (let i = 0; i < groupSize; i++) {
    const group = images.slice(i * imagesPerGroup, (i + 1) * imagesPerGroup);
    group.forEach((image, index) => {
      const x = 15 + (index % 2) * 100;
      y = startY + Math.floor(index / 2) * 100;
      doc.addImage(image.src, "JPEG", x, y, 80, 80);

      const rectWidth = 80;
      const rectHeight = 10;
      const rectX = x;
      const rectY = y + 80;
      doc.setDrawColor(255, 0, 0);
      doc.rect(rectX, rectY + 3, rectWidth, rectHeight);
      doc.text(
        "Foto " + parseInt(image.index + 1) + ": " + image.info,
        rectX + 5,
        rectY + 7
      ); // Agregar el texto dentro del recuadro
    });

    if (i < groupSize - 1) {
      doc.addPage();
      createHeader(doc, 3);
      doc.setFontSize(9);
      startY = 50; // Ajustar la posición Y para la siguiente página
    }
  }

  // Agregar pie de página en las páginas adicionales
  for (let i = 1; i < groupSize; i++) {
    doc.setPage(i + 1); // Cambiar a la página siguiente
    doc.setFontSize(10);
    doc.text(`${i + 1}`, 190, 290); // Ajusta la posición y el texto según tus necesidades
  }

  // Restaurar página actual
  doc.setPage(1);

  doc.save("mi_documento.pdf");
};

const createHeader = (doc, pageNumber) => {
  doc.addFont("Arial");
  var img = new Image();
  img.src = "https://i.ibb.co/m50WVvh/logo-cic.png";
  doc.addImage(img, "JPEG", 10, 10, 50, 13);
  var img2 = new Image();
  img2.src = "https://i.ibb.co/m9Ggg4Z/dnvgl.png";
  var imgWidth = 20;
  var imgHeight = 20;
  var marginLeft = (doc.internal.pageSize.width - imgWidth) / 2;
  doc.addImage(img2, "JPEG", marginLeft, 10, imgWidth, imgHeight);
  const text = "Página " + pageNumber;
  console.log(text);
  doc.setFontSize(13);
  doc.text("Corporativo Industrial y Comercial", 130, 18);
  doc.text("S.A de C.V", 145, 24);
};
