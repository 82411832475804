export default {
    light: {
      primary: "#B21E28 ",
      secondary: "#231F20",
      error: "#FF4081",
      warning: "#FFC260",
      success: "#3CD4A0",
      info: "#9013FE",
      textColor: "#B9B9B9",
      iconColor: "#FFFFFF59",
      grayBold: '#4a4a4a'
  }
};

