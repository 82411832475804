<template>
  <v-container fluid>
    <v-bottom-sheet v-model="sheetHorometro">
      <v-sheet height="100%">
        <div class="py-3">
          <v-card class="mx-auto"
            ><v-card-text>
              <v-row>
                <v-col cols="10" sm="10" lg="10">
                  <h2 style="text-align: left">Horometro</h2>
                </v-col>
                <v-col cols="2" sm="2" lg="2">
                  <v-btn
                    @click="
                      mostrarFormularioNuevoHorometro =
                        !mostrarFormularioNuevoHorometro
                    "
                    fab
                    dark
                    x-small
                    color="primary"
                  >
                    <v-icon
                      v-if="mostrarFormularioNuevoHorometro == false"
                      dark
                    >
                      mdi-plus
                    </v-icon>
                    <v-icon v-if="mostrarFormularioNuevoHorometro == true" dark>
                      mdi-close-circle-outline
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <p style="text-align: left">
                Horometros registrados para el equipo seleccionado
              </p>
              <v-row v-if="equipoSeleccionado">
                <v-col cols="12" sm="12" lg="12">
                  <v-text-field
                    :value="`${equipoSeleccionado.nombreActivo} ${equipoSeleccionado.numeroEconomico}`"
                    label="Solo"
                    solo
                    disabled
                    dense
                  ></v-text-field>
                </v-col>

                <v-col
                  v-if="mostrarFormularioNuevoHorometro"
                  cols="6"
                  sm="6"
                  lg="6"
                >
                  <v-text-field
                    label="Horometro"
                    solo
                    dense
                    style="margin-top: -40px"
                    v-model="horometro"
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="mostrarFormularioNuevoHorometro"
                  cols="6"
                  sm="6"
                  lg="6"
                >
                  <v-text-field
                    style="margin-top: -40px"
                    label="Fecha"
                    solo
                    type="date"
                    v-model="fecha"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="mostrarFormularioNuevoHorometro"
                  cols="12"
                  sm="12"
                  lg="12"
                >
                  <v-btn
                    style="margin-top: -40px"
                    @click="agregarHorometro"
                    block
                    color="primary"
                  >
                    <v-icon left> mdi-content-save </v-icon>
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" lg="12">
                  <v-list
                    subheader
                    two-line
                    style="height: 400px; overflow-y: scroll"
                  >
                    <v-list-item v-for="item in horometros" :key="item._id">
                      <v-icon dense color="green darken-2" class="pr-6">
                        mdi-checkbox-blank-circle
                      </v-icon>

                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <p class="font-weight-black">
                            Horometro {{ item.horometro }}
                          </p>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          >Fecha :{{
                            moment(item.fechaRegistro).format("L")
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <div
                    v-if="horometros.length"
                    v-observe-visibility="handleScrolledToBottomHorometro"
                  ></div>
                  <div class="text-center">
                  <v-progress-circular
                    v-if="loadingHorometros"
                    :size="25"
                    indeterminate
                    color="red"
                  ></v-progress-circular>
                </div>
                  </v-list>
                 
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <div class="tables-basic">
      <br /><br />
      <h1 class="page-title mt-15 mb-1">Horometro</h1>

      <div>
        <div class="pa-1">
          <div class="pa-3">
            <div class="my-5">
              <v-card flat class="pa-0">
                <v-list
                  v-for="item in equipos"
                  :key="item.numeroSerie"
                  subheader
                  two-line
                >
                  <v-layout :class="` reporte ${item.estatu}`">
                    <v-list-item style="max-width: 130%">
                      <v-list-item-content>
                        <v-list-item-subtitle>{{
                          item.nombreActivo
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle
                          ><p class="font-italic">
                            {{ item.numeroEconomico }}
                          </p></v-list-item-subtitle
                        >
                   
                        <v-list-item-subtitle>
                            <v-row>
                            <v-col cols="1">
                                <v-btn
                            @click="mostrarHorometrosRegistrados(item)"
                            color="primary"
                            fab
                            outlined
                            x-small
                          >
                            <v-icon>mdi-clock-plus</v-icon>
                          </v-btn>
                            </v-col>
                         
                        </v-row>
                       
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-layout>
                  <v-divider></v-divider>
                </v-list>
                <div
                  v-if="equipos.length"
                  v-observe-visibility="handleScrolledToBottom"
                ></div>
                <div class="text-center">
                  <v-progress-circular
                    v-if="loadingReportes"
                    :size="50"
                    indeterminate
                    color="red"
                  ></v-progress-circular>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import api from "../../api/Mtto";

export default {
  name: "app",

  data() {
    return {
      moment,
      datosReporteFalla: [],
      mostrarMecanico: false,
      loadingReportes: true,
      page: 1,
      pageHorometro: 1,
      lastpage: 1,
      lastpageHorometro: 1,
      idEquipo: "",
      equipos: [],
      horometros: [],
      sheetHorometro: false,
      equipoSeleccionado: [],
      mostrarFormularioNuevoHorometro: false,
      loadingHorometros:true,
      horometro: "",
      fecha: "",
    };
  },

  created() {
    this.listar();
  },
  methods: {
    limpiar() {
      this.numeroEconomicoBusqueda = "";
      this.fechaFalla = "";
      this.operador = "";
      this.id = "";
      this.tipoDeFalla = "";
      this.descripcion = "";
      this.folio = "";
      this.activoPorQuery.fotos = [];
    },

    mostrarNuevo() {
      this.dialogGenerarFallas = true;
      this.limpiar();
    },
    ocultarNuevo() {},
    mostrarHorometrosRegistrados(item) {
      this.sheetHorometro = true;
      this.mostrarFormularioNuevoHorometro = false;
      this.equipoSeleccionado = item;
      this.obtenerOrometroEquipo();
    },

    async listar() {
      //let me = this;
      let datos = await api.obtenerActivosPemex(this.page);
      this.equipos.push(...datos.data.docs);
      this.lastpage = datos.data.totalPages;
    },

    async obtenerOrometroEquipo() {
      //let me = this;
      let datos = await api.obtenerHorometroEquipo(
        this.equipoSeleccionado._id,
        this.pageHorometro
      );
      console.log(datos);
      this.horometros.push(...datos.data.docs);
      this.lastpageHorometro = datos.data.totalPages;
    },

    agregarHorometro() {
      let datos = {
        activo: this.equipoSeleccionado._id,
        usuarioCreador: this.$store.state.usuario.personal._id,
        horometro: this.horometro,
        fechaRegistro: this.fecha,
      };
      api
        .insertarOrometro(datos)
        .then(() => {
          this.addSuccessNotification("Horometro ingresado correctamente");
          //this.sheetHorometro = false;
          //this.mostrarFormularioNuevoHorometro = false;
        })
        .catch((e) => {
          this.addErrorNotification(e);
        });
    },

    handleScrolledToBottom(isVisible) {
      if (!isVisible) {
        return;
      }
      if (this.page >= this.lastpage) {
        this.loadingReportes = false;
        return;
      }
      this.page++;
      this.listar();
    },

    handleScrolledToBottomHorometro(isVisible) {
      if (!isVisible) {
        return;
      }
      if (this.pageHorometro >= this.lastpageHorometro) {
        this.loadingHorometros = false;
        return;
      }
      this.pageHorometro++;
      this.obtenerOrometroEquipo();
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification(mensaje) {
      this.$toast.error(mensaje, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
};
</script>
<style scoped>
.button-fab {
  bottom: 50px;
  height: 60px;
  position: fixed;
  right: 20px;
  width: 60px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.Revision {
  border-left: 4px solid #3cd1c2;
}
.Fuera {
  border-left: 4px solid orange;
}
.Operativo {
  border-left: 4px solid rgb(24, 230, 110);
}
.Pendiente {
  border-left: 4px solid black;
}
.Cancelado {
  border-left: 4px solid #f83e70;
}
.app_more {
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
}
.app_more:hover {
  text-decoration: underline;
}
.app_more,
.app_more:active {
  color: #3cd1c2;
}
.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid rgb(123, 90, 243);
  border-top-color: #fff;
  position: absolute;
  bottom: 0;
  left: calc(50%-20px);
  animation: spinner-animation 1.2s linear infinite;
}
.reporte.iniciado {
  border-left: 4px solid #3cd1c2;
}
.reporte.Pendiente {
  border-left: 4px solid orange;
}
.reporte.Cancelado {
  border-left: 4px solid #f83e70;
}
.v-chip.Activo {
  background: #f83e70;
  color: black;
}
#chips-container .v-chip.activo {
  color: white;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
