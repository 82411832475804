<template>
     <v-container fluid>
    <div class="tables-basic">
        <br><br><br><br>
    
    
     
      <v-row>
          
          
        <v-col cols="12">
             <v-row>
        
         <v-col cols="12" lg="3">
            <v-text-field v-model="compania" style="margin-top:-20px" label="Compañia" outlined dense disabled></v-text-field> 
         </v-col>
       
       
         <v-col cols="12" lg="3">
            <v-text-field v-model="contrato" style="margin-top:-20px" label="Contrato" outlined dense disabled></v-text-field> 
         </v-col>
        <v-col cols="12" lg="3">
            <v-text-field v-model="servicio" style="margin-top:-20px" label="Servicio" outlined dense disabled></v-text-field> 
         </v-col>
          <v-col cols="12" lg="3">
            <v-text-field v-model="periodo" style="margin-top:-20px" label="Periodo" outlined dense disabled></v-text-field> 
         </v-col> 
     </v-row>
          <v-card class="employee-list ">
            
      
            <div class="pa-5"  v-if="verNuevo==0">
                <v-btn @click="mostrarNuevo()" class="mb-5"  rounded color="primary" small dark>Nuevo Registro</v-btn>

      
            </div>
                 <div v-if="verNuevo">
        <div>
               <v-card-title>{{formTitle}}</v-card-title>
       
        
        </div>
        <div class=" pa-5">
          
            <v-flex xs11 sm11 lg11 x11 >
                <v-row>
                  
                    <v-col cols="12" lg="2">
                        <v-text-field outlined dense style="margin-top:-30px" label="Programa Presupuestal" color="primary" v-model="programaPresupuestal" append-icon="mdi-briefcase-search-outline" @click:append="buscarDatos()" :loading="loading" ></v-text-field>
                    </v-col>
                      <v-col cols="12" lg="2">
                        <v-text-field outlined dense v-model="teb"  label="TEB" style="margin-top:-30px"></v-text-field>
                    </v-col>   
                    <v-col cols="12" lg="3">
                        <v-combobox outlined dense v-model="epep" :items="items"  style="margin-top:-30px" hide-selected label="EPEP" multiple persistent-hint small-chips>
                            <template v-slot:no-data>
                                <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Preciona la tecla <kbd>enter</kbd> para crear un nuevo EPEP
                                    </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-combobox>
                    </v-col>
                     <v-col cols="12" lg="2">
                        <v-text-field outlined dense v-model="activo" disabled  label="Activo" style="margin-top:-30px"></v-text-field>
                    </v-col>
                     <v-col cols="12" lg="3">
                        <v-autocomplete outlined dense  :items="inst_dest" v-model="inst_dest_items" style="margin-top:-30px" chips small-chips multiple label="Instalación/Destino"></v-autocomplete>
                    </v-col>
                    
                </v-row>

          
                <v-btn
            color="blue darken-1"
            text
            @click="ocultarNuevo()"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="guardar()"
          >
            Guardar
          </v-btn>

               
            </v-flex>    
           
          
        </div>
        
                </div>
          </v-card>
        </v-col>
       
      </v-row>
    </div>
    <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="programasPresupuestales"
      dense
      :search="search"
    >
       <template v-slot:[`item.action`]="{item}">
          <v-icon @click="editItem(item)" small class="mr-2" >mdi-border-color</v-icon>
        </template>
    </v-data-table>
  </v-card>
  
     </v-container>
</template>

<script>
import axios from 'axios'
export default {
   
    data(){
        return{
          
            headers: [
                { text: "Actions", value: "action", filterable:true },
                { text: "Programa Presupuestal", value: "programaPresupuestal",filterable:true },   
                 { text: "TEB", value: "teb",filterable:true },   
                { text: "EPEP", value: "epep",  },
                { text: "Activo", value: "claves.activoAbreb",  filterable:true}, 
                { text: "Instalacion/Destino", value: "inst_dest_items",  },
              

            ],
            compania:"CORPORATIVO INDUSTRIAL Y COMERCIAL S.A. DE C.V.",
            contrato:"648228851",
            servicio:"SERVICIO DE TRANSPORTE DE CARGA Y DESCARGA DE MATERIALES EN PUERTO DOS BOCAS",
            periodo:"DEL 01 AL 31 DE MARZO 2022",
            mostrarTabla:false, 
            hover: false,
            nombreContrato:"INTEGRAL EQ. TERRESTRE DB",
            partida:'',
            items:[],
            partidas:[],
            inst_dest:['ABKATUN','ALMACEN GENERAL/PROCESOS','ALMACEN GENERAL/VENTAS','CAJAS','COSECO','COSECO/LOGISTICA','COSECO/LOGISTICA/REMANENTE','INSPECCIÓN TUBULAR','INSPECCIÓN TUBULAR/MONOBOYAS','LOGISTICA','LOGISTICA/PLANTA DE LODOS/TALLER DE ARBOLES'
            ,'LOGISTICA/REMANENTE','LOGISTICA/REMANENTE/SEYBAPLAYA/TANQUE 2','LOGISTICA/REMANENTE/SEYBAPLAYA/TANQUE 2','LOGISTICA/SEYBAPLAYA','LOGISTICA/SEYBAPLAYA/TANQUE 1','LOGISTICA/TALLER DE ARBOLES','LOGISTICA/TANQUE 1','LOGISTICA/URREP','MONOBOYAS','PATRIMONIAL','PATRIMONIAL/VENTAS',
            'PLANTA DE LODOS','PLANTA DE LODOS/TALLER DE ARBOLES','PROCESOS','REMANENTE','SEYBAPLAYA','SEYBAPLAYA/TANQUE 1','TALLER DE ARBOLES','TANQUE 1','TANQUE 2','URREP','VENTAS'],
            costo:'',
            search:'',
            costoUnitario:'',
            volumen:'',
            montoEstimado:'',
            activoId:'',
            inst_dest_items:[],
            programaPresupuestal:'',
            programasPresupuestales:[],
            epep:[],
            activo:'',
            teb:'',
            menu: false,
            modal: false,
            documentos:[],
            menu2: false,
            ppres:'',
            razonSocial:'',
            verNuevo:0,
             editedIndex: -1,
            loading:false,
            submited:'',
            alert:'',
            options: {
            locale: "en-US",
            prefix: "$",
            suffix: "",
            length: 11,

            company_logo:{
                w: 110,
                h: 35
            },

            precision: 2
            },
   
    
        }
    },
    watch:{
        
    },
    computed: {
     
  
  
        formTitle() {
        return this.editedIndex === -1 ? "Nuevo registro" : "Editar registro";
        }
    },
    created(){
        this.listar()
       
    },
    methods:{

          generarPdf(){
        
            
                    
        },

        listar(){
            let me = this
            let header={"Token":this.$store.state.token};
            let configuracion= {headers: header}
                axios.get('/programaspress/list',configuracion).then(function (response){
                  me.programasPresupuestales = response.data
                  console.log(me.programasPresupuestales)
              }).catch(function(error){
                  console.log(error)
              }).finally(()=>{
                
              })
        } ,
    
       
        limpiar(){
            this.programaPresupuestal='';
            this.teb=''
            this.epep=''
            this.activo=''
            this.inst_dest_items=''
           
        },

       
       
        guardar(){
            let me = this
            let header={"Token":this.$store.state.token};
            let configuracion= {headers: header}
            if(this.editedIndex>-1){
               
                axios.put('/programaspress/update',{'_id':this._id,'programaPresupuestal':this.programaPresupuestal,'epep':this.epep,'claves':this.activoId,'inst_dest_items':this.inst_dest_items,'teb':this.teb},configuracion)
                .then(function(){
                  
                    me.listar()
                me.ocultarNuevo()
                me.addSuccessNotification('Programa editado')
                })
                .catch(function(error){
                    console.log(error)
                })
            }else{
            axios.post('/programaspress/add',{'programaPresupuestal':this.programaPresupuestal,'epep':this.epep,'claves':this.activoId,'inst_dest_items':this.inst_dest_items,'teb':this.teb},configuracion)
            .then(function(){
             
                me.listar()
                me.ocultarNuevo()
                me.addSuccessNotification('Programa agregado')
                
            })
            .catch(function(error){
                console.log(error)
            })
            }
        },
   
      
        editItem(item) {
           
            this.editedIndex = 1;
            this._id = item._id
            this.programaPresupuestal = item.programaPresupuestal
            this.teb = item.teb
            this.epep = item.epep
            this.activo = item.claves.activoAbreb
            this.activoId = item.claves._id
            this.inst_dest_items = item.inst_dest_items
           this.mostrarNuevo()
        },
         mostrarNuevo(){
           // this.listarAgencia(this.$store.state.usuario.agencia)
            this.verNuevo =1;

          },
            ocultarNuevo(){
            this.verNuevo =0
            this.limpiar();
          },

        addSuccessNotification(mensaje) {
                this.$toast.success(mensaje, {
                position: 'top-right',
                timeout: 2000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true
            });
        },
         addErrorNotification() {
                this.$toast.error("Error, verifique los campos ingresados", {
                position: 'bottom-right',
                timeout: 2000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true
            });   
        },

        buscarDatos(){
             let me = this;
             let header={"Token":this.$store.state.token};
             this.loading=true;
            let configuracion= {headers: header}
                        let claveId = this.programaPresupuestal.substr(0,3)
                        axios.get('/claves/query?idAsignacion='+claveId,configuracion).then(function (response){
                        me.loading=false
                        let activoPemex = response.data.activoAbreb
                        let activoId = response.data._id
                            me.activo = activoPemex
                            me.activoId = activoId
                    }).catch(function(){
                         me.addErrorNotification()
                         me.loading=false
                    })  
                }
        },
      
      
        changeIcon(){
            let me = this;
             let header={"Token":this.$store.state.token};
            let configuracion= {headers: header}
                if(me.volumen && me.partida){
                        axios.get('/partidas/query?partida='+this.partida,configuracion).then(function (response){
                        me.partidas = response.data
                        if(me.partidas){
                            me.costoUnitario = me.partidas.costo
                            me.montoEstimado = me.costoUnitario* me.volumen
                        }else{
                            me.addErrorNotification()
                            me.partida=''
                            me.costoUnitario='',
                            me.montoEstimado=''
                        }
                            
                        
                    }).catch(function(){
                    
                    })  
                }
            
        },
      
     

    }

</script>
