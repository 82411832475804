<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>
<script>
import { Doughnut } from "vue-chartjs/legacy";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
ChartJS.register(ChartDataLabels,);
ChartJS.defaults.set("plugins.datalabels", {
  color: "#ffffff",
});

export default {
  components: {
    Doughnut,
  },
  props: {
    chartData: {
      type: Object,
      
    },
    chartId: {
      type: String,
      default: "doughnut-chart",
    },

    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default:750,
    },
    height: {
      type: Number,
      default: 700,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
     
      chartOptions: {
        
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              color: "white",
              font: {
                size: 10,
                
              },
            },
          },
         
          
          datalabels: {
            formatter: function(value) {
              let number = parseFloat(value)
                return `$${number.toLocaleString("en-US")}`
            } ,
            labels: {
              title: {
                color:'white',
                font: {
                 
                  weight: "bold",
                  size: 13,
                },
              },
            },
          },
         
        },
      },
    };
  },
  methods: {
    costoToDollar(text) {
      let number = parseFloat(text);
      return number.toLocaleString("en-US");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>