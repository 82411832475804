<template>
  <v-container fluid><br><br>
    <v-card v-if="info_equipo.tipoUnidad" >
      <v-img
        v-if="info_equipo.fotos"
        :src="info_equipo.fotos[2]"
        height="300px"
        dark
      >
        <v-row class="fill-height">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn dark class="mr-4" icon @click="dialog_info_equipo = false">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-card-title>

          <v-spacer></v-spacer>

          <v-card-title class="white--text pl-12 pt-12"> </v-card-title>
        </v-row>
      </v-img>

      <v-list two-line>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary"> mdi-apps </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              ><span style="font-size: 12px"
                >Tipo de Unidad</span
              ></v-list-item-title
            >
            <v-list-item-subtitle>{{
              info_equipo.tipoUnidad
            }}</v-list-item-subtitle>

            <v-list-item-title
              ><span style="font-size: 12px">Año</span></v-list-item-title
            >
            <v-list-item-subtitle>{{ info_equipo.anio }}</v-list-item-subtitle>
            <v-list-item-title
              ><span style="font-size: 12px">Capacidad</span></v-list-item-title
            >
            <v-list-item-subtitle>{{
              info_equipo.capacidad
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary"> mdi-barcode </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              ><span style="font-size: 12px"
                >Numero de Serie</span
              ></v-list-item-title
            >
            <v-list-item-subtitle>{{
              info_equipo.numeroSerie
            }}</v-list-item-subtitle>
            <v-list-item-title
              ><span style="font-size: 12px"
                >Numero de Placa</span
              ></v-list-item-title
            >
            <v-list-item-subtitle>{{ info_equipo.placa }}</v-list-item-subtitle>
            <v-list-item-title
              ><span style="font-size: 12px"
                >Fecha de Adquisición</span
              ></v-list-item-title
            >
            <v-list-item-subtitle>{{
              info_equipo.fechaDeAdquisicion
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary"> mdi-cash </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              ><span style="font-size: 12px">Factura</span></v-list-item-title
            >
            <v-list-item-subtitle>{{
              info_equipo.factura
            }}</v-list-item-subtitle>
            <v-list-item-title
              ><span style="font-size: 12px"
                >Numero Economico</span
              ></v-list-item-title
            >
            <v-list-item-subtitle>{{
              info_equipo.numeroEconomico
            }}</v-list-item-subtitle>
            <v-list-item-title
              ><span style="font-size: 12px"
                >Costo de la Unidad</span
              ></v-list-item-title
            >
            <v-list-item-subtitle
              >${{
                this.costoToDollar(info_equipo.costo)
              }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary"> mdi-badge-account </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              ><span style="font-size: 12px">Resguardo</span></v-list-item-title
            >
            <v-list-item-subtitle v-if="info_equipo.personal">{{
              info_equipo.personal.nombreCompleto
            }}</v-list-item-subtitle>
            <v-list-item-title
              ><span style="font-size: 12px"
                >Propietario</span
              ></v-list-item-title
            >
            <v-list-item-subtitle v-if="info_equipo.personal">{{
              info_equipo.personal.empresa.razonSocial
            }}</v-list-item-subtitle>
            <v-list-item-title
              ><span style="font-size: 12px">Ubicación</span></v-list-item-title
            >
            <v-list-item-subtitle v-if="info_equipo.personal">{{
              info_equipo.ubicacion.ubicacion
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-overlay :value="loadingTable">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="buttonScan">
      <br /><br />
      <v-row align="center" justify="space-around">
        <v-btn tile color="success" @click="mostrarCamaraFunction">
          <v-icon style="color: black" left> mdi-qrcode-scan </v-icon>
          Escanear QR
        </v-btn>
      </v-row>
    </div>

    <qrcode-stream
      v-if="mostrarCamara"
      :camera="camera"
      @decode="onDecode"
      @init="onInit"
    >
      <div v-show="showScanConfirmation" class="scan-confirmation">
        <v-icon x-large color="blue">mdi-barcode-scan</v-icon>
      </div>
    </qrcode-stream>
    <br><br>
  </v-container>
</template>

<script>
import Catalogo from "../../api/Catalogo";

export default {
  data() {
    return {
      buttonScan: true,
      loadingTable: false,
      camera: "auto",
      result: null,
      showScanConfirmation: false,
      mostrarCamara: false,
      info_equipo: true,
    };
  },
  computed: {},
  created() {},
  methods: {
    async onInit(promise) {
      try {
        await promise;
      } catch (e) {
        console.error(e);
      } finally {
        this.showScanConfirmation = this.camera === "off";
      }
    },

    async onDecode(content) {
      this.result = content;
      this.pause();
      await this.timeout(500);
      this.mostrarCamara = false;
      this.buttonScan = true;
      this.mostrarActivo(this.result);
    },

    unpause() {
      this.camera = "auto";
    },

    pause() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    mostrarCamaraFunction() {
      this.info_equipo = [],
      (this.mostrarCamara = true), (this.buttonScan = false);
      this.camera = "auto";
    },

    async mostrarActivo(_id) {
      await Catalogo.queryIdActivo(_id).then((e) => {
        this.info_equipo = e.data;
      });
    },
    costoToDollar(text) {
      let number = parseFloat(text);
      return number.toLocaleString("en-US");
    },
  },
};
</script>
<style scoped>
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
</style>
