import Vue from "vue";
import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";

import App from "./App.vue";
import router from "./Routes";

import store from "./store/index";
import * as VueGoogleMaps from "vue2-google-maps";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Vuelidate from "vuelidate";
import axios from "axios";
import VuetifyMoney from "vuetify-money";
import VuePapaParse from "vue-papa-parse";
import JsonExcel from "vue-json-excel";
import VueExcelEditor from "vue-excel-editor";
import VueObserveVisibility from "vue-observe-visibility";
import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import VueQrcodeReader from "vue-qrcode-reader";

Vue.use(VueGoodTablePlugin);
Vue.use(VueObserveVisibility);
Vue.use(VueExcelEditor);
Vue.use(Toast);
Vue.use(VueQrcodeReader);

Vue.use(VuetifyMoney);
Vue.component("downloadExcel", JsonExcel);
const moment = require("moment");
require("moment/locale/es");
Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(VuePapaParse);
Vue.config.productionTip = false;

//axios.defaults.baseURL = "http://192.168.201.64:5001/api/";
//axios.defaults.baseURL = "http://localhost:5002/api/";
axios.defaults.baseURL = "https://mtto.cicsagruas.com/api/";

Vue.config.productionTip = false;
Vue.config.timezone = "America/Mexico_City";
Vue.use(Vuelidate);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg",
  },
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
