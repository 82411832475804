<template>
  <v-app-bar class="main-header" height="64" fixed color='primary' dark>
    <v-btn v-if="logueado" icon class="mx-1" @click.stop="TOGGLE_DRAWER">
      <template v-if="DRAWER_STATE">
        <v-icon style="font-size: 28px">mdi-menu</v-icon>
      </template>
      <template v-else>
        <v-icon style="font-size: 28px">mdi-menu</v-icon>
      </template>
    </v-btn>
    <v-toolbar-title v-if="logueado">
      <img src="https://i.ibb.co/HVvQJj5/logo-cicsa.png" class="mt-2" width="190" height="45" />
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <v-menu min-width="180" offset-y bottom left v-if="logueado" nudge-bottom="10">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mr-0" icon v-bind="attrs" v-on="on">
          <v-icon style="font-size: 28px" :color="config.light.iconColor">mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list>
        <div class="text-h5 grey--text text--darken-3 px-4 pt-4">Usuario</div>
        <div class="subtitle-2 primary--text font-weight-regular px-4">Correo</div>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in account" :key="i">
            <v-list-item-icon class="mr-4">
              <v-icon :color="item.color" v-text="item.icon">
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title :color='config.light.textColor' v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <div class="d-flex justify-center my-3">
          <v-btn width="80%" large outlined color="primary" class="text-capitalize" @click="logOut">Cerrar sesión
          </v-btn>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import config from '../../config';
export default {
  name: 'Header',

  data: () => ({
    config,
    searchCollapse: true,


    account: [
      { text: 'Perfil', icon: 'mdi-account', color: 'textColor' },

    ],
    notificationsBadge: true,
    messageBadge: true
  }),
  computed: {
    ...mapState(['drawer']),
    DRAWER_STATE: {
      get() {
        return this.drawer
      },
    },
    logueado() {
      return this.$store.state.usuario
    },
  },
  methods: {
    ...mapActions(['TOGGLE_DRAWER']),
    prueba() {
      this.$store.commit('setRuta', 'Dashboard');
    },

    logOut() {
      localStorage.removeItem('token')
      this.$store.dispatch("salir")
    }
  },
  created() {
    this.prueba()
  }
};
</script>

<style src="./Header.scss" lang="scss"></style>