<template>
  <v-container class="spacing-playground pa-6" fluid>
    <div class="tables-basic">
      <v-dialog v-model="dialogCorreos" max-width="400px">
        <v-card>
          <v-card-title>Ingrese los correos</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 200px">
            <br />
            <v-textarea
              outlined
              name="input-7-4"
              label="Ingrese los correos electronicos"
              v-model="correos"
            ></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="dialogCorreos = false">
              Cerrar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="enviarSolicitudArticulos(datosReporteFallaSeguimiento)"
            >
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <br />
      <h1 class="page-title mt-11 mb-6">Mecanicos</h1>
    </div
    >
    <v-row>
      <v-col class="col-sm-6">
        <v-select
          v-model="mecanico"
          @input="obtenerPinMecanico"
          :items="mecanicosActivos"
          label="Mecanicos"
        ></v-select>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogContrasenia" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 primary">
          <span style="color: white">Ingrese el PIN asignado</span>
        </v-card-title>
        <v-card-text
          ><br />
          <div class="ma-auto position-relative" style="max-width: 300px">
            <v-otp-input
              type="number"
              v-model="otp"
              :disabled="loadingPass"
              @finish="onFinish"
            ></v-otp-input>
            <v-overlay absolute :value="loadingPass">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </v-card-text>
        <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="1000">
          {{ text }}
        </v-snackbar>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cerrarDialogo"> Salir </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Traendo datos desde Microsip, espere porfavor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title class="primary white--text text-h5">Reportes</v-card-title>
      <v-row>
        <v-col cols="12" sm="3" v-if="asignados">
          <v-treeview :items="reportesAsignados" transition>
            <template v-slot:prepend="{ item }">
              <div v-if="!item.children">
                <v-col class="justify=space-between">
                  <v-icon
                    @click="descargarPdf(item)"
                    v-if="item.estatus == 'finalizado'"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                  <span class="pa-6" style="font-size: 14px"
                    >Folio {{ item.folio_falla }}</span
                  >
                  <v-chip
                    v-if="item.estatus == 'Asignado'"
                    small
                    color="success"
                    class="ma-2"
                  >
                    {{ item.estatus }}
                  </v-chip>
                  <v-chip
                    dark
                    v-if="item.estatus == 'borrador_asignado'"
                    small
                    color="orange"
                    class="ma-2"
                  >
                    Borrador
                  </v-chip>
                  <v-chip
                    dark
                    v-if="item.estatus == 'iniciado'"
                    small
                    color="blue"
                    class="ma-2"
                  >
                    Iniciado
                  </v-chip>
                  <v-chip
                    dark
                    v-if="item.estatus == 'finalizado'"
                    small
                    color="black"
                    class="ma-2"
                  >
                    Finalizado
                  </v-chip>
                  <v-btn
                    class="justify-"
                    @click="mostrarReporteFolio(item)"
                    icon
                    color="primary"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-divider></v-divider>
                </v-col>
              </div>
            </template>
          </v-treeview>
        </v-col>

        <v-divider vertical></v-divider>
        <v-col v-if="asignados" class="">
          <v-scroll-y-transition mode="out-in">
            <div
              v-if="textSeleeccVer"
              class="text-h6 grey--text text--lighten-1 font-weight-light"
              style="align-self: center"
            >
              Seleccionar reporte de falla
            </div>
            <div v-else class="pa-6 ma-2">
              <h4>Reporte</h4>
              <v-row>
                <v-col cols="12" lg="3" sm="6">
                  <v-text-field
                    :value="datosReporte.activo.numeroEconomico"
                    disabled
                    label="Numero Economico"
                    placeholder="Numero Economico"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <v-text-field
                    :value="datosReporte.activo.placa"
                    disabled
                    label="Placa"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <v-text-field
                    :value="datosReporte.activo.modelo"
                    disabled
                    label="Modelo"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <v-text-field
                    :value="datosReporte.activo.numeroSerie"
                    disabled
                    label="Numero de serie"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <v-textarea
                    label="Descripcion de la falla"
                    disabled
                    filled
                    :value="datosReporte.descripcionFalla"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" slg="3" sm="6">
                  <v-text-field
                    :value="datosReporte.tipoFalla"
                    disabled
                    label="Tipo de Falla"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <v-text-field
                    :value="datosReporte.operador"
                    disabled
                    label="Operador"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  sm="6"
                  v-if="datosReporte.usuarioCreador"
                >
                  <v-text-field
                    :value="datosReporte.usuarioCreador.nombreCompleto"
                    disabled
                    label="Solicitante"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea
                    v-model="diagnostico"
                    label="Diagnostico"
                    outlined
                  ></v-textarea>
                </v-col>
                <v-col cols="12" lg="3" sm="5">
                  <v-btn
                    @click="mostrarArticulosMicrosip"
                    small
                    depressed
                    color="primary"
                  >
                    Articulos de Microsip
                  </v-btn>
                </v-col>
                <v-col cols="12" lg="3" sm="5">
                  <v-autocomplete
                    :items="articulosMicrosip"
                    dense
                    v-model="articulos"
                    label="Articulos"
                    item-text="text"
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" lg="3" sm="2">
                  <v-btn
                    @click="agregarArticuloTableSelecc(articulos)"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-card>
                    <v-card-title>
                      <v-text-field
                        v-model="searchArticulos"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      :headers="headersArticulos"
                      :items="articulosAgregadados"
                      :search="searchArticulos"
                    >
                      <template v-slot:[`item.delete`]="{ item }">
                        <v-btn
                          @click="eliminarArticulo(item)"
                          icon
                          color="pink"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.cantidad`]="{ item }">
                        <v-text-field
                          type="number"
                          v-model="item.cantidad"
                          dense
                        ></v-text-field>
                      </template>
                      <template v-slot:[`item.articulo`]="{ item }">
                        <v-text-field
                          v-model="item.articulo"
                          dense
                        ></v-text-field>
                      </template>
                    </v-data-table> </v-card
                  ><br />
                  <v-btn
                    @click="dialogEnviarCorreo(datosReporte)"
                    small
                    depressed
                    color="warning"
                  >
                    Solicitar articulos
                  </v-btn>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-text-field label="Requisición" v-model="requisicion" dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea
                    v-model="procedimiento"
                    label="Procedimiento de Trabajo"
                    outlined
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea
                    v-model="observaciones"
                    label="Observaciones"
                    outlined
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-btn
                    @click="seguimientoFallas"
                    small
                    depressed
                    v-if="
                      estatus == 'Asignado' || estatus == 'borrador_asignado'
                    "
                    color="primary"
                  >
                    Guardar borrador
                  </v-btn>

                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        depressed
                        class="ma-2"
                        dark
                        v-if="
                          estatus == 'borrador_asignado' ||
                          estatus == 'Asignado'
                        "
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Fecha inicio de mtto
                      </v-btn>
                    </template>

                    <v-card>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >Fecha de inicio de
                              mantenimiento</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <v-divider></v-divider>

                      <v-list>
                        <v-list-item>
                          <v-list-item-title
                            ><v-text-field
                              v-model="fechaInicioMantenimiento"
                              type="datetime-local"
                            ></v-text-field
                          ></v-list-item-title>
                        </v-list-item>
                      </v-list>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn text @click="menu = false"> Cancel </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="iniciarMantenimiento"
                        >
                          Iniciar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>

                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        depressed
                        class="ma-2"
                        dark
                        v-if="estatus == 'iniciado'"
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Fecha fin de mtto
                      </v-btn>
                    </template>

                    <v-card>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >Fecha de fin de mantenimiento</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <v-divider></v-divider>

                      <v-list>
                        <v-list-item>
                          <v-list-item-title
                            ><v-text-field
                              v-model="fechaFinMantenimiento"
                              type="datetime-local"
                            ></v-text-field
                          ></v-list-item-title>
                        </v-list-item>
                      </v-list>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn text @click="menu2 = false"> Cancel </v-btn>
                        <v-btn color="primary" text @click="finalizar()">
                          Finalizar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>

                  <v-btn
                    @click="textSeleeccVer = true"
                    small
                    depressed
                    class="ma-2"
                    dark
                    color="primary"
                  >
                    Salir
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import axios from "axios";
import jsPDF from "jspdf";
import io from "socket.io-client";
import autoTable from "jspdf-autotable";


export default {
  name: "app",

  data() {
    return {
      headersArticulos: [
        { text: "Id", value: "idMicrosip" },
        { text: "Articulo", value: "articulo" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Eliminar", value: "delete" },
      ],
      dialogContrasenia: false,
      moment,
      searchArticulos: "",
      loadingPass: false,
      mecanicos: [],
      articulosAgregadados: [],
      mecanico: "",
      mostrarMecanico: false,
      dialogMecanicoBaja: false,
      mecanicosActivos: [],
      asignados: [],
      snackbar: false,
      snackbarColor: "default",
      otp: "",
      articulosMicrosip: [],
      datosReporte: [],
      text: "",
      pin: "",
      articulos: [],
      articulosPrueba: [
        {
          text: "Articulo 1",
          value: 1,
        },
        {
          text: "Articulo 2",
          value: 2,
        },
      ],
      observaciones: "",
      textSeleeccVer: true,
      expectedOtp: "123456",
      dialogLoading: false,
      diagnostico: "",
      procedimiento: "",
      id: "",
      estatus: "",
      dialogCorreos: false,
      datosReporteFallaSeguimiento: [],
      correos: "",
      menu: false,
      menu2: false,
      fechaInicioMantenimiento: "",
      fechaFinMantenimiento: "",
      reportesAsignados: [],
      requisicion:''
    };
  },
  computed: {},
  created() {
    this.selectMecanicos();
  },
  methods: {
    limpiar() {
      this.nombreMecanico = "";
      this.categoriaMecanico = "";
    },

    editItem(item) {
      this.editedIndex = true;
      this.drawer = true;
      this.id = item._id;
      this.nombreMecanico = item.nombreMecanico;
      this.categoriaMecanico = item.categoria;
    },

    mostrarNuevo() {
      this.drawer = true;
      this.editedIndex = false;
      this.limpiar();
    },
    ocultarNuevo() {},
    salir() {
      this.drawer = false;
    },

    dialogEnviarCorreo(item) {
      this.datosReporteFallaSeguimiento = item;
      console.log(item);
      this.dialogCorreos = true;
    },

    mostrarReporteFolio(item) {
      this.id = item._id;
      this.textSeleeccVer = false;

      this.datosReporte = item;
      this.articulosAgregadados = [];
      this.articulosAgregadados = item.articulos;
      this.diagnostico = item.diagnostico;
      this.procedimiento = item.procedimiento;
      this.observaciones = item.observaciones;
      this.estatus = item.estatus;
      this.requisicion = item.requisicion
    },

    cerrarDialogo() {
      this.mecanico = "";
      this.dialogContrasenia = false;
    },
    obtenerPinMecanico() {
      this.otp = "";
      axios
        .get("/mecanico/query?_id=" + this.mecanico)
        .then((response) => {
          this.dialogContrasenia = true;
          this.pin = response.data.pin.toString();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    agregarArticuloTableSelecc(item) {
      this.articulosAgregadados.push({
        idMicrosip: item.value,
        articulo: item.text,
      });
    },

    asignadoMecanico() {
      let me = this;
      this.reportesAsignados = []
      axios
        .get("/reporteFallas/listAsginados?asignado=" + this.mecanico)
        .then((response) => {
          me.asignados = response.data;
          me.reportesAsignados.push({
            id: 1,
            name: "Pendientes",
            children: [],
          });
          me.reportesAsignados.push({
            id: 2,
            name: "Atendidos",
            children: [],
          });
          response.data.forEach((e) => {
            if (e.estatus == "finalizado") {
              me.reportesAsignados[1].children.push(e);
            } else {
              me.reportesAsignados[0].children.push(e);
            }
          });
          console.log(me.reportesAsignados);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    seguimientoFallas() {
      let me = this;
      axios
        .put("/reporteFallas/atencion_falla", {
          id: this.id,
          diagnostico: this.diagnostico,
          procedimiento: this.procedimiento,
          observaciones: this.observaciones,
          articulos: this.articulosAgregadados,
          requisicion:this.requisicion
        })
        .then(function () {
          me.drawer = false;
          //  me.dialog=false
          me.textSeleeccVer = true;
          me.asignadoMecanico();
          me.addSuccessNotification("Reporte de falla atendido correctamente");
        })
        .catch(function (error) {
          console.log(error);
          me.addErrorNotification();
        });
    },

    iniciarMantenimiento() {
      let me = this;
      axios
        .put("/reporteFallas/iniciar_reporte", {
          id: this.id,
          diagnostico: this.diagnostico,
          procedimiento: this.procedimiento,
          observaciones: this.observaciones,
          articulos: this.articulosAgregadados,
          fecha: this.fechaInicioMantenimiento,
        })
        .then(function () {
          me.drawer = false;
          me.asignadoMecanico();
          //  me.dialog=false

          me.textSeleeccVer = true;
          me.textSeleeccVer = true;
          //const socket = io("https://cicsa.herokuapp.com/");
          const socket = io("https://cicsa.herokuapp.com/");
          socket.emit("reportesGenerados");
          me.addSuccessNotification("Reporte de falla iniciado correctamente");
        })
        .catch(function (error) {
          console.log(error);
          me.addErrorNotification();
        });
    },
    finalizar() {
      let me = this;
      axios
        .put("/reporteFallas/fin_reporte", {
          id: this.id,
          fechafin: this.fechaFinMantenimiento,
        })
        .then(function () {
          me.drawer = false;
          //  me.dialog=false+

          me.asignadoMecanico();
          me.textSeleeccVer = true;
          const socket = io("https://cicsa.herokuapp.com/");

          socket.emit("reportesGenerados");
          me.addSuccessNotification(
            "Reporte de falla finalizado correctamente"
          );
        })
        .catch(function (error) {
          console.log(error);
          me.addErrorNotification();
        });
    },

    eliminarArticulo(item) {
      let i = this.articulosAgregadados.indexOf(item);
      if (i != -1) {
        this.articulosAgregadados.splice(i, 1);
      }
    },

    mostrarArticulosMicrosip() {
      let me = this;
      let articulosArray = [];
      let token = process.env.VUE_APP_TOKEN_MICROSIP;
      let configuracion = {
        headers: {
          Authorization: `Bearer ` + token,
        },
      };

      this.dialogLoading = true;
      axios
        .get(
          "http://187.237.99.178:8180/api_microsip/cicsa/listaArticulosCicsa",
          configuracion
        )
        .then((response) => {
          console.log(response);
          articulosArray = response.data.data;
          articulosArray.map((x) => {
            me.articulosMicrosip.push({ text: x.NOMBRE, value: x.ARTICULO_ID });
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },

    onFinish(rsp) {
      this.loadingPass = true;
      setTimeout(() => {
        this.loadingPass = false;
        this.snackbarColor = rsp === this.pin ? "success" : "warning";
        this.text = `Pin incorrecto`;
        this.snackbar = true;
        if (this.pin === rsp) {
          this.addSuccessNotification("Pin correcto");
          this.asignadoMecanico();
          this.dialogContrasenia = false;
        }
      }, 500);
    },

    selectMecanicos() {
      let me = this;
      let mecaniccArray = [];
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/mecanico/listActivos", configuracion)
        .then((response) => {
          mecaniccArray = response.data;
          mecaniccArray.map(function (x) {
            me.mecanicosActivos.push({ text: x.nombreMecanico, value: x._id });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    descargarPdf(item) {
      var doc = new jsPDF("p", "pt", "a4");
      var startX = 40;
      var startY = 20;
      doc.addImage(
        "https://i.ibb.co/L5Lmq8d/Logotipo-CICSA.png",
        "JPEG",
        startX + 15,
        (startY += 5),
        80,
        60
      );

      doc.setFont(undefined, "bold");
      doc.setFontSize(7);
      let date = moment(new Date()).format("LLL");
      doc.text(date, (startX += 405), (startY += 25));
      doc.text("CORPORATIVO INDUSTRIAL Y COMERCIAL S.A. DE C.V.", 348, 67);
      doc.setFontSize(14);
      doc.setLineWidth(1.5);
      doc.text("Reporte de Fallas", 230, 110);
      doc.setFontSize(10);
      doc.text("Información del Solicitante", 55, 140);
      doc.setFontSize(8);
      doc.setLineWidth(1);
      doc.text("Nombre Completo:", 55, 165);
      doc.line(145, 167, 400, 167);
      doc.text("Correo:", 55, 178);
      doc.line(145, 180, 400, 180);
      doc.text("Telefono:", 55, 191);
      doc.line(145, 193, 400, 193);

      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      if (item.usuarioCreador) {
        doc.text(item.usuarioCreador.nombreCompleto, 150, 165);
        doc.text(item.usuarioCreador.correo, 150, 178);
        doc.text(item.usuarioCreador.telefono.toString(), 150, 191);
      }

      doc.setFont(undefined, "bold");
      doc.setFontSize(10);
      doc.text("Información del Equipo", 55, 216);
      doc.setFontSize(8);
      doc.setLineWidth(1);
      doc.text("Activo:", 55, 241);
      doc.line(145, 243, 300, 243);
      doc.text("Marca:", 55, 254);
      doc.line(145, 256, 300, 256);
      doc.text("Modelo:", 55, 267);
      doc.line(145, 269, 300, 269);
      doc.text("Numero de Serie:", 55, 280);
      doc.line(145, 282, 300, 282);

      doc.text("Placa:", 308, 241);
      doc.line(395, 243, 550, 243);
      doc.text("Estatus:", 308, 254);
      doc.line(395, 256, 550, 256);
      doc.text("Numero Economico:", 308, 267);
      doc.line(395, 269, 550, 269);
      doc.text("Ubicacion:", 308, 280);
      doc.line(395, 282, 550, 282);

      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      doc.text(item.activo.nombreActivo, 150, 241);
      doc.text(item.activo.marca, 150, 254);
      doc.text(item.activo.modelo, 150, 267);
      doc.text(item.activo.numeroSerie, 150, 280);

      doc.text(item.activo.placa, 403, 241);
      doc.text(item.activo.estatu, 403, 254);
      doc.text(item.activo.numeroEconomico, 403, 267);
      doc.text(item.activo.ubicacion.ubicacion, 403, 280);

      doc.setFont(undefined, "bold");
      doc.setFontSize(10);
      doc.text("Información del Repore de Falla", 55, 380);
      doc.setFontSize(8);
      doc.setLineWidth(1);
      doc.text("Estatus:", 55, 395);
      doc.line(145, 397, 300, 397);
      doc.text("Fecha Falla:", 55, 408);
      doc.line(145, 410, 300, 410);
      doc.text("Operador:", 55, 421);
      doc.line(145, 423, 300, 423);
      doc.text("Prioridad:", 55, 434);
      doc.line(145, 436, 300, 436);

      doc.text("Tipo de Falla:", 308, 395);
      doc.line(395, 397, 550, 397);
      doc.text("Folio de Falla:", 308, 408);
      doc.line(395, 410, 550, 410);
      doc.text("Fecha de Creacion:", 308, 421);
      doc.line(395, 423, 550, 423);
      doc.text("Mecanico asignado:", 308, 434);
      doc.line(395, 436, 550, 436);

      let fechaFalla = moment(new Date(item.fechaFalla)).format("LL");
      let createdAt = moment(new Date(item.createdAt)).format("LLL");

      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      doc.text(item.estatus, 150, 393);
      doc.text(fechaFalla, 150, 406);
      doc.text(item.operador, 150, 419);
      if(item.prioridad){
        doc.text(item.prioridad,150,432)
      }

      doc.text(item.tipoFalla, 403, 393);
      doc.text(item.folio_falla.toString(), 403, 406);
      doc.text(createdAt, 403, 419);
      if(item.asignado){
        doc.text(item.asignado.nombreMecanico,403,432)
      }
      doc.setFont(undefined, "bold");
      doc.setFontSize(10);
      doc.text("Descripción de la Falla", 55, 305);
      doc.rect(55, 315, 500, 40);
      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      doc.text(item.descripcionFalla, 58, 325);

      doc.setFont(undefined, "bold");
      doc.setFontSize(10);
      doc.text("Diagnostico", 55, 455);
      doc.rect(55, 465, 500, 40);
      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      if (item.diagnostico) {
        doc.text(item.diagnostico, 58, 475);
      }

      doc.setFont(undefined, "bold");
      doc.setFontSize(10);
      doc.text("Procedimiento de Trabajo", 55, 525);
      doc.rect(55, 535, 500, 40);
      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      if (item.procedimiento) {
        doc.text(item.procedimiento, 58, 545);
      }

      doc.setFont(undefined, "bold");
      doc.setFontSize(10);
      doc.text("Observaciones", 55, 595);
      doc.rect(55, 605, 500, 40);
      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      if (item.observaciones) {
        doc.text(item.observaciones, 58, 615);
      }

      let Y = 660;
      let X = 55;
      let rows = [];

      item.articulos.map((x) => {
        rows.push({
          idMicrosip: x.idMicrosip,
          articulo: x.articulo,
          cantidad: x.cantidad,
        });
      });
      autoTable(doc, {
        startY: Y,
        startX: X,
        head: [["Id", "Articulo", "Cantidad"]],
        body: rows,
        columns: [
          { header: "Id", dataKey: "idMicrosip" },
          { header: "Articulo", dataKey: "articulo" },
          { header: "Cantidad", dataKey: "cantidad" },
        ],
        headStyles: {
          fillColor: [243, 22, 22],
        },
      });

      doc.save("reporte de falla " + item.folio_falla.toString());
    },

    enviarSolicitudArticulos(item) {
      if (!this.diagnostico || item.articulos.length == 0) {
        this.addErrorNotification2("Ingrese el diagnostico para continuar");
        return;
      }
      var doc = new jsPDF("p", "pt", "a4");
      var startX = 40;
      var startY = 20;
      console.log(item);
      doc.addImage(
        "https://i.ibb.co/L5Lmq8d/Logotipo-CICSA.png",
        "JPEG",
        startX + 15,
        (startY += 5),
        80,
        60
      );

      doc.setFont(undefined, "bold");
      doc.setFontSize(7);
      let fecha = moment(new Date()).format("MMMM Do YYYY, h:mm:ss a");

      doc.text(fecha, (startX += 380), (startY += 25));
      doc.text("CORPORATIVO INDUSTRIAL Y COMERCIAL S.A. DE C.V.", 348, 67);
      doc.setFontSize(12);
      doc.setLineWidth(1.5);
      doc.text("Solicitud de Articulos", 230, 110);
      doc.setFontSize(8);
      doc.setLineWidth(1);
      doc.text("Articulos para equipo:", 55, 155);
      doc.text("Modelo del Equipo:", 55, 175);
      doc.text("Numero de Serie:", 55, 195);

      doc.text("Tipo de Falla:", 390, 155);
      doc.text("Operador:", 390, 175);
      doc.text("Solicitante:", 390, 195);
      doc.text("Falla del Equipo:", 55, 215);
      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      doc.text(item.activo.numeroEconomico, 150, 155);
      doc.text(item.activo.modelo, 150, 175);
      doc.text(item.activo.numeroSerie, 150, 195);
      doc.text(item.descripcionFalla, 150, 215);
      doc.text(item.tipoFalla, 450, 155);
      doc.text(item.operador, 450, 175);
      doc.text(item.asignado.nombreMecanico, 450, 195);
      doc.text(
        "Por medio de la presente solicito su amable apoyo para la recepción de los artículos solicitados en este formato, en caso de",
        55,
        255
      );
      doc.text(
        "no contar con los equipos solicitados favor de informar al jefe de almacén para realizar la requisición a estos artículos.",
        55,
        275
      );
      doc.setFont(undefined, "bold");
      doc.setFontSize(9);
      doc.text("Diagnostico", 55, 290);
      doc.rect(55, 295, 500, 40);
      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      doc.text(this.diagnostico, 58, 305);

      let Y = 360;
      let X = 55;
      let rows = [];

      item.articulos.map((x) => {
        rows.push({
          idMicrosip: x.idMicrosip,
          articulo: x.articulo,
          cantidad: x.cantidad,
        });
      });
      autoTable(doc, {
        startY: Y,
        startX: X,
        head: [["Id", "Articulo", "Cantidad"]],
        body: rows,
        columns: [
          { header: "Id", dataKey: "idMicrosip" },
          { header: "Articulo", dataKey: "articulo" },
          { header: "Cantidad", dataKey: "cantidad" },
        ],
        headStyles: {
          fillColor: [243, 22, 22],
        },
      });
      doc.setFont(undefined, "bold");
      doc.setFontSize(9);

      // doc.line(200,600,380,600,'F')
      doc.save("Prueba");
      const file = doc.output("datauristring");
      this.pruebaCorreo(file, item.asignado.nombreMecanico);
      this.datosReporteFallaSeguimiento = [];
      this.dialogCorreos = false;
    },

    pruebaCorreo(file, solicitante) {
      //let me = this;
      console.log("object");
      axios.post("/emails/sendEmail", {
        file: file,
        solicitante: solicitante,
        correos: this.correos,
      });
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification2(error) {
      this.$toast.error(error, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
};
</script>
<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.Revision {
  border-left: 4px solid #3cd1c2;
}
.sin_asignar {
  border-left: 4px solid orange;
}
.Pendiente {
  border-left: 4px solid black;
}
.Cancelado {
  border-left: 4px solid #f83e70;
}
.app_more {
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
}
.app_more:hover {
  text-decoration: underline;
}
.app_more,
.app_more:active {
  color: #3cd1c2;
}
.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid rgb(123, 90, 243);
  border-top-color: #fff;
  position: absolute;
  bottom: 0;
  left: calc(50%-20px);
  animation: spinner-animation 1.2s linear infinite;
}
.tebs.Revision {
  border-left: 4px solid orange;
}
.tebs.Finalizado {
  border-left: 4px solid rgb(123, 90, 243);
}
.v-chip.Activo {
  background: #f83e70;
  color: black;
}
#chips-container .v-chip.activo {
  color: white;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
