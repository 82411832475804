<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogFirmaMtto" max-width="600px">
      <v-card>
        <v-card-title>Firmar Orden de trabajo</v-card-title>
        <v-card-subtitle>Para firmar la solicitud deberá tener registrada su firma en la base de
          datos</v-card-subtitle>
        <v-divider></v-divider>

        <v-card-text>
          <div>
            <div style="margin-top: 15px;">
              <span style="font-size: 15px; font-weight: 400 ; color:black">Información del RF</span>
              <div class="">
                <span>Folio #{{ otSelected.folio_ot }}</span>
              </div>
            </div>
            <div style="margin-top: 15px;">
              <span style="font-size: 15px; font-weight: 400 ; color:black">Trabajos</span>
              <div v-if="otSelected.trabajos" class="">
                <span>{{ otSelected.trabajos }}</span>
              </div>
            </div>



            <div style="margin-top: 15px;">
              <span style="font-size: 15px; font-weight: 400 ; color:black">Equipo</span>
              <div v-if="otSelected.activo" class="">
                <span>{{ otSelected.activo.tipoUnidad }} {{ otSelected.activo.numeroEconomico }}</span>
              </div>
            </div>
            <div>

            </div>
            <div v-if="otSelected.firma_mtto" style="margin-top: 25px;">
              <span style="font-size: 15px; font-weight: 400 ; color:black">Firmado por:</span>
              <v-list>
                <v-list-item v-if="otSelected.firma_mtto.firmado">
                  <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                      mdi-account-check
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="font-size:15px">{{ otSelected.firma_mtto.full_name
                      }}</v-list-item-title>
                    <v-list-item-subtitle style="font-size: 10px;">{{
                      moment(otSelected.firma_mtto.fecha_firma).format('LLLL') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                </v-list-item>
              </v-list>
              <div v-if="!otSelected.firma_mtto.firmado">
                <p style="text-align: center; margin-top:40px; font-weight: bold;">
                  Solicitud pendiente de firmar</p>
                <v-btn color="primary" block @click="firmaMtto">
                  Firmar
                </v-btn>
              </div>
            </div>
          </div>

        </v-card-text>
        <v-divider></v-divider>

      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEstatusUnidad" max-width="500px">
      <v-card>
        <v-card-title class="texto">Cambiar estatus de unidad {{ ne_cambio_estatus }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 320px">
          <br />
          <p class="texto" style="font-size: 14px; color: #666; margin-bottom: 10px">
            Orden de Trabajo
          </p>
          <v-text-field outlined disabled name="input-7-4" dense v-model="reporte_cambio_estatus"></v-text-field>
          <p class="texto" style="font-size: 14px; color: #666; margin-bottom: 10px">
            Fecha de cambio de estatus
          </p>
          <v-text-field outlined type="datetime-local" name="input-7-4" dense
            v-model="fecha_cambio_estatus"></v-text-field>
          <p class="texto" style="font-size: 14px; color: #666; margin-bottom: 10px">
            La unidad <b>{{ ne_cambio_estatus }} </b> actualmente tiene el estatus de:
            <b> {{ estatus_cambio_estatus ? "Fuera de Servicio" : "Operativo" }}</b>
            decear cambiar su estatus a
            <b> {{ estatus_cambio_estatus ? "Operativo" : "Fuera de Servicio" }}</b> ?
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogEstatusUnidad = false">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text @click="cambiarEstatus()"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="bottomDialogAsignarRF">
      <v-sheet class="text-center" height="200px">
        <div class="ma-3"> <br>
          <span class="texto"> Asignar RF a OT</span>
        </div>

        <div class="py-3 pa-9">
          <v-row no-gutters>
            <v-col cols="2" sm="2" lg="2" md="2">
              <v-text-field disabled outlined dense v-model="orden_trabajo_selected" label="Folio OT"></v-text-field>
            </v-col>
            <v-col class="ml-4" cols="3" sm="3" lg="3" md="3">
              <v-autocomplete outlined return-object v-model="reporte_falla_selected" :items="folios_rf"
                menu-props="auto" dense small-chips label="Folio RF">

                <template v-slot:item="data">
                  <template>

                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      <v-list-item-subtitle>{{ data.item.descripcionFalla }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="1" sm="1" lg="1" md="1">
              <v-btn :loading="loading3" :disabled="loading3" color="blue-grey" class="white--text" @click="agregarRF">
                Guardar
                <v-icon right dark>
                  mdi-content-save
                </v-icon>
              </v-btn>
            </v-col>

          </v-row>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog transition="dialog-bottom-transition" max-width="750" v-model="dialogArticulosMicrosip">
      <v-card>
        <v-toolbar color="primary" dark>Articulos solicitados de Requsiciones</v-toolbar>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Folio</th>
                  <th class="text-left">Articulos</th>
                  <th class="text-left">Cantidad</th>
                  <th class="text-left">Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in articulos_microsip" :key="index">
                  <td style="font-size: 11px">{{ item.FOLIO }}</td>
                  <td style="font-size: 11px">{{ item.ARTICULO }}</td>
                  <td style="font-size: 11px; text-align: center">
                    {{ parseInt(item.CANTIDAD) }}
                  </td>
                  <td style="font-size: 11px; text-align: center">
                    <v-btn icon color="pink" @click="agregarArticuloRequi(item)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialogArticulosMicrosip = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLoader" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Espere por favor
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog transition="dialog-bottom-transition" max-width="900" v-model="dialogEvidencias">
      <v-card>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-toolbar color="primary" class="texto" style="font-size: 13px" dark>Adjunta las evidencias correspondientes al
          trabajo realizado</v-toolbar>
        <v-card-text>
          <br />
          <v-row>
            <v-col cols="6" sm="6" lg="6" md="6">
              <v-file-input v-model="evidencias" accept="image/*" label="Imagen"></v-file-input>
            </v-col>
            <v-col colw="6" sm="6" lg="6" md="6">
              <v-text-field v-model="evidencia_info" label="Informacion" required></v-text-field>
            </v-col>
          </v-row>


          <v-btn color="primary" @click="subirImagen" block> Subir </v-btn> <br>
          <v-carousel v-if="evidencias_imagenes.length > 0">
            <v-carousel-item v-for="(item, i) in evidencias_imagenes" :key="i"
              :src="item.url.split('upload')[0] + 'upload/c_thumb,w_400,g_face' + item.url.split('upload')[1]"
              reverse-transition="fade-transition" transition="fade-transition" class="carousel-item">
              <div>
                <p style="
                                                                        color: white;
                                                                        font-size: 14px;
                                                                        margin-top: 10px;
                                                                        background-color: black;
                                                                        padding: 10px;
                                                                        text-align: center;
                                                                      ">
                  {{ item.info }}
                  <v-btn icon>
                    <v-icon color="white lighten-1" @click="eliminarFotoEvidencia(item._id)">mdi-delete</v-icon>
                  </v-btn>
                </p>
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialogEvidencias = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" max-width="900" v-model="dialogDocEviedenciasOt">
      <v-card>
        <v-toolbar color="primary" class="texto" style="font-size: 13px" dark>Adjunta los documentos correspondientes al
          trabajo realizado</v-toolbar>
        <v-card-text>
          <br />
          <v-row no-gutters>
            <v-col cols="12" sm="12" lg="12" md="12">
              <v-file-input v-model="evidencias_doc" accept=".pdf" label="Documento"></v-file-input>
            </v-col>
            <v-col colw="12" sm="12" lg="12" md="12">
              <v-text-field v-model="evidencias_doc_info" label="Informacion" required></v-text-field>
            </v-col>
          </v-row>

          <v-btn block class="ma-2" :loading="loadingDoc" :disabled="loadingDoc" color="primary"
            @click="subirDocumento">
            Subir
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
          <v-list subheader two-line v-if="evidencias_docs">
            <v-list-item v-for="(doc, index) in evidencias_docs" :key="index">
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-file-cog-outline </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="texto" v-text="doc.info"></v-list-item-title>

                <v-list-item-subtitle class="texto">{{ moment(doc.createdAt).format("MMM Do YYYY, H:MM")
                  }}</v-list-item-subtitle>

              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1" @click="mostrarDocumento(doc.url)">mdi-download</v-icon>
                </v-btn>

              </v-list-item-action>

            </v-list-item>



          </v-list>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialogDocEviedenciasOt = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCerrarOt" max-width="590">
      <v-card>
        <v-card-title class="text-h5"> Guardar </v-card-title>
        <v-card-text style="text-align: justify">Está a punto de cerrar una orden de trabajo en el sistema de
          mantenimiento.
          Tenga en cuenta que una vez cerrada, no podrá ser modificada.<br />

          ¿Desea proceder con el cierre de la orden de trabajo?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="estatus !== 'En espera'" color="green darken-1" text @click="save_and_update('Normal')">
            Continuar
          </v-btn>
          <v-btn v-if="estatus !== 'En espera'" color="green darken-1" text @click="save_and_update('Cerrado')">
            Cerrar Orden de Trabajo
          </v-btn>
          <v-btn v-if="estatus == 'En espera'" color="orange darken-1" text @click="save_and_update('En espera')">
            Continuar en espera de refacciones
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCorreos" max-width="400px">
      <v-card>
        <v-card-title>Ingrese los correos</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 200px">
          <br />
          <v-textarea outlined name="input-7-4" label="Ingrese los correos electronicos" v-model="correos"></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogCorreos = false">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text @click="enviarEmailReporteFallas">
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPauseOt" max-width="400px">
      <v-card>
        <v-card-title>Pausar mantenimiento</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 200px">
          <br />
          <v-textarea outlined name="input-7-4" label="Ingrese el motivo" v-model="motivo_pause"></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogPauseOt = false"> Salir </v-btn>
          <v-btn color="blue darken-1" text @click="pauseOrdenTrabajo"> Pausar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTiempos" width="600">
      <v-card class="mx-auto" max-width="600">
        <v-card dark flat>
          <v-card-title class="pa-3 primary">
            <span class="text-h6 font-weight-light text-center grow">Linea de Tiempo</span>
            <v-btn @click="dialogTiempos = false" icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>

        <v-card-text class="py-0">
          <br />
          <v-row class="pt-1">
            <v-col cols="6">
              <div style="color: black" class="subheading font-weight-light">
                Tiempo de trabajo del mantenimiento
              </div>
              <v-icon class="mr-2" small> mdi-clock </v-icon>
              <span class="text-caption grey--text font-weight-light">{{
                `${tiempo_ejecucion_mtto.dias} días ${tiempo_ejecucion_mtto.horas} horas
                ${tiempo_ejecucion_mtto.minutos}
                minutos`
              }}</span>
            </v-col>
            <v-col cols="6">
              <div style="color: black" class="subheading font-weight-light">
                Tiempo de espera
              </div>
              <v-icon class="mr-2" small> mdi-clock </v-icon>
              <span class="text-caption grey--text font-weight-light">{{
                `${tiempo_en_espera.dias} días ${tiempo_en_espera.horas} horas ${tiempo_en_espera.minutos} minutos `
              }}</span>
            </v-col> </v-row><br />
          <v-divider></v-divider><br />

          <v-timeline align-top dense v-for="(item, index) in tiemposTimeline.tiempos" :key="index">
            <v-timeline-item color="green" v-if="item.tipo == 'En progreso' || item.tipo == 'En curso'" icon="mdi-play">
              <v-row class="pt-1">
                <v-col cols="12">
                  <div class="timeline-content">
                    <div class="timeline-details">
                      <strong class="timeline-type">{{ item.tipo }}</strong>

                      <div class="timeline-time">
                        <div style="color: black" class="subheading font-weight-light">
                          {{ moment(item.tiempo).format("L") }}
                          {{ moment(item.tiempo).format("h:mm a") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item color="orange" icon="mdi-pause" v-if="item.tipo !== 'En progreso' &&
              item.tipo !== 'En curso' &&
              item.tipo !== 'Completado'
            ">
              <v-row class="pt-1">
                <v-col cols="12">
                  <div class="timeline-content">
                    <strong class="timeline-type">{{ item.tipo }}</strong>

                    <div class="timeline-time">
                      <div style="color: black" class="subheading font-weight-light">
                        {{ moment(item.tiempo).format("L") }}
                        {{ moment(item.tiempo).format("h:mm a") }}
                      </div>
                    </div>
                    <span class="text-caption grey--text font-weight-light">{{
                      item.motivo
                    }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item color="blue" icon="mdi-check-bold" v-if="item.tipo == 'Completado'">
              <v-row class="pt-1">
                <v-col cols="12">
                  <div class="timeline-content">
                    <strong class="timeline-type">{{ item.tipo }}</strong>

                    <div class="timeline-time">
                      <div style="color: black" class="subheading font-weight-light">
                        {{ moment(item.tiempo).format("L") }}
                        {{ moment(item.tiempo).format("h:mm a") }}
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog transition="dialog-bottom-transition" v-model="dialogOrdenServicio">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogOrdenServicio = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Orden de trabajo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="mostrarDialogCerrarOt"> Guardar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-stepper v-model="e6" vertical>
          <v-stepper-step :complete="e6 > 1" step="1">
            <span style="font-weight: bold; font-size: 1.2em">Información del Equipo</span>
            <small style="font-style: italic; color: #999999">Todos los datos son requeridos</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card color="grey lighten-5 " style="border-radius: 20px" class="mb-12">
              <v-row class="pa-1 ma-2">
                <v-col cols="12" sm="12">
                  <small style="font-style: italic; color: #999999">{{ trabajo }}</small>
                </v-col>

                <v-col cols="12" lg="3" sm="3">
                  <p class="texto" style="font-size: 14px; color: #666666; font-weight: bold">
                    Numero Económico
                  </p>
                  <v-autocomplete v-model="numeroEconomicoBusqueda" :items="activosSelect" menu-props="auto"
                    @input="datosEquipo" dense small-chips solo>
                    <template v-slot:selection="data">
                      <v-chip :input-value="data.text">
                        <v-avatar left>
                          <v-icon color="green" v-if="data.item.estatus == 'Operativo'">
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon color="orange" v-if="data.item.estatus == 'Pendiente'">
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon color="red" v-if="data.item.estatus == 'Fuera de Servicio'">
                            mdi-checkbox-blank-circle
                          </v-icon>
                        </v-avatar>
                        {{ data.item.text }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-avatar>
                          <v-icon color="green" v-if="data.item.estatus == 'Operativo'">
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon color="orange" v-if="data.item.estatus == 'Pendiente'">
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon color="red" v-if="data.item.estatus == 'Fuera de Servicio'">
                            mdi-checkbox-blank-circle
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            data.item.estatus
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" lg="5" sm="5">
                  <p class="texto" style="font-size: 14px; color: #666666; font-weight: bold">
                    Descripción del equipo
                  </p>
                  <v-text-field disabled v-model="descripcion_equipo" dense solo></v-text-field>
                </v-col>

                <v-col cols="12" lg="2" sm="2">
                  <p class="texto" style="font-size: 14px; color: #666666; font-weight: bold">
                    Tipo de Atención
                  </p>
                  <v-autocomplete :items="tipoDeFallas" dense v-model="tipoFalla" solo></v-autocomplete>
                </v-col>
                <v-col cols="12" lg="2" sm="2">
                  <p class="texto" style="font-size: 14px; color: #666666; font-weight: bold">
                    Tipo de Intervención
                  </p>
                  <v-autocomplete :items="[
                    'Preditiva',
                    'Preventiva',
                    'Correctiva Programada',
                    'Correctiva',
                  ]" dense v-model="intervencion" solo></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12">
                  <p class="text" style="
                                                                          text-align: center;
                                                                          font-family: Arial, sans-serif;
                                                                          font-size: 14px;
                                                                          color: #666666;
                                                                          font-weight: bold;
                                                                        ">
                    Ubicación
                  </p>
                  <v-text-field v-model="ubicacion" dense solo></v-text-field>
                </v-col>

                <v-col cols="6">
                  <!-- <v-radio-group v-model="estatus_unidad" row>
                    <v-radio class="texto" label="Fuera de Servicio" value="Fuera de Servicio"></v-radio>
                    <v-radio class="texto" label="Operativo" value="Operativo"></v-radio>
                  </v-radio-group> -->
                </v-col>
              </v-row>
            </v-card>
            <v-btn color="primary" dense @click="e6 = 2"> Continuar </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 2" step="2">
            <span style="font-weight: bold; font-size: 1.2em">Información del responsable y tiempos</span>
            <small style="font-style: italic; color: #999999">Todos los datos son requeridos</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card color="grey lighten-5" style="border-radius: 20px" class="mb-12">
              <v-row class="ma-2">
                <v-col cols="12" sm="3" lg="3">
                  <p class="texto" style="font-size: 14px; color: #666666; font-weight: bold">
                    Hora de llegada
                  </p>
                  <v-text-field format="YYYY-MM-DDTHH:mm:ss" type="datetime-local" dense v-model="hora_apertura"
                    solo></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" lg="3">
                  <p class="texto" style="font-size: 14px; color: #666666; font-weight: bold">
                    Hora de salida
                  </p>
                  <v-text-field format="YYYY-MM-DDTHH:mm:ss" type="datetime-local" dense v-model="hora_vencimiento"
                    solo></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" sm="6">
                  <p class="texto" style="font-size: 14px; color: #666666; font-weight: bold">
                    Tecnico
                  </p>
                  <v-autocomplete multiple dense chips small-chips solo v-model="mecanico"
                    :items="mecanicosActivos"></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 3"> Continuar </v-btn>
            <v-btn text @click="e6 = 1"> Regresar </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 3" step="3">
            <span style="font-weight: bold; font-size: 1.2em">Refacciones usadas</span>
            <small style="font-style: italic; color: #999999">Todos los datos son requeridos</small>
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card color="grey lighten-5" class="mb-12">
              <v-row class="pa-6 ma-2">
                <v-col cols="10" sm="10">
                  <v-autocomplete v-model="requisiciones" :items="requisiciones_mtto" outlined chips dense
                    color="blue-grey lighten-2" label="Requisiciones solicitadas" item-text="TEXT" item-value="FOLIO"
                    multiple>
                    <template v-slot:selection="data">
                      <v-chip v-bind="data.attrs" :input-value="data.selected" close small @click="data.select"
                        @click:close="remove(data.item)">
                        {{ data.item.FOLIO }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px; font-weight: bold">{{
                            data.item.FOLIO
                          }}</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px">{{
                            data.item.DESCRIPCION
                          }}</v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 12px; font-style: italic">{{ data.item.USUARIO
                            }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="2" sm="2">
                  <v-btn dense @click="buscarRefacciones" color="primary">
                    Mostrar refacciones
                  </v-btn>
                </v-col>
                <v-col cols="2" sm="2">
                  <v-btn @click="agregarArticulo" small color="primary">
                    Agregar Refacción
                  </v-btn>
                </v-col>
                <v-col cols="2" sm="2">
                  <v-btn @click="agregarRefaccionRequisicones" small color="grey" dark>
                    Agregar Refacción de Requisiciones
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-data-table dense :headers="headers" :items="articulos">
                    <template v-slot:[`item.descripcion`]="{ item }">
                      <v-edit-dialog :return-value.sync="item.descripcion" @save="saveDescripcion(item)"
                        @cancel="cancel" large persistent>
                        <span style="
                                                                                font-family: Arial, sans-serif;
                                                                                font-size: 14px;
                                                                                color: #666666;
                                                                                font-style: italic;
                                                                              ">
                          {{ item.descripcion }}
                        </span>

                        <template v-slot:input>
                          <!-- <v-text-field
                            v-model="item.descripcion"
                            label="Ingresa los datos necesarios"
                            single-line
                            counter
                          ></v-text-field> -->
                          <div class="mt-4 text-h6">Agregar refaccion</div>
                          <v-combobox v-model="item.descripcion" :items="refacciones" item-text="descripcion"
                            item-value="descripcion" :search-input.sync="searchRefacciones" return-object small-chips>
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    No hay resultados que coincidan con "<strong>{{
                                      searchRefacciones
                                    }}</strong>". Presiona <kbd>enter</kbd> para crear uno nuevo
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-combobox>
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template v-slot:[`item.no_parte`]="{ item }">
                      <v-edit-dialog :return-value.sync="item.no_parte" @save="save" @cancel="cancel" @close="close">
                        <span style="
                                                                                font-family: Arial, sans-serif;
                                                                                font-size: 14px;
                                                                                color: #666666;
                                                                                font-style: italic;
                                                                              ">
                          {{ item.no_parte }}
                        </span>
                        <template v-slot:input>
                          <v-text-field v-model="item.no_parte" label="Ingresa los datos necesarios" single-line
                            counter></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template v-slot:[`item.cantidad`]="{ item }">
                      <v-edit-dialog :return-value.sync="item.cantidad" @save="save" @cancel="cancel" @close="close">
                        <span style="
                                                                                font-family: Arial, sans-serif;
                                                                                font-size: 14px;
                                                                                color: #666666;
                                                                                font-style: italic;
                                                                              ">
                          {{ item.cantidad }}
                        </span>
                        <template v-slot:input>
                          <v-text-field v-model="item.cantidad" label="Ingresa los datos necesarios" single-line
                            counter></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template v-slot:[`item.observaciones`]="{ item }">
                      <v-edit-dialog :return-value.sync="item.observaciones" @save="save" @cancel="cancel"
                        @close="close">
                        <span style="
                                                                                font-family: Arial, sans-serif;
                                                                                font-size: 14px;
                                                                                color: #666666;
                                                                                font-style: italic;
                                                                              ">
                          {{ item.observaciones }}
                        </span>
                        <template v-slot:input>
                          <v-text-field v-model="item.observaciones" label="Ingresa los datos necesarios" single-line
                            counter></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                    <!-- Otros campos del formulario -->
                    <template v-slot:[`item.eliminar`]="{ item }">
                      <v-btn @click="eliminarArticulo(item)" icon color="pink">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 4"> Continue </v-btn>
            <v-btn text @click="e6 = 2"> Regresar </v-btn>
          </v-stepper-content>

          <v-stepper-step step="4">
            <span style="font-weight: bold; font-size: 1.2em">Prodecimiento y Observaciones</span>
            <small style="font-style: italic; color: #999999">Todos los datos son requeridos</small>
          </v-stepper-step>
          <v-stepper-content step="4">
            <v-card color="grey lighten-5" class="mb-12">
              <v-row class="ma-2">
                <v-col cols="12" lg="4" sm="4">
                  <p class="text" style="
                                                                          font-family: Arial, sans-serif;
                                                                          font-size: 14px;
                                                                          color: #666666;
                                                                          font-style: italic;
                                                                        ">
                    Procedimiento de Trabajo
                  </p>
                  <v-textarea v-model="procedimiento_trabajo" dense solo></v-textarea>
                </v-col>
                <v-col cols="12" lg="4" sm="4">
                  <p class="text" style="
                                                                          font-family: Arial, sans-serif;
                                                                          font-size: 14px;
                                                                          color: #666666;
                                                                          font-style: italic;
                                                                        ">
                    Observaciones
                  </p>
                  <v-textarea v-model="observaciones" dense solo></v-textarea>
                </v-col>
                <v-col cols="12" lg="4" sm="4">
                  <p class="text" style="
                                                                          font-family: Arial, sans-serif;
                                                                          font-size: 14px;
                                                                          color: #666666;
                                                                          font-style: italic;
                                                                        ">
                    Trabajo a realizar
                  </p>
                  <v-textarea v-model="trabajo_realizar" dense solo></v-textarea>
                </v-col>
              </v-row>
              <v-btn text @click="e6 = 3"> Regresar </v-btn>
            </v-card>
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-dialog max-width="900px" transition="dialog-bottom-transition" v-model="dialogGuiaRequisicion">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogGuiaRequisicion = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Guia de seguimiento</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-timeline clipped class="ma-5">
            <v-timeline-item color="blue" small>
              <template v-slot:opposite>
                <span class="`headline font-weight-bold red--text`">{{
                  seguimiento_requisiciones.FECHA_CREACION_REQUI
                }}</span>
              </template>
              <div class="py-4">
                <h3 class="`headline font-weight-light mb-4 $red--text`">
                  {{ seguimiento_requisiciones.FOLIO }}
                </h3>
                <h6 class="`headline font-weight-light `">
                  {{ seguimiento_requisiciones.DEPARTAMENTO }}
                  <v-chip v-if="seguimiento_requisiciones.ESTATUS == 'N'" class="ma-2" color="blue" small outlined>
                    Normal
                  </v-chip>
                  <v-chip v-if="seguimiento_requisiciones.ESTATUS == 'A'" class="ma-2" color="green" small outlined>
                    Cerrada
                  </v-chip>
                  <v-chip v-if="seguimiento_requisiciones.ESTATUS == 'C'" class="ma-2" color="red" small outlined>
                    Cerrada
                  </v-chip>
                </h6>
                <div>
                  <span class="texto" style="font-size: 12px" v-if="seguimiento_requisiciones.DESCRIPCION_REQUI">
                    {{ seguimiento_requisiciones.DESCRIPCION_REQUI }} </span><br />
                  <span class="texto" style="font-size: 12px" v-if="seguimiento_requisiciones.AUT1">
                    Autorización 1:
                    <b>{{ seguimiento_requisiciones.AUT1 }} el
                      {{ seguimiento_requisiciones.FECHA_AUTORIZACIO_1 }}
                    </b>
                  </span>
                  <br />
                  <span class="texto" style="font-size: 12px" v-if="seguimiento_requisiciones.AUT1">
                    Autorización 2:
                    <b>{{ seguimiento_requisiciones.AUT2 }} el
                      {{ seguimiento_requisiciones.FECHA_AUTORIZACION_2 }}
                    </b>
                  </span>
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item color="green" small>
              <template v-slot:opposite>
                <span class="`headline font-weight-bold red--text`">{{
                  seguimiento_requisiciones.FECHA_CREACION_COTI
                }}</span>
              </template>
              <div class="py-4">
                <h3 class="`headline font-weight-light mb-4 $red--text`">
                  {{ seguimiento_requisiciones.FOLIO_COTI }}
                </h3>
                <h6 class="`headline font-weight-light `">
                  {{ seguimiento_requisiciones.USUARIO_CREADOR }}
                  <v-chip v-if="seguimiento_requisiciones.ESTATUS_COTI == 'N'" class="ma-2" color="blue" small outlined>
                    Normal
                  </v-chip>
                  <v-chip v-if="seguimiento_requisiciones.ESTATUS_COTI == 'A'" class="ma-2" color="green" small
                    outlined>
                    Cerrada
                  </v-chip>
                  <v-chip v-if="seguimiento_requisiciones.ESTATUS_COTI == 'C'" class="ma-2" color="red" small outlined>
                    Cerrada
                  </v-chip>
                </h6>
                <div>
                  <span class="texto" style="font-size: 12px" v-if="seguimiento_requisiciones.DESCRIPCION_COTI">
                    {{ seguimiento_requisiciones.DESCRIPCION_COTI }} </span><br />
                  <span class="texto" style="font-size: 12px" v-if="seguimiento_requisiciones.COT_AUT_1">
                    Autorización 1:
                    <b>{{ seguimiento_requisiciones.COT_AUT_1 }} el
                      {{ seguimiento_requisiciones.FECHA_AUT_COT_1 }}
                    </b>
                  </span>
                  <br />
                  <span class="texto" style="font-size: 12px" v-if="seguimiento_requisiciones.AUT2">
                    Autorización 2:
                    <b>{{ seguimiento_requisiciones.AUT2 }} el
                      {{ seguimiento_requisiciones.FECHA_AUT_COT_2 }}
                    </b>
                  </span>
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item color="orange" small>
              <template v-slot:opposite>
                <span class="`headline font-weight-bold red--text`">{{
                  seguimiento_requisiciones.FECHA_CREACION_COMPRA_FINAL
                }}</span>
              </template>
              <div class="py-4">
                <h3 class="`headline font-weight-light mb-4 $red--text`">
                  {{ seguimiento_requisiciones.FOLIO_COMPRA_FINAL }}
                </h3>
                <h6 class="`headline font-weight-light `">
                  {{ seguimiento_requisiciones.USUARIO_CREADOR_COMPRA_FINAL }}
                </h6>
                <div>
                  <span class="texto" style="font-size: 12px" v-if="seguimiento_requisiciones.DESCRIPCION_COMPRA_FINAL">
                    {{ seguimiento_requisiciones.DESCRIPCION_COMPRA_FINAL }} </span><br />
                  <span class="texto" style="font-size: 12px" v-if="seguimiento_requisiciones.ALMACEN_COMPRA_FINAL">
                    Almacen:
                    <b>{{ seguimiento_requisiciones.ALMACEN_COMPRA_FINAL }} </b>
                  </span>
                  <br />
                  <span class="texto" style="font-size: 12px" v-if="seguimiento_requisiciones.FECHA_COMPRA_FINAL">
                    Fecha : <b>{{ seguimiento_requisiciones.FECHA_COMPRA_FINAL }} </b>
                  </span>
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogGenerarReporteFotografico" scrollable max-width="700px">
      <v-card>
        <v-card-title class="texto">Generar Reporte Fotograficos</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <br />
          <v-row no-gutters>
            <v-col cols="6" sm="6" lg="6" md="6">
              <span class="ma-5 texto">Numero Economico:</span>
              <v-autocomplete return-object v-model="numeroEconomicoReporte" :items="activosSelect" menu-props="auto"
                dense small-chips solo>
                <template v-slot:selection="data">
                  <v-chip :input-value="data.text">
                    <v-avatar left>
                      <v-icon color="green" v-if="data.item.estatus == 'Operativo'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <v-icon color="orange" v-if="data.item.estatus == 'Pendiente'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <v-icon color="red" v-if="data.item.estatus == 'Fuera de Servicio'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                    </v-avatar>
                    {{ data.item.text }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar>
                      <v-icon color="green" v-if="data.item.estatus == 'Operativo'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <v-icon color="orange" v-if="data.item.estatus == 'Pendiente'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <v-icon color="red" v-if="data.item.estatus == 'Fuera de Servicio'">
                        mdi-checkbox-blank-circle
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      <v-list-item-subtitle>{{ data.item.estatus }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col colw="6" sm="6" lg="6" md="6">
              <span class="ma-5 texto">Semana :</span>
              <v-text-field class="ml-5 texto" dense solo v-model="semanaReporte"></v-text-field>
            </v-col>

            <v-col colw="6" sm="6" lg="6" md="6">
              <span class="texto">Inicio :</span>
              <v-text-field type="date" class="texto" dense solo v-model="fechaInicioReporte"></v-text-field>
            </v-col>

            <v-col colw="6" sm="6" lg="6" md="6">
              <span class="ma-5 texto">Fin :</span>
              <v-text-field type="date" class="ml-5 texto" dense solo v-model="fechaFinReporte"></v-text-field>
            </v-col>
            <v-btn @click="mostrarImagenesOtFechas" color="primary" block>
              Mostrar imagenes de OT
            </v-btn>
          </v-row>

          <v-list v-if="ot_evidencias_reporte.length > 0" two-line>
            <v-list-item-group v-model="selected" active-class="green--text" multiple>
              <template>
                <v-list-item v-for="(item, index) in ot_evidencias_reporte" :key="index">
                  <template v-slot:default="{}">
                    <v-list-item-content>
                      <v-list-item-title class="texto">{{ item.info }}</v-list-item-title>
                      <v-list-item-subtitle class="text--primary texto">OT- {{ item.folio
                        }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-img max-width="100" :src="item.url"></v-img>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogGenerarReporteFotografico = false">
            Cerrar
          </v-btn>
          <v-btn @click="generarReporteFotografico()" color="blue darken-1" text>
            Generar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent scrollable v-model="dialogSeguimientoReporteFalla" width="1100" height="800">
      <v-card>
        <v-card-title class="texto"><b> R-{{ reporte_falla_seguimiento.folio_falla }}</b>
        </v-card-title>
        <v-divider></v-divider> <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="5" sm="5">
              <div v-if="reporte_falla_seguimiento.activo">
                <v-img v-if="reporte_falla_seguimiento.activo.avatar" :src="reporte_falla_seguimiento.activo.avatar">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-2"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <div v-else :src="reporte_falla_seguimiento.activo.fotos[0]">
                  <v-img v-if="reporte_falla_seguimiento.activo.fotos.length == 0"
                    src="https://res.cloudinary.com/cicsa/image/upload/v1669661111/logos/jgb0ztkvoromgyjcv5zk.jpg">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-2"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </div>
            </v-col>
            <v-col cols="12" lg="7" sm="7">
              <v-row>
                <v-col cols="12" lg="12" sm="12">
                  <h1 class="texto" style="font-weight: bold; font-size: 15px; text-align: center">
                    Información del RF
                  </h1>
                </v-col>
                <v-expansion-panels style="border-radius: 15px" accordion focusable>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <p class="texto" style="font-weight: bold; font-size: 13px; text-align: center">
                        RF-{{ reporte_falla_seguimiento.folio_falla }} /
                        {{ reporte_falla_seguimiento.estatus }}
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" lg="6" sm="12">
                          <span class="texto" style="font-weight: bold; font-size: 12px">Folio</span>
                          <div class="input-filter_seguimiento">
                            <span class="texto ml-3" style="font-size: 12px">
                              <b>{{ reporte_falla_seguimiento.folio_falla }}</b>
                            </span>
                          </div>
                        </v-col>
                        <v-col cols="12" lg="6" sm="12">
                          <span class="texto" style="font-weight: bold; font-size: 12px">Numero Economico</span>
                          <div class="input-filter_seguimiento" style="font-size: 12px"
                            v-if="reporte_falla_seguimiento.activo">
                            <span class="texto ml-3">
                              <b>{{
                                reporte_falla_seguimiento.activo.numeroEconomico
                              }}</b>
                            </span>
                          </div>
                        </v-col>
                        <v-col cols="12" lg="12" sm="12">
                          <span class="texto" style="font-weight: bold; font-size: 12px">Falla:</span>
                          <div class="">
                            <span class="texto ml-3" style="font-size: 12px">
                              <b>{{ reporte_falla_seguimiento.descripcionFalla }}</b>
                            </span>
                          </div>
                        </v-col>

                        <v-col cols="12" lg="12" sm="12" v-if="reporte_falla_seguimiento.mecanico">
                          <span class="texto" style="font-weight: bold; font-size: 12px">Mecanicos asignados para
                            diagnostico:</span>
                          <div class="texto">
                            <v-chip class="ma-2" color="indigo" text-color="white" small
                              v-for="(item, index) in reporte_falla_seguimiento.mecanico" :key="index">
                              <v-avatar left>
                                <v-icon>mdi-account</v-icon>
                              </v-avatar>
                              {{ item }}
                            </v-chip>
                          </div>
                        </v-col>

                        <v-col cols="12" lg="12" sm="12" v-if="reporte_falla_seguimiento.fechaAsignacion">
                          <span class="texto" style="font-weight: bold; font-size: 12px">Fecha de Asignación</span>
                          <div class="input-filter_seguimiento" style="font-size: 12px">
                            <span class="ml-3">
                              <b>{{
                                moment(reporte_falla_seguimiento.fechaAsignacion)
                                  .add(1, "days")
                                  .format("Do MMMM YYYY")
                              }}</b>
                            </span>
                          </div>
                        </v-col>
                        <v-col cols="12" lg="12" sm="12" v-if="reporte_falla_seguimiento.diagnostico">
                          <span class="texto" style="font-weight: bold; font-size: 12px">Diagnostico:</span>
                          <div class="">
                            <span class="texto ml-3" style="font-size: 13px; text-align: center">
                              <b>{{ reporte_falla_seguimiento.diagnostico }}</b>
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
              <br />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogSeguimientoReporteFalla = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <div>
        <div class="pa-5">
          <div class="mt-15">
            <h3 class="texto">Ordenes de Trabajo</h3>

            <span class="texto" style="font-size: 12px">En la siguiente tabla se muestran todas las ordenes de trabajo
              generadas en
              el sistema:</span>
            <br />
            <v-row no-gutters>
              <v-col cols="12" sm="6" md="8">
                <div class="input-filter mt-5">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" color="primary" class="mb-2">
                        <v-icon>mdi-filter-plus</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item dense @click="showFilterFolio = true">
                        <v-list-item-title>
                          <span class="texto">Folio</span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item dense @click="showFilterNumeroEconomico = true">
                        <v-list-item-title>
                          <span class="texto">Numero Economico</span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item dense @click="showFilterFecha = true">
                        <v-list-item-title>
                          <span class="texto">Fecha</span></v-list-item-title>
                      </v-list-item>
                      <v-list-item dense @click="showFilterTipoFalla = true">
                        <v-list-item-title>
                          <span class="texto">Tipo de falla</span></v-list-item-title>
                      </v-list-item>
                      <v-list-item dense @click="showFilterReporteFalla = true">
                        <v-list-item-title>
                          <span class="texto">Reporte de falla</span></v-list-item-title>
                      </v-list-item>
                      <v-list-item dense @click="showFilterTipoUnidad = true">
                        <v-list-item-title> <span class="texto">Tipo de Unidad</span></v-list-item-title>
                      </v-list-item>
                      <v-list-item dense @click="showFilterestatus = true">
                        <v-list-item-title>
                          <span class="texto">Estatus</span></v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <div v-if="arr.length == 0" class="filter-label">
                    <span>Ningun filtro seleccionado...</span>
                  </div>
                  <div v-else class="filter-chips">
                    <v-chip class="ml-2" v-for="(item, index) in arr" :key="index" close close-icon="mdi-close-circle"
                      @click:close="eliminarFiltro(index)" color="primary" small>
                      {{ item.label }}
                    </v-chip>
                  </div>

                  <v-dialog v-model="showFilterNumeroEconomico" transition="dialog-bottom-transition" max-width="350">
                    <v-card>
                      <v-toolbar color="primary" dark>Busqueda por Numero Economico</v-toolbar>
                      <v-card-text class="mt-10">
                        <v-autocomplete return-object v-model="numero_economico_filter" outlined dense
                          prepend-icon="mdi-pier-crane" :items="activosSelect" label="Equipo" append-icon="mdi-search">
                        </v-autocomplete>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="filtroLabelPush('activo')">Aplicar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="showFilterFolio" transition="dialog-bottom-transition" max-width="350">
                    <v-card>
                      <v-toolbar color="primary" dark>Busqueda por Folio</v-toolbar>
                      <v-card-text class="mt-10">
                        <v-text-field v-model="folio_filter" outlined dense label="Ingrese el folio"></v-text-field>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="filtroLabelPush('folio_ot')">Aplicar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="showFilterTipoFalla" transition="dialog-bottom-transition" max-width="350">
                    <v-card>
                      <v-toolbar color="primary" dark>Busqueda por Tipo de Falla</v-toolbar>
                      <v-card-text class="mt-10">
                        <v-select v-model="tipo_falla_filter"
                          :items="['Mecánica', 'Hidráulica', 'Électrica', 'Neumática']" outlined dense
                          label="Ingrese el tipo de Falla"></v-select>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="filtroLabelPush('tipo_falla')">Aplicar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="showFilterTipoUnidad" transition="dialog-bottom-transition" max-width="350">
                    <v-card>
                      <v-toolbar color="primary" dark>Busqueda por Tipo de unidad</v-toolbar>
                      <v-card-text class="mt-10">
                        <v-select v-model="tipo_unidad_filter" :items=tiposDeUnidad outlined dense
                          label="Ingrese el tipo de unidad que decea filtrar"></v-select>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="filtroLabelPush('tipoUnidad')">Aplicar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="showFilterReporteFalla" transition="dialog-bottom-transition" max-width="350">
                    <v-card>
                      <v-toolbar color="primary" dark>Busqueda Reporte Falla</v-toolbar>
                      <v-card-text class="mt-10">
                        <v-text-field v-model="reporte_falla_filter" outlined dense
                          label="Ingrese el reporte de falla"></v-text-field>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="filtroLabelPush('reporte_falla')">Aplicar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="showFilterFecha" transition="dialog-bottom-transition" max-width="350">
                    <v-card>
                      <v-toolbar color="primary" dark>Busqueda por Fecha</v-toolbar>
                      <v-card-text class="mt-10">
                        <v-text-field v-model="fecha_inicio_filter" type="date" outlined dense
                          label="Inicio"></v-text-field>
                        <v-text-field v-model="fecha_fin_filter" type="date" outlined dense label="Fin"></v-text-field>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="filtroLabelPush('createdAt')">Aplicar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="showFilterestatus" transition="dialog-bottom-transition" max-width="350">
                    <v-card>
                      <v-toolbar color="primary" dark>Busqueda por Estatus</v-toolbar>
                      <v-card-text class="mt-10">
                        <v-select :items="['Normal', 'En espera', 'Cerrado']" v-model="estatus_filter" outlined dense
                          label="Estatus"></v-select>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="filtroLabelPush('estatus')">Aplicar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
              <v-col cols="10" sm="6" md="3">
                <div class="mt-5">
                  <v-autocomplete v-model="requisicion_seguimiento" :items="requisiciones_mtto"
                    style="border-radius: 20px" outlined chips clearable filled dense color="blue-grey lighten-2"
                    label="Ver estatus de requisición" item-text="TEXT" item-value="FOLIO">
                    <template v-slot:selection="data">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" :input-value="data.selected" small @click="data.select">
                            {{ data.item.FOLIO }}
                          </v-chip>
                        </template>
                        <span>{{ data.item.DESCRIPCION }}</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px; font-weight: bold">{{
                            data.item.FOLIO
                          }}</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px">{{
                            data.item.DESCRIPCION
                          }}</v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 12px; font-style: italic">{{ data.item.USUARIO
                            }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </div>
              </v-col>
              <v-col cols="1" sm="6" md="1">
                <div class="mt-5">
                  <v-btn @click="guiaRequisciones" class="mx-2" fab dark small color="primary">
                    <v-icon>mdi-note-search-outline</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col col="12" sm="12" lg="12" md="12"> </v-col>
              <v-col col="12" sm="12" lg="12" md="12"> </v-col>
              <v-col col="1" sm="1" lg="1"> </v-col>
            </v-row>

            <v-data-table :headers="headersOT" :items-per-page="11" :items="ordenes_trabajo" class="elevation-3 mt-5"
              style="border-radius: 10px" hide-default-footer>
              <template v-slot:[`header.estatus`]="{ header }">
                <span class="texto" style="font-size: 12px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                </span>
              </template>
              <template v-slot:[`header.folio_ot`]="{ header }">
                <span class="texto" style="font-size: 12px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                </span>
              </template>
              <template v-slot:[`header.activo.numeroEconomico`]="{ header }">
                <span class="texto" style="font-size: 12px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                </span>
              </template>
              <template v-slot:[`header.createdAt`]="{ header }">
                <span class="texto" style="font-size: 12px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                </span>
              </template>
              <template v-slot:[`header.tipo_falla`]="{ header }">
                <span class="texto" style="font-size: 12px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                </span>
              </template>
              <template v-slot:[`header.reporte_falla`]="{ header }">
                <span class="texto" style="font-size: 12px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                </span>
              </template>
              <template v-slot:[`header.hora_llegada`]="{ header }">
                <span class="texto" style="font-size: 12px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                </span>
              </template>
              <template v-slot:[`header.hora_salida`]="{ header }">
                <span class="texto" style="font-size: 12px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                </span>
              </template>
              <template v-slot:[`header.opciones`]="{ header }">
                <span class="texto" style="font-size: 12px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                </span>
              </template>
              <template v-slot:[`header.requisiciones`]="{ header }">
                <span class="texto" style="font-size: 12px; color: rgb(231, 231, 231)">{{ header.text.toUpperCase() }}
                </span>
              </template>
              <template v-slot:[`item.opciones`]="{ item }">
                <v-menu :close-on-content-click="false" :nudge-width="10" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" @click="CalculotiempoEjecucion">
                      mdi-dots-horizontal
                    </v-icon>
                  </template>

                  <v-card width="400px">
                    <v-list v-if="item.estatus !== 'Cerrado'" dense dark color="black">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Opciones para :</v-list-item-title>
                          <v-list-item-subtitle>{{ item.activo.numeroEconomico }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list>
                      <v-list-item-group v-model="selectedItem" color="#3768D4" dense>
                        <v-list v-if="item.estatus == 'Cerrado'" dense color="#91FB98">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                <p class="text" style="
                                                                                        text-align: center;
                                                                                        font-family: Arial, sans-serif;
                                                                                        font-size: 18px;
                                                                                        color: #666666;
                                                                                        font-weight: bold;
                                                                                      ">
                                  Reporte de falla cerrado
                                  {{ item.activo.numeroEconomico }}
                                </p>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Tiempo de Ejecucion :
                                {{ tiempoEjecucion }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-list-item dense @click="editItem(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Editar</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense @click="mostrarDialogCorreos(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-email</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Enviar notificacion</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense @click="mostrarDialogFirmaLog(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-account-key</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Firmar</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="item.estatus == 'Cerrado'" dense @click="mostrarDialogTimeline(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-timeline-clock</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Tiempos</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <!-- <v-list-item dense @click="generarPdf(item, true)">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Generar Reporte</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item> -->
                        <v-list-item dense @click="generarPdfSop(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-microsoft-excel</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Generar reporte SOP-20-F-06</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="item.estatus == 'Normal'" dense @click="mostrarDialogPauseOT(item._id)">
                          <v-list-item-icon>
                            <v-icon>mdi-pause</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Pausar mantenimiento</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="item.estatus == 'En espera'" dense @click="playOrdenTrabajo(item._id)">
                          <v-list-item-icon>
                            <v-icon>mdi-play</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitlef>Continuar con mantenimiento</v-list-item-subtitlef>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense @click="mostrarDialogEvidencias(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-camera</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Subir evidencias fotograficas</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense @click="mostrarDialogEvidenciasDoc(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Subir documentos</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="item.activo.estatu == 'Fuera de Servicio'" dense
                          @click="mostrarDialogCambiarEstatus(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-list-status</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle>Cambiar estatus de unidad</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-menu>
              </template>
              <template v-slot:[`item.folio_ot`]="{ item }">
                <span class="" style="font-size: 12px; font-weight: bold">OT-{{ item.folio_ot }}</span>
              </template>
              <template v-slot:[`item.requisiciones`]="{ item }">
                <v-chip v-for="(req, index) in item.requisiciones" :key="index" class="ma-2" x-small color="secondary">
                  {{ req }}
                </v-chip>
              </template>
              <template v-slot:[`item.activo.numeroEconomico`]="{ item }">
                <span v-if="item.activo.estatu == 'Operativo'" class="" style="
                                                                        color: rgb(0, 121, 54);
                                                                        font-size: 12px;
                                                                        font-weight: bold;
                                                                        text-transform: uppercase;
                                                                      ">{{ item.activo.numeroEconomico }}</span>
                <span v-else class="" style="
                                                                        color: rgb(158, 0, 0);
                                                                        font-size: 12px;
                                                                        font-weight: bold;
                                                                        text-transform: uppercase;
                                                                      ">{{ item.activo.numeroEconomico }}</span>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <span class="texto" style="color: #202020; font-size: 12px">{{
                  moment(item.createdAt).format("MMM Do YYYY, H:MM")
                }}</span>
              </template>
              <template v-slot:[`item.tipo_falla`]="{ item }">
                <span class="texto" style="color: #202020; font-size: 12px; text-transform: uppercase">{{
                  item.tipo_falla
                }}</span>
              </template>
              <template v-slot:[`item.reporte_falla`]="{ item }">
                <span v-if="item.reporte_falla" @click="mostrarReporteFalla(item.reporte_falla)" class="texto" style="
                                                                        color: #202020;
                                                                        font-size: 12px;
                                                                        font-weight: bold;
                                                                        text-transform: uppercase;
                                                                        cursor: pointer;
                                                                        text-decoration: underline;
                                                                      ">{{ item.reporte_falla }}</span>
                <span v-else class="texto" @click="asignarRF(item.folio_ot)" style="
                                                                        color: #202020;
                                                                        font-weight: bold;
                                                                        font-size: 12px;
                                                                        text-transform: uppercase;
                                                                        cursor: pointer;
                                                                        text-decoration: underline;

                                                                      ">NA</span>
              </template>
              <template v-slot:[`item.hora_llegada`]="{ item }">
                <span class="texto" style="color: #202020; font-size: 12px">{{
                  moment(item.hora_apertura).format("MMM Do YYYY, H:MM")
                }}</span>
              </template>
              <template v-slot:[`item.hora_salida`]="{ item }">
                <span v-if="item.hora_vencimiento" class="texto" style="color: #202020; font-size: 12px">{{
                  moment(item.hora_vencimiento).format("MMM Do YYYY, H:MM") }}</span>
              </template>
              <template v-slot:[`item.estatus`]="{ item }">
                <v-chip label outlined class="texto" small v-if="item.estatus == 'Cerrado'" color="green">
                  Cerrado
                </v-chip>
                <v-chip label outlined class="texto" small v-if="item.estatus == 'Normal'" color="blue">
                  Normal
                </v-chip>
                <v-chip label outlined class="texto" small v-if="item.estatus == 'En espera'" color="orange"
                  style="color: #202020">
                  En espera
                </v-chip>
              </template> </v-data-table><br />
            <div class="text-center">
              <v-pagination v-model="page" :length="totalPages" :total-visible="7" @input="listar()"></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="button-fab" dark @click="mostrarNuevo()" v-bind="attrs" v-on="on"
          color="primary"><v-icon>mdi-plus</v-icon></v-btn>
      </template>
      <span>Nueva OT</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="button-fab-fotografico" dark @click="mostraDialogGenerarRepFot()" v-bind="attrs" v-on="on"
          color="primary"><v-icon>mdi-camera</v-icon></v-btn>
      </template>
      <span>Generar Reporte Fotografico</span>
    </v-tooltip>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import MttoApi from '../../api/Mtto'
import jsPDF from "jspdf";
import EmailApi from "../../api/Email";
import uploadImage from "../../utils/uploadImage";
import ApiMicrosip from "../../api/Microsip";
import Catalogo from "../../api/Catalogo";
import { generarReporteFotografico } from "../../utils/reportes/ReporteFotografico";

export default {
  name: "app",

  data() {
    return {
      bottomDialogAsignarRF: false,
      orden_trabajo_selected: '',
      reporte_falla_selected: '',
      folios_rf: [],
      loading3: false,
      overlay: false,
      snack: false,
      snackColor: "",
      snackText: "",
      moment: moment,
      e6: 1,
      max25chars: (v) => v.length <= 25 || "Input too long!",
      pagination: {},
      dialogLoader: false,
      loadingDoc: false,
      dialogOrdenServicio: false,
      dialogDocEviedenciasOt: false,
      evidencias_doc_info: "",
      evidencias_doc: "",
      tipo_trabajo: "",
      activosSelect: [],
      numeroEconomicoBusqueda: "",
      descripcion_equipo: "",
      tipoFalla: "",
      hora_apertura: "",
      hora_vencimiento: "",
      mecanico: "",
      procedimiento_trabajo: "",
      observaciones: "",
      reportes_falla: [],
      folios_reportes: [],
      motivos: "",
      e1: 1,
      evidencias: "",
      mecanicosActivos: [],
      row: "",
      headers: [
        {
          text: "No",
          value: "index",
        },
        { text: "Descripción de la refacción", value: "descripcion" },
        { text: "No. Partes", value: "no_parte" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Observaciones", value: "observaciones" },
        { text: "Eliminar", value: "eliminar" },
      ],
      headersOT: [
        { text: "Folio", value: "folio_ot", width: "100px", align: "center" },
        {
          text: "Equipo",
          value: "activo.numeroEconomico",
          width: "100px",
          align: "center",
        },
        {
          text: "Fecha de Registro",
          value: "createdAt",
          width: "200px",
          align: "center",
        },

        {
          text: "Tipo de Falla",
          value: "tipo_falla",
          width: "120px",
          align: "center",
        },
        {
          text: "RF",
          value: "reporte_falla",
          width: "120",
          align: "center",
        },
        {
          text: "Hora de llegada",
          value: "hora_llegada",
          width: "190px",
          align: "center",
        },
        {
          text: "Hora de Salida",
          value: "hora_salida",
          width: "190px",
          align: "center",
        },
        { text: "Requisiciones", value: "requisiciones", width: "90px", align: "center" },
        { text: "Estatus", value: "estatus", width: "90px", align: "center" },
        { text: "Opciones", value: "opciones", width: "90px", align: "center" },
      ],
      articulos: [],
      ordenes_trabajo: [],
      selectedItem: 1,
      trabajo: "",
      id: "",
      intervencion: "",
      dialogCerrarOt: false,
      ordent_trabajo_item: [],
      reporte_falla: "",
      dialogCorreos: false,
      otSelected: [],
      correos: "",
      documentoOt: "",
      estatus_unidad: "",
      tiempoEjecucion: "",
      guardarOrdenDirecta: false,
      menu: false,
      estatus: "",
      dialogTiempos: false,
      tiemposTimeline: [],
      tiempo_ejecucion_mtto: "",
      tiempo_en_espera: "",
      dialogPauseOt: "",
      motivo_pause: "",
      search: "",
      ubicacion: "",
      dialogEvidencias: false,
      evidencia_info: "",
      evidencias_imagenes: [],
      evidencias_docs: [],
      trabajo_realizar: "",
      refacciones: [],
      searchRefacciones: null,
      refaccionesObject: [],
      dialogArticulosMicrosip: false,
      articulos_microsip: [],
      requisiciones_mtto: [],
      requisiciones: [],
      page: 1,
      totalPages: 0,
      arr: [],
      showFilterNumeroEconomico: false,
      showFilterFolio: false,
      showFilterTipoFalla: false,
      showFilterTipoUnidad: false,
      showFilterReporteFalla: false,
      showFilterestatus: false,
      numero_economico_filter: "",
      estatus_filter: "",
      folio_filter: "",
      tipo_falla_filter: "",
      tipo_unidad_filter: "",
      reporte_falla_filter: "",
      showFilterFecha: false,
      fecha_inicio_filter: "",
      fecha_fin_filter: "",
      requisicion_seguimiento: "",
      dialogGuiaRequisicion: false,
      seguimiento_requisiciones: false,
      dialogGenerarReporteFotografico: false,
      numeroEconomicoReporte: [],
      fechaInicioReporte: "",
      fechaFinReporte: "",
      semanaReporte: "",
      dialogSeguimientoReporteFalla: false,
      reporte_falla_seguimiento: [],
      ot_evidencias_reporte: [],
      selected: [],
      dialogEstatusUnidad: false,
      ne_cambio_estatus: "",
      equipo_cambio_estatus: "",
      fecha_cambio_estatus: "",
      reporte_cambio_estatus: "",
      estatus_cambio_estatus: "",
      dialogFirmaMtto: "",
      tiposDeUnidad: [
        "Autos y Pick Ups",
        "Motocicletas",
        "Tractocamion",
        "Plataforma",
        "Grúa Terrestre",
        "Montacargas",
        "Cargador Frontal",
      ],
      tipoDeFallas: [
        "Mecánica",
        "Hojalatería",
        "Estructural",
        "Hidráulica",
        "Carpintería",
        "Soldadura",
        "Électrica",
        "Neumática",
        "Sistema de aire",
        "Otro",
      ],
    };
  },
  computed: {},
  created() {
    this.listarActivos();
    this.selectMecanicos();
    this.listar();
    this.mostrarRequicionesMicrosip();
    this.mostrarRefacciones();
  },

  methods: {
    limpiar() {
      (this.id = ""), (this.numeroEconomicoBusqueda = "");
      this.tipoFalla = "";
      this.mecanico = [];
      this.trabajo = "";
      this.articulos = [];
      this.observaciones = "";
      this.procedimiento_trabajo = "";
      this.hora_apertura = "";
      this.hora_vencimiento = "";
      this.intervencion = "";
      this.reporte_falla = "";
      this.estatus_unidad = "";
    },

    editItem(item) {
      this.articulos_microsip = [];
      this.id = item._id;
      this.requisiciones = item.requisiciones;
      this.dialogOrdenServicio = true;
      this.numeroEconomicoBusqueda = item.activo._id;
      this.tipoFalla = item.tipo_falla;
      this.mecanico = item.tecnico;
      this.trabajo = item.trabajos;
      this.articulos = item.refacciones;
      this.observaciones = item.observaciones;
      (this.procedimiento_trabajo = item.procedimiento_trabajo),
        (this.hora_apertura = item.hora_apertura);
      this.hora_vencimiento = item.hora_vencimiento;
      this.intervencion = item.tipo_intervencion;
      this.reporte_falla = item.reporte_falla;
      this.estatus_unidad = item.activo.estatu;
      this.ubicacion = item.ubicacion;
      this.estatus = item.estatus;
      this.trabajo_realizar = item.trabajos;

      this.datosEquipo();
    },

    mostrarNuevo() {
      this.guardarOrdenDirecta = true;
      this.dialogOrdenServicio = true;
      this.limpiar();
      this.mostrarRequicionesMicrosip();
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    listarActivos() {
      let me = this;
      MttoApi.obtenerActivosSelect().then((res) => {
        me.activosSelect = res.data;
      });
    },

    agregarRF() {
      this.loading3 = true
      if (!this.reporte_falla_selected) {
        this.addErrorNotification()
        return
      }
      const datos = {
        ot: this.orden_trabajo_selected,
        rf: this.reporte_falla_selected.text
      }

      MttoApi.agregarReporteFallaOT(datos).then(() => {
        this.addSuccessNotification()
        this.bottomDialogAsignarRF = false
        this.listar()
        this.reporte_falla_selected = ''
      }).finally(() => {
        this.loading3 = false
      })
    },

    datosEquipo() {
      if (this.numeroEconomicoBusqueda == '656788989b2b1600226b6944') {
        this.descripcion_equipo = 'Ninguna unidad asignada'
      } else {
        MttoApi.obtenerDetalleEquipoId(this.numeroEconomicoBusqueda)
          .then((e) => {
            this.descripcion_equipo = e.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },



    mostrarMotivosReporte() {
      const datos = {
        folio: this.folios_reportes,
      };
      MttoApi.obtenerMotivosReporte(datos).then((e) => {
        this.motivos = e.data;
      });
    },

    selectMecanicos() {
      let me = this;
      let mecaniccArray = [];

      axios
        .get("/mecanico/listActivos")
        .then((response) => {
          mecaniccArray = response.data;
          mecaniccArray.map(function (x) {
            me.mecanicosActivos.push({
              text: `${x.nombreMecanico} `,
              value: `${x.nombreMecanico} `,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    listar() {
      this.overlay = true;
      MttoApi.listarOrdenTrabajo(this.page, this.arr)
        .then((e) => {
          this.ordenes_trabajo = e.data.reg;
          this.totalPages = e.data.totalPaginas;
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Dato Guardado";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Cancelado";
    },
    open(item) {
      item.descripcion = "";
    },
    close() {
      console.log("Dialog closed");
    },

    agregarArticulo() {
      this.articulos.push({
        descripcion: "",
        no_parte: "",
        cantidad: "",
        observaciones: "",
      });
    },

    eliminarArticulo(item) {
      let i = this.articulos.indexOf(item);
      if (i != -1) {
        this.articulos.splice(i, 1);
      }
    },

    mostrarDialogCerrarOt(item) {
      this.dialogCerrarOt = true;
      this.ordent_trabajo_item = item;
    },

    save_and_update(estatus) {
      if (estatus == "Cerrado") {
        if (
          this.intervencion == null ||
          this.hora_apertura == null ||
          this.hora_vencimiento == null ||
          this.procedimiento_trabajo == null ||
          this.observaciones == null ||
          this.mecanico == []
        ) {
          this.addErrorNotification2("Ingrese los campos faltantes");
          this.dialogCerrarOt = false;
          return;
        }
      }
      this.guardarOrdenDirecta ? this.guardar() : this.updateOt(estatus);
    },

    updateOt(estatus) {
      this.overlay = true;
      const datos = {
        _id: this.id,
        tipo_falla: this.tipoFalla,
        hora_apertura: this.hora_apertura,
        hora_vencimiento: this.hora_vencimiento,
        tecnico: this.mecanico,
        refacciones: this.articulos,
        procedimiento_trabajo: this.procedimiento_trabajo,
        observaciones: this.observaciones,
        tipo_intervencion: this.intervencion,
        reporte_falla: this.reporte_falla,
        estatus: estatus,
        estatus_unidad: this.estatus_unidad,
        equipo: this.numeroEconomicoBusqueda,
        ubicacion: this.ubicacion,
        trabajos: this.trabajo_realizar,
        requisiciones: this.requisiciones
      };
      console.log(datos)
      MttoApi.updateOrdenTrabajo(datos)
        .then(() => {
          this.addSuccessNotification("Orden de trabajo actualizada correctamente");
          this.dialogOrdenServicio = false;
          this.dialogCerrarOt = false;
          this.listar();
          this.listarActivos();
          this.e6 = 1;
        })
        .catch((err) => {
          console.log(err);
          this.addErrorNotification();
        })
        .finally(() => {
          this.overlay = false;
          this.id = "";
          this.reporte_falla = "";
        });
    },

    guardar() {
      this.overlay = true;
      const datos = {
        tipo_falla: this.tipoFalla,
        hora_apertura: this.hora_apertura,
        hora_vencimiento: this.hora_vencimiento,
        tecnico: this.mecanico,
        refacciones: this.articulos,
        procedimiento_trabajo: this.procedimiento_trabajo,
        observaciones: this.observaciones,
        tipo_intervencion: this.intervencion,
        estatus_unidad: this.estatus_unidad,
        activo: this.numeroEconomicoBusqueda,
        ubicacion: this.ubicacion,
        trabajos: this.trabajo_realizar,
        requisiciones: this.requisiciones,
        usuarioCreador: this.$store.state.usuario.personal._id,
      };
      MttoApi.nuevaOrdenTrabajo(datos)
        .then(() => {
          this.addSuccessNotification("Nueva orden de trabajo generada");
          this.dialogOrdenServicio = false;
          this.dialogCerrarOt = false;
          this.listar();
          this.guardarOrdenDirecta = false;
          this.listarActivos();
          this.e6 = 1;
        })
        .catch((err) => {
          console.log(err);
          this.addErrorNotification();
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    mostrarRefacciones() {
      this.overlay = true;
      MttoApi.mostrarRefacciones()
        .then((e) => {
          this.refaccionesObject = e.data;
          e.data.forEach((e) => {
            this.refacciones.push(e.descripcion);
          });
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    mostrarNumeroParte(refaccion) {
      let i = this.articulos.indexOf(refaccion);
      console.log(i);
    },

    mostrarDialogCorreos(item) {
      this.dialogCorreos = true;
      this.otSelected = item;
    },

    async generarPdfSop(item) {
      try {
        this.overlay = true

        const fechaCompleta = item.createdAt;
        const fechaSolo = fechaCompleta.slice(0, 10);

        let fechaSoloApertura = "";
        if (item.hora_apertura) {
          const fechaCompletaApertura = item.hora_apertura;
          fechaSoloApertura = fechaCompletaApertura.slice(0, 10);
        }

        let fechaSoloVencimiento = "";
        if (item.hora_vencimiento) {
          const fechaCompletaVencimiento = item.hora_vencimiento;
          fechaSoloVencimiento = fechaCompletaVencimiento.slice(0, 10);
        }

        let mecanico = "";
        let hidraulico = "";
        let electrico = "";
        let neumatico = "";
        let otro = "";

        if (item.tipo_falla == "Électrica") {
          electrico = "X";
        } else if (item.tipo_falla == "Mecánica") {
          mecanico = "X";
        } else if (item.tipo_falla == "Neumática") {
          neumatico = "X";
        } else if (item.tipo_falla == "Hidráulica") {
          hidraulico = "X";
        } else {
          otro = item.tipo_falla;
        }

        let refaccion = "";
        if (item.refacciones) {
          item.refacciones.forEach((e, index) => {
            const refacciones = `${e.descripcion}`;
            refaccion += refacciones;
            if (index < item.refacciones.length - 1) {
              refaccion += " ";
            }
          });
        }

        const datos = {
          numero_economico: item.activo.numeroEconomico,
          numero_serie: item.activo.numeroSerie,
          fecha: fechaSolo,
          folio: item.folio_ot,
          tipoUnidad: item.activo.nombreActivo,
          hora_llegada: fechaSoloApertura,
          hora_vencimiento: fechaSoloVencimiento,
          electrico,
          mecanico,
          neumatico,
          hidraulico,
          otro,
          observaciones: item.observaciones,
          diagnostica: item.tecnico.toString(),
          trabajos: item.procedimiento_trabajo,
          refaccion,
          reporte_falla: item.reporte_falla,
          trabajos2: item.trabajos,
        };

        // Mostrar overlay (suponiendo que tienes un método this.overlay.show())

        // Llamar a la API para descargar el archivo
        const response = await MttoApi.descargarOtExcel(datos);
        const base64Data = response.data;
        const binaryData = atob(base64Data);

        // Crear el blob y el enlace para descargar
        const blob = new Blob([
          new Uint8Array(binaryData.length).map((_, i) => binaryData.charCodeAt(i)),
        ]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "ot" + item.folio_ot + ".xlsx"; // Nombre del archivo
        document.body.appendChild(a);

        a.click();

        // Revocar el objeto URL creado
        window.URL.revokeObjectURL(url);

        // Ocultar overlay (suponiendo que tienes un método this.overlay.hide())

      } catch (error) {
        console.error(error);
        // Manejar el error aquí si es necesario
      } finally {
        this.overlay = false
      }
    },

    async generarPdf(item, guardar = false) {
      var doc = new jsPDF("p", "pt", "letter");
      var startX = 40;
      var startY = 20;

      doc.addImage(
        "https://i.ibb.co/xfG9TpF/Logotipo-apaisado-vectores-CICSA-Recuperado.png",
        startX + 20,
        startY + 1,
        110,
        30
      );

      doc.setFont(undefined, "bold");
      doc.setFontSize(15);
      doc.text("Orden de Trabajo", startX + 400, startY + 10);

      //Varuables

      doc.setDrawColor(128);
      doc.setLineWidth(1.5);
      //Rectangulos
      doc.roundedRect(startX + 395, startY + 20, 140, 20, 5, 5);
      doc.roundedRect(startX + 15, startY + 60, 250, 60, 5, 5);
      doc.roundedRect(startX + 295, startY + 60, 240, 20, 5, 5);
      doc.roundedRect(startX + 295, startY + 100, 240, 20, 5, 5);

      //Rectangulos tipo de intervención
      doc.setFillColor("#4A4A4A");

      item.tipo_intervencion == "Preditiva"
        ? doc.roundedRect(startX + 55, startY + 160, 15, 15, 3, 3, "F")
        : doc.roundedRect(startX + 55, startY + 160, 15, 15, 3, 3);
      item.tipo_intervencion == "Preventiva"
        ? doc.roundedRect(startX + 135, startY + 160, 15, 15, 3, 3, "F")
        : doc.roundedRect(startX + 135, startY + 160, 15, 15, 3, 3);
      item.tipo_intervencion == "Correctiva Programada"
        ? doc.roundedRect(startX + 225, startY + 160, 15, 15, 3, 3, "F")
        : doc.roundedRect(startX + 225, startY + 160, 15, 15, 3, 3);
      item.tipo_intervencion == "Correctiva"
        ? doc.roundedRect(startX + 310, startY + 160, 15, 15, 3, 3, "F")
        : doc.roundedRect(startX + 310, startY + 160, 15, 15, 3, 3);

      //Rectangulo Fechas
      doc.roundedRect(startX + 385, startY + 140, 150, 45, 5, 5);
      doc.roundedRect(startX + 385, startY + 195, 150, 45, 5, 5);
      //Rectangulo Tipo de Falla
      item.tipo_falla == "Mecánica"
        ? doc.roundedRect(startX + 30, startY + 230, 10, 10, 3, 3, "F")
        : doc.roundedRect(startX + 30, startY + 230, 10, 10, 3, 3);
      item.tipo_falla == "Hidráulica"
        ? doc.roundedRect(startX + 100, startY + 230, 10, 10, 3, 3, "F")
        : doc.roundedRect(startX + 100, startY + 230, 10, 10, 3, 3);
      item.tipo_falla == "Electrica"
        ? doc.roundedRect(startX + 175, startY + 230, 10, 10, 3, 3, "F")
        : doc.roundedRect(startX + 175, startY + 230, 10, 10, 3, 3);
      item.tipo_falla == "Neumática"
        ? doc.roundedRect(startX + 245, startY + 230, 10, 10, 3, 3)
        : doc.roundedRect(startX + 245, startY + 230, 10, 10, 3, 3);

      //Rectangulos chips
      doc.setFillColor("#E1F5FE");
      //
      doc.roundedRect(startX + 105, startY + 180, 80, 15, 3, 3, "F");
      doc.setFillColor("#FFF9C4");
      doc.roundedRect(startX + 190, startY + 180, 80, 15, 3, 3, "F");
      doc.setFillColor("#FFCDD2");
      doc.roundedRect(startX + 275, startY + 180, 80, 15, 3, 3, "F");
      doc.setFillColor("#F5F5F5");
      doc.roundedRect(startX + 15, startY + 401, 520, 20, 5, 0, "F");
      doc.roundedRect(startX + 20, startY + 180, 80, 15, 3, 3, "F");
      doc.setFillColor("#E7E7E7");
      doc.roundedRect(0, startY + 750, 680, 30, 3, 3, "F");
      //Rectangulo responsables
      doc.roundedRect(startX + 15, startY + 613, 520, 25, 5, 5);
      //Requisiciones
      doc.roundedRect(startX + 15, startY + 703, 520, 25, 5, 5);
      //Refacciones tabla
      doc.roundedRect(startX + 15, startY + 400, 520, 180, 5, 5);

      //Observaciones
      //Procedimiento realizado
      doc.roundedRect(startX + 15, startY + 270, 520, 100, 5, 5);

      //observaciones y fecha
      doc.roundedRect(startX + 15, startY + 665, 350, 20, 5, 5);
      doc.roundedRect(startX + 380, startY + 665, 155, 20, 5, 5);

      doc.setFontSize(12);
      doc.text("N°    " + "OT-000" + item.folio_ot, startX + 415, startY + 35);

      doc.setTextColor.apply(this, [128, 128, 128]);
      doc.setFontSize(17);
      doc.text(item.estatus, startX + 220, startY + 14);
      doc.setFontSize(9);
      doc.text("Trabajo a realizar", startX + 15, startY + 55);
      doc.text("Datos de la unidad", startX + 305, startY + 55);
      doc.text("Ubicación", startX + 305, startY + 95);
      doc.text("Tipo de Intervención", startX + 130, startY + 145);
      doc.text("Fecha de Apertura", startX + 420, startY + 155);
      doc.text("Fecha de Vencimiento", startX + 415, startY + 210);
      doc.text("Tipo de Falla", startX + 150, startY + 215);

      doc.text("Preditiva", startX + 43, startY + 191);
      doc.text("Preventiva", startX + 123, startY + 191);
      doc.text("Programada", startX + 203, startY + 191);
      doc.text("Correctiva", startX + 293, startY + 191);

      doc.text("Mecánica", startX + 50, startY + 238);
      doc.text("Hidráulica", startX + 120, startY + 238);
      doc.text("Électrica", startX + 195, startY + 238);
      doc.text("Neumática", startX + 265, startY + 238);
      doc.text("Responsables", startX + 15, startY + 605);

      doc.text("Refacciones del inventario", startX + 215, startY + 390);
      doc.text("No", startX + 50, startY + 415);
      doc.text("Descripción de la refacción", startX + 130, startY + 415);
      doc.text("No. Parte", startX + 400, startY + 415);
      doc.text("Cantidad", startX + 470, startY + 415);

      doc.text("Procedimiento Realizado", startX + 215, startY + 260);
      doc.text("Observaciones", startX + 15, startY + 655);
      doc.text("Requisiciones", startX + 15, startY + 698);
      doc.text("Tiempo de Ejecución", startX + 415, startY + 655);

      let colorBlanco = "#4A4A4A"; // Color blanco en formato hexadecimal
      let colorRojo = "#B21E28";
      doc.setTextColor(colorBlanco);
      doc.text("www.", 255, 785);
      let textoWwwWidth = doc.getStringUnitWidth("www.") * doc.internal.getFontSize();
      doc.setTextColor(colorRojo);
      doc.text("cicsagruas", 255 + textoWwwWidth, 785);
      let textoCicsagruasWidth =
        doc.getStringUnitWidth("cicsagruas") * doc.internal.getFontSize();
      doc.setTextColor(colorBlanco);
      doc.text(".com", 255 + textoWwwWidth + textoCicsagruasWidth, 785);

      doc.setTextColor("#292929");
      doc.setFontSize(8);
      const trabajo = doc.splitTextToSize(item.trabajos, 230);
      item.trabajos
        ? doc.text(trabajo, startX + 20, 95)
        : doc.text("NA", startX + 20, 95);
      doc.text(
        `${item.activo.numeroEconomico} ${item.activo.marca} ${item.activo.modelo} ${item.activo.numeroSerie}`,
        startX + 305,
        93
      );
      if (item.ubicacion) {
        doc.text(item.ubicacion, startX + 305, 135);
      }
      if (item.requisiciones) {
        //doc.text(item.requisiciones.toString(), startX + 25, 780);
        doc.text(item.requisiciones, startX + 25, 740);
      }
      doc.setFontSize(10);
      if (item.hora_apertura) {
        doc.text(item.hora_apertura, 455, 195);
      }
      if (item.hora_vencimiento) {
        doc.text(item.hora_vencimiento, 455, 250);
      }
      doc.setFontSize(8);
      doc.text(item.tecnico.toString(), startX + 25, 650);
      doc.setFontSize(9);
      startY = 440;
      if (item.refacciones)
        if (item.refacciones) {
          item.refacciones.forEach((e, index) => {
            doc.text((index + 1).toString(), (startX = 95), (startY += 15));
            doc.text(e.descripcion, (startX = 180), startY);
            if (e.no_parte) {
              doc.text(e.no_parte, (startX = 445), startY);
              doc.text(e.cantidad.toString(), (startX = 525), startY);
            }
          });
        }

      const procedimiento = doc.splitTextToSize(item.procedimiento_trabajo, 500);

      doc.text(procedimiento, 60, 305);
      doc.setFontSize(7);

      const observaciones = doc.splitTextToSize(item.observaciones, 360);
      doc.text(observaciones, 60, 700);

      const fecha1 = new Date(item.hora_apertura);
      const fecha2 = new Date(item.hora_vencimiento);

      var diferencia = fecha2.getTime() - fecha1.getTime();

      // Calcular los componentes de la diferencia
      var segundos = Math.floor(diferencia / 1000);
      var minutos = Math.floor(segundos / 60);
      var horas = Math.floor(minutos / 60);
      var dias = Math.floor(horas / 24);

      // Calcular los minutos, horas y días restantes
      var minutosRestantes = minutos % 60;
      var horasRestantes = horas % 24;
      doc.setFontSize(8);
      doc.text(
        `${dias} dias ${horasRestantes} horas y ${minutosRestantes} minutos`,
        430,
        700
      );

      if (item.evidencias.length > 0) {
        const evidencias = this.dividirEnGrupos(item.evidencias, 6);

        evidencias.forEach((e) => {
          doc.addPage();
          doc.setFontSize(8);
          let colorBlanco = "#4A4A4A"; // Color blanco en formato hexadecimal
          let colorRojo = "#B21E28";
          doc.setTextColor(colorBlanco);
          doc.text("www.", 255, 830);
          let textoWwwWidth = doc.getStringUnitWidth("www.") * doc.internal.getFontSize();
          doc.setTextColor(colorRojo);
          doc.text("cicsagruas", 255 + textoWwwWidth, 830);
          let textoCicsagruasWidth =
            doc.getStringUnitWidth("cicsagruas") * doc.internal.getFontSize();
          doc.setTextColor(colorBlanco);
          doc.text(".com", 255 + textoWwwWidth + textoCicsagruasWidth, 830);
          doc.setTextColor("#292929");

          //IMAGENES

          var margen = 40;
          var espacioHorizontal = (doc.internal.pageSize.getWidth() - margen * 3) / 2;
          var espacioVertical = 180;
          var posicionVertical = margen;

          for (var i = 0; i < e.length; i++) {
            var imagen = evidencias[0][i];
            var fila = Math.floor(i / 2);
            var columna = i % 2;
            var x = margen + columna * (espacioHorizontal + margen);
            var y = posicionVertical + fila * (espacioVertical + margen) + 100;

            doc.addImage(imagen.url, "JPEG", x, y, espacioHorizontal, espacioVertical);

            // Agregar descripción de la imagen
            doc.text(x, y + espacioVertical + 20, imagen.info);
          }

          var startX2 = 40;
          var startY2 = 20;

          doc.addImage(
            "https://i.ibb.co/xfG9TpF/Logotipo-apaisado-vectores-CICSA-Recuperado.png",
            startX2 + 20,
            startY2 + 1,
            110,
            30
          );

          doc.setFont(undefined, "bold");
          doc.setFontSize(15);
          doc.text("Orden de Trabajo", startX2 + 400, startY2 + 10);
          doc.setFontSize(12);
          doc.text("N°    " + "OT-000" + item.folio_ot, startX2 + 415, startY2 + 35);
          doc.setDrawColor(128);
          doc.setLineWidth(1.5);
          //Rectangulos
          doc.roundedRect(startX2 + 395, startY2 + 20, 140, 20, 5, 5);
          doc.setFontSize(20);
          doc.text("Evidencias Fotograficas", startX2 + 160, startY2 + 80);
        });
      }

      if (item.reporte_falla) {
        this.overlay = true
        let itemReporteFalla = [];
        await MttoApi.queryReporteFallaFolio(item.reporte_falla).then((e) => {
          itemReporteFalla = e.data;
        }).finally(() => {
          this.overlay = false
        });



        doc.addPage();
        doc.addImage(
          "https://i.ibb.co/xfG9TpF/Logotipo-apaisado-vectores-CICSA-Recuperado.png",
          "JPEG",
          40 + 15,
          50,
          110,
          30
        );

        doc.setFont(undefined, "bold");
        doc.setFontSize(7);
        //let date = moment(new Date()).format("LLL");
        // doc.text(date, (startX += 405), (startY += 25));
        doc.text("CORPORATIVO INDUSTRIAL Y COMERCIAL S.A. DE C.V.", 348, 67);
        doc.setFontSize(14);
        doc.setLineWidth(1.5);
        doc.text(
          "Reporte de Falla R-" + itemReporteFalla.folio_falla.toString(),
          230,
          110
        );
        doc.setFontSize(10);
        doc.text("Información del Solicitante", 55, 140);
        doc.setFontSize(8);
        doc.setLineWidth(1);
        doc.text("Nombre Completo:", 55, 165);
        doc.line(145, 167, 400, 167);
        doc.text("Correo:", 55, 178);
        doc.line(145, 180, 400, 180);
        doc.text("Telefono:", 55, 191);
        doc.line(145, 193, 400, 193);

        doc.setFont(undefined, "normal");
        doc.setFontSize(8);
        doc.text(itemReporteFalla.usuarioCreador.nombreCompleto, 150, 165);
        doc.text(itemReporteFalla.usuarioCreador.correo, 150, 178);
        //  doc.text(item.usuarioCreador.telefono.toString(), 150, 191);

        doc.setFont(undefined, "bold");
        doc.setFontSize(10);
        doc.text("Información del Equipo", 55, 216);
        doc.setFontSize(8);
        doc.setLineWidth(1);
        doc.text("Activo:", 55, 241);
        doc.line(145, 243, 300, 243);
        doc.text("Marca:", 55, 254);
        doc.line(145, 256, 300, 256);
        doc.text("Modelo:", 55, 267);
        doc.line(145, 269, 300, 269);
        doc.text("Numero de Serie:", 55, 280);
        doc.line(145, 282, 300, 282);

        doc.text("Placa:", 308, 241);
        doc.line(395, 243, 550, 243);
        doc.text("Estatus:", 308, 254);
        doc.line(395, 256, 550, 256);
        doc.text("Numero Economico:", 308, 267);
        doc.line(395, 269, 550, 269);

        doc.setFont(undefined, "normal");
        doc.setFontSize(8);
        doc.text(itemReporteFalla.activo.nombreActivo, 150, 241);
        doc.text(itemReporteFalla.activo.marca, 150, 254);
        doc.text(itemReporteFalla.activo.modelo, 150, 267);
        doc.text(itemReporteFalla.activo.numeroSerie, 150, 280);

        doc.text(itemReporteFalla.activo.placa, 403, 241);
        doc.text(itemReporteFalla.activo.estatu, 403, 254);
        doc.text(itemReporteFalla.activo.numeroEconomico, 403, 267);

        doc.setFont(undefined, "bold");
        doc.setFontSize(10);
        doc.text("Información del Reporte de Falla", 55, 380);
        doc.setFontSize(8);
        doc.setLineWidth(1);
        doc.text("Estatus:", 55, 405);
        doc.line(145, 407, 300, 407);
        doc.text("Fecha Falla:", 55, 418);
        doc.line(145, 420, 300, 420);
        doc.text("Operador:", 55, 431);
        doc.line(145, 433, 300, 433);
        doc.text("Prioridad:", 55, 444);
        doc.line(145, 446, 300, 446);
        doc.text("Mecanicos Asignados:", 55, 457);
        doc.line(145, 459, 550, 459);
        doc.text("Requisiciones:", 55, 470);
        doc.line(145, 472, 550, 472);
        if (itemReporteFalla.requisiciones) {
          doc.text(itemReporteFalla.requisiciones.toString(), 150, 469);
        }

        doc.text("Tipo de Falla:", 308, 405);
        doc.line(395, 407, 550, 407);
        doc.text("Folio:", 308, 418);
        doc.line(395, 420, 550, 420);
        doc.text("Fecha de Creacion:", 308, 431);
        doc.line(395, 433, 550, 433);
        doc.text("Fecha de Asignación:", 308, 444);
        doc.line(395, 446, 550, 446);

        let createdAt = moment(new Date(itemReporteFalla.createdAt)).format("LLL");

        const fechaOriginal = itemReporteFalla.fechaFalla;
        const fecha = new Date(fechaOriginal);
        fecha.setHours(fecha.getHours() + 5); // Agregar 5 horas para convertir a GMT-5
        const fechaFormateada = fecha.toISOString().slice(0, 10);

        let asignacionFecha = moment(new Date(itemReporteFalla.fechaAsignacion)).format(
          "LLL"
        );

        doc.setFont(undefined, "normal");
        doc.setFontSize(8);
        doc.text(itemReporteFalla.estatus, 150, 403);
        doc.text(fechaFormateada, 150, 416);
        doc.text(itemReporteFalla.operador, 150, 429);
        // if (item.prioridad) {
        doc.text(itemReporteFalla.prioridad, 150, 444);
        // }
        doc.text(itemReporteFalla.tipoFalla, 403, 403);
        doc.text(itemReporteFalla.folio_falla.toString(), 403, 416);
        doc.text(createdAt, 403, 431);
        if (itemReporteFalla.mecanico.length > 0) {
          console.log(itemReporteFalla.mecanico);
          doc.text(asignacionFecha, 403, 444);
          doc.setFontSize(6);
          doc.text(itemReporteFalla.mecanico.toString(), 150, 456);
        }
        doc.setFontSize(8);
        const text = itemReporteFalla.diagnostico;
        const lines = doc.splitTextToSize(text, 487.5);
        if (itemReporteFalla.diagnostico) {
          doc.text(lines, 58, 515);
        }
        doc.setFont(undefined, "bold");
        doc.setFontSize(10);
        doc.text("Descripción de la Falla", 55, 305);
        doc.rect(55, 315, 500, 40);
        doc.setFont(undefined, "normal");
        doc.setFontSize(8);
        doc.text(itemReporteFalla.descripcionFalla, 58, 325);
        // doc.line(200,600,380,600,'F')

        doc.setFont(undefined, "bold");
        doc.setFontSize(10);
        doc.text("Diagnostico", 55, 495);
        doc.rect(55, 505, 500, 90);

        doc.text("Estatus de la unidad", 55, 615);
        if (itemReporteFalla.activo.estatu == "Operativo") {
          doc.rect(115, 645, 20, 15, "F");
        } else {
          doc.rect(115, 645, 20, 15);
        }
        if (itemReporteFalla.activo.estatu == "Fuera de Servicio") {
          doc.rect(325, 645, 20, 15, "F");
        } else {
          doc.rect(325, 645, 20, 15);
        }
        doc.setFont(undefined, "normal");
        doc.setFontSize(10);

        doc.text("Operativo", 55, 655);

        doc.text("Fuera de Servicio", 225, 655);
        if (itemReporteFalla.procedimiento) {
          doc.setFont(undefined, "bold");
          doc.setFontSize(10);
          doc.text("Procedimiento de Trabajo", 55, 535);
          doc.rect(55, 535, 500, 40);
          doc.setFont(undefined, "normal");
          doc.setFontSize(8);

          doc.text(itemReporteFalla.procedimiento, 58, 545);
        }
        if (itemReporteFalla.observaciones) {
          doc.setFont(undefined, "bold");
          doc.setFontSize(10);
          doc.text("Observaciones", 55, 595);
          doc.rect(55, 605, 500, 40);
          doc.setFont(undefined, "normal");
          doc.setFontSize(8);

          doc.text(itemReporteFalla.observaciones, 58, 615);
        }
        doc.line(60, 750, 200, 750);
        doc.line(240, 750, 380, 750);
        doc.line(420, 750, 560, 750);
        doc.setFontSize(7);
        doc.text("Supervisor", 110, 770);
        doc.text("Operador", 300, 770);
        doc.text("Jefe de Mantenimiento", 460, 770);
      }
      this.documentoOt = doc.output("datauristring");
      if (guardar == true) {
        var blob = doc.output("blob");
        var url = URL.createObjectURL(blob);
        var ventanaPopup = window.open("", "_blank", "width=900,height=800");
        ventanaPopup.document.write(
          '<embed src="' + url + '" type="application/pdf" width="100%" height="100%" />'
        );
      }
    },

    enviarEmailReporteFallas() {
      this.overlay = true;
      this.generarPdf(this.otSelected);
      const folio = this.otSelected.folio_ot;

      const numeroEconomico = this.otSelected.activo.numeroEconomico;

      //Información
      const estatus = this.otSelected.estatus;
      const hora_apertura = this.otSelected.hora_apertura;
      const hora_vencimiento = this.otSelected.hora_vencimiento;
      const reporte_falla = this.otSelected.reporte_falla;
      const tipo_falla = this.otSelected.tipo_falla;
      const tipo_intervencion = this.otSelected.tipo_intervencion;
      const correos = this.correos;
      let mecanicos = "";
      if (this.otSelected.tecnico) {
        mecanicos = this.otSelected.tecnico.toString();
      }
      const documento = this.documentoOt;
      EmailApi.enviarEmailOrdenTrabajo(
        folio,
        numeroEconomico,
        estatus,
        hora_apertura,
        hora_vencimiento,
        reporte_falla,
        tipo_falla,
        tipo_intervencion,
        correos,
        documento,
        mecanicos
      )
        .then(() => {
          this.addSuccessNotification("Notificación enviada correctamente");
          this.dialogCorreos = false;
          (this.otSelected = []), (this.correos = "");
          this.documentoOt = "";
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    CalculotiempoEjecucion(item) {
      if (item.estatus == "Cerrado") {
        const fechaInicio = new Date(item.createdAt);
        const fechaFin = new Date(item.fecha_cerrado);
        const tiempoTranscurrido = fechaFin - fechaInicio;

        //const segundos = Math.floor(tiempoTranscurrido / 1000) % 60;
        const minutos = Math.floor(tiempoTranscurrido / (1000 * 60)) % 60;
        const horas = Math.floor(tiempoTranscurrido / (1000 * 60 * 60)) % 24;
        const dias = Math.floor(tiempoTranscurrido / (1000 * 60 * 60 * 24));

        this.tiempoEjecucion = `${dias} días, ${horas} horas ${minutos} minutos`;
      }
    },

    mostrarDialogPauseOT(item) {
      this.dialogPauseOt = true;
      this._id = item;
    },

    pauseOrdenTrabajo() {
      MttoApi.pauseOrdenTrabajo(this._id, this.motivo_pause)
        .then(() => {
          this.menu = false;
          this._id = "";
          this.dialogPauseOt = false;
          this.addSuccessNotification("Orden de trabajo en espera de refacciones");
          this.listar();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    playOrdenTrabajo(item) {
      MttoApi.playOrdenTrabajo(item)
        .then(() => {
          this.menu = false;
          this.addSuccessNotification("Orden de trabajo reaunudada");
          this.listar();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    mostrarDialogEvidencias(item) {
      console.log(item)
      this.dialogEvidencias = true;
      this.evidencias_imagenes = item.evidencias;
      this.id = item._id;
    },

    mostrarDialogEvidenciasDoc(item) {
      this.dialogDocEviedenciasOt = true;
      this.evidencias_docs = item.evidencias_doc;
      this.id = item._id;
    },

    saveDescripcion(item) {
      let i = this.articulos.indexOf(item);
      const reg = this.refaccionesObject.find((e) => e.descripcion == item.descripcion);
      reg
        ? (this.articulos[i].no_parte = reg.no_parte)
        : (this.articulos[i].no_parte = "");
    },

    mostrarDialogTimeline(item) {
      this.tiemposTimeline = item;
      this.dialogTiempos = true;
      const tiemposRefacciones = [...item.tiempos];
      tiemposRefacciones.shift();
      tiemposRefacciones.pop();

      const tiemposAgrupados = [];
      const tiemposAgrupadosRefacciones = [];
      for (let i = 0; i < item.tiempos.length; i += 2) {
        const grupos = item.tiempos.slice(i, i + 2);
        tiemposAgrupados.push(grupos);
      }
      for (let i = 0; i < tiemposRefacciones.length; i += 2) {
        const grupos = tiemposRefacciones.slice(i, i + 2);
        tiemposAgrupadosRefacciones.push(grupos);
      }

      console.log(tiemposAgrupados[1]);
      const tiempo_ejecucion_group = [];
      const tiempo_en_espera_group = [];

      tiemposAgrupados.forEach((e) => {
        const fechaInicio = new Date(e[0].tiempo);
        const fechaFin = new Date(e[1].tiempo);
        const tiempoTranscurrido = fechaFin - fechaInicio;
        const segundos = Math.floor(tiempoTranscurrido / 1000) % 60;
        const minutos = Math.floor(tiempoTranscurrido / (1000 * 60)) % 60;
        const horas = Math.floor(tiempoTranscurrido / (1000 * 60 * 60)) % 24;
        const dias = Math.floor(tiempoTranscurrido / (1000 * 60 * 60 * 24));

        tiempo_ejecucion_group.push({
          segundos: segundos,
          minutos: minutos,
          horas: horas,
          dias: dias,
        });
      });

      console.log(tiempo_ejecucion_group);

      tiemposAgrupadosRefacciones.forEach((e) => {
        const fechaInicio = new Date(e[0].tiempo);
        const fechaFin = new Date(e[1].tiempo);
        const tiempoTranscurrido = fechaFin - fechaInicio;
        const segundos = Math.floor(tiempoTranscurrido / 1000) % 60;
        const minutos = Math.floor(tiempoTranscurrido / (1000 * 60)) % 60;
        const horas = Math.floor(tiempoTranscurrido / (1000 * 60 * 60)) % 24;
        const dias = Math.floor(tiempoTranscurrido / (1000 * 60 * 60 * 24));

        tiempo_en_espera_group.push({
          segundos: segundos,
          minutos: minutos,
          horas: horas,
          dias: dias,
        });
      });

      console.log(tiempo_en_espera_group);
      this.tiempo_en_espera = this.sumarTiempoDeEjecucion(tiempo_en_espera_group);
      this.tiempo_ejecucion_mtto = this.sumarTiempoDeEjecucion(tiempo_ejecucion_group);
    },

    sumarTiempoDeEjecucion(registros) {
      let totalDias = 0;
      let totalHoras = 0;
      let totalMinutos = 0;
      let totalSegundos = 0;

      registros.forEach((registro) => {
        totalDias += registro.dias;
        totalHoras += registro.horas;
        totalMinutos += registro.minutos;
        totalSegundos += registro.segundos;
      });

      // Ajustar los valores excedentes
      totalMinutos += Math.floor(totalSegundos / 60);
      totalSegundos %= 60;

      totalHoras += Math.floor(totalMinutos / 60);
      totalMinutos %= 60;

      totalDias += Math.floor(totalHoras / 24);
      totalHoras %= 24;

      return {
        dias: totalDias,
        horas: totalHoras,
        minutos: totalMinutos,
        segundos: totalSegundos,
      };
    },

    async subirImagen() {
      if (!this.evidencia_info) {
        this.addErrorNotification2("Ingresa la información de la imagen");
        return;
      }
      this.overlay = true;
      const evidencias_url = await uploadImage.evidenciasOt(this.evidencias);
      const datos = {
        url: evidencias_url,
        info: this.evidencia_info,
        _id: this.id,
      };
      MttoApi.adjuntarEvidencia(datos)
        .then((e) => {
          this.addSuccessNotification("Evidencia agregada");
          this.evidencias_imagenes = e.data.evidencias;
          console.log(e.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    async subirDocumento() {
      this.loadingDoc = true;
      if (!this.evidencias_doc_info) {
        this.addErrorNotification2("Ingresa la información de la imagen");
        return;
      }
      const evidencias_url = await uploadImage.evidenciasDocOt(this.evidencias_doc);
      const datos = {
        url: evidencias_url,
        info: this.evidencias_doc_info,
        _id: this.id,
      };
      MttoApi.adjuntarEvidenciaDoc(datos)
        .then((e) => {
          this.addSuccessNotification("Evidencia agregada");
          this.evidencias_doc = "";
          this.evidencias_doc_info = "";
          this.evidencias_docs = e.data.evidencias_doc;

        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingDoc = false;
        });
    },

    mostrarDocumento(item) {
      window.open(item, '_blank')
    },

    dividirEnGrupos(objeto, tamanoGrupo) {
      var grupos = [];
      var grupoActual = [];

      // Iterar sobre los elementos del objeto
      for (var i = 0; i < objeto.length; i++) {
        grupoActual.push(objeto[i]);

        // Si se ha alcanzado el tamaño del grupo o se han procesado todos los elementos
        if (grupoActual.length === tamanoGrupo || i === objeto.length - 1) {
          grupos.push(grupoActual);
          grupoActual = [];
        }
      }

      return grupos;
    },

    async agregarRefaccionRequisicones() {
      if (this.reporte_falla) {
        this.dialogArticulosMicrosip = true;
        await MttoApi.queryReporteFallaFolio(this.reporte_falla).then((e) => {
          let datos = [];
          datos = {
            requis: e.data.requisiciones.toString(),
          };
          ApiMicrosip.obtenerRequisicionesDetMtto(datos)
            .then((e) => {
              this.articulos_microsip = e.data.data;
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        this.addWarningNotification(
          "Alerta, ninguna requisicion agregada en el reporte de falla"
        );
      }
    },

    async buscarRefacciones() {
      this.dialogArticulosMicrosip = true;
      let req = this.requisiciones.toString();
      const datos = {
        requis: req.toString(),
      };
      await ApiMicrosip.obtenerRequisicionesDetMtto(datos).then((e) => {
        this.articulos_microsip = e.data.data;
      });
    },

    agregarArticuloRequi(item) {
      this.articulos.push({
        descripcion: item.ARTICULO,
        cantidad: parseInt(item.CANTIDAD),
      });
    },

    remove(item) {
      const index = this.requisiciones.indexOf(item.FOLIO);
      if (index >= 0) this.requisiciones.splice(index, 1);
    },

    mostrarRequicionesMicrosip() {
      ApiMicrosip.obtenerRequisicionesMtto()
        .then((e) => {
          this.requisiciones_mtto = e.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    filtroLabelPush(tipo) {
      if (tipo == "folio_ot") {
        let existe = this.arr.findIndex((e) => e.field == "folio_ot");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "folio_ot",
          value: this.folio_filter,
          label: "Folio : " + this.folio_filter,
          collection: "ot"
        });
      } else if (tipo == "activo") {
        let existe = this.arr.findIndex((e) => e.field == "activo");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "_id",
          value: this.numero_economico_filter.value,
          label: "N.E. : " + this.numero_economico_filter.text,
          collection: "activo"
        });
      } else if (tipo == 'tipoUnidad') {
        let existe = this.arr.findIndex((e) => e.field == "tipoUnidad");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "tipoUnidad",
          value: this.tipo_unidad_filter,
          label: "Tipo de unidad : " + this.tipo_unidad_filter,
          collection: "activo"
        });
      } else if (tipo == "estatus") {
        let existe = this.arr.findIndex((e) => e.field == "estatus");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "estatus",
          value: this.estatus_filter,
          label: "Estatus : " + this.estatus_filter,
          collection: "ot"
        });
      } else if (tipo == "tipo_falla") {
        let existe = this.arr.findIndex((e) => e.field == "tipo_falla");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "tipo_falla",
          value: this.tipo_falla_filter,
          label: "Tipo de falla : " + this.tipo_falla_filter,
          collection: "ot"
        });
      } else if (tipo == "reporte_falla") {
        let existe = this.arr.findIndex((e) => e.field == "reporte_falla");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "reporte_falla",
          value: this.reporte_falla_filter,
          label: "RF : " + this.reporte_falla_filter,
          collection: "ot"
        });
      } else if (tipo == "createdAt") {
        const fecha_inicio = new Date(this.fecha_inicio_filter);
        const fecha_fin = new Date(this.fecha_fin_filter);
        let existe = this.arr.findIndex((e) => e.field == "createdAt");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "createdAt",
          value: {
            $gte: fecha_inicio,
            $lte: fecha_fin,
          },
          label: "Entre : " + this.fecha_inicio_filter + " y " + this.fecha_fin_filter,
          collection: "ot"
        });
      }

      this.showFilterFolio = false;
      this.showFilterNumeroEconomico = false;
      this.showFilterTipoFalla = false;
      this.showFilterEstatus = false;
      this.showFilterFecha = false;
      this.showFilterReporteFalla = false;
      this.showFilterestatus = false;
      this.showFilterTipoUnidad = false
      this.listar();
    },

    async guiaRequisciones() {
      this.overlay = true;
      const datos = {
        folio: this.requisicion_seguimiento,
      };
      await ApiMicrosip.guiaRequisicionFolio(datos)
        .then((e) => {
          this.dialogGuiaRequisicion = true;
          this.seguimiento_requisiciones = e.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    mostraDialogGenerarRepFot() {
      this.dialogGenerarReporteFotografico = true;
    },

    generarReporteFotografico() {
      let fotos = [];
      this.selected.forEach((e, index) => {
        fotos.push({
          src: this.ot_evidencias_reporte[e].url,
          info: this.ot_evidencias_reporte[e].info,
          index: index + 1,
        });
      });

      Catalogo.obtenerAvatar(this.numeroEconomicoReporte.text).then((e) => {
        const datos = {
          numeroEconomico: this.numeroEconomicoReporte.text,
          semana: this.semanaReporte,
          avatar: e.data,
          fotos,
          fecha_inicio: this.fechaInicioReporte,
          fecha_fin: this.fechaFinReporte,
        };
        generarReporteFotografico(datos);
      });
    },

    mostrarReporteFalla(id) {
      this.overlay = true;
      MttoApi.queryReporteFallaFolio(id)
        .then((e) => {
          this.reporte_falla_seguimiento = e.data;
          this.dialogSeguimientoReporteFalla = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    mostrarImagenesOtFechas() {
      const datos = {
        numeroEconomico: this.numeroEconomicoReporte.value,
        fechaInicio: new Date(this.fechaInicioReporte),
        fechaFin: new Date(this.fechaFinReporte),
      };
      MttoApi.obtenerImagenesOt(datos).then((e) => {
        console.log(e.data);
        this.ot_evidencias_reporte = e.data;
      });
    },

    asignarRF(item) {
      console.log(item)
      this.bottomDialogAsignarRF = true
      this.orden_trabajo_selected = item
      MttoApi.listFoliosRF().then((e) => {
        this.folios_rf = e.data
        console.log(e.data)
      })
    },

    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },

    cambiarEstatus() {
      this.overlay = true;
      if (!this.fecha_cambio_estatus) {
        this.addErrorNotification();
        this.overlay = false;
        return;
      }
      let estado = "";
      if (this.estatus_cambio_estatus) {
        estado = "Operativo";
      } else {
        estado = "Fuera de Servicio";
      }
      const data = {
        fecha: this.fecha_cambio_estatus,
        estado: estado,
        equipo: this.equipo_cambio_estatus,
        documento: this.reporte_cambio_estatus,
        tipo_doc: "OT",
      };
      MttoApi.cambiarEstatusEquipo(data)
        .then(() => {
          this.listar();
          this.dialogEstatusUnidad = false;
          this.addSuccessNotification("Datos actualzados correctamente");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    mostrarDialogCambiarEstatus(item) {
      if (item.activo.estatu == "Operativo") {
        this.estatus_cambio_estatus = false;
      } else if (item.activo.estatu == "Fuera de Servicio") {
        this.estatus_cambio_estatus = true;
      }
      this.ne_cambio_estatus = item.activo.numeroEconomico;
      this.equipo_cambio_estatus = item.activo._id;
      this.reporte_cambio_estatus = item.folio_ot;
      this.dialogEstatusUnidad = true;
    },

    mostrarDialogFirmaLog(item) {
      this.otSelected = item
      this.dialogFirmaMtto = true
    },

    firmaMtto() {
      MttoApi.firmarOtMtto(this.otSelected._id).then((e) => {
        if (e.data.message == 'Sin firma') {
          this.addWarningNotification("No se encuentra su firma en  el sistema, favor de validar con el depto de desarrollo")
        } else {
          this.listar()
          this.addSuccessNotification("Solicitud firmada correctamente")
          this.dialogFirmaMtto = false
        }
      })
    },


    eliminarFotoEvidencia(_id) {
      this.overlay = true;
      const datos = {
        _id: this.id,
        _idPhoto: _id
      }
      MttoApi.eliminarEvidenciaFotografica(datos).then((e) => {
        this.addSuccessNotification("Datos actualzados correctamente");
        this.evidencias_imagenes = e.data.evidencias;
      }).finally(() => {
        this.overlay = false
      })
    },




    addErrorNotification2(mensaje) {
      this.$toast.error(mensaje, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addWarningNotification(mensaje) {
      this.$toast.warning(mensaje, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    eliminarFiltro(index) {
      this.arr.splice(index, 1);
      this.listar();
    },
  },
};
</script>
<style scoped>
.custom-td {
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-span {
  color: #555555;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.text-trabajo {
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
  margin: 0;
  padding: 10px;
  background-color: #f9f9f9;
}

.custom-timeline-item {
  margin-bottom: 20px;
}

.timeline-content {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
}

.timeline-type {
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.timeline-date {
  font-size: 14px;
  color: #888;
}

.timeline-time {
  font-size: 12px;
  color: #999;
}

.timeline-icon i {
  color: #4caf50;
  font-size: 18px;
}

.input-filter {
  background-color: rgb(241, 241, 241);
  border-radius: 20px;
  padding: -5px;
  width: 90%;
  height: 40px;
}

.button-fab {
  bottom: 50px;
  height: 60px;
  position: fixed;
  right: 20px;
  width: 60px;
}

.button-fab-fotografico {
  bottom: 50px;
  height: 60px;
  position: fixed;
  right: 120px;
  width: 70px;
}

.filter-label {
  color: #b1adad;
  padding: -20px;
  margin-top: -35px;
  margin-left: 40px;
  font-size: 13px;
  font-weight: normal;
}

.texto {
  font-family: "Raleway", sans-serif;
}

.filter-chips {
  color: #b1adad;
  padding: -20px;
  margin-top: -36px;
  margin-left: 40px;
}

.v-data-table>>>.v-data-table-header {
  background-color: rgb(23, 23, 87) !important;
}
</style>
