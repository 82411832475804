<template>
  <div ref="chart" :style="{ width: '100%', height: height }"></div>
</template>
  
<script>
import * as echarts from 'echarts';

export default {
  props: {
    chartTitle: String,
    data: Array,
    height: String,
  },
  mounted() {
    // Inicializa ECharts y crea el gráfico
    const chart = this.$refs.chart;
    const myChart = echarts.init(chart);
    const colors = [
      '#ff7070ff',  // Rojo
      '#91cc75',    // Verde
      '#5c7bd9ff',  // Azul
      '#ffcc6b',    // Naranja
      '#6f6b76',    // Gris
      '#c17f89',    // Rosa
      '#3a9d9c',    // Turquesa
      '#c7a4ff',    // Lila
    ];

    const option = {
      title: {
        x: 'center',
      },
      tooltip: {
        trigger: 'item',
        // formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      legend: {
        top: '5%',
        left: 'center',
        // doesn't perfectly work with our tricks, disable it
        selectedMode: false
      },
      color: colors,
      series: [
        {
          name: this.chartTitle,
          type: 'pie',
          radius: ['50%', '100%'],
          center: ['50%', '90%'],
          startAngle: 180,
          data: this.data,
          label: {
            formatter: function (params) {
              return `${params.name} (${params.value})`;
            }
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };

    // Establece los datos en el gráfico
    myChart.setOption(option);
    myChart.on('click', (params) => {
      if (params.name) {
        this.$emit('chart-click', {
          chartTitle: this.chartTitle,
          selectedValue: params.name
        });
      }
    });
  },
};
</script>



